
export function toSnakeCase(text) {
  return text.split(/(?=[A-Z])/).join('_').toLowerCase()
}

export function getObjectValueByString(obj, string) {
  string = string.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  string = string.replace(/^\./, '');           // strip a leading dot
  var a = string.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in obj) {
          obj = obj[k];
      } else {
          return;
      }
  }
  return obj;
}

export function sum(arr, column) {
  return arr.reduce((sum, elm) => sum += elm[column], 0)
}

export function sumInt(arr, column) {
  return arr.reduce((sum, elm) => sum += parseInt(elm[column]), 0)
}

/*********** Object ***************/
export function objectIsEmpty(obj) {
  // null and undefined are "empty"
  if (obj == undefined) return true;
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0)    return false;
  if (obj.length === 0)  return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== "object") return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (var key in obj) {
      if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
}

export function objectIndexOf(object, field, value) {
  return object.map(e => e[field]).indexOf(value)
}

export function objectReturnZeroIfNull(obj, field) {
  if (obj) {
    return obj[field] || 0
  } else {
    return 0
  }
}

export function objectPreventNull(obj, field) {
  if (obj) {
    return obj[field]
  } else {
    return null
  }
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

/*********** Object ***************/

/*********** Array ***************/
export function ArrayFindRecord(arr, field, value) {
  return arr.filter(elm => elm[field] === value)[0]
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function toUniqueArr(arr) {
  return arr.filter(onlyUnique);
}

export function subtractArr(arr1, arr2) {
  return arr1.filter(x => !arr2.includes(x))
             .concat(arr2.filter(x => !arr1.includes(x)));
}

export function queryInArr(arr, query, field_arr) {
  // g modifier: global. All matches (don't return on first match)
  // i modifier: insensitive. Case insensitive match (ignores case of [a-zA-Z]) 
  let regex = new RegExp( query, 'gi' );
  let filtered_arr = arr
  if (query) {
    filtered_arr = arr.filter((elm) => {
      let result = false
      for (let field of field_arr) {
        if (elm[field]) {
          result = result || elm[field].match(regex)
        }
      }
      return result
    })
  }
  return filtered_arr
}

export function queryInArrWithSubItem(arr, query, field_arr) {
  // g modifier: global. All matches (don't return on first match)
  // i modifier: insensitive. Case insensitive match (ignores case of [a-zA-Z]) 
  let regex = new RegExp( query, 'gi' );
  let filtered_arr = arr
  if (query) {
    filtered_arr = arr.filter((elm) => {
      let result = false
      for (let field of field_arr) {
        if (elm[field]) {
          result = result || elm[field].match(regex)
        }
      }
      return result
    })
  }
  return filtered_arr
}
/*********** Array ***************/

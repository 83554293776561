import React, { useEffect } from 'react'
import Modal from 'react-modal'
import DatePicker from 'react-datepicker'
import _ from 'lodash'
import Select from 'react-select'
import Spinner from '../../global/Spinner'
import ReactPaginate from 'react-paginate'
import { IconSorting } from '../../global/IconSorting'
import { API_RESOURCES, ORDER_STATES, APP_TITLE_ORDER, FORMAT_APP_DATE, ORDER_TYPE } from '../../../constants'
import '../Order.css'
import moment from 'moment'
import ExportExcel from '../../global/ExportExcel'
// Components
import SearchBar from '../../global/SearchBar'
import OrderProgressFilters from '../OrderProgressFilters'
import { ProcurementBtn, ExportProcurementModal, ImportProcurementModal } from './SubComponents'

// Functions
import { formatDate, formatApiDate } from '../../../functions/Date'
import { getKeyByValue, objectPreventNull } from '../../../functions/Object'
import { toLocaleStringCus } from '../../../functions/Number'

import { totalSalesWithVat, getClientStationName } from '../../../models/Quotations'
import { ORDER_PROGRESS_FLAGS, ORDER_TABS } from '../../../models/Orders'

const OPTIONS_STATES = Object.keys(ORDER_STATES).map((key) => {
  return { value: ORDER_STATES[key].name, label: ORDER_STATES[key].name }
})
const OPTIONS_TYPE = Object.keys(ORDER_TYPE).map((key) => {
  return { value: ORDER_TYPE[key].name, label: ORDER_TYPE[key].name }
})
// const OPTIONS_SORT = [
//   { value: "", label: "" },
//   { value: "po_date", label: "Order Date" },
//   { value: "client_station.name", label: "Station Name" },
//   { value: "state", label: "Status" },
//   { value: "code", label: "Order #" },
// ];

const OPTIONS_TYPE_SORT = [
  { value: '0', label: 'Vendor' },
  { value: '1', label: 'DC' },
]

const DEFAULT_FLAG_FILTER = {}
for (let key of Object.keys(ORDER_PROGRESS_FLAGS)) {
  DEFAULT_FLAG_FILTER[key] = null
}

function OrderIndex(props) {
  const headerColWithSortIcon = (text, sortText) => {
    let active = props.selected_sorting === sortText
    let sort_up = active && !props.selected_sorting_reversed
    let sort_down = active && props.selected_sorting_reversed

    return (
      <div className="d-flex flex-row cursor-pointer align-items-center" onClick={() => props.onClickSort(sortText)}>
        <div className="col-break-word text-bold" style={{ fontSize: '12px' }}>
          {text}
        </div>
        <IconSorting type={sortText} key={Math.random() * 100} activeUp={sort_up ? true : undefined} activeDown={sort_down ? true : undefined} />
      </div>
    )
  }

  const tableHeader = () => {
    let { orders, selected_orders, is_exporting } = props
    let sel_order_keys = Object.keys(selected_orders)
    let this_page_all_selected = true
    for (let elm of orders) {
      this_page_all_selected = this_page_all_selected && sel_order_keys.includes('' + elm.id)
    }
    return (
      <div className="card-box mt-1 p-3 d-none d-md-flex flex-row mt-3">
        <div className={`col-p5 align-items-center ${is_exporting ? 'd-flex flex-column flex-sm-row' : 'd-none'}`}>
          <div className="col-12 p-0">
            <input
              name="selected_all_on_page"
              type="checkbox"
              checked={this_page_all_selected}
              onClick={() => props.onSelectAllRecords(!this_page_all_selected)}
            />
          </div>
        </div>
        <div className={`${is_exporting ? 'col' : 'col-12'} d-flex flex-column flex-sm-row align-items-center px-0`}>
          <div className="col-custom-14 col-custom-sm-100 px-1 py-2">{headerColWithSortIcon('Order Date', 'po_date')}</div>
          <div className="col-custom-12 col-custom-sm-100 px-1 py-2">{headerColWithSortIcon('Order Type', 'code')}</div>
          <div className="col-custom-14 col-custom-sm-100 px-1 py-2">{headerColWithSortIcon('Station Name', 'client_station.name')}</div>
          <div className="col-custom-12 col-custom-sm-100 px-1 py-2">{headerColWithSortIcon('Order #', 'code')}</div>
          <div className="col-custom-12 col-custom-sm-100 px-1 py-2">{headerColWithSortIcon('Quotation #', 'quotation.code')}</div>
          <div className="col-custom-12 col-custom-sm-100 px-1 py-2">{headerColWithSortIcon('Status', 'state')}</div>
          <div className="col-custom-12 col-custom-sm-100 px-1 py-2">{headerColWithSortIcon('Total Sales (VAT Inc.)', 'total_sales')}</div>
          <div className="col-custom-12 col-custom-sm-100 px-1 py-2">{headerColWithSortIcon('Total Cost (VAT Inc.)', 'total_cost')}</div>
          <div className="col-custom-10 col-custom-sm-100 px-1 py-2"></div>
        </div>
      </div>
    )
  }

  const displayOrderContractsPoGr = (order) => {
    if (order.order_po_grs) {
      let contracts = order.order_po_grs.map((elm, index) => {
        if (index === 0) {
          return (
            <React.Fragment>
              {/* sm up */}
              <div className="col-12 d-none d-sm-flex flex-row mb-2">
                <div className="d-flex-1">
                  <a href={`/${API_RESOURCES.order}/${order.id}?tab=${getKeyByValue(ORDER_TABS, ORDER_TABS.po_gr)}`}>PO/GR</a>
                </div>
                <div className="d-flex-2 color-green">
                  {elm.contract.code} - {elm.contract.name}
                </div>
                <div className="d-flex-1 color-green">PO/GR (ส่ง) - {formatApiDate(elm.submit_date)}</div>
                <div className="d-flex-1 color-green">วันที่ได้รับ - {formatApiDate(elm.po_date)}</div>
              </div>
              {/* sm down */}
              <div className="col-12 d-flex d-sm-none flex-row mb-2 pl-4">
                <div className="d-flex-2">
                  <a href={`/${API_RESOURCES.order}/${order.id}?tab=${getKeyByValue(ORDER_TABS, ORDER_TABS.po_gr)}`}>PO/GR</a>
                </div>
              </div>
              <div className="col-12 d-flex d-sm-none flex-row mb-2 pl-4">
                <div className="d-flex-2 color-green">
                  {elm.contract.code} - {elm.contract.name}
                </div>
              </div>
              <div className="col-12 d-flex d-sm-none flex-row mb-2 pl-4">
                <div className="d-flex-1 color-green">PO/GR (ส่ง) - {formatApiDate(elm.submit_date)}</div>
                <div className="d-flex-1 color-green">วันที่ได้รับ - {formatApiDate(elm.po_date)}</div>
              </div>
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>
              {/* sm up */}
              <div className="col-12 d-none d-sm-flex flex-row mb-2">
                <div className="d-flex-1"></div>
                <div className="d-flex-2 color-green">
                  {elm.contract.code} - {elm.contract.name}
                </div>
                <div className="d-flex-1 color-green">PO/GR (ส่ง) - {formatApiDate(elm.submit_date)}</div>
                <div className="d-flex-1 color-green">วันที่ได้รับ - {formatApiDate(elm.po_date)}</div>
              </div>
              {/* sm down */}
              <div className="col-12 d-flex d-sm-none flex-row mb-2 pl-4">
                <div className="d-flex-2 color-green">
                  {elm.contract.code} - {elm.contract.name}
                </div>
              </div>
              <div className="col-12 d-flex d-sm-none flex-row mb-2 pl-4">
                <div className="d-flex-1 color-green">PO/GR (ส่ง) - {formatApiDate(elm.submit_date)}</div>
                <div className="d-flex-1 color-green">วันที่ได้รับ - {formatApiDate(elm.po_date)}</div>
              </div>
            </React.Fragment>
          )
        }
      })
      return contracts
    }
  }

  const displayOrderContractsManu = (order) => {
    if (order.purchasing_order_items) {
      let contracts = order.purchasing_order_items
        .filter((elm) => !elm.is_mbom)
        .map((elm, index) => {
          let contract_text = `${elm.code} - ${elm.name}`
          let installation_style = textColorStyle(objectPreventNull(elm, 'installation_date'))
          let installation_date = formatApiDate(elm.installation_date)
          // let contract_text = `${objectPreventNull(elm.contract, "code")} - ${objectPreventNull(elm.contract, "name")}`
          if (index === 0) {
            return (
              <React.Fragment>
                {/* sm up */}
                <div className="col-12 d-none d-sm-flex flex-row mb-2">
                  <div className="d-flex-1">
                    <a href={`/${API_RESOURCES.order}/${order.id}?tab=${getKeyByValue(ORDER_TABS, ORDER_TABS.manufacturer)}`}>การติดตั้ง</a>
                  </div>
                  <div className="d-flex-2 color-lightgray03">{contract_text}</div>
                  <div className={`d-flex-1 ${installation_style}`}>วันที่ติดตั้ง - {installation_date}</div>
                  <div className="d-flex-1"></div>
                </div>
                {/* sm down */}
                <div className="col-12 d-flex d-sm-none flex-row mb-2 pl-4">
                  <div className="d-flex-2 color-lightgray03">{contract_text}</div>
                </div>
                <div className="col-12 d-flex d-sm-none flex-row mb-2 pl-4">
                  <div className={`d-flex-1 ${installation_style}`}>วันที่ติดตั้ง - {installation_date}</div>
                  <div className="d-flex-1"></div>
                </div>
              </React.Fragment>
            )
          } else {
            return (
              <React.Fragment>
                {/* sm up */}
                <div className="col-12 d-none d-sm-flex flex-row mb-2">
                  <div className="d-flex-1"></div>
                  <div className="d-flex-2 color-lightgray03">{contract_text}</div>
                  <div className={`d-flex-1 ${installation_style}`}>วันที่ติดตั้ง - {installation_date}</div>
                  <div className="d-flex-1"></div>
                </div>
                {/* sm down */}
                <div className="col-12 d-flex d-sm-none flex-row mb-2 pl-4">
                  <div className="d-flex-2 color-lightgray03">{contract_text}</div>
                </div>
                <div className="col-12 d-flex d-sm-none flex-row mb-2 pl-4">
                  <div className={`d-flex-1 ${installation_style}`}>วันที่ติดตั้ง - {installation_date}</div>
                  <div className="d-flex-1"></div>
                </div>
              </React.Fragment>
            )
          }
        })
      return contracts
    }
  }

  const displayExportBasket = () => {
    const selectedOrderCount = Object.keys(props.selected_orders).length
    if (selectedOrderCount > 0) {
      return (
        <div
          className="order-import-basket-wrapper"
          onClick={() => {
            props.handleDisplayingBasket(true)
          }}
        >
          <i className="icon-folder-basket"></i>
          <div className="count-nb">{selectedOrderCount}</div>
        </div>
      )
    }
  }
  /* ================== Export Procurement Template (End) ==================== */

  const textColorStyle = (value) => {
    if (value) {
      return 'color-green'
    } else {
      return 'color-lightgray03'
    }
  }

  const orderItems = () => {
    let { orders, collapsed_id, selected_orders } = props
    let selected_ids = Object.keys(selected_orders)
    let items = orders.map((elm, index) => {
      // let total_with_vat = (elm.total_sales * 0.07) + elm.total_sales // totalSalesWithVat(elm.total_sales)
      // let total_cost_with_vat = (elm.total_cost*0.07) + elm.total_cost // totalSalesWithVat(elm.total_cost)
      // elm.total_cost = total_cost_with_vat
      return (
        <div className={`card-box mt-1 px-3 pt-3 link-no-hover`} onClick={() => props.onClickOrder(elm.id)} key={elm.no + index}>
          <div className="row mb-2">
            {/* md for Checkbox */}
            <div className={`${props.is_exporting ? 'col-p5 flex-custom-md flex-custom-sm-none' : 'd-none'} flex-column flex-sm-row cursor-pointer`}>
              <div className="col-12">
                <input name={`checkboxOrder-${elm.id}`} type="checkbox" id={`checkboxOrder-${elm.id}`} checked={selected_ids.includes('' + elm.id)} />
              </div>
            </div>

            {/* md */}
            <div className={`${props.is_exporting ? 'col' : 'col-12'} flex-custom-md flex-custom-sm-none flex-column flex-sm-row cursor-pointer`}>
              <div className="col-custom-14 col-custom-sm-100 px-1">
                <div className="col-break-word">{`${elm.po_date ? formatDate(elm.po_date) : '-'}`}</div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word" style={{ fontWeight: 'bold' }}>{`${
                  elm.dc_id && elm.dc_id === 1 ? ORDER_TYPE['dc'].name : ORDER_TYPE['vendor'].name
                }`}</div>
              </div>
              <div className="col-custom-14 col-custom-sm-100 px-1">
                <div className="col-break-word">{`${elm.quotation ? getClientStationName(elm.quotation) : '-'}`}</div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">{`${elm.code ? elm.code : '-'}`}</div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">{`${elm.quotation.code ? elm.quotation.code : '-'}`}</div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">{`${elm.state ? elm.state : '-'}`}</div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">{`${elm.cost_sale ? elm.cost_sale.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2}) : 'N/A'}`}</div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">{`${elm.cost_vat ? elm.cost_vat.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2}) : 'N/A'}`}</div>
              </div>
              <div className="col-custom-10 col-custom-sm-100 px-1 flex-row justify-content-end">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  onClick={(e) => {
                    e.stopPropagation()
                    props.onShowOrderDetails(elm.id)
                  }}
                >
                  {collapsed_id === elm.id ? 'ปิด' : 'เปิด'}
                </button>
              </div>
            </div>

            {/* sm for Checkbox */}
            <div
              className={`${props.is_exporting ? 'flex-custom-sm flex-custom-md-none' : 'd-none'} col-12 flex-column flex-sm-row cursor-pointer mb-2`}
            >
              <div className="col-12 px-1">
                <input name={`checkboxOrder-${elm.id}`} type="checkbox" id={`checkboxOrder-${elm.id}`} checked={selected_ids.includes('' + elm.id)} />
              </div>
            </div>

            {/* sm */}
            <div className="col-12 flex-custom-sm flex-custom-md-none flex-column flex-sm-row mb-2 cursor-pointe">
              <div className="col-custom-14 col-custom-sm-100 px-3 py-2 border-radious-custom bg-lightyellow01">
                <div className="col-break-word">{`${elm.quotation ? getClientStationName(elm.quotation) : '-'}`}</div>
              </div>
            </div>
            <div className="col-12 flex-custom-sm flex-custom-md-none flex-column flex-sm-row cursor-pointer">
              <div className="col-custom-14 col-custom-sm-100 px-1">
                <div className="col-break-word">
                  <span className="text-bold pr-2">Order Date</span>
                  <span className="text-bold pr-2">:</span>
                  {`${elm.po_date ? formatDate(elm.po_date) : '-'}`}
                </div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">
                  <span className="text-bold pr-2">Order Type</span>
                  <span className="text-bold pr-2">:</span>
                  {`${elm.dc_id && elm.dc_id === 1 ? ORDER_TYPE['dc'].name : ORDER_TYPE['vendor'].name}`}
                </div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">
                  <span className="text-bold pr-2">Order</span>
                  <span className="text-bold pr-2">#</span>
                  {`${elm.code ? elm.code : '-'}`}
                </div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">
                  <span className="text-bold pr-2">Quotation</span>
                  <span className="text-bold pr-2">#</span>
                  {`${elm.quotation.code ? elm.quotation.code : '-'}`}
                </div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">
                  <span className="text-bold pr-2">Status</span>
                  <span className="text-bold pr-2">:</span>
                  {`${elm.state ? elm.state : '-'}`}
                </div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">
                  <span className="text-bold pr-2">Total Sales</span>
                  <span className="text-bold pr-2">:</span>
                  {`${elm.cost_sale ? elm.cost_sale.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2}) + " บาท" : 'N/A'}`}
                </div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">
                  <span className="text-bold pr-2">Cost</span>
                  <span className="text-bold pr-2">:</span>
                  {`${elm.cost_vat ? elm.cost_vat.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2}) + " บาท"  : 'N/A'}`}
                </div>
              </div>
              <div className="col-custom-10 col-custom-sm-100 flex-row justify-content-end mt-1">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  onClick={(e) => {
                    e.stopPropagation()
                    props.onShowOrderDetails(elm.id)
                  }}
                >
                  {collapsed_id === elm.id ? 'ปิด' : 'เปิด'}
                </button>
              </div>
            </div>
          </div>

          {/* SM up */}
          <div className={`row mx-1 mb-1 d-none d-sm-block div-hide-scrollbar order-data-collapse ${collapsed_id === elm.id ? 'show' : ''}`}>
            <div className="col-12 d-flex flex-row mb-2">
              <div className="d-flex-1">
                <a href={`/${API_RESOURCES.order}/${elm.id}?tab=${getKeyByValue(ORDER_TABS, ORDER_TABS.payment)}`}>ใบสั่งซื้อ-ขาขาย</a>
              </div>
              <div className={`d-flex-1 ${textColorStyle(objectPreventNull(elm.payment_method, 'payment_date'))}`}>
                {objectPreventNull(elm.payment_method, 'method_type')} - {formatApiDate(objectPreventNull(elm.payment_method, 'payment_date'))}
              </div>
              <div className={`d-flex-1 ${textColorStyle(objectPreventNull(elm.payment_method, 'set_debt_date'))}`}>
                ตั้งหนี้ - {formatApiDate(objectPreventNull(elm.payment_method, 'set_debt_date'))}
              </div>
              <div className="d-flex-1"></div>
              <div className="d-flex-1"></div>
            </div>
            <div className="col-12 d-flex flex-row mb-2">
              <div className="d-flex-1">
                <a href={`/${API_RESOURCES.order}/${elm.id}?tab=${getKeyByValue(ORDER_TABS, ORDER_TABS.purchase_price)}`}>ราคาขาซื้อ (ราคาต้นทุน)</a>
              </div>
              <div className={`d-flex-1 ${textColorStyle(elm.total_cost)}`}>{toLocaleStringCus(elm.total_cost)} บาท</div>
              <div className="d-flex-1"></div>
              <div className="d-flex-1"></div>
              <div className="d-flex-1"></div>
            </div>

            {/* <div className="col-12 d-flex flex-row mb-2">
              <div className="d-flex-1"><a href={`/${API_RESOURCES.order}/${elm.id}?tab=${getKeyByValue(ORDER_TABS, ORDER_TABS.po_gr)}`}>PO/GR</a></div>
              <div className="d-flex-1 color-green">Send Info - 5/4/2021</div>
              <div className="d-flex-1"></div>
              <div className="d-flex-1"></div>
              <div className="d-flex-1"></div>
            </div> */}
            {displayOrderContractsPoGr(elm)}
            <div className="col-12 d-flex flex-row mb-2">
              <div className="d-flex-1">
                <a href={`/${API_RESOURCES.order}/${elm.id}?tab=${getKeyByValue(ORDER_TABS, ORDER_TABS.delivery_billing)}`}>Delivery/Billing</a>
              </div>
              <div className={`d-flex-1 ${textColorStyle(elm.db_delivery_code)}`}>Delivery# - {elm.db_delivery_code}</div>
              <div className={`d-flex-1 ${textColorStyle(elm.db_delivery_date)}`}>Delivery Date - {formatApiDate(elm.db_delivery_date)}</div>
              <div className={`d-flex-1 ${textColorStyle(elm.db_billing_code)}`}>Billing# - {elm.db_billing_code}</div>
              <div className={`d-flex-1 ${textColorStyle(elm.db_billing_date)}`}>Billing Date - {formatApiDate(elm.db_billing_date)}</div>
            </div>
            {displayOrderContractsManu(elm)}
            <div className="col-12 font-size-15px"></div>
            <div className="col-12 d-flex flex-row mb-2">
              <div className="d-flex-1">
                <a href={`/${API_RESOURCES.order}/${elm.id}?tab=${getKeyByValue(ORDER_TABS, ORDER_TABS.invoice)}`}>Invoice</a>
              </div>
              <div className={`d-flex-1 ${textColorStyle(elm.invoice_issued_date)}`}>วันที่ส่ง - {formatApiDate(elm.invoice_issued_date)}</div>
              <div className={`d-flex-1 ${textColorStyle(elm.invoice_tracking_no)}`}>เลขพัสดุ - {elm.invoice_tracking_no}</div>
              <div className={`d-flex-1 ${textColorStyle(elm.invoice_received_date)}`}>
                วันที่ได้รับกลับ - {formatApiDate(elm.invoice_received_date)}
              </div>
              <div className="d-flex-1"></div>
            </div>

            {/* {displayOrderContractsPoGr(elm)}
            <div className="col d-flex flex-column">
              <div className="font-size-15px pb-2"><a href={`/${API_RESOURCES.order}/${elm.id}?tab=${getKeyByValue(ORDER_TABS, ORDER_TABS.payment)}`}>Payment</a></div>
              <div className="color-green">DL - 2/4/2021</div>
              <div className="color-green">Set Debt - 1/4/2021</div>
            </div>
            <div className="col">
              <div className="font-size-15px pb-2"><a href={`/${API_RESOURCES.order}/${elm.id}?tab=${getKeyByValue(ORDER_TABS, ORDER_TABS.po_gr)}`}>PO/GR</a></div>
              <div className="color-green">Send Info - 5/4/2021</div>
            </div>
            <div className="col">
              <div className="font-size-15px pb-2"><a href={`/${API_RESOURCES.order}/${elm.id}?tab=${getKeyByValue(ORDER_TABS, ORDER_TABS.delivery_billing)}`}>Delivery/Billing</a></div>
              <div className="color-lightgray03">Delivery# - N/A</div>
              <div className="color-lightgray03">Delivery Date - N/A</div>
              <div className="color-lightgray03">Billing# - N/A</div>
              <div className="color-lightgray03">Billing Date - N/A</div>
            </div>
            <div className="col">
              <div className="font-size-15px pb-2"><a href={`/${API_RESOURCES.order}/${elm.id}?tab=${getKeyByValue(ORDER_TABS, ORDER_TABS.manufacturer)}`}>Manufacturer</a></div>
              <div className="color-lightgray03">Informed - N/A</div>
            </div>
            <div className="col">
              <div className="font-size-15px pb-2"><a href={`/${API_RESOURCES.order}/${elm.id}?tab=${getKeyByValue(ORDER_TABS, ORDER_TABS.invoice)}`}>Invoice</a></div>
              <div className="color-lightgray03">Created - N/A</div>
              <div className="color-lightgray03">Sent - N/A</div>
              <div className="color-lightgray03">Received - N/A</div>
            </div> */}
          </div>

          {/* SM down */}
          <div className={`row mx-1 mb-1 d-sm-none div-hide-scrollbar order-data-collapse ${collapsed_id === elm.id ? 'show' : ''}`}>
            <div className="col-12 d-flex flex-row mb-2">
              <div className="d-flex-2">
                <a href={`/${API_RESOURCES.order}/${elm.id}?tab=${getKeyByValue(ORDER_TABS, ORDER_TABS.payment)}`}>ใบสั่งซื้อ-ขาขาย</a>
              </div>
            </div>
            <div className="col-12 d-flex flex-row mb-2 pl-4">
              <div className={`d-flex-1 ${textColorStyle(objectPreventNull(elm.payment_method, 'payment_date'))}`}>
                {objectPreventNull(elm.payment_method, 'method_type')} - {formatApiDate(objectPreventNull(elm.payment_method, 'payment_date'))}
              </div>
              <div className={`d-flex-1 ${textColorStyle(objectPreventNull(elm.payment_method, 'set_debt_date'))}`}>
                ตั้งหนี้ - {formatApiDate(objectPreventNull(elm.payment_method, 'set_debt_date'))}
              </div>
            </div>
            <div className="col-12 d-flex flex-row mb-2">
              <div className="d-flex-2">
                <a href={`/${API_RESOURCES.order}/${elm.id}?tab=${getKeyByValue(ORDER_TABS, ORDER_TABS.purchase_price)}`}>ราคาขาซื้อ (ราคาต้นทุน)</a>
              </div>
            </div>
            <div className="col-12 d-flex flex-row mb-2 pl-4">
              <div className={`d-flex-1 ${textColorStyle(elm.total_cost)}`}>{toLocaleStringCus(elm.total_cost)} บาท</div>
            </div>
            {displayOrderContractsPoGr(elm)}
            <div className="col-12 d-flex flex-row mb-2">
              <div className="d-flex-2">
                <a href={`/${API_RESOURCES.order}/${elm.id}?tab=${getKeyByValue(ORDER_TABS, ORDER_TABS.delivery_billing)}`}>Delivery/Billing</a>
              </div>
            </div>
            <div className="col-12 d-flex flex-row mb-2 pl-4">
              <div className={`d-flex-1 ${textColorStyle(elm.db_delivery_code)}`}>Delivery# - {elm.db_delivery_code}</div>
              <div className={`d-flex-1 ${textColorStyle(elm.db_delivery_date)}`}>Delivery Date - {formatApiDate(elm.db_delivery_date)}</div>
            </div>
            <div className="col-12 d-flex flex-row mb-2 pl-4">
              <div className={`d-flex-1 ${textColorStyle(elm.db_billing_code)}`}>Billing# - {elm.db_billing_code}</div>
              <div className={`d-flex-1 ${textColorStyle(elm.db_billing_date)}`}>Billing Date - {formatApiDate(elm.db_billing_date)}</div>
            </div>
            {displayOrderContractsManu(elm)}
            <div className="col-12 font-size-15px"></div>
            <div className="col-12 d-flex flex-row mb-2">
              <div className="d-flex-2">
                <a href={`/${API_RESOURCES.order}/${elm.id}?tab=${getKeyByValue(ORDER_TABS, ORDER_TABS.invoice)}`}>Invoice</a>
              </div>
            </div>
            <div className="col-12 d-flex flex-row mb-2 pl-4">
              <div className={`d-flex-1 ${textColorStyle(elm.invoice_issued_date)}`}>วันที่ส่ง - {formatApiDate(elm.invoice_issued_date)}</div>
              <div className={`d-flex-1 ${textColorStyle(elm.invoice_tracking_no)}`}>เลขพัสดุ - {elm.invoice_tracking_no}</div>
            </div>
            <div className="col-12 d-flex flex-row mb-2 pl-4">
              <div className={`d-flex-1 ${textColorStyle(elm.invoice_received_date)}`}>
                วันที่ได้รับกลับ - {formatApiDate(elm.invoice_received_date)}
              </div>
              <div className="d-flex-1"></div>
            </div>
          </div>
        </div>
      )
    })
    return items
  }

  const pagePagination = () => {
    return (
      <div className="d-flex justify-content-end my-3">
        <ReactPaginate
          forcePage={props.page}
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={props.page_count}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          onPageChange={props.handlePageClick}
          containerClassName={'pagination'}
          // activeClassName={"active"}
          disabledClassName={'pagination__link--disabled'}
          activeClassName={'pagination__link--active'}
          key={props.resetKey}
        />
      </div>
    )
  }

  /* ============== More Filters ============== */
  const displayMoreFilters = () => {
    return (
      <React.Fragment>
        <div className={`${props.is_showing_more ? '' : 'd-none d-md-block'}`}>
          <div className="row">
            <div className="col-4 col-md-2 mt-2">
              <label>สถานะ</label>
            </div>
            <div className="col-8 col-md-10 mt-2">
              <Select isMulti value={props.selected_state} onChange={props.onChangeState} options={OPTIONS_STATES} />
            </div>
            <div className="col-4 col-md-2 mt-2 d-block d-md-none">
              <label>ประเภทออเดอร์</label>
            </div>
            <div className="col mt-2 d-block d-md-none">
              <Select
                isMulti
                // value={props.selected_state}
                // onChange={props.onChangeState}
                options={OPTIONS_TYPE}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-4 col-md-2 mt-2">
              <label>ประเภทออเดอร์</label>
            </div>
            <div className="col-8 col-md-10 mt-2">
              <Select
                isDisabled={props.is_exporting}
                value={OPTIONS_TYPE_SORT.filter((option) => option.value === props.selected_sorting_type.toString())}
                onChange={props.onChangeSortingType}
                options={OPTIONS_TYPE_SORT}
              />
            </div>
          </div>

          <OrderProgressFilters flag_filters={props.flag_filters} setFlagFilters={props.setFlagFilters} key={props.resetKey} />
        </div>
      </React.Fragment>
    )
  }
  const displayMoreFilterBtn = () => {
    if (props.is_showing_more) {
      return (
        <div className="row mt-3 d-md-none">
          <div className="col-12 div-icon-show d-flex" onClick={() => props.handleShowFiltersMD(false)}>
            <i className="icon-show-less cursor-pointer" />
            <div className="color-white">Show Less</div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="row mt-3 d-md-none">
          <div className="col-12 div-icon-show d-flex" onClick={() => props.handleShowFiltersMD(true)}>
            <i className="icon-show-more cursor-pointer" />
            <div className="color-white">Show More</div>
          </div>
        </div>
      )
    }
  }
  /* ============== More Filters ============== */
  const manageProcurement = () => {
    if (!props.is_exporting) {
      return (
        <div className="row justify-content-end pb-3 pr-3">
          <button type="button" className="btn btn-success d-block" onClick={props.onClickProcurement}>
            รายการส่งจัดซื้อ
          </button>
        </div>
      )
    } else {
      return (
        <div className="row justify-content-end pb-3 pr-3">
          <button type="button" className="btn btn-success d-block" onClick={() => props.handleProcurementModal(true)}>
            Import
          </button>
        </div>
      )
    }
  }
  let filename = 'DC_Order_Report_' + moment().format('YYYYMMDD_HHmmss')
  return (
    <React.Fragment>
      <Spinner loading={props.is_refiltering || props.is_loading} />
      <div className="offset-xl-1 col-xl-10 p-3 home-scrollable">
        <div className="row align-items-end">
          <div className="col-md-7 col-12 pb-2 d-flex flex-row align-items-end">
            <span className="header-title d-md-block my-0">{APP_TITLE_ORDER}</span>
          </div>
          <div className="col-md-5 col-12 text-right">{manageProcurement()}</div>
        </div>

        <div className="card-box light px-3 pt-3">
          <form onSubmit={props.handleSubmit}>
            <SearchBar
              onEnter={props.onEnter}
              onChangeQuery={props.onChangeQuery}
              onClickClear={props.onClickClear}
              query={props.query}
              placeholder={'ค้นหา สถานี, ผู้ซื้อ, เลขที่ใบสั่งซื้อ, เลขที่ใบเสนอราคา'}
            />

            <div className="row">
              <div className="col-4 col-md-2 mt-2">
                <label>วันที่ Order</label>
              </div>
              <div className="col-8 col-md-4 mt-2 date-picker-wrapper">
                <DatePicker
                  className="form-control"
                  selected={props.selected_date_form}
                  onChange={(date) => props.onChangeFromDate(date)}
                  dateFormat={FORMAT_APP_DATE}
                />
              </div>
              <div className="col-4 col-md-2 mt-2">
                <label>ถึง</label>
              </div>
              <div className="col mt-2 date-picker-wrapper">
                <DatePicker
                  className="form-control"
                  selected={props.selected_date_to}
                  onChange={(date) => props.onChangeToDate(date)}
                  dateFormat={FORMAT_APP_DATE}
                />
              </div>
            </div>
            {displayMoreFilters()}

            {/* Visible xs, sm  */}
            <div className="row d-block d-md-none">
              <div className="col-12 mt-3 text-right">
                <button type="submit" className="btn btn-primary ml-2">
                  Search
                </button>
                <button type="button" className="btn btn-danger ml-2" onClick={props.onClickClear}>
                  clear
                </button>
              </div>
            </div>

            {displayMoreFilterBtn()}
          </form>
        </div>
        {pagePagination()}
        <ProcurementBtn {...props} />

        <div className="">
          {!props.is_exporting && (
            <ExportExcel
              data={props.order_excel}
              content={'DC Report Export'}
              filename={filename}
              customClass={'btn btn-primary ml-2'}
              disabled={props.order_excel === null}
            />
          )}

          {tableHeader()}
          {orderItems()}
        </div>
        <div className="block-info-buffer-box"></div>
      </div>
      <ImportProcurementModal {...props} />
      {displayExportBasket()}
      <ExportProcurementModal {...props} />
    </React.Fragment>
  )
}
export default OrderIndex

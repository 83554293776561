import React from "react";
import PropTypes from "prop-types";
import ReactExport from "react-data-export";
import { objectIsEmpty } from "../../functions/Object";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportExcel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // excelHeader(headers) {
  //   return headers.map((col) => {
  //     return (
  //       <ExcelColumn label={col.label} value={col.value}/>
  //     )
  //   })
  // }

  // Example
  // {sheet_name: 'ตารางเปรียบเทียบ', dataSet: [{ columns: title, data: [] }, { columns: header, data: data }]}
  excelSheet() {
    console.log("this.props.data", this.props.data);

    return this.props.data.map((sheet) => {
      return <ExcelSheet dataSet={sheet.dataSet} name={sheet.sheet_name} />;
    });
  }

  render() {
    let { data, content, filename, customClass, disabled } = this.props;

    if (disabled || objectIsEmpty(data)) {
      return (
        <button type="button" className={`${customClass ? customClass : "btn btn-primary"}`} disabled>
          {content}
        </button>
      );
    } else {
      return (
        <React.Fragment>
          <ExcelFile
            element={
              <button type="button" className={`${customClass ? customClass : "btn btn-primary"}`} onClick={this.props.onExport ? this.props.onExport : undefined}>
                {content}
              </button>
            }
            filename={filename}
          >
            {this.excelSheet()}
          </ExcelFile>
        </React.Fragment>
      );
    }
  }
}

export default ExportExcel;

ExportExcel.propTypes = {
  data: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  onExport: PropTypes.func,
  disabled: PropTypes.bool,
};

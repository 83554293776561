import React, { useState, useEffect } from 'react'
import { Switch, Input, Button, DatePicker, Modal, Radio, Space, Select, Divider, Upload, Tooltip, Form } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { formatApiDate } from '../../../functions/Date'
import moment from 'moment'
import { CheckOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons'
import './table.css'
import axios from 'axios'
import { initDaystoInstallation } from '../../../models/Quotations'
import { getWarrantyExpirationDate } from '../../../models/ItemCategories'
import AWS from 'aws-sdk'
import { Storage } from 'aws-amplify'
import { API_RESOURCES, QUO_STATES } from '../../../constants.js'
const { Option } = Select
const { TextArea } = Input
const REPORT_STATE = ['Order issued partially', 'Order issued completed']
const REPORT_STATE_FORBIDDEN = ['draft', 'submitted', 'approved']

Storage.configure({
  AWSS3: {
    region: 'ap-southeast-1',
  },
})

export default function TableChild({
  quo,
  bank_list,
  current_user,
  QuotationChange,
  UpdateDeleteQuotation,
  setIsLoading,
  UpdateCancelQuataion,
  UpdateCancelAllQuataion,
}) {
  const [quotation, setQuotation] = useState(quo)
  const [visible, setVisible] = useState(false)
  const [bankDetail, setBankDetail] = useState({})
  const [currOrderID, setCurrOrderID] = useState('')
  const [orderID, setOrderID] = useState(null)
  const [paymentID, setPaymentID] = useState(null)
  const [approvedVisible, setApprovedVisible] = useState(false)
  const [cancelApprovedVisible, setCancelApprovedVisible] = useState(false)
  const [isCancelVisible, setIsCancelVisible] = useState(false)
  const [isDeleteQuotation, setIsDeleteQuotation] = useState(false)
  const [isCancelAll, setIsCancelAll] = useState(false)
  const [currentApproved, setCurrentApproved] = useState({ vendor: '', category: '', key: '' })
  const [currentCancelApproved, setCurrentCancelApproved] = useState({ vendor: '', category: '' })
  const [imageList, setImageList] = useState([])

  const quotation_items = quotation.quotation_items.filter((item) => item.issued === false)
  const quotation_categories = GetQuotationCategories(quotation_items, quotation.quotation_item_categories)
  let po_items_not_null = false
  let setQuotationTimeout = null

  useEffect(() => {
    setQuotation(quo)
  }, [quo])

  function HandleClickModal(oredr_id, payment_method = {}, order_id, payment_id = null) {
    setVisible((prev) => !prev)
    setCurrOrderID(oredr_id)
    setBankDetail(payment_method)
    setPaymentID(payment_id)
    setOrderID(order_id ? order_id : null)
    if (payment_method && payment_method.s3_slip_url) CheckImage(payment_method.s3_slip_url)
  }

  function CheckImage(image) {
    let img_url = image
    let image_list = []
    if (img_url && img_url.indexOf('http') < 0) {
      img_url = img_url.replace('public/', '')
      Storage.get(img_url).then((url) => {
        image_list = [
          {
            uid: '-1',
            name: `payslip.${img_url.split('payslip.')[1]}`,
            status: 'done',
            url: url,
          },
        ]

        setImageList(image_list)
      })
    }
  }

  function OnConfirmApproved(type = null) {
    UpdateInformedDate(type)
  }

  function OnClickApproved(params = {}) {
    setApprovedVisible((prev) => !prev)
    setCurrentApproved(params)
  }

  function OnClickCancelApproved(params = {}) {
    setCancelApprovedVisible((prev) => !prev)
    setCurrentCancelApproved(params)
  }

  function GetQuotationCategories(quo_items, categories) {
    let categories_list = []

    quo_items.forEach((item) => {
      categories.forEach((category) => {
        if (item.item_category_id === category.item_category_id) {
          categories_list.push(category)
        }
      })
    })

    return [...new Set(categories_list)]
  }

  function QuotationCategoryforInformDate(quotation_item_categories) {
    let category = {}

    quotation_item_categories.forEach((cate) => {
      category[cate.item_category_id] = cate
    })

    return category
  }

  function UpdateInformedDate(type) {
    let quotation_item_categories = QuotationCategoryforInformDate(quotation.quotation_item_categories)

    let params = {
      quo_code: quotation.code,
      quotation_item_categories: quotation_item_categories,
      quotation_items: quotation.quotation_items,
      user_id: current_user.id,
    }

    UpdateNewInformedDate(quotation_item_categories, type === null ? currentApproved.key : currentCancelApproved.key, type)
    UpdateInstallationDate(quotation_item_categories, type === null ? currentApproved.key : currentCancelApproved.key, type)

    axios
      .put(`${process.env.REACT_APP_API_URL}/quotations/${quotation.id}/update_informed_dates`, params)
      .then(() => {
        let current_installation_date = {}
        quotation.quotation_items.forEach((quo_item) => {
          current_installation_date[quo_item.id] = quo_item.installation_date
        })

        alert('Inform date updated!')
        setApprovedVisible(false)
        setCancelApprovedVisible(false)
      })
      .catch(() => {
        alert('Update Error !')
        setApprovedVisible(false)
        setCancelApprovedVisible(false)
      })
  }

  function OnChangeQuotation(newQuotation) {
    clearTimeout(setQuotationTimeout)
    setQuotationTimeout = setTimeout(() => {
      QuotationChange(newQuotation)
    }, 500)
  }

  function UpdateNewInformedDate(quotation_item_categories, key, type = null) {
    let newQuotation = { ...quotation }
    newQuotation.quotation_item_categories = quotation_item_categories

    newQuotation.quotation_item_categories[key].informed_date = type === null ? formatApiDate(new Date()) : null
    setQuotation(newQuotation)
    OnChangeQuotation(newQuotation)
  }

  function UpdateInstallationDate(quotation_categories, key, type = null) {
    let { quotation_items, quotation_item_categories } = quotation
    quotation_item_categories = quotation_categories

    quotation_items.forEach((elm) => {
      if (parseInt(key) === parseInt(elm.item_category_id)) {
        let inst_date = new Date()
        let nb_days_to_complete = quotation_item_categories[elm.item_category_id].contract.nb_days_to_complete
          ? quotation_item_categories[elm.item_category_id].contract.nb_days_to_complete
          : 0
        let days_to_ship = quotation_item_categories[elm.item_category_id].contract.days_to_ship
          ? quotation_item_categories[elm.item_category_id].contract.days_to_ship
          : 0
        let days_to_complete = nb_days_to_complete + days_to_ship
        let date_h = {
          target: {
            name: 'installation_date',
            value: type === null ? formatApiDate(inst_date.setDate(inst_date.getDate() + days_to_complete)) : null,
          },
        }
        onChangeItemManufactureDetails(date_h, elm.id)

        let num_days = {
          target: {
            name: 'days_to_installation',
            value: days_to_complete,
          },
        }
        onChangeItemManufactureDetails(num_days, elm.id)
      }
    })
  }

  function onChangeItemManufactureDetails(e, id) {
    let newQuotation = { ...quotation }
    let { quotation_items } = newQuotation
    let de_item_index = quotation_items.findIndex((x) => x.id === id)

    quotation_items[de_item_index] = {
      ...quotation_items[de_item_index],
      [e.target.name]: e.target.value,
    }
    quotation_items = initDaystoInstallation(newQuotation)
    quotation_items[de_item_index].expiration_date = formatApiDate(getWarrantyExpirationDate(quotation_items[de_item_index]))
    setQuotation(newQuotation)
    OnChangeQuotation(newQuotation)
  }

  function HandleChangeBank(e) {
    const detail = { ...bankDetail }
    detail['bank_id'] = e

    setBankDetail(detail)
  }

  function HandleChangeBankNumber(e) {
    const detail = { ...bankDetail }
    detail['note'] = e.target.value

    setBankDetail(detail)
  }

  function OnChangeValueRadio(e) {
    const detail = { ...bankDetail }
    detail['method_type'] = e.target.value
    setImageList([])

    setBankDetail(detail)
  }

  function HandleChangePaymentDate(e) {
    const detail = { ...bankDetail }
    detail['payment_date'] = moment(e).format('YYYY-MM-DD')

    setBankDetail(detail)
  }

  function HandleChangeDebtDate(e) {
    const detail = { ...bankDetail }
    detail['set_debt_date'] = moment(e).format('YYYY-MM-DD')

    setBankDetail(detail)
  }

  function CustomAntdUpaloadRequest(e) {
    const url_expire = 3600 * 24 * 7
    const list_image = [...imageList]
    const detail = { ...bankDetail }
    AWS.config.update({
      region: process.env.REACT_APP_REGION,
    })

    const S3 = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
      accessKeyId: process.env.REACT_APP_S3_USER_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_S3_USER_SECRET_KEY,
    })

    const path_key = `public/purchasing_orders/${orderID}/payslip.${e.file.name.split('.')[1]}`

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: path_key,
      Body: e.file,
      ContentType: e.file.type,
      Expires: url_expire,
    }

    S3.putObject(params)
      .on('httpUploadProgress', ({ loaded, total }) => {
        e.onProgress({
          percen: Math.round((loaded / total) * 100),
        })
      })
      .send((err, data) => {
        if (err) {
          e.onError()
        } else {
          e.onSuccess(data, e.file)
          // list_image.push({ key: path_key, value: e.file });
        }
        detail['s3_slip_url'] = path_key
        // setImageList(list_image);
        setBankDetail(detail)
      })
  }

  function OnRemoveImage(file) {
    const list = []
    const detail = { ...bankDetail }
    imageList.forEach((image) => {
      if (image.value && image.value.uid !== file.uid) list.push(image)
      else if (image.uid && image.uid !== file.uid) list.push(image)
    })

    detail['s3_slip_url'] = null

    setBankDetail(detail)
    setImageList(list)
  }

  function HandleOnFinishPayment(currOrderID) {
    const params = { ...bankDetail }
    const newQuotation = { ...quotation }
    const findIndex_order = newQuotation.purchasing_orders.findIndex((index) => index.code === currOrderID)
    delete params.id
    const date_for_api = moment(params.payment_date).format('YYYY-MM-DD')
    params.payment_date = date_for_api === 'Invalid date' ? null : date_for_api
    const order = newQuotation.purchasing_orders[findIndex_order]
    const values = {
      order_id: order.id,
      method_type: bankDetail.method_type,
      order_code: order.order_code,
      flag_id: order.order_flag.id,
      payment_method: params,
    }

    if (paymentID) {
      axios
        .put(`${process.env.REACT_APP_API_URL}/order_payment/${paymentID}`, values)
        .then(() => {
          newQuotation.purchasing_orders[findIndex_order].payment_method = values.payment_method
          newQuotation.purchasing_orders[findIndex_order].payment_method.id = paymentID
          newQuotation.purchasing_orders[findIndex_order].order_code = values.order_code
          QuotationChange(newQuotation)
          alert('Update payment complete !')
          setVisible(false)
        })
        .catch(() => {
          alert('Update payment error !')
          setVisible(false)
        })
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/order_payment`, values)
        .then(() => {
          newQuotation.purchasing_orders[findIndex_order].payment_method = values.payment_method
          QuotationChange(newQuotation)
          alert('Update payment complete !')
          setVisible(false)
        })
        .catch(() => {
          alert('Update payment error !')
          setVisible(false)
        })
    }
  }

  function OnChangeOrder(e, type, order_id) {
    const newQuotation = { ...quotation }
    const order_index = newQuotation.purchasing_orders.findIndex((po) => po.id === order_id)
    if (type === 'orc') {
      newQuotation.purchasing_orders[order_index].order_code = e.target.value
    }
    if (type === 'do') {
      newQuotation.purchasing_orders[order_index].db_delivery_code = e.target.value
    }
    if (type === 'billing') {
      newQuotation.purchasing_orders[order_index].db_billing_code = e.target.value
    }

    setQuotation(newQuotation)
    OnChangeQuotation(newQuotation)
  }

  function OnChangeOrderPOGR(e, type, order_id, contract_id) {
    const newQuotation = { ...quotation }
    const order_index = newQuotation.purchasing_orders.findIndex((po) => po.id === order_id)
    const po_grs = newQuotation.purchasing_orders[order_index].order_po_grs.findIndex((po_gr) => po_gr.contract_id === contract_id)
    if (type === 'po_code') {
      if (po_grs === -1) {
        newQuotation.purchasing_orders[order_index].order_po_grs.push({
          contract_id: contract_id,
          po_code: e.target.value,
          po_date: moment().format('YYYY-MM-DD'),
          id: null,
        })
      } else {
        newQuotation.purchasing_orders[order_index].order_po_grs[po_grs].po_code = e.target.value
        newQuotation.purchasing_orders[order_index].order_po_grs[po_grs].po_date = newQuotation.purchasing_orders[order_index].order_po_grs[po_grs]
          .po_date
          ? newQuotation.purchasing_orders[order_index].order_po_grs[po_grs].po_date
          : moment().format('YYYY-MM-DD')
      }
    }
    if (type === 'gr_code') {
      if (po_grs === -1) {
        newQuotation.purchasing_orders[order_index].order_po_grs.push({
          contract_id: contract_id,
          gr_code: e.target.value,
          gr_date: moment().format('YYYY-MM-DD'),
          id: null,
        })
      } else {
        newQuotation.purchasing_orders[order_index].order_po_grs[po_grs].gr_code = e.target.value
        newQuotation.purchasing_orders[order_index].order_po_grs[po_grs].gr_date = newQuotation.purchasing_orders[order_index].order_po_grs[po_grs]
          .gr_date
          ? newQuotation.purchasing_orders[order_index].order_po_grs[po_grs].gr_date
          : moment().format('YYYY-MM-DD')
      }
    }

    let po_gr_completed = true

    newQuotation.purchasing_orders[order_index].order_po_grs.forEach((po_grs) => {
      if (!po_grs.po_code || !po_grs.gr_code) {
        po_gr_completed = false
      }
    })

    newQuotation.purchasing_orders[order_index].order_flag.po_gr_completed = po_gr_completed

    console.log('po_gr_completed', po_gr_completed)
    console.log('newQuotation.purchasing_orders[order_index]', newQuotation.purchasing_orders[order_index].order_flag.po_gr_completed)

    setQuotation(newQuotation)
    OnChangeQuotation(newQuotation)
  }

  function HandleChangeItem(e, order_type, type, order_id, item_id) {
    const newQuotation = { ...quotation }
    const order_index = order_id && newQuotation.purchasing_orders.findIndex((order) => order.id === order_id)
    const item_index =
      order_type === 'quotation'
        ? newQuotation.quotation_items.findIndex((item) => item.id === item_id)
        : newQuotation.purchasing_orders[order_index].purchasing_order_items.findIndex((item) => item.id === item_id)

    if (type === 'switch') {
      if (order_type === 'quotation') newQuotation.quotation_items[item_index].station_ready = e ? moment().format('YYYY-MM-DD') : null
      else newQuotation.purchasing_orders[order_index].purchasing_order_items[item_index].station_ready = e ? moment().format('YYYY-MM-DD') : null
    }
    if (type === 'installation_date') {
      if (order_type === 'quotation') newQuotation.quotation_items[item_index].installation_date = moment(e).format('YYYY-MM-DD')
      else newQuotation.purchasing_orders[order_index].purchasing_order_items[item_index].installation_date = moment(e).format('YYYY-MM-DD')
    }

    setQuotation(newQuotation)
    OnChangeQuotation(newQuotation)
  }

  function HandleChangeQuotationOwner(e) {
    const newQuotation = { ...quotation }

    newQuotation.owner = e.target.value

    setQuotation(newQuotation)
    QuotationChange(newQuotation)
  }

  const onChangeImageUpload = ({ fileList: newFileList }) => {
    setImageList(newFileList)
  }

  function onCancelQuotation(type) {
    setIsDeleteQuotation(type)
    setIsCancelVisible(true)
  }

  function OnClickCancelQuotation() {
    setIsCancelVisible(false)
  }

  function onClickCancelQuotationPo(type) {
    setIsCancelAll(type)
  }

  function onHandleCancelQuotation(values) {
    setIsLoading(true)
    if (isDeleteQuotation) {
      //delete
      axios
        .delete(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.quotation}/${quotation.id}`, {
          data: {},
          headers: { 'Content-Type': 'text/plain' },
        })
        .then(() => {
          UpdateDeleteQuotation(quotation.id)
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    } else {
      //cancel
      axios
        .put(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.quotation}/${quotation.id}/update_state`, {
          state: isCancelAll ? QUO_STATES.cancelled.name : QUO_STATES.order_issued_completed.name,
          cancel_note: values.reason,
        })
        .then(() => {
          if (isCancelAll) {
            UpdateCancelAllQuataion(quotation.id)
          } else {
            UpdateCancelQuataion(quotation.id, QUO_STATES.order_issued_completed.name)
          }

          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    }
    setIsCancelVisible(false)
  }

  return (
    quotation.quotation_items.length > 0 && (
      <>
        <div className="main-table-head-sub">
          <div className="delete-or-cancel-container">
            <div
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 5, cursor: 'pointer' }}
              onClick={() => onCancelQuotation(false)}
            >
              <CloseOutlined /> ยกเลิกใบเสนอราคา
            </div>
            {quotation.state === 'draft' && (
              <div
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 5, cursor: 'pointer' }}
                onClick={() => onCancelQuotation(true)}
              >
                <DeleteOutlined /> ลบใบเสนอราคา
              </div>
            )}
          </div>
          <div className="table-head-sub">
            <div className="table-content-sub" style={{ border: 'none', maxWidth: 150, minWidth: 150, justifyContent: 'normal' }}>
              {<Input placeholder="ผู้รับผิดชอบ" style={{ borderRadius: 5 }} value={quotation.owner} onChange={HandleChangeQuotationOwner} />}
            </div>
            <div className="table-content-sub" style={{ border: 'none', minWidth: 180, justifyContent: 'normal' }}>
              {quotation.buyer ? quotation.buyer.name : '-'}
            </div>
            <div className="table-content-sub" style={{ border: 'none', justifyContent: 'normal', fontWeight: 'bold', minWidth: 250 }}>
              {quotation.client_station.name}
            </div>
            <div className="table-content-sub" style={{ border: 'none', flexDirection: 'column', minWidth: 150, justifyContent: 'normal' }}>
              <a href={`/quotations/${quotation.id}`} style={{ color: quotation.code ? '#2376C3' : 'red', fontWeight: 'bold' }}>
                {quotation.code ? quotation.code : 'PENDING'}
              </a>
              <div style={{ color: '#0D8D40', fontWeight: 'bold' }}>{quotation.state}</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              {quotation.purchasing_orders.map((order) => {
                let order_items = order.purchasing_order_items.length > 0 ? order.purchasing_order_items : quotation.quotation_items
                let order_categories =
                  order.purchasing_order_items.length > 0
                    ? order.order_item_categories.filter((item) => (item.dc_id === 0 ? item.informed_date != null : item))
                    : quotation.quotation_item_categories
                let po_gr_is_null = order.order_po_grs.filter(
                  (value, index, self) => value.po_code && value.gr_code && index === self.findIndex((t) => t.contract_id === value.contract_id)
                )
                po_items_not_null = order.purchasing_order_items.length > 0
                let isDc = order_categories.find((item) => item.item_category && item.item_category.dc_id === 1)

                return (
                  order.state !== 'cancelled' && (
                    <div style={{ display: 'flex', height: order_items.length === 1 && '100%' }}>
                      <div style={{ display: 'flex' }}>
                        <div>
                          {order_categories.map((category) => {
                            let item_in_this_cate = []

                            if (po_items_not_null) {
                              item_in_this_cate = order_items.filter(
                                (item) => item.item_category_id === category.item_category_id && item.from_mbom === false
                              )
                            } else {
                              item_in_this_cate = order_items.filter((item) => item.item_category_id === category.item_category_id)
                            }

                            const po_gr = order.order_po_grs.find((item) => item.contract_id === category.contract_id)

                            if (item_in_this_cate.length === 0) {
                              return
                            }

                            return (
                              <div style={{ display: 'flex', flexDirection: 'rows', height: order_items.length === 1 && '100%' }}>
                                <div className="table-content-sub" style={{ flexDirection: 'column', width: 200 }}>
                                  <div style={{ height: 32 }}>{category.sys_name}</div>
                                </div>
                                <div
                                  className="table-content-sub"
                                  style={{ flexDirection: 'column', width: 120, background: quotation.code && '#ABF0CF' }}
                                >
                                  <Button
                                    className={
                                      !po_items_not_null && category.informed_date && quotation.state === 'approved'
                                        ? 'approve-btn'
                                        : REPORT_STATE.includes(quotation.state) && !po_items_not_null && category.informed_date && 'approve-btn'
                                    }
                                    type="primary"
                                    style={{
                                      borderRadius: 5,
                                      background:
                                        category.item_category && category.item_category.dc_id === 1
                                          ? '#ABABAB'
                                          : !po_items_not_null && REPORT_STATE.includes(quotation.state)
                                          ? category.informed_date && '#1D9F1A'
                                          : REPORT_STATE_FORBIDDEN.includes(quotation.state)
                                          ? quotation.state === 'approved'
                                            ? category.informed_date
                                              ? '#1D9F1A'
                                              : ''
                                            : '#ABABAB'
                                          : '#ABABAB',
                                      border: 'none',
                                      color: 'white',
                                    }}
                                    onClick={
                                      category.informed_date
                                        ? () =>
                                            OnClickCancelApproved({
                                              vendor: quotation.client_station.name,
                                              category: category.sys_name,
                                              key: category.item_category_id,
                                            })
                                        : () =>
                                            OnClickApproved({
                                              vendor: quotation.client_station.name,
                                              category: category.sys_name,
                                              key: category.item_category_id,
                                            })
                                    }
                                    disabled={
                                      category.item_category && category.item_category.dc_id === 1
                                        ? true
                                        : REPORT_STATE.includes(quotation.state)
                                        ? po_items_not_null
                                          ? true
                                          : false
                                        : true || REPORT_STATE_FORBIDDEN.includes(quotation.state)
                                        ? quotation.state === 'approved'
                                          ? false
                                          : true
                                        : true
                                    }
                                  >
                                    <div
                                      className={
                                        !po_items_not_null && category.informed_date && quotation.state === 'approved'
                                          ? 'approved-text'
                                          : REPORT_STATE.includes(quotation.state) && !po_items_not_null && category.informed_date && 'approved-text'
                                      }
                                    >
                                      {category.informed_date ? (
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 5 }}>
                                          <CheckOutlined /> แจ้งแล้ว
                                        </div>
                                      ) : (
                                        'แจ้งผู้ผลิต'
                                      )}
                                    </div>
                                    {!po_items_not_null && category.informed_date && quotation.state === 'approved' ? (
                                      <div className="cancel-text">ยกเลิก</div>
                                    ) : (
                                      REPORT_STATE.includes(quotation.state) &&
                                      !po_items_not_null &&
                                      category.informed_date && <div className="cancel-text">ยกเลิก</div>
                                    )}
                                  </Button>
                                </div>
                                <div style={{ display: 'flex', marginLeft: 1200 }}>
                                  <div className="table-content-sub" style={{ width: 150, background: po_gr && po_gr.po_code && '#ABF0CF' }}>
                                    <Input
                                      onChange={(e) => OnChangeOrderPOGR(e, 'po_code', order.id, category.contract_id)}
                                      style={{ borderRadius: 5 }}
                                      value={po_gr && po_gr.po_code}
                                      disabled={order.dc_id > 0 ? true : REPORT_STATE_FORBIDDEN.includes(quotation.state)}
                                    />
                                  </div>
                                  <div className="table-content-sub" style={{ width: 150, background: po_gr && po_gr.gr_code && '#ABF0CF' }}>
                                    <Input
                                      onChange={(e) => OnChangeOrderPOGR(e, 'gr_code', order.id, category.contract_id)}
                                      style={{ borderRadius: 5 }}
                                      value={po_gr && po_gr.gr_code}
                                      disabled={order.dc_id > 0 ? true : REPORT_STATE_FORBIDDEN.includes(quotation.state)}
                                    />
                                  </div>
                                </div>
                                <div style={{ marginLeft: -1500 }}>
                                  {item_in_this_cate.map((item) => {
                                    return (
                                      <div style={{ display: 'flex', minHeight: '90px' }}>
                                        <div
                                          className="table-content-sub"
                                          style={{
                                            flexDirection: 'column',
                                            width: 300,
                                            background: item.name && '#ABF0CF',
                                            marginRight: 600,
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {item.code} - {item.name}
                                        </div>
                                        <div
                                          className="table-content-sub"
                                          style={{ flexDirection: 'column', width: 200, background: item.installation_date && '#ABF0CF' }}
                                        >
                                          <DatePicker
                                            onChange={(e) =>
                                              HandleChangeItem(
                                                e,
                                                item.issued !== undefined ? 'quotation' : 'purchasing',
                                                'installation_date',
                                                order.id,
                                                item.id
                                              )
                                            }
                                            disabled={item.installation_date ? false : REPORT_STATE_FORBIDDEN.includes(quotation.state)}
                                            style={{ borderRadius: 5 }}
                                            value={item.installation_date ? moment(item.installation_date, 'YYYY/MM/DD') : null}
                                            format={'DD/MM/YYYY'}
                                          />
                                        </div>
                                        <div
                                          className="table-content-sub"
                                          style={{
                                            flexDirection: 'column',
                                            width: 100,
                                            alignItems: 'center',
                                            background: item.station_ready && '#ABF0CF',
                                          }}
                                        >
                                          <Switch
                                            onClick={(e) =>
                                              HandleChangeItem(e, item.issued !== undefined ? 'quotation' : 'purchasing', 'switch', order.id, item.id)
                                            }
                                            checked={item.station_ready ? true : false}
                                            style={{ width: 50 }}
                                            disabled={item.installation_date ? false : REPORT_STATE_FORBIDDEN.includes(quotation.state)}
                                          />
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <div
                          className="table-content-sub"
                          style={{ flexDirection: 'column', width: 150, textAlign: 'center', marginLeft: -900, background: order.code && '#ABF0CF' }}
                        >
                          {order.code ? (
                            <a href={`/orders/${order.id}`} style={{ margin: 0, color: '#2376C3', fontWeight: 'bold', fontSize: 14 }}>
                              {order.code}
                            </a>
                          ) : (
                            <a href={`/quotations/${quotation.id}`} style={{ color: 'red', fontSize: 14, margin: 0, fontWeight: 'bold' }}>
                              ORDER PENDING
                            </a>
                          )}
                        </div>
                      </div>
                      <div className="table-content-sub" style={{ width: 150, background: order.order_code && '#ABF0CF' }}>
                        <Input
                          style={{ borderRadius: 5 }}
                          value={order.order_code}
                          onChange={(e) => OnChangeOrder(e, 'orc', order.id)}
                          disabled={REPORT_STATE_FORBIDDEN.includes(quotation.state)}
                        />
                      </div>
                      <div
                        className="table-content-sub"
                        style={{
                          padding: 0,
                          margin: 0,
                          borderRight: 'none',
                          paddingLeft: 10,
                          width: 150,
                          background: order.payment_method && order.payment_method.method_type && '#ABF0CF',
                        }}
                      >
                        <div
                          className="modal-box"
                          style={{
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5,
                            margin: 0,
                            cursor: REPORT_STATE_FORBIDDEN.includes(quotation.state) && 'not-allowed',
                            background: REPORT_STATE_FORBIDDEN.includes(quotation.state) && 'rgba(0,0,0,0.15)',
                          }}
                          onClick={
                            REPORT_STATE_FORBIDDEN.includes(quotation.state)
                              ? () => {}
                              : () => HandleClickModal(order.code, order.payment_method, order.id, order.payment_method && order.payment_method.id)
                          }
                        >
                          {order.payment_method && order.payment_method.payment_date ? order.payment_method.payment_date : '-'}
                        </div>
                      </div>
                      <div
                        className="table-content-sub"
                        style={{
                          margin: 0,
                          padding: 0,
                          paddingRight: 10,
                          width: 150,
                          marginRight: 300,
                          background: order.payment_method && order.payment_method.method_type && '#ABF0CF',
                        }}
                      >
                        <div
                          className="modal-box"
                          style={{
                            borderTopRightRadius: 5,
                            borderBottomRightRadius: 5,
                            cursor: REPORT_STATE_FORBIDDEN.includes(quotation.state) && 'not-allowed',
                            background: REPORT_STATE_FORBIDDEN.includes(quotation.state) && 'rgba(0,0,0,0.15)',
                          }}
                          onClick={
                            REPORT_STATE_FORBIDDEN.includes(quotation.state)
                              ? () => {}
                              : () => HandleClickModal(order.code, order.payment_method, order.id, order.payment_method && order.payment_method.id)
                          }
                        >
                          {order.payment_method && order.payment_method.set_debt_date && order.payment_method.method_type === 'yodl'
                            ? order.payment_method.set_debt_date
                            : '-'}
                        </div>
                      </div>
                      <div style={{ display: 'flex', marginLeft: 300 }}>
                        <div className="table-content-sub" style={{ width: 150, background: order.db_delivery_code && '#ABF0CF' }}>
                          <Tooltip
                            placement="bottom"
                            title={
                              order_categories.length === po_gr_is_null.length && order_categories.length !== 0
                                ? ''
                                : order.dc_id && order.dc_id > 0
                                ? ''
                                : 'แก้ไขได้เมื่อระบุข้อมูล PO/GR เสร็จสมบูรณ์'
                            }
                          >
                            {console.log('order', order)}
                            <div>
                              <Input
                                onChange={(e) => OnChangeOrder(e, 'do', order.id)}
                                disabled={
                                  order_categories.length === po_gr_is_null.length && order_categories.length !== 0
                                    ? false
                                    : order.dc_id && order.dc_id > 0
                                    ? false
                                    : true
                                }
                                style={{ borderRadius: 5 }}
                                value={order.db_delivery_code}
                              />
                            </div>
                          </Tooltip>
                        </div>
                        <div className="table-content-sub" style={{ width: 150, background: order.db_billing_code && '#ABF0CF' }}>
                          <Tooltip
                            placement="bottom"
                            title={
                              order_categories.length === po_gr_is_null.length && order_categories.length !== 0
                                ? ''
                                : order.dc_id && order.dc_id > 0
                                ? ''
                                : 'แก้ไขได้เมื่อระบุข้อมูล PO/GR เสร็จสมบูรณ์'
                            }
                          >
                            <div>
                              <Input
                                onChange={(e) => OnChangeOrder(e, 'billing', order.id)}
                                disabled={
                                  isDc ? true : order_categories.length === po_gr_is_null.length && order_categories.length !== 0 ? false : true
                                }
                                style={{ borderRadius: 5 }}
                                value={order.db_billing_code}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  )
                )
              })}

              {po_items_not_null && quotation_items.length !== 0 && (
                <>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', height: quotation_items.length === 1 && '100%' }}>
                      <div style={{ display: 'flex' }}>
                        <div>
                          {quotation_categories.map((category) => {
                            const item_in_this_cate = quotation_items.filter((item) => item.item_category_id === category.item_category_id)
                            const all_items_in_this_cate = quotation.quotation_items.filter(
                              (item) => item.item_category_id === category.item_category_id
                            )
                            const issued_in_cate = all_items_in_this_cate.filter((item) => item.issued === true)

                            let isDisabled = issued_in_cate.length > 0 ? true : category.item_category.dc_id === 1 ? true : false

                            return (
                              <div style={{ display: 'flex', flexDirection: 'rows', height: quotation_items.length === 1 && '100%' }}>
                                <div className="table-content-sub" style={{ flexDirection: 'column', width: 200 }}>
                                  <div style={{ height: 32 }}>{category.sys_name}</div>
                                </div>

                                <div
                                  className="table-content-sub"
                                  style={{ flexDirection: 'column', width: 120, background: category.informed_date && '#ABF0CF' }}
                                >
                                  <Tooltip
                                    placement="bottom"
                                    title={
                                      category.informed_date &&
                                      issued_in_cate.length > 0 &&
                                      'ยกเลิกแจ้งผลิตไม่ได้เนื่องจากสินค้าในหมวดหมู่มีการออกออเดอร์ไปแล้ว'
                                    }
                                  >
                                    <Button
                                      className={category.informed_date && issued_in_cate.length === 0 && 'approve-btn'}
                                      type="primary"
                                      style={{
                                        width: 110,
                                        borderRadius: 5,
                                        color: 'white',
                                        background: category.informed_date ? '#1D9F1A' : isDisabled && '#ABABAB',
                                        border: 'none',
                                      }}
                                      onClick={
                                        issued_in_cate.length === 0
                                          ? category.informed_date
                                            ? () =>
                                                OnClickCancelApproved({
                                                  vendor: quotation.client_station.name,
                                                  category: category.sys_name,
                                                  key: category.item_category_id,
                                                })
                                            : () =>
                                                OnClickApproved({
                                                  vendor: quotation.client_station.name,
                                                  category: category.sys_name,
                                                  key: category.item_category_id,
                                                })
                                          : () => {}
                                      }
                                      disabled={isDisabled}
                                    >
                                      <div className={category.informed_date && issued_in_cate.length === 0 && 'approved-text'}>
                                        {category.informed_date ? (
                                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 5 }}>
                                            <CheckOutlined /> แจ้งแล้ว
                                          </div>
                                        ) : (
                                          'แจ้งผู้ผลิต'
                                        )}
                                      </div>
                                      {issued_in_cate.length === 0 && category.informed_date && <div className="cancel-text">ยกเลิก</div>}
                                    </Button>
                                  </Tooltip>
                                </div>

                                <div style={{ display: 'flex', marginLeft: 1200 }}>
                                  <div className="table-content-sub" style={{ width: 150 }}>
                                    <Input style={{ borderRadius: 5 }} disabled={true} />
                                  </div>
                                  <div className="table-content-sub" style={{ width: 150 }}>
                                    <Input style={{ borderRadius: 5 }} disabled={true} />
                                  </div>
                                  <div className="table-content-sub" style={{ width: 150 }}>
                                    <Input style={{ borderRadius: 5 }} disabled={true} />
                                  </div>
                                  <div className="table-content-sub" style={{ width: 150 }}>
                                    <Input style={{ borderRadius: 5 }} disabled={true} />
                                  </div>
                                </div>
                                <div style={{ marginLeft: -1800 }}>
                                  {item_in_this_cate.map((item) => {
                                    return (
                                      <div style={{ display: 'flex', minHeight: '80px' }}>
                                        <div
                                          className="table-content-sub"
                                          style={{
                                            flexDirection: 'column',
                                            width: 300,
                                            background: item.name && '#ABF0CF',
                                            marginRight: 600,
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {item.code} - {item.name}
                                        </div>
                                        <div
                                          className="table-content-sub"
                                          style={{ flexDirection: 'column', width: 200, background: item.installation_date && '#ABF0CF' }}
                                        >
                                          <DatePicker
                                            onChange={(e) => HandleChangeItem(e, 'quotation', 'installation_date', null, item.id)}
                                            style={{ borderRadius: 5 }}
                                            value={item.installation_date ? moment(item.installation_date, 'YYYY/MM/DD') : null}
                                            format={'DD/MM/YYYY'}
                                            disabled={item.installation_date ? false : REPORT_STATE_FORBIDDEN.includes(quotation.state)}
                                          />
                                        </div>
                                        <div
                                          className="table-content-sub"
                                          style={{
                                            flexDirection: 'column',
                                            width: 100,
                                            alignItems: 'center',
                                            background: item.station_ready && '#ABF0CF',
                                          }}
                                        >
                                          <Switch
                                            onClick={(e) => HandleChangeItem(e, 'quotation', 'switch', null, item.id)}
                                            checked={item.station_ready ? true : false}
                                            style={{ width: 50 }}
                                            disabled={!item.installation_date || REPORT_STATE_FORBIDDEN.includes(quotation.state)}
                                          />
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <div className="table-content-sub" style={{ flexDirection: 'column', width: 150, textAlign: 'center', marginLeft: -900 }}>
                          <a href={`/quotations/${quotation.id}`} style={{ color: 'red', fontSize: 14, margin: 0, fontWeight: 'bold' }}>
                            ORDER PENDING
                          </a>
                        </div>
                        <div className="table-content-sub" style={{ width: 150 }}>
                          <Input style={{ borderRadius: 5 }} disabled={true} />
                        </div>
                        <div
                          className="table-content-sub"
                          style={{
                            padding: 0,
                            margin: 0,
                            borderRight: 'none',
                            paddingLeft: 10,
                            width: 150,
                          }}
                        >
                          <div
                            className="modal-box"
                            style={{
                              borderTopLeftRadius: 5,
                              borderBottomLeftRadius: 5,
                              margin: 0,
                              cursor: 'not-allowed',
                              background: 'rgba(0,0,0,0.15)',
                            }}
                          >
                            -
                          </div>
                        </div>
                        <div className="table-content-sub" style={{ margin: 0, padding: 0, paddingRight: 10, width: 150, marginRight: 300 }}>
                          <div
                            className="modal-box"
                            style={{ borderTopRightRadius: 5, borderBottomRightRadius: 5, cursor: 'not-allowed', background: 'rgba(0,0,0,0.15)' }}
                          >
                            -
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <Modal open={visible} footer={false} centered onCancel={HandleClickModal} className="modalstyle">
          <div style={{ fontWeight: 'bold', fontSize: 20 }}>แก้ไขข้อมูลการชำระเงินของออเดอร์</div>
          <div style={{ fontWeight: 'bold', fontSize: 24 }}>{currOrderID}</div>
          <div style={{ fontSize: 18, marginTop: 10 }}>ตัวเลือกการชำระ</div>
          <Radio.Group onChange={OnChangeValueRadio} style={{ width: '100%' }} value={bankDetail && bankDetail.method_type}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Radio value="yoda" style={{ width: '100%' }}>
                Direct Approve (YODA)
                {bankDetail && bankDetail.method_type === 'yoda' ? (
                  <>
                    <div style={{ width: '100%', display: 'flex', padding: '0px 0px 0px 20px', gap: 8, marginTop: 10 }}>
                      <div style={{ width: 85, textAlign: 'right' }}>ชื่อธนาคาร</div>
                      <Select
                        placeholder="กรุณาเลือกธนาคาร"
                        style={{ width: '100%', borderRadius: 5 }}
                        onChange={(e) => HandleChangeBank(e)}
                        value={bankDetail.bank_id}
                      >
                        {bank_list.map((bank) => {
                          return bank.is_active && <Option value={bank.id}>{bank.name}</Option>
                        })}
                      </Select>
                    </div>
                    <div style={{ width: '100%', display: 'flex', padding: '0px 0px 0px 20px', gap: 8, marginTop: 5 }}>
                      <div style={{ width: 85, textAlign: 'right' }}>เบอร์บัญชี</div>
                      <Input style={{ borderRadius: 5 }} onChange={(e) => HandleChangeBankNumber(e)} value={bankDetail.note} />
                    </div>
                  </>
                ) : null}
              </Radio>
              <Radio value="yodl" style={{ width: '100%' }}>
                Direct Link (YODL)
                {bankDetail && bankDetail.method_type === 'yodl' ? (
                  <>
                    <div style={{ width: '100%', display: 'flex', padding: '0px 0px 0px 20px', marginTop: 10, gap: 8 }}>
                      <div style={{ width: 85, textAlign: 'right' }}>ระบุ</div>
                      <Input style={{ borderRadius: 5 }} onChange={(e) => HandleChangeBankNumber(e)} value={bankDetail.note} />
                    </div>
                  </>
                ) : null}
              </Radio>
            </Space>
          </Radio.Group>
          <Divider />
          <div style={{ display: 'flex', gap: 15 }}>
            <div style={{ width: '100%', display: 'flex', marginBottom: 10, gap: 8 }}>
              <div style={{ width: 115, textAlign: 'right' }}>วันที่ชำระ</div>
              <DatePicker
                style={{ borderRadius: 5, width: 250 }}
                format={'DD/MM/YYYY'}
                onChange={(e) => HandleChangePaymentDate(e)}
                value={bankDetail && bankDetail.payment_date ? moment(bankDetail.payment_date, 'YYYY/MM/DD') : null}
              />
            </div>
            {bankDetail && bankDetail.method_type === 'yodl' && (
              <div style={{ width: '100%', display: 'flex', marginBottom: 10, gap: 8 }}>
                <div style={{ width: 115, textAlign: 'right' }}>วันที่แจ้งตั้งหนี้</div>
                <DatePicker
                  style={{ borderRadius: 5, width: 250 }}
                  format={'DD/MM/YYYY'}
                  onChange={(e) => HandleChangeDebtDate(e)}
                  value={bankDetail && bankDetail.set_debt_date ? moment(bankDetail.set_debt_date, 'YYYY/MM/DD') : null}
                />
              </div>
            )}
          </div>
          {bankDetail && bankDetail.method_type === 'yodl' && (
            <div style={{ width: '100%', display: 'flex', marginBottom: 10, gap: 8 }}>
              <div style={{ width: 115, textAlign: 'right' }}>หลักฐานการจ่ายเงิน</div>
              <Upload
                customRequest={(e) => CustomAntdUpaloadRequest(e)}
                fileList={imageList}
                onRemove={OnRemoveImage}
                listType="picture"
                onChange={onChangeImageUpload}
              >
                {imageList.length === 0 && (
                  <Button icon={<UploadOutlined />} style={{ borderRadius: 5, display: 'flex', alignItems: 'center' }}>
                    Choose File
                  </Button>
                )}
              </Upload>
            </div>
          )}
          <div style={{ display: 'flex', gap: 5, justifyContent: 'right' }}>
            <Button
              onClick={() => HandleOnFinishPayment(currOrderID)}
              htmlType="submit"
              type="primary"
              style={{ background: '#0D8D21', border: 'none', color: 'white', borderRadius: 5, width: 80, height: 39, marginRight: 7, fontSize: 16 }}
            >
              Save
            </Button>
            <Button
              style={{ background: 'red', color: 'white', borderRadius: 5, width: 80, height: 39, marginRight: 7, fontSize: 16, border: 'none' }}
              onClick={HandleClickModal}
            >
              Cancel
            </Button>
          </div>
        </Modal>
        <Modal open={approvedVisible} centered footer={false} closable={false} onCancel={() => OnClickApproved()} className="modalstyle">
          <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 10 }}>
            <div style={{ fontWeight: 'bold', fontSize: 24 }}>ยืนยันการแจ้งผู้ผลิต</div>
            <div style={{ fontWeight: 'bold', fontSize: 16 }}>
              สถานี: <span style={{ color: 'blue' }}>{currentApproved.vendor}</span>
            </div>
            <div style={{ fontWeight: 'bold', fontSize: 16 }}>
              หมวดหมู่: <span style={{ color: 'green' }}>{currentApproved.category}</span>
            </div>
            <div style={{ display: 'flex', gap: 10, marginTop: 10, justifyContent: 'center' }}>
              <Button style={{ width: 150, background: 'green', color: 'white', borderRadius: 5 }} onClick={(e) => OnConfirmApproved()}>
                ยืนยัน
              </Button>
              <Button style={{ width: 150, background: 'red', color: 'white', borderRadius: 5 }} onClick={() => OnClickApproved()}>
                ยกเลิก
              </Button>
            </div>
          </div>
        </Modal>
        <Modal open={cancelApprovedVisible} centered footer={false} closable={false} onCancel={() => OnClickCancelApproved()} className="modalstyle">
          <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 10 }}>
            <div style={{ fontWeight: 'bold', fontSize: 24, color: 'red' }}>ยกเลิกการแจ้งผู้ผลิต</div>
            <div style={{ fontWeight: 'bold', fontSize: 18 }}>คุณแน่ใจหรือไม่ที่ต้องการยกเลิกการแจ้งผู้ผลิต ?</div>
            <div style={{ fontWeight: 'bold', fontSize: 16 }}>
              สถานี: <span style={{ color: 'blue' }}>{currentCancelApproved.vendor}</span>
            </div>
            <div style={{ fontWeight: 'bold', fontSize: 16 }}>
              หมวดหมู่: <span style={{ color: 'green' }}>{currentCancelApproved.category}</span>
            </div>
            <div style={{ display: 'flex', gap: 10, marginTop: 10, justifyContent: 'center' }}>
              <Button style={{ width: 150, background: 'green', color: 'white', borderRadius: 5 }} onClick={(e) => OnConfirmApproved('cancel')}>
                ใช่, ยืนยัน
              </Button>
              <Button style={{ width: 150, background: 'red', color: 'white', borderRadius: 5 }} onClick={() => OnClickCancelApproved()}>
                ไม่ใช่
              </Button>
            </div>
          </div>
        </Modal>
        <Modal className="modalstyle" open={isCancelVisible} centered footer={false} closable={false} onCancel={() => OnClickCancelQuotation()}>
          <Form onFinish={onHandleCancelQuotation}>
            <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 10 }}>
              {!isDeleteQuotation ? (
                <div style={{ fontWeight: 'bold', fontSize: 24, color: 'red' }}>ยกเลิกใบเสนอราคา</div>
              ) : (
                <div style={{ fontWeight: 'bold', fontSize: 24, color: 'red' }}>ลบใบเสนอราคา</div>
              )}
              <Divider style={{ margin: '5px 0px' }} />
              {!isDeleteQuotation ? (
                <div>
                  {quotation.state === QUO_STATES.order_issued_partially.name && (
                    <div style={{ marginBottom: 10, fontSize: 18 }}>
                      <span className="font-weight-bold color-success">[กรณียกเลิกทั้งหมด]</span>
                      <br />
                      Order ที่เกี่ยวข้อง จะถูกยกเลิกทั้งหมดด้วย
                      <br />
                      <span className="font-weight-bold color-red">[กรณียกเลิกสินค้าที่เหลืออยู่]</span>
                      <br />
                      และถ้ามีสินค้าที่ออกไปแล้วในหมวดหมู่เดียวกัน อาจจะทำให้ค่าบริการไม่ถูกต้องได้
                    </div>
                  )}
                  <div style={{ fontSize: 18, marginBottom: 10 }}>คุณยืนยันที่จะ 'ยกเลิก' ใบเสนอราคานี้ใช่หรือไม่ ?</div>
                  <Form.Item
                    name="reason"
                    style={{ marginBottom: -8 }}
                    required={true}
                    label="เหตุผลที่ยกเลิก"
                    rules={[{ required: true, message: 'กรุณากรอกเหตุผลที่ยกเลิก !' }]}
                  >
                    <TextArea placeholder="เหตุผลที่ยกเลิก..." autoSize={{ maxRows: 3, minRows: 3 }} />
                  </Form.Item>
                </div>
              ) : (
                <div style={{ fontSize: 18 }}>
                  <div>คุณยืนยันที่จะ 'ลบ' ใบเสนอราคานี้ใช่หรือไม่ ?</div>
                  <div>หากทำการลบจะไม่สามารถนำกลับมาใช้งานได้อีก !</div>
                </div>
              )}

              <Form.Item>
                <div style={{ display: 'flex', gap: 10, marginTop: 10, justifyContent: 'center' }}>
                  <Button
                    htmlType="submit"
                    style={{ width: 180, background: 'green', color: 'white', borderRadius: 5 }}
                    onClick={() => onClickCancelQuotationPo(true)}
                  >
                    {quotation.state === QUO_STATES.order_issued_partially.name ? 'ใช่, ยกเลิกทั้งหมด' : 'ใช่, ยืนยัน'}
                  </Button>
                  {quotation.state === QUO_STATES.order_issued_partially.name && (
                    <Button
                      htmlType="submit"
                      style={{ width: 180, background: 'red', color: 'white', borderRadius: 5 }}
                      onClick={() => onClickCancelQuotationPo(false)}
                    >
                      ใช่, ยกเลิกสินค้าที่เหลือ
                    </Button>
                  )}
                  <Button style={{ width: 180, background: 'gray', color: 'white', borderRadius: 5 }} onClick={() => OnClickCancelQuotation()}>
                    ไม่ใช่
                  </Button>
                </div>
              </Form.Item>
            </div>
          </Form>
        </Modal>
      </>
    )
  )
}

import React from 'react';
import DatePicker from "react-datepicker";
import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { PAYMENT_METHODS, API_RESOURCES, FORMAT_APP_DATE } from '../../constants.js';

// Models

// Component
import { OrderFormOtherInfo } from './OrderFormOtherInfo'
import ErrorMessage from '../global/ErrorMessage'
import FileUploader from '../global/FileUploader.js';


export class OrderFormInvoice extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    let { order, disabled } = this.props
    return (
      <React.Fragment>
      {/* <div className="color-red mb-3">{warning}</div> */}

      <h5 className="pb-1">ใบแจ้งหนี้</h5>
      <div className="card-box p-3 mt-1">
        <div className="row">
          <div className="col-4 col-md-2 pr-0 mt-2"><label>เลขที่ ใบแจ้งหนี้</label></div>
          <div className="col-8 col-md-4 date-picker-wrapper mt-2">
            {order.db_billing_code}
          </div>
          {/* <div className="col-4 col-md-2 pr-0 mt-2"><label>วันที่ได้รับ</label></div>
          <div className="col-8 col-md-4 date-picker-wrapper mt-2">
            <DatePicker
              className="form-control"
              dateFormat={FORMAT_APP_DATE}
              selected={new Date(order.delivery_date)}
              // onChange={date => this.onChangeDeliveryDate(date)}
              disabled={disabled}
            />
            <ErrorMessage errorKey={'delivery_date'} errorMessages={this.state.errors}/>
          </div> */}
          <div className="col-4 col-md-2 pr-0 mt-2"><label>วันที่ส่ง</label></div>
          <div className="col-8 col-md-4 date-picker-wrapper mt-2">
            <DatePicker
              className="form-control"
              dateFormat={FORMAT_APP_DATE}
              selected={order.invoice_issued_date ? new Date(order.invoice_issued_date) : null}
              onChange={date => this.props.onChangeOrderDate(date, 'invoice_issued_date')}
              disabled={disabled}
            />
            <ErrorMessage errorKey={'po_date'} errorMessages={this.state.errors}/>
          </div>
        </div>

        <div className="row">
          <div className="col-4 col-md-2 pr-0 mt-2"><label>เลขพัสดุ</label></div>
          <div className="col-8 col-md-10 mt-2">
            <input className="form-control" id="order-invoice-tracking-no" name="invoice_tracking_no"
                    onChange={this.props.onChangeOrderDetails} value={order.invoice_tracking_no} disabled={disabled}
                    ></input>
          </div>
        </div>
      </div>

      <div className="card-box p-3 mt-1">
        <div className="row">
          <div className="col-4 col-md-2 pr-0 mt-2"><label>วันที่ได้รับกลับ</label></div>
          <div className="col-8 col-md-4 date-picker-wrapper mt-2">
            <DatePicker
              className="form-control"
              dateFormat={FORMAT_APP_DATE}
              selected={order.invoice_received_date ? new Date(order.invoice_received_date) : null}
              onChange={date => this.props.onChangeOrderDate(date, 'invoice_received_date')}
              disabled={disabled}
            />
            <ErrorMessage errorKey={'delivery_date'} errorMessages={this.state.errors}/>
          </div>
        </div>
        <div className="row">
          <div className="col-4 col-md-2 pr-0 mt-2"><label>เอกสารที่ได้รับกลับ</label></div>
          <div className="col-8 col-md-10 date-picker-wrapper mt-2">
            <FileUploader
              file_category={`purchasing_orders/${order.id}/invoice`}
              url={order.s3_invoice_url}
              onUploadNewFile={this.props.onUploadInvoice}
              disabled={disabled}
              key={this.state.image_uploader_key + this.props.resetKeyNote}
            />
          </div>
        </div>
      </div>

      {/* Other Info */}
      <OrderFormOtherInfo
        page_nb={this.props.page_nb}
        note={this.props.order.order_notes.filter(elm => elm.page_nb === this.props.page_nb)[0]}
        onChangeNote = {this.props.onChangeNote}
        disabled={this.props.disabled}
        key={this.props.resetKeyNote}
      />
      <div className="block-info-buffer-box"></div>
      </React.Fragment>
    );
  }
}
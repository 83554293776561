import React from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import axios from "axios";
import Spinner from "../../global/Spinner";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
import _ from "lodash";
import moment from "moment";
import { withRouter } from "react-router-dom";
import SearchBar from "../../global/SearchBar";
import { IconSorting } from "../../global/IconSorting";
import { formatApiDate } from "../../../functions/Date";
import { getDataRange } from "../../../functions/Excel";
import { objectIsEmpty } from "../../../functions/Object";
import ExportExcel from "../../global/ExportExcel";
import {
  FORMAT_DATE_EXCEL_IN,
  FORMAT_DATE,
  CLONE_OPTIONS,
  PER_PAGE,
  QUO_STATES,
  FORMAT_APP_DATE,
  APP_TITLE_DC_INBOUND_ORDER,
  DC_INBOUND_ORDER_STATES,
  DC_INBOUND_ORDER_PROCESS,
} from "../../../constants";
import {
  ExportProcurementModal,
  ImportProcurementModal,
} from "./SubComponents";
import "../DcInboundOrder.css";
// Functions
import { formatDate } from "../../../functions/Date";
import { uniqueKey } from "../../../functions/Random";
import { getHeaderTotalPageCount } from "../../../functions/Response";
// Components
import ModalConfirmation from "../../global/ModalConfirmation";
import { Checkbox } from "antd";
import { toExcelMassUploadPoInbound } from "../../../models/Orders";

const OPTIONS_STATES = Object.keys(DC_INBOUND_ORDER_STATES).map((key) => {
  return {
    value: DC_INBOUND_ORDER_STATES[key].name,
    label: DC_INBOUND_ORDER_STATES[key].name,
  };
});

const OPTIONS_PROCESS = Object.keys(DC_INBOUND_ORDER_PROCESS).map((key) => {
  return {
    value: DC_INBOUND_ORDER_PROCESS[key].name,
    label: DC_INBOUND_ORDER_PROCESS[key].name,
  };
});

const OPTIONS_SORT = [
  { value: 1, label: "Draft" },
  { value: 2, label: "Pending delivery" },
  { value: 3, label: "Partially received" },
  { value: 4, label: "Received" },
  { value: 5, label: "Cancelled" },
];

let searchParams = {};

class DcInboundOrderIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      selected_state: props.state
        ? OPTIONS_SORT.filter((elm) => props.state.indexOf(elm.value) > -1)
        : null,
      selected_process: props.state
        ? OPTIONS_PROCESS.filter((elm) => props.state.indexOf(elm.value) > -1)
        : null,
      selected_id: "",
      selected_sorting: "created_at",
      selected_sorting_reversed: true,
      selected_date_form: props.link_date_from
        ? moment(props.link_date_from).toDate()
        : "",
      selected_date_to: props.link_date_to
        ? moment(props.link_date_to).toDate()
        : "",
      modal_preview: false,
      is_cloning: false,
      clone_quo: null,
      selected_preview_order: null,
      collapsed_id: null,

      reFiltering: true,
      dc_inbound_orders: [],
      filter: null,

      // pagination
      page: 0,
      per_page: PER_PAGE,
      page_count: 1,
      resetKey: uniqueKey(),

      onExporting: false,
      onImporting: false,
      is_displaying_basket: false,
      selectToExporting: {},
      export_orders: null,
      import_error: null,
      import_data: null,
      filter_state: null,

      selected_date_form: "",
      selected_date_to: "",
    };

    this.handlePageClick = this.handlePageClick.bind(this);
    this.onClickExport = this.onClickExport.bind(this);
    this.handleProcurementModal = this.handleProcurementModal.bind(this);
    this.handleDisplayingBasket = this.handleDisplayingBasket.bind(this);
    this.onSelectItemToExport = this.onSelectItemToExport.bind(this);
  }

  componentDidMount() {
    if (this.props.location.search) this.getPathParams();
    else this.getInboundList();
  }

  componentDidUpdate() {
    if (this.state.reFiltering) {
      this.getInboundList(this.state.filter);
    }
  }

  onClickExport(filter) {
    this.setState({
      selectToExporting: {},
      export_orders: null,
      filter: filter,
      reFiltering: true,
    });
  }

  onChangeFromDate = (date) => {
    if (date) {
      searchParams["start_date"] = moment(date).format("MM/DD/YYYY");
      this.setState({ selected_date_form: date });
    } else {
      delete searchParams["start_date"];
      this.setState({ selected_date_form: "" });
    }
  };

  onChangeToDate = (date) => {
    if (date) {
      searchParams["end_date"] = moment(date).format("MM/DD/YYYY");
      this.setState({ selected_date_to: date });
    } else {
      delete searchParams["end_date"];
      this.setState({ selected_date_to: "" });
    }
  };

  getInboundList(filter = null) {
    const {
      selected_sorting,
      selected_sorting_reversed,
      per_page,
      page,
      filter_state,
      query,
      selected_date_form,
      selected_date_to,
    } = this.state;
    let range_start = page * per_page;
    let sort_dir = selected_sorting_reversed ? "DESC" : "ASC";
    let options = {
      sort: JSON.stringify([selected_sorting, sort_dir]),
      filter: filter ? filter : {},
      range: JSON.stringify([range_start, range_start + per_page - 1]),
      all_output: true,
    };

    if (selected_date_form || selected_date_to) {
      let start_date = selected_date_form
        ? moment(selected_date_form).format(FORMAT_DATE)
        : null;
      let end_date = selected_date_to
        ? moment(selected_date_to).format(FORMAT_DATE)
        : null;
      if (start_date && !end_date) {
        end_date = "*";
      }
      if (!start_date && end_date) {
        start_date = "*";
        end_date = end_date;
      }
      options.filter.created_at = [start_date, end_date];
    }

    if (filter_state) {
      options.filter.state = filter_state;
    }

    if (query) {
      options.filter.code = query;
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/inbound`, { params: options })
      .then((response) => {
        let page_count = Math.ceil(
          response.headers["x-total-count"] / per_page
        );

        if (response.data.code === 200) {
          this.setState({
            filter: null,
            reFiltering: false,
            page_count: page_count,
            dc_inbound_orders: response.data.data,
          });
        }

        if (filter) {
          this.setState({ onExporting: !this.state.onExporting, filter: null });
        }

        this.setState({ reFiltering: false });
      })
      .catch((err) => {
        this.setState({
          reFiltering: false,
          // onExporting: !this.state.onExporting,
        });
      });
  }

  getPathParams() {
    const query =
      new URLSearchParams(this.props.location.search).get("query") || "";
    const state =
      new URLSearchParams(this.props.location.search).get("state") || null;
    const start_date =
      new URLSearchParams(this.props.location.search).get("start_date") || "";
    const end_date =
      new URLSearchParams(this.props.location.search).get("end_date") || "";
    const page =
      new URLSearchParams(this.props.location.search).get("page") || 0;

    let search_state = [];
    if (query !== "") searchParams["query"] = query;
    if (state) searchParams["state"] = state;
    if (start_date) searchParams["start_date"] = start_date;
    if (end_date) searchParams["end_date"] = end_date;
    if (page !== 0) searchParams["page"] = page;

    if (state) {
      state.split(",").forEach((search) => {
        let findState = OPTIONS_SORT.find((state) => state.value == search);
        search_state.push(findState);
      });
    }

    this.setState({
      selected_date_form: start_date ? moment(start_date).toDate() : "",
      selected_date_to: end_date ? moment(end_date).toDate() : "",
      selected_state: search_state,
      query: query,
      page: page ? parseInt(page) - 1 : 0,
      filter_state: state ? state.split(",") : null,
    });
  }

  handlePageClick(data) {
    if (data.selected !== 0) searchParams["page"] = data.selected + 1;
    else delete searchParams["page"];

    this.setState({ reFiltering: true, page: data.selected });
  }

  tableHeader() {
    const { onExporting } = this.state;

    return (
      <div className="card-box mt-1 p-3 d-none d-md-block mt-3">
        <div className="row">
          <div className="col-12 d-flex flex-column flex-sm-row align-items-center">
            {onExporting && (
              <div className="col-custom-20 col-custom-sm-100 px-1 py-2">
                Select
              </div>
            )}
            <div className="col-custom-20 col-custom-sm-100 px-1 py-2">
              {this.headerColWithSortIcon("วันที่ Order", "created_at")}
            </div>
            <div className="col-custom-26 col-custom-sm-100 px-1 py-2">
              {this.headerColWithSortIcon("เลขที่ใบสั่งซื้อ", "code")}
            </div>
            <div className="col-custom-18 col-custom-sm-100 px-1 py-2">
              สถานะ{" "}
            </div>
            <div className="col-custom-18 col-custom-sm-100 px-1 py-2">
              ยอดรวม{" "}
            </div>
            <div className="col-custom-18 col-custom-sm-100 px-1 py-2"></div>
          </div>
        </div>
      </div>
    );
  }

  handleDisplayingBasket = (enable) => {
    this.setState({ is_displaying_basket: enable });
  };

  displayExportBasket = () => {
    const selectedOrderCount = Object.keys(this.state.selectToExporting).length;
    if (selectedOrderCount > 0) {
      return (
        <div
          className="order-import-basket-wrapper"
          onClick={() => {
            this.handleDisplayingBasket(true);
          }}
        >
          <i className="icon-folder-basket"></i>
          <div className="count-nb">{selectedOrderCount}</div>
        </div>
      );
    }
  };

  onShowDcInboundOrderDetails(id) {
    if (id !== this.state.collapsed_id) {
      this.setState({ collapsed_id: id });
    } else {
      this.setState({ collapsed_id: null });
    }
  }

  isCloned(state) {
    return [QUO_STATES.cloned_to_edit.name, QUO_STATES.suspended.name].includes(
      state
    );
  }

  getAllItemInLot(lot) {
    let lot_qty = 0;

    lot.forEach((item) => {
      lot_qty = lot_qty + item.qty;
    });

    return lot_qty;
  }

  addSearchParams(params, path = this.props.location.pathname) {
    let searchParams = "";
    Object.keys(params).forEach((key, index) => {
      searchParams =
        searchParams +
        `${Object.keys(params).length > 1 && index !== 0 ? "&" : ""}${key}=${
          params[key]
        }`;
    });

    this.props.history.replace({ pathname: `${path}`, search: searchParams });
  }

  onClickDcOrder(id) {
    this.addSearchParams(searchParams, `/dc_inbound_orders/${id}`);
  }

  gotoEditPage(id) {
    this.props.history.push({
      pathname: `/dc_inbound_orders/${id}`,
    });
  }

  export_orders_to_excel(selected_orders) {
    let order_arr = [];
    for (let key in selected_orders) {
      order_arr.push(selected_orders[key]);
    }

    return toExcelMassUploadPoInbound(order_arr);
  }

  onSelectItemToExport(order) {
    const { selectToExporting } = this.state;
    let mbom_list = [];

    if (selectToExporting[order.id]) {
      delete selectToExporting[order.id];
    } else {
      order.inbound_order_items.forEach((ib_item) => {
        if (ib_item.is_mbom) {
          let findCategory_mbom = order.inbound_order_item_category.find(
            (cate) => cate.item_category_id === ib_item.item_category_id
          );
          if (findCategory_mbom) {
            findCategory_mbom.contract.contract_item_categories.forEach(
              (contract_cate_items) => {
                contract_cate_items.contract_items.forEach((item) => {
                  const isBombInContract =
                    ib_item.contract_item.item.bom_item_ids.find(
                      (id) => id === item.item_id
                    );

                  if (isBombInContract) {
                    const item_params = {
                      ...item,
                      is_mbom: false,
                      from_mbom: true,
                      item_category_id: ib_item.item_category_id,
                      inbound_order_item_lot: [],
                      expense_ratio: ib_item.expense_ratio,
                      expense_ratio_arr: ib_item.expense_ratio_arr,
                      contract_price: ib_item.contract_price,
                      contract_item_id: ib_item.contract_item_id,
                      contract_item: ib_item.contract_item,
                      code: item.code ? item.code : ib_item.code,
                      item_category: ib_item.item_category,
                      installation_date: ib_item.installation_date,
                      warranty_code: ib_item.warranty_code,
                      qty: ib_item.qty,
                    };

                    mbom_list.push(item_params);
                  }
                });
              }
            );
          }
        }
      });
      if (mbom_list.length > 0)
        order.inbound_order_items = [
          ...order.inbound_order_items,
          ...mbom_list,
        ];
      selectToExporting[order.id] = order;
    }

    this.setState({
      export_orders: this.export_orders_to_excel(selectToExporting),
      selectToExporting: selectToExporting,
    });
  }

  onCloseProcurement = (isClear = false) => {
    if (isClear) {
      this.setState({
        selectToExporting: {},
        onExporting: {},
        export_orders: null,
      });
    } else {
      this.setState({
        selectToExporting: {},
        onExporting: false,
        reFiltering: true,
        export_orders: null,
      });
    }
  };

  orderItems() {
    let { dc_inbound_orders, collapsed_id, onExporting, selectToExporting } =
      this.state;
    let items = dc_inbound_orders.map((elm, index) => {
      return (
        <div
          className="card-box mt-2 px-3 pt-3 pb-2 link-no-hover cursor-pointer"
          key={elm.order_no + index}
        >
          <div className="row mb-2">
            {/* md */}
            <div
              className="col-12 flex-custom-md flex-custom-sm-none flex-column flex-sm-row"
              onClick={() =>
                onExporting
                  ? this.onSelectItemToExport(elm)
                  : this.onClickDcOrder(elm.id)
              }
            >
              {onExporting && (
                <div className="col-custom-20 col-custom-sm-100 px-1">
                  <div className="col-break-word">
                    <Checkbox checked={selectToExporting[elm.id]} />
                  </div>
                </div>
              )}
              <div className="col-custom-20 col-custom-sm-100 px-1">
                <div className="col-break-word">{`${
                  elm.created_at ? formatDate(elm.created_at) : "-"
                }`}</div>
              </div>
              <div className="col-custom-26 col-custom-sm-100 px-1">
                <div className="col-break-word">{`${
                  elm.code ? elm.code : "-"
                }`}</div>
              </div>
              <div className="col-custom-18 col-custom-sm-100 px-1">
                <div className="col-break-word">{`${
                  elm.state ? elm.state : ""
                }`}</div>
              </div>
              <div className="col-custom-18 col-custom-sm-100 px-1">
                <div className="col-break-word">{`${
                  elm.total_cost
                    ? (elm.total_cost * 1.07).toLocaleString()
                    : "0"
                }`}</div>
              </div>
              <div className="col-custom-18 col-custom-sm-100 px-1 flex-row justify-content-end">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm mr-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.onShowDcInboundOrderDetails(elm.id);
                  }}
                >
                  {collapsed_id === elm.id ? "ปิด" : "เปิด"}
                </button>
              </div>
            </div>

            {/* sm */}
            <div
              className="col-12 flex-custom-sm flex-custom-md-none flex-column flex-sm-row cursor-pointer"
              onClick={() => this.onClickDcOrder(elm.id)}
            >
              <div className="col-custom-20 col-custom-sm-100 px-1">
                <div className="col-break-word">
                  <span className="text-bold pr-2">วันที่ Order</span>
                  <span className="text-bold pr-2">:</span>
                  {`${elm.created_at ? formatDate(elm.created_at) : "-"}`}
                </div>
              </div>

              <div className="col-custom-26 col-custom-sm-100 px-1">
                <div className="col-ellipsis">
                  <span className="text-bold pr-2">เลขที่ใบสั่งซื้อ</span>
                  <span className="text-bold pr-2">:</span>
                  {`${elm.code ? elm.code : "-"}`}
                </div>
              </div>

              <div className="col-custom-18 col-custom-sm-100 px-1">
                <div className="col-break-word">
                  <span className="text-bold pr-2">สถานะ</span>
                  <span className="text-bold pr-2">:</span>
                  {`${elm.state ? elm.state : ""}`}
                </div>
              </div>

              <div className="col-custom-18 col-custom-sm-100 px-1">
                <div className="col-break-word">
                  <span className="text-bold pr-2">ยอดรวม</span>
                  <span className="text-bold pr-2">:</span>
                  {`${
                    elm.total_cost
                      ? (elm.total_cost * 1.07).toLocaleString()
                      : "0"
                  }`}
                </div>
              </div>

              <div className="col-custom-10 col-custom-sm-100 flex-row justify-content-end mt-1">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm mr-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.onShowDcInboundOrderDetails(elm.id);
                  }}
                >
                  {collapsed_id === elm.id ? "ปิด" : "เปิด"}
                </button>
                {/* <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    // this.onShowDcInboundOrderDetails(elm.id);
                  }}
                >
                  Clone
                </button> */}
              </div>
            </div>
          </div>
          <div
            className={`div-hide-scrollbar order-data-collapse ${
              collapsed_id === elm.id ? "show pt-3 pb-0" : ""
            }`}
          >
            <div className="collapse-container">
              <div className="collapse-content-price">
                <p className="main-type">ราคารวม :</p>
                <p>{elm.total_cost}</p>
                <p>บาท</p>
              </div>
              {elm.inbound_order_items.map((item, index) => {
                return (
                  <div className="collapse-content">
                    <p className="main-type">
                      {index === 0 && "รายการสินค้า :"}
                    </p>
                    <div className="collapse-item-content">
                      <p>{`${item.code}`}</p>
                      <p>{`-`}</p>
                      <p>{`${item.name}`}</p>
                      <p>{`(${this.getAllItemInLot(
                        item.inbound_order_item_lot
                      )}/${item.qty})`}</p>
                    </div>
                  </div>
                );
              })}
              {elm.inbound_order_item_pos.map((po, index) => {
                return (
                  <div className="collapse-content">
                    <p className="main-type">{index === 0 && "PO :"}</p>
                    <div className="collapse-item-content">
                      <p>{po.contract.name}</p>
                      <p>{po.contract.code ? po.contract.code : "-"}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    });
    return items;
  }

  displayCloneOption() {
    if (this.state.is_cloning) {
      const content = (
        <React.Fragment>
          <div>กรุณาเลือก option ในการโคลน</div>
          <br />
          <div>
            <b className="text-success">โคลนเพื่อสร้างใบเสนอราคาใหม่ :</b>{" "}
            เพื่อสร้างใบเสนอราคาใหม่ ที่คล้ายกัน โดยมีข้อมูลคล้ายเดิมทั้งหมด
          </div>
          <br />
          <div>
            <b className="text-danger">โคลนเพื่อแก้ไข :</b>{" "}
            ใบเสนอราคานี้จะเปลี่ยนเป็นสถานะ suspended
            และถูกโคลนเป็นใบเสนอราคาใหม่ในสถานะ cloned to edit
            โดยมีข้อมูลทั้งหมดดังเดิม หากแก้ไขเรียบร้อย จะสามารถกด Submit and
            Approve ใบเสนอราคา เพื่อแสดงเป็นใบเสนอราคาใหม่ในสถานะ approve และจะ
            <i>
              <b className="text-danger">
                ยกเลิกใบเสนอราคาเดิม รวมถึงออเดอร์ที่เกี่ยวข้องด้วย
              </b>
            </i>
          </div>
          <div></div>
        </React.Fragment>
      );
      return (
        <ModalConfirmation
          isOpen={true}
          title="ต้องการโคลนใบเสนอราคาที่ approved แล้ว?"
          content={content}
          btnOK="เพื่อสร้างใหม่"
          btnDanger="เพื่อแก้ไข"
          onConfirm={() =>
            this.handleCloneQuotationOption(
              this.state.clone_quo,
              CLONE_OPTIONS.clone_to_new
            )
          }
          onCustomAction={() =>
            this.handleCloneQuotationOption(
              this.state.clone_quo,
              CLONE_OPTIONS.clone_to_edit
            )
          }
          onCancel={() => this.handleCloneModal(false)}
        />
      );
    }
  }

  onClickSort(type) {
    let new_toggle =
      this.state.selected_sorting === type
        ? !this.state.selected_sorting_reversed
        : false;
    this.setState({
      selected_sorting: type,
      selected_sorting_reversed: new_toggle,
      reFiltering: true,
    });
  }

  onClickSortUp(active, type) {
    let sortType = active ? type : "";
    this.setState({
      selected_sorting: sortType,
      selected_sorting_reversed: false,
      reFiltering: true,
    });
  }

  onClickSortDown(active, type) {
    let sortType = active ? type : "";
    this.setState({
      selected_sorting: sortType,
      selected_sorting_reversed: true,
      reFiltering: true,
    });
  }

  headerColWithSortIcon(text, sortText) {
    let active = this.state.selected_sorting === sortText;
    let sort_up = active && !this.state.selected_sorting_reversed;
    let sort_down = active && this.state.selected_sorting_reversed;

    return (
      <div
        className="d-flex flex-row cursor-pointer align-items-center"
        onClick={() => this.onClickSort(sortText)}
      >
        <div className="col-break-word text-bold">{text}</div>
        <IconSorting
          onClickUp={this.onClickSort}
          onClickDown={this.onClickSortDown}
          type={sortText}
          key={Math.random() * 100}
          activeUp={sort_up ? true : undefined}
          activeDown={sort_down ? true : undefined}
        />
      </div>
    );
  }

  displayOnExport() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          gap: 5,
        }}
      >
        <div className="ml-auto">
          <ExportExcel
            data={[this.state.export_orders]}
            content={`Export (${
              Object.keys(this.state.selectToExporting).length
            })`}
            filename={`OR_Mass_Upload_Auto_DC_PO_${moment().format(
              "DD-MM-YYYY"
            )}`}
            // customClass={btn_class}
            onExport={() => this.onCloseProcurement(false)}
            disabled={Object.keys(this.state.selectToExporting).length === 0}
          />
        </div>
        <button
          type="button"
          className="btn btn-danger ml-2"
          onClick={() => this.onCloseProcurement(true)}
        >
          ล้าง
        </button>
        <button
          type="button"
          className="btn btn-outline-secondary ml-2"
          onClick={() => this.onClickExport({})}
        >
          Close
        </button>
      </div>
    );
  }

  onClickImport() {
    this.setState({
      onImporting: !this.state.onImporting,
    });
  }

  handleProcurementModal(importing) {
    this.setState({ onImporting: importing });
  }

  onImportFile = (e) => {
    const file = e.target.files[0];

    if (file) {
      var reader = new FileReader();
      reader.onload = (e) => {
        try {
          var data = e.target.result;
          let readedData = XLSX.read(data, { type: "binary" });
          const wsname = readedData.SheetNames[0];
          const ws = readedData.Sheets[wsname];
          ws["!ref"] = getDataRange(ws);

          /* Convert array to json*/
          const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

          let start_row = 5;
          let contract_code_col = 0;
          let client_name_col = 6;
          let order_code_col = 26;
          let po_code_col = 46;
          let gr_code_col = 48;
          let delivery_no_col = 53;
          let delivery_date_col = 54;
          let billing_no_col = 55;
          let billing_date_col = 56;
          let invoice_issued_date_col = 57;
          let invoice_tracking_no_col = 58;
          let today = formatApiDate(new Date());
          let import_data = {};
          for (let index in dataParse) {
            if (index < start_row) {
              continue;
            }
            if (!objectIsEmpty(dataParse[index])) {
              let row = dataParse[index];
              if (!(row[order_code_col] in import_data)) {
                let order_code_arr = row[order_code_col]
                    ? row[order_code_col].split("-")
                    : [],
                  order_code = order_code_arr[0];
                import_data[row[order_code_col]] = {
                  order_code: "" + order_code,
                  client_name: row[client_name_col],
                  contract_code: "" + row[contract_code_col],
                  po_code: row[po_code_col] ? "" + row[po_code_col] : null,
                  gr_code: row[gr_code_col] ? "" + row[gr_code_col] : null,
                  po_date: today,
                  gr_date: today,
                };
                if (row[delivery_no_col]) {
                  import_data[row[order_code_col]].db_delivery_code =
                    row[delivery_no_col];
                  import_data[row[order_code_col]].db_delivery_date = row[
                    delivery_date_col
                  ]
                    ? moment(
                        row[delivery_date_col],
                        FORMAT_DATE_EXCEL_IN
                      ).format(FORMAT_DATE)
                    : today;
                }
                if (row[billing_no_col]) {
                  import_data[row[order_code_col]].db_billing_code =
                    row[billing_no_col];
                  import_data[row[order_code_col]].db_billing_date = row[
                    billing_date_col
                  ]
                    ? moment(
                        row[billing_date_col],
                        FORMAT_DATE_EXCEL_IN
                      ).format(FORMAT_DATE)
                    : today;
                }
                if (row[invoice_issued_date_col]) {
                  import_data[row[order_code_col]].invoice_issued_date = moment(
                    row[invoice_issued_date_col],
                    FORMAT_DATE_EXCEL_IN
                  ).format(FORMAT_DATE);
                }
                if (row[invoice_tracking_no_col]) {
                  import_data[row[order_code_col]].invoice_tracking_no =
                    row[invoice_tracking_no_col];
                }
              }
            }
          }
          this.setState({ import_error: null, import_data: import_data });
        } catch (error) {
          console.log(error);
          this.setState({ import_error: error.message });
        }
      };
      reader.readAsBinaryString(file);
    }
  };

  onConfirmImportFile = () => {
    let { import_data } = this.state;
    axios
      .put(`${process.env.REACT_APP_API_URL}/purchasing_orders/import_pos`, {
        ib_pos: import_data,
      })
      .then((response) => {
        this.handleProcurementModal(false);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ reFiltering: false });
      });
    this.setState({ reFiltering: true, onExporting: false });
  };

  onChangeState = (e) => {
    const { selected_state } = this.state;
    let stateFilter = e.map((state) => parseInt(state.value));

    searchParams["state"] = stateFilter.join(",");

    this.setState({ selected_state: e, filter_state: stateFilter });
  };

  onSearch = () => {
    this.setState({ reFiltering: true });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { onExporting } = this.state;

    if (!onExporting) {
      this.addSearchParams(searchParams);
    }
    this.setState({ reFiltering: true });
  };

  onChangeQuery = (e) => {
    if (e.target.value) {
      searchParams["query"] = e.target.value;
    } else {
      delete searchParams["query"];
    }

    this.setState({ query: e.target.value });
  };

  onClickClear = () => {
    const { onExporting } = this.state;
    this.setState({
      filter_state: null,
      page: 0,
      per_page: PER_PAGE,
      query: "",
      is_refiltering: true,
    });

    searchParams = {};
    if (!onExporting)
      this.props.history.replace({
        pathname: `${this.props.location.pathname}`,
        search: null,
      });
  };

  render() {
    const { onExporting, reFiltering } = this.state;
    return (
      <React.Fragment>
        <Spinner loading={reFiltering} />
        <div className="offset-xl-1 col-xl-10 p-3 home-scrollable">
          <div className="row align-items-end">
            <div className="col-md-7 col-12 pb-2 d-flex flex-row align-items-end">
              <span className="header-title d-md-block my-0">
                {APP_TITLE_DC_INBOUND_ORDER}
              </span>
            </div>
            <div className="col-md-5 col-12 text-right">
              <div className="row justify-content-end pb-3 pr-3">
                <div className="">
                  {!onExporting ? (
                    <button
                      type="button"
                      className="btn btn-primary d-block mr-2"
                      onClick={() =>
                        this.props.history.push({
                          pathname: `/dc_inbound_orders/create`,
                        })
                      }
                    >
                      New
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
                <button
                  type="button"
                  className={`btn btn-success d-block`}
                  onClick={
                    onExporting
                      ? () => this.onClickImport()
                      : () => this.onClickExport({ state: [1, 2, 3] })
                  }
                >
                  {onExporting ? "Import" : "รายการส่งจัดซื้อ"}
                </button>
              </div>
            </div>
          </div>

          <div className="card-box light p-3">
            <form onSubmit={this.handleSubmit}>
              <SearchBar
                onEnter={this.onSearch}
                onChangeQuery={this.onChangeQuery}
                onClickClear={this.onClickClear}
                query={this.state.query}
                placeholder={"ค้นหา เลขที่ใบสั่งซื้อ"}
              />

              <div className="row">
                <div className="col-4 col-md-2 mt-2">
                  <label>วันที่ Order</label>
                </div>
                <div className="col-8 col-md-4 mt-2 date-picker-wrapper">
                  <DatePicker
                    className="form-control"
                    selected={this.state.selected_date_form}
                    onChange={(date) => this.onChangeFromDate(date)}
                    dateFormat={FORMAT_APP_DATE}
                  />
                </div>
                <div className="col-4 col-md-2 mt-2">
                  <label>ถึง</label>
                </div>
                <div className="col mt-2 date-picker-wrapper">
                  <DatePicker
                    className="form-control"
                    selected={this.state.selected_date_to}
                    onChange={(date) => this.onChangeToDate(date)}
                    dateFormat={FORMAT_APP_DATE}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-4 col-md-2 mt-2">
                  <label>สถานะ</label>
                </div>
                <div className="col-8 col-md-10 mt-2">
                  <Select
                    isMulti
                    value={this.state.selected_state}
                    onChange={this.onChangeState}
                    options={OPTIONS_SORT}
                  />
                </div>
              </div>

              {/* Visible xs, sm  */}
              <div className="row d-block d-md-none">
                <div className="col-12 mt-3 text-right">
                  <button
                    type="button"
                    className="btn btn-primary ml-2"
                    onClick={() => this.setState({ reFiltering: true })}
                  >
                    Search
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger ml-2"
                    onClick={this.onClickClear}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div className="d-flex justify-content-end my-3">
            <ReactPaginate
              forcePage={this.state.page}
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.page_count}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
              key={this.state.resetKey}
            />
          </div>

          {onExporting && this.displayOnExport()}

          <div>
            {this.tableHeader()}
            {this.orderItems()}
          </div>
        </div>
        {this.displayCloneOption()}

        {this.displayExportBasket()}
        <ExportProcurementModal
          onClearSelectedOrders={this.onCloseProcurement}
          is_displaying_basket={this.state.is_displaying_basket}
          selected_orders={this.state.selectToExporting}
          {...this.state}
          handleDisplayingBasket={this.handleDisplayingBasket}
          onSelectItemToExport={this.onSelectItemToExport}
        />
        <ImportProcurementModal
          onConfirmImportFile={this.onConfirmImportFile}
          is_importing={this.state.onImporting}
          handleProcurementModal={this.handleProcurementModal}
          onImportFile={this.onImportFile}
          import_data={this.state.import_data}
          import_error={this.state.import_error}
        />
      </React.Fragment>
    );
  }
}
export default withRouter(DcInboundOrderIndex);

import React, { useState } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";

import { R_SEL_OPTION_MAX_H, MODAL_SELECT_STYLE } from "../../../constants";
import Number from "../../../components/global/Number.js";

export default function AddItem(props) {
  const { control, handleSubmit, register, errors, reset, setValue, getValues } = useForm({ mode: "onChange" });
  const defaultValues = {
    selectItem: "",
    quantity: "",
  };
  const onSubmit = (data, e) => {
    props.onAddItem({ item: data.selectItem, quantity: data.quantity, comment: data.comment, is_mini_select: true });
    reset(defaultValues);
  };
  const onChangeQuantity = (e) => {
    setValue("quantity", e.target.value);
  };

  return (
    <React.Fragment>
      {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
      <form>
        <div className="d-flex flex-row">
          <h6 className="pb-1 mb-0">เพิ่มรายการสินค้า</h6>
          <button type="button" className="btn btn-primary btn-sm ml-auto p-0 d-none d-lg-flex m-b-3" onClick={props.handleOpenMultiAddItemsModal} disabled={props.disabled}>
            <i className="icon-fullscreen-white"></i>
          </button>
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex-3 mt-1">
            <Controller
              name="selectItem"
              control={control}
              rules={{ required: true }}
              defaultValue={false}
              as={<Select options={props.ItemSelect.filter((item) => !item.from_parent)} menuPortalTarget={document.body} maxMenuHeight={R_SEL_OPTION_MAX_H} isDisabled={props.disabled} styles={MODAL_SELECT_STYLE} />}
            />
            {errors.selectItem && <span class="color-red">โปรดระบุสินค้า</span>}
          </div>
          <div className="d-flex-1 mt-1">
            <Controller name="quantity" control={control} defaultValue="" render={() => <Number value={getValues("quantity")} placeholder="Quantity..." onChange={onChangeQuantity} disabled={props.disabled} />} />
          </div>
          <div className="d-flex flex-row mt-2 d-lg-none">
            <div className="d-flex-1">
              <label>comment</label>
            </div>
            <div className="d-flex-2">
              <input className="form-control text-center" name="comment" ref={register}></input>
            </div>
          </div>
          <button type="submit" className="btn btn-primary mt-1 d-none d-lg-flex justify-content-center" onClick={handleSubmit(onSubmit)}>
            เพิ่ม
          </button>
          <div className="d-flex flex-row mt-2 d-lg-none">
            <button type="button" className="btn btn-success btn-sm w-100 mr-1" onClick={handleSubmit(onSubmit)}>
              เพิ่ม
            </button>
            <button type="button" className="btn btn-secondary btn-sm w-100 ml-1" onClick={props.handleCloseModalPreview}>
              ยกเลิก
            </button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}

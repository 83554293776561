import React from 'react';
// import CreatableSelect from 'react-select/creatable';
import Select from 'react-select'
import Modal from 'react-modal';
import cloneDeep from 'lodash/cloneDeep';

// constants
import { MODAL_STYLE_SMALL_SM, MODAL_SELECT_STYLE } from '../../constants'

// components
import ClientStation from '../global/ClientStation'

// Functions
import { uniqueKey } from '../../functions/Random.js';

// Model
import { MR_STATION_TYPE_DEFAULT } from '../../models/ManufactureRequest'

const NewOption = { value: '', label: '' }
export class MrFormStations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      category_options: props.item_category_options,
      selected_name: cloneDeep(NewOption),
      selected_category: cloneDeep(NewOption),
      selected_type: '',

      edited_item: null,
      modal_preview: false,

      reFilteringClientStation: uniqueKey()
    }

    this.handleOpenModalPreview = this.handleOpenModalPreview.bind(this)
    this.handleCloseModalPreview = this.handleCloseModalPreview.bind(this)
    this.handleOpenEditedModal = this.handleOpenEditedModal.bind(this)
    this.handleCloseEditedModal = this.handleCloseEditedModal.bind(this)
    this.onHandleStationName = this.onHandleStationName.bind(this)
    this.onHandleCategory = this.onHandleCategory.bind(this)
    this.onChangeComment = this.onChangeComment.bind(this)
    this.onChangeCustomItem = this.onChangeCustomItem.bind(this)
    this.onChangeCustomQuantity = this.onChangeCustomQuantity.bind(this)
    this.onChangeType = this.onChangeType.bind(this)
    this.onAddStationList = this.onAddStationList.bind(this)
  }

  validateAddList() {
    let error = ""
    if (this.state.selected_name.label === "") {
      error = error.concat(" - โปรดระบุ Station name\r\n")
    }
    if (this.state.selected_category.label === "") {
      error = error.concat(" - โปรดระบุ Product category/Equipment name\r\n")
    }
    if (this.state.selected_type === "") {
      error = error.concat(" - โปรดระบุ Station Type\r\n")
    }

    if (error === "") {
      return true
    } else {
      alert(error)
      return false
    }
  }

  handleOpenEditedModal(id) {
    this.setState({ edited_item: id })
  }

  handleCloseEditedModal() {
    this.setState({ edited_item: null })
  }

  handleOpenModalPreview() {
    this.setState({ modal_preview: true })
  }

  handleCloseModalPreview() {
    this.setState({ modal_preview: false })
  }

  onHandleStationName(value, e) {
    if (e) {
      let { selected_type } = this.state
      if (e.__isNew__ === undefined) {
        selected_type = MR_STATION_TYPE_DEFAULT
      }
      this.setState({ selected_name: e, selected_type: selected_type })
    } else {
      this.setState({ selected_name: cloneDeep(NewOption) })
    }
  }

  onHandleCategory(e) {
    if (e !== null) {
      if (e.__isNew__ !== undefined) {
        let category_options = this.state.category_options
        category_options.push(e)

        this.setState({ selected_category: e, category_options: category_options })
      } else {
        this.setState({ selected_category: e })
      }
    } else {
      this.setState({ selected_category: cloneDeep(NewOption) })
    }
  }

  onChangeComment(e) {
    this.setState({ comment: e.target.value })
  }

  onChangeCustomItem(e) {
    this.setState({ custom_item: e.target.value })
  }

  onChangeCustomQuantity(e) {
    this.setState({ custom_quantity: e.target.value })
  }

  onChangeType(e) {
    this.setState({ selected_type: e.target.value })
  }

  onAddStationList() {
    if (this.validateAddList() === true) {
      this.props.onAddStationList(this.state.selected_name, this.state.selected_category, this.state.selected_type)
      this.setState({ selected_name: NewOption, selected_category: NewOption, selected_type: '', reFilteringClientStation: uniqueKey() })
      this.handleCloseModalPreview()
    }
  }

  inputField(elm, field) {
    return (
      <input className="form-control text-center" name={field} value={elm[field]}
        onChange={(e) => this.props.onChangeStationList(e, elm.id)} disabled={this.props.disabled}></input>
    )
  }

  addItemMd() {
    if (this.props.stations.length === 0) {
      return (
        <button type="button" className="btn btn-success btn-lg w-100 mr-1 mt-3 d-md-none"
          onClick={this.handleOpenModalPreview}>+ เพิ่มรายการสถานี</button>
      )
    } else {
      return (
        // <a onClick={this.handleOpenModalPreview}>
        //   <div className="row mt-1 d-flex d-md-none">
        //     <div className="bg-antiquewhite col-12 py-2 d-flex justify-content-between">
        //       + เพิ่มรายการสถานี
        //     </div>
        //   </div>
        // </a>
        <button type="button" className="btn btn-success btn-lg btn-sm w-100 d-md-none"
          onClick={this.handleOpenModalPreview}>+ เพิ่มรายการสถานี</button>
      )
    }
  }

  editModal(elm) {
    let { edited_item } = this.state
    const btns = () => {
      if (!this.props.disabled) {
        return (
          <React.Fragment>
            <button type="button" className="btn btn-success btn-sm w-100"
              onClick={this.handleCloseEditedModal} disabled={this.props.disabled}>บันทึก</button>
            <button type="button" className="btn btn-danger btn-sm w-100 ml-1"
              onClick={() => this.props.onDeleteStationList(elm.id)}>ลบ</button>
            <button type="button" className="btn btn-secondary btn-sm w-100 ml-1"
              onClick={this.handleCloseEditedModal}>ยกเลิก</button>
          </React.Fragment>
        )
      } else {
        return null
      }
    }
    return (
      <Modal
        isOpen={elm.id === edited_item}
        contentLabel="onAddStationList"
        onRequestClose={this.handleCloseEditedModal}
        style={MODAL_STYLE_SMALL_SM}
      >
        <div className="d-flex flex-column">
          <h5>แก้ไข</h5>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1"><label>ชื่อสถานี</label></div>
            <div className="d-flex-2">
              {elm.station_name}
            </div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1"><label className="mb-0">หมวดหมู่สินค้า</label></div>
            <div className="d-flex-2">
              {this.inputField(elm, "item_category_name")}
            </div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1"><label className="mb-0">ประเภทสถานี</label></div>
            <div className="d-flex-2">
              {this.inputField(elm, "station_type")}
            </div>
          </div>
          <div className="d-flex flex-row mt-3">
            {btns()}
          </div>
        </div>
      </Modal>
    )
  }

  addListField() {
    let { category_options, reFilteringClientStation } = this.state
    if (!this.props.disabled) {
      return (
        <React.Fragment>
          <h5 className="pb-1 mb-0">เพิ่มรายการสั่งซื้อของสถานี</h5>
          <div className="d-flex flex-column flex-md-row">
            <div className="d-flex-2 mt-2">
              {/* <CreatableSelect
                isClearable
                placeholder="Station name..."
                value={station_options.filter(option => option.value === this.state.selected_name.value)}
                onChange={this.onHandleStationName}
                options={station_options}
                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                menuPortalTarget={document.body}
                maxMenuHeight={R_SEL_OPTION_MAX_H}
              /> */}
              <ClientStation
                creatable_select={false}
                onChange={this.onHandleStationName}
                clientStation={this.state.selected_name}
                disabled={this.props.disabled}
                key={reFilteringClientStation}
              />
            </div>
            <div className="d-flex-2 mt-2 ml-md-2">
              <Select
                className="select2-style"
                onChange={this.onHandleCategory}
                value={category_options.filter(option => option.value === this.state.selected_category.value)}
                options={category_options}
                isDisabled={this.props.disabled}
                placeholder="Product category..."
                styles={MODAL_SELECT_STYLE}
              />
            </div>
            <div className="d-flex-1 mt-2 ml-md-2">
              <input className="form-control" onChange={this.onChangeType} value={this.state.selected_type} placeholder="Station Type..."></input>
            </div>
            <button type="button" className="btn btn-primary mt-2 ml-md-2" onClick={this.onAddStationList}>เพิ่ม</button>
          </div>
        </React.Fragment>
      )
    }
  }

  addModal() {
    return (
      <Modal
        isOpen={this.state.modal_preview}
        contentLabel="onAddStationList"
        onRequestClose={this.handleCloseModalPreview}
        style={MODAL_STYLE_SMALL_SM}
      >
        <div className="d-flex flex-column">
          {this.addListField()}
        </div>
      </Modal>
    )
  }

  displayItems() {
    let items = this.props.stations.map((elm, index) => {
      const deleteBtn = () => {
        if (!this.props.disabled) {
          return (
            <button type="button" className="btn btn-danger btn-sm ml-auto" onClick={() => this.props.onDeleteStationList(elm.id)}>X</button>
          )
        }
      }
      return (
        <React.Fragment>
          {/* md up */}
          <div className="row mt-2 border d-none d-md-flex">
            <div className="table-item-bg first col-p5 justify-content-center">{index + 1}</div>
            <div className="table-item-bg col-4 pr-0">
              {elm.station_name}
            </div>
            <div className="table-item-bg col-4">
              {this.inputField(elm, "item_category_name")}
            </div>
            <div className="table-item-bg col-2">
              {this.inputField(elm, "station_type")}
            </div>
            <div className="table-item-bg col-2">
              {elm.item_category && elm.item_category.mr_remark ? this.inputField(elm.item_category, "mr_remark") : ""}
            </div>
            <div className="table-item-bg col justify-content-between">
              {deleteBtn()}
            </div>
          </div>
          {/* md down */}
          <a onClick={() => this.handleOpenEditedModal(elm.id)}>
            <div className="row mt-2 border d-md-none">
              <div className="table-item-bg col-12 d-flex bg-ghostwhite">
                {elm.station_name}
              </div>
              <div className="table-item-bg col-12 pt-1 pr-0">
                {elm.item_category_name}
              </div>
              <div className="table-item-bg col-12 text-center">
                {elm.station_type}
              </div>
            </div>
          </a>
          {this.editModal(elm)}
        </React.Fragment>
      )
    })
    return items
  }


  displayTable() {
    return (
      <React.Fragment>
        <div className="col-12 mt-2">
          <div className="row table-header d-none d-md-flex">
            <div className="table-item-bg first col-p5 justify-content-center"><strong>ลำดับ</strong></div>
            <div className="table-item-bg col-4"><strong>ชื่อสถานี</strong></div>
            <div className="table-item-bg col-4"><strong>หมวดหมู่สินค้า/อุปกรณ์</strong></div>
            <div className="table-item-bg col-2"><strong>ประเภทสถานี</strong></div>
            <div className="table-item-bg col pl-5"><strong></strong></div>
          </div>
          <div className="row quo-table">
            <div className="col-12">
              {this.displayItems()}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    return (
      <React.Fragment>
        <div className="col">
          <div className="row mt-2">
            <div className="offset-xl-1 col-xl-10">
              <div className="card-box mt-0 p-3 d-none d-md-block">
                {this.addListField()}
              </div>
            </div>
          </div>
          {this.addItemMd()}
          {this.addModal()}
          <div className="row">
            <div className="offset-xl-1 col-xl-10 ">
              {this.displayTable()}
            </div>
          </div>
        </div>

      </React.Fragment>
    );
  }
}
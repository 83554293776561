import React from 'react'
import Select from 'react-select'
import axios from 'axios'
import { API_RESOURCES, QUO_STATES, APP_TITLE_QUO, APP_TITLE_ORDER, APP_TITLE_MR } from '../../constants'

// functions
import { uniqueKey } from '../../functions/Random'
let serchTiming = null
class QuickSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      options: [],
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  handleInputChange(value) {
    if (value !== '') {
      this.getCollection(value)
    }
  }

  setOptions(data) {
    let options = data.map((e) => {
      e.text = e.name
      if (e.address) {
        e.text += ` - ${e.address.province.name} ${e.ship_to_code}`
      }
      e.label = e.text
      e.value = e.id
      return e
    })
    this.setState({
      options: options,
      isMounted: true,
    })
  }

  getCollection(query) {
    // filter: {}
    // range: [0,9]
    // sort: ["code","ASC"]
    let options = {
      sort: JSON.stringify(['created_at', 'DESC']),
      range: JSON.stringify([0, 5]),
      filter: {},
    }
    if (query) {
      options.filter.query = query
    }

    clearTimeout(serchTiming)
    serchTiming = setTimeout(() => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/quick_search`, {
          params: options,
        })
        .then((response) => {
          let select_options = []
          let quotations = response.data.quotations.map((elm) => {
            let quo_code = QUO_STATES.draft.name === elm.state ? 'draft' : elm.code
            return {
              value: elm.id,
              link_to: `/${API_RESOURCES.quotation}/${elm.id}`,
              label: `${quo_code} - ${elm.client_station.name}`,
            }
          })
          let pos = response.data.pos.map((elm) => {
            return {
              value: elm.id,
              link_to: `/${API_RESOURCES.order}/${elm.id}`,
              label: `${elm.code} - ${elm.quotation.client_station.name}`,
            }
          })
          let mrs = response.data.mrs.map((elm) => {
            return {
              value: elm.id,
              link_to: `/${API_RESOURCES.mr}/${elm.id}`,
              label: `${elm.code} - ${elm.manufacturer_name}`,
            }
          })
          let inorder = response.data.inorder.map((elm) => {
            return {
              value: elm.id,
              link_to: `/${API_RESOURCES.dc_inbound_order}/${elm.id}`,
              label: `${elm.code} `,
            }
          })

          select_options.push({
            label: APP_TITLE_QUO,
            options: quotations,
          })
          select_options.push({
            label: APP_TITLE_ORDER,
            options: pos,
          })
          select_options.push({
            label: APP_TITLE_MR,
            options: mrs,
          })
          select_options.push({
            label: "ออร์เดอร์เข้า DC ",
            options: inorder,
          })
          this.setState({
            options: select_options,
          })
        })
        .catch((error) => {
          console.log(error)
        })
        .then(() => {
          // always executed
        })
    }, 500)
  }

  onClick(e) {
    window.location.href = e.link_to
  }

  render() {
    const customStyles = {
      control: (base) => ({
        ...base,
        border: 0,
        // This line disable the blue border
        boxShadow: 'none',
        background: '#ffffffd9',
      }),
      menu: (provided) => ({ ...provided, zIndex: 100 }),
    }
    return (
      <React.Fragment>
        <Select
          styles={customStyles}
          placeholder="พิมพ์ เพื่อค้นหา..."
          onChange={this.onClick}
          onInputChange={this.handleInputChange}
          options={this.state.options}
          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        />
      </React.Fragment>
    )
  }
}

export default QuickSearch

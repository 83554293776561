import React from 'react'
import Select from 'react-select'
import Modal from 'react-modal'
import cloneDeep from 'lodash/cloneDeep'

// constants
import { MODAL_STYLE_SMALL_SM, MODAL_SELECT_STYLE } from '../../constants'

// components
import ErrorMessage from '../global/ErrorMessage'
import CustomDatePicker from '../global/CustomDatePicker'

// Functions
import { validateInputFromRequireFields } from '../../functions/Validation'
import { objectIsEmpty } from '../../functions/Object'
import { formatDate } from '../../functions/Date'
import { generateID } from '../../functions/Random'
import { Tooltip } from 'antd'

const requiredRepairFields = {
  selected_item_option: '',
  selected_informed_date: '',
}

export class OrderFormRepair extends React.Component {
  constructor(props) {
    super(props)
    let item_options = []
    props.order.purchasing_order_items
      // .filter((elm) => !elm.is_mbom)
      .forEach((elm) => {
        item_options.push({ id: elm.id, text: elm.code + ' - ' + elm.name, value: elm.id, expiration_date: elm.expiration_date })
      })
    this.state = {
      category_options: props.item_category_options,
      selected_item_option: null,
      selected_item_warranty_date: null,
      selected_informed_date: new Date(),
      selected_repaired_date: null,
      comment_cause: '',
      comment_how_to_fix: '',
      comment: '',

      edited_item: null,
      modal_preview: false,

      errors: {},
      item_options: item_options,
    }

    this.handleOpenModalPreview = this.handleOpenModalPreview.bind(this)
    this.handleCloseModalPreview = this.handleCloseModalPreview.bind(this)
    this.handleOpenEditedModal = this.handleOpenEditedModal.bind(this)
    this.handleCloseEditedModal = this.handleCloseEditedModal.bind(this)

    this.onChangeItemId = this.onChangeItemId.bind(this)
    this.onChangeInformedDate = this.onChangeInformedDate.bind(this)
    this.onChangeRepairedDate = this.onChangeRepairedDate.bind(this)
    this.onChangeComment = this.onChangeComment.bind(this)
    this.onChangeCommentCause = this.onChangeCommentCause.bind(this)
    this.onChangeCommentHowToFix = this.onChangeCommentHowToFix.bind(this)
    this.onAddRepairLog = this.onAddRepairLog.bind(this)
  }

  handleOpenEditedModal(id) {
    this.setState({ edited_item: id })
  }

  handleCloseEditedModal() {
    this.setState({ edited_item: null })
  }

  handleOpenModalPreview() {
    this.setState({ modal_preview: true })
  }

  handleCloseModalPreview() {
    this.setState({ modal_preview: false })
  }

  onChangeItemId(selected) {
    let { errors } = this.state
    let warr_date = ''
    if (selected.expiration_date) {
      warr_date = selected.expiration_date

      // validate warranty date
      let w_date = new Date(warr_date)
      let today = new Date()
      if (w_date < today) {
        errors.selected_item_option = '  ( สินค้าหมดระยะเวลาของการรับประกัน )'
      } else {
        delete errors.selected_item_option
      }
    } else {
      delete errors.selected_item_option
    }
    this.setState({ selected_item_option: selected, selected_item_warranty_date: warr_date, errors: errors })
  }

  onChangeInformedDate(date) {
    this.setState({ selected_informed_date: date })
  }

  onChangeRepairedDate(date) {
    this.setState({ selected_repaired_date: date })
  }

  onChangeComment(e) {
    this.setState({ comment: e.target.value })
  }

  onChangeCommentCause(e) {
    this.setState({ comment_cause: e.target.value })
  }

  onChangeCommentHowToFix(e) {
    this.setState({ comment_how_to_fix: e.target.value })
  }

  onAddRepairLog() {
    let { selected_item_option, selected_informed_date, selected_repaired_date, comment, comment_cause, comment_how_to_fix } = this.state
    let record = {
      selected_item_option: selected_item_option.value,
      selected_informed_date: selected_informed_date,
      selected_repaired_date: selected_repaired_date,
      comment: comment,
      comment_cause: comment_cause,
      comment_how_to_fix: comment_how_to_fix,
    }
    let errors = validateInputFromRequireFields(record, requiredRepairFields)
    if (objectIsEmpty(errors)) {
      this.setState({ selected_item_option: null, selected_item_warranty_date: null, selected_informed_date: new Date(), selected_repaired_date: null, comment: '', comment_cause: "", comment_how_to_fix: "", errors: {} })
      this.props.onAddRepairLog({
        id: generateID(),
        // name: selected_item_option.label,
        order_item_id: selected_item_option.id,
        informed_date: selected_informed_date,
        repaired_date: selected_repaired_date,
        comment: comment,
        comment_cause: comment_cause,
        comment_how_to_fix: comment_how_to_fix,
      })
      this.handleCloseModalPreview()
    } else {
      this.setState({ errors: errors })
    }
  }

  // addItemMd() {
  //   if (this.props.stations.length === 0) {
  //     return (
  //       <button type="button" className="btn btn-success btn-lg w-100 mr-1 mt-3 d-md-none"
  //       onClick={this.handleOpenModalPreview}>+ เพิ่มรายการสถานี</button>
  //     )
  //   } else {
  //     return (
  //       // <a onClick={this.handleOpenModalPreview}>
  //       //   <div className="row mt-1 d-flex d-md-none">
  //       //     <div className="bg-antiquewhite col-12 py-2 d-flex justify-content-between">
  //       //       + เพิ่มรายการสถานี
  //       //     </div>
  //       //   </div>
  //       // </a>
  //       <button type="button" className="btn btn-success btn-lg btn-sm w-100 d-md-none"
  //       onClick={this.handleOpenModalPreview}>+ เพิ่มรายการสถานี</button>
  //     )
  //   }
  // }

  addListField() {
    let { order, disabled } = this.props
    let { item_options, selected_item_option, selected_item_warranty_date, selected_repaired_date, selected_informed_date, comment, comment_cause, comment_how_to_fix } = this.state

    if (!this.props.disabled) {
      return (
        <React.Fragment>
          <div className="row">
            <div className="col-4 col-md-2 mt-2">
              <label className="sign-is-required">สินค้า</label>
            </div>
            <div className="col-8 col-md-10 mt-2">
              <Select className="select2-style" value={selected_item_option} getOptionLabel={(option) => `${option.text}`} onChange={this.onChangeItemId} options={item_options} styles={MODAL_SELECT_STYLE} />
              <span className="pt-2">{selected_item_warranty_date ? `สินค้ารับประกันถึงวันที่ ${formatDate(selected_item_warranty_date)}\n` : ''}</span>
              <ErrorMessage errorKey={'selected_item_option'} errorMessages={this.state.errors} />
            </div>
          </div>
          <div className="row">
            <div className="col-4 col-md-2 mt-2">
              <label className="sign-is-required">วันที่แจ้ง</label>
            </div>
            <div className="col-8 col-md-4 mt-2 date-picker-wrapper">
              <CustomDatePicker selected={selected_informed_date} onChange={this.onChangeInformedDate} disabled={disabled} />
              <ErrorMessage errorKey={'selected_informed_date'} errorMessages={this.state.errors} />
            </div>
            <div className="col-4 col-md-2 mt-2">
              <label>วันที่แก้ไขเสร็จ</label>
            </div>
            <div className="col-8 col-md-4 mt-2 date-picker-wrapper">
              <CustomDatePicker selected={selected_repaired_date} onChange={this.onChangeRepairedDate} disabled={disabled} />
            </div>
          </div>
          <div className="row">
            <div className="col-4 col-md-2 mt-2">
              <label>สาเหตุ</label>
            </div>
            <div className="col-8 col-md-10 mt-2">
              <textarea className="form-control" type="text" name="comment_cause" onChange={this.onChangeCommentCause} rows="2" value={comment_cause}></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col-4 col-md-2 mt-2">
              <label>วิธีแก้ไข</label>
            </div>
            <div className="col-8 col-md-10 mt-2">
              <textarea className="form-control" type="text" name="comment_how_to_fix" onChange={this.onChangeCommentHowToFix} rows="2" value={comment_how_to_fix}></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col-4 col-md-2 mt-2">
              <label>หมายเหตุ</label>
            </div>
            <div className="col-8 col-md-10 mt-2">
              <textarea className="form-control" type="text" name="comment" onChange={this.onChangeComment} rows="2" value={comment}></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col text-right">
              <button type="button" className="btn btn-primary mt-2 ml-md-2" onClick={this.onAddRepairLog}>
                เพิ่ม
              </button>
            </div>
          </div>
        </React.Fragment>
      )
    }
  }

  addModal() {
    return (
      <Modal isOpen={this.state.modal_preview} contentLabel="onAddRepairLog" onRequestClose={this.handleCloseModalPreview} style={MODAL_STYLE_SMALL_SM}>
        <div className="d-flex flex-column">
          <h5 className="pb-1 mb-0">เพิ่มรายการ</h5>
          {this.addListField()}
        </div>
      </Modal>
    )
  }

  inputField(elm, field, text_align, isNumber) {
    if (isNumber === true) {
      // return (
      //   <Number
      //     innerClass={text_align}
      //     name={field}
      //     value={elm[field]}
      //     onChange={this.onChangeMrInfo}
      //     disabled={this.props.disabled}
      //   />
      // )
    } else {
      return (
        <input
          className={`form-control ${text_align}`}
          name={field}
          value={elm[field]}
        //onChange={this.onChangeMrInfo} disabled={this.props.disabled}
        ></input>
      )
    }
  }

  editModal(elm) {
    let { edited_item, item_options } = this.state
    return (
      <Modal isOpen={elm.id === edited_item} contentLabel="onEditItem" onRequestClose={this.handleCloseEditedModal} style={MODAL_STYLE_SMALL_SM}>
        <div className="d-flex flex-column">
          <h5>แก้ไขรายการแจ้งซ่อม</h5>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>รายการ</label>
            </div>
            <div className="d-flex-2">{item_options.filter((option) => option.id === elm.order_item_id)[0].text}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>วันที่แจ้ง</label>
            </div>
            <div className="d-flex-2">
              <CustomDatePicker
                selected={elm.informed_date}
                onChange={(date) => this.props.onChangeRepairLog(elm.id, 'informed_date', date)}
              // disabled={disabled}
              />
            </div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>วันที่แก้ไขเสร็จ</label>
            </div>
            <div className="d-flex-2">
              <CustomDatePicker
                selected={elm.repaired_date}
                onChange={(date) => this.props.onChangeRepairLog(elm.id, 'repaired_date', date)}
              // onChange={date => this.onChangeInformedDate(date)}
              // disabled={disabled}
              />
            </div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>ผู้แจ้ง</label>
            </div>
            <div className="d-flex-2">{elm.user.name}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>สาเหตุ</label>
            </div>
            <div className="d-flex-2">
              <input className="form-control" name="repairLogComment" value={elm.comment_cause} disabled={this.props.disabled} onChange={(e) => this.props.onChangeRepairLog(elm.id, 'comment_cause', e.target.value)}></input>
            </div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>
                วิธีแก้ไข</label>
            </div>
            <div className="d-flex-2">
              <input className="form-control" name="repairLogComment" value={elm.comment_how_to_fix} disabled={this.props.disabled} onChange={(e) => this.props.onChangeRepairLog(elm.id, 'comment_how_to_fix', e.target.value)}></input>
            </div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>หมายเหตุ</label>
            </div>
            <div className="d-flex-2">
              <input className="form-control" name="repairLogComment" value={elm.comment} disabled={this.props.disabled} onChange={(e) => this.props.onChangeRepairLog(elm.id, 'comment', e.target.value)}></input>
            </div>
          </div>
          <div className="d-flex flex-row mt-2">
            <button
              type="button"
              className="btn btn-danger btn-sm w-100 ml-1"
              onClick={() => {
                this.handleCloseEditedModal()
                this.props.onRemoveRepairLog(elm.id)
              }}
              disabled={this.props.disabled}
            >
              ลบ
            </button>
            <button type="button" className="btn btn-secondary btn-sm w-100 ml-1" onClick={this.handleCloseEditedModal}>
              ปิด
            </button>
          </div>
        </div>
      </Modal>
    )
  }

  displayItems() {
    let items = this.props.order.order_repairs.map((elm, index) => {
      return (
        <React.Fragment>
          {/* md up */}
          <div className="repair-header">
            <div>{index + 1}</div>
            <Tooltip placement='top' title={this.state.item_options.filter((option) => option.id === elm.order_item_id)[0].text}>
              <div style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical'
              }}>{this.state.item_options.filter((option) => option.id === elm.order_item_id)[0].text}</div>
            </Tooltip>
            <div >
              <CustomDatePicker selected={elm.informed_date} onChange={(date) => this.props.onChangeRepairLog(elm.id, 'informed_date', date)} disabled={this.props.disabled} />
              {/* {formatDate(elm.informed_date)} */}
            </div>
            <div>
              <CustomDatePicker selected={elm.repaired_date} onChange={(date) => this.props.onChangeRepairLog(elm.id, 'repaired_date', date)} disabled={this.props.disabled} />
            </div>
            <div style={{
              zIndex: 999, whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              <Tooltip placement='top' title={`${elm.user.firstname} ${elm.user.lastname || ""}`}>
                {elm.user.firstname} {elm.user.lastname || ""}
              </Tooltip>
            </div>
            <div>
              <input className="form-control" name="repairLogCommentCause" value={elm.comment_cause} disabled={this.props.disabled} onChange={(e) => this.props.onChangeRepairLog(elm.id, 'comment_cause', e.target.value)} />
            </div>
            <div>
              <input className="form-control" name="repairLogCommentHowToFix" value={elm.comment_how_to_fix} disabled={this.props.disabled} onChange={(e) => this.props.onChangeRepairLog(elm.id, 'comment_how_to_fix', e.target.value)} />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input className="form-control" name="repairLogComment" value={elm.comment} disabled={this.props.disabled} onChange={(e) => this.props.onChangeRepairLog(elm.id, 'comment', e.target.value)} />
              <div>
                <button
                  type="button"
                  className="btn btn-danger btn-sm ml-1"
                  onClick={() => {
                    this.handleCloseEditedModal()
                    this.props.onRemoveRepairLog(elm.id)
                  }}
                  disabled={this.props.disabled}
                >
                  X
                </button>
              </div>
            </div>

          </div>

          {/* md down */}
          <a onClick={() => (this.props.disabled ? false : this.handleOpenEditedModal(elm.id))}>
            <div className="row mt-2 border d-md-none">
              <div className="table-item-bg col-12 py-1 col-md-4 pr-0">
                {index + 1}. {elm.user.firstname} {elm.user.lastname}
              </div>
              <div className="table-item-bg col-12 col-md d-flex flex-row bg-ghostwhite d-md-none">
                <div className="d-flex-1">วันที่แจ้ง</div>
                <div className="d-flex-2">{formatDate(elm.informed_date)}</div>
              </div>
              <div className="table-item-bg col-12 col-md d-flex flex-row bg-ghostwhite d-md-none">
                <div className="d-flex-1">วันที่แก้ไขเสร็จ</div>
                <div className="d-flex-2">{formatDate(elm.repaired_date)}</div>
              </div>
            </div>
          </a>
          {this.editModal(elm)}
        </React.Fragment >
      )
    })
    return items
  }

  displayTable() {
    return (
      <React.Fragment>
        <div>
          {/* md up */}
          <div className="repair-header">
            <div>
              <strong>ลำดับ</strong>
            </div>
            <div>
              <strong>สินค้าที่แจ้งซ่อม</strong>
            </div>
            <div>
              <strong>วันที่แจ้ง</strong>
            </div>
            <div>
              <strong>วันที่แก้ไขเสร็จ</strong>
            </div>
            <div>
              <strong>ผู้แจ้ง</strong>
            </div>
            <div>
              <strong>สาเหตุ</strong>
            </div>
            <div>
              <strong>วิธีแก้ไข</strong>
            </div>
            <div>
              <strong>หมายเหตุ</strong>
            </div>

          </div>
          <div>{this.displayItems()}</div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    return (
      <React.Fragment>
        <div className="col">
          <div className="row mt-2">
            <div className="col-12">
              <h5 className="pb-1">เพิ่มการแจ้งซ่อม</h5>
              <div className="card-box p-3 mt-1">{this.addListField()}</div>
            </div>
          </div>
          {/* {this.addItemMd()} */}
          {this.addModal()}
          <div className="row">
            <div className="col-12">{this.displayTable()}</div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

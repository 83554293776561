import React from 'react'
import axios from 'axios'
import {
  PAGES,
  OPTIONS_PERIOD,
  QUO_STATES,
  MR_STATES,
  API_RESOURCES,
  ORDER_STATES,
  APP_TITLE_QUICK_SEARCH,
  APP_TITLE_QUO,
  APP_TITLE_MR,
  APP_TITLE_ORDER,
} from '../constants.js'
import ExportProcurementModal from '../components/dashboard/SubComponents/ExportProcurementModal'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
// Functions
import { formatApiDate } from '../functions/Date'
import { ArrayFindRecord, objectReturnZeroIfNull, sum } from '../functions/Object'
import { getHeaderTotalPageCount } from '../functions/Response'
// Components
import CardLink from '../components/global/CardLink.js'
import { SettingQuery } from '../components/home/SettingQuery'
import QuickSearch from '../components/home/QuickSearch'
import Spinner from '../components/global/Spinner'
import _ from 'lodash'
import { totalSalesWithVat } from '../models/Quotations'
import '../styles/model.css'
import { Link } from 'react-router-dom'
import moment from 'moment'

let serchTiming = null
const DEFAULT_FILTER_PERIOD = OPTIONS_PERIOD.year.value
export class Home extends React.Component {
  constructor(props) {
    super(props)

    let today = new Date()
    let this_year = today.getFullYear()

    this.state = {
      dashboard: { quotations: [], pos: [], mrs: [], inbound_orders: [], outbound_orders: [] },
      currentPage: PAGES.home.value,
      currentSubPage: null,
      options: {
        state: '',
      },
      selected_id: '',
      selected_link: '',
      selected_setting: false,
      filters: {
        year: this_year,
        period: null,
        type: DEFAULT_FILTER_PERIOD,
      },
      from_date: formatApiDate(new Date(this_year, 0, 1)),
      to_date: formatApiDate(new Date(this_year, 11, 31)),
      reFiltering: true,
      is_loading: true,

      isVisible: false,
      logList: null,
      logListTemp: null,
      page_count: 0,
      logSearch: null,
    }

    this.onChangeSubPage = this.onChangeSubPage.bind(this)

    // Filter
    this.onSubmitSetting = this.onSubmitSetting.bind(this)
    this.onHandleSetting = this.onHandleSetting.bind(this)

    // Temp
    this.onClickQuickSearch = this.onClickQuickSearch.bind(this)

    this.handleCancel = this.handleCancel.bind(this)
    this.handleLogSearch = this.handleLogSearch.bind(this)
    this.getLogChangeLog = this.getLogChangeLog.bind(this)
    this.handleClickSearch = this.handleClickSearch.bind(this)
  }

  componentDidMount() {
    this.getDashboard(this.state.from_date, this.state.to_date)
  }

  getDashboard(from_date, to_date) {
    axios
      .get(`${process.env.REACT_APP_API_URL}/dashboard`, {
        params: { from_date: from_date, to_date: to_date },
      })
      .then((response) => {
        console.log(response.data)
        this.setState({
          dashboard: response.data,
          reFiltering: false,
          is_loading: false,
        })
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          reFiltering: false,
          is_loading: false,
        })
      })
  }

  onSubmitSetting(filters, from_date, to_date) {
    this.setState({ filters: filters, from_date: from_date, to_date: to_date, reFiltering: true, selected_setting: false })
    this.getDashboard(from_date, to_date)
  }

  handleCancel(enable) {
    this.setState({ isVisible: enable })
  }

  handleLogAll(enable) {
    this.setState({ isVisible: enable, logSearch: null }, () => this.getLogChangeLog(0, null))
  }

  getLogChangeLog(page, search) {
    this.setState({
      is_loading: true,
    })
    const params = {
      page: page ? page.selected : 0,
      limit: 20,
      search: this.state.logSearch || search,
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/dashboard/log_installation`, { params })
      .then((response) => {
        this.setState({
          logList: response.data,
          logListTemp: response.data,
          is_loading: false,
          page_count: getHeaderTotalPageCount(response, params.limit),
        })
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          reFiltering: false,
          is_loading: false,
        })
      })
  }

  handleClickSearch(e) {
    if (this.state.logSearch === '') {
      this.getLogChangeLog(0, null)
    } else {
      this.getLogChangeLog(0, this.state.logSearch)
    }
  }

  onHandleSetting(value) {
    this.setState({ selected_setting: value })
  }

  onChangeSubPage(page, subPage) {
    this.setState({
      currentPage: page,
      currentSubPage: subPage,
    })
  }

  onClickQuickSearch(e) {
    // this.setState({ selected_id: e.id, selected_link: e.link_to })
    window.location.href = `/${e.link_to}/${e.id}`
  }

  displayFilterDate(filter) {
    switch (filter.type) {
      case OPTIONS_PERIOD.year.value:
        return `YTD ${filter.year}`
      default:
        return `${filter.year} - ${filter.period}`
    }
  }

  handleLogSearch(e) {
    this.setState({ logSearch: e.target.value })

    if (e.key === 'Enter' && e.target.value) {
      this.getLogChangeLog(0, e.target.value)
    } else if (e.key === 'Enter' && !e.target.value) {
      this.setState({ logSearch: null }, () => {
        this.getLogChangeLog(0, null)
      })
    }
  }

  render() {
    console.log('this.state.page_count 22 :>> ', this.state.page_count)
    let { filters, selected_setting, dashboard } = this.state

    // Quotation
    // Total Approved and Issue PO amount / Total All Active Quotations (Not Rejected, Cancelled)
    let quo_draft = ArrayFindRecord(dashboard.quotations, 'state', QUO_STATES.draft.name)
    let quo_submitted = ArrayFindRecord(dashboard.quotations, 'state', QUO_STATES.submitted.name)
    let num_of_all_quotations = sum(dashboard.quotations, 'count')
    let num_of_draft_quotations = objectReturnZeroIfNull(quo_draft, 'count')
    let num_of_submitted_quotations = objectReturnZeroIfNull(quo_submitted, 'count')
    // Approved คือ รวมที่ผ่าน status approved มาแล้ว ()
    // approved + order_issued_partially + order_issued_completed
    let num_of_approved_quotations = num_of_all_quotations - num_of_draft_quotations - num_of_submitted_quotations
    let all_total_sales = sum(dashboard.quotations, 'total_sales')
    let draft_total_sales = objectReturnZeroIfNull(quo_draft, 'total_sales')
    let submitted_total_sales = objectReturnZeroIfNull(quo_submitted, 'total_sales')
    let approved_total_sales = sum(dashboard.quotations, 'total_sales') - draft_total_sales - submitted_total_sales

    // Order
    let order_in_progress = ArrayFindRecord(dashboard.pos, 'state', ORDER_STATES.in_progress.name)
    let order_completed = ArrayFindRecord(dashboard.pos, 'state', ORDER_STATES.completed.name)
    let order_po_payment_compeleted = ArrayFindRecord(dashboard.pos, 'state', 'po_payment_compeleted')
    let num_of_in_progress_orders = objectReturnZeroIfNull(order_in_progress, 'count')
    let num_of_completed_orders = objectReturnZeroIfNull(order_completed, 'count')
    let order_all_total_sales = sum(dashboard.pos, 'total_prices')
    let order_in_progress_total_sales = objectReturnZeroIfNull(order_in_progress, 'total_prices')
    let order_completed_total_sales = objectReturnZeroIfNull(order_completed, 'total_prices')
    let order_payment_total_sales = objectReturnZeroIfNull(order_po_payment_compeleted, 'total_prices')

    // MR
    let mr_draft = ArrayFindRecord(dashboard.mrs, 'state', MR_STATES.draft.name)
    let mr_submitted = ArrayFindRecord(dashboard.mrs, 'state', MR_STATES.submitted.name)
    let num_of_draft_mrs = objectReturnZeroIfNull(mr_draft, 'count')
    let num_of_submitted_mrs = objectReturnZeroIfNull(mr_submitted, 'count')

    let totalOurbound = _.sumBy(
      _.filter(dashboard.outbound_orders, (o) => o.state != 'po_payment_compeleted'),
      'total_prices'
    )
    return (
      <React.Fragment>
        <Spinner loading={this.state.is_loading} />
        <div className="home-scrollable">
          <div className="col-12 offset-md-1 col-md-10 outer-card-md">
            <div className="row">
              <div className="col d-flex flex-row">
                <h2 className="d-none d-md-block">{APP_TITLE_QUICK_SEARCH}</h2>
                <h3 className="d-md-none">{APP_TITLE_QUICK_SEARCH}</h3>
              </div>
            </div>
            <div className="row">
              <div className="col py-2 d-flex flex-row">
                <div className="nav-select-wrapper">
                  <QuickSearch />
                  {/* <Select
                    styles={customStyles}
                    placeholder="Search..."
                    onChange={this.onClickQuickSearch}
                    options={select_options}
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  /> */}
                </div>
                {/* <a className="ml-2" href={`/${selected_link}/${selected_id || ''}`}>
                  <button type="button" className="btn btn-outline-secondary">Go</button>
                </a> */}
              </div>
            </div>
          </div>

          <div
            className={`col-12 offset-md-1 col-md-10 outer-card sticky-header-md bg-white-md ${this.props.onStickySetting || selected_setting ? '' : 'z-index-1'
              }`}
          >
            <div className="row">
              <div className="col d-flex flex-row">
                <h3>Setting</h3>
                <span className={`badge badge-pill badge-warning my-auto ml-2 mr-auto cursor-pointer`} onClick={() => this.onHandleSetting(true)}>
                  {this.displayFilterDate(filters)}
                </span>
              </div>
            </div>
          </div>

          <div className="col-12 offset-md-1 col-md-10 outer-card-md">
            <div className="row d-none d-md-block mb-2">
              <div className="col d-flex flex-row">
                <h2 className="mb-0 pt-2">{APP_TITLE_QUO}</h2>
                <div className="pt-3 mt-1 pl-2 text-truncate">{`(${totalSalesWithVat(
                  all_total_sales - draft_total_sales - submitted_total_sales
                ).toLocaleString()} / ${totalSalesWithVat(all_total_sales).toLocaleString()})`}</div>

                <a className="ml-2" href={`/${API_RESOURCES.quotation}/new`}>
                  <button type="button" className="btn btn-outline-secondary mt-2">
                    New
                  </button>
                </a>
                <a className="ml-2" href={`/${API_RESOURCES.quotation}`}>
                  <button type="button" className="btn btn-outline-secondary mt-2">
                    All
                  </button>
                </a>
              </div>
            </div>

            <div className="row d-md-none">
              <div className="col d-flex flex-row">
                <h3 className="mr-auto">{APP_TITLE_QUO}</h3>
                <a className="ml-2" href={`/${API_RESOURCES.quotation}/new`}>
                  <button type="button" className="btn btn-outline-secondary btn-sm">
                    New
                  </button>
                </a>
                <a className="ml-2" href={`/${API_RESOURCES.quotation}`}>
                  <button type="button" className="btn btn-outline-secondary btn-sm">
                    All
                  </button>
                </a>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6 col-md-3 px-3 py-1 h-100">
                <CardLink
                  classOutter=""
                  title={`Draft (${num_of_draft_quotations})`}
                  content={
                    <div className="font-larger text-break">
                      <strong>{totalSalesWithVat(draft_total_sales).toLocaleString()}</strong>THB
                    </div>
                  }
                  link={`/${API_RESOURCES.quotation}?state=${QUO_STATES.draft.name}&date_from=${this.state.from_date}&date_to=${this.state.to_date}`}
                />
              </div>

              <div className="col-12 col-sm-6 col-md-3 px-3 py-1 h-100">
                <CardLink
                  classOutter=""
                  title={`Submitted (${num_of_submitted_quotations})`}
                  content={
                    <div className="font-larger text-break">
                      <strong>{totalSalesWithVat(submitted_total_sales).toLocaleString()}</strong>THB
                    </div>
                  }
                  link={`/${API_RESOURCES.quotation}?state=${QUO_STATES.submitted.name}&date_from=${this.state.from_date}&date_to=${this.state.to_date}`}
                />
              </div>

              <div className="col-12 col-sm-6 col-md-3 px-3 py-1 h-100">
                <CardLink
                  classOutter=""
                  title={`Approved (${num_of_approved_quotations})`}
                  content={
                    <div className="font-larger text-break">
                      <strong>{totalSalesWithVat(approved_total_sales).toLocaleString()}</strong>THB
                    </div>
                  }
                  link={`/${API_RESOURCES.quotation}?state=${QUO_STATES.approved.name},${QUO_STATES.order_issued_partially.name},${QUO_STATES.order_issued_completed.name}&date_from=${this.state.from_date}&date_to=${this.state.to_date}`}
                />
              </div>
            </div>
          </div>

          {/* PO */}
          <div className="col-12 offset-md-1 col-md-10 outer-card-md">
            <div className="row d-none d-md-block mb-2">
              <div className="col d-flex flex-row">
                <h2 className="mb-0 pt-2">{APP_TITLE_ORDER}</h2>
                <div className="pt-3 mt-1 pl-2 text-truncate">{`(${totalSalesWithVat(
                  order_payment_total_sales
                ).toLocaleString()} / ${totalSalesWithVat(order_all_total_sales).toLocaleString()})`}</div>

                <a className="ml-2" href={`/${API_RESOURCES.order}`}>
                  <button type="button" className="btn btn-outline-secondary mt-2">
                    All
                  </button>
                </a>
              </div>
            </div>

            <div className="row d-md-none">
              <div className="col d-flex flex-row">
                <h3 className="mr-auto">{APP_TITLE_ORDER}</h3>
                <a className="ml-2" href={`/${API_RESOURCES.order}`}>
                  <button type="button" className="btn btn-outline-secondary btn-sm">
                    All
                  </button>
                </a>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6 col-md-3 px-3 py-1 h-100">
                <CardLink
                  classOutter=""
                  title={`In Progress (${num_of_in_progress_orders})`}
                  content={
                    <div className="font-larger text-break">
                      <strong>{totalSalesWithVat(order_in_progress_total_sales).toLocaleString()}</strong>THB
                    </div>
                  }
                  link={`/${API_RESOURCES.order}?state=${ORDER_STATES.in_progress.name}&date_from=${this.state.from_date}&date_to=${this.state.to_date}`}
                />
              </div>

              <div className="col-12 col-sm-6 col-md-3 px-3 py-1 h-100">
                <CardLink
                  classOutter=""
                  title={`Completed (${num_of_completed_orders})`}
                  content={
                    <div className="font-larger text-break">
                      <strong>{totalSalesWithVat(order_completed_total_sales).toLocaleString()}</strong>THB
                    </div>
                  }
                  link={`/${API_RESOURCES.order}?state=${ORDER_STATES.completed.name}&date_from=${this.state.from_date}&date_to=${this.state.to_date}`}
                />
              </div>
            </div>

            {/* <div className="row">
              <div className="col-12 col-sm-6 col-md-3 px-3 py-1 h-100">
                <CardLink
                  classOutter=""
                  title={`In Progress (${num_of_draft_pos})`}
                  content={<div className="font-larger text-break"><strong>{(totalSalesWithVat(po_draft_total_sales)).toLocaleString()}</strong>THB</div>}
                  link={`/${API_RESOURCES.po}?state=in%20progress`}
                />
              </div>

              <div className="col-12 col-sm-6 col-md-3 px-3 py-1 h-100">
                <CardLink
                  classOutter=""
                  title={`Completed (${num_of_submitted_pos})`}
                  content={<div className="font-larger text-break"><strong>{(totalSalesWithVat(po_submitted_total_sales)).toLocaleString()}</strong>THB</div>}
                  link={`/${API_RESOURCES.po}?state=completed`}
                />
              </div>
            </div> */}
          </div>

          {/* MR */}
          <div className="col-12 offset-md-1 col-md-10 outer-card-md mb-4">
            <div className="row d-none d-md-block mb-2">
              <div className="col d-flex flex-row">
                <h2 className="mb-0 pt-2">{APP_TITLE_MR}</h2>

                <a className="ml-4" href={`/${API_RESOURCES.mr}/new`}>
                  <button type="button" className="btn btn-outline-secondary mt-2">
                    New
                  </button>
                </a>
                <a className="ml-2" href={`/${API_RESOURCES.mr}`}>
                  <button type="button" className="btn btn-outline-secondary mt-2">
                    All
                  </button>
                </a>
              </div>
            </div>

            <div className="row d-md-none">
              <div className="col d-flex flex-row">
                <h3 className="mr-auto">{APP_TITLE_MR}</h3>
                <a className="ml-2" href={`/${API_RESOURCES.mr}/new`}>
                  <button type="button" className="btn btn-outline-secondary btn-sm">
                    New
                  </button>
                </a>
                <a className="ml-2" href={`/${API_RESOURCES.mr}`}>
                  <button type="button" className="btn btn-outline-secondary btn-sm">
                    All
                  </button>
                </a>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6 col-md-3 px-3 py-1 h-100">
                <CardLink
                  classOutter=""
                  title={`Draft (${num_of_draft_mrs})`}
                  content={<div className="font-larger text-break"></div>}
                  link={`/${API_RESOURCES.mr}?state=draft&date_from=${this.state.from_date}&date_to=${this.state.to_date}`}
                />
              </div>

              <div className="col-12 col-sm-6 col-md-3 px-3 py-1 h-100">
                <CardLink
                  classOutter=""
                  title={`Submitted (${num_of_submitted_mrs})`}
                  content={<div className="font-larger text-break"></div>}
                  link={`/${API_RESOURCES.mr}?state=submitted&date_from=${this.state.from_date}&date_to=${this.state.to_date}`}
                />
              </div>
            </div>
          </div>

          {/* Inbound */}
          <div className="col-12 offset-md-1 col-md-10 outer-card-md">
            <div className="row d-none d-md-block mb-2">
              <div className="col d-flex flex-row">
                <h2 className="mb-0 pt-2">ออร์เดอร์เข้า DC</h2>
                {/* <div className="pt-3 mt-1 pl-2 text-truncate">{`(${totalSalesWithVat(all_total_sales - draft_total_sales - submitted_total_sales).toLocaleString()} / ${totalSalesWithVat(all_total_sales).toLocaleString()})`}</div> */}

                <a className="ml-2" href={`/dc_inbound_orders/create`}>
                  <button type="button" className="btn btn-outline-secondary mt-2">
                    New
                  </button>
                </a>
                <a className="ml-2" href={`/dc_inbound_orders`}>
                  <button type="button" className="btn btn-outline-secondary mt-2">
                    All
                  </button>
                </a>
              </div>
            </div>

            <div className="row d-md-none">
              <div className="col d-flex flex-row">
                <h3 className="mr-auto">ออร์เดอร์เข้า DC</h3>
                <a className="ml-2" href={`/dc_inbound_orders/create`}>
                  <button type="button" className="btn btn-outline-secondary btn-sm">
                    New
                  </button>
                </a>
                <a className="ml-2" href={`/dc_inbound_orders`}>
                  <button type="button" className="btn btn-outline-secondary btn-sm">
                    All
                  </button>
                </a>
              </div>
            </div>

            <div className="row">
              {this.state.dashboard.inbound_orders &&
                this.state.dashboard.inbound_orders.map((item, index) => {
                  let word = item.state.replace('_', ' ')
                  let capitalized = word.charAt(0).toUpperCase() + word.slice(1)
                  return (
                    <div className="col-12 col-sm-6 col-md-3 px-3 py-1 h-100">
                      <CardLink
                        classOutter=""
                        title={`${capitalized} (${item.count})`}
                        content={
                          <div className="font-larger text-break">
                            <strong>{totalSalesWithVat(item.total_cost).toLocaleString()} </strong>THB
                          </div>
                        }
                        link={`/dc_inbound_orders?state=${item.state_id}&start_date=${this.state.from_date}&end_date=${this.state.to_date}`}
                      />
                    </div>
                  )
                })}
            </div>
          </div>

          {/* Outbound */}
          <div className="col-12 offset-md-1 col-md-10 outer-card-md">
            <div className="row d-none d-md-block mb-2">
              <div className="col d-flex flex-row">
                <h2 className="mb-0 pt-2">ออร์เดอร์ขาออก DC</h2>
                <div className="pt-3 mt-1 pl-2 text-truncate">{`(${totalSalesWithVat(totalOurbound).toLocaleString()})`}</div>

                <a className="ml-2" href={`/${API_RESOURCES.order}`}>
                  <button type="button" className="btn btn-outline-secondary mt-2">
                    All
                  </button>
                </a>
              </div>
            </div>

            <div className="row d-md-none">
              <div className="col d-flex flex-row">
                <h3 className="mr-auto">ออร์เดอร์ขาออก DC</h3>
                <a className="ml-2" href={`/${API_RESOURCES.order}`}>
                  <button type="button" className="btn btn-outline-secondary btn-sm">
                    All
                  </button>
                </a>
              </div>
            </div>

            <div className="row">
              {this.state.dashboard.outbound_orders &&
                this.state.dashboard.outbound_orders.map((item, index) => {
                  let word = item.state.replace('_', ' ')
                  let capitalized = word.charAt(0).toUpperCase() + word.slice(1)
                  if (item.state != 'po_payment_compeleted')
                    return (
                      <div className="col-12 col-sm-6 col-md-3 px-3 py-1 h-100">
                        <CardLink
                          classOutter=""
                          title={`${capitalized} (${item.count})`}
                          content={
                            <div className="font-larger text-break">
                              <strong>{totalSalesWithVat(item.total_prices).toLocaleString()}</strong>THB
                            </div>
                          }
                          link={`/orders?state=${item.state}&date_from=${this.state.from_date}&date_to=${this.state.to_date}`}
                        />
                      </div>
                    )
                })}
            </div>
          </div>

          {/* Log Dashboard */}
          <div className="col-12 offset-md-1 col-md-10 outer-card-md mb-4">
            <div className="row d-none d-md-block mb-2">
              <div className="col d-flex flex-row">
                <h2 className="mb-0 pt-2">{'การเปลี่ยนแปลง'}</h2>
                <a className="ml-2" onClick={() => this.handleLogAll(true)}>
                  <button type="button" className="btn btn-outline-secondary mt-2">
                    All
                  </button>
                </a>
              </div>
            </div>

            <div className="row d-md-none">
              <div className="col d-flex flex-row">
                <h3 className="mr-auto">{'การเปลี่ยนแปลง'}</h3>
                <a className="ml-2" onClick={() => this.handleLogAll(true)}>
                  <button type="button" className="btn btn-outline-secondary btn-sm">
                    All
                  </button>
                </a>
              </div>
            </div>

            <div className="row" style={{ flexDirection: 'column', gap: 10, padding: '0 20px' }}>
              <div className="d-none d-md-block">
                <div
                  className="col-24 px-3 py-1"
                  style={{
                    height: 60,
                    textAlign: 'center',
                    borderRadius: 10,
                    background: 'white',
                    display: 'grid',
                    gap: 20,
                    gridTemplateColumns: '12% 20% 20% 20% 15%',
                    border: '1px solid #e8e8e8',
                  }}
                >
                  <div className="dashboard_installation">Order</div>
                  <div className="dashboard_installation">Vendor</div>
                  <div className="dashboard_installation">สินค้า</div>
                  <div className="dashboard_installation">วันติดตั้งก่อนหน้า > ปัจจุบัน</div>
                  <div className="dashboard_installation">วันที่แจ้ง (ผู้แจ้ง)</div>
                </div>
              </div>
              {dashboard &&
                dashboard.logs &&
                dashboard.logs.log_change_installation.map((log, index) => {
                  return (
                    <>
                      <div ket={index} className="row d-md-none" style={{ flexDirection: 'column', gap: 10, padding: '0 20px' }}>
                        <Link
                          className="custom-link"
                          to={`/${log.type === 'purchasing' ? API_RESOURCES.order : API_RESOURCES.quotation}/${log.order_id}`}
                        >
                          <div
                            className="col-12 col-sm-12 col-md-3 px-3 py-1 h-100"
                            style={{
                              cursor: 'pointer',
                              textAlign: 'left',
                              borderRadius: 10,
                              background: 'white',
                              display: 'grid',
                              gap: 10,
                              gridTemplateColumns: '140px auto',
                              border: '1px solid #e8e8e8',
                            }}
                          >
                            <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                              <div className="ellipsis">Order : </div>
                              <div className="ellipsis">Vendor : </div>
                              <div className="ellipsis">สินค้า : </div>
                              <div className="ellipsis">วันติดตั้งก่อนหน้า > ปัจจุบัน : </div>
                              <div className="ellipsis">วันที่แจ้ง (ผู้แจ้ง) : </div>
                            </div>
                            <div className="ellipsis" style={{ width: '100%' }}>
                              <OverlayTrigger
                                key={index}
                                placement={'bottom'}
                                overlay={
                                  <Tooltip id={`tooltip-${index}`}>
                                    <div>{log.order_code || '-'}</div>
                                  </Tooltip>
                                }
                              >
                                <div className="ellipsis">{log.order_code || '-'}</div>
                              </OverlayTrigger>
                              <OverlayTrigger
                                key={index}
                                placement={'bottom'}
                                overlay={
                                  <Tooltip id={`tooltip-${index}`}>
                                    <div>{log.vendor_name}</div>
                                  </Tooltip>
                                }
                              >
                                <div className="ellipsis">{log.vendor_name}</div>
                              </OverlayTrigger>
                              <OverlayTrigger
                                key={index}
                                placement={'bottom'}
                                overlay={
                                  <Tooltip id={`tooltip-${index}`}>
                                    <div>{log.item_name}</div>
                                  </Tooltip>
                                }
                              >
                                <div className="ellipsis">{log.item_name}</div>
                              </OverlayTrigger>
                              <OverlayTrigger
                                key={index}
                                placement={'bottom'}
                                overlay={
                                  <Tooltip id={`tooltip-${index}`}>
                                    <div>
                                      {log.prev_installation_date !== 'None' && log.new_installation_date !== 'None'
                                        ? `${log.prev_installation_date} > ${log.new_installation_date}` || '-'
                                        : '-'}
                                    </div>
                                  </Tooltip>
                                }
                              >
                                <div className="ellipsis">
                                  {log.prev_installation_date !== 'None' && log.new_installation_date !== 'None'
                                    ? `${log.prev_installation_date} > ${log.new_installation_date}` || '-'
                                    : '-'}
                                </div>
                              </OverlayTrigger>
                              <OverlayTrigger
                                key={index}
                                placement={'bottom'}
                                overlay={
                                  <Tooltip id={`tooltip-${index}`}>
                                    <div>{`${moment(log.created_at).format('YYYY-MM-DD')} (${log.owner || '-'})`}</div>
                                  </Tooltip>
                                }
                              >
                                <div className="ellipsis">{`${moment(log.created_at).format('YYYY-MM-DD')} (${log.owner || '-'})`}</div>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="d-none d-md-block">
                        <Link
                          className="custom-link"
                          to={`/${log.type === 'purchasing' ? API_RESOURCES.order : API_RESOURCES.quotation}/${log.order_id}`}
                        >
                          <div
                            className="col-24 px-3 py-1 h-100"
                            style={{
                              cursor: 'pointer',
                              textAlign: 'center',
                              borderRadius: 10,
                              background: 'white',
                              display: 'grid',
                              gap: 20,
                              gridTemplateColumns: '12% 20% 20% 20% 15%',
                              border: '1px solid #e8e8e8',
                            }}
                          >
                            <OverlayTrigger
                              key={index}
                              placement={'bottom'}
                              overlay={
                                <Tooltip id={`tooltip-${index}`}>
                                  <div>{log.order_code || '-'}</div>
                                </Tooltip>
                              }
                            >
                              <div className="ellipsis">{log.order_code || '-'}</div>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key={index}
                              placement={'bottom'}
                              overlay={
                                <Tooltip id={`tooltip-${index}`}>
                                  <div>{log.vendor_name}</div>
                                </Tooltip>
                              }
                            >
                              <div className="ellipsis">{log.vendor_name}</div>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key={index}
                              placement={'bottom'}
                              overlay={
                                <Tooltip id={`tooltip-${index}`}>
                                  <div>{log.item_name}</div>
                                </Tooltip>
                              }
                            >
                              <div className="ellipsis">{log.item_name}</div>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key={index}
                              placement={'bottom'}
                              overlay={
                                <Tooltip id={`tooltip-${index}`}>
                                  <div>
                                    {log.prev_installation_date !== 'None' && log.new_installation_date !== 'None'
                                      ? `${log.prev_installation_date} > ${log.new_installation_date}` || '-'
                                      : '-'}
                                  </div>
                                </Tooltip>
                              }
                            >
                              <div className="ellipsis">
                                {log.prev_installation_date !== 'None' && log.new_installation_date !== 'None'
                                  ? `${log.prev_installation_date} > ${log.new_installation_date}` || '-'
                                  : '-'}
                              </div>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key={index}
                              placement={'bottom'}
                              overlay={
                                <Tooltip id={`tooltip-${index}`}>
                                  <div>{`${moment(log.created_at).format('YYYY-MM-DD')} (${log.owner || '-'})`}</div>
                                </Tooltip>
                              }
                            >
                              <div className="ellipsis">{`${moment(log.created_at).format('YYYY-MM-DD')} (${log.owner || '-'})`}</div>
                            </OverlayTrigger>
                          </div>
                        </Link>
                      </div>
                    </>
                  )
                })}
            </div>
          </div>

          <ExportProcurementModal
            page_count={this.state.page_count}
            getLogChangeLog={this.getLogChangeLog}
            handleLogSearch={this.handleLogSearch}
            API_RESOURCES={API_RESOURCES}
            logList={this.state.logList}
            isVisible={this.state.isVisible}
            handleCancel={this.handleCancel}
            handleClickSearch={this.handleClickSearch}
          />
          {/* Log Dashboard */}

          <SettingQuery
            filters={this.state.filters}
            currentSetting={this.state.selected_setting}
            onChangeFilterType={this.onChangeFilterType}
            onChangeFilterYear={this.onChangeFilterYear}
            onChangeFilterPeriod={this.onChangeFilterPeriod}
            onHandleSetting={this.onHandleSetting}
            onSubmitSetting={this.onSubmitSetting}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default Home

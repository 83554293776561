import React from 'react'
import { InputNumber } from 'antd'
import PropTypes from 'prop-types'

class Number extends React.Component {
  constructor(props) {
    super(props)

    this.onChangeValue = this.onChangeValue.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  onChangeValue(e, name) {
    let result = { target: { name: name } }
    result.target.value = e || 0
    this.props.onChange(result)
  }

  handleKeyPress(e) {
    if (isNaN(e.key)) {
      e.preventDefault()
    }
  }

  render() {
    let { placeholder, disabled, name, innerClass, value } = this.props
    return (
      <React.Fragment>
        <InputNumber
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', padding: '0px', backgroundColor: disabled ? '#e9ecef' : 'none' }}
          disabled={disabled}
          placeholder={placeholder}
          min={0}
          // precision={2}
          defaultValue={0}
          value={value || parseFloat(0)}
          className={`form-control ${innerClass}`}
          controls={false}
          stringMode={false}
          onPressEnter={this.handleKeyPress}
          onChange={(e) => this.onChangeValue(e, name)}
        />
      </React.Fragment>
    )
  }
}
export default Number

Number.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  innerClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

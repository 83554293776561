import React from "react";
import DatePicker from "react-datepicker";
import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { PAYMENT_METHODS, API_RESOURCES, FORMAT_APP_DATE } from "../../constants.js";

// Models

// Component
import { OrderFormOtherInfo } from "./OrderFormOtherInfo";
import BankSelect from "../global/BankSelect";
import ErrorMessage from "../global/ErrorMessage";

// Functions
import { objectPreventNull } from "../../functions/Object";
import { formatDate } from "../../functions/Date";

export class OrderFormInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeCustomerStationDetails = (e) => {
    this.props.onChangeQuotationDetails(e, "client_station");
  };

  onChangeBuyerDetails = (e) => {
    this.props.onChangeQuotationDetails(e, "buyer");
  };

  render() {
    let { order, disabled } = this.props;
    let { client_station, buyer } = order.quotation;
    let user = order.quotation.user;
    return (
      <React.Fragment>
        {/* User Info */}
        <h5 className="pb-1">ข้อมูลทั่วไป</h5>
        <div className="card-box p-3 mt-1">
          <div className="row">
            <div className="col-4 col-md-2 pr-0 mt-2">
              <label>เลขที่ใบเสนอราคา</label>
            </div>
            <div className="col mt-2">{<a href={`/${API_RESOURCES.quotation}/${order.quotation.id}`}>{order.quotation.code}</a> || "-"}</div>
            <div className="col-4 col-md-2 pr-0 mt-2">
              <label>ผู้ออกใบเสนอราคา</label>
            </div>
            <div className="col mt-2">
              {objectPreventNull(user, "firstname")} {objectPreventNull(user, "lastname")}
            </div>
          </div>
          <div className="row">
            <div className="col-4 col-md-2 pr-0 mt-2">
              <label>วันส่งใบเสนอราคา</label>
            </div>
            <div className="col mt-2">{formatDate(order.quotation.submitted_date)}</div>
            <div className="col-4 col-md-2 pr-0 mt-2">
              <label>วันที่อนุมัติ</label>
            </div>
            <div className="col mt-2">{formatDate(order.quotation.approved_date)}</div>
          </div>
          <div className="row">
            <div className="col-4 col-md-2 pr-0 mt-2">
              <label>เลขที่ออร์เดอร์ (ของระบบ)</label>
            </div>
            <div className="col mt-2">{order.code}</div>
          </div>
          <div className="row">
            <div className="col-4 col-md-2 pr-0 mt-2">
              <label className="sign-is-required">วันที่ ORDER</label>
            </div>
            <div className="col-8 col-md-4 date-picker-wrapper mt-2">
              <DatePicker className="form-control" dateFormat={FORMAT_APP_DATE} selected={new Date(order.po_date)} onChange={(date) => this.props.onChangeOrderDate(date, "po_date")} disabled={disabled} />
              <ErrorMessage errorKey={"po_date"} errorMessages={this.props.errors} />
            </div>
            <div className="col-4 col-md-2 pr-0 mt-2">
              <label className="sign-is-required">วันจัดส่ง</label>
            </div>
            <div className="col-8 col-md-4 date-picker-wrapper mt-2">
              <DatePicker className="form-control" dateFormat={FORMAT_APP_DATE} selected={new Date(order.delivery_date)} onChange={(date) => this.props.onChangeOrderDate(date, "delivery_date")} disabled={disabled} />
              <ErrorMessage errorKey={"delivery_date"} errorMessages={this.props.errors} />
            </div>
          </div>
        </div>

        {/* Customer Station Info */}
        <h5 className="mt-4 pb-1">ข้อมูลสถานี</h5>
        <div className="card-box p-3 mt-1">
          <div className="row mt-2">
            <div className="col-4 col-md-2 pr-0">
              <label>สถานี/ชื่อลูกค้า</label>
            </div>
            <div className="col">{order.station_name}</div>
          </div>
          <div className="row">
            <div className="col-4 col-md-2 pr-0 mt-2">
              <label>Sold-to</label>
            </div>
            <div className="col-8 col-md-4 mt-2">
              <input type="id" className="form-control" id="quotationSoldToCode" name="sold_to_code" onChange={this.onChangeCustomerStationDetails} value={client_station.sold_to_code} disabled={disabled}></input>
            </div>
            <div className="col-4 col-md-2 pr-0 mt-2">
              <label>Ship-to</label>
            </div>
            <div className="col-8 col-md-4 mt-2">
              <input type="id" className="form-control" id="quotationShipToCode" name="ship_to_code" onChange={this.onChangeCustomerStationDetails} value={client_station.ship_to_code} disabled={disabled}></input>
            </div>
          </div>
        {!order.quotation.has_buyer ? (  <div className="row"> 
            <div className="col-4 col-md-2 pr-0 mt-2">
              <label>ชื่อผู้ติดต่อ</label>
            </div>
            <div className="col-8 col-md-4 mt-2">
              <input type="id" className="form-control" id="quotationBuyerName" name="buyer_name" onChange={this.props.onChangeOrderDetails} value={order.buyer_name} disabled={disabled}></input>
            </div>
            <div className="col-4 col-md-2 pr-0 mt-2">
              <label>เบอร์ติดต่อ</label>
            </div>
            <div className="col-8 col-md-4 mt-2">
              <input type="id" className="form-control" id="quotationBuyerPhone" name="buyer_phone" onChange={this.props.onChangeOrderDetails} value={order.buyer_phone} disabled={disabled}></input>
            </div>
          </div> ) : ( "")}
        
        </div>

        {/* Buyer Info */}
        {order.quotation.has_buyer ? (
          <div> 
            <h5 className="mt-4 pb-1">ข้อมูลผู้ซื้อ</h5>
            <div className="card-box p-3 mt-1">
              <div className="row mt-2">
                <div className="col-4 col-md-2 pr-0">
                  <label>ชื่อผู้ซื้อ</label>
                </div>
                <div className="col">{buyer.name}</div>
              </div>
              <div className="row">
                <div className="col-4 col-md-2 pr-0 mt-2">
                  <label>Sold-to</label>
                </div>
                <div className="col-8 col-md-4 mt-2">
                  <input type="id" className="form-control" id="buyerSoldToCode" name="sold_to_code" onChange={this.onChangeBuyerDetails} value={buyer.sold_to_code} disabled={disabled}></input>
                </div>
                <div className="col-4 col-md-2 pr-0 mt-2">
                  <label>Ship-to</label>
                </div>
                <div className="col-8 col-md-4 mt-2">
                  <input type="id" className="form-control" id="buyerShipToCode" name="ship_to_code" onChange={this.onChangeBuyerDetails} value={buyer.ship_to_code} disabled={disabled}></input>
                </div>
              </div>

              <div className="row">
                <div className="col-4 col-md-2 pr-0 mt-2">
                  <label>ชื่อผู้ติดต่อ</label>
                </div>
                <div className="col-8 col-md-4 mt-2">
                {/* <input type="id" className="form-control" id="buyerContactPerson" name="buyer_contact_person" onChange={this.onChangeBuyerDetails} value={buyer.buyer_contact_person} disabled={disabled}></input> */} 
                  <input type="id" className="form-control" id="quotationBuyerName" name="buyer_name" onChange={this.props.onChangeOrderDetails} value={order.buyer_name} disabled={disabled}></input>
                </div>
                <div className="col-4 col-md-2 pr-0 mt-2">
                  <label>เบอร์ติดต่อ</label>
                </div>
                <div className="col-8 col-md-4 mt-2">
                {/* <input type="id" className="form-control" id="buyerBuyerTel" name="buyer_tel" onChange={this.onChangeBuyerDetails} value={buyer.buyer_tel} disabled={disabled}></input> */} 
                <input type="id" className="form-control" id="quotationBuyerPhone" name="buyer_phone" onChange={this.props.onChangeOrderDetails} value={order.buyer_phone} disabled={disabled}></input>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* Other Info */}
        <OrderFormOtherInfo page_nb={this.props.page_nb} note={this.props.order.order_notes.filter((elm) => elm.page_nb === this.props.page_nb)[0]} onChangeNote={this.props.onChangeNote} disabled={this.props.disabled} key={this.props.resetKeyNote} />
        <div className="block-info-buffer-box"></div>
      </React.Fragment>
    );
  }
}

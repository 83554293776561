import React from 'react'
import DatePicker from 'react-datepicker'
// import Select from "react-select";
import axios from 'axios'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import { withRouter } from 'react-router-dom'
// import _ from "lodash";
import { Input } from 'antd'

import { PlusOutlined } from '@ant-design/icons'

// Components
import Spinner from '../../global/Spinner'
// import OrderProgressFilters from "../../order/OrderProgressFilters";
import ExportExcel from '../../global/ExportExcel'
import SearchBar from '../../global/SearchBar'
import ReportTable from './table'

// Functions
import { uniqueKey, generateID } from '../../../functions/Random'
import { objectIsEmpty } from '../../../functions/Object'
import { getHeaderTotalPageCount } from '../../../functions/Response'
import { getDateFromToday, formatDate, formatApiDate } from '../../../functions/Date'

// Models
import { ORDER_PROGRESS_FLAGS } from '../../../models/Orders'
import { REPORT_TYPES, set_temp_order, toExcelQuotationReport } from '../../../models/Reports'

import { ORDER_STATES, API_RESOURCES, FORMAT_APP_DATE, FORMAT_DATE, PAYMENT_METHODS } from '../../../constants'
import { Button } from 'antd'

const DEFAULT_SORTING = 'created_at'
const DEFAULT_STATE = []
const OPTIONS_STATES = [{ value: 'pending order', label: 'pending order' }]
Object.keys(ORDER_STATES).map((key) => {
  OPTIONS_STATES.push({ value: ORDER_STATES[key].name, label: ORDER_STATES[key].name })
})

const DEFAULT_FLAG_FILTER = {}
for (let key of Object.keys(ORDER_PROGRESS_FLAGS)) {
  DEFAULT_FLAG_FILTER[key] = null
}

let searchParams = {}

class OrderReport extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      is_loading: false,
      query: '',
      selected_state: DEFAULT_STATE,
      selected_sorting: DEFAULT_SORTING,
      selected_date_form: null,
      selected_date_to: null,
      selected_submitted_date_form: getDateFromToday(-30),
      selected_submitted_date_to: new Date(),
      selected_flags: {},
      flag_filters: cloneDeep(DEFAULT_FLAG_FILTER),
      quotations: [],
      selected_order_cate_id: null,
      is_refiltering: true,
      owner_search: '',

      bank_list: [],

      // pagination
      page: 0,
      per_page: 100,
      page_count: 1,

      resetKey: uniqueKey(),

      // export
      export_report: null,
    }
  }

  componentDidMount() {
    if (this.props.location.search) {
      this.getPathParams()
    } else {
      this.getOrder()
    }
  }

  componentDidUpdate() {
    if (this.state.is_refiltering) {
      this.getOrder()
    }
  }

  getBanks() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/banks`)
      .then((response) => {
        this.setState({
          bank_list: response.data,
        })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  OnClickMainSave() {
    const { quotations } = this.state
    this.setState({
      is_loading: true,
    })
    axios
      .put(`${process.env.REACT_APP_API_URL}/order_report`, quotations)
      .then((response) => {
        alert('Report date updated!')
        this.setState({
          is_loading: false,
        })
      })
      .catch((error) => {
        alert('Report date failed!')
        this.setState({
          is_loading: false,
        })
      })
  }

  setQuotations(quotations) {
    this.setState({
      quotations: quotations,
    })
  }

  getPathParams() {
    const query = new URLSearchParams(this.props.location.search).get('query') || ''
    const owner = new URLSearchParams(this.props.location.search).get('owner') || ''
    const start_date = new URLSearchParams(this.props.location.search).get('start_date') || ''
    const end_date = new URLSearchParams(this.props.location.search).get('end_date') || ''
    const state = new URLSearchParams(this.props.location.search).get('state') || ''
    const start_created_date = new URLSearchParams(this.props.location.search).get('start_created_date') || ''
    const end_created_date = new URLSearchParams(this.props.location.search).get('end_created_date') || ''

    let search_state = []

    if (query !== '') searchParams['query'] = query
    if (owner !== '') searchParams['owner'] = owner
    if (start_date) searchParams['start_date'] = start_date
    if (end_date) searchParams['end_date'] = end_date
    if (start_created_date) searchParams['start_created_date'] = start_created_date
    if (end_created_date) searchParams['end_created_date'] = end_created_date

    Object.keys(DEFAULT_FLAG_FILTER).forEach((flag) => {
      const flag_search = new URLSearchParams(this.props.location.search).get(flag) || null
      if (flag_search) DEFAULT_FLAG_FILTER[flag] = flag_search === 'true'
      else DEFAULT_FLAG_FILTER[flag] = null
    })

    if (state) {
      state.split(',').forEach((search) => {
        search_state.push({ value: search, label: search })
      })
    }

    this.setState({
      owner_search: owner,
      query: query,
      selected_date_form: start_date ? moment(start_date).toDate() : '',
      selected_date_to: end_date ? moment(end_date).toDate() : '',
      selected_submitted_date_form: start_created_date ? moment(start_created_date).toDate() : getDateFromToday(-30),
      selected_submitted_date_to: end_created_date ? moment(end_created_date).toDate() : new Date(),
      flag_filters: DEFAULT_FLAG_FILTER,
      selected_state: search_state,
    })

    // this.getOrder();
  }

  getOrder(resetPage) {
    // filter: {}
    // range: [0,9]
    // sort: ["code","ASC"]
    let {
      owner_search,
      page,
      per_page,
      selected_sorting,
      selected_sorting_reversed,
      selected_state,
      selected_date_form,
      selected_date_to,
      query,
      flag_filters,
      selected_submitted_date_form,
      selected_submitted_date_to,
    } = this.state
    let de_per_page = per_page
    let target_page = resetPage ? 0 : page
    let range_start = target_page * de_per_page
    let sort_dir = selected_sorting_reversed ? 'ASC' : 'DESC'
    let options = {
      sort: JSON.stringify([selected_sorting, sort_dir]),
      filter: {},
      range: JSON.stringify([range_start, range_start + per_page - 1]),
    }

    if (!objectIsEmpty(selected_state)) {
      options.filter.order_state = selected_state.map((elm) => {
        return elm.value
      })
    }
    if (selected_date_form || selected_date_to) {
      let start_date = selected_date_form ? moment(selected_date_form).format(FORMAT_DATE) : null
      let end_date = selected_date_to ? moment(selected_date_to).format(FORMAT_DATE) : null
      options.filter.po_date = [start_date, end_date]
    }
    if (selected_submitted_date_form || selected_submitted_date_to) {
      let submitted_start_date = selected_submitted_date_form ? moment(selected_submitted_date_form).format(FORMAT_DATE) : null
      let submitted_end_date = selected_submitted_date_to ? moment(selected_submitted_date_to).format(FORMAT_DATE) : null
      options.filter.created_at = [submitted_start_date, submitted_end_date]
    }
    if (query) {
      options.filter.query = query
    }

    if (owner_search) {
      options.filter.owner = owner_search
    }
    // Flag filters
    Object.keys(flag_filters).map((key, index) => {
      if (flag_filters[key] != null) {
        options.filter[`order_flag.${key}`] = flag_filters[key]
      }
    })

    axios
      .get(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.po}/report`, {
        params: options,
      })
      .then((response) => {
        let page_count = getHeaderTotalPageCount(response, this.state.per_page)
        let export_report = toExcelQuotationReport(response.data)

        this.setState({
          quotations: response.data,
          export_report: export_report,
          is_refiltering: false,
          page_count: page_count,
          resetKey: resetPage ? uniqueKey() : this.state.resetKey,
        })
        this.getBanks()
      })
      .catch((error) => {
        this.setState({
          is_refiltering: false,
        })
      })
      .then(() => {
        // always executed
      })
  }

  onClickClear = () => {
    this.setState({
      selected_state: DEFAULT_STATE,
      selected_period: '',
      selected_id: '',
      selected_station_name: '',
      selected_sorting: DEFAULT_SORTING,
      selected_sorting_reversed: true,
      selected_date_form: null,
      selected_date_to: null,
      selected_submitted_date_form: getDateFromToday(-30),
      selected_submitted_date_to: new Date(),
      page: 0,
      query: '',
      flag_filters: cloneDeep(DEFAULT_FLAG_FILTER),
      is_refiltering: true,
      resetKey: uniqueKey(),
    })

    for (let key of Object.keys(ORDER_PROGRESS_FLAGS)) {
      DEFAULT_FLAG_FILTER[key] = null
    }

    searchParams = {}
    this.props.history.replace({ pathname: `${this.props.location.pathname}`, search: null })
  }

  onChangeQuery = (e) => {
    if (e.target.value) {
      searchParams['query'] = e.target.value
    } else {
      delete searchParams['query']
    }

    this.setState({ query: e.target.value })
  }

  onChangeOwnerSearch = (e) => {
    if (e.target.value) {
      searchParams['owner'] = e.target.value
    } else {
      delete searchParams['owner']
    }

    this.setState({ owner_search: e.target.value })
  }

  onChangeState = (e) => {
    if (e.length > 0) {
      let state_search = []
      e.forEach((state) => {
        state_search.push(state.value)
      })
      searchParams['state'] = state_search.join(',')
    } else {
      delete searchParams['state']
    }

    this.setState({ selected_state: e })
  }

  onChangeFromDate = (date) => {
    searchParams['start_date'] = moment(date).format('MM/DD/YYYY')
    this.setState({ selected_date_form: date })
  }

  onChangeToDate = (date) => {
    searchParams['end_date'] = moment(date).format('MM/DD/YYYY')
    this.setState({ selected_date_to: date })
  }

  onChangeSubmittedFromDate = (date) => {
    searchParams['start_created_date'] = moment(date).format('MM/DD/YYYY')
    this.setState({ selected_submitted_date_form: date })
  }

  onChangeSubmittedToDate = (date) => {
    searchParams['end_created_date'] = moment(date).format('MM/DD/YYYY')
    this.setState({ selected_submitted_date_to: date })
  }

  onChangeFlag = (e) => {
    let { selected_flags } = this.state
    selected_flags[e.target.id] = e.target.checked
    this.setState({ selected_flags: selected_flags })
  }

  setFlagFilters = (filters) => {
    Object.keys(filters).forEach((key) => {
      if (filters[key] !== null) {
        DEFAULT_FLAG_FILTER[key] = filters[key]
        searchParams[key] = filters[key]
      } else {
        DEFAULT_FLAG_FILTER[key] = null
        delete searchParams[key]
      }
    })

    this.setState({ flag_filters: filters })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.addSearchParams(searchParams)
    this.setState({ is_refiltering: true })
  }

  addSearchParams(params, path = this.props.location.pathname) {
    let searchParams = ''
    Object.keys(params).forEach((key, index) => {
      searchParams = searchParams + `${Object.keys(params).length > 1 && index !== 0 ? '&' : ''}${key}=${params[key]}`
    })

    this.props.history.replace({ pathname: `${path}`, search: searchParams })
  }

  onClickOrderRow = (client_station_id) => {
    let { quotations } = this.state
    for (let quotation of quotations) {
      if (quotation.client_station.id === client_station_id) {
        quotation.opened = !quotation.opened
      }
    }
    this.setState({ quotations: quotations })
  }

  tableHeader() {
    return (
      <div className="card-box mt-2 p-2 d-none d-md-block">
        <div className="row">
          <div className="col d-flex flex-column flex-sm-row">
            <div className="d-flex-3 py-2">สถานี</div>
            <div className="d-flex-1 text-center py-2">ใบเสนอราคา</div>
            <div className="d-flex-1 text-center py-2">แจ้งผู้ผลิต</div>
            <div className="d-flex-1 text-center py-2">Confirm</div>
            <div className="d-flex-1 text-center py-2">OC sent</div>
            <div className="d-flex-1 text-center py-2">วันที่ชำระ</div>
            <div className="d-flex-1 text-center py-2">ตั้งหนี้</div>
            <div className="d-flex-1 text-center py-2">แจ้ง จส</div>
            <div className="d-flex-1 text-center py-2">PO/GR</div>
            <div className="d-flex-1 text-center py-2">Billing</div>
          </div>
        </div>
      </div>
    )
  }

  reportItemLayer(items) {
    return items.map((item, index) => {
      return (
        <div className="row p-1">
          <div className="col-10">
            {index + 1}. {item.name}
          </div>
          <div className="col">{item.qty}</div>
        </div>
      )
    })
  }

  onClickItem = (id) => {
    if (this.state.selected_order_cate_id !== id) {
      this.setState({ selected_order_cate_id: id })
    } else {
      this.setState({ selected_order_cate_id: null })
    }
  }

  displayItemInfo(order_cate, items) {
    return (
      <td className="td-info report-tooltip-wrapper align-middle" onClick={() => this.onClickItem(order_cate.id)}>
        {order_cate.sys_name}
        <span className="float-right">
          <i className="icon-info invert-02"></i>
        </span>
        {this.state.selected_order_cate_id === order_cate.id ? (
          <React.Fragment>
            <div className="report-tooltip-triangle"></div>
            <div className="report-tooltip">
              <div className="row m-0">
                <div className="col-10 p-1 header">ช่ือสินค้า</div>
                <div className="col p-1 header">จำนวน</div>
              </div>
              {this.reportItemLayer(items)}
            </div>
          </React.Fragment>
        ) : (
          ''
        )}
      </td>
    )
  }

  setIsLoading(loading) {
    this.setState({ is_loading: loading })
  }

  reportOrderLayer(quotation) {
    let record = []
    if (quotation.purchasing_orders.length === 0) {
      quotation.purchasing_orders.push(set_temp_order())
    }
    let nb_of_buf_lines = quotation.purchasing_orders.reduce((sum, elm) => (sum += elm.order_item_categories.length), 0)
    quotation.purchasing_orders.map((order, index) => {
      let nb_of_buf_contracts = order.order_item_categories.length
      order.order_item_categories.map((order_cate, sub_index) => {
        let contract = order.order_po_grs.filter((elm) => elm.contract_id === order_cate.contract_id)[0]
        let items = order.purchasing_order_items.filter((elm) => elm.item_category_id === order_cate.item_category_id && !elm.is_mbom)
        let num_of_installed_items = items.reduce((sum, item) => {
          if (item.installation_date) {
            sum += 1
          }
          return sum
        }, 0)
        const orderCode = () => {
          if (order.code !== '') {
            let orc_code = order.order_code ? (
              <span>
                <br />
                {`ORC-${order.order_code}`}
              </span>
            ) : (
              ''
            )
            let order_state =
              order.state === ORDER_STATES.cancelled.name ? (
                <span className="color-red">
                  <br />({order.state})
                </span>
              ) : (
                ''
              )
            return (
              <td rowSpan={nb_of_buf_contracts} className="align-middle">
                <a target="_blank" href={`/${API_RESOURCES.order}/${order.id}`}>
                  {order.code}
                  {orc_code}
                  {order_state}
                </a>
              </td>
            )
          } else {
            return (
              <td rowSpan={nb_of_buf_contracts} className="text-center">
                Pending Order
              </td>
            )
          }
        }

        const orderPayment = () => {
          if (order.payment_method) {
            let text =
              order.payment_method.method_type !== '' ? `${order.payment_method.method_type}-${formatDate(order.payment_method.payment_date)}` : ''
            if (order.payment_method.method_type === PAYMENT_METHODS.yodl) {
              return (
                <td rowSpan={nb_of_buf_contracts} className="text-center align-middle">
                  {text}
                  <br />
                  ตั้งหนี้-{formatDate(order.payment_method.set_debt_date)}
                </td>
              )
            } else {
              return (
                <td rowSpan={nb_of_buf_contracts} className="text-center align-middle">
                  {text}
                </td>
              )
            }
          } else {
            return <td rowSpan={nb_of_buf_contracts} className="text-center"></td>
          }
        }
        const orderOtherDetails = () => {
          if (contract) {
            return (
              <React.Fragment>
                <td className="text-center align-middle">{formatDate(contract.submit_date)}</td>
                <td className="text-center align-middle">{formatDate(contract.po_date)}</td>
                <td className="text-center align-middle">{contract.po_code}</td>
                <td className="text-center align-middle">{contract.gr_code}</td>
              </React.Fragment>
            )
          } else {
            return (
              <React.Fragment>
                <td className="text-center"></td>
                <td className="text-center"></td>
                <td className="text-center"></td>
                <td className="text-center"></td>
              </React.Fragment>
            )
          }
        }
        const orderCodeStr = orderCode()
        if (index === 0 && sub_index === 0) {
          record.push(
            <tr key={'' + sub_index + orderCodeStr}>
              <td rowSpan={nb_of_buf_lines} className="align-middle">
                <a target="_blank" href={`/${API_RESOURCES.quotation}/${quotation.id}`}>
                  {quotation.code}
                </a>
              </td>
              <td rowSpan={nb_of_buf_lines} className="text-center align-middle">
                {formatDate(quotation.approved_date)}
              </td>
              {orderCodeStr}
              {this.displayItemInfo(order_cate, items)}
              <td className="text-center align-middle">{formatDate(order_cate.informed_date)}</td>
              <td rowSpan={nb_of_buf_contracts} className="text-center align-middle">
                {formatDate(order.po_date)}
              </td>
              {orderPayment()}
              {orderOtherDetails()}
              <td rowSpan={nb_of_buf_contracts} className="text-center align-middle">
                {formatDate(order.db_billing_date)}
              </td>
              <td rowSpan={nb_of_buf_contracts} className="text-center align-middle">
                {items.length === 0 ? '' : `${num_of_installed_items}/${items.length}`}
              </td>
              <td rowSpan={nb_of_buf_contracts} className="text-center align-middle">
                {formatDate(order.invoice_issued_date)}
              </td>
            </tr>
          )
        } else {
          if (sub_index === 0) {
            record.push(
              <tr key={'' + order_cate.id + orderCodeStr}>
                {orderCodeStr}
                {this.displayItemInfo(order_cate, items)}
                <td className="text-center align-middle">{formatDate(order_cate.informed_date)}</td>
                <td rowSpan={nb_of_buf_contracts} className="text-center align-middle">
                  {formatDate(order.po_date)}
                </td>
                {orderPayment()}
                {orderOtherDetails()}
                <td rowSpan={nb_of_buf_contracts} className="text-center align-middle">
                  {formatDate(order.db_billing_date)}
                </td>
                <td rowSpan={nb_of_buf_contracts} className="text-center align-middle">
                  {items.length === 0 ? '' : `${num_of_installed_items}/${items.length}`}
                </td>
                <td rowSpan={nb_of_buf_contracts} className="text-center align-middle">
                  {formatDate(order.invoice_issued_date)}
                </td>
              </tr>
            )
          } else {
            record.push(
              <tr key={'' + order_cate.id + orderCodeStr}>
                {this.displayItemInfo(order_cate, items)}
                <td className="text-center align-middle">{formatDate(order_cate.informed_date)}</td>
                {orderOtherDetails()}
              </tr>
            )
          }
        }
      })
    })
    return record
  }

  render() {
    const { quotations } = this.state
    return (
      <React.Fragment>
        <Spinner loading={this.state.is_refiltering || this.state.is_loading} />
        <div className="col p-3 home-scrollable" style={{ position: 'relative' }}>
          <div className="card-box light p-3" style={{ zIndex: 99, width: '-webkit-fill-available', backgroundColor: '#e7f2fb', marginBottom: 20 }}>
            <div className="row">
              <div className="col-8">
                <h3>{REPORT_TYPES.order.name}</h3>
              </div>
            </div>
            <form onSubmit={this.handleSubmit}>
              <SearchBar
                onEnter={() => {
                  this.setState({ is_refiltering: true })
                }}
                onChangeQuery={this.onChangeQuery}
                onClickClear={this.onClickClear}
                query={this.state.query}
                placeholder={'ค้นหาชื่อสถานี, ชื่อผู้ซื้อ...'}
              />
              <div className="row">
                <div className="col-4 col-md-2 mt-2">
                  <label>วันที่สร้างใบเสนอราคา</label>
                </div>
                <div className="col-8 col-md-4 mt-2 date-picker-wrapper">
                  <DatePicker
                    className="form-control"
                    selected={this.state.selected_submitted_date_form}
                    onChange={(date) => this.onChangeSubmittedFromDate(date)}
                    dateFormat={FORMAT_APP_DATE}
                  />
                </div>
                <div className="col-4 col-md-1 mt-2">
                  <label>ถึง</label>
                </div>
                <div className="col-8 col-md-4 mt-2 date-picker-wrapper">
                  <DatePicker
                    className="form-control"
                    selected={this.state.selected_submitted_date_to}
                    onChange={(date) => this.onChangeSubmittedToDate(date)}
                    dateFormat={FORMAT_APP_DATE}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4 col-md-2 mt-2">
                  <label>ผู้รับผิดชอบ</label>
                </div>
                <div className="col-8 col-md-4 mt-2 date-picker-wrapper">
                  <Input
                    value={this.state.owner_search}
                    style={{ borderRadius: 5 }}
                    onChange={this.onChangeOwnerSearch}
                    placeholder="ค้นหาผู้รับผิดชอบ..."
                  />
                </div>
              </div>

              {/* <div className="row">
                <div className="col-4 col-md-2 mt-2">
                  <label>วันที่สร้าง Order</label>
                </div>
                <div className="col-8 col-md-4 mt-2 date-picker-wrapper">
                  <DatePicker className="form-control" selected={this.state.selected_date_form} onChange={(date) => this.onChangeFromDate(date)} dateFormat={FORMAT_APP_DATE} />
                </div>
                <div className="col-4 col-md-1 mt-2">
                  <label>ถึง</label>
                </div>
                <div className="col col-md-4 mt-2 date-picker-wrapper">
                  <DatePicker className="form-control" selected={this.state.selected_date_to} onChange={(date) => this.onChangeToDate(date)} dateFormat={FORMAT_APP_DATE} />
                </div>
                <div className="d-none d-md-block col-md-2 mt-2"></div>
              </div> */}

              {/* <div className="row">
                <div className="col-4 col-md-2 mt-2">
                  <label>สถานะ</label>
                </div>
                <div className="col-8 col-md-9 mt-2">
                  <Select isMulti value={this.state.selected_state} onChange={this.onChangeState} options={OPTIONS_STATES} />
                </div>
              </div> */}

              {/* <OrderProgressFilters flag_filters={this.state.flag_filters} setFlagFilters={this.setFlagFilters} key={this.state.resetKey} /> */}

              {/* Visible xs, sm  */}
              <div className="row d-block d-md-none">
                <div className="col-12 mt-3 text-right">
                  <button type="submit" className="btn btn-primary ml-2">
                    Search
                  </button>
                </div>
              </div>
            </form>

            <div className="row">
              <div className="d-none d-md-block col-12 mt-2">
                <div className="d-flex flex-row-reverse" style={{ justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
                    <ExportExcel
                      data={[this.state.export_report]}
                      content={'Export'}
                      filename={`tracking_${formatApiDate(this.state.selected_submitted_date_form)}_to_${formatApiDate(
                        this.state.selected_submitted_date_to
                      )}`}
                      customClass={'btn btn-success ml-2'}
                      disabled={this.state.export_report === null}
                    />
                    {/* <Button style={{ background: "red", color: "white", borderRadius: 5, width: 80, height: 39, fontSize: 16 }}>Cancel</Button> */}
                    <Button
                      style={{ background: '#0D8D21', color: 'white', borderRadius: 5, width: 80, height: 39, marginRight: 7, fontSize: 16 }}
                      onClick={this.OnClickMainSave.bind(this)}
                    >
                      Save
                    </Button>
                  </div>
                  <a href="/quotations?new_quotation=new">
                    <Button
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#0D8D21',
                        color: 'white',
                        borderRadius: 5,
                        width: 180,
                        height: 39,
                        marginRight: 7,
                        fontSize: 16,
                      }}
                    >
                      <PlusOutlined /> สร้างใบเสนอราคา
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="font-size-15px">
            <ReportTable
              setIsLoading={this.setIsLoading.bind(this)}
              quotations={quotations}
              bank_list={this.state.bank_list}
              current_user={this.props.current_user}
              setQuotations={this.setQuotations.bind(this)}
            />
          </div>
          {/* <div className="block-info-buffer-box"></div> */}
        </div>
      </React.Fragment>
    )
  }
}
export default withRouter(OrderReport)

import React from "react";
import Modal from "react-modal";
import Select from "react-select";
import _ from "lodash";

import { MODAL_STYLE_SMALL_SM, R_SCREEN_DOCK_SIZE } from "../../constants";
import { OrderFormPurchasePriceItemsFee } from "./OrderFormPurchasePriceItemsFee";

import { itemCategoryName } from "../../models/ItemCategories";
import { calTotalDiscount, calTotalCost } from "../../models/Orders";
import { getWarrantyCode } from "../../models/Orders";
import { getCategoryTotalExpense } from "../../models/Quotations";

import Number from "../global/Number";
import ModalConfirmation from "../global/ModalConfirmation";
import ContractByCateSelect from "../global/ContractByCateSelect";

import { stringIsEmpty } from "../../functions/String";
import { Multiple } from "../../functions/Number";

import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export class OrderFormPurchasePriceItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_item: "",
      selected_item_category: "",
      edited_item: null,
      custom_item: "",
      custom_quantity: "",
      quantity: "",
      comment: "",
      modal_preview: false,
      isConfirmingAdjustment: false,
      vendor: {},
    };

    this.handleOpenEditedModal = this.handleOpenEditedModal.bind(this);
    this.handleCloseEditedModal = this.handleCloseEditedModal.bind(this);
    this.onChangeComment = this.onChangeComment.bind(this);

    // Confirm shipping adjustment
    this.onResetContractPrice = this.onResetContractPrice.bind(this);
    this.onHandleFeeAdjustModal = this.onHandleFeeAdjustModal.bind(this);
  }

  handleOpenEditedModal(id) {
    this.setState({ edited_item: id });
  }

  handleCloseEditedModal() {
    this.setState({ edited_item: null });
  }

  onChangeComment(e) {
    this.setState({ comment: e.target.value });
  }

  /* Confirm shipping adjustment (start) */
  onHandleFeeAdjustModal(cate_id) {
    this.setState({ isConfirmingAdjustment: cate_id });
  }

  onResetContractPrice() {
    this.props.onResetContractPrice([this.state.isConfirmingAdjustment]);
    this.onHandleFeeAdjustModal(false);
  }

  displayFeeAdjustmentModal() {
    let { isConfirmingAdjustment } = this.state;
    let { order_item_categories } = this.props.order;
    if (isConfirmingAdjustment !== false) {
      return (
        <ModalConfirmation
          isOpen={true}
          title="ปรับค่าราคาขาซื้อเป็นค่าเริ่มต้น"
          content={
            <p>
              ต้องการปรับค่าราคาขาซื้อของ{" "}
              <u>
                <strong>{itemCategoryName(order_item_categories[isConfirmingAdjustment])}</strong>
              </u>{" "}
              เป็นค่าเริ่มต้น ?
            </p>
          }
          onConfirm={this.onResetContractPrice}
          onCancel={() => this.onHandleFeeAdjustModal(false)}
        />
      );
    }
  }
  /* Confirm shipping adjustment (end) */

  inputField(elm, field, isNumber) {
    if (isNumber === true) {
      return <Number innerClass="text-right" name={field} value={elm[field]} onChange={(e) => this.props.onChangeItemDetails(e, elm.id)} disabled={this.props.disabled} />;
    } else {
      return <input className="form-control text-right" name={field} value={elm[field]} onChange={(e) => this.props.onChangeItemDetails(e, elm.id)} disabled={this.props.disabled}></input>;
    }
  }

  editModal(elm) {
    let { edited_item } = this.state;
    return (
      <Modal isOpen={elm.id === edited_item} contentLabel="onEditItem" onRequestClose={this.handleCloseEditedModal} style={MODAL_STYLE_SMALL_SM}>
        <div className="d-flex flex-column">
          <h5>แก้ไขรายการสินค้า</h5>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>รายการ</label>
            </div>
            <div className="d-flex-2">{elm.name}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>จำนวน</label>
            </div>
            <div className="d-flex-2">{elm.qty}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>หน่วย</label>
            </div>
            <div className="d-flex-2">{elm.unit}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>ราคา</label>
            </div>
            <div className="d-flex-2">
              {this.inputField(elm, "contract_price", true)}
              {/* {elm.price.toLocaleString()} */}
            </div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>รวม</label>
            </div>
            <div className="d-flex-2">{(elm.contract_price * elm.qty).toLocaleString()}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <button type="button" className="btn btn-success btn-sm w-100" onClick={this.handleCloseEditedModal} disabled={this.props.disabled}>
              บันทึก
            </button>
            <button type="button" className="btn btn-danger btn-sm w-100 ml-1" onClick={() => this.props.onDeleteItem(elm)} disabled={this.props.disabled}>
              ลบ
            </button>
            <button type="button" className="btn btn-secondary btn-sm w-100 ml-1" onClick={this.handleCloseEditedModal}>
              ยกเลิก
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  displayItems(itemsByCate, index_offset) {
    let item_index = -1
    let sub_items = []

    let items = itemsByCate.map((elm, index) => {

      if (elm.from_parent) return false

      if (elm.is_parent) sub_items = itemsByCate.filter(({ from_parent_item_id }) => from_parent_item_id === elm.item_id)

      item_index++;

      let net_cost = Multiple(elm.contract_price, elm.qty);
      let code_name = `${elm.code ? elm.code + "-" : ""}${elm.name}`;
      let sum_sub_price = sub_items.reduce((prev, curr) => { return prev += curr.contract_price * curr.qty }, 0)

      return (
        <React.Fragment key={elm.id}>
          {/* md up */}
          <div className="row mt-2 border d-none d-md-flex" style={{ position: "relative", zIndex: 1, padding: "10px 0px", backgroundColor: "white" }}>
            <div className="table-item-bg first col-p5 justify-content-center">{index_offset + item_index}</div>
            <div className="table-item-bg col-12 pt-1 col-md-6 pr-0">{code_name}</div>
            <div className="table-item-bg col-3 col-md-1 text-center" style={{ marginLeft: 5 }}>
              <div className="w-100 ml-2 text-right">{elm.qty}</div>
            </div>
            <div className="table-item-bg col col-md-1 justify-content-center">{elm.unit}</div>

            <div className="table-item-bg col-4 p-0 col-md justify-content-end">
              {/* {elm.contract_price.toLocaleString()} */}
              {console.log('elm', elm.is_active)}
              {elm.is_parent ? "" : this.inputField(elm, "contract_price", true)}
              {elm.contract_price === 0 && !elm.is_parent ? (
                <OverlayTrigger
                  key={index}
                  placement={"bottom"}
                  overlay={
                    <Tooltip id={`tooltip-${index}`}>
                      <div>คำเตือน</div>
                      {elm.contract_price === 0 && <div>- ราคาขาซื้อเป็น 0</div>}
                      {!elm.is_active && <div>- สินค้านี้ไม่มีในสัญญาที่เลือก หรือสัญญาถูกปิดใช้งาน</div>}
                    </Tooltip>
                  }
                >
                  <div
                    style={{
                      zIndex: 1,
                      right: "-32px",
                      position: "absolute",
                      fontSize: 16,
                      fontWeight: "bold",
                      color: "red",
                      border: "2px solid red",
                      borderRadius: "50%",
                      width: 25,
                      height: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    !
                  </div>
                </OverlayTrigger>
              ) : (
                !elm.is_active && (
                  <OverlayTrigger
                    key={index}
                    placement={"bottom"}
                    overlay={
                      <Tooltip id={`tooltip-${index}`}>
                        <div>คำเตือน</div>
                        <div>- สินค้านี้ไม่มีในสัญญาที่เลือก หรือสัญญาถูกปิดใช้งาน</div>
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        zIndex: 1,
                        right: "-32px",
                        position: "absolute",
                        fontSize: 16,
                        fontWeight: "bold",
                        color: "red",
                        border: "2px solid red",
                        borderRadius: "50%",
                        width: 25,
                        height: 25,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      !
                    </div>
                  </OverlayTrigger>
                )
              )}
            </div>
            <div className="table-item-bg col-12 col-md" style={{ paddingRight: elm.is_parent ? 30 : 15 }}>
              <div className="w-100 ml-2 text-right">{elm.is_parent ? Intl.NumberFormat().format(sum_sub_price) : net_cost.toLocaleString()}</div>
            </div>
          </div>
          {elm.is_parent &&
            <div className="border" style={{ backgroundColor: "white", marginTop: -10, position: "relative", zIndex: 0 }}>
              {sub_items.length > 0 && sub_items?.map((item, s_index) => {

                let sub_net_cost = Multiple(item.contract_price, item.qty);
                let sub_code_name = `${item.code ? item.code + "-" : ""}${item.name}`;

                return <div style={{ padding: "0px 15px" }}>
                  <div className="row mt-2 d-none d-md-flex" style={{ padding: "10px 0px", backgroundColor: "white" }}>
                    <div className="table-item-bg first col-p5 justify-content-center" style={{ marginLeft: 10 }}>{index_offset + item_index}.{s_index + 1}</div>
                    <div className="table-item-bg col-12 pt-1 col-md-6 pr-0">{sub_code_name}</div>
                    <div className="table-item-bg col-3 col-md-1 text-center">
                      <div className="w-100 ml-2 text-right">{item.qty}</div>
                    </div>
                    <div className="table-item-bg col col-md-1 justify-content-center">{item.unit}</div>

                    <div className="table-item-bg col-4 col-md justify-content-end" style={{ marginRight: -5 }}>
                      {this.inputField(item, "contract_price", true)}
                      {item.contract_price === 0 ? (
                        <OverlayTrigger
                          key={index}
                          placement={"bottom"}
                          overlay={
                            <Tooltip id={`tooltip-${index}`}>
                              <div>คำเตือน</div>
                              {item.contract_price === 0 && <div>- ราคาขาซื้อเป็น 0</div>}
                              {!item.is_active && <div>- สินค้านี้ไม่มีในสัญญาที่เลือก หรือสัญญาถูกปิดใช้งาน</div>}
                            </Tooltip>
                          }
                        >
                          <div
                            style={{
                              zIndex: 1,
                              right: "-32px",
                              position: "absolute",
                              fontSize: 16,
                              fontWeight: "bold",
                              color: "red",
                              border: "2px solid red",
                              borderRadius: "50%",
                              width: 25,
                              height: 25,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            !
                          </div>
                        </OverlayTrigger>
                      ) : (
                        !item.is_active && (
                          <OverlayTrigger
                            key={index}
                            placement={"bottom"}
                            overlay={
                              <Tooltip id={`tooltip-${index}`}>
                                <div>คำเตือน</div>
                                <div>- สินค้านี้ไม่มีในสัญญาที่เลือก หรือสัญญาถูกปิดใช้งาน</div>
                              </Tooltip>
                            }
                          >
                            <div
                              style={{
                                zIndex: 1,
                                right: "-32px",
                                position: "absolute",
                                fontSize: 16,
                                fontWeight: "bold",
                                color: "red",
                                border: "2px solid red",
                                borderRadius: "50%",
                                width: 25,
                                height: 25,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              !
                            </div>
                          </OverlayTrigger>
                        )
                      )}
                    </div>
                    <div className="table-item-bg col-12 col-md">
                      <div className="w-100 ml-2 text-right">{sub_net_cost.toLocaleString()}</div>
                    </div>
                  </div>
                </div>
              })}
            </div>
          }
          {/* md down */}
          <a onClick={() => (this.props.disabled ? false : this.handleOpenEditedModal(elm.id))}>
            <div className="row mt-2 border d-md-none">
              <div className="table-item-bg col-12 pt-1 col-md-4 pr-0">{code_name}</div>
              <div className="table-item-bg col col-md-1 text-center d-md-none">
                จำนวน {elm.qty} {elm.unit}
              </div>
              <div className="table-item-bg col-12 col-md d-flex justify-content-end bg-ghostwhite d-md-none">
                <h4 className="sign-baht color-darkgrayblue">{net_cost.toLocaleString()}</h4>
              </div>
            </div>
          </a>
          {this.editModal(elm)}
        </React.Fragment>
      );
    });
    return items;
  }

  displayItemByCategory() {
    let { order } = this.props;
    let { order_item_categories, purchasing_order_items } = order;
    let order_items = purchasing_order_items.filter((elm) => !elm.is_mbom);
    let index_offset = 1;

    let itemsByCategory = _.groupBy(order_items, function (value) {
      return value.item_category_id;
    });

    let result = Object.keys(itemsByCategory).map((key, index) => {
      let current_cate = order_item_categories[key];
      let price_sum = 0;
      let disabled_contract_sel = itemsByCategory[key].reduce((pre, cur) => {
        return pre || !stringIsEmpty(getWarrantyCode(cur.warranty_codes));
      }, false);
      let rowItems = this.displayItems(itemsByCategory[key], index_offset);
      itemsByCategory[key].forEach((temp) => {

        if (temp.from_parent) return

        let contract_price = parseFloat(temp.contract_price);
        let qty = parseFloat(temp.qty);
        price_sum += Multiple(qty, contract_price);
      });
      index_offset += itemsByCategory[key].length;

      // const is_type_7 = current_cate.item_category_expenses.find((cate) => cate.method_type === 7 && cate.qty > cate.from_value)

      let is_type_7 = null

      current_cate.item_category_expenses.forEach((cate) => {
        if (cate.method_type === 7 && cate.qty > cate.from_value) {
          if (is_type_7) {
            if (cate.qty > is_type_7.from_value && is_type_7.from_value < cate.from_value) {
              is_type_7 = cate
            }
          } else {
            is_type_7 = cate
          }

        }
      })


      let sum_expense = getCategoryTotalExpense(current_cate.item_category_expenses, true, is_type_7 ? is_type_7 : false);

      return (
        <React.Fragment key={key}>
          <div className="row purchase-price">
            <div className="table-item-bg category-title first col-1 col-p5 p-0">
              <i className={`icon-adjust cursor-pointer ${this.props.disabled ? "d-none" : ""}`} onClick={() => this.onHandleFeeAdjustModal(key)} />
            </div>
            <div className="table-item-bg category-title font-weight-bold col p-0 col-md-3">
              {order_item_categories[key].sys_name}
              {/* {mockShippingCost("d-md-none")} */}
            </div>
            <div className="table-item-bg category-title font-weight-bold col p-0 col-md-5">
              <div className=" w-100">
                <ContractByCateSelect
                  contractId={order_item_categories[key].contract_id}
                  approvedDate={order.quotation.approved_date}
                  itemCategoryId={key}
                  onChange={this.props.onChangeContract}
                  // key = {this.props.resetContractByCateSelect}
                  disabled={this.props.disabled || disabled_contract_sel}
                />
              </div>
            </div>
            <div className="table-item-bg category-title col p-md-0 justify-content-end d-none d-md-flex">
              <span className="sign-baht pl-1 font-weight-bold">{`ค่าบริการ  ${sum_expense.toLocaleString()}`}</span>
              {/* {mockShippingCost()} */}
            </div>
            <div className="table-item-bg category-title font-weight-bold col justify-content-end sign-baht d-none d-md-flex">
              <span className="pr-1">รวม</span> {(price_sum + sum_expense).toLocaleString()}
            </div>
          </div>
          {rowItems}
          <OrderFormPurchasePriceItemsFee {...this.props} costByCate={current_cate.item_category_expenses} />
          <div className="row mt-3"></div>
        </React.Fragment>
      );
    });

    return result;
  }

  displayTable() {
    let tableHeight = "";
    //let tableHeight = (window.innerWidth > R_SCREEN_DOCK_SIZE) ? (this.props.disabled ? 'max-vh-55' :  'max-vh-45') : 'max-vh-45'
    return (
      <React.Fragment>
        <div className="col-12">
          <div className="row table-header d-none d-md-flex">
            <div className="table-item-bg first col-p5 justify-content-center">
              <strong>ลำดับ</strong>
            </div>
            <div className="table-item-bg col-6">
              <strong>รายการ</strong>
            </div>
            <div className="table-item-bg col-1 justify-content-center">
              <strong>จำนวน</strong>
            </div>
            {/* <div className="table-item-bg col-1 justify-content-center"><strong>หน่วย</strong></div> */}
            <div className="table-item-bg col justify-content-center">
              <strong>ราคา</strong>
            </div>
            <div className="table-item-bg col justify-content-center">
              <strong>รวม</strong>
            </div>
            {/* <div className="table-item-bg col-2 pl-5"><strong>comment</strong></div> */}
          </div>
          <div className={`row quo-table ${tableHeight}`}>
            <div className="col-12">{this.displayItemByCategory()}</div>
          </div>
          {this.displaySummary(true)}
        </div>
      </React.Fragment>
    );
  }

  displaySummary(md) {
    let { order } = this.props;
    let total_with_discount = calTotalCost(order, true);
    let discount = calTotalDiscount(order, true);
    let total = total_with_discount + discount;
    let vat = total_with_discount * 0.07;

    let text_total = "รวมเป็นเงิน";
    let text_discount = "ส่วนลด";
    let text_total_with_discount = "หลังหักส่วนลด";
    let text_vat = "VAT 7%";
    let text_sum = "รวมทั้งสิ้น";
    if (md) {
      return (
        <React.Fragment>
          {/* lg down */}
          <div className="mt-2 d-lg-none">
            <div className="row">
              <div className="table-item-bg col p-0 justify-content-end">{text_total}</div>
              <div className="table-item-bg col justify-content-end sign-baht">{total.toLocaleString()}</div>
            </div>
            <div className="row">
              <div className="table-item-bg col p-0 justify-content-end">{text_discount}</div>
              <div className="table-item-bg col justify-content-end sign-baht">{discount.toLocaleString()}</div>
            </div>
            <div className="row">
              <div className="table-item-bg col p-0 justify-content-end">{text_total_with_discount}</div>
              <div className="table-item-bg col justify-content-end sign-baht">{total_with_discount.toLocaleString()}</div>
            </div>
            <div className="row mb-2">
              <div className="table-item-bg col p-0 justify-content-end">{text_vat}</div>
              <div className="table-item-bg col justify-content-end sign-baht">{vat.toLocaleString()}</div>
            </div>
            <div className="row">
              <div className="table-item-bg total col p-0 justify-content-end">{text_sum}</div>
              <div className="table-item-bg total col justify-content-end sign-baht">{(vat + total_with_discount).toLocaleString()}</div>
              {/* <div className="table-item-bg total col justify-content-end sign-baht">{(calTotalCostWithVat(this.props.quotation)).toLocaleString()}</div> */}
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {/* lg up */}
          <div className="mt-2 d-none d-lg-block">
            <div className="d-flex flex-row pr-3">
              <div className="d-flex-1 py-1 text-right">{text_total}</div>
              <div className="d-flex-1 py-1 text-right">{total.toLocaleString()}</div>
            </div>
            <div className="d-flex flex-row pr-3">
              <div className="d-flex-1 py-1 text-right">{text_discount}</div>
              <div className="d-flex-1 py-1 text-right">{discount.toLocaleString()}</div>
            </div>
            <div className="d-flex flex-row pr-3">
              <div className="d-flex-1 py-1 text-right">{text_total_with_discount}</div>
              <div className="d-flex-1 py-1 text-right">{total_with_discount.toLocaleString()}</div>
            </div>
            <div className="d-flex flex-row pr-3">
              <div className="d-flex-1 py-1 text-right">{text_vat}</div>
              <div className="d-flex-1 py-1 text-right">{vat.toLocaleString()}</div>
            </div>
            <div className="d-flex flex-row pr-3 bg-grayyellow">
              <div className="d-flex-1 py-2 text-right font-weight-bold">{text_sum}</div>
              <div className="d-flex-1 py-2 text-right font-weight-bold">{(total_with_discount + vat).toLocaleString()}</div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  rightSideBar() {
    return (
      <React.Fragment>
        <div className="quo-right-side-bar">{this.displaySummary()}</div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="col">
          <div className="row mt-2">
            <div className="col p-0">{this.displayTable()}</div>
            <div className="col-3 d-none d-lg-block">{this.rightSideBar()}</div>
          </div>
        </div>
        {this.displayFeeAdjustmentModal()}
      </React.Fragment>
    );
  }
}

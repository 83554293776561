import React from "react";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import Select from "react-select";
import axios from "axios";
import moment from "moment";
import Spinner from "../global/Spinner";
import ReactPaginate from "react-paginate";
import { withRouter } from "react-router-dom";
import { IconSorting } from "../global/IconSorting";
import VendorSelect from "../global/VendorSelect";
import OrderSelect from "../global/OrderSelect";
import SearchBar from "../global/SearchBar";

import { MrFormDocuments } from "./MrFormDocuments";
import { R_SCREEN_DOCK_SIZE, MODAL_STYLE_LARGE, MODAL_STYLE_LARGE_MD, API_RESOURCES, PER_PAGE, MR_STATES, APP_TITLE_MR, FORMAT_APP_DATE } from "../../constants";

import { formatDate, formatApiDate } from "../../functions/Date";
import { getHeaderTotalPageCount } from "../../functions/Response";
import { uniqueKey } from "../../functions/Random";
import { objectIsEmpty } from "../../functions/Object";
const { Option } = Select;
const OPTIONS_STATES = Object.keys(MR_STATES).map((key) => {
  return { value: MR_STATES[key].name, label: MR_STATES[key].name };
});
const OPTIONS_SORT = [
  { value: "", label: "" },
  { value: "code", label: "MR No." },
  { value: "mr_date", label: "MR Date" },
  { value: "state", label: "State" },
  { value: "manufacturer_name", label: "Manufacturer name" },
  { value: "contract_no", label: "Contract No." },
];
const OPTIONS_DC_TYPE =   [  
  { value: "null", label: "-" },
  { value: "dc", label: "DC" },
  { value: "vendor", label: "Vendor" },
]

let searchParams = {};

class MrIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      selected_state: props.state ? OPTIONS_STATES.filter((elm) => props.state === elm.value) : null,
      selected_id: "",
      selected_sorting: "mr_date",
      selected_sorting_reversed: true,
      selected_date_form: props.link_date_from ? moment(props.link_date_from).toDate() : "",
      selected_date_to: props.link_date_to ? moment(props.link_date_to).toDate() : "",
      selected_vendor: null,
      reset_sorting: 0,
      modal_preview: false,
      selected_preview_mr: null,

      reFiltering: true,
      requests: [],

      // pagination
      page: 0,
      per_page: PER_PAGE,
      page_count: 1,
      resetKey: uniqueKey(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.onEnter = this.onEnter.bind(this);
    this.onChangeQuery = this.onChangeQuery.bind(this);
    this.onChangeID = this.onChangeID.bind(this);
    this.onChangeState = this.onChangeState.bind(this);
    this.onChangeSorting = this.onChangeSorting.bind(this);
    this.onChangeFromDate = this.onChangeFromDate.bind(this);
    this.onChangeToDate = this.onChangeToDate.bind(this);
    this.onChangeVendor = this.onChangeVendor.bind(this);
    this.onClickClear = this.onClickClear.bind(this);
    this.onClickSort = this.onClickSort.bind(this);
    this.onClickSortUp = this.onClickSortUp.bind(this);
    this.onClickSortDown = this.onClickSortDown.bind(this);
    this.handleCloneMR = this.handleCloneMR.bind(this);
    this.onChangeSelectOrderType = this.onChangeSelectOrderType.bind(this);

    this.onClickMr = this.onClickMr.bind(this);
    this.handleOpenModalPreview = this.handleOpenModalPreview.bind(this);
    this.handleCloseModalPreview = this.handleCloseModalPreview.bind(this);

    // pagination
    this.handlePageClick = this.handlePageClick.bind(this);

    this.getMRs = this.getMRs.bind(this);
  }

  componentDidMount() {
    if (this.props.location.search) {
      this.getPathParams();
    } else {
      this.getMRs();
    }
  }

  componentDidUpdate() {
    if (this.state.reFiltering) {
      this.getMRs();
    }
  }

  handleCloneMR(mr) {
    axios
      .post(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.mr}/${mr.id}/clone`)
      .then((response) => {
        console.log(response);
        window.location.href = `/${API_RESOURCES.mr}/${response.data.id}`;
      })
      .catch((error) => {
        console.log(error);
        this.setState({ is_loading: false });
      });
    this.setState({ is_loading: true });
  }

  getMRs(resetPage) {
    // filter: {}
    // range: [0,9]
    // sort: ["code","ASC"]
    let { page, per_page, selected_sorting, selected_sorting_reversed, selected_state, selected_date_form, selected_date_to, query, selected_vendor, selected_order, selected_order_type  } = this.state;
    let target_page = resetPage ? 0 : page;
    let range_start = target_page * per_page;
    let sort_dir = selected_sorting_reversed ? "DESC" : "ASC";
    let options = {
      sort: JSON.stringify([selected_sorting, sort_dir]),
      filter: {},
      range: JSON.stringify([range_start, range_start + per_page - 1]),
    };
    if (!objectIsEmpty(selected_state)) {
      options.filter.state = selected_state.map((elm) => {
        return elm.value;
      });
    }
    if (selected_date_form || selected_date_to) {
      let start_date = formatApiDate(selected_date_form);
      let end_date = formatApiDate(selected_date_to);
      options.filter.mr_date = [start_date, end_date];
    }
    if (selected_vendor) {
      options.filter.vendor_id = selected_vendor;
    }
    if (selected_order) {
      options.filter.order_id = selected_order;
    }
    if (query) {
      options.filter.query = query;
    }
    if (selected_order_type) {
      options.filter.order_type = (selected_order_type != 'null')?selected_order_type:null;
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.mr}`, {
        params: options,
      })
      .then((response) => {
        let page_count = getHeaderTotalPageCount(response, this.state.per_page);

        this.setState({
          requests: response.data,
          reFiltering: false,
          page_count: page_count,
          resetKey: resetPage ? uniqueKey() : this.state.resetKey,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          reFiltering: false,
        });
      })
      .then(() => {
        // always executed
      });
  }

  addSearchParams(params, path = this.props.location.pathname) {
    let searchParams = "";
    Object.keys(params).forEach((key, index) => {
      searchParams = searchParams + `${Object.keys(params).length > 1 && index !== 0 ? "&" : ""}${key}=${params[key]}`;
    });

    this.props.history.replace({ pathname: `${path}`, search: searchParams });
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    delete searchParams["page"];

    this.addSearchParams(searchParams);
    this.setState({ reFiltering: true });
  }

  onEnter() {
    this.setState({ reFiltering: true });
  }

  onChangeID(e) {
    this.setState({ selected_id: e.target.value });
  }

  onChangeState(e) {
    if (e.length > 0) {
      let state_search = [];
      e.forEach((state) => {
        state_search.push(state.value);
      });
      searchParams["state"] = state_search.join(",");
    } else {
      delete searchParams["state"];
    }

    this.setState({ selected_state: e });
  }

  getPathParams() {
    const query = new URLSearchParams(this.props.location.search).get("query") || "";
    const start_date = new URLSearchParams(this.props.location.search).get("start_date") || "";
    const end_date = new URLSearchParams(this.props.location.search).get("end_date") || "";
    const page = new URLSearchParams(this.props.location.search).get("page") || 0;
    const state = new URLSearchParams(this.props.location.search).get("state") || "";
    const order = new URLSearchParams(this.props.location.search).get("order") || null;
    const vendor = new URLSearchParams(this.props.location.search).get("vendor") || null;
    const order_type = new URLSearchParams(this.props.location.search).get("order_type") || null;

    let search_state = [];

    if (query !== "") searchParams["query"] = query;
    if (start_date) searchParams["start_date"] = start_date;
    if (end_date) searchParams["end_date"] = end_date;
    if (page !== 0) searchParams["page"] = page;
    if (order !== null) searchParams["order"] = order;
    if (vendor !== null) searchParams["vendor"] = vendor;

    if (state) {
      state.split(",").forEach((search) => {
        search_state.push({ value: search, label: search });
      });
    }

    this.setState({
      query: query,
      selected_date_form: start_date ? moment(start_date).toDate() : "",
      selected_date_to: end_date ? moment(end_date).toDate() : "",
      page: page ? parseInt(page) - 1 : 0,
      selected_state: search_state,
      selected_order: order ? parseInt(order) : null,
      selected_vendor: vendor ? parseInt(vendor) : null,
      selected_order_type : order_type ? order_type: null,
    });

    // this.getOrder();
  }

  onChangeSorting(e) {
    this.setState({ selected_sorting: e.value });
  }

  onChangeSelectOrderType(e) {
    try{
      this.setState({ selected_order_type: e.value });
      searchParams["order_type"] = e.value;
    }catch(e){
      
    }
  }

  onChangeQuery(e) {
    if (e.target.value) {
      searchParams["query"] = e.target.value;
    } else {
      delete searchParams["query"];
    }
    this.setState({ query: e.target.value });
  }

  onChangeFromDate(date) {
    searchParams["start_date"] = moment(date).format("MM/DD/YYYY");
    this.setState({ selected_date_form: date });
  }

  onChangeToDate(date) {
    searchParams["end_date"] = moment(date).format("MM/DD/YYYY");
    this.setState({ selected_date_to: date });
  }

  onChangeVendor(id, e) {
    searchParams["vendor"] = id;
    this.setState({ selected_vendor: id });
  }

  onChangeOrder = (id, e) => {
    if (id !== null) searchParams["order"] = id;
    else delete searchParams["order"];

    this.setState({ selected_order: id });
  };

  onClickClear() {
    this.setState({
      selected_state: null,
      selected_period: "",
      selected_id: "",
      selected_sorting: "mr_date",
      selected_sorting_reversed: true,
      selected_date_form: "",
      selected_date_to: "",
      selected_vendor: null,
      selected_order: null,
      page: 0,
      per_page: PER_PAGE,
      query: "",
      reFiltering: true,
      resetKey: uniqueKey(),
    });

    searchParams = {};
    this.props.history.replace({ pathname: `${this.props.location.pathname}`, search: null });
  }

  onClickMr(id) {
    this.addSearchParams(searchParams, `/${API_RESOURCES.mr}/${id}`);
  }

  onClickSort(type) {
    let new_toggle = this.state.selected_sorting === type ? !this.state.selected_sorting_reversed : false;
    this.setState({ selected_sorting: type, selected_sorting_reversed: new_toggle, reFiltering: true });
  }

  onClickSortUp(active, type) {
    let sortType = active ? type : "";
    this.setState({ selected_sorting: sortType, selected_sorting_reversed: false, reFiltering: true });
  }

  onClickSortDown(active, type) {
    let sortType = active ? type : "";
    this.setState({ selected_sorting: sortType, selected_sorting_reversed: true, reFiltering: true });
  }

  headerColWithSortIcon(text, sortText) {
    let active = this.state.selected_sorting === sortText;
    let sort_up = active && !this.state.selected_sorting_reversed;
    let sort_down = active && this.state.selected_sorting_reversed;

    return (
      <div className="d-flex flex-row cursor-pointer" onClick={() => this.onClickSort(sortText)}>
        <div className="">{text}</div>
        <IconSorting
          // onClickUp={this.onClickSort}
          // onClickDown={this.onClickSortDown}
          type={sortText}
          key={Math.random() * 100}
          activeUp={sort_up ? true : undefined}
          activeDown={sort_down ? true : undefined}
        />
      </div>
    );
  }

  /* ========================================================= */
  /* Pagination (start) */
  handlePageClick(data) {
    if (data.selected !== 0) searchParams["page"] = data.selected + 1;
    else delete searchParams["page"];

    this.setState({ reFiltering: true, page: data.selected });
  }
  /* Pagination (end) */
  /* ========================================================= */

  tableHeader() {
    return (
      <div className="card-box mt-1 p-3 d-none d-md-block mt-3">
        <div className="row">
          <div className="col-8 col-md-9 col-lg-10 d-flex flex-column flex-sm-row">
            <div className="d-flex-1 py-2">{this.headerColWithSortIcon("เลขที่", "code")}</div>
            <div className="d-flex-1 py-2">{this.headerColWithSortIcon("วันที่", "mr_date")}</div>
            <div className="d-flex-1 py-2">ประเภทออร์เดอร์</div>
            <div className="d-flex-1 py-2">{this.headerColWithSortIcon("สถานะ", "state")}</div>
            <div className="d-flex-3 py-2">{this.headerColWithSortIcon("ชื่อผู้ผลิต", "manufacturer_name")}</div>
            {/* <div className="d-flex-3 py-2">
              สถานี - หมวดหมู่สินค้า
            </div> */}
          </div>
          <div className="col d-flex flex-column"></div>
        </div>
      </div>
    );
  }

  checkType(data) {
    if(data.inbound_order_item_po != null && data.order_po_gr == null){
      return "DC"
    }else if(data.inbound_order_item_po == null && data.order_po_gr != null){
      return "Vendor"
    }else if(data.inbound_order_item_po == null && data.order_po_gr == null){
      return "-"
    }
  }

  mrItems() {
    let { requests } = this.state;
    let items = requests.map((elm, index) => {
      let station_details = [];
      if (elm.order_po_gr) {
        station_details.push(
          <div className="row mt-3">
            <div className="col color-green">
              เลขออร์เดอร์ - {elm.order_po_gr.order.order_code} [PO - {elm.order_po_gr.po_code}]
            </div>
          </div>
        );
      }
      elm.manufacture_request_stations.map((station, station_index) => {
        if (station_index === 0) {
          station_details.push(
            <div className="row mt-3 color-lightgray03">
              <div className="col">
                <u>รายชื่อสถานี - หมวดหมู่สินค้า</u>
              </div>
            </div>
          );
        }
        station_details.push(
          <div className={`row color-lightgray03`} key={elm.no + index + station_index}>
            <div className="col-6 col-md-6">
              {station_index + 1}. {station.station_name}
            </div>
            <div className="col">{station.item_category_name}</div>
          </div>
        );
      });
      return (
        <div className="card-box mt-1 p-3 link-no-hover" key={elm.no + index}>
          <div className="row line-1px pb-3">
            <div className="col-8 col-md-9 col-lg-10 d-flex flex-column flex-sm-row cursor-pointer" onClick={() => this.onClickMr(elm.id)}>
              <div className="d-flex-1">{elm.code}</div>
              <div className="d-flex-1">{elm.mr_date}</div>
              <div className="d-flex-1">{this.checkType(elm)}</div>
              <div className="d-flex-1">{elm.state}</div>
              <div className="d-flex-3">{elm.manufacturer_name}</div>
              {/* <div className="d-flex-3"></div> */}
            </div>
            <div className="col d-flex flex-row justify-content-end">
              <button type="button" className="btn btn-outline-primary btn-sm mr-2" onClick={() => this.handleOpenModalPreview(elm)}>
                Preview
              </button>
              <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.handleCloneMR(elm)}>
                Clone
              </button>
            </div>
          </div>
          {station_details}
        </div>
      );
    });
    return items;
  }

  handleOpenModalPreview(mr) {
    this.setState({ modal_preview: true, selected_preview_mr: mr });
  }

  handleCloseModalPreview() {
    this.setState({ modal_preview: false });
  }

  previewDoc() {
    let { selected_preview_mr } = this.state;
    return (
      <Modal isOpen={this.state.modal_preview} contentLabel="onRequestClose Example" onRequestClose={this.handleCloseModalPreview} style={window.innerWidth > R_SCREEN_DOCK_SIZE ? MODAL_STYLE_LARGE : MODAL_STYLE_LARGE_MD}>
        <div className="w-100 text-right d-none d-md-block">
          <button type="button" className="btn btn-danger ml-2" onClick={this.handleCloseModalPreview}>
            Close
          </button>
        </div>
        <MrFormDocuments mr={selected_preview_mr} />
      </Modal>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="offset-xl-1 col-xl-10 p-3 home-scrollable">
          <div className="row">
            <div className="col-8">
              <h2>{APP_TITLE_MR}</h2>
            </div>
            <div className="col text-right">
              <button type="button" className="btn btn-primary m-2" onClick={() => this.props.onChangeMrPage("new")}>
                New
              </button>
            </div>
          </div>

          <div className="card-box light p-3">
            <form onSubmit={this.handleSubmit}>
              <SearchBar onEnter={this.onEnter} onChangeQuery={this.onChangeQuery} onClickClear={this.onClickClear} query={this.state.query} placeholder={"ค้นหา เลขที่ใบสั่งผลิต, เลขที่สัญญา, สถานี"} />

              <div className="row">
                <div className="col-4 col-md-2 mt-2">
                  <label>วันที่ MR</label>
                </div>
                <div className="col-8 col-md-4 mt-2 date-picker-wrapper">
                  <DatePicker className="form-control" selected={this.state.selected_date_form} onChange={(date) => this.onChangeFromDate(date)} dateFormat={FORMAT_APP_DATE} />
                </div>
                <div className="col-4 col-md-2 mt-2">
                  <label>ถึง</label>
                </div>
                <div className="col mt-2 date-picker-wrapper">
                  <DatePicker className="form-control" selected={this.state.selected_date_to} onChange={(date) => this.onChangeToDate(date)} dateFormat={FORMAT_APP_DATE} />
                </div>
              </div>

              <div className="row">
                <div className="col-4 col-md-2 mt-2">
                  <label>สถานะ</label>
                </div>
                <div className="col-8 col-md-4 mt-2">
                  <Select isMulti value={this.state.selected_state} onChange={this.onChangeState} options={OPTIONS_STATES} placeholder={"ค้นหา..."} />
                </div>
                <div className="col-4 col-md-2 mt-2">
                  <label>ผู้ผลิต</label>
                </div>
                <div className="col-8 col-md-4 mt-2">
                  <VendorSelect onChange={this.onChangeVendor} vendorId={this.state.selected_vendor} disabled={false} />
                </div>
                {/* Visible xs, sm  */}
                <div className="col-4 col-md-2 mt-2 d-block d-md-none">
                  <label>Sort By</label>
                </div>
                <div className="col mt-2 d-block d-md-none">
                  <Select value={OPTIONS_SORT.filter((option) => option.value === this.state.selected_sorting)} onChange={this.onChangeSorting} options={OPTIONS_SORT} />
                </div>
              </div>

              <div className="row">
                <div className="col-4 col-md-2 mt-2">
                  <label>ออร์เดอร์</label>
                </div>
                <div className="col-8 col-md-4 mt-2">
                  <OrderSelect onChange={this.onChangeOrder} orderId={this.state.selected_order} disabled={false} />
                </div>
                <div className="col-4 col-md-2 mt-2">
                  <label>ประเภทออร์เดอร์</label>
                </div>
                <div className="col-8 col-md-4 mt-2">
                  {/* <OrderSelect onChange={this.onChangeOrder} orderId={this.state.selected_order} disabled={false} /> */} 
                  <Select  value={OPTIONS_DC_TYPE.filter((option) => option.value === this.state.selected_order_type)}  placeholder={"ค้นหา..."} style={{ width: "100%" }}    onChange={this.onChangeSelectOrderType} options={OPTIONS_DC_TYPE} > 
                  </Select>
                </div>
              </div>
              

              {/* Visible xs, sm  */}
              <div className="row d-block d-md-none">
                <div className="col-12 mt-3 text-right">
                  <button type="submit" className="btn btn-primary ml-2">
                    Search
                  </button>
                  <button type="button" className="btn btn-danger ml-2" onClick={this.onClickClear}>
                    clear
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="d-flex justify-content-end my-3">
            <ReactPaginate
              forcePage={this.state.page}
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.page_count}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              // activeClassName={"active"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
              key={this.state.resetKey}
            />
          </div>
          <div className="">
            {this.tableHeader()}
            {this.mrItems()}
          </div>
          <div className="block-info-buffer-box"></div>
        </div>
        {this.previewDoc()}
      </React.Fragment>
    );
  }
}
export default withRouter(MrIndex);

import React from "react";
import { Storage } from "aws-amplify";
import PropTypes from "prop-types";

// functions
import { uniqueKey } from "../../functions/Random";

// Component
import Spinner from "../global/Spinner";

Storage.configure({
  AWSS3: {
    region: "ap-southeast-1",
  },
});
class ImageUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: props.url,
      is_loading: false,
      uniqueKey: uniqueKey(),
    };
    this.inputRef = React.createRef();
    this.onImageChange = this.onImageChange.bind(this);
    this.onDeleteImage = this.onDeleteImage.bind(this);
  }

  componentDidMount() {
    this.checkImage();
  }

  componentDidUpdate() {
    this.checkImage();
  }

  checkImage() {
    let img_url = this.state.image;
    if (img_url && img_url.indexOf("http") < 0) {
      img_url = img_url.replace("public/", "");
      Storage.get(img_url).then((url) => {
        this.setState({ image: url, uniqueKey: uniqueKey() });
      });
    }
  }

  onDeleteImage() {
    if (this.props.onDeleteFile) {
      this.props.onDeleteFile();
      this.inputRef.current.value = null;
      this.setState({ image: null });
    }
  }

  // onImageChange = event => {
  //   if (event.target.files && event.target.files[0]) {
  //     let img = event.target.files[0];
  //     this.setState({
  //       image: URL.createObjectURL(img)
  //     });
  //   }
  // };

  async onImageChange(e) {
    const file = e.target.files[0];
    let filetype = e.target.files[0].type.split("/")[1];
    let filename = this.props.withUniqueKey ? `${this.props.file_category}_${uniqueKey()}.${filetype}` : `${this.props.file_category}.${filetype}`;
    if (filetype === "png" || filetype === "jpeg" || filetype === "jpg") {
      this.setState({ is_loading: true });
      try {
        const result = await Storage.put(filename, file, {
          contentType: e.target.files[0].type, // contentType is optional
        });
        const signedURL = await Storage.get(result.key);

        this.props.onUploadNewFile(`public/${result.key}`);
        this.setState({ image: signedURL, is_loading: false });
      } catch (error) {
        console.log("Error uploading file: ", error);
        this.setState({ is_loading: false });
      }
    } else {
      alert("กรุณาอัพโหลดไฟล์ที่เป็น PNG หรือ JPEG เท่านั้น");
    }
  }

  render() {
    let { disabled } = this.props;
    let { image } = this.state;
    const preview = () => {
      if (image && image.indexOf("http") >= 0) {
        let image_src = image.indexOf("?") >= 0 ? image : `${image}?${this.state.uniqueKey}`;
        return (
          <div className="d-flex flex-row max-w-500px">
            <img src={image_src} onError={(i) => (i.target.style.display = "none")} style={{ maxWidth: "100%" }} />
            {this.props.onDeleteFile ? <i className="icon-cancel" onClick={this.onDeleteImage}></i> : ""}
          </div>
        );
      }
    };

    return (
      <div>
        <div>
          <Spinner loading={this.state.is_loading} />
          <div>
            {preview()}
            <input type="file" name="myImage" onChange={this.onImageChange} disabled={disabled} ref={this.inputRef} />
          </div>
        </div>
      </div>
    );
  }
}
export default ImageUploader;

ImageUploader.propTypes = {
  file_category: PropTypes.string.isRequired,
  onUploadNewFile: PropTypes.func.isRequired,
  url: PropTypes.string,
  disabled: PropTypes.string,
  withUniqueKey: PropTypes.bool,
};

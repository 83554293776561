// in warranty_codes.js
import * as React from "react";
import {
  List, Datagrid, Edit, Create, SimpleForm, DeleteWithConfirmButton, TextField,
  EditButton, TextInput, SaveButton, required, useListContext, NumberInput, ReferenceField, AutocompleteInput,
  FunctionField, ReferenceInput, BooleanInput, FormDataConsumer
} from 'react-admin';
import BookIcon from '@material-ui/icons/Book';
import { Form } from 'react-final-form';
import { Box, Button as CoreButton, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ContentFilter from "@material-ui/icons/FilterList";

import { toStrWithLeadingZeros } from '../../functions/Number'

import { exporter } from '../../functions/reactAdmin'

import { EditToolbar, EditActions } from './Default'


export const WarrantyCodeIcon = BookIcon;

const WarrantyCodeFilter = props => {
  return props.context === "button" ? (
    <WarrantyCodeFilterButton {...props} />
  ) : (
    <WarrantyCodeFilterForm {...props} />
  );
};

const WarrantyCodeFilterButton = () => {
  const { showFilter } = useListContext();
  return (
      <CoreButton
          size="small"
          color="primary"
          onClick={() => showFilter("main")}
          startIcon={<ContentFilter />}
      >
          Filter
      </CoreButton>
  );
};

const WarrantyCodeFilterForm = ({ open }) => {
  const {
      displayedFilters,
      filterValues,
      setFilters,
      hideFilter,
  } = useListContext();

  if (!displayedFilters.main) return null;

  const onSubmit = values => {
      if (Object.keys(values).length > 0) {
          setFilters(values);
      } else {
          hideFilter("main");
      }
  };

  const resetFilter = () => {
      setFilters({}, []);
  };

  return (
      <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
          {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box mt={7} />
            <Box display="flex" alignItems="flex-end" mb={1}>
            <Box component="span" mr={3}>
              <BooleanInput source="is_valid" label="ไม่ถูกใช้งาน"/>
            </Box>
            <Box component="span" mr={1}>
              <ReferenceInput 
                resettable
                label="หมวดหมู่" 
                source="item_category_id" 
                reference="item_categories"
                filterToQuery={searchText => ({ sys_name: searchText })}
              >
                <AutocompleteInput optionText="sys_name" />
              </ReferenceInput>
            </Box>
            <Box component="span" mr={1}>
              <ReferenceInput 
                resettable
                label="สินค้า" 
                source="item_id" 
                reference="items"
                filterToQuery={searchText => ({name: searchText })}
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            </Box>
            <Box component="span" mr={1}>
              <ReferenceInput 
                resettable
                label="ผู้ผลิต" 
                source="vendor_id" 
                reference="vendors"
                filterToQuery={searchText => ({name: searchText })}
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            </Box>
            <Box component="span" mr={2}>
              {/* Full-text search filter. We don't use <SearchFilter> to force a large form input */}
              <NumberInput
                resettable
                helperText={false}
                source="code"
                label="Running Number"
                InputProps={{
                    endAdornment: (
                    <InputAdornment>
                        <SearchIcon color="disabled" />
                    </InputAdornment>
                    )
              }}
              />
            </Box>
            <Box component="span" mr={2} mb={1.5}>
                <CoreButton variant="outlined" color="primary" type="submit">
                Filter
                </CoreButton>
            </Box>
            <Box component="span" mb={1.5}>
                <CoreButton variant="outlined" onClick={resetFilter}>
                Close
                </CoreButton>
            </Box>
            </Box>
          </form>
          )}
      </Form>
      </div>
  );
};

const AssetDisplay = ({ basePath, record, resource }) => {
  if (record.item_id) {
    return (
      <ReferenceField label="หมวดหมู่/สินค้า" source="item_id" reference="items" sortBy="name">
        <TextField source="name" />
      </ReferenceField>
    )
  } else {
    return (
      <ReferenceField label="หมวดหมู่/สินค้า" source="item_category_id" reference="item_categories" sortBy="sys_name">
        <TextField source="sys_name" />
      </ReferenceField>
    )
  }
}

const CodeDisplay = ({ basePath, record, resource }) => {
  let item_code = record.item ? record.item.asset_code : record.item_category.code
  let code = `OR-${item_code}-${record.vendor.vendor_code}-${toStrWithLeadingZeros(record.code, 4)}`
  return (
    <React.Fragment>
      <FunctionField
        label="รหัสทรัพย์สิน"
        render={record => code}
      />
    </React.Fragment>
  )
}
export const WarrantyCodeList = (props) => (
  <List {...props} filters={<WarrantyCodeFilter />} exporter={exporter}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="is_valid" label="ไม่ถูกใช้งาน"/>
      <AssetDisplay/>
      <ReferenceField label="ผู้ผลิต" source="vendor_id" reference="vendors" sortBy="name">
        <TextField source="name" />
      </ReferenceField>
      <CodeDisplay />
      <EditButton basePath="/warranty_codes" />
       <DeleteWithConfirmButton undoable={false}/>
    </Datagrid>
  </List>
);

const WarrantyCodeTitle = ({ record }) => {
  return <span>WarrantyCode {record ? `"${record.name}"` : ''}</span>;
};

const ItemField = props => {
  return (
    <FormDataConsumer>
      {({
          formData, // The whole form data
          scopedFormData, // The data for this item of the ArrayInput
          getSource, // A function to get the valid source inside an ArrayInput
          ...rest
      }) =>
        formData.item_category_id ? (
          <ReferenceInput
            label="สินค้า" 
            source="item_id" 
            reference="items"
            filterToQuery={searchText => ({ name: searchText, item_category_id: formData.item_category_id })}
            key={formData.item_category_id}
            >
              <AutocompleteInput optionText="name" resettable/>
            </ReferenceInput>
        ) : null
      }
    </FormDataConsumer>
  );
};

const CodeEditDisplay = ({ basePath, record, resource }) => {
  let item_code = record.item ? record.item.asset_code : record.item_category ? record.item_category.code : ''
  let vendor = (record.vendor && record.vendor.vendor_code)? record.vendor.vendor_code : ''
  let code = `รหัสทรัพย์สิน (ก่อนเปลี่ยน): OR-${item_code}-${vendor}-${toStrWithLeadingZeros(record.code, 4)}`
  return (
    <React.Fragment>
      <FunctionField
        label="รหัสทรัพย์สิน (ก่อนเปลี่ยน)"
        render={record => code}
      />
    </React.Fragment>
  )
}
export const WarrantyCodeEdit = (props) => (
  <Edit undoable={false} actions={<EditActions />} title={<WarrantyCodeTitle />} {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput disabled source="id" />
      <div className="color-red">WARNING: ถ้ารหัสทรัพย์สินนี้ถูกใช้งานใน Order แล้วถูกปรับเป็น "ไม่ถูกใช้งาน" ระบบจะไม่ได้ทำการยกเลิกรหัสใน Order นั้นๆ</div>
      <BooleanInput source="is_valid" label="ไม่ถูกใช้งาน"/>
      <CodeEditDisplay />
      <ReferenceInput
        label="หมวดหมู่" 
        source="item_category_id" 
        reference="item_categories"
        filterToQuery={searchText => ({ sys_name: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="sys_name" resettable/>
      </ReferenceInput>
      <div className="color-red">[Optional] เลือกสินค้าในกรณีที่สินค้านั้นมีรหัสเฉพาะ ที่ไม่เหมือนกับค่ารหัสที่ตั้งไว้ในหมวดหมู่</div>
      <ItemField/>
      <ReferenceInput
        label="ผู้ผลิต" 
        source="vendor_id" 
        reference="vendors"
        filterToQuery={searchText => ({ name: searchText })}
      >
        <AutocompleteInput optionText="name" resettable/>
      </ReferenceInput>
      <NumberInput source="code" label="Running Number" step={1} validate={required()}/>
    </SimpleForm>
  </Edit>
);

export const WarrantyCodeCreate = (props) => (
  <Create title="Create a WarrantyCode" actions={<EditActions />} {...props}>
    <SimpleForm>
      <div className="color-red">WARNING: ถ้ารหัสทรัพย์สินนี้ถูกใช้งานใน Order แล้วถูกปรับเป็น "ไม่ถูกใช้งาน" ระบบจะไม่ได้ทำการยกเลิกรหัสใน Order นั้นๆ</div>
      <BooleanInput source="is_valid" label="ไม่ถูกใช้งาน"/>
      <CodeEditDisplay />
      <ReferenceInput
        label="หมวดหมู่" 
        source="item_category_id" 
        reference="item_categories"
        filterToQuery={searchText => ({ sys_name: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="sys_name" resettable/>
      </ReferenceInput>
      <div className="color-red">[Optional] เลือกสินค้าในกรณีที่สินค้านั้นมีรหัสเฉพาะ ที่ไม่เหมือนกับค่ารหัสที่ตั้งไว้ในหมวดหมู่</div>
      <ItemField/>
      <ReferenceInput
        label="ผู้ผลิต" 
        source="vendor_id" 
        reference="vendors"
        filterToQuery={searchText => ({ name: searchText })}
      >
        <AutocompleteInput optionText="name" resettable/>
      </ReferenceInput>
      <NumberInput source="code" label="Running Number" step={1} validate={required()}/>
    </SimpleForm>
  </Create>
);
import React from "react";
import PropTypes, { oneOfType } from "prop-types";
import Select from "react-select";
import axios from "axios";

class ItemCategorySelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      itemCates: [],
      isMounted: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.itemCateSelected = this.itemCateSelected.bind(this);
  }

  componentDidMount() {
    let { itemCateId } = this.props;
    if (itemCateId) {
      this.getProvinceFromId(itemCateId);
    } else {
      this.getCollection();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.itemCateId != this.props.itemCateId && this.state.isMounted) {
      let selectOption = this.state.itemCates.filter((option) => option.value == this.props.itemCateId)[0];
      this.props.onChange(this.props.itemCateId, selectOption);
    }
  }

  handleInputChange(value) {
    if (value !== "") {
      this.getCollection(value);
    }
  }

  setOptions(response) {
    let options = [];
    response.data.map((e) => {
      options.push({ id: e.id, text: e.dc_id === 0 ? `[Vendor] ${e.sys_name}` : `[DC] ${e.sys_name}`, value: e.id });
    });
    this.setState({
      itemCates: options,
      isMounted: true,
    });
  }

  getProvinceFromId(itemCateId) {
    axios
      .get(`${process.env.REACT_APP_API_URL}/item_categories/${itemCateId}`)
      .then((response) => {
        let options = [{ id: response.data.id, text: response.data.sys_name, value: response.data.id }];
        this.setState({
          itemCates: options,
          isMounted: true,
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  getCollection(query) {
    let params = {
      range: "[0, 30]",
      sort: ["id", "ASC"],
    };
    if (query) {
      params.filter = { sys_name: query };
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/item_categories`, {
        params: params,
      })
      .then((response) => {
        this.setOptions(response);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  itemCateSelected(e) {
    let value = e.value;
    if (value !== this.props.itemCateId && this.state.isMounted) {
      this.props.onChange(value, e);
    }
  }

  render() {
    return (
      <React.Fragment>
        <Select
          className="select2-style"
          onChange={this.itemCateSelected}
          onInputChange={this.handleInputChange}
          getOptionLabel={(option) => `${option.text}`}
          value={this.state.itemCates.filter((option) => option.value === this.props.itemCateId)}
          getOptionValue={(option) => `${option.id}`}
          options={this.state.itemCates}
          isDisabled={this.props.disabled}
          placeholder={"ค้นหา..."}
        />
      </React.Fragment>
    );
  }
}

ItemCategorySelect.propTypes = {
  itemCateId: oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ItemCategorySelect;

import React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { R_SCREEN_DOCK_SIZE_SM, MODAL_STYLE_SMALL, MODAL_STYLE_SMALL_SM } from "../../constants";

export class ModalConfirmation extends React.Component {
  confirmBtn() {
    let { onConfirm, btnOK, onConfirmDisabled } = this.props;
    let btn_ok_text = btnOK ? btnOK : "ตกลง";
    if (onConfirm !== undefined) {
      return (
        <button type="button" className="btn btn-success btn-sm w-100 mr-1" onClick={onConfirm} disabled={onConfirmDisabled}>
          {btn_ok_text}
        </button>
      );
    }
  }

  customBtn() {
    let { btnDanger, onCustomAction } = this.props;
    if (btnDanger !== undefined && onCustomAction !== undefined) {
      return (
        <button type="button" className="btn btn-danger btn-sm w-100 mr-1" onClick={() => this.props.onCustomAction()}>
          {btnDanger}
        </button>
      );
    }
  }

  render() {
    let { title, content, isOpen, btnSecondary } = this.props;

    return (
      <React.Fragment>
        <Modal
          isOpen={isOpen}
          contentLabel="ModelConfrmation"
          style={window.innerWidth > R_SCREEN_DOCK_SIZE_SM ? MODAL_STYLE_SMALL : MODAL_STYLE_SMALL_SM}
          onRequestClose={() => this.props.onCancel()}
          // Warning: react-modal: App element is not defined
          ariaHideApp={false}
        >
          <div className="d-flex flex-column">
            <h5>{title}</h5>
            <div className="line mb-2"></div>
            <div className="mt-2 mb-3">{content}</div>
            <div className="d-flex flex-row">
              {this.confirmBtn()}
              {this.customBtn()}
              <button type="button" className="btn btn-secondary btn-sm w-100 mr-1" onClick={() => this.props.onCancel()}>
                {btnSecondary || "ยกเลิก"}
              </button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
export default ModalConfirmation;

ModalConfirmation.propTypes = {
  isOpen: PropTypes.bool,
  onConfirmDisabled: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  btnDanger: PropTypes.string,
  btnSecondary: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCustomAction: PropTypes.func.isRequired,
};

import React from "react"
import PropTypes, { oneOfType } from "prop-types"
import Select from 'react-select'
import axios from 'axios';

class ProvinceSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      provinces: [],
      isMounted: false
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.provinceSelected = this.provinceSelected.bind(this)
  }

  componentDidMount() {
    let { provinceId } = this.props
    if (provinceId) {
      this.getProvinceFromId(provinceId)
    } else {
      this.getCollection()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.provinceId != this.props.provinceId && this.state.isMounted) {
      let selectOption = this.state.provinces.filter(option => option.value == this.props.provinceId)[0]
      this.props.onChange(this.props.provinceId, selectOption)
    }
  }

  handleInputChange(value) {
    if (value !== "") {
      this.getCollection(value)
    }
  }

  setOptions(response) {
    let options = []
    response.data.map(e => {
      options.push({ id: e.id, text: e.name, value: e.id })
    })
    this.setState({
      provinces: options,
      isMounted: true
    })
  }

  getProvinceFromId(provinceId) {
    axios.get(`${process.env.REACT_APP_API_URL}/provinces/${provinceId}`)
    .then((response) => {
      let options = [{ id: response.data.id, text: response.data.name, value: response.data.id }]
      this.setState({
        provinces: options,
        isMounted: true
      })
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  }

  getCollection(query) {
    let params = {
      range: '[0, 15]',
      sort: ["id","ASC"]
    }
    if (query) {
      params.filter = {name: query}
    }

    axios.get(`${process.env.REACT_APP_API_URL}/provinces`, {
      params: params
    })
    .then((response) => {
      this.setOptions(response)
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  }

  provinceSelected(e) {
    let value = e.value
    if (value !== this.props.provinceId && this.state.isMounted) {
      this.props.onChange(value, e)
    }
  }

  render() {
    return (
      <React.Fragment>
        <Select
          className="select2-style"
          onChange={this.provinceSelected}
          onInputChange={this.handleInputChange}
          getOptionLabel={option =>
            `${option.text}`
          }
          value={this.state.provinces.filter(option => option.value === this.props.provinceId)}
          getOptionValue={option => `${option.id}`}
          options={this.state.provinces}
          isDisabled={this.props.disabled}
          placeholder={'ค้นหา...'}
        />
      </React.Fragment>
    );
  }
}

ProvinceSelect.propTypes = {
  provinceId: oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export default ProvinceSelect

import React from 'react'
import Modal from 'react-modal'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import axios from 'axios'
import Spinner from '../global/Spinner'
import ReactPaginate from 'react-paginate'
import _ from 'lodash'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import SearchBar from '../global/SearchBar'
import { IconSorting } from '../global/IconSorting'
import { QuotationFormDocuments } from './QuotationFormDocuments'
import ItemCategorySelect from '../global/ItemCategorySelect'
import { CLONE_OPTIONS, R_SCREEN_DOCK_SIZE, MODAL_STYLE_LARGE, MODAL_STYLE_LARGE_MD, PER_PAGE, API_RESOURCES, QUO_STATES, APP_TITLE_QUO, FORMAT_APP_DATE } from '../../constants'

// Functions
import { formatDate, formatApiDate } from '../../functions/Date'
import { getHeaderTotalPageCount } from '../../functions/Response'
import { uniqueKey } from '../../functions/Random'
import { objectIsEmpty } from '../../functions/Object'

// Models
import { totalSalesWithVat } from '../../models/Quotations'

// Components
import ModalConfirmation from '../global/ModalConfirmation'

const OPTIONS_STATES = Object.keys(QUO_STATES).map((key) => {
  return { value: QUO_STATES[key].name, label: QUO_STATES[key].name }
})
// const OPTIONS_STATES = Object.keys(QUO_STATES).map((key) => { return {value: QUO_STATES[key].name, label: QUO_STATES[key].name.replace('PO', 'Order')}})
const OPTIONS_SORT = [
  { value: '', label: '' },
  { value: 'code', label: 'code' },
  { value: 'client_station.name', label: 'station name' },
  { value: 'state', label: 'state' },
  { value: 'quotation_date', label: 'date' },
  { value: 'total_sales', label: 'Total Sales' },
]

let searchParams = {}

class QuotationIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      query: '',
      selected_state: props.state ? OPTIONS_STATES.filter((elm) => props.state.indexOf(elm.value) > -1) : null,
      selected_id: '',
      selected_station_name: '',
      selected_cate_id: null,
      selected_sorting: 'quotation_date',
      selected_sorting_reversed: true,
      selected_date_form: props.link_date_from ? moment(props.link_date_from).toDate() : '',
      selected_date_to: props.link_date_to ? moment(props.link_date_to).toDate() : '',
      modal_preview: false,
      is_cloning: false,
      clone_quo: null,
      selected_preview_quo: null,
      collapsed_id: null,

      reFiltering: true,
      quotations: [],

      // pagination
      page: 0,
      per_page: PER_PAGE,
      page_count: 1,
      resetKey: uniqueKey(),
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.onEnter = this.onEnter.bind(this)
    this.onChangeQuery = this.onChangeQuery.bind(this)
    this.onChangeID = this.onChangeID.bind(this)
    this.onChangeState = this.onChangeState.bind(this)
    this.onChangeSorting = this.onChangeSorting.bind(this)
    this.onChangeStationName = this.onChangeStationName.bind(this)
    this.onChangeFromDate = this.onChangeFromDate.bind(this)
    this.onChangeToDate = this.onChangeToDate.bind(this)
    this.onClickClear = this.onClickClear.bind(this)
    this.onClickSort = this.onClickSort.bind(this)
    this.onClickSortUp = this.onClickSortUp.bind(this)
    this.onClickSortDown = this.onClickSortDown.bind(this)
    this.onShowQuotationDetails = this.onShowQuotationDetails.bind(this)

    this.onClickQuo = this.onClickQuo.bind(this)
    this.handleOpenModalPreview = this.handleOpenModalPreview.bind(this)
    this.handleCloseModalPreview = this.handleCloseModalPreview.bind(this)
    this.handleCloneQuotationOption = this.handleCloneQuotationOption.bind(this)
    this.handleCloneModal = this.handleCloneModal.bind(this)

    // pagination
    this.handlePageClick = this.handlePageClick.bind(this)

    this.getQuotations = this.getQuotations.bind(this)
  }

  componentDidMount() {
    if (this.props.location.search) {
      this.getPathParams()
    } else {
      this.getQuotations()
    }
  }

  componentDidUpdate() {
    if (this.state.reFiltering) {
      this.getQuotations()
    }
  }

  getPathParams() {
    const new_quotation = new URLSearchParams(this.props.location.search).get('new_quotation') || ''
    const query = new URLSearchParams(this.props.location.search).get('query') || ''
    const category = new URLSearchParams(this.props.location.search).get('category') || null
    const start_date = new URLSearchParams(this.props.location.search).get('start_date') || ''
    const end_date = new URLSearchParams(this.props.location.search).get('end_date') || ''
    const page = new URLSearchParams(this.props.location.search).get('page') || 0

    if (query !== '') searchParams['query'] = query
    if (category) searchParams['category'] = category
    if (start_date) searchParams['start_date'] = start_date
    if (end_date) searchParams['end_date'] = end_date
    if (page !== 0) searchParams['page'] = page
    if (new_quotation) this.props.onChangeQuoPage('new')

    this.setState({
      query: query,
      selected_cate_id: category ? parseInt(category) : null,
      selected_date_form: start_date ? moment(start_date).toDate() : '',
      selected_date_to: end_date ? moment(end_date).toDate() : '',
      page: page ? parseInt(page) - 1 : 0,
    })

    // setTimeout(() => {
    //   this.getQuotations();
    // }, 1500);
  }

  isApproved(state) {
    return ![QUO_STATES.draft.name, QUO_STATES.submitted.name, QUO_STATES.cancelled.name, QUO_STATES.rejected.name].includes(state)
  }

  // =======Clone Section [Start]
  isCloned(state) {
    return [QUO_STATES.cloned_to_edit.name, QUO_STATES.suspended.name].includes(state)
  }

  handleCloneModal(value, quo) {
    this.setState({
      is_cloning: value,
      clone_quo: quo,
    })
  }

  handleCloneQuotation(quotation) {
    axios
      .post(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.quotation}/${quotation.id}/clone`)
      .then((response) => {
        window.location.href = `/${API_RESOURCES.quotation}/${response.data.id}`
      })
      .catch((error) => {
        console.log(error)
        this.setState({ is_loading: false })
      })

    this.setState({ is_loading: true })
  }

  handleCloneQuotationOption(quotation, option) {
    if (option == CLONE_OPTIONS.clone_to_edit) {
      this.sendCloneEditAPI(quotation)
    } else if (option == CLONE_OPTIONS.clone_to_new) {
      this.sendCloneAPI(quotation)
    }

    this.setState({ is_loading: true })
    this.handleCloneModal(false, null)
  }

  displayCloneOption() {
    if (this.state.is_cloning) {
      const content = (
        <React.Fragment>
          <div>กรุณาเลือก option ในการโคลน</div>
          <br />
          <div>
            <b className="text-success">โคลนเพื่อสร้างใบเสนอราคาใหม่ :</b> เพื่อสร้างใบเสนอราคาใหม่ ที่คล้ายกัน โดยมีข้อมูลคล้ายเดิมทั้งหมด
          </div>
          <br />
          <div>
            <b className="text-danger">โคลนเพื่อแก้ไข :</b> ใบเสนอราคานี้จะเปลี่ยนเป็นสถานะ suspended และถูกโคลนเป็นใบเสนอราคาใหม่ในสถานะ cloned to edit โดยมีข้อมูลทั้งหมดดังเดิม หากแก้ไขเรียบร้อย จะสามารถกด Submit and Approve ใบเสนอราคา เพื่อแสดงเป็นใบเสนอราคาใหม่ในสถานะ approve และจะ
            <i>
              <b className="text-danger">ยกเลิกใบเสนอราคาเดิม รวมถึงออเดอร์ที่เกี่ยวข้องด้วย</b>
            </i>
          </div>
          <div></div>
        </React.Fragment>
      )
      return (
        <ModalConfirmation
          isOpen={true}
          title="ต้องการโคลนใบเสนอราคาที่ approved แล้ว?"
          content={content}
          btnOK="เพื่อสร้างใหม่"
          btnDanger="เพื่อแก้ไข"
          onConfirm={() => this.handleCloneQuotationOption(this.state.clone_quo, CLONE_OPTIONS.clone_to_new)}
          onCustomAction={() => this.handleCloneQuotationOption(this.state.clone_quo, CLONE_OPTIONS.clone_to_edit)}
          onCancel={() => this.handleCloneModal(false)}
        />
      )
    }
  }

  sendCloneEditAPI(quotation) {
    axios
      .post(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.quotation}/${quotation.id}/clone_edit`)
      .then((response) => {
        axios
          .put(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.quotation}/${quotation.id}/update_state`, {
            state: QUO_STATES.suspended.name,
          })
          .then(() => {
            window.location.href = `/${API_RESOURCES.quotation}/${response.data.id}`
          })
          .catch((error) => {
            console.log(error)
            this.setState({ is_loading: false })
          })
      })
      .catch((error) => {
        console.log(error)
        this.setState({ is_loading: false })
      })
  }

  sendCloneAPI(quotation) {
    axios
      .post(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.quotation}/${quotation.id}/clone`)
      .then((response) => {
        window.location.href = `/${API_RESOURCES.quotation}/${response.data.id}`
      })
      .catch((error) => {
        console.log(error)
        this.setState({ is_loading: false })
      })
  }

  // =======Clone Section [End]

  getQuotations(resetPage) {
    // filter: {}
    // range: [0,9]
    // sort: ["code","ASC"]
    let { page, per_page, selected_sorting, selected_sorting_reversed, selected_state, selected_cate_id, selected_date_form, selected_date_to, query } = this.state
    let target_page = resetPage ? 0 : page
    let range_start = target_page * per_page
    let sort_dir = selected_sorting_reversed ? 'DESC' : 'ASC'
    let options = {
      sort: JSON.stringify([selected_sorting, sort_dir]),
      filter: {},
      range: JSON.stringify([range_start, range_start + per_page - 1]),
    }
    if (!objectIsEmpty(selected_state)) {
      options.filter.state = selected_state.map((elm) => {
        return elm.value
      })
    }
    if (selected_date_form || selected_date_to) {
      let start_date = formatApiDate(selected_date_form)
      let end_date = formatApiDate(selected_date_to)
      options.filter.quotation_date = [start_date, end_date]
    }
    if (selected_cate_id) {
      options.filter.item_category_id = selected_cate_id
    }
    if (query) {
      options.filter.query = query
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.quotation}`, {
        params: options,
      })
      .then((response) => {
        let page_count = getHeaderTotalPageCount(response, this.state.per_page)

        this.setState({
          quotations: response.data,
          reFiltering: false,
          page_count: page_count,
          resetKey: resetPage ? uniqueKey() : this.state.resetKey,
        })
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          reFiltering: false,
        })
      })
      .then(() => {
        // always executed
      })
  }

  handleChange(event) {
    this.setState({ value: event.target.value })
  }

  handleSubmit(event) {
    event.preventDefault()

    delete searchParams['page']

    this.addSearchParams(searchParams)
    this.setState({ reFiltering: true })
  }

  onEnter() {
    this.setState({ reFiltering: true })
  }

  onChangeID(e) {
    this.setState({ selected_id: e.target.value })
  }

  onChangeState(e) {
    let state_search = []

    // if (e.length < 8) {
    e.forEach((state) => {
      state_search.push(state.value)
    })
    state_search = state_search.join(',')
    searchParams['state'] = state_search
    // }

    this.setState({ selected_state: e })
  }

  onChangeItemCategoryId = (value) => {
    const category = new URLSearchParams(this.props.location.search).get('category') || null
    if ((!value && searchParams['category']) || isNaN(value) === true) {
      delete searchParams['category']
    } else {
      searchParams['category'] = value
    }

    if (category && category !== null) {
      this.setState({ selected_cate_id: category })
    }

    if (value && isNaN(value) === false) {
      this.setState({ selected_cate_id: value })
    }
  }

  addSearchParams(params, path = this.props.location.pathname) {
    let searchParams = ''
    Object.keys(params).forEach((key, index) => {
      searchParams = searchParams + `${Object.keys(params).length > 1 && index !== 0 ? '&' : ''}${key}=${params[key]}`
    })

    this.props.history.replace({ pathname: `${path}`, search: searchParams })
  }

  onChangeSorting(e) {
    this.setState({ selected_sorting: e.value })
  }

  onChangeStationName(e) {
    this.setState({ selected_station_name: e.target.value })
  }

  onChangeQuery(e) {
    const value = e.target.value

    if (value === '' && searchParams['query']) {
      delete searchParams['query']
    } else {
      searchParams['query'] = value
    }

    this.setState({ query: value })
  }

  onChangeFromDate(date) {
    searchParams['start_date'] = moment(date).format('MM/DD/YYYY')
    this.setState({ selected_date_form: date })
  }

  onChangeToDate(date) {
    searchParams['end_date'] = moment(date).format('MM/DD/YYYY')
    this.setState({ selected_date_to: date })
  }

  onClickClear() {
    this.setState({
      selected_state: null,
      selected_cate_id: null,
      selected_period: '',
      selected_id: '',
      selected_station_name: '',
      selected_sorting: 'quotation_date',
      selected_sorting_reversed: true,
      selected_date_form: '',
      selected_date_to: '',
      page: 0,
      per_page: PER_PAGE,
      query: '',
      reFiltering: true,
      resetKey: uniqueKey(),
    })

    searchParams = {}
    this.props.history.replace({
      pathname: `${this.props.location.pathname}`,
      search: null,
    })
  }

  onClickQuo(id) {
    this.addSearchParams(searchParams, `/${API_RESOURCES.quotation}/${id}`)
  }

  onClickSort(type) {
    let new_toggle = this.state.selected_sorting === type ? !this.state.selected_sorting_reversed : false
    this.setState({
      selected_sorting: type,
      selected_sorting_reversed: new_toggle,
      reFiltering: true,
    })
  }

  onClickSortUp(active, type) {
    let sortType = active ? type : ''
    this.setState({
      selected_sorting: sortType,
      selected_sorting_reversed: false,
      reFiltering: true,
    })
  }

  onClickSortDown(active, type) {
    let sortType = active ? type : ''
    this.setState({
      selected_sorting: sortType,
      selected_sorting_reversed: true,
      reFiltering: true,
    })
  }

  headerColWithSortIcon(text, sortText) {
    let active = this.state.selected_sorting === sortText
    let sort_up = active && !this.state.selected_sorting_reversed
    let sort_down = active && this.state.selected_sorting_reversed

    return (
      <div className="d-flex flex-row cursor-pointer align-items-center" onClick={() => this.onClickSort(sortText)}>
        <div className="col-break-word text-bold">{text}</div>
        <IconSorting
          // onClickUp={this.onClickSort}
          // onClickDown={this.onClickSortDown}
          type={sortText}
          key={Math.random() * 100}
          activeUp={sort_up ? true : undefined}
          activeDown={sort_down ? true : undefined}
        />
      </div>
    )
  }

  onShowQuotationDetails(id) {
    if (id !== this.state.collapsed_id) {
      this.setState({ collapsed_id: id })
    } else {
      this.setState({ collapsed_id: null })
    }
  }
  /* ========================================================= */
  /* Pagination (start) */
  handlePageClick(data) {
    if (data.selected !== 0) searchParams['page'] = data.selected + 1
    else delete searchParams['page']

    this.setState({ reFiltering: true, page: data.selected })
  }
  /* Pagination (end) */
  /* ========================================================= */

  tableHeader() {
    return (
      <div className="card-box mt-1 p-3 d-none d-md-block mt-3">
        <div className="row">
          <div className="col-12 d-flex flex-column flex-sm-row align-items-center">
            <div className="col-custom-18 col-custom-sm-100 px-1 py-2">{this.headerColWithSortIcon('เลขที่ใบเสนอราคา', 'code')}</div>
            <div className="col-custom-26 col-custom-sm-100 px-1 py-2">{this.headerColWithSortIcon('สถานี', 'client_station.name')}</div>
            <div className="col-custom-18 col-custom-sm-100 px-1 py-2">{this.headerColWithSortIcon('สถานะ', 'state')}</div>
            <div className="col-custom-18 col-custom-sm-100 px-1 py-2">{this.headerColWithSortIcon('วันที่ออก', 'quotation_date')}</div>
            <div className="col-custom-10 col-custom-sm-100 px-1 py-2">{this.headerColWithSortIcon('ยอดรวม', 'total_sales')}</div>
            <div className="col-custom-10 col-custom-sm-100 px-1 py-2"></div>
          </div>
        </div>
      </div>
    )
  }

  quoItems() {
    let { quotations, collapsed_id } = this.state
    let items = quotations.map((elm, index) => {
      let total_with_vat = totalSalesWithVat(elm.total_sales)
      let quo_vendor_details = []
      let quo_dc_details = []
      let itemsByCategory = _.groupBy(elm.quotation_items, function (value) {
        return value.item_category_id
      })
      let categoryName = _.groupBy(elm.quotation_item_categories, function (value) {
        return value.item_category_id
      })

      quo_vendor_details.push(
        <div className="row pb-2">
          <div className="col">
            <b>สินค้าผู้ผลิต</b>
          </div>
        </div>
      )

      let vendor_cate_index = 0
      for (let cate_id in itemsByCategory) {
        if (categoryName[cate_id][0].item_category.dc_id === 0) {
          vendor_cate_index += 1
          for (let item_index in itemsByCategory[cate_id]) {
            let item = itemsByCategory[cate_id][item_index]
            if (item_index === '0') {
              quo_vendor_details.push(
                <div className="row">
                  <div className="col">
                    <u>
                      {vendor_cate_index}. {categoryName[cate_id][0].sys_name}
                    </u>
                  </div>
                </div>
              )
            }

            quo_vendor_details.push(
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col">
                  - {item.name} ({item.qty})
                </div>
              </div>
            )
          }
        }
      }

      quo_dc_details.push(
        <div className="row pb-2">
          <div className="col">
            <b>สินค้า DC</b>
          </div>
        </div>
      )

      let dc_cate_index = 0
      for (let cate_id in itemsByCategory) {
        if (categoryName[cate_id][0].item_category.dc_id === 1) {
          dc_cate_index += 1
          for (let item_index in itemsByCategory[cate_id]) {
            let item = itemsByCategory[cate_id][item_index]
            if (item_index === '0') {
              quo_dc_details.push(
                <div className="row">
                  <div className="col">
                    <u>
                      {dc_cate_index}. {categoryName[cate_id][0].sys_name}
                    </u>
                  </div>
                </div>
              )
            }

            quo_dc_details.push(
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col">
                  - {item.name} ({item.qty})
                </div>
              </div>
            )
          }
        }
      }

      return (
        <div className="card-box mt-1 px-3 pt-3 link-no-hover" key={elm.code + index}>
          <div className="row mb-2">
            {/* md */}
            <div className="col-12 flex-custom-md flex-custom-sm-none flex-column flex-sm-row cursor-pointer" onClick={() => this.onClickQuo(elm.id)}>
              <div className="col-custom-18 col-custom-sm-100 px-1">
                <div className="col-break-word">{`${elm.code ? elm.code : '-'}`}</div>
              </div>

              <div className="col-custom-26 col-custom-sm-100 px-1">
                <div className="col-break-word">{`${elm.client_station.name ? elm.client_station.name : '-'}`}</div>
              </div>

              <div className="col-custom-18 col-custom-sm-100 px-1">
                <div className="col-break-word">{`${elm.state ? elm.state : '-'}`}</div>
              </div>

              <div className="col-custom-18 col-custom-sm-100 px-1">
                <div className="col-break-word">{`${elm.quotation_date ? formatDate(elm.quotation_date) : '-'}`}</div>
              </div>

              <div className="col-custom-10 col-custom-sm-100 px-1">
                <div className="col-break-word">{`${total_with_vat ? total_with_vat.toLocaleString() : '0'}`}</div>
              </div>
              <div className="col-custom-10 col-custom-sm-100 px-1 flex-row justify-content-end">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  onClick={(e) => {
                    e.stopPropagation()
                    this.onShowQuotationDetails(elm.id)
                  }}
                >
                  {collapsed_id === elm.id ? 'ปิด' : 'เปิด'}
                </button>
              </div>
            </div>

            {/* sm */}
            <div className="col-12 flex-custom-sm flex-custom-md-none flex-column flex-sm-row cursor-pointer" onClick={() => this.onClickQuo(elm.id)}>
              <div className="col-custom-18 col-custom-sm-100 px-1">
                <div className="col-break-word">
                  <span className="text-bold pr-2">เลขที่ใบเสนอราคา</span>
                  <span className="text-bold pr-2">:</span>
                  {`${elm.code ? elm.code : '-'}`}
                </div>
              </div>

              <div className="col-custom-26 col-custom-sm-100 px-1">
                <div className="col-ellipsis">
                  <span className="text-bold pr-2">สถานี</span>
                  <span className="text-bold pr-2">:</span>
                  {`${elm.client_station.name ? elm.client_station.name : '-'}`}
                </div>
              </div>

              <div className="col-custom-18 col-custom-sm-100 px-1">
                <div className="col-break-word">
                  <span className="text-bold pr-2">สถานะ</span>
                  <span className="text-bold pr-2">:</span>
                  {`${elm.state ? elm.state : '-'}`}
                </div>
              </div>

              <div className="col-custom-18 col-custom-sm-100 px-1">
                <div className="col-break-word">
                  <span className="text-bold pr-2">วันที่ออก</span>
                  <span className="text-bold pr-2">:</span>
                  {`${elm.quotation_date ? formatDate(elm.quotation_date) : '-'}`}
                </div>
              </div>

              <div className="col-custom-10 col-custom-sm-100 px-1">
                <div className="col-break-word">
                  <span className="text-bold pr-2">ยอดรวม</span>
                  <span className="text-bold pr-2">:</span>
                  {`${total_with_vat ? total_with_vat.toLocaleString() : '0'}`}
                </div>
              </div>
              <div className="col-custom-10 col-custom-sm-100 flex-row justify-content-end mt-1">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  onClick={(e) => {
                    e.stopPropagation()
                    this.onShowQuotationDetails(elm.id)
                  }}
                >
                  {collapsed_id === elm.id ? 'ปิด' : 'เปิด'}
                </button>
              </div>
            </div>
          </div>
          <div className={`div-hide-scrollbar quo-data-collapse ${collapsed_id === elm.id ? 'show' : ''}`}>
            <div className="row mt-1 ">
              <div className="col-12 d-flex flex-row justify-content-end d-sm-none">
                <button type="button" className="btn btn-outline-secondary btn-sm ml-2 mt-2" onClick={() => this.handleOpenModalPreview(elm)}>
                  Preview
                </button>
                {!this.isCloned(elm.state) ? (
                  <button type="button" className="btn btn-outline-secondary btn-sm ml-2 mt-2" onClick={() => (this.isApproved(elm.state) ? this.handleCloneModal(true, elm) : this.handleCloneQuotation(elm))}>
                    Clone
                  </button>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="row mt-1">
              <div className="col-sm-12 col-md-5">{quo_vendor_details}</div>
              <div className="col-sm-12 col-md-5">{quo_dc_details}</div>
              <div className="col-sm-12 col-md-2 d-none d-sm-flex flex-row justify-content-end">
                <button type="button" className="btn btn-outline-secondary btn-sm ml-2 mt-2" onClick={() => this.handleOpenModalPreview(elm)}>
                  Preview
                </button>
                {!this.isCloned(elm.state) ? (
                  <button type="button" className="btn btn-outline-secondary btn-sm ml-2 mt-2" onClick={() => (this.isApproved(elm.state) ? this.handleCloneModal(true, elm) : this.handleCloneQuotation(elm))}>
                    Clone
                  </button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      )
    })
    return items
  }

  handleOpenModalPreview(quotation) {
    this.setState({ modal_preview: true, selected_preview_quo: quotation })
  }

  handleCloseModalPreview() {
    this.setState({ modal_preview: false })
  }

  previewDoc() {
    let { selected_preview_quo } = this.state
    return (
      <Modal isOpen={this.state.modal_preview} contentLabel="onRequestClose Example" onRequestClose={this.handleCloseModalPreview} style={window.innerWidth > R_SCREEN_DOCK_SIZE ? MODAL_STYLE_LARGE : MODAL_STYLE_LARGE_MD}>
        <div className="w-100 text-right d-none d-md-block">
          <button type="button" className="btn btn-danger ml-2" onClick={this.handleCloseModalPreview}>
            Close
          </button>
        </div>
        <QuotationFormDocuments quotation={selected_preview_quo} />
      </Modal>
    )
  }

  render() {
    return (
      <React.Fragment>
        <Spinner loading={this.state.reFiltering} />
        <div className="offset-xl-1 col-xl-10 p-3 home-scrollable">
          <div className="row align-items-end">
            <div className="col-md-7 col-12 pb-2 d-flex flex-row align-items-end">
              <span className="header-title d-md-block my-0">{APP_TITLE_QUO}</span>
            </div>
            <div className="col-md-5 col-12 text-right">
              <div className="row justify-content-end pb-3 pr-3">
                <button type="button" className="btn btn-primary mr-0" onClick={() => this.props.onChangeQuoPage('new')}>
                  New
                </button>
              </div>
            </div>
          </div>

          <div className="card-box light p-3">
            <form onSubmit={this.handleSubmit}>
              <SearchBar onEnter={this.onEnter} onChangeQuery={this.onChangeQuery} onClickClear={this.onClickClear} query={this.state.query} placeholder={'ค้นหาเลขที่ใบเสนอราคา, สถานี, ผู้ซื้อ'} />

              <div className="row">
                <div className="col-4 col-md-2 mt-2">
                  <label>วันที่ออก</label>
                </div>
                <div className="col-8 col-md-4 mt-2 date-picker-wrapper">
                  <DatePicker className="form-control" selected={this.state.selected_date_form} onChange={(date) => this.onChangeFromDate(date)} dateFormat={FORMAT_APP_DATE} />
                </div>
                <div className="col-4 col-md-2 mt-2">
                  <label>ถึง</label>
                </div>
                <div className="col mt-2 date-picker-wrapper">
                  <DatePicker className="form-control" selected={this.state.selected_date_to} onChange={(date) => this.onChangeToDate(date)} dateFormat={FORMAT_APP_DATE} />
                </div>
              </div>

              <div className="row">
                <div className="col-4 col-md-2 mt-2">
                  <label>หมวดหมู่สินค้า</label>
                </div>
                <div className="col-8 col-md-4 mt-2 ">
                  <ItemCategorySelect onChange={this.onChangeItemCategoryId} itemCateId={this.state.selected_cate_id} />
                </div>
              </div>

              <div className="row">
                <div className="col-4 col-md-2 mt-2">
                  <label>สถานะ</label>
                </div>
                <div className="col-8 col-md-10 mt-2">
                  <Select isMulti value={this.state.selected_state} onChange={this.onChangeState} options={OPTIONS_STATES} placeholder={'ค้นหา...'} />
                </div>
                <div className="col-4 col-md-2 mt-2 d-block d-md-none">
                  <label>Sort By</label>
                </div>
                <div className="col mt-2 d-block d-md-none">
                  <Select value={OPTIONS_SORT.filter((option) => option.value === this.state.selected_sorting)} onChange={this.onChangeSorting} options={OPTIONS_SORT} />
                </div>
              </div>

              <div className="row d-block d-md-none">
                <div className="col-12 mt-3 text-right">
                  <button type="button" className="btn btn-primary ml-2" onClick={() => this.getQuotations(true)}>
                    Search
                  </button>
                  <button type="button" className="btn btn-danger ml-2" onClick={this.onClickClear}>
                    clear
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div className="d-flex justify-content-end my-3">
            <ReactPaginate
              forcePage={this.state.page}
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.page_count}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              disabledClassName={'pagination__link--disabled'}
              activeClassName={'pagination__link--active'}
              key={this.state.resetKey}
            />
          </div>
          <div className="">
            {this.tableHeader()}
            {this.quoItems()}
          </div>
        </div>
        {this.previewDoc()}
        {this.displayCloneOption()}
      </React.Fragment>
    )
  }
}
export default withRouter(QuotationIndex)

import React from "react";
import Modal from "react-modal";
import _ from "lodash";

import { MODAL_STYLE_SMALL_SM } from "../../../constants";

import { itemCategoryName } from "../../../models/ItemCategories";
import { calTotalDiscount, calTotalCost } from "../../../models/Orders";
import { getWarrantyCode } from "../../../models/Orders";
import { getCategoryTotalExpense } from "../../../models/Quotations";

import Number from "../../global/Number";
import ModalConfirmation from "../../global/ModalConfirmation";

import { stringIsEmpty } from "../../../functions/String";
import { Multiple } from "../../../functions/Number";

export class OrderFormCostPriceItems extends React.Component {
  constructor(props) {
    super(props);
    let temp_purchasing_order_items = props.order.purchasing_order_items;
    let purchasing_order_items = [];
    Object.keys(temp_purchasing_order_items).forEach((key) => {
      temp_purchasing_order_items[key].opened = true;
      purchasing_order_items[key] = temp_purchasing_order_items[key];
    });
    let order_items = purchasing_order_items.filter((elm) => !elm.is_mbom);
    let itemsByCategory = _.groupBy(order_items, function (value) {
      return value.item_category_id;
    });

    this.state = {
      items_by_category: itemsByCategory,
      selected_item: "",
      selected_item_category: "",
      edited_item: null,
      custom_item: "",
      custom_quantity: "",
      quantity: "",
      comment: "",
      modal_preview: false,
      isConfirmingAdjustment: false,
      vendor: {},
      is_open_sub_item: [],
    };

    this.handleOpenEditedModal = this.handleOpenEditedModal.bind(this);
    this.handleCloseEditedModal = this.handleCloseEditedModal.bind(this);
    this.onChangeComment = this.onChangeComment.bind(this);

    // Confirm shipping adjustment
    this.onResetContractPrice = this.onResetContractPrice.bind(this);
    this.onHandleFeeAdjustModal = this.onHandleFeeAdjustModal.bind(this);
  }

  handleOpenEditedModal(id) {
    this.setState({ edited_item: id });
  }

  handleCloseEditedModal() {
    this.setState({ edited_item: null });
  }

  onChangeComment(e) {
    this.setState({ comment: e.target.value });
  }

  /* Confirm shipping adjustment (start) */
  onHandleFeeAdjustModal(cate_id) {
    this.setState({ isConfirmingAdjustment: cate_id });
  }

  onResetContractPrice() {
    this.props.onResetContractPrice([this.state.isConfirmingAdjustment]);
    this.onHandleFeeAdjustModal(false);
  }

  displayFeeAdjustmentModal() {
    let { isConfirmingAdjustment } = this.state;
    let { order_item_categories } = this.props.order;
    if (isConfirmingAdjustment !== false) {
      return (
        <ModalConfirmation
          isOpen={true}
          title="ปรับค่าราคาขาซื้อเป็นค่าเริ่มต้น"
          content={
            <p>
              ต้องการปรับค่าราคาขาซื้อของ{" "}
              <u>
                <strong>
                  {itemCategoryName(
                    order_item_categories[isConfirmingAdjustment]
                  )}
                </strong>
              </u>{" "}
              เป็นค่าเริ่มต้น ?
            </p>
          }
          onConfirm={this.onResetContractPrice}
          onCancel={() => this.onHandleFeeAdjustModal(false)}
        />
      );
    }
  }
  /* Confirm shipping adjustment (end) */

  inputField(elm, field, isNumber) {
    if (isNumber === true) {
      return (
        <Number
          innerClass="text-right"
          name={field}
          value={elm[field]}
          onChange={(e) => this.props.onChangeItemDetails(e, elm.id)}
          disabled={this.props.disabled}
        />
      );
    } else {
      return (
        <input
          className="form-control text-right"
          name={field}
          value={elm[field]}
          onChange={(e) => this.props.onChangeItemDetails(e, elm.id)}
          disabled={this.props.disabled}
        ></input>
      );
    }
  }

  editModal(elm) {
    let { edited_item } = this.state;
    return (
      <Modal
        isOpen={elm.id === edited_item}
        contentLabel="onEditItem"
        onRequestClose={this.handleCloseEditedModal}
        style={MODAL_STYLE_SMALL_SM}
      >
        <div className="d-flex flex-column">
          <h5>แก้ไขรายการสินค้า</h5>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>รายการ</label>
            </div>
            <div className="d-flex-2">{elm.name}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>จำนวน</label>
            </div>
            <div className="d-flex-2">{elm.qty}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>หน่วย</label>
            </div>
            <div className="d-flex-2">{elm.unit}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>ราคา</label>
            </div>
            <div className="d-flex-2">
              {this.inputField(elm, "contract_price", true)}
            </div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>รวม</label>
            </div>
            <div className="d-flex-2">
              {(elm.contract_price * elm.qty).toLocaleString()}
            </div>
          </div>
          <div className="d-flex flex-row mt-2">
            <button
              type="button"
              className="btn btn-success btn-sm w-100"
              onClick={this.handleCloseEditedModal}
              disabled={this.props.disabled}
            >
              บันทึก
            </button>
            <button
              type="button"
              className="btn btn-danger btn-sm w-100 ml-1"
              onClick={() => this.props.onDeleteItem(elm)}
              disabled={this.props.disabled}
            >
              ลบ
            </button>
            <button
              type="button"
              className="btn btn-secondary btn-sm w-100 ml-1"
              onClick={this.handleCloseEditedModal}
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  onClickRow = (key, index) => {
    let { items_by_category } = this.state;
    items_by_category[key][index].opened =
      !items_by_category[key][index].opened;
    this.setState({ items_by_category: items_by_category });
  };

  displaySubItems(subItemArr) {
    let subItems = subItemArr.map((elm, index) => {
      return (
        <React.Fragment key={elm.id}>
          <div className="row border mt-1 d-none d-md-flex">
            <div className="sub-row-item-bg col-1 px-0 first justify-content-center" />
            <div className="sub-row-item-bg col-1 px-0">
              {`ชิ้นที่ ${index + 1}`}
            </div>
            <div className="sub-row-item-bg col-1 px-0">{`ID : ${elm.id}`}</div>
            <div className="sub-row-item-bg col-2 px-0 justify-content-end">
              ราคาต้นทุนขาเข้า
            </div>
            <div className="sub-row-item-bg col-2 pl-0 justify-content-end">
              {`${elm.inbound_cost_price.toLocaleString()} ฿`}
            </div>
            <div className="sub-row-item-bg col-3 px-0 justify-content-center">
              {this.inputField(elm, "contract_price", true)}{" "}
              <p className="px-3 m-0">฿</p>
            </div>
            <div className="sub-row-item-bg col-2 px-0 justify-content-center" />
          </div>
        </React.Fragment>
      );
    });
    return subItems;
  }

  displayItems(itemsByCate, index_offset, key) {
    let items = itemsByCate.map((elm, index) => {
      let net_cost = Multiple(elm.contract_price, elm.qty);
      let code_name = `${elm.code ? elm.code + "-" : ""}${elm.name}`;
      let is_opened = elm.opened;
      let tempSubItem = [
        { id: "01", inbound_cost_price: 16210 },
        { id: "02", inbound_cost_price: 16250 },
      ];
      let rowSubItems = this.displaySubItems(tempSubItem);
      return (
        <React.Fragment key={elm.id}>
          {/* md up */}
          <div className="row mt-2 border d-none d-md-flex">
            <div className="table-item-bg col-1 px-0 first justify-content-center">
              {index_offset + index}
            </div>
            <div className="table-item-bg col-4 px-0">{`${code_name}`}</div>
            <div className="table-item-bg col-2 pl-0 justify-content-end">
              {`${elm.qty} ${elm.unit}`}
            </div>
            <div className="table-item-bg col-3 px-0 justify-content-center">
              {this.inputField(elm, "contract_price", true)}{" "}
              <p className="px-3 m-0">฿</p>
            </div>
            <div className="table-item-bg col-2 px-0 justify-content-between">
              <div className="d-flex justify-content-start align-items-center">
                / ชิ้น
              </div>
              <div
                className="d-flex justify-content-start align-items-center"
                style={{ fontSize: "10px" }}
              >
                Apply all
              </div>
              <i
                className={`${
                  is_opened ? "icon-arrow-up" : "icon-arrow-down"
                } ml-1 mr-1`}
                onClick={() => this.onClickRow(key, index)}
              />
            </div>
          </div>
          {is_opened ? rowSubItems : null}
          {/* md down */}
          <a
            onClick={() =>
              this.props.disabled ? false : this.handleOpenEditedModal(elm.id)
            }
          >
            <div className="row mt-2 border d-md-none">
              <div className="table-item-bg col-12 pt-1 col-md-4 pr-0">
                {code_name}
              </div>
              <div className="table-item-bg col col-md-1 text-center d-md-none">
                จำนวน {elm.qty} {elm.unit}
              </div>
              <div className="table-item-bg col-12 col-md d-flex justify-content-end bg-ghostwhite d-md-none">
                <h4 className="sign-baht color-darkgrayblue">
                  {net_cost.toLocaleString()}
                </h4>
              </div>
            </div>
          </a>
          {this.editModal(elm)}
        </React.Fragment>
      );
    });
    return items;
  }

  displayItemByCategory() {
    let { items_by_category } = this.state;
    let { order } = this.props;
    let { order_item_categories } = order;
    let index_offset = 1;
    let itemsByCategory = items_by_category;
    let result = Object.keys(items_by_category).map((key, index) => {
      let current_cate = order_item_categories[key];
      let price_sum = 0;
      let rowItems = this.displayItems(itemsByCategory[key], index_offset, key);
      itemsByCategory[key].map((temp) => {
        let contract_price = parseFloat(temp.contract_price);
        let qty = parseFloat(temp.qty);
        price_sum += Multiple(qty, contract_price);
      });
      index_offset += itemsByCategory[key].length;
      let sum_expense = getCategoryTotalExpense(
        current_cate.item_category_expenses,
        true
      );
      return (
        <React.Fragment key={key}>
          <div className="row purchase-price">
            <div className="table-item-bg category-title first col-1 pl-2">
              <i
                className={`icon-adjust cursor-pointer ${
                  this.props.disabled ? "d-none" : ""
                }`}
                onClick={() => this.onHandleFeeAdjustModal(key)}
              />
            </div>
            <div
              className="table-item-bg category-title font-weight-bold col-11 px-0"
              style={{ minHeight: "38px" }}
            >
              {order_item_categories[key].sys_name}
            </div>
          </div>
          {rowItems}
        </React.Fragment>
      );
    });

    return result;
  }

  displayTable() {
    let tableHeight = "";
    return (
      <React.Fragment>
        <div className="col-12">
          <div className="row table-header d-none d-md-flex">
            <div className="table-item-bg col-1 px-0 first justify-content-center">
              <strong>ลำดับ</strong>
            </div>
            <div className="table-item-bg col-4 px-0 ">
              <strong>รายการสินค้า DC</strong>
            </div>
            <div className="table-item-bg col-2 pl-0 justify-content-end">
              <strong>จำนวน</strong>
            </div>
            <div className="table-item-bg col-4 pl-0 pr-4 justify-content-center">
              <strong>ต้นทุนเพิ่มเติม</strong>
            </div>
            <div className="table-item-bg col-1 pl-0 pr-3 justify-content-end" />
          </div>
          <div className={`row quo-table ${tableHeight}`}>
            <div className="col-12">{this.displayItemByCategory()}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  displaySummary(md) {
    let { order } = this.props;
    let total_with_discount = calTotalCost(order, true);
    let discount = calTotalDiscount(order, true);
    let total = total_with_discount + discount;
    let vat = total_with_discount * 0.07;

    let text_total = "รวมเป็นเงิน";
    let text_discount = "ส่วนลด";
    let text_total_with_discount = "หลังหักส่วนลด";
    let text_vat = "VAT 7%";
    let text_sum = "รวมทั้งสิ้น";
    if (md) {
      return (
        <React.Fragment>
          {/* lg down */}
          <div className="mt-2 d-lg-none">
            <div className="row">
              <div className="table-item-bg col p-0 justify-content-end">
                {text_total}
              </div>
              <div className="table-item-bg col justify-content-end sign-baht">
                {total.toLocaleString()}
              </div>
            </div>
            <div className="row">
              <div className="table-item-bg col p-0 justify-content-end">
                {text_discount}
              </div>
              <div className="table-item-bg col justify-content-end sign-baht">
                {discount.toLocaleString()}
              </div>
            </div>
            <div className="row">
              <div className="table-item-bg col p-0 justify-content-end">
                {text_total_with_discount}
              </div>
              <div className="table-item-bg col justify-content-end sign-baht">
                {total_with_discount.toLocaleString()}
              </div>
            </div>
            <div className="row mb-2">
              <div className="table-item-bg col p-0 justify-content-end">
                {text_vat}
              </div>
              <div className="table-item-bg col justify-content-end sign-baht">
                {vat.toLocaleString()}
              </div>
            </div>
            <div className="row">
              <div className="table-item-bg total col p-0 justify-content-end">
                {text_sum}
              </div>
              <div className="table-item-bg total col justify-content-end sign-baht">
                {(vat + total_with_discount).toLocaleString()}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {/* lg up */}
          <div className="mt-2 d-none d-lg-block">
            <div className="d-flex flex-row pr-3">
              <div className="d-flex-1 py-1 text-right">{text_total}</div>
              <div className="d-flex-1 py-1 text-right">
                {total.toLocaleString()}
              </div>
            </div>
            <div className="d-flex flex-row pr-3">
              <div className="d-flex-1 py-1 text-right">{text_discount}</div>
              <div className="d-flex-1 py-1 text-right">
                {discount.toLocaleString()}
              </div>
            </div>
            <div className="d-flex flex-row pr-3">
              <div className="d-flex-1 py-1 text-right">
                {text_total_with_discount}
              </div>
              <div className="d-flex-1 py-1 text-right">
                {total_with_discount.toLocaleString()}
              </div>
            </div>
            <div className="d-flex flex-row pr-3">
              <div className="d-flex-1 py-1 text-right">{text_vat}</div>
              <div className="d-flex-1 py-1 text-right">
                {vat.toLocaleString()}
              </div>
            </div>
            <div className="d-flex flex-row pr-3 bg-grayyellow">
              <div className="d-flex-1 py-2 text-right font-weight-bold">
                {text_sum}
              </div>
              <div className="d-flex-1 py-2 text-right font-weight-bold">
                {(total_with_discount + vat).toLocaleString()}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  rightSideBar() {
    return (
      <React.Fragment>
        <div className="quo-right-side-bar">{this.displaySummary()}</div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="col">
          <div className="row">
            <div className="col">{this.displayTable()}</div>
            <div className="col-3 d-none d-lg-block">{this.rightSideBar()}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

import React from "react";
import "../DcInboundOrder.css";
import dayjs from "dayjs";

export default function DcInboundOrderInfo({ dc_info, current_user, order_data }) {
  function GetDcAddress() {
    return <div>{dc_info && `ต.${dc_info[0].address.sub_district.name} อ.${dc_info[0].address.district.name} จ.${dc_info[0].address.province.name} ${dc_info[0].address.postcode}`}</div>;
  }

  return (
    <React.Fragment>
      <div className="offset-lg-1 col-lg-10 px-3 block-info home-scrollable">
        <h5 className="pb-1 ">ข้อมูลทั่วไป</h5>
        <div className="card-box p-3">
          <div className="row pt-md-1 pb-md-2 py-sm-0">
            <div className="col-md-2 col-4 pr-0 mt-2 text-bold">วันที่สร้างออเดอร์</div>
            <div className="col-md-10 col-8 mt-2">{dayjs(order_data?.created_at).format("DD-MM-YYYY")}</div>
          </div>
          <div className="row py-md-1 py-sm-0">
            <div className="col-md-2 col-4 pr-0 mt-2 text-bold">เลขที่ออเดอร์</div>
            <div className="col-md-10 col-8 mt-2">{order_data.code || "-"}</div>
          </div>
          <div className="row py-md-1 py-sm-0">
            <div className="col-md-2 col-4 pr-0 mt-2 text-bold">ผู้ออกออเดอร์</div>
            <div className="col-md-10 col-8 mt-2">
              {current_user.firstname} {current_user.lastname}
            </div>
          </div>
        </div>
        <h5 className="pt-3 pb-1">รายละเอียดที่อยู่ DC</h5>
        <div className="card-box p-3">
          <div className="row py-md-1 py-sm-0">
            <div className="col-md-2 col-4 pr-0 mt-2 text-bold">ที่อยู่</div>
            <div className="col-md-10 col-8 mt-2">{GetDcAddress()}</div>
          </div>
          <div className="row py-md-1 py-sm-0">
            <div className="col-md-2 col-4 pr-0 mt-2 text-bold">ชื่อผู้ติดต่อ</div>
            <div className="col-md-10 col-8 mt-2">{dc_info && dc_info[0].name}</div>
          </div>
          <div className="row py-md-1 py-sm-0">
            <div className="col-md-2 col-4 pr-0 mt-2 text-bold">เบอร์ติดต่อ</div>
            <div className="col-md-10 col-8 mt-2">{dc_info && dc_info[0].phone}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

import React from "react";
import PropTypes from "prop-types";

import "../../styles/model.css";

class ToggleSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChange = (e) => {
    this.props.onChange(e.target.checked);
  };

  render() {
    return (
      <React.Fragment>
        <label className="switch m-0">
          <input
            type="checkbox"
            onChange={this.onChange}
            checked={this.props.checked}
            disabled={this.props.disabled}
          ></input>
          <span className="slider round"></span>
        </label>
      </React.Fragment>
    );
  }
}

export default ToggleSwitch;

ToggleSwitch.propTypes = {
  checked: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export function stringIsEmpty(str) {
  if (str) {
    if (str.length === "") return true;
  } else {
    return true
  }

  return false
}
export function addressToStr(address) {
  let str = ''
  
  if (address) {
    if (address.address_line1) {
      str = address.address_line1
    }
  
    if (address.sub_district && address.sub_district.name) {
      str = str + ' ' + address.sub_district.name
    }
  
    if (address.district && address.district.name) {
      str = str + ' ' + address.district.name
    }
  
    if (address.province && address.province.name) {
      str = str + ' ' + address.province.name
    }
  
    if (address.postcode) {
      str = str + ' ' + address.postcode
    }
  }

  return str
}
import React from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Select from 'react-select'
import _ from 'lodash'
import { Button, Modal, Tooltip } from 'antd'

import './Quotation.css'

// Components
import Number from '../global/Number'
import ToggleSwitch from '../global/ToggleSwitch'

// models
import { getWarrantyCode } from '../../models/Orders'
import { WARRANTY_OPTIONS } from '../../models/ItemCategories'

// functions
import { formatDate, formatApiDate } from '../../functions/Date'

import { FORMAT_APP_DATE, QUO_STATES } from '../../constants.js'

const QUO_MANUFACTURE_TABS = {
  vendor: 0,
  dc: 1,
}
export class QuotationManufacture extends React.Component {
  constructor(props) {
    super(props)
    let opened_cate = {}
    for (let cate_id in props.quotation.quotation_item_categories) {
      opened_cate[cate_id] = true
    }
    this.state = {
      current_manufacturer_tab: QUO_MANUFACTURE_TABS.vendor,
      opened_cate: opened_cate,
      is_completed: props.quotation.state === QUO_STATES.order_issued_completed.name,
      modal_approved_item_visible: false,
      current_approved: {
        vendor: '',
        category: '',
      },
    }
  }

  /* Station Ready to Install */
  onChangeStationReady = (e, id) => {
    let ready = {
      target: {
        name: 'station_ready',
        value: e ? formatApiDate(new Date()) : null,
      },
    }

    this.props.onChangeItemManufactureDetails(ready, id)
  }

  onChangeCatStationReady(e, items) {
    if (e) {
      items = items.filter((items) => items.station_ready == null)
    } else {
      items = items.filter((items) => items.station_ready != null)
    }

    items.map((item) => {
      this.onChangeStationReady(e, item.id)
    })
  }

  onClickRow = (key) => {
    let { opened_cate } = this.state
    opened_cate[key] = !opened_cate[key]

    this.setState({ opened_cate: opened_cate })
  }

  // when change installation date to change days to installation
  onChangeInstallationDate = (date, id, key) => {
    let { quotation } = this.props
    let date_h = {
      target: {
        name: 'installation_date',
        value: date != null ? formatApiDate(date) : formatApiDate(new Date()),
      },
    }
    this.props.onChangeItemManufactureDetails(date_h, id)
    let inform_date_moment = moment(new Date(quotation.quotation_item_categories[key].informed_date))
    let num = moment(new Date(date)).startOf('day').diff(inform_date_moment.startOf('day'), 'days')

    let num_days = {
      target: {
        name: 'days_to_installation',
        value: num,
      },
    }
    this.props.onChangeItemManufactureDetails(num_days, id)
  }

  // when change days to installation to change installation date
  onChangeInstallationNumofD = (num, id) => {
    let { quotation } = this.props
    this.props.onChangeItemManufactureDetails(num, id)

    let approved_state_date_moment = moment(new Date(quotation.approved_state_date))
    let date = moment(approved_state_date_moment).add(num['target'].value, 'days')

    let date_h = {
      target: {
        name: 'installation_date',
        value: formatApiDate(date),
      },
    }
    this.props.onChangeItemManufactureDetails(date_h, id)
  }

  onChangeWarrantyType = (e, id) => {
    let type_h = {
      target: {
        name: 'warranty_type',
        value: e.value,
      },
    }
    this.props.onChangeItemManufactureDetails(type_h, id)
  }

  displayHeader(tabName) {
    // let { quotation, disabled } = this.props;
    // let quotation_items = quotation.quotation_items;

    return (
      <React.Fragment>
        <div className="row px-3">
          <div className="col-1 m-auto text-left">ลำดับ</div>
          <div className="col-11">{`หมวดหมู่ - ${tabName}`}</div>
        </div>
      </React.Fragment>
    )
  }

  displayVendorItemHeader() {
    return (
      <React.Fragment>
        <div className="col-2 text-center">รหัสทรัพย์สิน</div>
        <div className="col-4 text-center px-1">
          <div className="row align-items-center justify-content-center">
            <div className="col-5 px-1">วันที่ติดตั้ง</div>
            <div className="col-1 text-center px-0">|</div>
            <div className="col-5 px-1">วันจากวันที่แจ้ง</div>
            <div className="col-1 text-center px-0"></div>
          </div>
        </div>
        <div className="col-2 text-center">รับประกัน</div>
        <div className="col-2 text-center">หมดประกัน</div>
        <div className="col-2 text-center px-0">{this.props.isApproved ? <b>สถานีพร้อมติดตั้ง</b> : <div className="disabled-pale-text">สถานีพร้อมติดตั้ง</div>}</div>
      </React.Fragment>
    )
  }

  displayDcItemHeader() {
    return (
      <React.Fragment>
        <div className="col-5 text-center px-1">
          <div className="row align-items-center justify-content-center">
            <div className="col-5 px-1">วันที่ติดตั้ง</div>
            {/* <div className="col-1 text-center px-0">|</div>
            <div className="col-4 px-1">วันจากวันที่แจ้ง</div> */}
            <div className="col-2 text-center px-0"></div>
          </div>
        </div>
        <div className="col-3 text-center">รับประกัน</div>
        <div className="col-2 text-center">หมดประกัน</div>
        <div className="col-2 text-center">{this.props.isApproved ? <b>สถานีพร้อมติดตั้ง</b> : <div className="disabled-pale-text">สถานีพร้อมติดตั้ง</div>}</div>
      </React.Fragment>
    )
  }

  onClickConfirmApproved(vendor, category, key, type = null, informed_date = null) {
    // const { modal_approved_item_visible } = this.state;
    this.setState({
      modal_approved_item_visible: !this.state.modal_approved_item_visible,
      current_approved: {
        vendor: vendor,
        category: category,
        key: key,
        type: type,
        informed_date: informed_date,
      },
    })
  }

  displayVendorItems() {
    let { quotation, disabled } = this.props
    let { quotation_item_categories, quotation_items } = quotation
    let { opened_cate } = this.state
    let itemsByCate = _.groupBy(quotation_items, function (value) {
      return value.item_category_id
    })

    let item_index = 0

    let items = []


    Object.keys(itemsByCate).map((key) => {
      let is_opened = opened_cate[key]
      let current_cate = quotation_item_categories[key]
      let vendor_name = current_cate.contract ? current_cate.contract.vendor.name : ''

      itemsByCate[key].map((elm, index) => {
        const is_issued_some_items = itemsByCate[key].find((item) => item.issued === true)
        let isVendor = current_cate.item_category.dc_id === 0 || current_cate.dc_id === 0

        if (isVendor) {
          if (index === 0) {
            items.push(
              <div className="card-flex card-in-menu p-3" onClick={() => this.onClickRow(key)}>
                <div className="card-width ">
                  <div>
                    <span className="font-weight-bold">
                      <i className={`m-0 ${is_opened ? 'icon-arrow-up' : 'icon-arrow-down'}`} />
                      {current_cate.sys_name}
                    </span>
                  </div>
                </div>
                <div className="card-width">
                  <div className="card-flex ">
                    <div className="card-width box-input">
                      <div className="pr-1">สถานีพร้อมติดตั้งทั้งหมวด :</div>
                      <ToggleSwitch checked={itemsByCate[key].every((item) => item.station_ready != null)} onChange={(e) => this.onChangeCatStationReady(e, itemsByCate[key])} disabled={!this.props.isApproved || this.state.is_completed || (this.props.quotation.state === QUO_STATES.cloned_to_edit.name ? false : elm.issued)} />
                    </div>
                    <div className="card-width box-btn">
                      <div className="row justify-content-end px-2 pr-md-1">
                        {!current_cate.informed_date ? (
                          <Button
                            disabled={!this.props.isApproved || this.state.is_completed || (this.props.quotation.state === QUO_STATES.cloned_to_edit.name ? false : elm.issued)}
                            type="primary"
                            style={{
                              // marginLeft: 15,
                              width: 150,
                              borderRadius: 5,
                              border: 'none',
                              // position: "absolute",
                              // top: 10,
                              // right: -5,
                            }}
                            onClick={() => this.onClickConfirmApproved(vendor_name, current_cate.sys_name, key)}
                          >
                            แจ้งผู้ผลิต
                          </Button>
                        ) : (
                          <div
                            className={!is_issued_some_items && 'approved-container'}
                            style={{
                              // display: "flex",
                              // position: "absolute",
                              // top: 10,
                              // right: -5,
                              // marginLeft: 15,
                              // justifyContent: "center",
                              // alignItems: "center",
                              color: 'white',
                              width: 150,
                              height: 35,
                              padding: 5,
                              borderRadius: 5,
                              background: !is_issued_some_items ? 'green' : 'gray',
                              cursor: !is_issued_some_items ? 'pointer' : 'not-allowed',
                            }}
                            onClick={!is_issued_some_items ? () => this.onClickConfirmApproved(vendor_name, current_cate.sys_name, key, 'cancel', current_cate.informed_date) : () => { }}
                          >
                            <Tooltip placement="bottom" title={is_issued_some_items && 'ยกเลิกแจ้งผู้ผลิตไม่ได้เนื่องจากสินค้าในหมวดหมู่มีการออกออเดอร์ไปแล้ว'}>
                              <div style={{ whiteSpace: "nowrap", fontSize: 14 }} className="approved-text text-center col-break-word">แจ้งเมื่อ : {current_cate.informed_date}</div>
                              {!is_issued_some_items && <div style={{ textAlign: "center" }} className="cancel-text">ยกเลิก</div>}
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }

          if (elm.from_parent) return false

          item_index = item_index + 1

          if (is_opened) {
            let warranty_code = getWarrantyCode(elm.warranty_codes)
            let warrantyCodeDisabled = (disabled && !this.props.quotation.state == QUO_STATES.approved.name) || elm.issued
            let item_canceled = this.state.is_completed && !elm.issued
            let is_disabled = !elm.installation_date || !this.props.isApproved || this.state.is_completed || (this.props.quotation.state === QUO_STATES.cloned_to_edit.name ? false : elm.issued)
            // let expired_date = getWarrantyExpirationDate(elm)
            items.push(
              <React.Fragment>
                <div className={`row p-2 align-items-center ${index % 2 === 0 ? '' : 'bg-aliceblue'} ${item_canceled ? 'text-line-through' : ''}`}>
                  <div className="col-12 col-md-1 justify-content-center text-center text-md-left items-name">
                    <span className="running-number-highlight">{`${item_index}`} </span>
                  </div>
                  <div className="col-12 col-md-11 m-auto items-name  title-in-card">{`${elm.code} - ${elm.name}`}</div>
                </div>
                <div className={`row d-none d-md-flex p-2 align-items-center flex-row justify-content-between order-item-header`} style={{ cursor: 'default' }}>
                  {this.displayVendorItemHeader()}
                </div>
                <div className={`row p-2 align-items-center flex-row justify-content-between ${index % 2 === 0 ? '' : 'bg-aliceblue'} ${item_canceled ? 'text-line-through' : ''}`}>
                  <div className="col-12 text-bold justify-content-center d-flex d-md-none px-1 pb-1">รหัสทรัพย์สิน</div>
                  <div className="col-12 col-md-2 text-center px-1">
                    {warranty_code ? (
                      <React.Fragment>
                        {!warrantyCodeDisabled ? (
                          <div className="d-flex flex-row justify-content-center align-items-center">
                            <div className="white-space-break pl-2">{warranty_code}</div>
                            <i className="icon-cancel ml-1" onClick={() => this.props.onDeleteWarrantyCodes(elm.id)} />
                          </div>
                        ) : (
                          <div className="white-space-break text-center">{warranty_code}</div>
                        )}
                      </React.Fragment>
                    ) : current_cate.code || (elm.item && elm.item.asset_code) ? (
                      <button type="button" className="btn btn-primary font-size-13px w-75" onClick={() => this.props.onGetWarrantyCodes(elm.id)} disabled={warrantyCodeDisabled} style={{ maxWidth: '90px' }}>
                        สร้างรหัส
                      </button>
                    ) : (
                      '-'
                    )}
                  </div>
                  <div className="col-12 text-bold justify-content-center d-flex d-md-none pt-3 pb-1 px-1">
                    <div className="col-5 px-1 text-right">วันที่ติดตั้ง</div>
                    <div className="col-1 text-center px-0">|</div>
                    <div className="col-6 px-1 text-left">วันจากวันที่แจ้ง</div>
                  </div>
                  <div className="col-12 col-md-4 text-center px-1">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-4 col-md-5 px-1 text-right">
                        <DatePicker
                          style={{ width: '100%' }}
                          popperPlacement="top-end"
                          wrapperClassName="date-picker-w-100"
                          className="form-control text-right"
                          dateFormat={FORMAT_APP_DATE}
                          selected={elm.installation_date ? new Date(elm.installation_date) : null}
                          onChange={(date) => this.onChangeInstallationDate(date, elm.id, key)}
                          disabled={is_disabled}
                          placeholderText="วันที่ติดตั้ง"
                        />
                      </div>
                      <div className="col-1 text-center px-0">|</div>
                      <div className="col-4 px-1 text-left">
                        <Number innerClass="text-right" name={'days_to_installation'} value={elm.days_to_installation == 0 ? 0 : elm.days_to_installation} onChange={(e) => this.onChangeInstallationNumofD(e, elm.id)} disabled={is_disabled} placeholder="วัน" />
                      </div>
                      <div className="col-1 col-md-2 text-right text-md-left px-0">วัน</div>
                    </div>
                  </div>
                  <div className="col-12 text-bold justify-content-center d-flex d-md-none pt-3 pb-1 px-1">รับประกัน</div>
                  <div className="col-12 col-md-2 text-center px-1">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-5 col-md-5 px-1">
                        <Number innerClass="text-right" name={'warranty_value'} value={elm.warranty_value} onChange={(e) => this.props.onChangeItemManufactureDetails(e, elm.id)} disabled={is_disabled || elm.issued} />
                      </div>
                      <div className="col-5 col-md-7 px-1">
                        <Select menuPosition="fixed" value={WARRANTY_OPTIONS.filter((option) => option.value == elm.warranty_type)} onChange={(e) => this.onChangeWarrantyType(e, elm.id)} options={WARRANTY_OPTIONS} isDisabled={is_disabled} />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-bold justify-content-center d-flex d-md-none pt-3 px-1">หมดประกัน</div>
                  <div className="col-12 col-md-2 text-center px-1">
                    <div className="col-break-word">{`${elm.expiration_date ? formatDate(elm.expiration_date) : '-'}`}</div>
                  </div>
                  <div className="col-12 text-bold justify-content-center d-flex d-md-none pt-3 pb-1 px-1">สถานีพร้อมติดตั้ง</div>
                  <div className="col-12 col-md-2 text-center align-items-center px-1">
                    <ToggleSwitch checked={elm.station_ready != null} onChange={(e) => this.onChangeStationReady(e, elm.id)} disabled={is_disabled} />
                  </div>
                  {/* Station Ready to Install */}
                </div>
              </React.Fragment>
            )
          }
        }
      })
    })

    return items
  }

  displayDcItems() {
    let { quotation, disabled } = this.props
    let { quotation_item_categories, quotation_items } = quotation
    let { opened_cate } = this.state
    let itemsByCate = _.groupBy(quotation_items, function (value) {
      return value.item_category_id
    })

    let items = []

    Object.keys(itemsByCate).forEach((key) => {
      let is_opened = opened_cate[key]
      let current_cate = quotation_item_categories[key]
      let vendor_name = current_cate.contract ? current_cate.contract.vendor.name : ''

      itemsByCate[key].map((elm, index) => {
        const is_issued_some_items = itemsByCate[key].find((item) => item.issued === true)
        let isDC = current_cate.item_category.dc_id === 1 || current_cate.dc_id === 1
        if (isDC) {
          if (index === 0) {
            items.push(
              <div className="card-flex card-in-menu p-3" onClick={() => this.onClickRow(key)}>
                <div className="card-width ">
                  <div>
                    <span className="font-weight-bold">
                      <i className={`m-0 ${is_opened ? 'icon-arrow-up' : 'icon-arrow-down'}`} />
                      {current_cate.sys_name}
                    </span>
                  </div>
                </div>
                <div className="card-width">
                  <div className="card-flex ">
                    <div className="card-width box-input" style={{ width: '100%' }}>
                      <div className="pr-1">สถานีพร้อมติดตั้งทั้งหมวด :</div>
                      <ToggleSwitch checked={itemsByCate[key].every((item) => item.station_ready != null)} onChange={(e) => this.onChangeCatStationReady(e, itemsByCate[key])} disabled={!this.props.isApproved && !elm.issued} />
                    </div>
                    {/* <div className="card-width box-btn">
                      <div className="row justify-content-end px-2 pr-md-1">
                        {!current_cate.informed_date ? (
                          <Button
                            disabled={!this.props.isApproved || this.state.is_completed || (this.props.quotation.state === QUO_STATES.cloned_to_edit.name ? false : elm.issued)}
                            type="primary"
                            style={{
                              width: 150,
                              borderRadius: 5,
                              border: "none",
                            }}
                            onClick={() => this.onClickConfirmApproved(vendor_name, current_cate.sys_name, key)}
                          >
                            แจ้งผู้ผลิต
                          </Button>
                        ) : (
                          <div
                            className={!is_issued_some_items && "approved-container"}
                            style={{
                              color: "white",
                              width: 150,
                              height: 32,
                              borderRadius: 5,
                              background: !is_issued_some_items ? "green" : "gray",
                              cursor: !is_issued_some_items ? "pointer" : "not-allowed",
                            }}
                            onClick={!is_issued_some_items ? () => this.onClickConfirmApproved(vendor_name, current_cate.sys_name, key, "cancel", current_cate.informed_date) : () => {}}
                          >
                            <Tooltip placement="bottom" title={is_issued_some_items && "ยกเลิกแจ้งผู้ผลิตไม่ได้เนื่องจากสินค้าในหมวดหมู่มีการออกออเดอร์ไปแล้ว"}>
                              <div className="approved-text pl-2 pt-1">แจ้งเมื่อ : {current_cate.informed_date}</div>
                              {!is_issued_some_items && <div className="cancel-text pl-2 pt-1">ยกเลิก</div>}
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            )
          }

          if (is_opened) {
            let item_canceled = this.state.is_completed && !elm.issued
            let is_disabled = elm.issued ? true : !this.props.isApproved && !elm.issued
            // let expired_date = getWarrantyExpirationDate(elm)
            items.push(
              <React.Fragment>
                <div className={`row p-2 align-items-center ${index % 2 === 0 ? '' : 'bg-aliceblue'} ${item_canceled ? 'text-line-through' : ''}`}>
                  <div className="col-12 col-md-1 justify-content-center text-center text-md-left items-name">
                    <span className="running-number-highlight">{`${index + 1}`}</span>
                  </div>
                  <div className="col-12 col-md-11 m-auto items-name title-in-card">{`${elm.code} - ${elm.name}`}</div>
                </div>
                <div className={`row d-none d-md-flex p-2 align-items-center flex-row justify-content-between order-item-header`} style={{ cursor: 'default' }}>
                  {this.displayDcItemHeader()}
                </div>
                <div className={`row p-2 align-items-center flex-row justify-content-between ${index % 2 === 0 ? '' : 'bg-aliceblue'} ${item_canceled ? 'text-line-through' : ''}`}>
                  <div className="col-12 text-bold justify-content-center d-flex d-md-none pt-3 pb-1 px-1">
                    <div className="col-5 px-1 text-center">วันที่ติดตั้ง</div>
                    {/* <div className="col-1 text-center px-0">|</div>
                    <div className="col-6 px-1 text-left">วันจากวันที่แจ้ง</div> */}
                  </div>
                  <div className="col-12 col-md-5 text-center px-1">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-4 px-1 text-right">
                        <DatePicker
                          popperPlacement="top-end"
                          wrapperClassName="date-picker-w-100"
                          className="form-control text-right"
                          dateFormat={FORMAT_APP_DATE}
                          selected={elm.installation_date ? new Date(elm.installation_date) : null}
                          onChange={(date) => this.onChangeInstallationDate(date, elm.id, key)}
                          disabled={is_disabled}
                          placeholderText="วันที่ติดตั้ง"
                        />
                      </div>
                      {/* <div className="col-1 text-center px-0">|</div>
                      <div className="col-4 px-1 text-left">
                        <Number
                          innerClass="text-right"
                          name={"days_to_installation"}
                          value={elm.days_to_installation == 0 ? 0 : elm.days_to_installation}
                          onChange={(e) => this.onChangeInstallationNumofD(e, elm.id)}
                          disabled={is_disabled}
                          placeholder="วัน"
                        />
                      </div> */}
                      {/* <div className="col-1 text-right text-md-left px-0">วัน</div> */}
                    </div>
                  </div>
                  <div className="col-12 text-bold justify-content-center d-flex d-md-none pt-3 px-1">รับประกัน</div>
                  <div className="col-12 col-md-3 text-center px-1">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-5 col-md-5 px-1">
                        <Number innerClass="text-right" name={'warranty_value'} value={elm.warranty_value} onChange={(e) => this.props.onChangeItemManufactureDetails(e, elm.id)} disabled={is_disabled || elm.issued} />
                      </div>
                      <div className="col-5 col-md-7 px-1">
                        <Select menuPosition="fixed" value={WARRANTY_OPTIONS.filter((option) => option.value == elm.warranty_type)} onChange={(e) => this.onChangeWarrantyType(e, elm.id)} options={WARRANTY_OPTIONS} isDisabled={is_disabled} />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-bold justify-content-center d-flex d-md-none pt-3 px-1">หมดประกัน</div>
                  <div className="col-12 col-md-2 text-center px-1">
                    <div className="col-break-word">{`${elm.expiration_date ? formatDate(elm.expiration_date) : '-'}`}</div>
                  </div>
                  <div className="col-12 text-bold justify-content-center d-flex d-md-none pt-3 pb-1 px-1">สถานีพร้อมติดตั้ง</div>
                  <div className="col-12 col-md-2 text-center m-auto align-items-center px-1">
                    <ToggleSwitch checked={elm.station_ready != null} onChange={(e) => this.onChangeStationReady(e, elm.id)} disabled={is_disabled} />
                  </div>
                  {/* Station Ready to Install */}
                </div>
              </React.Fragment>
            )
          }
        }
      })
    })

    return items
  }

  onConfirmApproved() {
    const { current_approved } = this.state
    this.setState({ modal_approved_item_visible: false })
    this.props.updateInformedDate('inform_date', current_approved.key, current_approved.type)
  }

  onChangeMenufacturerTab(index) {
    this.setState({ current_manufacturer_tab: index })
  }

  displayMenufacturerTabs(menufacturer_text, menufacturer_tab_name) {
    let { current_manufacturer_tab } = this.state
    return (
      <li className="nav-item">
        <a className={`nav-link px-2 ${current_manufacturer_tab === QUO_MANUFACTURE_TABS[menufacturer_tab_name] ? 'active' : ''}`} onClick={() => this.onChangeMenufacturerTab(QUO_MANUFACTURE_TABS[menufacturer_tab_name])}>
          {menufacturer_text}
        </a>
      </li>
    )
  }

  displayVendorMenufacturerList() {
    const { current_approved } = this.state
    return (
      <div>
        <div className="card-box p-2 d-none d-md-block">{this.displayHeader('สินค้า Vendor')}</div>
        <div className="card-box px-3 mt-1">{this.displayVendorItems()}</div>
        <div className="block-info-buffer-box"></div>
        <Modal visible={this.state.modal_approved_item_visible} centered footer={false} closable={false} onCancel={() => this.onClickConfirmApproved()}>
          <div style={{ fontWeight: 'bold', fontSize: 24 }}>
            {current_approved.type ? (
              <div>
                ยืนยัน <span style={{ color: 'red' }}>ยกเลิก</span> การแจ้งผู้ผลิต
              </div>
            ) : (
              'ยืนยันการแจ้งผู้ผลิต'
            )}
          </div>
          <div style={{ fontWeight: 'bold', fontSize: 16 }}>
            ผู้ผลิต: <span style={{ color: 'blue' }}>{current_approved.vendor}</span>
          </div>
          <div style={{ fontWeight: 'bold', fontSize: 16 }}>
            หมวดหมู่: <span style={{ color: 'green' }}>{current_approved.category}</span>
          </div>
          <div style={{ fontWeight: 'bold', fontSize: 16 }}>
            แจ้งไปเมื่อ: <span>{current_approved.informed_date}</span>
          </div>
          <div
            style={{
              display: 'flex',
              gap: 10,
              marginTop: 10,
              justifyContent: 'center',
            }}
          >
            <Button
              style={{
                width: 150,
                background: 'green',
                color: 'white',
                borderRadius: 5,
              }}
              onClick={(e) => this.onConfirmApproved()}
            >
              ยืนยัน
            </Button>
            <Button
              style={{
                width: 150,
                background: 'red',
                color: 'white',
                borderRadius: 5,
              }}
              onClick={() => this.onClickConfirmApproved()}
            >
              ยกเลิก
            </Button>
          </div>
        </Modal>
      </div>
    )
  }

  displayDcMenufacturerList() {
    const { current_approved } = this.state
    return (
      <div>
        <div className="card-box p-2 d-none d-md-block">{this.displayHeader('สินค้า DC')}</div>
        <div className="card-box px-3 mt-1">{this.displayDcItems()}</div>
        <Modal visible={this.state.modal_approved_item_visible} centered footer={false} closable={false} onCancel={() => this.onClickConfirmApproved()}>
          <div style={{ fontWeight: 'bold', fontSize: 24 }}>
            {current_approved.type ? (
              <div>
                ยืนยัน <span style={{ color: 'red' }}>ยกเลิก</span> การแจ้งผู้ผลิต
              </div>
            ) : (
              'ยืนยันการแจ้งผู้ผลิต'
            )}
          </div>
          <div style={{ fontWeight: 'bold', fontSize: 16 }}>
            ผู้ผลิต: <span style={{ color: 'blue' }}>{current_approved.vendor}</span>
          </div>
          <div style={{ fontWeight: 'bold', fontSize: 16 }}>
            หมวดหมู่: <span style={{ color: 'green' }}>{current_approved.category}</span>
          </div>
          <div style={{ fontWeight: 'bold', fontSize: 16 }}>
            แจ้งไปเมื่อ: <span>{current_approved.informed_date}</span>
          </div>
          <div
            style={{
              display: 'flex',
              gap: 10,
              marginTop: 10,
              justifyContent: 'center',
            }}
          >
            <Button
              style={{
                width: 150,
                background: 'green',
                color: 'white',
                borderRadius: 5,
              }}
              onClick={(e) => this.onConfirmApproved()}
            >
              ยืนยัน
            </Button>
            <Button
              style={{
                width: 150,
                background: 'red',
                color: 'white',
                borderRadius: 5,
              }}
              onClick={() => this.onClickConfirmApproved()}
            >
              ยกเลิก
            </Button>
          </div>
        </Modal>
      </div>
    )
  }

  render() {
    const { quotation } = this.props
    // const { quotation_items } = quotation;

    return (
      <React.Fragment>
        <div className="col quo-scrollable-table">
          <div className="row p-0 p-md-4">
            <div className="col">
              <div className="d-flex flex-row mb-2">
                <ul className="nav nav-tabs tab-responsive">
                  {this.displayMenufacturerTabs(`สินค้า Vendor (${this.props.selected_vendor_item})`, 'vendor')}
                  {this.displayMenufacturerTabs(`สินค้า DC (${this.props.selected_dc_item})`, 'dc')}
                </ul>
              </div>
              {this.state.current_manufacturer_tab === QUO_MANUFACTURE_TABS.vendor ? this.displayVendorMenufacturerList() : this.displayDcMenufacturerList()}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

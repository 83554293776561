import moment from "moment";

import { FORMAT_DATE, FORMAT_EXPORT_DATE } from "../constants";

export const START_YEAR = 2021;
export const MONTH_NAMES = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const MONTH_NAMES_TH = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
export const OPTIONS_YEAR = optionsYear();
export const OPTIONS_QUARTER = optionsQuarter();
export const OPTIONS_MONTH = optionsMonth();
export const OPTIONS_MONTH_TH = optionsMonth(true);

export function formatDate(date) {
  if (date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  }

  return "";
}

export function formatThaiDate(date) {
  if (date) {
    var d = new Date(date),
      month = "" + d.getMonth(),
      day = "" + d.getDate(),
      year = d.getFullYear() + 543;

    return [day, MONTH_NAMES_TH[month], year].join(" ");
  }

  return "";
}

export function addYears(date, nb_of_years) {
  if (date) {
    var d = new Date(date),
      month = d.getMonth(),
      day = d.getDate(),
      year = d.getFullYear() + nb_of_years;
    return new Date(year, month, day);
  }
  return date;
}

export function addMonths(date_in, nb_of_months) {
  if (date_in) {
    var date = new Date(date_in);
    var d = date.getDate();
    date.setMonth(date.getMonth() + +nb_of_months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }
  return date_in;
}

export function addDays(date, nb_of_days) {
  if (date) {
    var d = new Date(date);
    return new Date(d.setDate(d.getDate() + nb_of_days));
  }
  return date;
}

export function formatApiDate(date) {
  return date ? moment(date).format(FORMAT_DATE) : null;
}

export function formatExportDate(date) {
  return date ? moment(date).format(FORMAT_EXPORT_DATE) : null;
}

export function quarterToStartMonth(quarter) {
  return quarter * 3 - 3;
}

export function firstDayOfMonth(month_offset) {
  var date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + month_offset, 1);
}

export function lastDayOfMonth() {
  var date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export function getDateFromToday(number) {
  var today = new Date();
  return new Date().setDate(today.getDate() + number);
}

function optionsYear() {
  let options = [];
  for (let year = START_YEAR; year <= new Date().getFullYear(); year++) {
    options.push({ value: year, label: String(year) });
  }
  return options;
}

function optionsQuarter() {
  let options = [...Array(4)].map((_, i) => {
    let text = `Q${i + 1} (${MONTH_NAMES[i * 3]} - ${MONTH_NAMES[i * 3 + 2]})`;
    return { value: text, label: text };
  });

  return options;
}

function optionsMonth(in_th) {
  let options = MONTH_NAMES.map((month, index) => {
    return { value: month, label: in_th ? MONTH_NAMES_TH[index] : month };
  });
  return options;
}

export const FEE_MULTIPLIER = 100;
export const LIMIT_BRUTE_FORCE_TICK = Math.pow(5, 9);

export function parseExpRatioArr(value) {
  return parseInt(value) / FEE_MULTIPLIER;
}

function find_combination2(items, avg, vary, fee, remain_sign) {
  /**
   * Find a combination begin with the default case, where fee for all SKU are equal
   * Then the changed will be added as much as 'vary' can do
   * v = 1 -> {-1 , 0 , 1}         -> {-2^0 , 0 , 2^0}
   * v = 2 -> {-2, -1 , 0 , 1, 2}  -> {-2^1, -2^0 , 0 , 2^0, 2^1}
   */
  var combi_init = [...Array(items.length)].map((_, i) => avg);
  var nearest_combi_sum = fee;
  var min_diff = Math.abs(fee);
  var nearest_combi = combi_init;
  var use_local_minimum = false; // This is disabled due to local minimum trap, when some combination need to explore more vary
  for (var v = 1; v <= vary; v++) {
    var changes = [0]; // {-1 , 0 , 1}
    var get_nearer = false;
    for (var p = 1; p <= v; p++) {
      // add the -1,1 to changable list
      changes.push(p);
      changes.push(-p);
    }

    /* Make a changable-table possbility for each SKU's unit,
     * we CANNOT directly iterate through change
     * since some of the unit may be less than zero
     */
    var item_changes = [];
    // Calulcate Maximum change we can traverse, Ex. 3^4 = {-1,0,1} for [a,b,c,d] SKU's unit
    // CANNOT use shape of the changable set since some of them may less than zero and removed
    var max_tick = 1;
    for (var i = 0; i < combi_init.length; i++) {
      item_changes[i] = [];
      for (var c = 0; c < changes.length; c++) {
        if (remain_sign == 1)
          if (combi_init[i] + changes[c] >= 0) {
            item_changes[i].push(combi_init[i] + changes[c]);
          } else if (combi_init[i] + changes[c] < 0) {
            item_changes[i].push(combi_init[i] + changes[c]);
          }
      }
      if (i % 2 == 1) {
        // Reverse set of array change when have multiple items to speedup descent
        item_changes[i].reverse();
      }
      max_tick = max_tick * item_changes[i].length;
    }

    /**
     * Fail-safe for the combination that will consumes too much time
     */
    if (max_tick > LIMIT_BRUTE_FORCE_TICK) {
      // console.log('possible combination is exceed limit: ', max_tick, ' : ', LIMIT_BRUTE_FORCE_TICK);
      // console.log('break at beginning of vary: ', vary);
      break;
    }

    /**
     * Traverse through all possible combination changes
     * If the combination * items == fee then we return the combination with remaining = 0
     * else we keep the nearest combination and return it with the remaining
     *
     *
     * inds Array : current index for SKU's unit change
     * pos  Int   : current index's (bit location) to increase, and may, reset to 0
     * pos_change Int : pos's change per each iteration has to be flipped when reach the 0 or inds.length
     */
    var inds = [...Array(items.length)].map((_, i) => 0);
    for (var tick = 0; tick < max_tick; tick++) {
      var tmp = [];
      for (var digit = 0; digit < items.length; digit++) {
        tmp.push(item_changes[digit][inds[digit]]);
      }
      var combi_sum = dot(items, tmp);
      //console.log('>>',max_tick, changes, item_changes, pos, inds,  tmp, combi_sum, fee);
      if (combi_sum == fee) {
        return [tmp, combi_sum, 0];
      } else if (combi_sum > fee) {
        if (Math.abs(combi_sum - fee) < min_diff) {
          min_diff = Math.abs(combi_sum - fee);
          nearest_combi_sum = combi_sum;
          nearest_combi = [...tmp];
          get_nearer = true;
        }
      }
      inds[0] = inds[0] + 1;
      for (var i_pos = 0; i_pos + 1 < inds.length; i_pos++) {
        if (inds[i_pos] < item_changes[i_pos].length) {
          break;
        } else {
          inds[i_pos] = 0;
          inds[i_pos + 1] = inds[i_pos + 1] + 1;
        }
      }
      // inds[pos] = inds[pos] + 1 < item_changes[pos].length ? inds[pos] + 1 : 0;
      // pos_change = (pos + pos_change < inds.length) & (pos + pos_change >= 0) ? pos_change : -pos_change;
      // pos = pos + pos_change;
    }
    if (!get_nearer & use_local_minimum) {
      /*
       * if this vary make thing worse, then break
       */
      console.log("break at", vary);
      break;
    }
  }
  return [nearest_combi, nearest_combi_sum, nearest_combi_sum - fee];
}

export function solveFeeNew(fee, items, unit_price, vary = 2, suggest_more = true) {
  /**
   * Find an added-fee-combination which will
   * be balanced amongs multiple SKUs' unit
   */

  var usum = items.reduce(function_sum); // Sum of unit from all SKU
  var cost = fee >= 0 ? Math.floor(fee / usum) : Math.ceil(fee / usum); // Initial added-fee for all SKU
  // var remain = (fee % usum);              // Remaining which has to balanced amongs SKUs' unit

  // var remain_sign =  remain < 0 ? -1 : 1
  var remain_sign = suggest_more ? 1 : -1;

  var suggest = fee;
  var add_fee = []; // An added-fee-combination

  // Assign default output
  // Added-fee is weighted by each items subtotal
  var subtotal = [];
  var grand_total = 0;
  for (var i = 0; i < items.length; i++) {
    subtotal[subtotal.length] = unit_price[i] * items[i];
    grand_total = grand_total + unit_price[i] * items[i];
  }

  // Try to calculated unit_fee for each piece of item
  for (i = 0; i < items.length; i++) {
    add_fee[add_fee.length] = Math.round((fee * (subtotal[i] / grand_total)) / items[i]);
  }
  if (fee < dot(add_fee, items)) {
    // if Round function cost more than the fee then use Floor instead
    for (i = 0; i < items.length; i++) {
      add_fee[i] = Math.floor((fee * (subtotal[i] / grand_total)) / items[i]);
    }
  }
  // Remain should be positive to let the client add more fee instead of granting discount
  var remain = fee - dot(add_fee, items);

  var second_min = Math.max(items);
  for (var i = 0; i < items.length; i++) {
    if (items[i] != 2 && items[i] != 0 && second_min < items[i] && second_min > 2) {
      second_min = items[i];
    }
  }

  if (remain == 0) {
    return [fee, remain, add_fee, suggest, items];
  }

  // try to solve remaining
  var suggest_item = [...items];
  if (items.length == 1) {
    // console.log('case 0')
    // Case 0, if only one item then adding back
    if (items[0] > 1) {
      // just for sure, since if the item's amount <= 1 , it should already return
      suggest_item.push(1);
      suggest_item[0]--;
      add_fee.push(add_fee[0] + remain);
      remain = 0;
    }
    return [fee, remain, add_fee, suggest, suggest_item];
  } else if (items.includes(remain)) {
    // console.log('case 1', remain)
    // Case 1, some SKU's unit match the remaining
    add_fee[items.indexOf(remain)] = add_fee[items.indexOf(remain)] + 1;
    remain = 0;
  } else if (items.includes(2) && remain % 2 == 0) {
    // console.log('case 2')
    // Case 2, some SKU's unit is 2 and the remaning is an even number
    add_fee[items.indexOf(2)] = add_fee[items.indexOf(2)] + Math.ceil(remain / 2);
    remain = 0;
  } else if (items.findIndex(function_can_divide, remain) != -1) {
    // console.log('case 3', remain, items, items.findIndex(function_can_divide, remain))
    // Case 3, some SKU's unit is an divider of the remaining
    var divider = items.findIndex(function_can_divide, remain);
    add_fee[divider] = add_fee[divider] + Math.ceil(remain / items[divider]);
    remain = 0;
  } else if (items.includes(2) && second_min % 2 == 0) {
    // console.log('case 4')
    // Case 4, a SKU's unit is 2(SKU-1) while another one(SKU-2) is even number
    // Thus, we can reduce the added-fee for SKU-1 equal to the SKU-2/2
    if (cost - Math.floor((second_min - remain) / 2) >= 0) {
      add_fee[items.indexOf(2)] = add_fee[items.indexOf(2)] - Math.floor((second_min - remain) / 2);
      add_fee[items.indexOf(second_min)] = add_fee[items.indexOf(second_min)] + 1;
      remain = 0;
    }
  } else if (items.includes(1)) {
    // Case 5, some SKU's unit is 1, then put the remaining there
    // console.log('case5 ')
    add_fee[items.indexOf(1)] = add_fee[items.indexOf(1)] + remain;
    remain = 0;
  }

  if (remain == 0) {
    // If above cases can solve, then return
    return [fee, remain, add_fee, suggest, suggest_item];
  }

  // Case 6, if the above cases cannot solve then we need to find
  // a more complex combination
  // console.log('BRUTE FORCING', items, cost, vary, fee, remain_sign, add_fee)
  let brute_res = find_combination(items, cost, vary, fee, remain_sign, add_fee);

  add_fee = brute_res[0];
  suggest = brute_res[1];
  remain = brute_res[2];

  if (remain !== 0) {
    for (var i = items.length - 1; i >= 0; i--) {
      if (items[i] > 1) {
        suggest_item.push(1);
        suggest_item[i]--;
        add_fee.push(add_fee[i] - 2 + remain * remain_sign);
        remain = 0;
        break;
      }
    }
  }

  return [fee, remain, add_fee, suggest, suggest_item];
}

export function solveFee(fee, items, vary = 2, suggest_more = false) {
  /**
   * Find an added-fee-combination which will
   * be balanced amongs multiple SKUs' unit
   */

  var usum = items.reduce(function_sum); // Sum of unit from all SKU
  var cost = fee >= 0 ? Math.floor(fee / usum) : Math.ceil(fee / usum); // Initial added-fee for all SKU
  var remain = fee % usum; // Remaining which has to balanced amongs SKUs' unit
  // var remain_sign =  remain < 0 ? -1 : 1
  var remain_sign = suggest_more ? 1 : -1;

  var suggest = fee;
  var add_fee = []; // An added-fee-combination

  // Assign default output
  for (var i = 0; i < items.length; i++) {
    add_fee[add_fee.length] = cost;
  }

  var second_min = Math.max(items);
  for (var i = 0; i < items.length; i++) {
    if (items[i] != 2 && items[i] != 0 && second_min < items[i] && second_min > 2) {
      second_min = items[i];
    }
  }

  if (remain == 0) {
    return [fee, remain, add_fee, suggest, items];
  }

  // try to solve remaining
  var suggest_item = [...items];
  if (items.length == 1) {
    // Case 0, if only one item then adding back
    if (items[0] > 1) {
      // just for sure, since if the item's amount <= 1 , it should already return
      suggest_item.push(1);
      suggest_item[0]--;
      add_fee.push(add_fee[0] + remain);
      remain = 0;
    }
    // console.log('case 0', fee, remain, add_fee, suggest, suggest_item)
    return [fee, remain, add_fee, suggest, suggest_item];
  } else if (items.includes(remain)) {
    // console.log('case 1', remain)
    // Case 1, some SKU's unit match the remaining
    add_fee[items.indexOf(remain)] = add_fee[items.indexOf(remain)] + 1;
    remain = 0;
  } else if (items.includes(2) && remain % 2 == 0) {
    // console.log('case 2')
    // Case 2, some SKU's unit is 2 and the remaning is an even number
    add_fee[items.indexOf(2)] = add_fee[items.indexOf(2)] + Math.ceil(remain / 2);
    remain = 0;
  } else if (items.findIndex(function_can_divide, remain) != -1) {
    // console.log('case 3', remain, items, items.findIndex(function_can_divide, remain))
    // Case 3, some SKU's unit is an divider of the remaining
    var divider = items.findIndex(function_can_divide, remain);
    add_fee[divider] = add_fee[divider] + Math.ceil(remain / items[divider]);
    remain = 0;
  } else if (items.includes(2) && second_min % 2 == 0) {
    // console.log('case 4')
    // Case 4, a SKU's unit is 2(SKU-1) while another one(SKU-2) is even number
    // Thus, we can reduce the added-fee for SKU-1 equal to the SKU-2/2
    if (cost - Math.floor((second_min - remain) / 2) >= 0) {
      add_fee[items.indexOf(2)] = add_fee[items.indexOf(2)] - Math.floor((second_min - remain) / 2);
      add_fee[items.indexOf(second_min)] = add_fee[items.indexOf(second_min)] + 1;
      remain = 0;
    }
  } else if (items.includes(1)) {
    // Case 5, some SKU's unit is 1, then put the remaining there
    // console.log('case 5')
    add_fee[items.indexOf(1)] = add_fee[items.indexOf(1)] + remain;
    remain = 0;
  }

  if (remain == 0) {
    // If above cases can solve, then return
    return [fee, remain, add_fee, suggest, suggest_item];
  }

  // Case 6, if the above cases cannot solve then we need to find
  // a more complex combination
  // console.log("BRUTE FORCING", items, cost, vary, fee, remain_sign);

  let brute_res = find_combination(items, cost, vary, fee, remain_sign);
  add_fee = brute_res[0];
  suggest = brute_res[1];
  remain = brute_res[2];

  if (remain !== 0) {
    for (var i = items.length - 1; i >= 0; i--) {
      if (items[i] > 1) {
        suggest_item.push(1);
        suggest_item[i]--;
        add_fee.push(add_fee[i] + remain * remain_sign);
        remain = 0;
        break;
      }
    }
  }
  return [fee, remain, add_fee, suggest, suggest_item];
}

function function_sum(total, num) {
  return total + num;
}

function function_can_divide(curr_val) {
  return this % curr_val == 0;
}

function dot(a, b) {
  return a.map((x, i) => a[i] * b[i]).reduce((m, n) => m + n);
}

function find_combination(items, avg, vary, fee, remain_sign, add_fee = null) {
  /**
   * Find a combination begin with the default case, where fee for all SKU are equal
   * Then the changed will be added as much as 'vary' can do
   * v = 1 -> {-1 , 0 , 1}         -> {-2^0 , 0 , 2^0}
   * v = 2 -> {-2, -1 , 0 , 1, 2}  -> {-2^1, -2^0 , 0 , 2^0, 2^1}
   */

  var combi_init = add_fee ? add_fee : [...Array(items.length)].map((_, i) => avg);
  var nearest_combi_sum = fee;
  var min_diff = Math.abs(fee);
  var nearest_combi = combi_init;
  var use_local_minimum = false; // This is disabled due to local minimum trap, when some combination need to explore more vary
  for (var v = 1; v <= vary; v++) {
    var changes = [0]; // {-1 , 0 , 1}
    var get_nearer = false;
    for (var p = 1; p <= v; p++) {
      // add the -1,1 to changable list
      changes.push(p);
      changes.push(-p);
    }

    /* Make a changable-table possbility for each SKU's unit,
     * we CANNOT directly iterate through change
     * since some of the unit may be less than zero
     */
    var item_changes = [];
    // Calulcate Maximum change we can traverse, Ex. 3^4 = {-1,0,1} for [a,b,c,d] SKU's unit
    // CANNOT use shape of the changable set since some of them may less than zero and removed
    var max_tick = 1;
    for (var i = 0; i < combi_init.length; i++) {
      item_changes[i] = [];
      for (var c = 0; c < changes.length; c++) {
        item_changes[i].push(combi_init[i] + changes[c]);
        // if(remain_sign == 1)
        //     if(combi_init[i] + changes[c] >= 0){
        //         item_changes[i].push(combi_init[i] + changes[c]);
        //     }
        // else
        //     if(combi_init[i] + changes[c] < 0){
        //         item_changes[i].push(combi_init[i] + changes[c]);
        //     }
      }
      if (i % 2 == 1) {
        // Reverse set of array change when have multiple items to speedup descent
        item_changes[i].reverse();
      }
      max_tick = max_tick * item_changes[i].length;
    }

    /**
     * Fail-safe for the combination that will consumes too much time
     */
    if (max_tick > LIMIT_BRUTE_FORCE_TICK) {
      // console.log('possible combination is exceed limit: ', max_tick, ' : ', LIMIT_BRUTE_FORCE_TICK)
      // console.log('break at beginning of vary: ', vary)
      break;
    }

    /**
     * Traverse through all possible combination changes
     * If the combination * items == fee then we return the combination with remaining = 0
     * else we keep the nearest combination and return it with the remaining
     *
     *
     * inds Array : current index for SKU's unit change
     * pos  Int   : current index's (bit location) to increase, and may, reset to 0
     * pos_change Int : pos's change per each iteration has to be flipped when reach the 0 or inds.length
     */
    var inds = [...Array(items.length)].map((_, i) => 0);
    for (var tick = 0; tick < max_tick; tick++) {
      var tmp = [];
      for (var digit = 0; digit < items.length; digit++) {
        tmp.push(item_changes[digit][inds[digit]]);
      }
      var combi_sum = dot(items, tmp);
      //console.log('>>',max_tick, changes, item_changes, inds,  tmp, combi_sum, fee, remain_sign);
      if (combi_sum == fee) {
        return [tmp, combi_sum, 0];
      } else if (remain_sign == -1 && combi_sum < fee) {
        if (Math.abs(combi_sum - fee) < min_diff) {
          min_diff = Math.abs(combi_sum - fee);
          nearest_combi_sum = combi_sum;
          nearest_combi = [...tmp];
          get_nearer = true;
        }
      } else if (remain_sign == 1 && combi_sum > fee) {
        if (Math.abs(combi_sum - fee) < min_diff) {
          min_diff = Math.abs(combi_sum - fee);
          nearest_combi_sum = combi_sum;
          nearest_combi = [...tmp];
          get_nearer = true;
        }
      }
      inds[0] = inds[0] + 1;
      for (var i_pos = 0; i_pos + 1 < inds.length; i_pos++) {
        if (inds[i_pos] < item_changes[i_pos].length) {
          break;
        } else {
          inds[i_pos] = 0;
          inds[i_pos + 1] = inds[i_pos + 1] + 1;
        }
      }
      // inds[pos] = inds[pos] + 1 < item_changes[pos].length ? inds[pos] + 1 : 0;
      // pos_change = (pos + pos_change < inds.length) & (pos + pos_change >= 0) ? pos_change : -pos_change;
      // pos = pos + pos_change;
    }
    if (!get_nearer & use_local_minimum) {
      /*
       * if this vary make thing worse, then break
       */
      // console.log('break at', vary)
      break;
    }
  }
  return [nearest_combi, nearest_combi_sum, nearest_combi_sum - fee];
}

// var items = [87, 4];
// var fee_min = 91701.12  // 100
// var fee_max = 91701.12  //-100
// var vary_max = 2
// var fee_multiplier = 100
// for (var i = fee_min; i <= fee_max; i++){
//     fee = i;
//     fee_in = fee * fee_multiplier;
//     var res = solveFee(fee_in, items, vary=vary_max, suggest_more=false)
//     // [fee, remain, add_fee, suggest]
//     res_ask = res[0]/fee_multiplier
//     res_remain = res[1]/fee_multiplier
//     res_ratio = res[2].map(x =>  x/fee_multiplier)
//     res_suggest = res[3]/fee_multiplier
//     res_items = res[4]
//     if (res_remain != 0 || res_items.length > items.length)
//         console.log(res_ask,'items:', items, 'res_items:', res_items, 'ratio:', res_ratio, 'fee:', dot(res_items, res_ratio),
//         "--> remain:",res_remain, 'suggest:', res_suggest);
//     else
//         console.log(res_ask,'items:', items,'ratio:',res_ratio, 'fee:', dot(res_items, res_ratio));
// }

// fee = 11746
// items  = [2]
// var res = solveFee(fee, items)
// // [fee, remain, add_fee, suggest]
// if (res[1] != 0)
//     console.log(res[0],'items:', items, 'ratio:', res[2], 'fee:', dot(items, res[2]),
//     "--> remain:",res[1], 'suggest:', res[3]);
// else
//     console.log(res[0],'items:', items,'ratio:',res[2], 'fee:', res[3]);

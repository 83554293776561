import React, { useState, useEffect } from "react";
import SearchBar from "../../global/SearchBar";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import Spinner from "../../global/Spinner.js";
import { APP_TITLE_DC_INVENTORIES_LOG, PER_PAGE, FORMAT_APP_DATE } from "../../../constants.js";
import { Button, Select, Tooltip } from "antd";
import { ExclamationCircleOutlined, RollbackOutlined } from "@ant-design/icons";
import _ from "lodash";
import { IconSorting } from "../../global/IconSorting";

import "../DcInventoriesManagement.css";
import { NavLink , Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";

const STATE_COLOR = {
  active: "#28A745",
  reserved: "#DC3545",
  delivered: "#0F205E",
  delete: "#000000",
};

export default function DcInventoriesManagementLog({props}) {
  const [selectedSorting, setSelectedSorting] = useState("quotation_date");
  const [selectedSortingReversed, setSelectedSortingReversed] = useState(true);
  const [collapsedID, setCollapsedID] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState(null);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(PER_PAGE);
  const [pageCount, setPageCount] = useState(1);
  const [sortingReverse, setSortingReverse] = useState(true);
  const [selectSorting, setSelectSorting] = useState("created_at");
  const [isSearch, setIsSearch] = useState(false);

  
  const [routeSearch, setRouteSearch] = useState("");
  const [routeDateFrom, setRouteDateFrom] = useState("");
  const [routeDateTo, setRouteDateTo] = useState("");
  const [routeFromStatus, setRouteFromStatus] = useState("");
  const [routeToStatus, setRouteToStatus] = useState("");

  const optionsStatus = [
    { value: "", label: "ทั้งหมด" },
    { value: "active", label: "Active" },
    { value: "reserved", label: "Reserved" },
    { value: "delivered", label: "Delivered" },
    { value: "deleted", label: "Delete" },
    { value: "inactive", label: "Inactive" },
    { value: "null", label: "-" },

  ]

 
  function getQuerySearch(data){   
    let params = (data)?`search=${data}`:(routeSearch)?`search=${routeSearch}`:''
    let date_from = (routeDateFrom)?`&date_from=${moment(routeDateFrom).format('YYYY-MM-DD')}`:''
    let date_to = (routeDateTo)?`&date_to=${moment(routeDateTo).format('YYYY-MM-DD')}`:''
    let from_status = (routeFromStatus)?`&from_status=${routeFromStatus}`:''
    let to_status = (routeToStatus)?`&to_status=${routeToStatus}`:''
    let other = `${date_from}${date_to}${from_status}${to_status}`
    console.log(params);
    props.history.replace({
      pathname: `${props.location.pathname}`,
      search: `?${params}${other}`,
    });
  }

  function clearForm(){
    setRouteSearch('')
    setRouteDateFrom('')
    setRouteDateTo('')
    setRouteFromStatus('')
    setRouteToStatus('')
    props.history.replace({
      pathname: `${props.location.pathname}`,
      search: ``,
    });
 }
 


  useEffect(() => {
    
    getLogList();
  }, [isSearch,selectSorting,sortingReverse]);

  function handlePageClick(data) {
    // if (data.selected !== 0) searchParams["page"] = data.selected + 1;
    // else delete searchParams["page"];

    setPage(data.selected);
    setIsSearch((prev) => !prev);
  }

  function getFilter(){
    let filter = {};
    let search = new URLSearchParams(props.location.search).get("search");
    let date_from = new URLSearchParams(props.location.search).get("date_from");
    let date_to = new URLSearchParams(props.location.search).get("date_to");
    let from_status = new URLSearchParams(props.location.search).get("from_status");
    let to_status = new URLSearchParams(props.location.search).get("to_status");
    if (search) {
      filter = {
        ...filter,
        query: search
      };
      setRouteSearch(search)
    }
    if (date_from||date_to) {
     if(date_from && date_to){
      date_from = (date_from)?moment(date_from).format("YYYY-MM-DD"):moment().format("YYYY-MM-DD")
      if(date_to){
        date_to = moment(date_to).format("YYYY-MM-DD");
      }
     }
      // date_to = (date_to)?moment(date_to).format("YYYY-MM-DD"):moment().format("YYYY-MM-DD")
      setRouteDateFrom((date_from)?new Date(date_from):'')
      setRouteDateTo((date_to)?new Date(date_to):'')

      if(date_from && !date_to){
        date_to = "*"
      }
      if(!date_from && date_to){
        date_from = "*"; 
        date_to = date_to
      }

      filter = {
        ...filter,
        created_at: [date_from, date_to],
      };
      
    } 
    if (from_status) {
      filter = {
        ...filter,
        from_status:(from_status == "null")?null:from_status,
      };
      setRouteFromStatus(from_status)
    }
    if (to_status) {
      filter = {
        ...filter,
        to_status:(to_status == "null")?null:to_status,
      };  
      setRouteToStatus(to_status)
  }
  return filter
}

  function getLogList() {
    setIsLoading(true);

    let range_start = page * perPage;
    let sort_dir = sortingReverse ? "DESC" : "ASC";
    let filter = getFilter(); 
      console.log([selectSorting, sort_dir])
    let options = {
      sort: JSON.stringify([selectSorting, sort_dir]),
      filter: filter ? filter : {}, 
      range: JSON.stringify([range_start,range_start + perPage - 1 ]),
      all_output: true,
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/inventory_log`, { params: options })
      .then((response) => {
        let page_count = Math.ceil(response.headers["x-total-count"] / perPage);

        if (response.status === 200) {
          let data = response.data.data;
          try {
            data = _.map(data, (r) => {
              r.remark_value = r.inbound_order_item ? getRemark(r) : "-";
              return r;
            });
          } catch (e) {
            data = response.data.data;
            console.log(e);
          }

          setPageCount(page_count);
          setLogs(data);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

   function onClickSort(type) {
    let new_toggle = selectedSorting === type ? !selectedSortingReversed : false; 
     setSelectSorting(type); 
     setSortingReverse(new_toggle);
     setSelectedSorting(type);
     setSelectedSortingReversed(new_toggle); 
   
  }

  function onClickSortDown(active, type) {
    let sortType = active ? type : ""; 
    setSelectedSorting(sortType);
    setSelectedSortingReversed(true);
  }

  function headerColWithSortIcon(text, sortText) {
    let active = selectedSorting === sortText;
    let sort_up = active && !selectedSortingReversed;
    let sort_down = active && selectedSortingReversed;

    return (
      <div className="d-flex flex-row cursor-pointer align-items-center" onClick={() => onClickSort(sortText)}>
        <div className="col-break-word text-bold">{text}</div>
        <IconSorting onClickUp={onClickSort} onClickDown={onClickSortDown} type={sortText} key={Math.random() * 100} activeUp={sort_up ? true : undefined} activeDown={sort_down ? true : undefined} />
      </div>
    );
  }

  function DisplayTableHeader() {
    return (
      <div className="card-box mt-1 p-3 d-none d-md-block mt-3">
        <div className="row">
          <div className="col-12 d-flex flex-column flex-sm-row align-items-center">
            <div className="col-custom-12 col-custom-sm-100 px-1 py-2">{headerColWithSortIcon("วันที่", "created_at")}</div>
            <div className="col-custom-14 col-custom-sm-100 px-1 py-2"> ชื่อสินค้า</div>
            <div className="col-custom-10 col-custom-sm-100 px-1 py-2 justify-content-center"> จำนวน</div>
            <div className="col-custom-14 col-custom-sm-100 px-1 py-2"> สถานะก่อนหน้า</div>
            <div className="col-custom-14 col-custom-sm-100 px-1 py-2"> สถานะที่เปลี่ยน</div>
            <div className="col-custom-12 col-custom-sm-100 px-1 py-2 justify-content-center"> หมายเหตุ</div>
            <div className="col-custom-14 col-custom-sm-100 px-1 py-2"> ผู้ใช้</div>
            <div className="col-custom-10 col-custom-sm-100 px-1 py-2"></div>
          </div>
        </div>
      </div>
    );
  }

  function onClickShowDetail(id) {
    setCollapsedID(id !== collapsedID ? id : null);
  }

  function getRemark(value) {
    let remark = "-";

    if (!value.inbound_order_item && !value.po_id) {
      remark = "Manual";
    }

    if (!value.from_status && value.to_status === "active")
    {
      try{
        remark = "Inbound Order ID - " + value.inbound_order_item.inbound_order_id;
      }catch(e){
        remark = "Manual Add";
        try{
            if(value.inventories){
              if((value.inventories).length > 0){
                let lot = value.inventories[0].inbound_order_item_lot;
                if(lot){
                  console.log(lot.inbound_order_item.inbound_order.id); 
                  remark = remark = "Inbound Order ID - " + lot.inbound_order_item.inbound_order.id
                } 
              } 
            }
        }catch(e){ 
        
        }
  
      } 
    }

    if (
      (value.from_status === "active" && value.to_status === "reserved") ||
      (value.from_status === "reserved" && value.to_status === "delivered") ||
      (value.from_status === "delivered" && value.to_status === "reserved") ||
      (value.from_status === "reserved" && value.to_status === "active")
    ) {
      remark = "Outbound Order ID - " + value.po_id;
    }

    if (value.from_status === "active" && value.to_status === "inactive") {
      remark = "Manual Delete";
    }

    return remark;
  }

  function getRemarkComponent(value){
 
    let remark_val = getRemark(value)
    if(remark_val.includes('Inbound')){
      try {
        return (
          <Link to={'/dc_inbound_orders/'+value.inbound_order_item.inbound_order_id}>
              <Tooltip placement="bottom" title={() => remark_val}>
                <ExclamationCircleOutlined />
              </Tooltip>
          </Link>
        )
      } catch (error) {
        try{
          if(value.inventories){
            if((value.inventories).length > 0){
              let lot = value.inventories[0].inbound_order_item_lot;
              if(lot){ 
                return (
                  <Link to={'/dc_inbound_orders/'+lot.inbound_order_item.inbound_order.id}>
                      <Tooltip placement="bottom" title={() => remark_val}>
                        <ExclamationCircleOutlined />
                      </Tooltip>
                  </Link>
                )
              } 
            } 
          }
      }catch(e){ 
      
      }
      }
    }
    else if(remark_val.includes('Outbound')){
      return (
        <Link to={'/orders/'+value.po_id}>
            <Tooltip placement="bottom" title={() => remark_val}>
              <ExclamationCircleOutlined />
            </Tooltip>
        </Link>
      )
    }else{
      return (
        <Tooltip placement="bottom" title={() => remark_val}>
              <ExclamationCircleOutlined />
        </Tooltip>
      )
    }
  }

  function DisplayTableItems() {
    return (
      logs &&
      logs.map((value, index) => {
        return (
          <div className="card-box mt-1 px-3 pt-3 link-no-hover" key={index}>
            <div className="row mb-2">
              {/* md */}
              <div className="col-12 flex-custom-md flex-custom-sm-none flex-column flex-sm-row cursor-pointer">
                <div className="col-custom-12 col-custom-sm-100 px-1">
                  <div className="col-break-word">{`${value.created_at ? moment(value.created_at).format("DD-MM-YYYY HH:mm:ss ") : "-"}`}</div>
                </div>
                <div className="col-custom-14 col-custom-sm-100 px-1">
                  <div className="col-break-word">{`${value.item ? value.item.name : "-"}`}</div>
                </div>
                <div className="col-custom-10 col-custom-sm-100 px-1 text-center">
                  <div className="col-break-word">{`${value.quantity ? value.quantity : "0"}`}</div>
                </div>
                <div className="col-custom-14 col-custom-sm-100 px-1">
                  <div className={`col-break-word text-bold ${value.from_status ? "color-" + value.from_status : ""}`}>{`${value.from_status ? value.from_status : "-"}`}</div>
                </div>
                <div className="col-custom-14 col-custom-sm-100 px-1">
                  <div className={`col-break-word text-bold ${value.to_status ? "color-" + value.to_status : ""}`}>{`${value.to_status ? value.to_status : "-"}`}</div>
                </div>
                <div className="col-custom-12 col-custom-sm-100 px-1 justify-content-center">
                  {getRemarkComponent(value)}
                </div>
                <div className="col-custom-14 col-custom-sm-100 px-1">
                  <div className="col-break-word">{`${value.user ? `${value.user.firstname} ${value.user.lastname} ` : "-"}`}</div>
                </div>
                <div className="col-custom-10 col-custom-sm-100 px-1 flex-row justify-content-end">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm mx-2 my-auto"
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickShowDetail(index);
                    }}
                  >
                    {collapsedID === index ? "ปิด" : "เปิด"}
                  </button>
                </div>
              </div>
              {/* sm */}
              <div className="col-12 flex-custom-sm flex-custom-md-none flex-column flex-sm-row cursor-pointer">
                <div className="col-custom-12 col-custom-sm-100 px-1">
                  <div className="col-break-word">
                    <span className="text-bold pr-2">วันที่</span>
                    <span className="text-bold pr-2">:</span>
                    {`${value.created_at ? moment(value.created_at).format("DD-MM-YYYY") : "-"}`}
                  </div>
                </div>
                <div className="col-custom-14 col-custom-sm-100 px-1">
                  <div className="col-break-word">
                    <span className="text-bold pr-2">ชื่อสินค้า</span>
                    <span className="text-bold pr-2">:</span>
                    {`${value.inbound_order_item ? value.inbound_order_item.name : "-"}`}
                  </div>
                </div>
                <div className="col-custom-10 col-custom-sm-100 px-1">
                  <div className="col-break-word">
                    <span className="text-bold pr-2">จำนวน</span>
                    <span className="text-bold pr-2">:</span>
                    {`${value.quantity ? value.quantity : "0"}`}
                  </div>
                </div>
                <div className="col-custom-14 col-custom-sm-100 px-1">
                  <div className="col-break-word">
                    <span className="text-bold pr-2">สถานะก่อนหน้า</span>
                    <span className="text-bold pr-2">:</span>
                    <span className={`col-break-word text-bold ${value.from_status ? "color-" + value.from_status : ""}`}>{`${value.from_status ? value.from_status : "-"}`}</span>
                  </div>
                </div>
                <div className="col-custom-14 col-custom-sm-100 px-1">
                  <div className="col-break-word">
                    <span className="text-bold pr-2">สถานะที่เปลี่ยน</span>
                    <span className="text-bold pr-2">:</span>
                    <span className={`col-break-word text-bold ${value.to_status ? "color-" + value.to_status : ""}`}>{`${value.to_status ? value.to_status : "-"}`}</span>
                  </div>
                </div>
                <div className="col-custom-14 col-custom-sm-100 px-1">
                  <div className="col-break-word align-items-center">
                    <span className="text-bold pr-2">หมายเหตุ</span>
                    <span className="text-bold pr-2">:</span>
                    {value.remark_value ? value.remark_value : "-"}
                  </div>
                </div>
                <div className="col-custom-10 col-custom-sm-100 px-1">
                  <div className="col-break-word">
                    <span className="text-bold pr-2">ผู้ใช้</span>
                    <span className="text-bold pr-2">:</span>
                    {`${value.user ? `${value.user.firstname} ${value.user.lastname} ` : "-"}`}
                  </div>
                </div>
                <div className="col-custom-10 col-custom-sm-100 flex-row justify-content-end mt-1">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickShowDetail(index);
                    }}
                  >
                    {collapsedID === index ? "ปิด" : "เปิด"}
                  </button>
                </div>
              </div>
            </div>
            <div className={`div-hide-scrollbar quo-data-collapse ${collapsedID === index ? "show" : ""}`}>
              <div className="col-12 d-flex flex-row" style={{ fontWeight: "bold", fontSize: 14, textAlign: "center" }}>
                <div className="d-flex-1 py-2">Inventory Item ID</div>
                <div className="d-flex-1 py-2">Latest Status</div>
                <div className="d-flex-1 py-2">ราคาต้นทุนขาเข้า</div>
                <div className="d-flex-1 py-2">DC Outbound Order ID</div>
                <div className="d-flex-1 py-2">ราคาต้นทุนเพิ่มเติม</div>
              </div>
              {value.inventories.map((item, index) => {
                return (
                  <div key={index} className="col-12 d-flex flex-sm-row" style={{ textAlign: "center" }}>
                    <div className="d-flex-1 py-2">{item.id}</div>
                    <div className="d-flex-1 py-2" style={{ color: STATE_COLOR[item.status] }}>
                      {item.status}
                    </div>
                    <div className="d-flex-1 py-2">{item.calc_unit_price}</div>
                    <div className="d-flex-1 py-2">{item.po_item ? item.po_item.purchasing_order_id : "-"}</div>
                    <div className="d-flex-1 py-2">{item.added_unit_cost ? item.added_unit_cost : "-"}</div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })
    );
  }

  return (
    <>
      <Spinner loading={isLoading} /> 
      <div className="offset-xl-1 col-xl-10 p-3 home-scrollable">
        <div className="row align-items-end">
          <div className="col-md-7 col-12 pb-2 d-flex flex-row align-items-end">
            <span className="header-title d-md-block my-0">{APP_TITLE_DC_INVENTORIES_LOG}</span>
          </div>
          <div className="col-md-5 col-12 text-right">
            <div className="row justify-content-end pb-3 pr-3">
              <NavLink to={`/dc_inventories_orders`}>
                <Button type="primary" ghost className="antd-ghost-btn">
                  <RollbackOutlined /> Back
                </Button>
              </NavLink>
            </div>
          </div>
        </div>
        <form onSubmit={(e)=>{getQuerySearch(e.target.value)}} >
        <div className="card-box light p-3">
          <SearchBar placeholder={"ชื่อสินค้า..."}  onEnter={(e)=>{getQuerySearch(e)}} onChangeQuery={(e)=>{setRouteSearch(e.target.value)}}   onClickClear={(e)=>{ clearForm()}} query={routeSearch} />
          {/* <SearchBar onEnter={this.onEnter} onChangeQuery={this.onChangeQuery} onClickClear={this.onClickClear} query={this.state.query} placeholder={"ค้นหาเลขที่ใบเสนอราคา, สถานี, ผู้ซื้อ"} /> */}
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-4 col-md-2 mt-2">
              <label>วันที่ออก</label>
            </div>
            <div className="col-8 col-md-4 mt-2 date-picker-wrapper">
              <DatePicker className="form-control" dateFormat={FORMAT_APP_DATE} onChange={(e)=>{setRouteDateFrom(e)}} selected={routeDateFrom}  />
            </div>
            <div className="col-4 col-md-2 mt-2">
              <label>ถึง</label>
            </div>
            <div className="col mt-2 date-picker-wrapper">
              <DatePicker minDate={routeDateFrom} className="form-control" dateFormat={FORMAT_APP_DATE} onChange={(e)=>{setRouteDateTo(e)}} selected={routeDateTo}   />
            </div>
          </div>
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-4 col-md-2 mt-2">
              <label style={{ margin: 0 }}>สถานะก่อนหน้า</label>
            </div>
            <div className="col-8 col-md-4 mt-2 date-picker-wrapper">
              <Select placeholder={"ค้นหา..."} style={{ width: "100%" }} options={optionsStatus} value={routeFromStatus} onChange={(e)=>{ setRouteFromStatus(e)} } />
            </div>
            <div className="col-4 col-md-2 mt-2">
              <label style={{ margin: 0 }}>สถานะที่เปลี่ยน </label>
            </div>
            <div className="col mt-2 date-picker-wrapper">
              <Select  placeholder={"ค้นหา..."} style={{ width: "100%" }} options={optionsStatus}  value={routeToStatus}  onChange={(e)=>{ setRouteToStatus(e)} }  />
            </div>
          </div>
          <div className="row d-block d-md-none">
                <div className="col-12 mt-3 text-right">
                  <button type="submit" className="btn btn-primary ml-2" >
                    Search
                  </button>
                  <button type="button" className="btn btn-danger ml-2" onClick={(e)=>{ clearForm()}}>
                    Clear
                  </button>
                </div>
              </div>
        </div>
        </form>
       

        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "right", marginTop: 15 }}> 
          <ReactPaginate
            forcePage={page}
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
            // key={this.state.resetKey}
          />
        </div>
        {DisplayTableHeader()}
        {DisplayTableItems()}
      </div>
    </>
  );
}

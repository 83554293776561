import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import axios from "axios";
import Sidebar from "react-sidebar";
import { Switch, Route, NavLink, Redirect } from "react-router-dom";
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import Amplify, { Auth, Storage } from "aws-amplify";
import awsconfig from "./aws-exports";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import { ConfirmSignIn, ConfirmSignUp, RequireNewPassword, SignIn, VerifyContact, withAuthenticator } from "aws-amplify-react";

// constant
import { APP_NAME, R_SCREEN_DOCK_SIZE, API_RESOURCES, APP_TITLE_QUO, APP_TITLE_MR, PATH_VENDORS, APP_TITLE_ADMIN, APP_TITLE_PROFILE, APP_TITLE_ORDER, APP_TITLE_VENDOR_BILLING, APP_TRACKING_PORTAL_ACCESS, APP_TITLE_DC_INBOUND_ORDER, APP_TITLE_DC_INVENTORIES_MANAGEMENT } from "./constants";

// Function
import { uniqueKey } from "./functions/Random";
import { isExpired } from "./functions/Auth";

// Views
import { Home } from "./views/Home";
import { Quotation } from "./views/Quotation";
import { Mr } from "./views/Mr";
import { Order } from "./views/Order";
import Report from "./views/Report";
import Vendor from "./views/Vendor";
import Profile from "./views/Profile";
import { MySignIn } from "./views/MySignIn";
import { Admin } from "./views/Admin";
import { Error } from "./views/Error";

// Styles
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/constants.css";
import "./styles/default.css";
import "./styles/icons.css";
import "./styles/app.css";
import "./styles/animation.css";

// models
import { REPORT_TYPES } from "./models/Reports";

// Images
import bg_01 from "./assets/images/bg_header_01.svg";
import bg_02 from "./assets/images/bg_header_02.svg";
import icon_menu from "./assets/images/icon_menu.svg";

import Spinner from "./components/global/Spinner";
import { DcInboundOrder } from "./views/DcInboundOrder";
import { DcInventoriesManagement } from "./views/DcInventoriesManagement";

Amplify.configure(awsconfig);
// Run this after the sign-in
// var session
// Auth.currentSession().then((value) => session = value)
Storage.configure({ level: "public" });

const history = createBrowserHistory();

let isAlreadyFetchingAccessToken = false;
let isAlreadyFetchingSessionRollback = false;
// Add a request interceptor
//  axios.interceptors.request.use(
//   async config => {
//     // for amplify storage
//     if (config.headers['authorization']) {
//       delete config.headers["common"]["Authorization"]
//     }
//     return config;
//   },
//   error => {
//     Promise.reject(error)
//   }
// );

//Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;
    if (typeof error.response === "undefined" || status === 401) {
      console.log("axios.interceptors.response undefined, 400");
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        // window.location.reload();

        Auth.currentSession().then((response) => {
          axios.defaults.headers.common["Authorization"] = response.idToken.jwtToken;
          return axios(originalRequest);
        });
      }
    } else if (status === 500 && !isAlreadyFetchingSessionRollback) {
      console.log("axios.interceptors.response undefined, 500");
      isAlreadyFetchingSessionRollback = true;
      axios.get(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.database}/session_rollback`).then(() => {
        console.log("session_rollback");
      });
    } else {
      console.log(`axios.interceptors.response undefined, else,${status}`);
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((session) => {
        const refreshToken = session.getRefreshToken();
        if (isExpired(session)) {
          console.log("expired");
          Auth.currentAuthenticatedUser().then((res) => {
            console.log("refreshing token");
            res.refreshSession(refreshToken, (err, data) => {
              if (err) {
                console.log("bye");
                Auth.signOut();
              } else {
                console.log("===== ok");
                config.headers.Authorization = data.getIdToken().getJwtToken();
                resolve(config);
              }
            });
          });
        } else {
          if (config.headers["authorization"]) {
            delete config.headers["common"]["Authorization"];
          } else {
            config.headers.Authorization = session.getIdToken().getJwtToken();
          }
          resolve(config);
        }
        // axios
        //   .post(`${process.env.REACT_APP_API_URL}/cross-login`)
        //   .then((response) => {
        //     console.log("response", response);
        //   });
      })
      .catch(() => {
        // No logged-in user: don't set auth header
        resolve(config);
      });
  });
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: props.authData.signInUserSession.idToken.jwtToken,
      is_using_admin: this.isUsingAdmin(window.location.pathname),
      sidebarOpen: false,
      sidebarDocked: window.innerWidth > R_SCREEN_DOCK_SIZE,
      key: uniqueKey(),
      is_geting_user: true,
      sidebar_collapse: {
        general: true,
        vendor: true,
        reports: true,
        setting: true,
      },
      current_user: {
        id: null,
        is_admin: null,
        email: props.authData.attributes.email,
      },
      is_loading: true,
    };
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    this.onSetSidebarClose = this.onSetSidebarClose.bind(this);
    this.onChangeRoute = this.onChangeRoute.bind(this);
    this.onCollapseSideBar = this.onCollapseSideBar.bind(this);
    // this.timerRefreshToken = this.timerRefreshToken.bind(this);
  }

  isUsingAdmin(pathname) {
    return pathname.split("/")[1] === "admin";
  }

  checkUser(email) {
    axios.defaults.headers.common["Authorization"] = this.state.token;
    axios
      .post(`${process.env.REACT_APP_API_URL}/users/check`, {
        email: email,
      })
      .then((response) => {
        this.setState({
          current_user: response.data,
          is_loading: false,
          key: uniqueKey(),
          is_geting_user: false,
        });

        this.updateTrackingToken();
      })
      .catch((error) => {
        console.log(error);
        this.setState({ is_loading: false, is_geting_user: false });
      });
  }

  updateTrackingToken() {
    const params = {
      username: this.state.current_user.email,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/cross-login`, params)
      .then((response) => {
        if (response.data.code === 201) {
          localStorage.setItem("token", response.data.data.token);
        } else {
          this.props.authData.signOut();
          window.location.reload(false);
        }
      })
      .catch((err) => {
        this.props.authData.signOut();
        window.location.reload(false);
      });
  }

  componentDidMount() {
    document.title = APP_NAME;
    Modal.setAppElement("body");
    if (this.state.current_user.email) {
      this.checkUser(this.state.current_user.email);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.authData.attributes.email !== this.state.current_user.email) {
      this.checkUser(prevProps.authData.attributes.email);
    }
  }

  UNSAFE_componentWillMount() {
    this.unlisten = history.listen((location, action) => {
      // console.log("on route change");
      console.log(location.pathname);
      if (this.isUsingAdmin(location.pathname)) {
        this.onSetSidebarClose();
      } else {
        this.setState({ key: uniqueKey() });
      }
    });

    // set timer
    //this.setRefreshTokenInterval()
  }

  componentWillUnmount() {
    this.unlisten();
    clearInterval(this.intervalId);
  }

  // setRefreshTokenInterval() {
  //   this.intervalId = setInterval(this.timerRefreshToken.bind(this), 1000*60*5);
  // }

  // timerRefreshToken() {
  //   Auth.currentSession()
  //       .then((response) => {
  //         let token = response.getIdToken().getJwtToken()
  //         clearInterval(this.intervalId);
  //         this.setRefreshTokenInterval()
  //         this.setState({token: token})
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         clearInterval(this.intervalId);
  //         this.setRefreshTokenInterval()
  //       });
  // }

  onSetSidebarOpen() {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  }

  onSetSidebarClose() {
    if (this.state.sidebarOpen || this.state.sidebarDocked) {
      this.setState({
        sidebarOpen: false,
        sidebarDocked: false,
        key: uniqueKey(),
      });
    }
  }

  onChangeRoute() {
    this.setState({ key: uniqueKey() });
  }

  onCollapseSideBar(type) {
    let { sidebar_collapse } = this.state;
    sidebar_collapse[type] = !sidebar_collapse[type];
    this.setState({ sidebar_collapse: sidebar_collapse });
  }

  displayHamburger() {
    if (window.innerWidth <= R_SCREEN_DOCK_SIZE) {
      return (
        <img src={icon_menu} className="icon-menu" />
        // <Hamburger onToggle={(toggled) => this.onSetSidebarOpen(toggled)} color="black" />
      );
    }
  }

  render() {
    let { sidebar_collapse } = this.state;
    let style_admin = this.state.current_user && this.state.current_user.is_admin ? "d-flex" : "d-none";

    const adminPage = () => {
      if (!this.state.is_geting_user) {
        return <Route path="/admin/:model?" render={(props) => <Admin {...props} is_admin={this.state.current_user.is_admin} key={this.state.resetAdmin} />} />;
      }
    };
    // history.location.pathname.includes(API_RESOURCES.mr)
    const sideBarMenu = (link_to, title, icon, enable) => {
      if (enable) {
        return (
          <NavLink className="sidelink d-flex flex-row" activeClassName="is-active" to={`/${link_to}`} onClick={this.onChangeRoute}>
            <div className="d-flex align-items-center">
              <i className={`${icon} invert-05`}></i>
            </div>
            <div className="pl-3 d-flex-2">{title}</div>
          </NavLink>
        );
      }
    };

    const sideBarCollapse = (name, type) => {
      return (
        <div
          className="sidelink-title"
          onClick={() => {
            this.onCollapseSideBar(type);
          }}
        >
          <div className="">{name}</div>
          <div className="invert-05">
            <i className={sidebar_collapse[type] ? "icon-arrow-up" : "icon-arrow-down"}></i>
          </div>
        </div>
      );
    };

    return (
      <React.Fragment>
        <Spinner loading={this.state.is_loading} />
        <Router history={history}>
          <Sidebar
            sidebar={
              <div className="d-flex flex-column sidebar">
                <nav className="navbar navbar-light bg-lightblue p-2">
                  <a className="navbar-brand" href="/">
                    {APP_NAME}
                  </a>
                </nav>
                <div className="mt-3"></div>
                <NavLink className="sidelink d-flex flex-row" exact={true} activeClassName="is-active" to="/">
                  <i className="icon-home invert-05"></i>
                  <div className="pl-3 d-flex-2">Dashboard</div>
                </NavLink>

                {sideBarCollapse("GENERAL", "general")}
                {sideBarMenu(API_RESOURCES.quotation, APP_TITLE_QUO, "icon-quotation", sidebar_collapse.general)}
                {sideBarMenu(API_RESOURCES.order, APP_TITLE_ORDER, "icon-order", sidebar_collapse.general)}
                {sideBarMenu(API_RESOURCES.mr, APP_TITLE_MR, "icon-mr", sidebar_collapse.general)}
                {sideBarMenu(API_RESOURCES.dc_inbound_order, APP_TITLE_DC_INBOUND_ORDER, "icon-warehouse", sidebar_collapse.general)}
                {sideBarMenu(API_RESOURCES.dc_inventories_management, APP_TITLE_DC_INVENTORIES_MANAGEMENT, "icon-inventory", sidebar_collapse.general)}

                {sideBarCollapse("VENDORS", "vendor")}
                {sideBarMenu(`${API_RESOURCES.vendor}/${PATH_VENDORS.billing}`, APP_TITLE_VENDOR_BILLING, "icon-billing", sidebar_collapse.vendor)}

                {sideBarCollapse("REPORTS", "reports")}
                {sideBarMenu(`${API_RESOURCES.report}/${REPORT_TYPES.order.path}`, REPORT_TYPES.order.name, "icon-report-tracking-order m-l-5", sidebar_collapse.reports)}
                {sideBarMenu(`${API_RESOURCES.report}/${REPORT_TYPES.client_station.path}`, REPORT_TYPES.client_station.name, "icon-contact m-l-5", sidebar_collapse.reports)}
                {sideBarMenu(`${API_RESOURCES.report}/${REPORT_TYPES.contract_budget.path}`, REPORT_TYPES.contract_budget.name, "mdi mdi-file-document-edit-outline mdi-size m-l-5", sidebar_collapse.reports)}
                {sideBarMenu(`${API_RESOURCES.report}/${REPORT_TYPES.inventory_report.path}`, REPORT_TYPES.inventory_report.name, "mdi mdi-inbox-full mdi-size m-l-5", sidebar_collapse.reports)}
                {sideBarMenu(`${API_RESOURCES.report}/${REPORT_TYPES.maintenance_report.path}`, REPORT_TYPES.maintenance_report.name, "mdi mdi-wrench mdi-size m-l-5", sidebar_collapse.reports)}

                {sideBarCollapse("SETTING", "setting")}
                {sideBarMenu(API_RESOURCES.tracking_portal, APP_TRACKING_PORTAL_ACCESS, "mdi mdi-account-sync-outline mdi-size m-l-5", sidebar_collapse.setting)}
                {sideBarMenu(API_RESOURCES.admin, APP_TITLE_ADMIN, "icon-admin", sidebar_collapse.setting)}
                {sideBarMenu(API_RESOURCES.profile, APP_TITLE_PROFILE, "icon-profile", sidebar_collapse.setting)}
                {/* version */}
                <div className="mt-4"></div>
                <div className="sidelink d-flex flex-row m-auto">
                  <AmplifySignOut />
                </div>
                <center>
                  <div style={{ fontSize: 10, color: "grey", marginTop: 10, marginBottom: 10 }}>version 4.0 (updated 07/06/23)</div>
                </center>
              </div>
            }
            open={this.state.sidebarOpen && !this.state.is_using_admin}
            docked={this.state.sidebarDocked && !this.state.is_using_admin}
            onSetOpen={this.onSetSidebarOpen}
            styles={{
              sidebar: { background: "white", zIndex: 199 },
              // content: { zIndex: 999 },
              overlay: { zIndex: 198 },
            }}
          >
            <Navbar>
              <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-auto" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="" onClick={this.onSetSidebarOpen}>
                  {this.displayHamburger()}
                </Nav>
                {/* <Form inline>
              <div className="nav-select-wrapper">
                <Select
                  styles={customStyles}
                  placeholder="Search..."
                  value={quo_options.filter(option => option.value === selected_quo)}
                  onChange={this.onClickSelectQuo}
                  options={select_options}
                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                />
              </div>
              <Nav.Link href={`/quotations/${selected_quo}`}><button type="button" className="btn btn-outline-primary">Go</button></Nav.Link>
              <Nav.Link className="p-0" href={`/quotations/new`}><button type="button" className="btn btn-outline-secondary">New</button></Nav.Link>
            </Form> */}
                {/* <Nav className="ml-auto">
                <Nav.Link href="/quotations/new">New</Nav.Link>
                <NavDropdown id="basic-nav-dropdown" className="icon-setting nav-dropdown-right">
                  <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item><AmplifySignOut /></NavDropdown.Item>
                </NavDropdown>
              </Nav> */}
              </Navbar.Collapse>
            </Navbar>
            {/* Header */}
            <img src={bg_01} className="background-header top-layer" />
            <img src={bg_02} className="background-header bottom-layer" />

            <Switch>
              <Route exact path="/" render={() => <Home onStickySetting={this.state.sidebarOpen || this.state.sidebarDocked} />} />
              <Route path={`/${API_RESOURCES.quotation}/:id?`} render={(props) => <Quotation {...props} key={this.state.key} current_user={this.state.current_user} />} />
              <Route path={`/${API_RESOURCES.mr}/:id?`} render={(props) => <Mr {...props} key={this.state.key} current_user={this.state.current_user} />} />
              <Route path={`/${API_RESOURCES.dc_inbound_order}/:id?`} render={(props) => <DcInboundOrder {...props} key={this.state.key} current_user={this.state.current_user} />} />
              <Route path={`/${API_RESOURCES.dc_inventories_management}/:id?`} render={(props) => <DcInventoriesManagement {...props} key={this.state.key} current_user={this.state.current_user} />} />
              <Route path={`/${API_RESOURCES.vendor}/:path?`} render={(props) => <Vendor {...props} key={this.state.key} current_user={this.state.current_user} />} />
              <Route path={`/${API_RESOURCES.order}/dc/:id?`} render={(props) => <Order {...props} key={this.state.key} current_user={this.state.current_user} />} />
              <Route path={`/${API_RESOURCES.order}/:id?`} render={(props) => <Order {...props} key={this.state.key} current_user={this.state.current_user} />} />
              <Route path={`/${API_RESOURCES.report}/:report?`} render={(props) => <Report {...props} key={this.state.key} current_user={this.state.current_user} />} />
              <Route path="/tracking" render={(props) => window.location.assign(`${process.env.REACT_APP_TRACKING_URL}/?t=${localStorage.getItem("token")}`)} />
              <Route path="/profile" render={(props) => <Profile {...props} key={this.state.key} current_user={this.state.current_user} />} />
              {adminPage()}
              <Route path="/error/:type" render={(props) => <Error {...props} />} />
            </Switch>
          </Sidebar>
        </Router>
      </React.Fragment>
    );
  }
}

export default withAuthenticator(App, false, [<MySignIn bg_01={bg_01} bg_02={bg_02} />, <ConfirmSignIn />, <VerifyContact />, <ConfirmSignUp />, <RequireNewPassword />]);

export const NEW_CUSTOMER = {
  id: null,
  name: '',
  ship_to_code: '',
  sold_to_code: '',
  contact_person: '',
  contact_tel: '',
  distance: 0,
  address_id: null,
  address: {
    address_line1: '',
    district_id: '',
    sub_district_id: '',
    province_id: '',
    postcode: '',
  }
}

import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { toExcelClientStationReport } from "../../../models/Reports";
// Components
import Spinner from "../../global/Spinner";
import ExportExcel from "../../global/ExportExcel";
import ClientStation from "../../global/ClientStation";
import BuyerSelect from "../../global/BuyerSelect";
import ExportProcurementModal from "./SubComponents/ExportProcurementModal";

// Functions
import { formatApiDate } from "../../../functions/Date";
import { addressToStr } from "../../../functions/String";
import { toLocaleStringCus } from "../../../functions/Number";

// Models
import { REPORT_TYPES, BUYER_TEXT } from "../../../models/Reports";

import { ORDER_STATES, FORMAT_APP_DATE } from "../../../constants";

const OPTIONS_STATES = [{ value: "pending order", label: "pending order" }];
Object.keys(ORDER_STATES).map((key) => {
  OPTIONS_STATES.push({ value: ORDER_STATES[key].name, label: ORDER_STATES[key].name });
});

function ClientStationReport(props) {
  const [selectedExport, setSelectExport] = useState([]);
  const [selectCount, setSelectCount] = useState(0);
  const [allChecked, setAllChecked] = useState(false);

  let export_selected = _.groupBy(selectedExport, function (elm) {
    return elm.quotation.buyer_id ? BUYER_TEXT + elm.quotation.buyer_id : elm.quotation.client_station.id;
  });

  let reportByBuyer = _.groupBy(props.reports, function (elm) {
    return elm.quotation.buyer_id ? BUYER_TEXT + elm.quotation.buyer_id : elm.quotation.client_station.id;
  });

  useEffect(() => {
    export_selected = _.groupBy(selectedExport, function (elm) {
      return elm.quotation.buyer_id ? BUYER_TEXT + elm.quotation.buyer_id : elm.quotation.client_station.id;
    });

    setSelectCount(Object.keys(export_selected).length);
  }, [selectedExport]);

  useEffect(() => {
    checkItemChecked();
  }, [props.reports]);

  const onSelectAllRecords = (e) => {
    let newSelectedExcel = [];
    let findDuplicate = [];
    for (const key in reportByBuyer) {
      const elm = document.getElementById(`checkboxOrder-${key}`);
      elm.checked = e.target.checked;

      if (e.target.checked) {
        setSelectExport((prev) => [...new Set([...prev, ...reportByBuyer[key]])]);
      } else {
        reportByBuyer[key].forEach((item) => {
          const duplicate = selectedExport.find((value) => value.id === item.id);
          if (duplicate) {
            findDuplicate.push(duplicate);
          }
        });
      }
    }

    if (e.target.checked) {
      setAllChecked(true);
    } else {
      selectedExport.forEach((item) => {
        const duplicate = findDuplicate.find((value) => value.id === item.id);
        if (!duplicate) {
          newSelectedExcel.push(item);
        }
      });

      checkAllItemChecked();
      setSelectExport(newSelectedExcel);
    }
  };

  const displayExportBasket = () => {
    if (selectCount > 0) {
      return (
        <div
          className="order-import-basket-wrapper"
          onClick={() => {
            props.handleDisplayingBasket(true);
          }}
        >
          <i className="icon-folder-basket-station"></i>
          <div className="count-nb">{selectCount}</div>
        </div>
      );
    }
  };

  const tableHeader = () => (
    <div className="card-box p-2 d-none d-md-block">
      {display_row_item(
        [
          props.is_exporting && (
            <div className={`${props.is_exporting ? "" : "d-none"}`}>
              <input
                name="selected_all_on_page"
                type="checkbox"
                id="selected_all_on_page"
                // defaultChecked={props.selected_all}
                checked={allChecked}
                onClick={(e) => onSelectAllRecords(e)}
              />
            </div>
          ),
          "ผู้ซื้อ",
          "สถานี",
          "เบอร์สถานี",
          "ที่อยู่สถานี",
          "จำนวนคำสั่งซื้อ",
          <div className="d-flex flex-column">
            <div>ยอดขาย(บาท)</div>
            <div>(ไม่รวม Vat 7%)</div>
          </div>,
        ],
        "p-2 mx-1",
        ["m-auto", "m-auto", "m-auto", "m-auto", "m-auto", "pr-0"]
      )}
    </div>
  );

  const display_row_item = (cols, row_classname, col_styles) => {
    return (
      <div className={`row ${row_classname}`} key={cols[5]}>
        {props.is_exporting && <div className={`col-1 ${col_styles[0]}`}>{cols[0]}</div>}
        <div className={`col-2 ${col_styles[1]}`}>{cols[1]}</div>
        <div className={`col-2 p-0 ${col_styles[2]}`}>{cols[2]}</div>
        <div className={`col-2 text-break ${col_styles[3]}`}>{cols[3]}</div>
        <div className={`col-2 p-0 ${col_styles[4]}`}>{cols[4]}</div>
        <div className={`col text-center ${col_styles[5]}`}>{cols[5]}</div>
        <div className={`col pl-0 text-right ${col_styles[6]}`}>{cols[6]}</div>
      </div>
    );
  };

  const handleOnCheckSelectExport = (e, key, report) => {
    const report_lenght = Object.keys(reportByBuyer).length;
    let selected = [];

    const elm_select_all = document.getElementById("selected_all_on_page");
    if (selectCount === report_lenght) {
      elm_select_all.checked = true;
    } else {
      elm_select_all.checked = false;
    }

    if (e.target.checked) {
      setSelectCount((prev) => prev + 1);
      setSelectExport((prev) => [...new Set([...prev, ...reportByBuyer[key]])]);
    } else {
      selectedExport.forEach((value) => {
        const duplicate = reportByBuyer[key].find((report) => report.id === value.id);
        if (!duplicate) {
          selected.push(value);
        }
        setSelectExport(selected);
      });
      setSelectCount((prev) => prev - 1);
    }

    checkAllItemChecked();
  };

  const handleClearExport = () => {
    setSelectExport([]);
    setSelectCount(Object.keys(export_selected).length);
    setAllChecked(false);
    for (const key in reportByBuyer) {
      const elm = document.getElementById(`checkboxOrder-${key}`);
      elm.checked = false;
    }
  };

  const deleteSelectItem = (key) => {
    const elm = document.getElementById(`checkboxOrder-${key}`);
    const selected = _.groupBy(selectedExport, function (elm) {
      return elm.quotation.buyer_id ? BUYER_TEXT + elm.quotation.buyer_id : elm.quotation.client_station.id;
    });

    const newSelected = [];

    selectedExport.forEach((value) => {
      const duplicate = selected[key].find((item) => item.id === value.id);
      if (!duplicate) {
        newSelected.push(value);
      }
    });

    checkAllItemChecked();
    setSelectExport(newSelected);
    if (elm) {
      elm.checked = false;
    }
    setSelectCount((prev) => prev - 1);
  };

  const handleCancel = () => {
    handleClearExport();
    props.onClickExporting();
  };

  const checkAllItemChecked = () => {
    let count = 0;
    Object.keys(reportByBuyer).forEach((key) => {
      const elm = document.getElementById(`checkboxOrder-${key}`);
      if (elm && elm.checked) count = count + 1;
    });
    setAllChecked(Object.keys(reportByBuyer).length === count);
  };

  const checkItemChecked = () => {
    Object.keys(reportByBuyer).forEach((key) => {
      const elm = document.getElementById(`checkboxOrder-${key}`);
      if (elm) elm.checked = false;
    });

    Object.keys(export_selected).forEach((key) => {
      const elm = document.getElementById(`checkboxOrder-${key}`);
      if (elm) elm.checked = true;
    });
  };

  const reportItems = () => {
    let rows = Object.keys(reportByBuyer)
      .reverse()
      .map((key, buyer_index) => {
        let reportByClient = _.groupBy(reportByBuyer[key], function (elm) {
          return elm.quotation.client_station.id;
        });
        let all_count = 0;
        let all_sales = 0;
        return (
          <div className="card-box py-1 mt-2">
            {Object.keys(reportByClient).map((client_id_key, client_index) => {
              let first_row = reportByClient[client_id_key][0];
              let buyer_name = first_row.quotation.buyer ? first_row.quotation.buyer.name : "";
              let client_station = first_row.quotation.client_station;
              let total_sales = reportByClient[client_id_key].reduce((pre, cur) => (pre += cur.total_sales), 0);
              all_count += reportByClient[client_id_key].length;
              all_sales += total_sales;
              return display_row_item(
                [
                  client_index === 0 ? (
                    <div className={`ml-2 ${props.is_exporting ? "" : "d-none"}`}>
                      <input name={`checkboxOrder-${key}`} type="checkbox" id={`checkboxOrder-${key}`} onChange={(e) => handleOnCheckSelectExport(e, key, reportByClient[client_id_key])} />
                    </div>
                  ) : (
                    ""
                  ),
                  client_index === 0 ? buyer_name : "",
                  client_station.name,
                  client_station.contact_tel,
                  addressToStr(client_station.address),
                  reportByClient[client_id_key].length,
                  toLocaleStringCus(total_sales),
                ],
                "p-2 mx-1",
                []
              );
            })}
            {/* summary row */}
            {display_row_item(["", "", "", "รวม", all_count, toLocaleStringCus(all_sales)], "p-2 mx-1 bg-aliceblue", ["", "", "", "text-right font-weight-bold", "font-weight-bold", "font-weight-bold"])}
          </div>
        );
      });
    return rows;
  };
  return (
    <React.Fragment>
      <Spinner loading={props.is_refiltering || props.is_loading} />
      <div className="col p-3 home-scrollable">
        <div className="row">
          <div className="col-8">
            <h3>{REPORT_TYPES.client_station.name}</h3>
          </div>
        </div>

        <div className="card-box light p-3">
          <form onSubmit={props.handleSubmit}>
            <div className="row">
              <div className="col-4 col-md-2 mt-2">
                <label>วันที่สร้างออร์เดอร์</label>
              </div>
              <div className="col-8 col-md-3 mt-2 date-picker-wrapper">
                <DatePicker className="form-control" selected={props.selected_created_date_form} onChange={(date) => props.onChangeSubmittedFromDate(date)} dateFormat={FORMAT_APP_DATE} />
              </div>
              <div className="col-4 col-md-1 mt-2">
                <label>ถึง</label>
              </div>
              <div className="col-8 col-md-3 mt-2 date-picker-wrapper">
                <DatePicker className="form-control" selected={props.selected_created_date_to} onChange={(date) => props.onChangeSubmittedToDate(date)} dateFormat={FORMAT_APP_DATE} />
              </div>
              <div className="d-none d-md-block col-md-2 mt-2">
                <div className="d-flex flex-row">
                  <button type="submit" className="btn btn-primary ml-2">
                    Search
                  </button>
                  <button type="button" className="btn btn-danger ml-2" onClick={props.onClickClear}>
                    clear
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4 col-md-2 mt-2">
                <label>สถานี</label>
              </div>
              <div className="col-8 col-md-3 mt-2 date-picker-wrapper">
                <ClientStation onChange={props.onClickSelectStation} clientStation={props.selected_station} key={props.selected_station.id} />
              </div>
            </div>

            <div className="row">
              <div className="col-4 col-md-2 mt-2">
                <label>ผู้ซื้อ</label>
              </div>
              <div className="col-8 col-md-3 mt-2 date-picker-wrapper">
                <BuyerSelect onChange={props.onClickSelectBuyer} buyer={props.selected_buyer} key={props.selected_buyer.id} />
              </div>
            </div>

            {/* Visible xs, sm  */}
            <div className="row d-block d-md-none">
              <div className="col-12 mt-3 text-right">
                <button type="submit" className="btn btn-primary ml-2">
                  Search
                </button>
                <button type="button" className="btn btn-danger ml-2" onClick={props.onClickClear}>
                    clear
                  </button>
              </div>
            </div>
          </form>
        </div>
        <div className="row">
          <div className="d-none d-md-block col-12 mt-2">
            <div className="d-flex flex-row-reverse">
              {props.is_exporting ? (
                <div style={{ display: "flex", gap: 10 }}>
                  <ExportExcel
                    data={[toExcelClientStationReport(selectedExport)]}
                    content={`Export (${selectCount})`}
                    filename={`รายชื่อผู้ซื้อ_สถานี_${formatApiDate(props.selected_created_date_form)}_to_${formatApiDate(props.selected_created_date_to)}`}
                    customClass={"btn btn-success ml-2"}
                    disabled={selectedExport.length === 0}
                  />
                  {/* <button type="button" className={"btn btn-success"} onClick={handleExportd}>
                    Export
                  </button> */}
                  <button type="button" className={"btn btn-danger"} onClick={handleClearExport}>
                    ล้าง
                  </button>
                  <button type="button" className={"btn btn-secondary"} onClick={handleCancel}>
                    ยกเลิก
                  </button>
                </div>
              ) : (
                <button type="button" className={"btn btn-primary"} onClick={props.onClickExporting}>
                  ออกรายงานสถานี
                </button>
              )}
              {/* */}
            </div>
          </div>
        </div>
        <div className="font-size-14px pt-2">
          {tableHeader()}
          {reportItems()}
        </div>
        {displayExportBasket()}
        <ExportProcurementModal {...props} handleClearExport={handleClearExport} deleteSelectItem={deleteSelectItem} selectedExport={selectedExport} display_row_item={display_row_item} select_export={toExcelClientStationReport(selectedExport)} />
        <div className="block-info-buffer-box"></div>
      </div>
    </React.Fragment>
  );
}
export default withRouter(ClientStationReport);

import React from 'react';
import Modal from 'react-modal';

import { R_SCREEN_WIDTH } from '../../constants'
import Number from '../global/Number'
import Decimal from '../global/Decimal'
import { Multiple } from '../../functions/Number'
import { isDiscount, isCategoryDiscount } from '../../models/Quotations'
import { getExpenseTotalCost, getExpenseUnit, getExpenseName } from '../../models/ItemCategories'

export class OrderFormPurchasePriceItemsFee extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal_preview: false,
      selected_elm: null
    }
    this.handleEditModal = this.handleEditModal.bind(this)
    this.handleOpenModalPreview = this.handleOpenModalPreview.bind(this)
    this.handleCloseModalPreview = this.handleCloseModalPreview.bind(this)
    this.onAddFee = this.onAddFee.bind(this)
  }

  handleEditModal(id) {
    this.setState({ modal_preview: true, selected_elm: id })
  }

  handleOpenModalPreview() {
    this.setState({ modal_preview: true })
  }

  handleCloseModalPreview() {
    this.setState({ modal_preview: false })
  }

  onAddFee(id, index, item_category_id) {
    this.handleCloseModalPreview()
    this.props.onAddFee(id, index, item_category_id)
  }

  shipBtn(elm, index) {
    if (!this.props.disabled) {
      if (isCategoryDiscount(elm)) { return <div style={{ "width": "46px" }}></div> }
      if (elm.is_new) {
        return (
          <button type="button" className="btn btn-success btn-sm ml-2" onClick={() => this.props.onAddFee(elm.id, index + 1, elm.item_category_id)}>+</button>
        )
      } else {
        return (
          <button type="button" className="btn btn-danger btn-sm ml-2" onClick={() => this.props.onDeleteExpense(elm)}>X</button>
        )
      }
    }
  }

  inputField(elm, field, isNumber) {
    if (isNumber === true) {
      return (
        <Number
          innerClass="text-right"
          name={field}
          value={elm[field]}
          onChange={(e) => this.props.onChangeFeeDetails(e, elm.id, elm.item_category_id)}
          disabled={this.props.disabled}
        />
      )
    } else {
      return (
        <input className="form-control text-right" name={field} value={elm[field]}
          onChange={(e) => this.props.onChangeFeeDetails(e, elm.id, elm.item_category_id)} disabled={this.props.disabled}></input>
      )
    }
  }

  decimalField(elm, field) {
    return (
      <Decimal
        innerClass="text-right"
        name={field}
        value={elm[field]}
        onChange={(e) => this.props.onChangeFeeDetails(e, elm.id, elm.item_category_id)}
        disabled={this.props.disabled}
      />
    )
  }

  addModal(elm, index) {
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        overflow: 'hidden',
        transform: 'translate(-50%, -50%)',
        zIndex: 999,
      }
    };
    const deleteBtn = (elm) => {
      if (!isCategoryDiscount(elm)) {
        return (
          <button type="button" className={`btn btn-danger btn-sm w-100 ml-1 ${elm.is_new ? "d-none" : ""}`}
            onClick={() => this.props.onDeleteExpense(elm)} disabled={this.props.disabled}>ลบ</button>
        )
      } else {
        return false
      }
    }
    return (
      <Modal
        isOpen={this.state.modal_preview && (this.state.selected_elm === elm.id)}
        contentLabel="onAddSpeacialFee"
        onRequestClose={this.handleCloseModalPreview}
        style={customStyles}
      >
        <div className="d-flex flex-column" style={{ zIndex: 999, position: 'relative' }}>
          <h5>เพิ่มค่าบริการ</h5>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1"><label>บริการ</label></div>
            <div className="d-flex-2">{(elm.is_new) ? this.inputField(elm, "name") : elm.name}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1"><label>จำนวน</label></div>
            <div className="d-flex-2">{this.inputField(elm, "qty", true)}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1"><label>หน่วย</label></div>
            <div className="d-flex-2">{(elm.is_new) ? this.inputField(elm, "unit") : elm.unit}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1"><label>ราคา</label></div>
            <div className="d-flex-2">
              {this.decimalField(elm, "contract_price")}
            </div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1"><label>รวม</label></div>
            <div className="d-flex-2">{(Multiple(elm.contract_price, elm.qty)).toFixed(2)}</div>
          </div>
          {/* <div className="d-flex flex-row mt-2">
            <div className="d-flex-1"><label>comment</label></div>
            <div className="d-flex-2">{this.inputField(elm, "note")}</div>
          </div> */}
          <div className="d-flex flex-row mt-2">
            <button type="button" className="btn btn-success btn-sm w-100"
              onClick={() => (elm.is_new) ? this.onAddFee(elm.id, index + 1, elm.item_category_id) : this.handleCloseModalPreview()}
              disabled={this.props.disabled}>{(elm.is_new) ? "เพิ่ม" : "บันทึก"}</button>
            {deleteBtn(elm)}
            <button type="button" className="btn btn-secondary btn-sm w-100 ml-1"
              onClick={this.handleCloseModalPreview}>ยกเลิก</button>
          </div>
        </div>
      </Modal>
    )
  }


  displayShippingCost(costByCate) {
    let items = costByCate.sort((a, b) => a.position > b.position ? 1 : -1).map((elm, index) => {
      let { disabled } = this.props

      const find_tyoe_7 = costByCate.find((cate) => cate.method_type === 7 && cate.qty > cate.from_value)

      if (find_tyoe_7 && elm.method_type === 1 || find_tyoe_7 && elm.method_type === 2 || find_tyoe_7 && elm.method_type === 5 || !find_tyoe_7 && elm.method_type === 7) return false

      // dont display if there is no fee
      if ((elm.qty == 0 || elm.item_qty == 0) && !elm.is_custom_qty) { return false }
      let total = (getExpenseTotalCost(elm, true)).toLocaleString()
      let row_style = isDiscount(elm) ? 'row-discount' : ''
      if (!elm.is_new || isCategoryDiscount(elm)) {
        return (
          <React.Fragment key={elm.id}>
            <a onClick={() => (window.innerWidth <= R_SCREEN_WIDTH.md) && !disabled ? this.handleEditModal(elm.id) : false}>
              <div className={`row mt-2 border ${row_style}`} style={{ padding: "5px 0", backgroundColor: "white" }}>
                <div className="table-item-bg first col-p5 d-none d-md-flex"></div>
                <div className="table-item-bg col-12 col-md-6 pr-0">
                  {getExpenseName(elm)}
                </div>
                <div className="table-item-bg col-1 pl-0 justify-content-start d-none d-md-flex">
                  {this.inputField(elm, "qty", true)}
                </div>
                <div className="table-item-bg col-1 justify-content-center d-none d-md-flex">
                  {getExpenseUnit(elm)}
                </div>
                <div className="table-item-bg col p-0 d-none d-md-flex">{this.decimalField(elm, "contract_price")}</div>
                <div className="table-item-bg col-12 col-md d-none d-md-flex">
                  <div className="w-100 ml-2 text-right">{total}</div>
                  {this.shipBtn(elm, index + 1)}
                </div>
                <div className="table-item-bg col-12 col-md d-flex justify-content-end bg-ghostwhite d-md-none">
                  <h4 className="sign-baht color-darkgrayblue">{total}</h4>
                </div>
                {/* <div className="table-item-bg col-2 justify-content-between d-none d-md-flex">
                  {this.inputField(elm, "note")}
                  {this.shipBtn(elm, index+1)}
                </div> */}
              </div>
            </a>
            {this.addModal(elm, index)}
          </React.Fragment>
        )
      } else {
        if (!this.props.disabled) {
          return (
            <React.Fragment>
              {/* <a onClick={() => this.handleEditModal(elm.id)}>
                <div className="row mt-1">
                  <div className="table-item-bg col-12 d-flex justify-content-between bg-antiquewhite cursor-pointer">
                    + เพิ่มค่าบริการ
                  </div>
                </div>
              </a> */}
              <div className="row mt-1 text-right">
                <button type="button" className="btn btn-success btn-lg btn-sm d-none d-md-flex"
                  onClick={() => this.handleEditModal(elm.id)} disabled={this.props.disabled}>+ เพิ่มค่าบริการ</button>
                <button type="button" className="btn btn-success btn-lg btn-sm w-100 d-md-none"
                  onClick={() => this.handleEditModal(elm.id)} disabled={this.props.disabled}>+ เพิ่มค่าบริการ</button>
              </div>
              {this.addModal(elm, index)}
            </React.Fragment>
          )
        }
      }
    })
    return items
  }

  render() {
    let { costByCate } = this.props
    // let decorated_costs = []
    // costByCate.map((elm) => {
    //   if(isDiscount(elm)) {
    //     let existing_cost = decorated_costs.filter((c) => c.name === elm.name)
    //     if(existing_cost.length !== 0) {
    //       existing_cost[0].item_qty = parseInt(existing_cost[0].item_qty) + parseInt(elm.item_qty)
    //     } else {
    //       decorated_costs.push(elm)
    //     }
    //   } else {
    //     decorated_costs.push(elm)
    //   }
    // })
    return (
      <React.Fragment>
        {this.displayShippingCost(costByCate)}
      </React.Fragment>
    );
  }
}

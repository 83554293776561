import React from "react";
import ExportModal from "./ExportModal";

export default function ProcurementBtn(props) {
  if (!props.is_exporting) return false;

  return (
    <div className="row my-2 my-md-0">
      {/* <div className="col-6">
        {this_page_all_selected ? 
        <button type="button" className="btn btn-outline-secondary" onClick={() => props.onSelectAllRecords(false)}><u>ไม่เลือก</u>ทั้งหมดในหน้านี้</button> :
        <button type="button" className="btn btn-outline-primary" onClick={() => props.onSelectAllRecords(true)}>เลือกทั้งหมดในหน้านี้</button>}
      </div> */}
      <div className="col mt-2 text-right">
        <ExportModal {...props} btnClass={"btn btn-primary btn-sm ml-2"} />
        <button type="button" className="btn btn-danger btn-sm ml-2" onClick={props.onClearSelectedOrders}>
          ล้าง
        </button>
        <button type="button" className="btn btn-secondary btn-sm ml-2" onClick={props.onCloseProcurement}>
          ยกเลิก
        </button>
      </div>
    </div>
  );
}


export function validate_find_by_cate(params) {
  let errors = []

  if (!params.item_category_id) {
    errors.push("โปรดระบุ หมวดหมู่สินค้า")
  }

  if (params.id) {
    return errors
  }

  if (!params.approved_date) {
    errors.push("โปรดระบุ วันที่อนุมัติ (วันที่ดึงราคาสัญญา)")
  }
  if (!params.province_id) {
    errors.push("โปรดระบุ จังหวัด ในข้อมูลสถานี")
  }

  return errors
}
import React from 'react';

// Functions
import { ORDER_PROGRESS_FLAGS } from '../../../models/Orders'

export default function OrderProgressFilters(props) {
  const { flag_filters } = props

  return (
    <div className="row">
      <div className="col-4 col-md-2 mt-2"><label>ขั้นตอน</label></div>
      <div className="col-8 col-md-10 mt-2">
        <div className="row">
          {Object.keys(flag_filters).map((key, index) => {
            return (
              <React.Fragment key={index+1}>
                {/* <div className="col-12 col-md-3 mt-2">
                  <div className="form-check d-flex flex-row">
                    <input className="form-check-input" type="checkbox" value="" id={key} onClick={onChangeFlag}></input>
                    <label className="form-check-label" for={key}>
                      {ORDER_PROGRESS_FLAGS[key]}
                    </label>
                  </div>
                </div> */}
                <div className="col-12 col-md-4 mt-2 d-flex flex-row">
                  <div className="btn-group" role="group" aria-label={`Basic example ${index}`}>
                    <button type="button" className={`btn btn-sm ${(flag_filters[key] === true) ? 'btn-success' : 'btn-secondary' }`} onClick={() => props.onClickBtnGroup(key, true)}><span>&#10003;</span></button>
                    <button type="button" className={`btn btn-sm ${(flag_filters[key] === false) ? 'btn-danger' : 'btn-secondary' }`} onClick={() => props.onClickBtnGroup(key, false)}>X</button>
                  </div>
                  <div className="ml-2">{ORDER_PROGRESS_FLAGS[key]}</div>
                </div>
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </div>
  );
}
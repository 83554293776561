import React from "react";
import OrderIndex from "../components/order/OrderIndex";
import OrderForm from "../components/order/OrderForm";
import OrderFormDc from "../components/order/OrderFormDc/OrderFormDc";

import "../styles/quotation.css";
import "../styles/order.css";

export class Order extends React.Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(props.location.search);
    const selected_state = urlParams.get("state");
    const selected_date_from = urlParams.get("date_from");
    const selected_date_to = urlParams.get("date_to");
    let selected_id = props.match.params.id;
    this.state = {
      current_page: selected_id === "new" ? null : selected_id,
      state: selected_state || "",
      link_date_from: selected_date_from || "",
      link_date_to: selected_date_to || "",
    };

    this.onChangeOrderPage = this.onChangeOrderPage.bind(this);
  }

  onChangeOrderPage(page) {
    this.setState({ current_page: page });
  }

  render() {
    // console.log("props.match :", this.props.match);
    if (this.state.current_page === undefined) {
      return (
        <OrderIndex
          state={this.state.state}
          link_date_from={this.state.link_date_from}
          link_date_to={this.state.link_date_to}
          onChangeOrderPage={this.onChangeOrderPage}
        />
      );
    } else {
      if (this.props.match.path === "/orders/dc/:id?") {
        return (
          <OrderFormDc
            {...this.props}
            id={this.state.current_page}
            onChangeOrderPage={this.onChangeOrderPage}
            current_user={this.props.current_user}
          />
        );
      } else {
        return (
          <OrderForm
            {...this.props}
            id={this.state.current_page}
            onChangeOrderPage={this.onChangeOrderPage}
            current_user={this.props.current_user}
          />
        );
      }
    }
  }
}

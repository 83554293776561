// in contracts.js
import * as React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  DeleteWithConfirmButton,
  Confirm,
  TextField,
  EditButton,
  TextInput,
  DateInput,
  required,
  useListContext,
  SelectInput,
  ReferenceInput,
  DateField,
  ReferenceField,
  useRedirect,
  useRefresh,
  TabbedForm,
  FormTab,
  AutocompleteInput,
  BooleanInput,
  useNotify,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  FormDataConsumer,
  FunctionField,
  Link,
  Button,
  useUpdate,
  useRecordContext,
} from "react-admin";

import { withStyles } from "@material-ui/core/styles";
import BookIcon from "@material-ui/icons/Book";
import { Form } from "react-final-form";
import { Box, Button as CoreButton, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ContentFilter from "@material-ui/icons/FilterList";
import { getExpenseDetails } from "../../models/ItemCategories";

import { exporter } from "../../functions/reactAdmin";

import { EditToolbar, EditActions } from "./Default";
import axios from "axios";
import { Modal } from "antd";

export const ContractIcon = BookIcon;

const OPTIONS_RESPONSIBLE_PERSON_TYPES = [
  { id: "am_structure_charts", name: "โครงสร้าง AM" },
  { id: "eng_structure_charts", name: "โครงสร้าง วฟ." },
  { id: "maintenance_structure_charts", name: "โครงสร้าง ซฟ." },
];

const ContractFilter = (props) => {
  return props.context === "button" ? <ContractFilterButton {...props} /> : <ContractFilterForm {...props} />;
};

const ContractFilterButton = () => {
  const { showFilter } = useListContext();
  return (
    <CoreButton size="small" color="primary" onClick={() => showFilter("main")} startIcon={<ContentFilter />}>
      Filter
    </CoreButton>
  );
};

const ContractFilterForm = ({ open }) => {
  const { displayedFilters, filterValues, setFilters, hideFilter } = useListContext();

  if (!displayedFilters.main) return null;

  const onSubmit = (values) => {
    if (Object.keys(values).length > 0) {
      setFilters(values);
    } else {
      hideFilter("main");
    }
  };

  const resetFilter = () => {
    setFilters({}, []);
  };

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box mt={8} />
            <Box display="flex" alignItems="flex-end" mb={1}>
              <Box component="span" mr={3}>
                <BooleanInput source="is_active" label="เปิดใช้งาน" alwaysOn />
              </Box>
              <Box component="span" mr={2}>
                {/* Full-text search filter. We don't use <SearchFilter> to force a large form input */}
                <TextInput
                  resettable
                  helperText={false}
                  source="code"
                  label="Search เลขที่สัญญา"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box component="span" mr={2}>
                {/* Full-text search filter. We don't use <SearchFilter> to force a large form input */}
                <TextInput
                  resettable
                  helperText={false}
                  source="name"
                  label="Search ชื่อ"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box component="span" mr={2} mb={1.5}>
                <CoreButton variant="outlined" color="primary" type="submit">
                  Filter
                </CoreButton>
              </Box>
              <Box component="span" mb={1.5}>
                <CoreButton variant="outlined" onClick={resetFilter}>
                  Close
                </CoreButton>
              </Box>
            </Box>
          </form>
        )}
      </Form>
    </div>
  );
};

function CloneButton() {
  const record = useRecordContext();
  const redirect = useRedirect();

  const [open, setOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  function handleConfirm() {
    setOpen(true)

  }

  function handleConfirmClone() {
    setIsLoading(true)

    axios
      .post(`${process.env.REACT_APP_API_URL}/contract/${record.id}/clone`)
      .then((response) => {
        setOpen(false)
        setIsLoading(false)
        redirect(`/contracts/${response?.data?.id}`)
      })
      .catch((err) => {
        setIsLoading(false)
        setOpen(false)
      });
  }

  return <div>
    <Confirm
      isOpen={open}
      loading={isLoading}
      title={`สัญญาหมายเลข #${record && record.id}`}
      content={isLoading ? `กำลังโคลนสัญญาหมายเลข #${record && record.id} กรุณารอสักครู่...` : `คุณแน่ใจหรือไม่ที่ต้องการโคลนสัญญาหมายเลข #${record && record.id}?`}
      onConfirm={handleConfirmClone}
      onClose={() => {
        if (!isLoading) {
          setOpen(false)
          setIsLoading(false)
        }
      }}
    />
    <Button onClick={handleConfirm} label="Clone" /></div>;
}

export const ContractList = (props) => {
  return (
    <List {...props} filters={<ContractFilter />} exporter={exporter}>

      <Datagrid>
        <TextField source="id" />
        <TextField source="is_active" label="เปิดใช้งาน" />
        <TextField source="code" label="เลขที่สัญญา" />
        <TextField source="name" />
        <ReferenceField label="ผู้ผลิต" source="vendor_id" reference="vendors" sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <DateField source="from_date" label="เริ่ม" locales="fr-FR" />
        <DateField source="to_date" label="สิ้นสุด" locales="fr-FR" />
        <EditButton basePath="/contracts" />
        <DeleteWithConfirmButton undoable={false} />
        <CloneButton />


      </Datagrid>
    </List>
  );
};

const ContractTitle = ({ record }) => {
  return <span>Contract {record ? `"${record.name}"` : ""}</span>;
};

const styles = {
  button: {
    marginTop: "1em",
    marginBottom: "1em",
    color: "#fff",
    backgroundColor: "#3f51b5",
  },
};

const AddNewContractCategoryButton = withStyles(styles)(({ classes, record }) => (
  <Button
    className={classes.button}
    variant="raised"
    component={Link}
    to={{
      pathname: "/contract_item_categories/create",
      search: `?contract_id=${record.id}&for_mr=${record.for_mr}`,
    }}
    label="Add an item category"
  ></Button>
));

const ResetContractItemCategoryFeeButton = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [approve, { loading }] = useUpdate("contract_item_categories", record.id, { reset_contract_cate_fee: true }, record, {
    onSuccess: ({ data }) => {
      refresh();
    },
    onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
  });
  return (
    <Box mb="1em">
      <Button label="Refresh" className="color-red" onClick={approve} disabled={loading} />
    </Box>
  );
};

const ResetContractItemButton = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [approve, { loading }] = useUpdate("contract_item_categories", record.id, { reset_contract_item: true }, record, {
    onSuccess: ({ data }) => {
      refresh();
    },
    onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
  });
  return (
    <Box mb="1em">
      <Button label="Refresh" className="color-red" onClick={approve} disabled={loading} />
    </Box>
  );
};

export const ContractEdit = (props) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  return (
    <Edit undoable={false} actions={<EditActions />} title={<ContractTitle />} {...props}>
      <TabbedForm toolbar={<EditToolbar />}>
        <FormTab label="ข้อมูลสัญญา">
          <TextInput disabled source="id" />
          <BooleanInput label="เปิดใช้งาน" source="is_active" />
          <BooleanInput label="ใช้สัญญานี้เป็นค่าตั้งต้น" source="is_default" />
          <BooleanInput label="สำหรับใบสั่งจ้างผลิต" source="for_mr" />
          <div className="color-red mb-2">เปิดเมื่อสัญญาใช้สำหรับใบสั่งจ้างผลิตเท่านั้น เช่น สัญญาแจ้งเข้างาน Inspection</div>
          <TextInput source="code" label="เลขที่สัญญา" validate={required()} />
          <TextInput source="name" label="ชื่อ" validate={required()} />
          <TextInput multiline source="detail" />
          <NumberInput source="nb_days_to_complete" label="ระยะเวลาส่งมอบงาน" min={0} validate={required()} />
          <NumberInput source="days_to_ship" label="ระยะเวลาจัดส่ง/ติดตั้ง" min={0} validate={required()} />
          <NumberInput source="contract_budget" label="วงเงินสัญญา" min={0} validate={required()} />
          <DateInput source="from_date" validate={required()} />
          <DateInput source="to_date" validate={required()} />
          <ReferenceInput label="ผู้ผลิต" source="vendor_id" reference="vendors" filterToQuery={(searchText) => ({ name: searchText })} validate={required()}>
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput label="Zone" source="zone_id" reference="zones" filterToQuery={(searchText) => ({ name: searchText })} validate={required()}>
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          <DateField source="created_at" showTime locales="fr-FR" />
          <DateField source="updated_at" showTime locales="fr-FR" />
        </FormTab>

        <FormTab label="หมวดหมู่">
          <AddNewContractCategoryButton />
          <ArrayInput source="contract_item_categories" label="หมวดสินค้าในสัญญา">
            <SimpleFormIterator disableAdd TransitionProps={{ enter: false }}>
              <ReferenceInput label="หมวดหมู่" source="item_category_id" reference="item_categories" filterToQuery={(searchText) => ({ sys_name: searchText })} fullWidth>
                <AutocompleteInput optionText="sys_name" />
              </ReferenceInput>
              <ResetContractItemCategoryFeeButton />
              <FormDataConsumer>
                {({ getSource, scopedFormData }) => {
                  if (scopedFormData && scopedFormData.contract_item_category_fees && scopedFormData.contract_item_category_fees.length !== 0) {
                    return (
                      <ArrayInput source={getSource("contract_item_category_fees")} label="ค่าบริการ/ค่าขนส่ง/อื่นๆ">
                        <SimpleFormIterator disableAdd disableRemove TransitionProps={{ enter: false }}>
                          <FormDataConsumer>
                            {({ getSource, scopedFormData }) => {
                              return (
                                <React.Fragment>
                                  <TextInput source={getSource("name")} label="ชื่อ" disabled fullWidth />
                                  <Box display="flex">
                                    <Box mr="0.5em" mt="1em">
                                      <BooleanInput source={getSource("is_active")} label="เปิดใช้งาน" />
                                    </Box>
                                    <Box mr="0.5em">
                                      <NumberInput source={getSource("price")} label="ราคา" step={0.01} min={0} validate={required()} />
                                    </Box>
                                    <Box mr="0.5em" mt="1.5em">
                                      <FunctionField label="Price" render={(record) => getExpenseDetails(scopedFormData)} />
                                    </Box>
                                  </Box>
                                </React.Fragment>
                              );
                            }}
                          </FormDataConsumer>
                        </SimpleFormIterator>
                      </ArrayInput>
                    );
                  }
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
          {/* <ReferenceManyField
            addLabel={false}
            reference="contract_item_categories"
            target="contract_id"
          >
            <Datagrid>
              <TextField source="item_category.ori_name" label="ชื่อ"/>
              <TextField source="item_category.sys_name" label="ชื่อที่ใช้ในระบบ"/>
              <EditButton />
              <DeleteWithConfirmButton
                {...props}
                onSuccess={(response) => {
                  redirect(`/contracts/${response.data.contract_id}/1`);
                  refresh();
                }}
              />
            </Datagrid>
          </ReferenceManyField> */}
          {/* <AddNewFeeButton /> */}
        </FormTab>

        <FormTab label="สินค้า">
          <ArrayInput source="contract_item_categories" label="หมวดสินค้าในสัญญา">
            <SimpleFormIterator disableAdd TransitionProps={{ enter: false }}>
              <ReferenceInput label="หมวดหมู่" source="item_category_id" reference="item_categories" filterToQuery={(searchText) => ({ sys_name: searchText })} fullWidth disabled>
                <AutocompleteInput optionText="sys_name" />
              </ReferenceInput>
              <ResetContractItemButton />
              <FormDataConsumer>
                {({ getSource, scopedFormData }) => {
                  if (scopedFormData && scopedFormData.contract_items && scopedFormData.contract_items.length !== 0) {
                    return (
                      <ArrayInput source={getSource("contract_items")} label="สินค้า">
                        <SimpleFormIterator disableAdd disableRemove TransitionProps={{ enter: false }}>
                          <FormDataConsumer>
                            {({ getSource, scopedFormData }) => {
                              return (
                                <React.Fragment>
                                  <TextInput source={getSource("name")} label="ชื่อ" disabled fullWidth />
                                  <Box display="flex">
                                    <Box mr="0.5em" mt="1em">
                                      <BooleanInput source={getSource("is_active")} label="เปิดใช้งาน" />
                                    </Box>
                                    <Box mr="0.5em">
                                      <NumberInput source={getSource("price")} label="ราคา" step={0.01} min={0} validate={required()} />
                                    </Box>
                                    <Box mr="0.5em">
                                      <NumberInput source={getSource("line_number")} label="บรรทัดในสัญญา" min={1} />
                                    </Box>
                                  </Box>
                                  {scopedFormData && scopedFormData.contract_item_fees && scopedFormData.contract_item_fees.length !== 0 ? (
                                    <ArrayInput source={getSource("contract_item_fees")} label="ค่าบริการ/ค่าขนส่ง/อื่นๆ">
                                      <SimpleFormIterator disableAdd disableRemove TransitionProps={{ enter: false }}>
                                        <FormDataConsumer>
                                          {({ getSource, scopedFormData }) => {
                                            return (
                                              <React.Fragment>
                                                <TextInput source={getSource("name")} label="สินค้า" disabled fullWidth />
                                                <Box display="flex">
                                                  <Box mr="0.5em" mt="1em">
                                                    <BooleanInput source={getSource("is_active")} label="เปิดใช้งาน" />
                                                  </Box>
                                                  <Box mr="0.5em">
                                                    <NumberInput source={getSource("price")} label="ราคา" step={0.01} min={0} validate={required()} />
                                                  </Box>
                                                  <Box mr="0.5em" mt="1.5em">
                                                    <FunctionField label="Price" render={(record) => getExpenseDetails(scopedFormData)} />
                                                  </Box>
                                                </Box>
                                              </React.Fragment>
                                            );
                                          }}
                                        </FormDataConsumer>
                                      </SimpleFormIterator>
                                    </ArrayInput>
                                  ) : (
                                    ""
                                  )}

                                  {scopedFormData && scopedFormData.contract_item_discounts && scopedFormData.contract_item_discounts.length !== 0 ? (
                                    <ArrayInput source={getSource("contract_item_discounts")} label="ส่วนลด">
                                      <SimpleFormIterator disableAdd disableRemove TransitionProps={{ enter: false }}>
                                        <FormDataConsumer>
                                          {({ getSource, scopedFormData }) => {
                                            return (
                                              <React.Fragment>
                                                <TextInput source={getSource("name")} label="สินค้า" disabled fullWidth />
                                                <Box display="flex">
                                                  <Box mr="0.5em" mt="1em">
                                                    <BooleanInput source={getSource("is_active")} label="เปิดใช้งาน" />
                                                  </Box>
                                                  <Box mr="0.5em">
                                                    <NumberInput source={getSource("price")} label="ราคา" step={0.01} min={0} validate={required()} />
                                                  </Box>
                                                  <Box mr="0.5em" mt="1.5em">
                                                    <FunctionField label="Price" render={(record) => getExpenseDetails(scopedFormData)} />
                                                  </Box>
                                                </Box>
                                              </React.Fragment>
                                            );
                                          }}
                                        </FormDataConsumer>
                                      </SimpleFormIterator>
                                    </ArrayInput>
                                  ) : (
                                    ""
                                  )}
                                </React.Fragment>
                              );
                            }}
                          </FormDataConsumer>
                        </SimpleFormIterator>
                      </ArrayInput>
                    );
                  }
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="กรรมการตรวจรับ">
          <ArrayInput source="contract_responsible_persons" label="รายชื่อกรรมการตรวจรับ">
            <SimpleFormIterator TransitionProps={{ enter: false }}>
              <NumberInput source="position" label="ลำดับ" />
              <TextInput source="name" label="ชื่อ" />
              <TextInput source="code" label="รหัสพนักงาน" />
              <TextInput source="title" label="ตำแหน่ง" />
              <SelectInput source="resource_type" label="ดึงข้อมูลมาจาก" choices={OPTIONS_RESPONSIBLE_PERSON_TYPES} resettable />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const ContractCreate = (props) => (
  <Create title="Create a Contract" actions={<EditActions />} {...props}>
    <SimpleForm>
      <BooleanInput label="เปิดใช้งาน" source="is_active" defaultValue={true} />
      <BooleanInput label="ใช้สัญญานี้เป็นค่าตั้งต้น" source="is_default" />
      <BooleanInput label="สำหรับใบสั่งจ้างผลิต" source="for_mr" />
      <div className="color-red mb-2">เปิดเมื่อสัญญาใช้สำหรับใบสั่งจ้างผลิตเท่านั้น เช่น สัญญาแจ้งเข้างาน Inspection</div>
      <TextInput source="code" label="เลขที่สัญญา" validate={required()} />
      <TextInput source="name" label="ชื่อ" validate={required()} />
      <TextInput multiline source="detail" />
      <NumberInput source="nb_days_to_complete" label="ระยะเวลาส่งมอบงาน" min={0} validate={required()} />
      <NumberInput source="days_to_ship" label="ระยะเวลาจัดส่ง/ติดตั้ง" min={0} validate={required()} />
      <NumberInput source="contract_budget" label="วงเงินสัญญา" min={0} validate={required()} />
      <DateInput source="from_date" validate={required()} />
      <DateInput source="to_date" validate={required()} />
      <ReferenceInput label="ผู้ผลิต" source="vendor_id" reference="vendors" filterToQuery={(searchText) => ({ name: searchText })} validate={required()}>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Zone" source="zone_id" reference="zones" filterToQuery={(searchText) => ({ name: searchText })} validate={required()}>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

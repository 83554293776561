import moment from "moment";
import { parseData } from "../../../functions/Excel";
import { addressToStr } from "../../../functions/String";
import _ from "lodash";

export function toExcelOrderRepairReport(reports) {
  let data = [];

  // Styles
  const header_font = { sz: "11", bold: true };
  const border_color = { style: "thin", color: { rgb: "FF000000" } };
  const border_color_medium = { style: "medium", color: { rgb: "FF000000" } };
  const border_style = { top: border_color, bottom: border_color, left: border_color, right: border_color };
  const border_style_bm = { bottom: border_color_medium };
  const style_row_buyer = { fill: { fgColor: { rgb: "95b3d7" } }, font: header_font, alignment: { horizontal: "center" } };
  const style_row_buyer_bb = {
    ...style_row_buyer,
    border: border_style_bm,
  };

  const style_row_body_center = { border: border_style, font: { sz: "10" }, alignment: { vertical: "top" }, alignment: { horizontal: "center" } };
  //   const style_row_body = { border: border_style, font: { sz: "10" }, alignment: { vertical: "top" } };
  //   const width_col_xs = { wch: 10 };
  //   const width_col_sm = { wch: 15 };
  const width_col_md = { wch: 25 };
  const width_col_lg = { wch: 50 };
  const width_col_xl = { wch: 70 };
  const width_col_xxl = { wch: 80 };

  const header = [
    { title: "ชื่อสินค้า", width: width_col_lg, style: style_row_buyer_bb },
    { title: "ชื่อสถานี", width: width_col_lg, style: style_row_buyer_bb },
    { title: "จังหวัด", width: width_col_md, style: style_row_buyer_bb },
    { title: "วันที่แจ้ง", width: width_col_md, style: style_row_buyer_bb },
    { title: "สาเหตุ", width: width_col_xxl, style: style_row_buyer_bb },
    { title: "วันที่แก้ไขเสร็จ", width: width_col_md, style: style_row_buyer_bb },
    { title: "วิธีแก้ไข", width: width_col_xxl, style: style_row_buyer_bb },
    { title: "หมายเหตุ", width: width_col_xxl, style: style_row_buyer_bb },
  ];

  reports.forEach((report) => {
    data.push([
      parseData(report.order_item.name ?? "-", style_row_body_center, "-", style_row_body_center),
      parseData(report.order_repair.quotation.client_station.name ?? "-", style_row_body_center, "-", style_row_body_center),
      parseData(report.order_repair.quotation.client_station.address.province.name ?? "-", style_row_body_center, "-", style_row_body_center),
      parseData(moment(report.informed_date).format("DD-MM-YYYY") ?? "-", style_row_body_center, "-", style_row_body_center),
      parseData(report.comment_cause ?? "-", style_row_body_center, "-", style_row_body_center),
      parseData(moment(report.repaired_date).format("DD-MM-YYYY") ?? "-", style_row_body_center, "-", style_row_body_center),
      parseData(report.comment_how_to_fix ?? "-", style_row_body_center, "-", style_row_body_center),
      parseData(report.comment ?? "-", style_row_body_center, "-", style_row_body_center),
    ]);
  });

  return { sheet_name: "รายงานแจ้งซ่อมและสินค้าเพื่อการขาย", dataSet: [{ columns: header, data: data }] };
}

import React from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { R_SCREEN_DOCK_SIZE_SM, MODAL_STYLE_SMALL, MODAL_STYLE_SMALL_SM } from '../../constants'

export class ModalIssueOrder extends React.Component {
  selectAllVendorItemBtn() {
    let { onSelectAllVendorItem, onSelectedAllVendorItemDisabled } = this.props
    if (onSelectAllVendorItem !== undefined) {
      return (
        <button type="button" className="btn btn-success btn-sm w-100" onClick={onSelectAllVendorItem} disabled={onSelectedAllVendorItemDisabled} style={{ minWidth: '120px' }}>
          {'เลือกทั้งหมด'}
        </button>
      )
    }
  }

  selectAllDcItemBtn() {
    let { onSelectAllDcItem, onSelectedAllDcItemDisabled } = this.props
    if (onSelectAllDcItem !== undefined) {
      return (
        <button type="button" className="btn btn-success btn-sm w-100" onClick={onSelectAllDcItem} disabled={onSelectedAllDcItemDisabled} style={{ minWidth: '120px' }}>
          {'เลือกทั้งหมด'}
        </button>
      )
    }
  }

  selectedPartialVendorItemBtn() {
    let { onSelecPartialVendorItem, onSelectedAnyVendorItem } = this.props
    if (onSelecPartialVendorItem !== undefined) {
      return (
        <button type="button" className="btn btn-primary btn-sm w-100" onClick={onSelecPartialVendorItem} disabled={onSelectedAnyVendorItem} style={{ minWidth: '120px' }}>
          {'เลือกบางส่วน'}
        </button>
      )
    }
  }

  selectedPartialDcItemBtn() {
    let { onSelecPartialDcItem, onSelectedAnyDCItem } = this.props
    if (onSelecPartialDcItem !== undefined) {
      return (
        <button type="button" className="btn btn-primary btn-sm w-100" onClick={onSelecPartialDcItem} disabled={onSelectedAnyDCItem} style={{ minWidth: '120px' }}>
          {'เลือกบางส่วน'}
        </button>
      )
    }
  }

  render() {
    let { title, content, isOpen, btnSecondary, isHavedDCProduct, isHavedVendorProduct } = this.props

    return (
      <React.Fragment>
        <Modal
          isOpen={isOpen}
          contentLabel="ModelConfrmation"
          style={window.innerWidth > R_SCREEN_DOCK_SIZE_SM ? MODAL_STYLE_SMALL : MODAL_STYLE_SMALL_SM}
          onRequestClose={() => this.props.onCancel()}
          // Warning: react-modal: App element is not defined
          ariaHideApp={false}
        >
          <div className="d-flex flex-column">
            <h5>{title}</h5>
            <div className="line mb-2"></div>
            <div className="mt-2 mb-3">{content}</div>

            {isHavedVendorProduct && this.props.onAllVendorIssued === false ? (
              <div className="p-6">
                <div className="card-margin  px-5 pb-3" style={{ background: '#F6F2F2', borderRadius: '5px' }}>
                  <div className="col-12 text-center p-3 font-weight-bold">สินค้า Vendor</div>
                  <div className="card-flex">
                    <div className="card-width p-2">{this.selectAllVendorItemBtn()}</div>
                    <div className="card-width p-2">{this.selectedPartialVendorItemBtn()}</div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {isHavedDCProduct && this.props.onAllDcIssued === false ? (
              <div className="p-6">
                <div className="card-margin px-5 pb-3" style={{ background: '#F6F2F2', borderRadius: '5px' }}>
                  <div className="col-12 text-center p-3 font-weight-bold">สินค้า DC</div>
                  <div className="card-flex">
                    <div className="card-width p-2">{this.selectAllDcItemBtn()}</div>
                    <div className="card-width p-2">{this.selectedPartialDcItemBtn()}</div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="px-5 py-2" style={{ marginTop: '20px' }}>
              <div className="d-flex flex-row justify-content-center">
                <button type="button" className="btn btn-danger btn-sm" onClick={() => this.props.onCancel()} style={{ width: '120px' }}>
                  {btnSecondary || 'ยกเลิก'}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}
export default ModalIssueOrder

ModalIssueOrder.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  btnSecondary: PropTypes.string,
  onSelectAllVendorItem: PropTypes.func.isRequired,
  onSelectAllDcItem: PropTypes.func.isRequired,
  onSelecPartialVendorItem: PropTypes.func.isRequired,
  onSelecPartialDcItem: PropTypes.func.isRequired,
  onSelectedAllVendorItemDisabled: PropTypes.bool,
  onSelectedAllDcItemDisabled: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
}

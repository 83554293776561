import React from "react";
import Modal from "react-modal";
import Select from "react-select";
import _ from "lodash";

import { MODAL_STYLE_SMALL_SM, QUO_STATES, FORMAT_APP_DATE } from "../../constants";
import { QuotationPurchasePricesFee } from "./QuotationPurchasePricesFee";

import { itemCategoryName } from "../../models/ItemCategories";
import { toExcelCompareTable } from "../../models/Quotations";
import { calTotalDiscount, getCategoryTotalExpense, calTotalSales } from "../../models/Quotations";
import { getWarrantyCode } from "../../models/Orders";
import Number from "../global/Number";
import ModalConfirmation from "../global/ModalConfirmation";
import CustomDatePicker from "../global/CustomDatePicker";
import ContractByCateSelect from "../global/ContractByCateSelect";

import { Multiple } from "../../functions/Number";
import { formatApiDate } from "../../functions/Date";
import { stringIsEmpty } from "../../functions/String";
import ExportExcel from "../global/ExportExcel";

import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const QUO_PURCHASE_PRICE_TABS = {
  vendor: 0,
  dc: 1,
};
export class QuotationPurchasePrices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current_purchase_price_tab: QUO_PURCHASE_PRICE_TABS.vendor,
      selected_item: "",
      selected_item_category: "",
      edited_item: null,
      custom_item: "",
      custom_quantity: "",
      quantity: "",
      comment: "",
      isConfirmingAdjustment: false,
      approved_date: props.quotation.approved_date,
      is_completed: props.quotation.state === QUO_STATES.order_issued_completed.name,
      /* approved_date: props.quotation.approved_date ? new Date(props.quotation.approved_date) : new Date(), */
    };

    this.handleOpenEditedModal = this.handleOpenEditedModal.bind(this);
    this.handleCloseEditedModal = this.handleCloseEditedModal.bind(this);
    this.onSelectItem = this.onSelectItem.bind(this);
    this.onSelectItemCategory = this.onSelectItemCategory.bind(this);
    this.onChangeComment = this.onChangeComment.bind(this);
    this.onChangeCustomItem = this.onChangeCustomItem.bind(this);
    this.onChangeCustomQuantity = this.onChangeCustomQuantity.bind(this);
    this.onChangeQuantity = this.onChangeQuantity.bind(this);

    // Confirm shipping adjustment
    this.onResetContractPrice = this.onResetContractPrice.bind(this);
    this.onHandleFeeAdjustModal = this.onHandleFeeAdjustModal.bind(this);

    // contract
    this.onChangeContractDate = this.onChangeContractDate.bind(this);
  }

  handleOpenEditedModal(id) {
    this.setState({ edited_item: id });
  }

  handleCloseEditedModal() {
    this.setState({ edited_item: null });
  }

  onSelectItem(e) {
    this.setState({ selected_item: e.id });
  }

  onSelectItemCategory(e) {
    this.setState({ selected_item_category: e.id });
  }

  onChangeComment(e) {
    this.setState({ comment: e.target.value });
  }

  onChangeCustomItem(e) {
    this.setState({ custom_item: e.target.value });
  }

  onChangeCustomQuantity(e) {
    this.setState({ custom_quantity: e.target.value });
  }

  onChangeQuantity(e) {
    this.setState({ quantity: e.target.value });
  }

  /* Confirm shipping adjustment (start) */
  onHandleFeeAdjustModal(cate_id) {
    this.setState({ isConfirmingAdjustment: cate_id });
  }

  onChangeContractDate(date) {
    this.setState({ approved_date: date });
    this.props.onChangeQuoContractDate(date);
  }

  onResetContractPrice() {
    this.props.onResetContractPrice([this.state.isConfirmingAdjustment]);
    this.onHandleFeeAdjustModal(false);
  }

  displayFeeAdjustmentModal() {
    let { isConfirmingAdjustment } = this.state;
    let { quotation_item_categories } = this.props.quotation;
    if (isConfirmingAdjustment !== false) {
      return (
        <ModalConfirmation
          isOpen={true}
          title="ปรับค่าราคาขาซื้อขาขายเป็นค่าเริ่มต้น"
          content={
            <p>
              ต้องการปรับค่าราคาขาซื้อขาขายของ{" "}
              <u>
                <strong>{itemCategoryName(quotation_item_categories[isConfirmingAdjustment])}</strong>
              </u>{" "}
              เป็นค่าเริ่มต้น ?
            </p>
          }
          onConfirm={this.onResetContractPrice}
          onCancel={() => this.onHandleFeeAdjustModal(false)}
        />
      );
    }
  }
  /* Confirm shipping adjustment (end) */

  inputField(elm, field, isNumber) {
    if (isNumber === true) {
      return <Number innerClass="text-right" name={field} value={elm[field]} onChange={(e) => this.props.onChangeItemDetails(e, elm.id)} disabled={this.props.disabled} />;
    } else {
      return <input className="form-control text-right" name={field} value={elm[field]} onChange={(e) => this.props.onChangeItemDetails(e, elm.id)} disabled={this.props.disabled}></input>;
    }
  }

  editModal(elm) {
    let { edited_item } = this.state;
    return (
      <Modal isOpen={elm.id === edited_item} contentLabel="onAddItem" onRequestClose={this.handleCloseEditedModal} style={MODAL_STYLE_SMALL_SM}>
        <div className="d-flex flex-column">
          <h5>แก้ไขรายการสินค้า</h5>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>รายการ</label>
            </div>
            <div className="d-flex-2">{elm.name}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>จำนวน</label>
            </div>
            <div className="d-flex-2">{this.inputField(elm, "qty", true)}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>หน่วย</label>
            </div>
            <div className="d-flex-2">{elm.unit}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>ราคา</label>
            </div>
            <div className="d-flex-2">
              {this.inputField(elm, "contract_price", true)}
              {/* {elm.contract_price.toLocaleString()} */}
            </div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>รวม</label>
            </div>
            <div className="d-flex-2">{(elm.contract_price * elm.qty).toLocaleString()}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <button type="button" className="btn btn-success btn-sm w-100" onClick={this.handleCloseEditedModal} disabled={this.props.disabled}>
              บันทึก
            </button>
            <button type="button" className="btn btn-danger btn-sm w-100 ml-1" onClick={() => this.props.onDeleteItem(elm)} disabled={this.props.disabled}>
              ลบ
            </button>
            <button type="button" className="btn btn-secondary btn-sm w-100 ml-1" onClick={this.handleCloseEditedModal}>
              ยกเลิก
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  displayVendorItems(itemsByCate, index_offset) {
    let { is_completed } = this.state;
    let item_index = -1;
    let items = itemsByCate.map((elm, index) => {
      let net_cost = Multiple(elm.contract_price, elm.qty);
      let code_name = elm.code + " - " + elm.name;
      let item_canceled = is_completed && !elm.issued;
      let no_contract_item = elm.no_contract;
      let parent_contract_price = 0;

      // if (elm.is_parent && elm?.parent_items?.length === 0) return false

      let find_parent_items = itemsByCate.filter((item) => item.from_parent_item_id === elm.item_id);

      if (elm.is_parent)
        find_parent_items?.forEach((sub_item) => {
          parent_contract_price = Multiple(sub_item.contract_price, sub_item.qty) + parent_contract_price;
        });

      if (!elm.from_parent) item_index = item_index + 1;

      return (
        <React.Fragment key={elm.id}>
          {/* md up */}
          {elm.is_parent ? (
            <div>
              <div style={{ position: "relative", zIndex: 0, padding: "5px 0px", backgroundColor: "white" }} className={`row mt-2 border d-none d-md-flex ${item_canceled ? "text-line-through" : ""} ${no_contract_item ? "color-red" : ""}`}>
                <div className="table-item-bg first col-1 justify-content-center">{index_offset + item_index}</div>
                <div className="table-item-bg col-5 pt-1 px-0">{code_name}</div>
                <div style={{ paddingRight: 25 }} className="table-item-bg col-2 pl-0 justify-content-end">{`${elm.qty} ${elm.unit}`}</div>
                <div style={{ right: 25 }} className="table-item-bg col-2 pl-0 justify-content-end d-relative">
                  {/* {this.inputField(elm, "contract_price", true)} */}
                  {/* {parent_contract_price || 0} */}
                </div>
                <div style={{ paddingRight: 35 }} className="table-item-bg col-2 pl-0 justify-content-end">
                  {Intl.NumberFormat().format(parent_contract_price)}
                </div>
              </div>
              <div style={{ padding: "0px 20px" }}>
                <div style={{ marginTop: "-1px", backgroundColor: "white", position: "relative", zIndex: 0, padding: "5px 0px" }} className={`row border d-none d-md-flex ${item_canceled ? "text-line-through" : ""}`}>
                  {find_parent_items?.map((item, i_index) => {
                    let net_cost_parent = Multiple(item.contract_price, item.qty);
                    let no_contract_item_parent = item.no_contract;
                    return (
                      <div style={{ width: "100%", marginLeft: 0, padding: "5px 0px" }} className={`row d-none d-md-flex ${item_canceled ? "text-line-through" : ""} ${no_contract_item_parent ? "color-red" : ""}`}>
                        <div className="table-item-bg first col-1 justify-content-center">
                          {index_offset + item_index}.{i_index + 1}
                        </div>
                        <div className="table-item-bg col-5 pt-1 px-0">{item.name}</div>
                        <div className="table-item-bg col-2 pl-0 justify-content-end">{`${item.qty} ${item.unit}`}</div>
                        <div className="table-item-bg col-2 pl-0 justify-content-end d-relative">
                          {this.inputField(item, "contract_price", true)}
                          {item.contract_price === 0 ? (
                            <OverlayTrigger
                              key={index}
                              placement={"bottom"}
                              overlay={
                                <Tooltip id={`tooltip-${index}`}>
                                  <div>คำเตือน</div>
                                  {item.contract_price === 0 && <div>- ราคาขาซื้อเป็น 0</div>}
                                  {!item.is_active && <div>- สินค้านี้ไม่มีในสัญญาที่เลือก หรือสัญญาถูกปิดใช้งาน</div>}
                                </Tooltip>
                              }
                            >
                              <div
                                style={{
                                  zIndex: 1,
                                  right: "-32px",
                                  position: "absolute",
                                  fontSize: 16,
                                  fontWeight: "bold",
                                  color: "red",
                                  border: "2px solid red",
                                  borderRadius: "50%",
                                  width: 25,
                                  height: 25,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                              >
                                !
                              </div>
                            </OverlayTrigger>
                          ) : (
                            !elm.is_active && (
                              <OverlayTrigger
                                key={index}
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-${index}`}>
                                    <div>คำเตือน</div>
                                    <div>- สินค้านี้ไม่มีในสัญญาที่เลือก หรือสัญญาถูกปิดใช้งาน</div>
                                  </Tooltip>
                                }
                              >
                                <div
                                  style={{
                                    zIndex: 1,
                                    right: "-32px",
                                    position: "absolute",
                                    fontSize: 16,
                                    fontWeight: "bold",
                                    color: "red",
                                    border: "2px solid red",
                                    borderRadius: "50%",
                                    width: 25,
                                    height: 25,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  !
                                </div>
                              </OverlayTrigger>
                            )
                          )}
                        </div>
                        <div className="table-item-bg col-2 pl-0 justify-content-end">{net_cost_parent.toLocaleString()}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            !elm.from_parent && (
              <div className={`row mt-2 border d-none d-md-flex ${item_canceled ? "text-line-through" : ""} ${no_contract_item ? "color-red" : ""}`}>
                <div className="table-item-bg first col-1 justify-content-center">{index_offset + index}</div>
                <div className="table-item-bg col-5 pt-1 px-0">{code_name}</div>
                <div className="table-item-bg col-2 pl-0 justify-content-end">{`${elm.qty} ${elm.unit}`}</div>
                <div className="table-item-bg col-2 pl-0 justify-content-end d-relative">
                  {this.inputField(elm, "contract_price", true)}
                  {elm.contract_price === 0 ? (
                    <OverlayTrigger
                      key={index}
                      placement={"bottom"}
                      overlay={
                        <Tooltip id={`tooltip-${index}`}>
                          <div>คำเตือน</div>
                          {elm.contract_price === 0 && <div>- ราคาขาซื้อเป็น 0</div>}
                          {!elm.is_active && <div>- สินค้านี้ไม่มีในสัญญาที่เลือก หรือสัญญาถูกปิดใช้งาน</div>}
                        </Tooltip>
                      }
                    >
                      <div
                        style={{
                          zIndex: 1,
                          right: "-32px",
                          position: "absolute",
                          fontSize: 16,
                          fontWeight: "bold",
                          color: "red",
                          border: "2px solid red",
                          borderRadius: "50%",
                          width: 25,
                          height: 25,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        !
                      </div>
                    </OverlayTrigger>
                  ) : (
                    !elm.is_active && (
                      <OverlayTrigger
                        key={index}
                        placement={"bottom"}
                        overlay={
                          <Tooltip id={`tooltip-${index}`}>
                            <div>คำเตือน</div>
                            <div>- สินค้านี้ไม่มีในสัญญาที่เลือก หรือสัญญาถูกปิดใช้งาน</div>
                          </Tooltip>
                        }
                      >
                        <div
                          style={{
                            zIndex: 1,
                            right: "-32px",
                            position: "absolute",
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "red",
                            border: "2px solid red",
                            borderRadius: "50%",
                            width: 25,
                            height: 25,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          !
                        </div>
                      </OverlayTrigger>
                    )
                  )}
                </div>
                <div className="table-item-bg col-2 pl-0 justify-content-end">{net_cost.toLocaleString()}</div>
              </div>
            )
          )}
          {/* md down */}
          <a a onClick={() => (this.props.disabled ? false : this.handleOpenEditedModal(elm.id))}>
            <div className={`row mt-2 border d-md-none ${item_canceled ? "text-line-through" : ""}`}>
              <div className="table-item-bg col-12 pt-1 col-md-4 pr-3">{code_name}</div>
              <div className="table-item-bg col col-md-1 text-center d-md-none">
                จำนวน {elm.qty} {elm.unit}
              </div>
              <div className="table-item-bg col-12 col-md d-flex justify-content-end bg-ghostwhite d-md-none">
                <h4 className="sign-baht color-darkgrayblue my-0">{net_cost.toLocaleString()}</h4>
              </div>
            </div>
          </a>
          {this.editModal(elm)}
        </React.Fragment>
      );
    });
    return items;
  }

  displayDcItems(itemsByCate, index_offset) {
    let { is_completed } = this.state;
    let items = itemsByCate.map((elm, index) => {
      let net_cost = Multiple(elm.contract_price, elm.qty);
      let code_name = elm.code + " - " + elm.name;
      let item_canceled = is_completed && !elm.issued;
      let no_contract_item = elm.no_contract;
      let findItem = this.props.ItemSelect.find((item) => item.id === elm.item_id);
      return (
        <React.Fragment key={elm.id}>
          {/* md up */}
          <div className={`row mt-2 border d-none d-md-flex ${item_canceled ? "text-line-through" : ""} ${no_contract_item ? "color-red" : ""}`}>
            <div className="table-item-bg first col-1 justify-content-center">{index_offset + index}</div>
            <div className="table-item-bg col-5 pt-1 px-0">{code_name}</div>
            <div className="table-item-bg col-2 px-0 justify-content-center">{`${elm.qty} ${elm.unit}`}</div>
            <div className="table-item-bg col-2 px-0 justify-content-center d-relative" style={{ color: "#3B9E42" }}>
              {findItem && findItem.active}
            </div>
            <div className="table-item-bg col-2 px-0 justify-content-center" style={{ color: "#FF0000" }}>
              {findItem && findItem.reserved}
            </div>
          </div>
          {/* md down */}
          <a onClick={() => (this.props.disabled ? false : this.handleOpenEditedModal(elm.id))}>
            <div className={`row mt-2 border d-md-none ${item_canceled ? "text-line-through" : ""}`}>
              <div className="table-item-bg col-12 pt-1 col-md-4 pr-3">{code_name}</div>
              <div className="table-item-bg col col-md-1 text-center d-md-none">
                จำนวน {elm.qty} {elm.unit}
              </div>
              <div className="table-item-bg col-12 col-md d-flex justify-content-end bg-ghostwhite d-md-none">
                <h4 className="sign-baht color-darkgrayblue my-0">{net_cost.toLocaleString()}</h4>
              </div>
            </div>
          </a>
          {this.editModal(elm)}
        </React.Fragment>
      );
    });
    return items;
  }

  displayVendorItemByCategory() {
    let { quotation } = this.props;
    let { quotation_item_categories, quotation_items } = quotation;
    let index_offset = 1;
    let itemsByCategory = _.groupBy(quotation_items, function (value) {
      return value.item_category_id;
    });

    let result = Object.keys(itemsByCategory).map((key) => {
      let current_cate = quotation_item_categories[key];
      let price_sum = 0;

      const disabled_contract_sel = itemsByCategory[key].reduce((pre, cur) => {
        return pre || !stringIsEmpty(getWarrantyCode(cur.warranty_codes));
      }, false);

      let rowItems = this.displayVendorItems(itemsByCategory[key], index_offset);

      itemsByCategory[key].forEach((temp) => {
        let contract_price = parseFloat(temp.contract_price);
        let qty = parseFloat(temp.qty);

        if (temp.is_parent) return;

        price_sum += Multiple(qty, contract_price);
      });

      // const is_type_7 = current_cate.item_category_expenses.find((cate) => cate.method_type === 7 && cate.qty < cate.from_value);


      let is_type_7 = null

      current_cate.item_category_expenses.forEach((cate) => {
        if (cate.method_type === 7 && cate.qty > cate.from_value) {
          if (is_type_7) {
            if (cate.qty > is_type_7.from_value && is_type_7.from_value < cate.from_value) {
              is_type_7 = cate
            }
          } else {
            is_type_7 = cate
          }

        }
      })

      index_offset += itemsByCategory[key].length;

      let sum_expense = getCategoryTotalExpense(current_cate.item_category_expenses, true, is_type_7 ? is_type_7 : false);
      let isVendor = current_cate.dc_id === 0 || current_cate.item_category.dc_id === 0;

      return (
        isVendor && (
          <React.Fragment key={key}>
            <div className="row purchase-price">
              <div className="table-item-bg category-title first font-weight-bold col-12 col-md-3 pr-0">
                <div className={`align-items-center pr-2 ${this.props.disabled ? "d-none" : "d-flex"}`}>
                  <i className={`icon-adjust cursor-pointer`} onClick={() => this.onHandleFeeAdjustModal(key)} />
                </div>
                {quotation_item_categories[key].sys_name}
              </div>
              <div className="table-item-bg category-title col-12 col-md-4 justify-content-end">
                <div className="w-100">
                  <ContractByCateSelect
                    contractId={quotation_item_categories[key].contract_id}
                    approvedDate={quotation.approved_date}
                    itemCategoryId={key}
                    onChange={this.props.onChangeContract}
                    key={this.props.reset_pp_key}
                    disabled={this.props.disabled || disabled_contract_sel}
                  />
                </div>
              </div>
              <div className="table-item-bg category-title col-12 col-md-3 pl-0 text-bold text-right">
                <div className="col-break-word sign-baht">{`ค่าบริการ ${sum_expense.toLocaleString()}`}</div>
              </div>
              <div className="table-item-bg category-title col-12 col-md-2 pl-0 text-bold text-right">
                <div className="col-break-word sign-baht">{`รวม ${(price_sum + sum_expense).toLocaleString()}`}</div>
              </div>
            </div>
            {rowItems}
            <QuotationPurchasePricesFee {...this.props} costByCate={current_cate.item_category_expenses} is_completed={this.state.is_completed} items_in_cate={itemsByCategory[key]} />
            <div className="row mt-3"></div>
          </React.Fragment>
        )
      );
    });

    return result;
  }

  displayDcItemByCategory() {
    let { quotation } = this.props;
    let { quotation_item_categories, quotation_items } = quotation;
    let index_offset = 1;
    let itemsByCategory = _.groupBy(quotation_items, function (value) {
      return value.item_category_id;
    });
    let result = Object.keys(itemsByCategory).map((key) => {
      let current_cate = quotation_item_categories[key];
      let price_sum = 0;
      const disabled_contract_sel = itemsByCategory[key].reduce((pre, cur) => {
        return pre || !stringIsEmpty(getWarrantyCode(cur.warranty_codes));
      }, false);
      let rowItems = this.displayDcItems(itemsByCategory[key], index_offset);
      itemsByCategory[key].forEach((temp) => {
        let contract_price = parseFloat(temp.contract_price);
        let qty = parseFloat(temp.qty);
        price_sum += Multiple(qty, contract_price);
      });
      index_offset += itemsByCategory[key].length;

      let isDC = quotation_item_categories[key].item_category.dc_id === 1 || quotation_item_categories[key].dc_id === 1;

      return (
        isDC && (
          <React.Fragment key={key}>
            <div className="row purchase-price">
              <div className="table-item-bg category-title first font-weight-bold col-12 pr-0" style={{ minHeight: "38px" }}>
                <div className={`align-items-center pr-2 ${this.props.disabled ? "d-none" : "d-flex"}`}>
                  <i className={`icon-adjust cursor-pointer`} onClick={() => this.onHandleFeeAdjustModal(key)} />
                </div>
                {quotation_item_categories[key].sys_name}
              </div>
            </div>
            {rowItems}
            <div className="row mt-3"></div>
          </React.Fragment>
        )
      );
    });

    return result;
  }

  displayVendorPurchasePriceList() {
    let tableHeight = "";
    const { quotation } = this.props;
    const disabled_contract = quotation.quotation_items.reduce((pre, cur) => {
      return pre || !stringIsEmpty(getWarrantyCode(cur.warranty_codes));
    }, false);
    const temp_disable = !(quotation.state === "draft" || quotation.state === "submitted" || quotation.state === "cloned to edit") || disabled_contract;
    return (
      <React.Fragment>
        <div className="col">
          <div className="row p-0 px-md-4">
            <div className="col pr-lg-0">
              <div className="row">
                <div className="col-6 col-md-3 pr-0 my-2">
                  <label className="sign-is-required my-0">วันที่อนุมัติ (วันที่ดึงราคาสัญญา)</label>
                </div>
                <div className="col-6 col-md-4 date-picker-wrapper my-2">
                  <CustomDatePicker className="form-control" dateFormat={FORMAT_APP_DATE} selected={this.state.approved_date} onChange={(date) => this.onChangeContractDate(date)} disabled={temp_disable} />
                </div>
                <div className="col-12 col-md-3 my-2">
                  <button type="button" className="btn btn-primary w-100" onClick={this.props.onReCalContractDate} disabled={temp_disable}>
                    คำนวณใหม่
                  </button>
                </div>
              </div>
              {disabled_contract ? (
                <div className="row color-red p-l-15px my-2">
                  <b>คำเตือน</b>: ถ้ามีการออกรหัสทรัพย์สินแล้ว จะไม่สามารถเปลี่ยนวันที่อนุมัติ (วันที่ดึงราคาสัญญา) หรือสัญญาที่มีสินค้าที่ออกรหัสทรัพย์สินแล้วได้
                </div>
              ) : (
                ""
              )}
              {this.displayError()}
              <div className="col-12">
                <div className="row table-header d-none d-md-flex">
                  <div className="table-item-bg col-1 px-0 first justify-content-center">
                    <strong>ลำดับ</strong>
                  </div>
                  <div className="table-item-bg col-5 px-0 ">
                    <strong>รายการสินค้า Vendor</strong>
                  </div>
                  <div className="table-item-bg col-2 pr-3 justify-content-end">
                    <strong>จำนวน</strong>
                  </div>
                  <div className="table-item-bg col-2 pr-3 justify-content-end">
                    <strong>ราคา</strong>
                  </div>
                  <div className="table-item-bg col-2 pr-3 justify-content-end">
                    <strong>รวม</strong>
                  </div>
                </div>
                <div className={`row quo-table ${tableHeight}`}>
                  <div className="col-12">{this.displayVendorItemByCategory()}</div>
                </div>
                {this.displaySummary(true)}
              </div>
            </div>
            <div className="col-3 d-none d-lg-block">{this.rightSideBar()}</div>
          </div>
        </div>
        {this.displayFeeAdjustmentModal()}
      </React.Fragment>
    );
  }

  displayDcPurchasePriceList() {
    let tableHeight = "";
    const { quotation } = this.props;
    const disabled_contract = quotation.quotation_items.reduce((pre, cur) => {
      return pre || !stringIsEmpty(getWarrantyCode(cur.warranty_codes));
    }, false);
    const temp_disable = !(quotation.state === "draft" || quotation.state === "submitted" || quotation.state === "cloned to edit") || disabled_contract;
    return (
      <React.Fragment>
        <div className="col quo-scrollable-table">
          <div className="row p-0 p-md-4">
            <div className="col">
              <div className="col-12">
                <div className="row table-header d-none d-md-flex">
                  <div className="table-item-bg col-1 px-0 first justify-content-center">
                    <strong>ลำดับ</strong>
                  </div>
                  <div className="table-item-bg col-5 px-0 ">
                    <strong>รายการสินค้า DC</strong>
                  </div>
                  <div className="table-item-bg col-2 px-0 justify-content-center">
                    <strong>จำนวน</strong>
                  </div>
                  <div className="table-item-bg col-2 px-0 justify-content-center" style={{ color: "#3B9E42" }}>
                    <strong>Active</strong>
                  </div>
                  <div className="table-item-bg col-2 px-0 justify-content-center" style={{ color: "#FF0000" }}>
                    <strong>Reserved</strong>
                  </div>
                </div>
                <div className={`row quo-table ${tableHeight}`}>
                  <div className="col-12">{this.displayDcItemByCategory()}</div>
                </div>
                {this.displaySummary(true)}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  displaySummary(md) {
    let { quotation } = this.props;
    let total_with_discount = calTotalSales(quotation, true);
    let discount = calTotalDiscount(quotation, true);
    let total = total_with_discount + discount;
    let vat = total * 0.07;

    let text_total = "รวมเป็นเงิน";
    let text_discount = "ส่วนลด";
    let text_total_with_discount = "หลังหักส่วนลด";
    let text_vat = "VAT 7%";
    let text_sum = "รวมทั้งสิ้น";
    if (md) {
      return (
        <React.Fragment>
          {/* lg down */}
          <div className="mt-2 d-lg-none">
            <div className="row">
              <div className="table-item-bg col p-0 justify-content-end">{text_total}</div>
              <div className="table-item-bg col justify-content-end sign-baht">{total.toLocaleString()}</div>
            </div>
            <div className="row">
              <div className="table-item-bg col p-0 justify-content-end">{text_discount}</div>
              <div className="table-item-bg col justify-content-end sign-baht">{discount.toLocaleString()}</div>
            </div>
            <div className="row">
              <div className="table-item-bg col p-0 justify-content-end">{text_total}</div>
              <div className="table-item-bg col justify-content-end sign-baht">{total_with_discount.toLocaleString()}</div>
            </div>
            <div className="row mb-2">
              <div className="table-item-bg col p-0 justify-content-end">{text_vat}</div>
              <div className="table-item-bg col justify-content-end sign-baht">{vat.toLocaleString()}</div>
            </div>
            <div className="row m-b-100">
              <div className="table-item-bg total col p-0 justify-content-end">{text_sum}</div>
              <div className="table-item-bg total col justify-content-end sign-baht">{(total_with_discount + vat).toLocaleString()}</div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {/* lg up */}
          <div className="mt-2 d-none d-lg-block">
            <div className="d-flex flex-row pr-3">
              <div className="d-flex-1 py-1 text-right">{text_total}</div>
              <div className="d-flex-1 py-1 text-right">{total.toLocaleString()}</div>
            </div>
            <div className="d-flex flex-row pr-3">
              <div className="d-flex-1 py-1 text-right">{text_discount}</div>
              <div className="d-flex-1 py-1 text-right">{discount.toLocaleString()}</div>
            </div>
            <div className="d-flex flex-row pr-3">
              <div className="d-flex-1 py-1 text-right">{text_total}</div>
              <div className="d-flex-1 py-1 text-right">{total_with_discount.toLocaleString()}</div>
            </div>
            <div className="d-flex flex-row pr-3">
              <div className="d-flex-1 py-1 text-right">{text_vat}</div>
              <div className="d-flex-1 py-1 text-right">{vat.toLocaleString()}</div>
            </div>
            <div className="d-flex flex-row pr-3 bg-grayyellow">
              <div className="d-flex-1 py-2 text-right font-weight-bold">{text_sum}</div>
              <div className="d-flex-1 py-2 text-right font-weight-bold">{(total_with_discount + vat).toLocaleString()}</div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  rightSideBar() {
    let { quotation } = this.props;

    return (
      <React.Fragment>
        <div className="quo-right-side-bar">
          {this.displaySummary()}
          <ExportExcel
            data={[toExcelCompareTable(quotation)]}
            content={"ออกตารางเปรียบเทียบ"}
            filename={`ตารางเปรียบเทียบ_${quotation.code}_${formatApiDate(new Date())}`}
            customClass={"btn btn-success w-100 mt-3"}
            disabled={quotation.quotation_items.length === 0}
          />
        </div>
      </React.Fragment>
    );
  }

  displayError() {
    let { errors_pprices } = this.props;
    const text = () => {
      return errors_pprices.map((elm) => {
        return (
          <div className="row color-red">
            <div className="col">{elm}</div>
          </div>
        );
      });
    };
    return <div className="col">{text()}</div>;
  }

  onChangePurchasePriceListTab(index) {
    this.setState({ current_purchase_price_tab: index });
  }

  displayPurchasePriceListTabs(purchase_price_list_text, purchase_price_list_tab_name) {
    let { current_purchase_price_tab } = this.state;
    return (
      <li className="nav-item">
        <a className={`nav-link px-2 ${current_purchase_price_tab === QUO_PURCHASE_PRICE_TABS[purchase_price_list_tab_name] ? "active" : ""}`} onClick={() => this.onChangePurchasePriceListTab(QUO_PURCHASE_PRICE_TABS[purchase_price_list_tab_name])}>
          {purchase_price_list_text}
        </a>
      </li>
    );
  }

  render() {
    const { quotation } = this.props;
    const { quotation_items } = quotation;
    const disabled_contract = quotation.quotation_items.reduce((pre, cur) => {
      return pre || !stringIsEmpty(getWarrantyCode(cur.warranty_codes));
    }, false);
    const temp_disable = !(quotation.state === "draft" || quotation.state === "submitted" || quotation.state === "cloned to edit") || disabled_contract;
    return (
      <React.Fragment>
        <div className="col">
          <div className="row pt-0 pt-md-4 px-0 px-md-4">
            <div className="col pr-lg-0">
              <div className="d-flex flex-row mb-2">
                <ul className="nav nav-tabs tab-responsive">
                  {this.displayPurchasePriceListTabs(`สินค้า Vendor (${this.props.selected_vendor_item})`, "vendor")}
                  {this.displayPurchasePriceListTabs(`สินค้า DC (${this.props.selected_dc_item})`, "dc")}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {this.state.current_purchase_price_tab === QUO_PURCHASE_PRICE_TABS.vendor ? this.displayVendorPurchasePriceList() : this.displayDcPurchasePriceList()}
      </React.Fragment>
    );
  }
}

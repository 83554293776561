import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";

import { formatDate } from "../functions/Date";
import { parseData } from "../functions/Excel";
import { generateID } from "../functions/Random";
import { addressToStr } from "../functions/String";

// Models
import { NEW_ORDER } from "./Orders";

// constants
import { APP_TITLE_REPORT_TR_ORDER, APP_TITLE_MAINTENANCE, APP_TITLE_REPORT_STATION, APP_TITLE_REPORT_VENDOR, APP_TITLE_REPORT_CONTRACT_BUDGET, APP_TITLE_REPORT_INVENTORY } from "../constants";
import moment from "moment";

export const BUYER_TEXT = "B";
export const REPORT_TYPES = {
  order: {
    value: 0,
    name: APP_TITLE_REPORT_TR_ORDER,
    path: "order",
  },
  client_station: {
    value: 1,
    name: APP_TITLE_REPORT_STATION,
    path: "client_station",
  },
  vendor: {
    value: 2,
    name: APP_TITLE_REPORT_VENDOR,
    path: "vendor",
  },
  contract_budget: {
    value: 3,
    name: APP_TITLE_REPORT_CONTRACT_BUDGET,
    path: "contract_budget",
  },
  inventory_report: {
    value: 4,
    name: APP_TITLE_REPORT_INVENTORY,
    path: "dc_inventory_report",
  },
  maintenance_report: {
    value: 5,
    name: APP_TITLE_MAINTENANCE,
    path: "maintenance",
  },
};

export function set_temp_order() {
  let temp_order = cloneDeep(NEW_ORDER);
  temp_order.id = generateID();
  temp_order.code = "";
  temp_order.po_date = null;
  temp_order.delivery_date = null;
  temp_order.order_item_categories.push({
    contract_id: null,
    item_category_id: null,
    informed_date: null,
    sys_name: null,
  });
  return temp_order;
}

export function toExcelQuotationReport(quotations) {
  let data = [];

  // Styles
  const font = { sz: "10" };
  const font_red = { sz: "10", color: { rgb: "FFff0000" } };
  const border_color = { style: "thin", color: { rgb: "FF000000" } };
  const border_color_medium = { style: "medium", color: { rgb: "FF000000" } };
  const border_white = { style: "thin", color: { rgb: "FFFFFFFF" } };
  const border_style = { top: border_color, bottom: border_color, left: border_color, right: border_color };
  const border_style_bm = { top: border_color, bottom: border_color_medium, left: border_color, right: border_color };
  const style_row_1 = { fill: { fgColor: { rgb: "FFC5E0B4" } }, border: border_style_bm, font: { sz: "11", bold: true }, alignment: { horizontal: "center" } };
  const style_row_body_title_center = { fill: { fgColor: { rgb: "FFffe699" } }, border: border_style, font: { sz: "10", bold: true }, alignment: { horizontal: "center" } };
  const style_row_body_title = { fill: { fgColor: { rgb: "FFffe699" } }, border: border_style, font: { sz: "10", bold: true } };
  const style_row_body_center = { border: border_style, font: { sz: "10" }, alignment: { vertical: "top" }, alignment: { horizontal: "center" } };
  const style_row_body = { border: border_style, font: { sz: "10" }, alignment: { vertical: "top" } };
  const width_col_xs = { wch: 8 };
  const width_col_sm = { wch: 15 };
  const width_col_md = { wch: 25 };
  const width_col_lg = { wch: 35 };
  const width_col_xl = { wch: 50 };

  const header = [
    { title: "ลำดับ", width: width_col_xs, style: style_row_1 },
    { title: "สถานี", width: width_col_xl, style: style_row_1 },
    { title: "จังหวัด", width: width_col_sm, style: style_row_1 },
    { title: "ชื่อผู้ติดต่อ", width: width_col_md, style: style_row_1 },
    { title: "เบอร์ติดต่อ", width: width_col_sm, style: style_row_1 },
    { title: "เลขที่ใบเสนอราคา", width: width_col_sm, style: style_row_1 },
    { title: "วันที่อนุมัติ", width: width_col_sm, style: style_row_1 },
    { title: "วันที่ออร์เดอร์", width: width_col_sm, style: style_row_1 },
    { title: "เลขที่ออร์เดอร์", width: width_col_sm, style: style_row_1 },
    { title: "เลขที่ออร์เดอร์ (ORC)", width: width_col_sm, style: style_row_1 },
    { title: "หมวดหมู่สินค้า", width: width_col_md, style: style_row_1 },
    { title: "แจ้งผู้ผลิต", width: width_col_sm, style: style_row_1 },
    { title: "สินค้า", width: width_col_lg, style: style_row_1 },
    { title: "จำนวน", width: width_col_sm, style: style_row_1 },
    { title: "วันที่ติดตั้ง", width: width_col_sm, style: style_row_1 },
    { title: "วันที่จัดส่ง	PO", width: width_col_sm, style: style_row_1 },
    { title: "การชำระ", width: width_col_sm, style: style_row_1 },
    { title: "ตั้งหนี้", width: width_col_sm, style: style_row_1 },
    { title: "วันที่ส่งข้อมูล PO/GR", width: width_col_sm, style: style_row_1 },
    { title: "วันที่ได้รับ PO", width: width_col_sm, style: style_row_1 },
    { title: "เลข PO", width: width_col_sm, style: style_row_1 },
    { title: "วันที่ได้รับ GR", width: width_col_sm, style: style_row_1 },
    { title: "เลข GR", width: width_col_sm, style: style_row_1 },
    { title: "เลขที่ Billing", width: width_col_sm, style: style_row_1 },
    { title: "วันที่ได้รับ Billing", width: width_col_sm, style: style_row_1 },
    { title: "เลขที่ใบแจ้งหนี้", width: width_col_sm, style: style_row_1 },
    { title: "วันที่ส่งใบแจ้งหนี้", width: width_col_sm, style: style_row_1 },
  ];

  let quotationByClient = _.groupBy(quotations, function (elm) {
    return elm.client_station.id;
  });
  let number_index = 0;
  for (let key of Object.keys(quotationByClient)) {
    let quotation_index = 0;
    for (let quotation of quotationByClient[key]) {
      if (quotation.purchasing_orders.length === 0) {
        quotation.purchasing_orders.push(set_temp_order());
      }
      let client = quotation.client_station;
      number_index += 1;
      quotation.purchasing_orders.forEach((order, index) => {
        // let order_po_date = '', order_code = '', order_order_code = '', order_db_billing_code = '',
        //     order_db_billing_date = '', order_invoice_issued_date = ''
        // if (index === 0) {
        //   order_po_date = formatDate(order.po_date)
        //   order_code = order.code
        //   order_order_code = order.order_code
        //   order_db_billing_code = order.db_billing_code
        //   order_db_billing_date = formatDate(order.db_billing_date)
        //   order_invoice_issued_date = formatDate(order.invoice_issued_date)
        // }
        let payment_text = order.payment_method && order.payment_method.method_type !== "" ? `${order.payment_method.method_type}-${formatDate(order.payment_method.payment_date)}` : "";
        let set_debt_text = order.payment_method && order.payment_method.method_type !== "" ? formatDate(order.payment_method.set_debt_date) : "";
        order.order_item_categories.forEach((order_cate, sub_index) => {
          let contract = order.order_po_grs.filter((elm) => elm.contract_id === order_cate.contract_id)[0];
          let items = order.purchasing_order_items.filter((elm) => elm.item_category_id === order_cate.item_category_id && !elm.is_mbom);
          let order_cate_name = "",
            order_cate_inform_date = "";
          if (!contract) {
            contract = {
              submit_date: null,
              po_date: null,
              po_code: null,
              gr_date: null,
              gr_code: null,
            };
          }
          if (items.length === 0) {
            items.push({
              name: null,
              qty: null,
              installation_date: null,
            });
          }
          if (sub_index === 0) {
            order_cate_name = order_cate.sys_name;
            order_cate_inform_date = formatDate(order_cate.informed_date);
          }
          for (let item of items) {
            let style_center = quotation_index === 0 ? style_row_body_title_center : style_row_body_center;
            let style = quotation_index === 0 ? style_row_body_title : style_row_body;
            data.push([
              parseData(number_index, style_center),
              parseData(client.name, style),
              parseData(client.address.province.name, style),
              parseData(client.contact_person, style),
              parseData(client.contact_tel, style),
              parseData(quotation.code, style),
              parseData(quotation.approved_date, style_center),
              parseData(formatDate(order.po_date), style_center),
              parseData(order.code, style),
              parseData(order.order_code, style),
              parseData(order_cate.sys_name, style),
              parseData(formatDate(order_cate.informed_date), style_center),
              parseData(item.name, style),
              parseData(item.qty, style),
              parseData(formatDate(item.installation_date), style_center),
              parseData(formatDate(order.delivery_date), style_center),
              parseData(payment_text, style),
              parseData(set_debt_text, style),
              parseData(formatDate(contract.submit_date), style_center),
              parseData(formatDate(contract.po_date), style_center),
              parseData(contract.po_code, style),
              parseData(formatDate(contract.gr_date), style_center),
              parseData(contract.gr_code, style),
              parseData(order.db_billing_code, style),
              parseData(formatDate(order.db_billing_date), style_center),
              parseData(order.db_billing_code, style),
              parseData(formatDate(order.invoice_issued_date), style_center),
            ]);
            quotation_index += 1;
          }
        });
      });
    }
  }

  return { sheet_name: "รายการ", dataSet: [{ columns: header, data: data }] };
}

export function toExcelClientStationReport(reports) {
  let data = [];

  // Styles
  const header_font = { sz: "11", bold: true };
  const border_color = { style: "thin", color: { rgb: "FF000000" } };
  const border_color_medium = { style: "medium", color: { rgb: "FF000000" } };
  const border_style = { top: border_color, bottom: border_color, left: border_color, right: border_color };
  const border_style_bm = { bottom: border_color_medium };
  const style_row_index = { fill: { fgColor: { rgb: "FFC5E0B4" } }, font: header_font, alignment: { horizontal: "center" } };
  const style_row_buyer = { fill: { fgColor: { rgb: "95b3d7" } }, font: header_font, alignment: { horizontal: "center" } };
  const style_row_station = { fill: { fgColor: { rgb: "b7dee8" } }, font: header_font, alignment: { horizontal: "center" } };
  const style_row_total = { fill: { fgColor: { rgb: "ffffa6" } }, font: header_font, alignment: { horizontal: "center" } };
  const style_row_index_bb = {
    ...style_row_index,
    border: border_style_bm,
  };
  const style_row_buyer_bb = {
    ...style_row_buyer,
    border: border_style_bm,
  };
  const style_row_station_bb = {
    ...style_row_station,
    border: border_style_bm,
  };
  const style_row_total_bb = {
    ...style_row_total,
    border: border_style_bm,
  };

  const style_row_sum = { fill: { fgColor: { rgb: "f2f2f2" } }, border: border_style, font: { sz: "10", bold: true } };
  const style_row_sum_center = {
    ...style_row_sum,
    alignment: { horizontal: "center" },
  };
  const style_row_sum_right = {
    ...style_row_sum,
    alignment: { horizontal: "right" },
  };

  const style_row_body_center = { border: border_style, font: { sz: "10" }, alignment: { vertical: "top" }, alignment: { horizontal: "center" } };
  const style_row_body = { border: border_style, font: { sz: "10" }, alignment: { vertical: "top" } };
  const width_col_xs = { wch: 10 };
  const width_col_sm = { wch: 15 };
  const width_col_md = { wch: 25 };
  const width_col_lg = { wch: 50 };
  const width_col_xl = { wch: 70 };

  const header = [
    { title: "ลำดับ", width: width_col_xs, style: style_row_index },
    { title: "", width: width_col_md, style: style_row_buyer },
    { title: "", width: width_col_md, style: style_row_buyer },
    { title: "", width: width_col_sm, style: style_row_buyer },
    { title: "", width: width_col_sm, style: style_row_buyer },
    { title: "", width: width_col_sm, style: style_row_buyer },
    { title: "", width: width_col_md, style: style_row_buyer },
    { title: "", width: width_col_md, style: style_row_buyer },
    { title: "ผู้สั่งซื้อ", width: width_col_md, style: style_row_buyer },
    { title: "", width: width_col_md, style: style_row_buyer },
    { title: "", width: width_col_sm, style: style_row_buyer },
    { title: "", width: width_col_xl, style: style_row_buyer },
    { title: "", width: width_col_lg, style: style_row_station },
    { title: "", width: width_col_md, style: style_row_station },
    { title: "", width: width_col_sm, style: style_row_station },
    { title: "", width: width_col_sm, style: style_row_station },
    { title: "", width: width_col_sm, style: style_row_station },
    { title: "", width: width_col_md, style: style_row_station },
    { title: "สถานี", width: width_col_md, style: style_row_station },
    { title: "", width: width_col_md, style: style_row_station },
    { title: "", width: width_col_md, style: style_row_station },
    { title: "", width: width_col_sm, style: style_row_station },
    { title: "", width: width_col_xl, style: style_row_station },
    { title: "จำนวนคำสั่งซื้อ", width: width_col_sm, style: style_row_total },
    { title: "ยอดขาย(บาท)", width: width_col_sm, style: style_row_total },
  ];

  data.push([
    { value: "", style: style_row_index_bb },
    { value: "ชื่อผู้ซื้อ", style: style_row_buyer_bb },
    { value: "รายชื่อติดต่อผู้ซื้อ", style: style_row_buyer_bb },
    { value: "เบอร์ติดต่อ", style: style_row_buyer_bb },
    { value: "Ship to", style: style_row_buyer_bb },
    { value: "Sold to", style: style_row_buyer_bb },
    { value: "ถนน/บ้านเลขที่", style: style_row_buyer_bb },
    { value: "แขวง/ตำบล", style: style_row_buyer_bb },
    { value: "เขต/อำเภอ", style: style_row_buyer_bb },
    { value: "จังหวัด", style: style_row_buyer_bb },
    { value: "รหัสไปรษณีย์", style: style_row_buyer_bb },
    { value: "ที่อยู่ผู้ซื้อ (เต็ม)", style: style_row_buyer_bb },
    { value: "สำหรับสถานี", style: style_row_station_bb },
    { value: "รายชื่อติดต่อสถานี", style: style_row_station_bb },
    { value: "เบอร์ติดต่อ", style: style_row_station_bb },
    { value: "Ship to", style: style_row_station_bb },
    { value: "Sold to", style: style_row_station_bb },
    { value: "ถนน/บ้านเลขที่", style: style_row_station_bb },
    { value: "แขวง/ตำบล", style: style_row_station_bb },
    { value: "เขต/อำเภอ", style: style_row_station_bb },
    { value: "จังหวัด", style: style_row_station_bb },
    { value: "รหัสไปรษณีย์", style: style_row_station_bb },
    { value: "ที่อยู่สถานี (เต็ม)", style: style_row_station_bb },
    { value: "", style: style_row_total_bb },
    { value: "(ไม่รวม Vat 7%)", style: style_row_total_bb },
  ]);

  let reportByBuyer = _.groupBy(reports, function (elm) {
    return elm.quotation.buyer_id ? BUYER_TEXT + elm.quotation.buyer_id : elm.quotation.client_station.id;
  });

  let buyer_index = 0;
  for (let key of Object.keys(reportByBuyer).reverse()) {
    let reportByClient = _.groupBy(reportByBuyer[key], function (elm) {
      return elm.quotation.client_station.id;
    });
    let client_index = 0;
    const all_count = reportByBuyer[key].length;
    const all_sales = reportByBuyer[key].reduce((pre, cur) => (pre += cur.total_sales), 0);
    for (let client_id_key in reportByClient) {
      const total_sales = reportByClient[client_id_key].reduce((pre, cur) => (pre += cur.total_sales), 0);
      const first_row = reportByClient[client_id_key][0];
      const buyer = first_row.quotation.buyer;
      const display_buyer = client_index === 0 && buyer;
      const client_station = first_row.quotation.client_station;
      const station_address = first_row.quotation.client_station.address;
      let buyer_address = null;
      if (display_buyer) {
        buyer_address = buyer.address;
      }

      data.push([
        parseData(client_index === 0 ? buyer_index + 1 : "", style_row_body_center, "-", style_row_body_center),
        parseData(display_buyer ? buyer.name : "", style_row_body, "-", style_row_body_center),
        parseData(display_buyer ? buyer.buyer_contact_person : "", style_row_body, "-", style_row_body_center),
        parseData(display_buyer ? buyer.buyer_tel : "", style_row_body, "-", style_row_body_center),
        parseData(display_buyer ? buyer.ship_to_code : "", style_row_body_center, "-", style_row_body_center),
        parseData(display_buyer ? buyer.sold_to_code : "", style_row_body_center, "-", style_row_body_center),
        parseData(display_buyer ? buyer_address.address_line1 : "", style_row_body, "-", style_row_body_center),
        parseData(display_buyer ? buyer_address.sub_district.name : "", style_row_body_center, "-", style_row_body_center),
        parseData(display_buyer ? buyer_address.district.name : "", style_row_body_center, "-", style_row_body_center),
        parseData(display_buyer ? buyer_address.province.name : "", style_row_body_center, "-", style_row_body_center),
        parseData(display_buyer ? buyer_address.postcode : "", style_row_body_center, "-", style_row_body_center),
        parseData(display_buyer ? addressToStr(buyer_address) : "", style_row_body, "-", style_row_body_center),
        parseData(client_station.name, style_row_body, "-", style_row_body_center),
        parseData(client_station.contact_person, style_row_body, "-", style_row_body_center),
        parseData(client_station.contact_tel, style_row_body_center, "-", style_row_body_center),
        parseData(client_station.ship_to_code, style_row_body_center, "-", style_row_body_center),
        parseData(client_station.sold_to_code, style_row_body_center, "-", style_row_body_center),
        parseData(station_address.address_line1, style_row_body, "-", style_row_body_center),
        parseData(station_address.sub_district.name, style_row_body_center, "-", style_row_body_center),
        parseData(station_address.district.name, style_row_body_center, "-", style_row_body_center),
        parseData(station_address.province.name, style_row_body_center, "-", style_row_body_center),
        parseData(station_address.postcode, style_row_body_center, "-", style_row_body_center),
        parseData(addressToStr(station_address), style_row_body, "-", style_row_body_center),
        parseData(reportByClient[client_id_key].length, style_row_body_center, "-", style_row_body_center),
        parseData(total_sales, style_row_body, "-", style_row_body_center),
      ]);
      client_index += 1;
    }

    data.push([
      { value: "", style: style_row_sum },
      { value: "", style: style_row_sum },
      { value: "", style: style_row_sum },
      { value: "", style: style_row_sum },
      { value: "", style: style_row_sum },
      { value: "", style: style_row_sum },
      { value: "", style: style_row_sum },
      { value: "", style: style_row_sum },
      { value: "", style: style_row_sum },
      { value: "", style: style_row_sum },
      { value: "", style: style_row_sum },
      { value: "", style: style_row_sum },
      { value: "", style: style_row_sum },
      { value: "", style: style_row_sum },
      { value: "", style: style_row_sum },
      { value: "", style: style_row_sum },
      { value: "", style: style_row_sum },
      { value: "", style: style_row_sum },
      { value: "", style: style_row_sum },
      { value: "", style: style_row_sum },
      { value: "", style: style_row_sum },
      { value: "", style: style_row_sum },
      { value: "รวม", style: style_row_sum_right },
      { value: all_count, style: style_row_sum_center },
      { value: all_sales, style: style_row_sum_right },
    ]);

    buyer_index += 1;
  }

  return { sheet_name: "รายการ", dataSet: [{ columns: header, data: data }] };
}

export function toExcelContractBudgetReport(contracts, approval_person) {
  let data = [];

  // Styles
  const header_font = { sz: "11", bold: true };
  const style_row_index = { fill: { fgColor: { rgb: "FFC5E0B4" } }, font: header_font, alignment: { horizontal: "center" } };
  const style_row_buyer = { fill: { fgColor: { rgb: "95b3d7" } }, font: { sz: "12", bold: true }, alignment: { horizontal: "center" } };

  const width_col_sm = { wch: 15 };
  const width_col_md = { wch: 25 };
  const width_col_lg = { wch: 50 };

  const header = [
    { title: "เลขที่สัญญา", width: width_col_md, style: style_row_buyer },
    { title: "ผู้มีอำนาจอนุมัติสัญญา", width: width_col_md, style: style_row_buyer },
    { title: "ชื่อสัญญา/ชื่อสินค้า", width: width_col_lg, style: style_row_buyer },
    { title: "ผู้ผลิต", width: width_col_lg, style: style_row_buyer },
    { title: "วันที่เริ่ม", width: width_col_sm, style: style_row_buyer },
    { title: "วันที่สิ้นสุด", width: width_col_sm, style: style_row_buyer },
    { title: "ระยะเวลาผลิต", width: width_col_sm, style: style_row_buyer },
    { title: "วงเงินสัญญา", width: width_col_md, style: style_row_buyer },
    { title: "จำนวนสินค้า", width: width_col_md, style: style_row_buyer },
    { title: "วงเงินที่ใช้ไป", width: width_col_md, style: style_row_buyer },
    { title: "วงเงินคงเหลือ", width: width_col_md, style: style_row_buyer },
    { title: "คณะกรรมการตรวจรับ", width: width_col_md, style: style_row_buyer },
  ];
  Object.keys(contracts).forEach((key) => {
    let contract_group = contracts[key];
    let contract_head = contract_group[0];

    let total_contract_useage = 0;
    let total_contract_qty = 0;
    contract_group.forEach((item) => {
      total_contract_qty += item.qty
      total_contract_useage += item.total_quo_item_contract_price;
    });

    data.push([
      parseData(contract_head.code, style_row_index),
      parseData(approval_person, style_row_index),
      parseData(contract_head.name, style_row_index),
      parseData(contract_head.vendor_name, style_row_index),
      parseData(moment(contract_head.from_date).format("DD-MM-YYYY"), style_row_index),
      parseData(moment(contract_head.to_date).format("DD-MM-YYYY"), style_row_index),
      parseData(contract_head.nb_days_to_complete ? contract_head.nb_days_to_complete : " ", style_row_index),
      parseData(contract_head.contract_budget != null ? contract_head.contract_budget.toLocaleString() : "", style_row_index),
      // parseData(contract_head.row != null ? contract_head.row.toLocaleString() : "", style_row_index),
      parseData(total_contract_qty !== 0 ? total_contract_qty.toLocaleString() : "", style_row_index),
      parseData(total_contract_useage !== 0 ? total_contract_useage.toLocaleString() : "", style_row_index),
      parseData(contract_head.contract_budget != null && total_contract_useage != null ? (contract_head.contract_budget - total_contract_useage).toLocaleString() : "-", style_row_index),
      parseData(contract_head.contract_responsible_persons, style_row_index),
    ]);
    contract_group.forEach((elm) => {
      data.push([
        parseData(""),
        parseData(""),
        parseData(elm.contract_item_name),
        parseData(""),
        parseData(""),
        parseData(""),
        parseData(""),
        parseData(""),
        parseData(elm.qty != null ? elm.qty.toLocaleString() : 0),
        parseData(elm.total_quo_item_contract_price != null ? elm.total_quo_item_contract_price.toLocaleString() : 0),
        parseData(""),
        parseData(""),
      ]);
    });
  });

  return { sheet_name: "รายการ", dataSet: [{ columns: header, data: data }] };
}

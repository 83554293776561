import React, { useState, useRef, useEffect } from "react";
import SearchBar from "../../global/SearchBar";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import Spinner from "../../global/Spinner.js";
import { APP_TITLE_DC_INVENTORIES_DEATIL, PER_PAGE, FORMAT_APP_DATE } from "../../../constants.js";
import { Button, Checkbox, Divider, Form, Input, Modal,Select } from "antd";
import { DeleteOutlined, RollbackOutlined, CloseOutlined } from "@ant-design/icons";
import { IconSorting } from "../../global/IconSorting";
import { getHeaderTotalPageCount } from "../../../functions/Response";
import "../DcInventoriesManagement.css";
import { NavLink } from "react-router-dom";
import axios from "axios";
import moment from "moment";
 
const { TextArea } = Input;
const { Option } = Select;
export default function DcInventoriesManagementList(props) {
  const [selectedSorting, setSelectedSorting] = useState("received_date");
  const [dataList, setDataList] = useState([]);
  const [code, setCode] = useState();
  const [isLoading, setIsLoading] = useState();
  const [selectedSortingReversed, setSelectedSortingReversed] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const [onDelete, setOnDelete] = useState(false);
  const [selectedDelete, setSelectedDelete] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(PER_PAGE);
  const [pageCount, setPageCount] = useState(1);

  const [itemName, setItemName] = useState(null);

  const [routeDateFrom, setRouteDateFrom] = useState('');
  const [routeDateTo, setRouteDateTo] = useState('');
  const [routeStatus, setRouteStatus] = useState('');

  const formRef = useRef();

  useEffect(() => {
    getDataList();
   
  }, [selectedSorting, selectedSortingReversed, page]);
  let searchParams = {};


function getQuery(){
  let date_from = (routeDateFrom)?`date_from=`+moment(routeDateFrom).format("YYYY-MM-DD"):""
  let date_to = (routeDateTo)?`&date_to=`+moment(routeDateTo).format("YYYY-MM-DD"):""
  let status = (routeStatus)?`&status=`+routeStatus:"" 
  let params = (date_from||date_to||status)?`?${date_from}${date_to}${status}`:''
  
  props.history.replace({
    pathname: `${props.location.pathname}`,
    search: `${params}`,
  });
}

function clearForm(){  
  setRouteDateFrom('');
  setRouteDateTo('');
  setRouteStatus('');
  props.history.replace({
    pathname: `${props.location.pathname}`,
    search: ``,
  });
}

function getStatusQuery(data){ 
  let params = (data)?`status=${data}`:(routeStatus)?`status=${routeStatus}`:''
  let dateFrom = (routeDateFrom)?`&date_to=${routeDateFrom}`:'' 
  let dateTo = (routeDateTo)?`&status=${routeDateTo}`:''
  let other = `${dateFrom}${dateTo}`
  setRouteDateFrom(data);
  props.history.replace({
    pathname: `${props.location.pathname}`,
    search: `${params}${other}`,
  });
}

  function getFilter(){ 
    let filter = {};
    let date_from = new URLSearchParams(props.location.search).get("date_from");
    let date_to = new URLSearchParams(props.location.search).get("date_to");
    let status = new URLSearchParams(props.location.search).get("status"); 
    if (date_from||date_to) {
      date_from = (date_from)?date_from:moment().format("YYYY-MM-DD")
      date_to = (date_to)?date_to:moment().format("YYYY-MM-DD")
      filter = {
        ...filter,
        received_date: [date_from, date_to],
      };
      setRouteDateFrom((date_from)?date_from:'')
      setRouteDateTo((date_to)?date_to:'')
    } 
    if (status) {
      filter = {
        ...filter,
        status:status,
      }; 
      setRouteStatus(status)
    }
    return filter;
  }

  function getDataList() {
    setIsLoading(true);  
    let range_start = page * perPage;
    let sort_dir = selectedSortingReversed ? "DESC" : "ASC";
    let filter = getFilter();
    let options = {
      sort: JSON.stringify([selectedSorting, sort_dir]),
      filter: filter,
      range: JSON.stringify([range_start, range_start + perPage - 1]),
    };
   
    axios
      .get(`${process.env.REACT_APP_API_URL}/inventory/${props.id}`, { params: options })
      .then((response) => {
        if (response.status === 200) {
          let page_count = getHeaderTotalPageCount(response, perPage);

          setPageCount(page_count);
          setItemName(response.data.data[0].item.name);
          setDataList(response.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => setIsLoading(false));

      axios.get(`${process.env.REACT_APP_API_URL}/inventory/${props.id}?sort=[%22received_date%22,%22DESC%22]&filter=%7B%7D&range=[0,14]`)
      .then((response) => {
        if (response.status === 200) {
          setItemName(response.data.data[0].item.name);
        }
      })
      .catch((error) => setIsLoading(false));
  }
  
  function onClickSort(type) {
    let new_toggle = selectedSorting === type ? !selectedSortingReversed : false;

    setSelectedSorting(type);
    setSelectedSortingReversed(new_toggle); 
  }

  // function onClickSortUp(active, type) {
  //   let sortType = active ? type : "";

  //   setSelectedSorting(sortType);
  //   setSelectedSortingReversed(false);
  // }

  function onClickSortDown(active, type) {
    let sortType = active ? type : "";

    setSelectedSorting(sortType);
    setSelectedSortingReversed(true);
  }

  function headerColWithSortIcon(text, sortText) {
    let active = selectedSorting === sortText;
    let sort_up = active && !selectedSortingReversed;
    let sort_down = active && selectedSortingReversed;
    // setSelectedSorting(sortText);
    return (
      <div className="d-flex flex-row cursor-pointer align-items-center" onClick={() => onClickSort(sortText)}>
        <div className="col-break-word text-bold" style={{ fontSize: "12px" }}>
          {text}
        </div>
        <IconSorting onClickUp={onClickSort} onClickDown={onClickSortDown} type={sortText} key={Math.random() * 100} activeUp={sort_up ? true : undefined} activeDown={sort_down ? true : undefined} />
      </div>
    );
  }

  function handleOnCheckDelete(e, item) {
    const delete_list = [...selectedDelete];
    if (e.target.checked) {
      delete_list.push(item);
    } else {
      const index = delete_list.findIndex((value) => value.item_id === item.item_id);
      delete_list.splice(index, 1);
    }

    setSelectedDelete(delete_list);
  }

  function DisplayTableHeader() {
    return (
      <div className="card-box mt-1 p-3 d-none d-md-block mt-3">
        <div className="row">
          <div className="col-12 d-flex flex-column flex-sm-row align-items-center">
            {onDelete && <div className="col-custom-4 col-custom-sm-100 px-1 py-2"></div>}
            <div className="col-custom-12 col-custom-sm-100 px-1 py-2">{headerColWithSortIcon("วันที่รับสินค้าเข้า", "received_date")}</div>
            <div className="col-custom-12 col-custom-sm-100 px-1 py-2">Inventory Item ID  </div>
            <div className="col-custom-12 col-custom-sm-100 px-1 py-2">Inbound Order ID  </div>
            <div className="col-custom-12 col-custom-sm-100 px-1 py-2">ราคาต้นทุนที่คำนวนจากขาเข้า </div>
            <div className="col-custom-12 col-custom-sm-100 px-1 py-2">Outbound Order ID</div>
            <div className="col-custom-12 col-custom-sm-100 px-1 py-2">ราคาต้นทุนเพิ่มเติม  </div>
            <div className="col-custom-12 col-custom-sm-100 px-1 py-2">สถานะ</div>
          </div>
        </div>
      </div>
    );
  }

  function DisplayTableItems() {
    return dataList.map((value) => {
      const is_item_in_delete_select = selectedDelete.findIndex((item) => item.id === value.id);

      if (onDelete && value.status !== "active") return;

      return (
        <div className={`card-box mt-1 px-3 pt-3 pb-2 ${is_item_in_delete_select !== -1 ? "is-select-to-delete" : "link-no-hover"} ${onDelete ? "" : "cursor-pointer"}`}>
          <div className="row mb-2">
            {/* md */}
            <div className="col-12 flex-custom-md flex-custom-sm-none flex-column flex-sm-row">
              {onDelete && (
                <div className="col-custom-4 col-custom-sm-100 px-1">
                  <Checkbox checked={is_item_in_delete_select !== -1 ? true : false} onChange={(e) => handleOnCheckDelete(e, value)} />
                </div>
              )}
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">{`${value.created_at ? moment(value.created_at).format("DD-MM-YYYY") : "-"}`}</div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">{`${value.id ? value.id : "-"}`}</div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">{`${value.inbound_order_item_lot ? `${value.inbound_order_item_lot.inbound_order_item.inbound_order.code} (${value.inbound_order_item_lot.inbound_order_item.inbound_order.id})` : "-"}`}</div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">{`${value.calc_unit_price ? value.calc_unit_price : "-"}`}</div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">{`${value.po_item ? value.po_item.purchasing_order_id ? value.po_item.purchasing_order_id  :"-": "-"}`}</div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">{`${value.added_unit_cost ? value.added_unit_cost : "-"}`}</div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className={`col-break-word text-bold ${value.status ? "color-" + value.status.toLocaleLowerCase() : ""}`}>{`${value.status ? value.status : "-"}`}</div>
              </div>
            </div>

            {/* sm */}
            <div className="col-12 flex-custom-sm flex-custom-md-none flex-column flex-sm-row">
              {onDelete && (
                <div className="col-custom-4 col-custom-sm-100 px-1">
                  <Checkbox checked={is_item_in_delete_select !== -1 ? true : false} onChange={(e) => handleOnCheckDelete(e, value)} />
                </div>
              )}
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">
                  <span className="text-bold pr-2">วันที่รับสินค้าเข้า</span>
                  <span className="text-bold pr-2">:</span>
                  {`${value.created_at ? moment(value.created_at).format("DD-MM-YYYY") : "-"}`}
                </div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">
                  <span className="text-bold pr-2">Inventory Item ID</span>
                  <span className="text-bold pr-2">:</span>
                  {`${value.id ? value.id : "-"}`}
                </div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">
                  <span className="text-bold pr-2">Inbound Order ID</span>
                  <span className="text-bold pr-2">:</span>
                  {`${value.inbound_order_item_lot ? `${value.inbound_order_item_lot.inbound_order_item.inbound_order.code} (${value.inbound_order_item_lot.inbound_order_item.inbound_order.id})` : "-"}`}
                </div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">
                  <span className="text-bold pr-2">ราคาต้นทุนที่คำนวนจากขาเข้า</span>
                  <span className="text-bold pr-2">:</span>
                  {`${value.calc_unit_price ? value.calc_unit_price : "-"}`}
                </div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">
                  <span className="text-bold pr-2">Outbound Order ID</span>
                  <span className="text-bold pr-2">:</span>
                  {`${value.po_item ? value.po_item.purchasing_order_id ? value.po_item.purchasing_order_id  :"-": "-"}`}
                </div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">
                  <span className="text-bold pr-2">ราคาต้นทุนเพิ่มเติม</span>
                  <span className="text-bold pr-2">:</span> 
                  {`${value.added_unit_cost ? value.added_unit_cost : "-"}`}
                </div>
              </div>
              <div className="col-custom-12 col-custom-sm-100 px-1">
                <div className="col-break-word">
                  <span className="text-bold pr-2">สถานะ</span>
                  <span className="text-bold pr-2">:</span>
                  <span className={`col-break-word text-bold ${value.status ? "color-" + value.status.toLocaleLowerCase() : ""}`}>{`${value.status ? value.status : "-"}`}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  async function onClickConfirmDelete(values) {
    setIsLoading(true);
    let new_value = [];
    const itemList = selectedDelete.map((item) => item.id);

    const params = {
      inventory_item_list: itemList,
      note: values.note,
      status: "inactive",
    };

    await axios
      .put(`${process.env.REACT_APP_API_URL}/inventory_bulk`, params)
      .then(() => {  
        dataList.forEach((item) => {
          const index_of_data = selectedDelete.findIndex((select) => select.id === item.id);
          if (index_of_data !== -1) {
            item.status = "inactive";
          }
          new_value.push(item);
        });
        setIsLoading(false);
        setDataList(new_value);
      })
      .catch(() => {
        new_value = dataList;
        setIsLoading(false);
        setDataList(new_value);
      });

    formRef.current.resetFields();
    setSelectedDelete([]);
    setVisibleModal(false);
    setOnDelete(false);
  }

  function onCloseModal() {
    formRef.current.resetFields();
    setVisibleModal(false);
  }

  function DisplayModalAddItem() {
    const selected_delete_sort = selectedDelete.sort((a, b) => (a.item_id > b.item_id ? 1 : -1));
    return (
      <Modal className="modalstyle-dc" visible={visibleModal} closable={false} centered footer={false} onCancel={() => onCloseModal()}>
        <React.Fragment>
          <div className="text-bold text-center text-custom-18 mb-2">{`ลบสินค้า ${selected_delete_sort ? selected_delete_sort.length : 0} รายการ!`}</div>
          <div className="text-center mb-2">{itemName && itemName}</div>
          <Divider className="my-3" />
          <div className="row text-bold">
            <div className="col-12 d-flex flex-row align-items-center justify-content-center">
              <div className="col-custom-40 col-custom-sm-50 py-1 text-center justify-content-center">Inventory Item ID</div>
              <div className="col-custom-40 col-custom-sm-50 py-1 text-center justify-content-center">ราคาต้นทุน</div>
            </div>
          </div>
          {selected_delete_sort.map((selected) => {
            return (
              <div className="row">
                <div className="col-12 d-flex flex-row align-items-center justify-content-center">
                  <div className="col-custom-40 col-custom-sm-50 py-1 text-center justify-content-center">{`${selected.id ? selected.id : "-"}`}</div>
                  <div className="col-custom-40 col-custom-sm-50 py-1 text-center justify-content-center">{`${selected.calc_unit_price ? selected.calc_unit_price : "-"}`}</div>
                </div>
              </div>
            );
          })}
          <Divider className="my-3" />
          <Form layout="vertical" onFinish={onClickConfirmDelete} ref={formRef}>
            <Form.Item name="note" required={true} rules={[{ required: true, message: "กรุณากรอก เหตุผล/หมายเหตุ !" }]} label="เหตุผล/หมายเหตุ *">
              <TextArea placeholder="ระบุหมายเหตุ..." autoSize={{ minRows: 3, maxRows: 3 }} />
            </Form.Item>
            <Form.Item className="mb-0">
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  gap: 15,
                  marginTop: 10,
                }}
              >
                <Button htmlType="submit" type="primary">
                  Confirm
                </Button>
                <Button danger type="primary" onClick={() => onCloseModal()}>
                  Cancel
                </Button>
              </div>
            </Form.Item>
          </Form>
        </React.Fragment>
      </Modal>
    );
  }

  function onCancelManualDelete() {
    setOnDelete(false);
    setSelectedDelete([]);
  }

  function handlePageClick(data) {
    if (data.selected !== 0) searchParams["page"] = data.selected + 1;
    else delete searchParams["page"];  
    setPage(data.selected);  
  }



  return (
    <>
      <Spinner loading={isLoading} />
      <div className="offset-xl-1 col-xl-10 p-3 home-scrollable">
        <div className="row align-items-end">
          <div className="col-md-7 col-12 pb-2 d-md-flex flex-row  align-items-end">
            <span className="header-title   my-0">{APP_TITLE_DC_INVENTORIES_DEATIL}</span> 
            {code ? <h5 className="my-0 pl-0 pl-md-2 pr-2 color-darkblue">{code}</h5> : <h5 className="my-0 pl-0 pl-md-2 pr-2 color-darkblue">({itemName && itemName})</h5>}
            {/* <div className="d-flex align-items-end mb-1">
            <span
              className={`py-1 px-2 badge badge-pill badge-${stateColor} quo-state-tag text-truncate`}
            >
              {this.state.quotation.state}
            </span>
          </div> */}
          </div>
          <div className="col-md-5 col-12 text-right">
            {onDelete ? (
              <div className="row justify-content-end pb-3 pr-3">
                <div className="pr-2">
                  <Button
                    tyle="primary"
                    danger
                    className="antd-ghost-btn"
                    onClick={() => setVisibleModal(true)}
                    style={{
                      border: "none",
                      background: selectedDelete.length === 0 ? "gray" : "red",
                      color: "white",
                    }}
                    disabled={selectedDelete.length === 0}
                  >
                    <DeleteOutlined /> Delete
                  </Button>
                </div>
                <Button danger ghost className="antd-ghost-btn" onClick={onCancelManualDelete}>
                  <CloseOutlined /> Cancel
                </Button>
              </div>
            ) : (
              <div className="row justify-content-end pb-3 pr-3">
                <div className="pr-2">
                  <Button danger ghost className="antd-ghost-btn pr-2" onClick={() => setOnDelete(true)}>
                    <DeleteOutlined /> Manual Delete
                  </Button>
                </div>
                <NavLink to={`/dc_inventories_orders`}>
                  <Button type="primary" ghost className="antd-ghost-btn">
                    <RollbackOutlined /> Back
                  </Button>
                </NavLink>
              </div>
            )}
          </div>
        </div>
        <div className="card-box light p-3">
          <div className="row pb-2" style={{ alignItems: "center" }}>
            <div className="col-4 col-md-2 mt-2">
              <label>วันที่ออก</label>
            </div>
            <div className="col-8 col-md-4 mt-2 date-picker-wrapper">
              <DatePicker className="form-control" dateFormat={FORMAT_APP_DATE} selected={(routeDateFrom)?new Date(routeDateFrom):''} onChange={(e)=>{ setRouteDateFrom(e) }}    />
            </div>
            <div className="col-4 col-md-2 mt-2">
              <label>ถึง</label>
            </div>
            <div className="col mt-2 date-picker-wrapper"> 
              <DatePicker className="form-control" min={(routeDateFrom)?new Date(routeDateFrom):''} dateFormat={FORMAT_APP_DATE} selected={(routeDateTo)?new Date(routeDateTo):''} onChange={(e)=>{ setRouteDateTo(e) }}     />
            </div> 
          </div> 

          <div className="row pb-2" style={{ alignItems: "center" }}> 
            <div className="col-4 col-md-2 mt-2">
              <label>สถานะ</label>
            </div>
            <div className="col mt-2 date-picker-wrapper">
              <Select className="w-100"  placeholder="สถานะ..." value={routeStatus}  onChange={(e)=>{getStatusQuery(e)}}  >
                <Option value="" > ทั้งหมด</Option> 
                <Option value="active" > active</Option> 
                <Option value="reserved" > reserved</Option> 
                <Option value="delivered" > delivered</Option>  
                <Option value="inactive" > inactive</Option> 
                <Option value="deleted" > deleted</Option> 
                <Option value={null} > -</Option> 
              </Select>              
            </div> 
            <div className="col mt-2 date-picker-wrapper">
            <div class="col-12 col-md-2">
                    <div class="d-flex flex-row-reverse flex-md-row ">
                        <button onClick={(e)=>{getQuery(e)}} type="button" class="btn btn-primary ml-2">Search</button>
                        <button onClick={(e)=>{clearForm(e)}} type="button" class="btn btn-danger ml-2">Clear</button>
                    </div>
                </div>
            </div>
            
          </div>
      
        </div>
        <div className="d-flex justify-content-end my-3">
          <ReactPaginate
            forcePage={page}
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
            // key={this.state.resetKey}
          />
        </div>
        {DisplayTableHeader()}
        {DisplayTableItems()}
        {DisplayModalAddItem()}
      </div>
    </>
  );
}

import React from "react";
import axios from "axios";
import moment from "moment";
import { withRouter } from "react-router-dom";
import _ from "lodash";

// Components
import ClientStationReport from "./ClientStationReport";

// Functions
import { uniqueKey } from "../../../functions/Random";
import { getHeaderTotalPageCount } from "../../../functions/Response";
import { getDateFromToday } from "../../../functions/Date";

// Models
import { toExcelClientStationReport } from "../../../models/Reports";

import { ORDER_STATES, API_RESOURCES, FORMAT_DATE } from "../../../constants";

const DEFAULT_SORTING = "created_date";
const OPTIONS_STATES = [{ value: "pending order", label: "pending order" }];
Object.keys(ORDER_STATES).map((key) => {
  OPTIONS_STATES.push({ value: ORDER_STATES[key].name, label: ORDER_STATES[key].name });
});

let searchParams = {};

class ClientStationReportContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: false,
      selected_station: { id: null },
      selected_buyer: { id: null },
      selected_sorting: DEFAULT_SORTING,
      selected_created_date_form: getDateFromToday(-30),
      selected_created_date_to: new Date(),
      reports: [],
      selected_order_cate_id: null,
      is_refiltering: true,

      // pagination
      page: 0,
      per_page: 100,
      page_count: 1,

      resetKey: uniqueKey(),

      // export
      export_report: null,
      is_exporting: false,
      selectd_export: {},

      is_displaying_basket: false,
    };
  }

  componentDidMount() {
    if (this.props.location.search) {
      this.getPathParams();
    } else {
      this.getOrder();
    }
  }

  componentDidUpdate() {
    if (this.state.is_refiltering) {
      this.getOrder();
    }
  }

  getPathParams() {
    const station = new URLSearchParams(this.props.location.search).get("station") || null;
    const buyer = new URLSearchParams(this.props.location.search).get("buyer") || null;
    const start_date = new URLSearchParams(this.props.location.search).get("start_date") || "";
    const end_date = new URLSearchParams(this.props.location.search).get("end_date") || "";

    if (start_date) searchParams["start_date"] = start_date;
    if (end_date) searchParams["end_date"] = end_date;
    if (station) searchParams["station"] = station;
    if (buyer) searchParams["buyer"] = buyer;

    console.log("station :>> ", station);
    console.log("buyer", buyer); 

    this.setState({
      selected_station: station ? { id: parseInt(station) } : { id: null },
      selected_buyer: buyer ? { id: parseInt(buyer) } : { id: null },
      selected_date_form: start_date ? moment(start_date).toDate() : getDateFromToday(-30),
      selected_date_to: end_date ? moment(end_date).toDate() : new Date(),
      selected_created_date_form: start_date ? moment(start_date).toDate() : getDateFromToday(-30),
      selected_created_date_to  : end_date ? moment(end_date).toDate() : new Date(),
    });
 
  }

  getOrder(resetPage) {
    // filter: {}
    // range: [0,9]
    // sort: ["code","ASC"]
    let { page, per_page, selected_sorting, selected_sorting_reversed, selected_station, selected_created_date_form, selected_created_date_to, selected_buyer } = this.state;
    let de_per_page = per_page;
    let target_page = resetPage ? 0 : page;
    let range_start = target_page * de_per_page;
    let sort_dir = selected_sorting_reversed ? "DESC" : "ASC";
    let options = {
      sort: JSON.stringify([selected_sorting, sort_dir]),
      filter: {
        order_state: ["in progress", "completed"],
      },
      range: JSON.stringify([range_start, range_start + per_page - 1]),
    };
    if (selected_created_date_form || selected_created_date_to) {
      let submitted_start_date = selected_created_date_form ? moment(selected_created_date_form).format(FORMAT_DATE) : null;
      let submitted_end_date = selected_created_date_to ? moment(selected_created_date_to).format(FORMAT_DATE) : null;
      options.filter.created_at = [submitted_start_date, submitted_end_date];
    }
    if (selected_station.id) {
      options.filter.client_station_id = selected_station.id;
    }
    if (selected_buyer.id) {
      options.filter.buyer_id = selected_buyer.id;
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.po}/report_test`, {
        params: options,
      })
      .then((response) => {
        let page_count = getHeaderTotalPageCount(response, this.state.per_page);
        let export_report = toExcelClientStationReport(response.data);

        this.setState({
          reports: response.data,
          export_report: export_report,
          is_refiltering: false,
          page_count: page_count,
          resetKey: resetPage ? uniqueKey() : this.state.resetKey,
        });
      })
      .catch((error) => {
        this.setState({
          is_refiltering: false,
        });
      })
      .then(() => {
        // always executed
      });
  }

  onClickClear = () => {
    const { is_exporting } = this.state;
    this.setState({
      selected_period: "",
      selected_id: "",
      selected_station: { id: null },
      selected_buyer: { id: null },
      selected_sorting: DEFAULT_SORTING,
      selected_sorting_reversed: true,
      selected_created_date_form: getDateFromToday(-30),
      selected_created_date_to: new Date(),
      page: 0,
      is_refiltering: true,
      resetKey: uniqueKey(),
    });

    searchParams = {};
    if (!is_exporting) this.props.history.replace({ pathname: `${this.props.location.pathname}`, search: null });
  };

  onChangeSubmittedFromDate = (date) => {
    searchParams["start_date"] = moment(date).format("MM/DD/YYYY");
    this.setState({ selected_created_date_form: date });
  };

  onChangeSubmittedToDate = (date) => {
    searchParams["end_date"] = moment(date).format("MM/DD/YYYY");
    this.setState({ selected_created_date_to: date });
  };

  handleSubmit = (event) => {
    const { is_exporting } = this.state;
    event.preventDefault();

    if (!is_exporting) {
      this.addSearchParams(searchParams);
    }
    this.setState({ is_refiltering: true });
  };

  addSearchParams(params, path = this.props.location.pathname) {
    let searchParams = "";
    Object.keys(params).forEach((key, index) => {
      searchParams = searchParams + `${Object.keys(params).length > 1 && index !== 0 ? "&" : ""}${key}=${params[key]}`;
    });

    this.props.history.replace({ pathname: `${path}`, search: searchParams });
  }

  onClickSelectStation = (value, e) => {
    let selected_station = e;
    selected_station.id = value;

    searchParams["station"] = value;

    console.log("selected_station", selected_station);

    this.setState({ selected_station: selected_station });
  };

  onClickSelectBuyer = (value, e) => {
    let selected_buyer = e;
    selected_buyer.id = value;

    searchParams["buyer"] = value;
    this.setState({ selected_buyer: selected_buyer });
  };

  onClickExporting = () => {
    this.setState({ is_exporting: !this.state.is_exporting });
  };

  handleDisplayingBasket = (enable) => {
    this.setState({ is_displaying_basket: enable });
  };

  render() {
    console.log("selected_station :>> ", this.state.selected_station);

    return (
      <ClientStationReport
        {...this.state}
        onClickExporting={this.onClickExporting}
        onClickClear={this.onClickClear}
        onChangeSubmittedFromDate={this.onChangeSubmittedFromDate}
        onChangeSubmittedToDate={this.onChangeSubmittedToDate}
        handleSubmit={this.handleSubmit}
        onClickSelectStation={this.onClickSelectStation}
        onClickSelectBuyer={this.onClickSelectBuyer}
        handleDisplayingBasket={this.handleDisplayingBasket}
      />
    );
  }
}
export default withRouter(ClientStationReportContainer);

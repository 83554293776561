import React from "react";
import DatePicker from "react-datepicker";

// constants
import { FORMAT_APP_DATE } from '../../constants'

class CustomDatePicker extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <DatePicker
        className="form-control"
        dateFormat={FORMAT_APP_DATE}
        selected={this.props.selected ? new Date(this.props.selected) : null}
        onChange={this.props.onChange}
        disabled={this.props.disabled}
        // popperModifiers is for fixing React datepicker cutting calendar on mobile screen
        // https://stackoverflow.com/questions/54003152/react-datepicker-cutting-calendar-on-mobile-screen
        popperModifiers={{
          preventOverflow: {
            enabled: true,
          },
        }}
        //====
      />
    );
  }
}
export default CustomDatePicker;
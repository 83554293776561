import React from "react";
import Modal from "react-modal";

import { R_SCREEN_WIDTH } from "../../constants";

// Global component
import Number from "../global/Number";
import Decimal from "../global/Decimal";
import TooltipExclamationMark from "../global/TooltipExclamationMark";

// Functions
import { Multiple } from "../../functions/Number";

// Models
import { isDiscount, isCategoryDiscount, isCategoryFee, isItemDiscount, isItemExpense } from "../../models/Quotations";
import { getExpenseTotalCost, getExpenseUnit, getExpenseName } from "../../models/ItemCategories";

export class QuotationFormItemsShippingCost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_preview: false,
      selected_elm: null,
    };
    this.handleEditModal = this.handleEditModal.bind(this);
    this.handleOpenModalPreview = this.handleOpenModalPreview.bind(this);
    this.handleCloseModalPreview = this.handleCloseModalPreview.bind(this);
    this.onAddShippingCost = this.onAddShippingCost.bind(this);
  }

  handleEditModal(id) {
    this.setState({ modal_preview: true, selected_elm: id });
  }

  handleOpenModalPreview() {
    this.setState({ modal_preview: true });
  }

  handleCloseModalPreview() {
    this.setState({ modal_preview: false });
  }

  onAddShippingCost(id, index, item_category_id) {
    this.handleCloseModalPreview();
    this.props.onAddShippingCost(id, index, item_category_id);
  }

  shipBtn(elm, index) {
    if (!this.props.disabled) {
      if (isCategoryDiscount(elm)) {
        return false;
      }
      if (elm.is_new) {
        return (
          <button type="button" className="btn btn-success btn-sm ml-2" onClick={() => this.props.onAddShippingCost(elm.id, index + 1, elm.item_category_id)}>
            +
          </button>
        );
      } else {
        return (
          <button type="button" className="btn btn-danger btn-sm ml-2" onClick={() => this.props.onDeleteShippingCost(elm)}>
            X
          </button>
        );
      }
    }
  }

  inputField(elm, field, isNumber) {
    if (isNumber === true) {
      return <Number innerClass="text-right" name={field} value={elm[field]} onChange={(e) => this.props.onChangeShippingDetails(e, elm.id, elm.item_category_id)} disabled={this.props.disabled} />;
    } else {
      return <input className="form-control text-right" name={field} value={elm[field]} onChange={(e) => this.props.onChangeShippingDetails(e, elm.id, elm.item_category_id)} disabled={this.props.disabled}></input>;
    }
  }

  decimalField(elm, field) {
    return <Decimal innerClass="text-right" name={field} value={elm[field]} onChange={(e) => this.props.onChangeShippingDetails(e, elm.id, elm.item_category_id)} disabled={this.props.disabled} />;
  }

  addModal(elm, index) {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        overflow: "hidden",
        transform: "translate(-50%, -50%)",
      },
    };
    const deleteBtn = (elm) => {
      if (!isCategoryDiscount(elm)) {
        return (
          <button type="button" className={`btn btn-danger btn-sm w-100 ml-1 ${elm.is_new ? "d-none" : ""}`} onClick={() => this.props.onDeleteShippingCost(elm)} disabled={this.props.disabled}>
            ลบ
          </button>
        );
      } else {
        return false;
      }
    };
    return (
      <Modal isOpen={this.state.modal_preview && this.state.selected_elm === elm.id} contentLabel="onAddSpeacialFee" onRequestClose={this.handleCloseModalPreview} style={customStyles}>
        <div className="d-flex flex-column">
          <h5>ค่าบริการ</h5>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>บริการ</label>
            </div>
            <div className="d-flex-2">{elm.is_new ? this.inputField(elm, "name") : elm.name}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>จำนวน</label>
            </div>
            <div className="d-flex-3">{this.inputField(elm, "qty", true)}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>หน่วย</label>
            </div>
            <div className="d-flex-2">{elm.is_new ? this.inputField(elm, "unit") : elm.unit}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>ราคา</label>
            </div>
            <div className="d-flex-2">{this.decimalField(elm, "price")}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>รวม</label>
            </div>
            <div className="d-flex-2">{Multiple(elm.price, elm.qty).toFixed(2)}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>comment</label>
            </div>
            <div className="d-flex-2">{this.inputField(elm, "note")}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <button type="button" className="btn btn-success btn-sm w-100" onClick={() => (elm.is_new ? this.onAddShippingCost(elm.id, index + 1, elm.item_category_id) : this.handleCloseModalPreview())} disabled={this.props.disabled}>
              {elm.is_new ? "เพิ่ม" : "บันทึก"}
            </button>
            {deleteBtn(elm)}
            <button type="button" className="btn btn-secondary btn-sm w-100 ml-1" onClick={this.handleCloseModalPreview}>
              ยกเลิก
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  displayShippingCost(costByCate) {
    let { disabled, all_dup_expenses, items_in_cate, is_completed, quotationItemCategories, onChangeRemarkDetail } = this.props;

    let find_type_7 = null;

    costByCate.forEach((cate) => {
      if (cate.method_type === 7 && cate.qty > cate.from_value) {
        if (find_type_7) {
          if (cate.qty > find_type_7.from_value && find_type_7.from_value < cate.from_value) {
            find_type_7 = cate;
          }
        } else {
          find_type_7 = cate;
        }
      }
    });

    let items = costByCate
      .sort((a, b) => (a.position > b.position ? 1 : -1))
      .map((elm, index) => {
        // dont display if there is no fee and display some method is when have method type 7

        if ((find_type_7 && elm.method_type === 1) || (find_type_7 && elm.method_type === 2) || (find_type_7 && elm.method_type === 5) || (!find_type_7 && elm.method_type === 7) || (find_type_7 && elm.method_type === 7 && elm.from_value !== find_type_7.from_value)) return false;
        if ((elm.qty == 0 || elm.item_qty == 0) && !elm.is_custom_qty) return false;

        let total = getExpenseTotalCost(elm).toLocaleString();
        let row_style = isDiscount(elm) ? "row-discount" : "";
        let canceled_item_style = "";
        if (is_completed && isItemExpense(elm.resource_type)) {
          let target_item = items_in_cate.filter((temp) => temp.item_id === elm.resource_id)[0];
          canceled_item_style = target_item && !target_item.issued ? "text-line-through" : "";
        }

        console.log('elm', elm.is_new, elm.name)

        if (!elm.is_new || isCategoryDiscount(elm)) {



          //let is_duplicated_expense = isCategoryFee(elm) ? all_dup_expenses.filter(exp => (elm.id !== exp.id && elm.name === exp.name))[0] : false
          let is_duplicated_expense = isCategoryFee(elm) && all_dup_expenses[elm.id];
          return (
            <React.Fragment key={elm.id}>
              <a onClick={() => (window.innerWidth <= R_SCREEN_WIDTH.md && !disabled ? this.handleEditModal(elm.id) : false)}>
                <div className={`display-expense-container mt-2 border display-row ${row_style} ${canceled_item_style}`}>
                  <div></div>
                  <div className={`table-item-bg ${is_duplicated_expense ? "color-red" : ""} text-left`}>
                    {is_duplicated_expense ? <TooltipExclamationMark text="มีค่าบริการที่มีชื่อซ้ำกัน" iconBeforeText={true} /> : ""}
                    {getExpenseName(elm)}
                  </div>

                  <div className="d-none d-md-flex text-center">{this.inputField(elm, "qty", true)}</div>
                  <div className="d-none d-md-flex" style={{ justifyContent: "center" }}>
                    {getExpenseUnit(elm) || "-"}
                  </div>
                  <div className="d-none d-md-flex" style={{ marginRight: -10 }}>{this.decimalField(elm, "price")}</div>
                  <div className="d-none d-md-flex">
                    <div className="w-100 text-right">{total}</div>
                  </div>
                  <div className="table-item-bg col-12 col-md d-flex justify-content-end bg-ghostwhite d-md-none">
                    <h4 className="sign-baht color-darkgrayblue my-0">{total}</h4>
                  </div>
                  <div style={{ display: "flex", marginLeft: 7 }} className="d-none d-md-flex">
                    {this.inputField(elm, "note")}
                    {this.shipBtn(elm, index + 1)}
                  </div>
                </div>
              </a>
              {this.addModal(elm, index)}
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={elm.id}>
              <div className={`row mt-2 border`} style={{ alignItems: "start", background: "white" }}>
                <div className="col-1"></div>
                <div className={`table-item-bg col-3 col-md-3 pl-md-0 pr-0`} style={{ color: "black", fontWeight: "bold", textAlign: "right" }}>
                  หมายเหตุ
                </div>
                <div className="table-item-bg col-8 col-md-8 justify-content-center" style={{ padding: "10px 10px 10px 0px" }}>
                  <textarea className="form-control" type="text" name="cancel_note" defaultValue={quotationItemCategories?.remark} onChange={(e) => onChangeRemarkDetail(e, elm.id, elm.item_category_id)} rows="4" disabled={this.props.disabled}></textarea>
                </div>
              </div>
              {!this.props.disabled && (
                <div className="row mt-1 text-right">
                  <button type="button" className="btn btn-success btn-lg btn-sm d-none d-md-flex" onClick={() => this.handleEditModal(elm.id)} disabled={this.props.disabled}>
                    + เพิ่มค่าบริการ
                  </button>
                  <button type="button" className="btn btn-success btn-lg btn-sm w-100 d-md-none" onClick={() => this.handleEditModal(elm.id)} disabled={this.props.disabled}>
                    + เพิ่มค่าบริการ
                  </button>
                </div>
              )}
              {this.addModal(elm, index)}
            </React.Fragment>
          );
        }
      });
    return <div>{items}</div>;
  }

  render() {
    let { costByCate } = this.props;
    return <React.Fragment>{this.displayShippingCost(costByCate)}</React.Fragment>;
  }
}

import * as React from "react";
import {
  List, Datagrid, Edit, Create, SimpleForm, DeleteWithConfirmButton, TextField,
  EditButton, TextInput, BooleanInput, required, Button, Link, Tab, ReferenceManyField,
  FunctionField, useListContext, useNotify, TabbedForm, FormTab, useRedirect, useRefresh, ReferenceField, AutocompleteInput,
  ReferenceInput
} from 'react-admin';
import { withStyles } from "@material-ui/core/styles";
import BookIcon from '@material-ui/icons/Book';
import { getExpenseDetails } from '../../models/ItemCategories'
import { parse } from "query-string";

import { Form } from 'react-final-form';
import { Box, Button as CoreButton, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ContentFilter from "@material-ui/icons/FilterList";

import { exporter } from '../../functions/reactAdmin'

import { EditToolbar, EditActions } from './Default'


export const ContractItemCategoryIcon = BookIcon;

const ContractItemCategoryFilter = props => {
  return props.context === "button" ? (
    <ContractItemCategoryFilterButton {...props} />
  ) : (
    <ContractItemCategoryFilterForm {...props} />
  );
};

const ContractItemCategoryFilterButton = () => {
  const { showFilter } = useListContext();
  return (
      <CoreButton
          size="small"
          color="primary"
          onClick={() => showFilter("main")}
          startIcon={<ContentFilter />}
      >
          Filter
      </CoreButton>
  );
};

const ContractItemCategoryFilterForm = ({ open }) => {
  const {
      displayedFilters,
      filterValues,
      setFilters,
      hideFilter,
  } = useListContext();

  if (!displayedFilters.main) return null;

  const onSubmit = values => {
      if (Object.keys(values).length > 0) {
          setFilters(values);
      } else {
          hideFilter("main");
      }
  };

  const resetFilter = () => {
      setFilters({}, []);
  };

  return (
      <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
          {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
              <Box mt={8} />
              <Box display="flex" alignItems="flex-end" mb={1}>
              <Box component="span" mr={2}>
                  {/* Full-text search filter. We don't use <SearchFilter> to force a large form input */}
                  <TextInput
                  resettable
                  helperText={false}
                  source="sys_name"
                  label="Search ชื่อ"
                  InputProps={{
                      endAdornment: (
                      <InputAdornment>
                        <SearchIcon color="disabled" />
                      </InputAdornment>
                      )
                  }}
                  />
              </Box>
              <Box component="span" mr={2} mb={1.5}>
                  <CoreButton variant="outlined" color="primary" type="submit">
                  Filter
                  </CoreButton>
              </Box>
              <Box component="span" mb={1.5}>
                  <CoreButton variant="outlined" onClick={resetFilter}>
                  Close
                  </CoreButton>
              </Box>
              </Box>
          </form>
          )}
      </Form>
      </div>
  );
};


export const ContractItemCategoryList = (props) => (
  <List {...props} filters={<ContractItemCategoryFilter />} exporter={exporter}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField label="สัญญา" source="contract_id" reference="contracts" sortable={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="หมวดหมู่" source="item_category_id" reference="item_categories" sortable={false}>
        <TextField source="sys_name" />
      </ReferenceField>
      <EditButton basePath="/contract_item_categories" />
       <DeleteWithConfirmButton undoable={false}/>
    </Datagrid>
  </List>
);

const ContractItemCategoryTitle = ({ record }) => {
  return <span>ContractItemCategory {record ? `"${record.sys_name}"` : ''}</span>;
};

const styles = {
  button: {
    marginTop: "1em",
    marginBottom: "1em",
    color: "#fff",
    backgroundColor: "#3f51b5"
  },
};

const AddNewFeeButton = withStyles(styles)(({ classes, record }) => (
  <Button
    className={classes.button}
    variant="raised"
    component={Link}
    to={{
      pathname: "/contract_item_category_fees/create",
      search: `?contract_item_category_id=${record.id}`,
    }}
    label="Add a fee"
  >
  </Button>
));

const FeeDisplay = ({ basePath, record, resource }) => {
  var text = getExpenseDetails(record)

  return (
    <React.Fragment>
      {/* <FunctionField
        label="Types"
        render={record => `${ITEM_CATE_FEE_TYPES[record.fee_type]} - `}
      /> */}
      <FunctionField
        label="Price"
        render={record => text}
      />
    </React.Fragment>
  )
}

export const ContractItemCategoryEdit = props => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  return (
    <Edit {...props} undoable={false} actions={<EditActions />} title={<ContractItemCategoryTitle />}>
      <TabbedForm toolbar={<EditToolbar />}>
        <FormTab label="Item Category">
          <TextInput disabled source="id" />
          <ReferenceInput
            label="สัญญา"
            source="contract_id"
            reference="contracts"
            filterToQuery={searchText => ({ name: searchText })}
            validate={required()}
          >
            <AutocompleteInput optionText="name"/>
          </ReferenceInput>
          <ReferenceInput
            label="หมวดหมู่สินค้า"
            source="item_category_id"
            reference="item_categories"
            filterToQuery={searchText => ({ sys_name: searchText })}
            validate={required()}
          >
            <AutocompleteInput optionText="sys_name"/>
          </ReferenceInput>
        </FormTab>
        {/* <FormTab label="Fee">
          <ReferenceManyField
            addLabel={false}
            reference="contract_item_category_fees"
            target="contract_item_category_id"
            sort={{ field: 'position', order: 'ASC' }}
          >
            <Datagrid>
              <TextField source="position" label="ตำแหน่ง"/>
              <TextField source="name"/>
              <FeeDisplay />
              <EditButton />
              <DeleteWithConfirmButton
                {...props}
                onSuccess={(response) => {
                  redirect(`/contract_item_categories/${response.data.contract_item_category_id}/1`);
                  refresh();
                }}
              />
            </Datagrid>
          </ReferenceManyField>
          <AddNewFeeButton />
        </FormTab> */}
      </TabbedForm>
    </Edit>
  );
}

export const ContractItemCategoryCreate = props => {
  const { contract_id: contract_id_string, for_mr: for_mr_string } = parse(props.location.search);
  const contract_id = contract_id_string ? parseInt(contract_id_string, 10) : "";
  const for_mr = (for_mr_string === "true") ? true : false
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({data}) => {
    redirect(`/contracts/${data.contract_id}/1`);
    window.location.reload(false);
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm defaultValue={{ contract_id, for_mr }} toolbar={<EditToolbar />}>
        <BooleanInput label="สำหรับใบสั่งจ้างผลิต" source="for_mr"/>
        <ReferenceInput
          label="สัญญา"
          source="contract_id"
          reference="contracts"
          filterToQuery={searchText => ({ name: searchText })}
          validate={required()}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput
          label="หมวดหมู่สินค้า"
          source="item_category_id"
          reference="item_categories"
          filterToQuery={searchText => ({ sys_name: searchText })}
          validate={required()}
        >
          <AutocompleteInput optionText="sys_name"/>
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
};
import React from 'react';
import MrIndex from '../components/mr/MrIndex';
import { MrForm } from '../components/mr/MrForm';

import '../styles/quotation.css';


export class Mr extends React.Component {
  constructor(props) {
    super(props)
    const urlParams = new URLSearchParams(props.location.search)
    const selected_state = urlParams.get('state')
    const selected_date_from = urlParams.get('date_from')
    const selected_date_to = urlParams.get('date_to')
    let selected_id = props.match.params.id
    this.state = {
      current_page: selected_id === "new" ? null : selected_id,
      state: selected_state || "",
      link_date_from : selected_date_from || "",
      link_date_to : selected_date_to || ""
    }

    this.onChangeMrPage = this.onChangeMrPage.bind(this)
  }

  onChangeMrPage(page) {
    this.setState({current_page: page})
  }

  render() {
    if (this.state.current_page === undefined) {
      return (
        <MrIndex
          state = {this.state.state}
          link_date_from = {this.state.link_date_from}
          link_date_to = {this.state.link_date_to}
          onChangeMrPage = {this.onChangeMrPage}
        />
      );
    } else {
      return (
        <MrForm
          {...this.props}
          id = {this.state.current_page}
          onChangeMrPage = {this.onChangeMrPage}
        />
      );
    }
  }
}
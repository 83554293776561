import React from 'react';
import Modal from 'react-modal';
import _ from 'lodash';
import { MODAL_STYLE_LARGE } from '../../../../constants'

// Components
import ExportModal from './ExportModal'

// Functions
import { formatDate } from '../../../../functions/Date'

import { totalSalesWithVat, getClientStationName } from '../../../../models/Quotations'

export default function ExportProcurementModal(props) {
  const { selected_orders } = props
  const keys = Object.keys(selected_orders)
  return(
    <Modal
      isOpen={props.is_displaying_basket}
      contentLabel="onImportProcurement"
      onRequestClose={() => props.handleDisplayingBasket(false)}
      style={MODAL_STYLE_LARGE}>
      <div className="order-modal-basket p-4">
        <div className="d-flex flex-row mb-2">
          <h5>รายการ Export ทั้งหมด ({keys.length} รายการ)</h5>
          <div className="ml-auto">
            <ExportModal
              {...props}
              btnClass={"btn btn-primary"}
            />
          </div>
          <button type="button" className="btn btn-danger ml-2" onClick={props.onClearSelectedOrders}>ล้าง</button>
          <button type="button" className="btn btn-outline-secondary ml-2" onClick={() => props.handleDisplayingBasket(false)}>Close</button>
        </div>
        <div className="order-list">
        {keys.map((key, index) => {
          let elm = selected_orders[key]
          let total_with_vat = totalSalesWithVat(elm.total_sales)
          return (
            <div className="card-box mt-1 link-no-hover order" key={elm.no + index}>
              <div className="row p-3">
                <div className="col d-none d-md-flex pr-0 flex-column flex-md-row">
                  <div className="d-flex-1">{formatDate(elm.po_date)}</div>
                  <div className="d-flex-2">{getClientStationName(elm.quotation)}</div>
                  <div className="d-flex-1">{elm.code}</div>
                  <div className="d-flex-1">{elm.quotation.code}</div>
                  <div className="d-flex-1">{elm.state}</div>
                  <div className="d-flex-1 d-flex flex-row justify-content-between">
                    <div>{total_with_vat.toLocaleString()}</div>
                    <button type="button" className="btn btn-danger btn-sm mx-2 my-auto" onClick={(e) => {e.stopPropagation();props.onClickOrder(elm.id)}}>delete</button>
                  </div>
                </div>
                <div className="col-12 d-md-none pr-0 py-1 d-flex flex-column flex-md-row bg-lightyellow01">
                  <div className="d-flex-2">{getClientStationName(elm.quotation)}</div>
                </div>
                <div className="col d-md-none pr-0 d-flex flex-column flex-md-row">
                  <div className="d-flex-1 color-darkgray01">{formatDate(elm.po_date)}</div>
                  <div className="d-flex-1 color-darkgray01">{elm.code}</div>
                  <div className="d-flex-1 color-darkgray01">{elm.quotation.code}</div>
                  <div className="d-flex-1 color-darkgray01">{elm.state}</div>
                  <div className="d-flex-1 color-darkgray01">{total_with_vat.toLocaleString()} บาท</div>
                </div>
                <div className="col-3 col-md-1 pl-0 d-md-none d-flex flex-column flex-md-row align-self-end">
                  <button type="button" className="btn btn-danger btn-sm ml-2 my-auto" onClick={(e) => {e.stopPropagation();props.onClickOrder(elm.id)}}>delete</button>
                </div>
              </div>
            </div>
          )
        })}
        </div>
      </div>
    </Modal>
  )
} 
import React, { useState, useEffect } from "react";
import { Checkbox } from "antd";

export default function AddItemModal({ categoty_name, items, onCheckMultiSelect, isReset, setIsReset, key }) {
  const [childSelect, setChildSelect] = useState([]);

  useEffect(() => {
    if (isReset) {
      resetSelected();
    }
  });

  function resetSelected() {
    let checkList = [...childSelect];
    checkList.forEach((checkItem) => {
      onCheckMultiSelect(checkItem, "remove");
    });

    setIsReset(false);
    setChildSelect([]);
  }

  function handleChildChecked(e, item, isHeadSelect = false) {
    let checkList = [...childSelect];

    if (isHeadSelect) {
      if (e.target.checked) {
        checkList = items;
        items.forEach((checkItem) => {
          onCheckMultiSelect(checkItem, "add");
        });
      } else {
        items.forEach((checkItem) => {
          onCheckMultiSelect(checkItem, "remove");
        });
        checkList = [];
      }
    } else {
      if (e && e.target.checked) {
        checkList.push(item);
        onCheckMultiSelect(item, "add");
      } else {
        let isCheckdIndex = childSelect.findIndex((select) => select.id === item.id);
        if (isCheckdIndex !== -1) {
          checkList.splice(isCheckdIndex, 1);
          onCheckMultiSelect(item, "remove");
        } else {
          checkList.push(item);
          onCheckMultiSelect(item, "add");
        }
      }
    }

    setChildSelect(checkList);
  }

  return (
    items.length > 0 && (
      <React.Fragment key={key}>
        <div style={{ borderRadius: 10, marginBottom: 5 }} className="card-box no-round mt-2 p-3 bg-lightblue03 font-weight-bold cursor-pointer">
          <div className="add-modal-header-expense-container">
            <div className="category-name-select-content">
              <Checkbox onClick={(e) => handleChildChecked(e, [], true)} checked={childSelect.length === items.length} />
              {categoty_name}
            </div>
            <div>
              <i className="icon-arrow-up"></i>
            </div>
          </div>
        </div>
        {items.map((item) => {
          let isIndex = childSelect.findIndex((select) => select.id === item.id);
          return (
            <div className="item-name-select-content" style={{ padding: "5px 16px", marginBottom: 5 }} onClick={() => handleChildChecked(false, item)}>
              <Checkbox checked={isIndex !== -1 ? true : false} onClick={(e) => handleChildChecked(e, item)} />
              <div>{item.code}</div>
              <div>{item.name}</div>
            </div>
          );
        })}
      </React.Fragment>
    )
  );
}

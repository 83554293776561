import React from "react";
import Modal from "react-modal";
import _ from "lodash";
import { MODAL_STYLE_LARGE } from "../../../constants";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";
// Components
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export default function ExportProcurementModal(props) {
  return (
    <Modal isOpen={props.isVisible} contentLabel="onImportProcurement" onRequestClose={() => props.handleCancel(false)} style={MODAL_STYLE_LARGE}>
      <div className="order-modal-basket p-1">
        <div className="d-flex flex-row">
          <h5>รายการเปลี่ยนแปลง ({props.logList && props.logList.length} รายการ)</h5>
          <div className="ml-auto">
            <button type="button" className="btn btn-outline-secondary ml-2" onClick={() => props.handleCancel(false)}>
              Close
            </button>
          </div>
        </div>
        <div className="d-block d-md-flex" style={{ justifyContent: "space-between" }}>
          <div className="mt-2">
            <input placeholder="ค้นหา Vendor, สินค้า" onKeyDown={props.handleLogSearch} style={{ borderRadius: 10, border: "1px solid #dee2e6", height: 40, padding: 10 }} />
            <button type="submit" className="btn btn-primary ml-2" onClick={props.handleClickSearch}>
              Search
            </button>
          </div>
          <div style={{ marginTop: 20 }}>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={props.page_count}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={props.getLogChangeLog}
              containerClassName={"pagination"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
              key={"pagination"}
            />
          </div>
        </div>
        <div className="d-none d-md-block mt-4">
          <div className="col-24 px-3 py-1" style={{ height: 68, textAlign: "center", borderRadius: 10, background: "white", display: "grid", gap: 20, gridTemplateColumns: "12% 20% 20% 20% 15%", border: "1px solid #e8e8e8" }}>
            <div className="dashboard_installation">Order</div>
            <div className="dashboard_installation">Vendor</div>
            <div className="dashboard_installation">สินค้า</div>
            <div className="dashboard_installation">วันติดตั้งก่อนหน้า > ปัจจุบัน</div>
            <div className="dashboard_installation">วันที่แจ้ง</div>
          </div>
        </div>
        <div className="order-list" style={{ height: "65vh" }}>
          <div className="row mt-2" style={{ flexDirection: "column", gap: 10, padding: "0 20px" }}>
            {props.logList &&
              props.logList.map((log, index) => {
                return (
                  <>
                    <div key={index} className="row d-md-none" style={{ flexDirection: "column", gap: 10, padding: "0 20px" }}>
                      <Link className="custom-link" to={`/${log.type === "purchasing" ? props.API_RESOURCES.order : props.API_RESOURCES.quotation}/${log.order_id}`}>
                        <div
                          className="col-12 col-sm-12 col-md-3 px-3 py-1 h-100 "
                          style={{ cursor: "pointer", textAlign: "left", borderRadius: 10, background: "white", display: "grid", gap: 10, gridTemplateColumns: "140px auto", border: "1px solid #e8e8e8" }}
                        >
                          <div style={{ textAlign: "right", fontWeight: "bold" }}>
                            <div className="ellipsis">Order : </div>
                            <div className="ellipsis">Vendor : </div>
                            <div className="ellipsis">สินค้า : </div>
                            <div className="ellipsis">วันติดตั้งก่อนหน้า > ปัจจุบัน : </div>
                            <div className="ellipsis">วันที่แจ้ง : </div>
                          </div>
                          <div className="ellipsis" style={{ width: "100%" }}>
                            <OverlayTrigger
                              key={index}
                              placement={"bottom"}
                              overlay={
                                <Tooltip id={`tooltip-${index}`}>
                                  <div>{log.order_code || "-"}</div>
                                </Tooltip>
                              }
                            >
                              <div className="ellipsis">{log.order_code || "-"}</div>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key={index}
                              placement={"bottom"}
                              overlay={
                                <Tooltip id={`tooltip-${index}`}>
                                  <div>{log.vendor_name}</div>
                                </Tooltip>
                              }
                            >
                              <div className="ellipsis">{log.vendor_name}</div>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key={index}
                              placement={"bottom"}
                              overlay={
                                <Tooltip id={`tooltip-${index}`}>
                                  <div>{log.item_name}</div>
                                </Tooltip>
                              }
                            >
                              <div className="ellipsis">{log.item_name}</div>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key={index}
                              placement={"bottom"}
                              overlay={
                                <Tooltip id={`tooltip-${index}`}>
                                  <div>{log.prev_installation_date !== "None" && log.new_installation_date !== "None" ? `${log.prev_installation_date} > ${log.new_installation_date}` || "-" : "-"}</div>
                                </Tooltip>
                              }
                            >
                              <div className="ellipsis">{log.prev_installation_date !== "None" && log.new_installation_date !== "None" ? `${log.prev_installation_date} > ${log.new_installation_date}` || "-" : "-"}</div>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key={index}
                              placement={"bottom"}
                              overlay={
                                <Tooltip id={`tooltip-${index}`}>
                                  <div>{`${moment(log.created_at).format("YYYY-MM-DD")} (${log.owner || "-"})`}</div>
                                </Tooltip>
                              }
                            >
                              <div className="ellipsis">{`${moment(log.created_at).format("YYYY-MM-DD")} (${log.owner || "-"})`}</div>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="d-none d-md-block">
                      <Link className="custom-link" to={`/${log.type === "purchasing" ? props.API_RESOURCES.order : props.API_RESOURCES.quotation}/${log.order_id}`}>
                        <div
                          className="col-24 px-3 py-1 h-100"
                          style={{ cursor: "pointer", textAlign: "center", borderRadius: 10, background: "white", display: "grid", gap: 20, gridTemplateColumns: "12% 20% 20% 20% 15%", border: "1px solid #e8e8e8" }}
                        >
                          <OverlayTrigger
                            key={index}
                            placement={"bottom"}
                            overlay={
                              <Tooltip id={`tooltip-${index}`}>
                                <div>{log.order_code || "-"}</div>
                              </Tooltip>
                            }
                          >
                            <div className="ellipsis">{log.order_code || "-"}</div>
                          </OverlayTrigger>
                          <OverlayTrigger
                            key={index}
                            placement={"bottom"}
                            overlay={
                              <Tooltip id={`tooltip-${index}`}>
                                <div>{log.vendor_name}</div>
                              </Tooltip>
                            }
                          >
                            <div className="ellipsis">{log.vendor_name}</div>
                          </OverlayTrigger>
                          <OverlayTrigger
                            key={index}
                            placement={"bottom"}
                            overlay={
                              <Tooltip id={`tooltip-${index}`}>
                                <div>{log.item_name}</div>
                              </Tooltip>
                            }
                          >
                            <div className="ellipsis">{log.item_name}</div>
                          </OverlayTrigger>
                          <OverlayTrigger
                            key={index}
                            placement={"bottom"}
                            overlay={
                              <Tooltip id={`tooltip-${index}`}>
                                <div>{log.prev_installation_date !== "None" && log.new_installation_date !== "None" ? `${log.prev_installation_date} > ${log.new_installation_date}` || "-" : "-"}</div>
                              </Tooltip>
                            }
                          >
                            <div className="ellipsis">{log.prev_installation_date !== "None" && log.new_installation_date !== "None" ? `${log.prev_installation_date} > ${log.new_installation_date}` || "-" : "-"}</div>
                          </OverlayTrigger>
                          <OverlayTrigger
                            key={index}
                            placement={"bottom"}
                            overlay={
                              <Tooltip id={`tooltip-${index}`}>
                                <div>{`${moment(log.created_at).format("YYYY-MM-DD")} (${log.owner || "-"})`}</div>
                              </Tooltip>
                            }
                          >
                            <div className="ellipsis">{`${moment(log.created_at).format("YYYY-MM-DD")} (${log.owner || "-"})`}</div>
                          </OverlayTrigger>
                        </div>
                      </Link>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </Modal>
  );
}

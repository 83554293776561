import React from "react";
import Select from "react-select";
import _ from "lodash";

// Models
import { sortItemByCate, getWarrantyCode } from "../../../models/Orders";

// Components
import { OrderFormOtherInfo } from "../OrderFormOtherInfo";
import { OrderFormPurchasePriceItems } from "../OrderFormPurchasePriceItems";
import ToggleSwitch from "../../global/ToggleSwitch";

// Functions
import { Multiple } from "../../../functions/Number";
import { parseExpRatioArr } from "../../../functions/FeePurchaseCalculation";
import { formatDate } from "../../../functions/Date";
import { stringIsEmpty } from "../../../functions/String";
import { OrderFormCostPriceItems } from "./OrderFormCostPriceItems";

export class OrderFormCostPrice extends React.Component {
  constructor(props) {
    super(props);
    let completed = props.order.order_flag.purchase_price_completed;
    this.state = {
      items: [],
      vendor: {},
      completed: completed,
      disabled: completed || props.disabled,
    };

    this.onChangeVendor = this.onChangeVendor.bind(this);
    this.onChangeContractPrice = this.onChangeContractPrice.bind(this);
  }

  componentDidMount() {
    // let { items } = this.props.order
    // let de_items = items.map((item) => {
    //   item.price = TEMP_ITEM_PRICES.filter(elm => (elm.item_id === item.value && elm.zone_id === 1))[0].price
    //   return item
    // })
    // this.setState({ items: de_items })
  }

  onChangeVendor(option, cate_id) {
    // let { items } = this.state
    // let selected_item = items.filter(elm => elm.value === id)[0]
    // selected_item.vendor = option
    // selected_item.price = option.price
    // this.setState({ items: items })
  }

  onChangeContractPrice(e, id) {
    let { items } = this.state;
    let selected_item = items.filter((elm) => elm.value === id)[0];
    selected_item.contract_price = e.target.value;

    this.setState({ items: items });
  }

  displayItems() {
    let items = [];
    let list_index = 0;
    this.props.order.purchasing_order_items.sort(sortItemByCate).map((elm, index) => {
      let expense_group = {};
      if (!elm.is_mbom) {
        if (elm.expense_ratio_arr) {
          expense_group = _.groupBy(elm.expense_ratio_arr, function (value) {
            return value;
          });
        } else {
          expense_group[elm.expense_ratio] = [elm.expense_ratio];
        }
        for (var key in expense_group) {
          let exp_ratio = parseExpRatioArr(key);
          let price_with_fee = elm.contract_price + exp_ratio;
          let de_qty = elm.expense_ratio_arr ? expense_group[key].length : elm.qty;
          items.push(
            <React.Fragment>
              <div className={`p-2 ${list_index % 2 === 0 ? "bg-card-white" : "bg-aliceblue"}`}>
                <div className="row px-3">
                  <div className="col-1 m-auto text-center">{list_index + 1}</div>
                  <div className="col-5">{elm.name}</div>
                  <div className="col-2 text-right">{`${de_qty} ${elm.unit}`}</div>
                  <div className="col-2 text-right">{price_with_fee.toLocaleString()}</div>
                  <div className="col-2 text-right">{Multiple(price_with_fee, de_qty).toLocaleString()}</div>
                </div>
              </div>
            </React.Fragment>
          );
        }
        list_index++;
      }
    });
    return items;
  }

  displayHeader() {
    return (
      <React.Fragment>
        <div className="row px-3">
          <div className="col-1 m-auto text-center">ลำดับ</div>
          <div className="col-5">สินค้า</div>
          <div className="col-2 text-right">จำนวน</div>
          <div className="col-2 text-right">ราคา</div>
          <div className="col-2 text-right">ราคารวม</div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { order } = this.props;
    const { disabled } = this.state;
    const disabled_contract = order.purchasing_order_items.reduce((pre, cur) => {
      return pre || !stringIsEmpty(getWarrantyCode(cur.warranty_codes));
    }, false);
    return (
      <React.Fragment>
        <div className="col quo-scrollable-table">
          <div className="row px-4 pt-1 pb-4">
            <div className="col-8">
              <h5 className="pb-1">{`สรุปราคาขาซื้อ (ต่อหน่วย)  - วันที่ดึงสัญญา ${formatDate(order.quotation.approved_date)}`}</h5>
            </div>
            <div className="col-4">
              <div className="row align-items-center justify-content-end pr-3">
                <div className="pr-1">ราคาทุนสมบูรณ์แล้ว</div>
                <ToggleSwitch checked={this.state.completed} onChange={this.props.confirmPurchasePrices} />
              </div>
            </div>
            <div className="col-12">
              <div className="card-box p-2 d-none d-md-block">{this.displayHeader()}</div>
              <div className="d-none d-md-block mt-1">{this.displayItems()}</div>
            </div>
          </div>

          <div className="row px-4 pt-1 pb-4">
            <div className="col-12">
              <h5 className="pb-1">สรุปราคาขาซื้อ (ต่อหน่วย)</h5>
              <div className="col d-flex flex-row justify-content-end">
                <div className="mr-3">ราคาทุนสมบูรณ์แล้ว</div>
                <ToggleSwitch checked={this.state.completed} onChange={this.props.confirmPurchasePrices} />
              </div>
              {disabled_contract ? (
                <div className="color-red">
                  <b>คำเตือน</b>: ถ้ามีการออกรหัสทรัพย์สินแล้ว จะไม่สามารถเปลี่ยนสัญญาที่มีสินค้าที่ออกรหัสทรัพย์สินแล้วได้
                </div>
              ) : null}
            </div>
          </div>

          <div className="row px-4 pt-1">
            <OrderFormCostPriceItems {...this.props} onChangeVendor={this.onChangeVendor} vendor={this.state.vendor} disabled={disabled} />
          </div>

          {/* Other Info */}
          <div className="px-4 pt-1 pb-4">
            <OrderFormOtherInfo
              page_nb={this.props.page_nb}
              note={this.props.order.order_notes.filter((elm) => elm.page_nb === this.props.page_nb)[0]}
              onChangeNote={this.props.onChangeNote}
              disabled={this.props.disabled}
              key={this.props.resetKeyNote}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

import React from "react";
import DatePicker from "react-datepicker";
import _ from "lodash";
import { FORMAT_APP_DATE } from "../../../constants.js";

// Models

// Component
import { OrderFormOtherInfo } from "../OrderFormOtherInfo";

export class OrderFormDelivery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { order, disabled, warning } = this.props;
    return (
      <React.Fragment>
        <div className="color-red mb-3">{warning}</div>

        <h5 className="pb-1">Delivery</h5>
        <div className="card-box p-3 mt-1">
          <div className="row">
            <div className="col-4 col-md-2 pr-0 mt-2">
              <label>เลขที่ Delivery</label>
            </div>
            <div className="col-8 col-md-4 date-picker-wrapper mt-2">
              <input
                type="id"
                className="form-control"
                id="order-db-delivery-code"
                name="db_delivery_code"
                onChange={this.props.onChangeOrderDetails}
                value={order.db_delivery_code}
                disabled={disabled}
              ></input>
            </div>
            <div className="col-4 col-md-2 pr-0 mt-2">
              <label>วันที่ได้รับ</label>
            </div>
            <div className="col-8 col-md-4 date-picker-wrapper mt-2">
              <DatePicker
                className="form-control"
                dateFormat={FORMAT_APP_DATE}
                selected={
                  order.db_delivery_date
                    ? new Date(order.db_delivery_date)
                    : null
                }
                onChange={(date) =>
                  this.props.onChangeOrderDate(date, "db_delivery_date")
                }
                disabled={disabled}
              />
            </div>
          </div>
        </div>

        {/* Other Info */}
        <OrderFormOtherInfo
          page_nb={this.props.page_nb}
          note={
            this.props.order.order_notes.filter(
              (elm) => elm.page_nb === this.props.page_nb
            )[0]
          }
          onChangeNote={this.props.onChangeNote}
          disabled={this.props.disabled}
          key={this.props.resetKeyNote}
        />
        <div className="block-info-buffer-box"></div>
      </React.Fragment>
    );
  }
}

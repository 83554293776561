import React from "react";
import { Multiple, Division } from "../functions/Number";
import { objectIndexOf } from "../functions/Object";
import { addYears, addMonths, addDays } from "../functions/Date";
import { isDiscount } from "../models/Quotations";

export const ITEM_CATE_FEE_TYPES = {
  1: "ค่าติดตั้ง",
  2: "ค่าขนส่ง",
};

export const ITEM_CATE_METHOD_TYPES = {
  1: "เหมาระยะทาง",
  2: "คิดตามระยะทางต่อ กิโลเมตร",
  3: "คิดราคาต่อ หน่วย",
  5: "คิดตามระยะทางต่อ กิโลเมตร และ ต่อหน่วย",
  6: "คิดราคาเป็นแพ็ค ตามขั้นบันได",
  7: "คิดตามระยะทางต่อ กิโลเมตร แบบเหมา",
  //4: 'อื่นๆ'
};

export const WARRANTY_TYPES = {
  1: "ปี",
  2: "เดือน",
  3: "วัน",
};

export const WARRANTY_OPTIONS = Object.keys(WARRANTY_TYPES).map((key) => {
  return { value: key, label: WARRANTY_TYPES[key] };
});

export function method_type_example_usages(method_type) {
  if (method_type == 1) {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 ml-3">
            <li>ตัวอย่าง 1: ค่าขนส่งพื้นที่เขตกรุงเทพและปริมณฑล 50 กม.แรกเหมา 8,000 บาท</li>
          </div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง วิธีการคำนวณ: {ITEM_CATE_METHOD_TYPES[1]}</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ตั้งแต่: 0</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ถึง: 50</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ราคา: 8000</div>
        </div>
      </React.Fragment>
    );
  } else if (method_type == 2) {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 ml-3">
            <li>ตัวอย่าง 1: ค่าเดินทาง ระยะทาง 1-500 กม. ราคา 15 บาท/กม.</li>
          </div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง วิธีการคำนวณ: {ITEM_CATE_METHOD_TYPES[2]}</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ตั้งแต่: 1</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ถึง: 500</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ราคา: 15</div>
        </div>
        <div className="row mt-2">
          <div className="col-12 ml-3">
            <li>ตัวอย่าง 2: ค่าเดินทาง ระยะทางตั้งแต่ 501 กม. ขึ้นไป ราคา 10 บาท/กม.</li>
          </div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง วิธีการคำนวณ: {ITEM_CATE_METHOD_TYPES[2]}</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ตั้งแต่: 501</div>
          <div className="col-12 ml-5 color-darkgrayblue">
            ช่อง ถึง: <span className="color-red">ไม่ต้องใส่ เว้นว่างไว้</span>
          </div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ราคา: 10</div>
        </div>
      </React.Fragment>
    );
  } else if (method_type == 3) {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 ml-3">
            <li>ตัวอย่าง 1: ค่าติดตั้งขอบหลังคาต่อเมตร ราคา 820 บาท</li>
          </div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง วิธีการคำนวณ: {ITEM_CATE_METHOD_TYPES[3]}</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ตั้งแต่: 1</div>
          <div className="col-12 ml-5 color-darkgrayblue">
            ช่อง ถึง: <span className="color-red">ไม่ต้องใส่ เว้นว่างไว้</span>
          </div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ราคา: 820</div>
        </div>
      </React.Fragment>
    );
  } else if (method_type == 5) {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 ml-3">
            <li>ตัวอย่าง 1: ค่าขนส่งกิโลเมตรที่ 1-50 ราคา 25 บาท/กม./ชิ้น</li>
          </div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง วิธีการคำนวณ: {ITEM_CATE_METHOD_TYPES[5]}</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ตั้งแต่: 1</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ถึง: 50</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง จำนวนชิ้น ต่อหน่วย: 1</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ราคา: 25</div>
        </div>
        <div className="row mt-2">
          <div className="col-12 ml-3">
            <li>ตัวอย่าง 2: ค่าขนส่งถังน้ำมันใต้ดินกิโลเมตรที่ 101 เป็นต้นไป ราคา 22 บาท/กม./ถัง</li>
          </div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง วิธีการคำนวณ: {ITEM_CATE_METHOD_TYPES[5]}</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ตั้งแต่: 101</div>
          <div className="col-12 ml-5 color-darkgrayblue">
            ช่อง ถึง: <span className="color-red">ไม่ต้องใส่ เว้นว่างไว้</span>
          </div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง จำนวนชิ้น ต่อหน่วย: 1</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ราคา: 22</div>
        </div>
        <div className="row mt-2">
          <div className="col-12 ml-3">
            <li>
              ตัวอย่าง 3: ค่าขนส่ง Kiosk ตั้งแต่ กม.ที่ 51 เป็นต้นไป ราคา 28 บาท/กม./<span className="color-red">2</span> ชิ้น
            </li>
          </div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง วิธีการคำนวณ: {ITEM_CATE_METHOD_TYPES[5]}</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ตั้งแต่: 51</div>
          <div className="col-12 ml-5 color-darkgrayblue">
            ช่อง ถึง: <span className="color-red">ไม่ต้องใส่ เว้นว่างไว้</span>
          </div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง จำนวนชิ้น ต่อหน่วย: 2</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ราคา: 28</div>
        </div>
      </React.Fragment>
    );
  } else if (method_type == 6) {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 ml-3">
            <li>ตัวอย่าง 1: ค่าส่งสั่ง 1 ชุด ราคา 55 บาท</li>
          </div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง วิธีการคำนวณ: {ITEM_CATE_METHOD_TYPES[6]}</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ตั้งแต่: 1</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ถึง: 1</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ราคา: 55</div>
        </div>
        <div className="row mt-2">
          <div className="col-12 ml-3">
            <li>ตัวอย่าง 1: ค่าส่งสั่ง 2-3 ชุด ราคา 110 บาท</li>
          </div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง วิธีการคำนวณ: {ITEM_CATE_METHOD_TYPES[6]}</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ตั้งแต่: 2</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ถึง: 3</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ราคา: 110</div>
        </div>
      </React.Fragment>
    );
  } else if (method_type == 7) {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 ml-3">
            <li>ตัวอย่าง 1: ค่าส่งสั่ง 1 ชุด ราคา 55 บาท</li>
          </div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง วิธีการคำนวณ: {ITEM_CATE_METHOD_TYPES[7]}</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ตั้งแต่: 500</div>
          <div className="col-12 ml-5 color-darkgrayblue">ช่อง ราคา: 8</div>
          <div className="col-12 ml-5 color-darkgrayblue">การทำงาน : จะทำการยกเลิกการส่งแบบระยะทางก่อนหน้าที่ถูกกำหนดไว้ และทำการใช้รูปบบการขนส่งนี้เท่านั้น</div>
        </div>
      </React.Fragment>
    );
  }
}

export function getExpenseDetails(elm) {
  if (elm === undefined) {
    return "";
  }

  let text = "";
  //======= Method 1 distance_based ========
  if (elm.method_type === 1) {
    text = "เหมาตามระยะทาง";
    //======= Method 2 per km ========
  } else if (elm.method_type === 2) {
    text = "คิดตามระยะทางต่อกิโลเมตร";
    //======= Method 3 per qty ========
  } else if (elm.method_type === 3) {
    text = `คิดต่อ ${elm.unit}`;
    //======= Method 5 per km * qty ========
  } else if (elm.method_type === 5) {
    text = `คิดตามระยะทางต่อกิโลเมตร และ ต่อหน่วย ตั้งแต่ ${elm.from_value} ${elm.to_value ? `ถึง ${elm.to_value}` : ""} ${elm.unit} 1 หน่วยมี ${elm.package_qty} ชิ้น ราคา ${elm.price} บาท`;
    //======= Method 6 per pack ========
  } else if (elm.method_type === 6) {
    text = "คิดราคาเป็นแพ็ค";
  } else if (elm.method_type === 7) {
    text = "คิดตามระยะทางต่อ กิโลเมตร แบบเหมา";
  } else {
    // undefined method
  }

  if (elm.method_type !== 5) {
    text = `${text} ตั้งแต่ ${elm.from_value} ${elm.to_value ? `ถึง ${elm.to_value}` : ""} ${elm.unit} ราคา ${elm.price} บาท`;
  }
  return text;
}

export function getExpenseName(elm) {
  if (isDiscount(elm)) {
    return "ส่วนลด " + elm.name;
  }
  return elm.name;
}

// function fee_type_name(elm) {
//   let name = ''
//   if (elm.fee_type !== 3) {
//     name = ITEM_CATE_FEE_TYPES[elm.fee_type] + ' - '
//   }
//   return name
// }

export function getExpenseTotalCost(elm, is_contract_price) {
  if (is_contract_price) {
    return Multiple(Multiple(elm.contract_price, elm.qty), elm.item_qty);
  } else {
    return Multiple(Multiple(elm.price, elm.qty), elm.item_qty);
  }
}

export function getExpenseUnit(elm) {
  let unit = elm.unit;
  if (elm.item_qty != 1) {
    unit = `${unit}x${elm.item_qty}`;
  }
  return unit;
}

export function itemCategoryName(item_category) {
  return item_category.sys_name ? item_category.sys_name : item_category.ori_name;
}

export function getQtyCategory(items, elm, distance, qty) {
  let caled_qty = 0;
  let caled_item_qty = parseInt(elm.item_qty);
  let int_qty = parseInt(qty || 0);
  //======= Method 1 distance_based ========
  if (elm.method_type === 1) {
    if (distance >= elm.from_value) {
      caled_qty = 1;
    }
    //======= Method 2 per km ========
  } else if (elm.method_type === 2) {
    caled_qty = per_method_cal(elm, distance);
    //======= Method 3 per qty ========
  } else if (elm.method_type === 3) {
    caled_qty = per_method_cal(elm, int_qty);
    //======= Method 4 custom ========
  } else if (elm.method_type === 4) {
    caled_qty = elm.qty;
    //======= Method 5 per km * qty ========
    // if this method is selected, it will have to display only "km" not "km*qty"
  } else if (elm.method_type === 5) {
    // console.log(elm)
    caled_qty = per_method_cal(elm, distance);
    caled_item_qty = Math.ceil(Division(int_qty, elm.package_qty));
    //caled_qty = caled_qty * qty
    //======= Method 6 per pack ========
  } else if (elm.method_type === 6) {
    caled_qty = per_qty_cal(elm, int_qty);
  } else if (elm.method_type === 7) {
    caled_qty = distance;
  } else {
    // undefined method
  }

  // check if there is the item for the discount
  if (isDiscount(elm)) {
    let index = objectIndexOf(items, "name", elm.name);
    if (index === -1) {
      caled_item_qty = 0;
    } else {
      if (elm.method_type === 5) {
        if (parseInt(items[index].qty) < caled_item_qty) {
          caled_item_qty = items[index].qty;
        }
      } else if (elm.method_type === 3) {
        if (parseInt(items[index].qty) <= int_qty) {
          caled_qty = items[index].qty;
          // in case that an item is added after its coupon
          caled_item_qty = 1;
        }
      }
    }
  }

  return { qty: caled_qty, item_qty: isNaN(caled_item_qty) ? 1 : caled_item_qty };
}

export function getQtyItem(elm, distance, qty) {
  let qty_int = parseInt(qty);
  let caled_qty = 0;
  //======= Method 1 distance_based ========
  if (elm.method_type === 1) {
    if (distance >= elm.from_value) {
      caled_qty = 1;
    }
    //======= Method 2 per km ========
  } else if (elm.method_type === 2) {
    caled_qty = per_method_cal(elm, distance);
    //======= Method 3 per qty ========
  } else if (elm.method_type === 3) {
    caled_qty = per_qty_cal(elm, qty_int);
    //======= Method 4 custom ========
  } else if (elm.method_type === 4) {
    caled_qty = elm.qty;
  } else {
    // undefined method
  }

  return caled_qty;
}

export function getWarrantyExpirationDate(order_item) {
  if (order_item.warranty_value && order_item.warranty_type) {
    if (order_item.warranty_type == 1) {
      return addYears(order_item.installation_date, order_item.warranty_value);
    } else if (order_item.warranty_type == 2) {
      return addMonths(order_item.installation_date, order_item.warranty_value);
    } else {
      return addDays(order_item.installation_date, order_item.warranty_value);
    }
  } else {
    return null;
  }
}

function per_method_cal(elm, value) {
  let cal_val = 0;
  if (elm.to_value !== null) {
    if (value >= elm.from_value && value <= elm.to_value) {
      cal_val = value - elm.from_value + 1;
    } else if (value > elm.to_value) {
      cal_val = elm.to_value - elm.from_value + 1;
    } else {
      // if value < elm.from_value, no calculation needed
    }
  } else {
    if (value >= elm.from_value) {
      cal_val = value - elm.from_value + 1;
    } else {
      // if value < elm.from_value, no calculation needed
    }
  }
  return cal_val;
}

function per_qty_cal(elm, qty) {
  let caled_qty = 0;
  // for item fee
  if (!isDiscount(elm)) {
    // 1. assign range of qty
    if (elm.to_value !== null) {
      if (qty >= elm.from_value && qty <= elm.to_value) {
        caled_qty = 1;
      }
      // 2. assign only from value
    } else {
      if (qty >= elm.from_value) {
        caled_qty = qty;
      }
    }
    // for item discount
  } else {
    // set only from_value
    if (elm.to_value === null) {
      if (qty >= elm.from_value) {
        caled_qty = elm.from_value;
      } else {
        caled_qty = qty;
      }
    }
  }

  return caled_qty;
}

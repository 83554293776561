import React from "react";
import Modal from "react-modal";
import _ from "lodash";

// import ModalConfirmation from "../global/ModalConfirmation";
import { InfoCircleOutlined } from "@ant-design/icons";
import { R_SCREEN_DOCK_SIZE, MODAL_STYLE_LARGE, MODAL_STYLE_LARGE_MD } from "../../constants";

//model
import { itemCategoryName } from "../../models/ItemCategories";
import { ModalIssueOrder } from "../global/ModalIssueOrder";
import moment from "moment";

const TABS = {
  remaining_items: 0,
  selected_items: 1,
};
export class QuotationFormIssuePo extends React.Component {
  constructor(props) {
    super(props);

    let remaining_items = props.items
      .filter((elm) => elm.issued === false)
      .map((elm) => {
        var o = Object.assign({}, elm);
        o.selected = false;
        return o;
      });
    this.state = {
      current_tab: TABS.remaining_items,
      isSelecPartialVendorItem: false,
      isSelecPartialDcItem: false,
      submit_all: false,
      selected_id: null,
      items: remaining_items,
    };
    this.onChangeTab = this.onChangeTab.bind(this);
    this.onSelectId = this.onSelectId.bind(this);
    this.onMoveItem = this.onMoveItem.bind(this);
    this.onSelecPartialVendorItem = this.onSelecPartialVendorItem.bind(this);
    this.onSelecPartialDcItem = this.onSelecPartialDcItem.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitAll = this.onSubmitAll.bind(this);
    this.onSubmitAllDcItems = this.onSubmitAllDcItems.bind(this);
  }

  onChangeTab(tab) {
    this.setState({ current_tab: tab });
  }

  onSelectId(id) {
    if (this.state.selected_id !== null && this.state.selected_id === id) {
      this.setState({ selected_id: null });
    } else {
      this.setState({ selected_id: id });
    }
  }

  onMoveItem() {
    let id = this.state.selected_id;
    if (id !== null) {
      let items = this.state.items;
      let de_item_index = items.findIndex((x) => x.id === id);

      if (items[de_item_index].is_parent) {
        const find_sub_items = items.filter((s_item) => s_item.from_parent_item_id === items[de_item_index].item_id);
        find_sub_items?.forEach((s_item) => {
          let s_item_index = items.findIndex((f_item) => f_item.id === s_item.id);
          items[s_item_index].selected = !items[s_item_index].selected;
        });
      }

      items[de_item_index].selected = !items[de_item_index].selected;
      this.setState({ items: items });
    }
  }

  onSelecPartialVendorItem() {
    this.setState({ isSelecPartialVendorItem: true });
    this.setState({ isSelecPartialDcItem: false });
  }

  onSelecPartialDcItem() {
    this.setState({ isSelecPartialDcItem: true });
    this.setState({ isSelecPartialVendorItem: false });
  }

  onSubmit() {
    let selected_items = this.state.items.filter((elm) => elm.selected === true);
    if (selected_items.length > 0) {
      this.props.onSubmitIssuePo(selected_items);
    } else {
      alert("โปรดเลือกสินค้าเพิ่อทำการออก Order");
    }
  }

  onSubmitAll() {
    let { item_categories } = this.props;
    let issuedAllItemDc = [];
    Object.keys(item_categories).map((key) => {
      const findDcItems = this.state.items.filter((item) => item.item_category_id === item_categories[key].item_category_id && item_categories[key].item_category.dc_id === 0);

      if (findDcItems.length > 0) {
        issuedAllItemDc.push(...findDcItems);
      }
    });
    this.props.onSubmitIssuePo(issuedAllItemDc);
  }

  onSubmitAllDcItems() {
    let { item_categories } = this.props;
    let issuedAllItemDc = [];
    Object.keys(item_categories).map((key) => {
      const findDcItems = this.state.items.filter((item) => item.item_category_id === item_categories[key].item_category_id && item_categories[key].item_category.dc_id === 1);

      if (findDcItems.length > 0) {
        issuedAllItemDc.push(...findDcItems);
      }
    });

    this.props.onSubmitIssuePo(issuedAllItemDc);
  }

  // onSelectItem(id) {
  //   let { remaining_items, selected_items } = this.state
  //   let index = remaining_items.findIndex(elm => elm.id === id)
  //   if (index > -1) {
  //     selected_items.push(remaining_items[index])
  //     remaining_items.splice(index, 1)

  //     this.setState({ remaining_items: remaining_items, selected_items: selected_items })
  //   }
  // }
  /* ========================================================= */
  /* Functions (start) */

  /* Functions (end) */
  /* ========================================================= */

  /* ========================================================= */
  /* Display (start) */
  displayItems(itemsByCate, index_offset, disabled, isDc = false) {
    let item_index = -1;
    let items = itemsByCate.map((elm, index) => {
      let selectedStyle = this.state.selected_id === elm.id ? "selected" : "";
      let isProductLessThanQTY = elm.qty > elm.active;

      if (elm.from_parent) return false;

      item_index = item_index + 1;

      return (
        <React.Fragment>
          <div
            className={`row mt-2 border ${selectedStyle} ${disabled ? "table-disable" : isDc && isProductLessThanQTY ? "table-disable" : "table-item-hoverable-wrapper"}`}
            onClick={() => (disabled ? false : isDc && isProductLessThanQTY ? false : this.onSelectId(elm.id))}
          >
            <div className="table-item-bg first col-p5 justify-content-center">{index_offset + item_index}</div>
            <div className="table-item-bg col pt-1 pr-0">{elm.name}</div>
            {isDc ? (
              <>
                <div className="table-item-bg col pt-1 pr-0" style={{ fontWeight: "bold", textAlign: "center", display: "flex", justifyContent: "center", color: elm.selected ? "#28a745" : "black" }}>
                  Active : {elm.active}
                </div>
                {isProductLessThanQTY ? (
                  <span className="table-item-bg col pt-1 pr-0" style={{ display: "flex", gap: 5, alignItems: "center", color: "red" }}>
                    <InfoCircleOutlined />
                    <div>สินค้าในคลังไม่เพียงพอ</div>
                  </span>
                ) : (
                  ``
                )}
              </>
            ) : (
              ``
            )}
          </div>
        </React.Fragment>
      );
    });
    return items;
  }

  displayItemByCategory(items) {
    let { item_categories } = this.props;
    let index_offset = 1;
    let elmByCategory = _.groupBy(items, function (value) {
      return value.item_category_id;
    });
    let result = Object.keys(elmByCategory).map((key) => {
      let cate_disabled = item_categories[key].informed_date === null;
      let rowItems = this.displayItems(elmByCategory[key], index_offset, cate_disabled);
      if (item_categories[key].item_category.dc_id === 0) {
        index_offset += elmByCategory[key].length;
      }

      return (
        item_categories[key].item_category.dc_id === 0 && (
          <React.Fragment key={key}>
            <div className={`row ${cate_disabled ? "table-disable" : ""}`}>
              <div className="table-item-bg category-title first col-p5 d-none d-md-flex"></div>
              <div className="table-item-bg category-title col">{itemCategoryName(item_categories[key])}</div>
              <span className="table-item-bg category-title color-red">{cate_disabled ? " (ยังไม่ได้แจ้งผู้ผลิต)" : ""}</span>
            </div>
            {rowItems}
            <div className="row mt-3"></div>
          </React.Fragment>
        )
      );
    });

    return result;
  }

  displayItemDCByCategory(items) {
    let { item_categories } = this.props;
    let index_offset = 1;
    let elmByCategory = _.groupBy(items, function (value) {
      return value.item_category_id;
    });
    let result = Object.keys(elmByCategory).map((key) => {
      // let cate_disabled = item_categories[key].informed_date === null;
      let rowItems = this.displayItems(elmByCategory[key], index_offset, false, true);

      if (item_categories[key].item_category.dc_id === 1) {
        index_offset += elmByCategory[key].length;
      }

      return (
        item_categories[key].item_category.dc_id === 1 && (
          <React.Fragment key={key}>
            <div className={`row`}>
              <div className="table-item-bg category-title first col-p5 d-none d-md-flex"></div>
              <div className="table-item-bg category-title col">{itemCategoryName(item_categories[key])}</div>
              {/* <span className="table-item-bg category-title color-red">{cate_disabled ? " (ยังไม่ได้แจ้งผู้ผลิต)" : ""}</span> */}
            </div>
            {rowItems}
            <div className="row mt-3"></div>
          </React.Fragment>
        )
      );
    });

    return result;
  }
  /* Display (end) */
  /* ========================================================= */

  render() {
    let { isSelecPartialVendorItem, isSelecPartialDcItem, current_tab } = this.state;
    let elmBySelection = _.groupBy(this.state.items, function (value) {
      return value.selected;
    });

    if (isSelecPartialVendorItem) {
      return (
        <Modal isOpen={true} contentLabel="QuotationFormIssuePo" style={window.innerWidth > R_SCREEN_DOCK_SIZE ? MODAL_STYLE_LARGE : MODAL_STYLE_LARGE_MD} onRequestClose={() => this.props.onHandleIssuePoModal(false)}>
          <React.Fragment>
            <div>
              <div className="row mb-md-4">
                <div className="col-8">
                  <h5>สร้างออเดอร์ใหม่ของสินค้า Vendor</h5>
                </div>
                <div className="col pl-0 text-right">
                  <button type="button" className="btn btn-success btn-sm mr-1" onClick={this.onSubmit}>
                    ออก Order
                  </button>
                  <button type="button" className="btn btn-secondary btn-sm mr-1" onClick={() => this.props.onHandleIssuePoModal(false)}>
                    ยกเลิก
                  </button>
                </div>
              </div>

              {/* md up (start) */}
              <div className="row d-none d-md-flex">
                <div className="col-5">
                  <p>สินค้าที่ยังไม่ได้ออก Order</p>
                </div>
                <div className="col"></div>
                <div className="col-5">
                  <p>สินค้าที่เลือก</p>
                </div>
              </div>

              <div className="row d-none d-md-flex">
                <div className="col-12 col-md-5 ml-3 div-scrollable vh-60">{this.displayItemByCategory(elmBySelection[false])}</div>
                <div className="col m-auto">
                  <button type="button" className="btn btn-info btn-sm w-100 mr-1" onClick={this.onMoveItem}>
                    {"<< ย้าย >>"}
                  </button>
                </div>
                <div className="col-12 col-md-5 mr-3 div-scrollable vh-60">{this.displayItemByCategory(elmBySelection[true])}</div>
              </div>
              {/* md up (end) */}

              {/* xs and sm (start) */}
              <ul className="nav nav-tabs d-md-none">
                <li className="nav-item">
                  <a className={`nav-link px-2 ${current_tab === TABS.remaining_items ? "active" : ""}`} onClick={() => this.onChangeTab(TABS.remaining_items)}>
                    สินค้าที่เหลือ
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link px-2 ${current_tab === TABS.selected_items ? "active" : ""}`} onClick={() => this.onChangeTab(TABS.selected_items)}>
                    สินค้าที่เลือก
                  </a>
                </li>
              </ul>
              <div className="row p-3 d-md-none">
                <div className="col div-scrollable vh-50">{this.displayItemByCategory(elmBySelection[current_tab === TABS.selected_items])}</div>
                <div className="col-12 text-center mt-3">
                  <button type="button" className="btn btn-info btn-sm" onClick={this.onMoveItem}>
                    {"<< ย้าย >>"}
                  </button>
                </div>
              </div>
              {/* xs and sm (end) */}
            </div>
          </React.Fragment>
        </Modal>
      );
    } else if (isSelecPartialDcItem) {
      return (
        <Modal isOpen={true} contentLabel="QuotationFormIssuePo" style={window.innerWidth > R_SCREEN_DOCK_SIZE ? MODAL_STYLE_LARGE : MODAL_STYLE_LARGE_MD} onRequestClose={() => this.props.onHandleIssuePoModal(false)}>
          <React.Fragment>
            <div>
              <div className="row mb-md-4">
                <div className="col-8">
                  <h5>สร้างออเดอร์ใหม่ของสินค้า DC</h5>
                </div>
                <div className="col pl-0 text-right">
                  <button type="button" className="btn btn-success btn-sm mr-1" onClick={this.onSubmit}>
                    ออก Order
                  </button>
                  <button type="button" className="btn btn-secondary btn-sm mr-1" onClick={() => this.props.onHandleIssuePoModal(false)}>
                    ยกเลิก
                  </button>
                </div>
              </div>

              {/* md up (start) */}
              <div className="row d-none d-md-flex">
                <div className="col-5">
                  <p>สินค้าที่ยังไม่ได้ออก Order</p>
                </div>
                <div className="col"></div>
                <div className="col-5">
                  <p>สินค้าที่เลือก</p>
                </div>
              </div>

              <div className="row d-none d-md-flex">
                <div className="col-12 col-md-5 ml-3 div-scrollable vh-60">{this.displayItemDCByCategory(elmBySelection[false])}</div>
                <div className="col m-auto">
                  <button type="button" className="btn btn-info btn-sm w-100 mr-1" onClick={this.onMoveItem}>
                    {"<< ย้าย >>"}
                  </button>
                </div>
                <div className="col-12 col-md-5 mr-3 div-scrollable vh-60">{this.displayItemDCByCategory(elmBySelection[true])}</div>
              </div>
              {/* md up (end) */}

              {/* xs and sm (start) */}
              <ul className="nav nav-tabs d-md-none">
                <li className="nav-item">
                  <a className={`nav-link px-2 ${current_tab === TABS.remaining_items ? "active" : ""}`} onClick={() => this.onChangeTab(TABS.remaining_items)}>
                    สินค้าที่เหลือ
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link px-2 ${current_tab === TABS.selected_items ? "active" : ""}`} onClick={() => this.onChangeTab(TABS.selected_items)}>
                    สินค้าที่เลือก
                  </a>
                </li>
              </ul>
              <div className="row p-3 d-md-none">
                <div className="col div-scrollable vh-50">{this.displayItemDCByCategory(elmBySelection[current_tab === TABS.selected_items])}</div>
                <div className="col-12 text-center mt-3">
                  <button type="button" className="btn btn-info btn-sm" onClick={this.onMoveItem}>
                    {"<< ย้าย >>"}
                  </button>
                </div>
              </div>
              {/* xs and sm (end) */}
            </div>
          </React.Fragment>
        </Modal>
      );
    } else {
      return (
        <React.Fragment>
          <ModalIssueOrder
            isOpen={true}
            title="สร้าง Order"
            content={
              <React.Fragment>
                <p>ต้องการสร้าง Order ใหม่ จากรายการสินค้าที่เหลือทั้งหมด หรือ บางส่วน?</p>
                {!this.props.inform_manu_completed && !this.props.select_any_vendor ? (
                  <div className="color-red">
                    <br />* ไม่สามารถสร้าง Order จากสินค้าทั้งหมดได้ เนื่องจากมีหมวดหมู่สินค้าที่ยังไม่ได้แจ้งผู้ผลิต
                  </div>
                ) : (
                  ""
                )}
                {!this.props.inform_dc_manu_completed && !this.props.all_dc_issued ? <div className="color-red mb-1">* ไม่สามารถสร้าง Order จากสินค้าทั้งหมดได้ เนื่องจากมีสินค้าบางชนิดไม่เพียงพอในคลัง</div> : ""}
              </React.Fragment>
            }
            onSelectAllVendorItem={this.onSubmitAll}
            onSelectAllDcItem={this.onSubmitAllDcItems}
            onSelecPartialVendorItem={this.onSelecPartialVendorItem}
            onSelecPartialDcItem={this.onSelecPartialDcItem}
            onSelectedAllVendorItemDisabled={this.props.all_vendor_issued ? this.props.all_vendor_issue : !this.props.inform_manu_completed}
            onSelectedAllDcItemDisabled={this.props.all_dc_issued ? this.props.all_dc_issued : !this.props.inform_dc_manu_completed}
            onAllDcIssued={this.props.all_dc_issued}
            onAllVendorIssued={this.props.all_vendor_issued}
            onSelectedAnyVendorItem={this.props.select_any_vendor}
            onSelectedAnyDCItem={this.props.select_any_dc}
            isHavedDCProduct={this.props.have_dc_product}
            isHavedVendorProduct={this.props.have_vendor_product}
            onCancel={() => this.props.onHandleIssuePoModal(false)}
          />
        </React.Fragment>
      );
    }
  }
}

import React from "react";
import { INBOUND_STATES } from "../constants";
import DcInboundOrderIndex from "../components/dc-inbound-order/list/DcInboundOrderIndex.jsx";
import DcInboundOrderForm from "../components/dc-inbound-order/form/DcInboundOrderForm";

export class DcInboundOrder extends React.Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(props.location.search);
    const defaultStates = Object.keys(INBOUND_STATES).reduce((pre, cur) => {
      if (cur !== "cancelled") {
        pre.push(INBOUND_STATES[cur].name);
      }
      return pre;
    }, []);
    const selected_state = urlParams.get("state") ? urlParams.get("state").split(",") : defaultStates;
    const selected_date_from = urlParams.get("date_from");
    const selected_date_to = urlParams.get("date_to");
    let selected_id = props.match.params.id;
    this.state = {
      current_page: selected_id === "new" ? null : selected_id,
      state: selected_state || "",
      link_date_from: selected_date_from || "",
      link_date_to: selected_date_to || "",
    };

    this.onChangeQuoPage = this.onChangeQuoPage.bind(this);
  }

  onChangeQuoPage(page) {
    this.setState({ current_page: page });
  }

  render() {
    if (this.state.current_page === undefined) {
      return <DcInboundOrderIndex state={this.state.state} link_date_from={this.state.link_date_from} link_date_to={this.state.link_date_to} onChangeQuoPage={this.onChangeQuoPage} />;
    } else {
      return <DcInboundOrderForm {...this.props} id={this.state.current_page} onChangeQuoPage={this.onChangeQuoPage} />;
    }
  }
}

import React from "react";
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  border-color: #145dac;
  position: fixed;
  z-index: 1000;
`;

class Spinner extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={`${this.props.loading ? "overlay-box" : "overlay-hide"}`} style={{ marginTop: "-60px", zIndex: 999999999 }}>
        <BeatLoader css={override} size={25} color={"#145DAC"} loading={this.props.loading} speedMultiplier={1.5} />
        <div css={override} style={{ marginTop: 60, fontSize: 20, textAlign: "center", fontWeight: "bold" }}>
          {this.props.allCategory && this.props.allCategory !== 0 ? `กำลังโหลดสัญญา (${this.props.allLoadeCategory} จาก ${this.props.allCategory})...` : `กำลังโหลดข้อมูลกรุณารอสักครู่...`}
        </div>
      </div>
    );
  }
}
export default Spinner;

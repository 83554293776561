import _ from 'lodash';
import * as XLSX from 'xlsx'

export function parseData(data, style, fillEmpty, emptyStyle) {
  if (data) {
    return { value: data, style: style }
  } else {
    return { value: fillEmpty ? fillEmpty : '', style: emptyStyle ? emptyStyle : '' }
  }
}

// see this issue for more information
// https://github.com/SheetJS/sheetjs/issues/1921
export function getDataRange(data) {
  const dataWithValues = _.pickBy(data, (value, key) => !!value.v)
  const cellNamesWithValues = _.keys(dataWithValues)
  const cellsWithValues = cellNamesWithValues.map(cell => XLSX.utils.decode_cell(cell))
  const maxRow = _.max(cellsWithValues.map(cell => cell.r))
  const maxColumn = _.max(cellsWithValues.map(cell => cell.c))
  const lastCellName = XLSX.utils.encode_cell({ c: maxColumn, r: maxRow })
  return `A1:${lastCellName}`
}

export function emptyRow(nb_of_cols, style) {
  let row = []
  for(let i = 0; i < nb_of_cols; i++) {
    row.push(parseData('', style))
  }
  return row
}
// in users.js
import * as React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  DeleteWithConfirmButton,
  TextField,
  EditButton,
  TextInput,
  required,
  useListContext,
  BooleanInput,
  PasswordInput,
  useNotify,
} from "react-admin";
import BookIcon from "@material-ui/icons/Book";
import { Form } from "react-final-form";
import { Box, Button as CoreButton, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ContentFilter from "@material-ui/icons/FilterList";

import { exporter } from "../../functions/reactAdmin";
import { requiredPasswordPolicies, validatePassword } from "../../models/Users";

import { EditToolbar, EditActions } from "./Default";

export const UserIcon = BookIcon;

const UserFilter = (props) => {
  return props.context === "button" ? (
    <UserFilterButton {...props} />
  ) : (
    <UserFilterForm {...props} />
  );
};

const UserFilterButton = () => {
  const { showFilter } = useListContext();
  return (
    <CoreButton
      size="small"
      color="primary"
      onClick={() => showFilter("main")}
      startIcon={<ContentFilter />}
    >
      Filter
    </CoreButton>
  );
};

const UserFilterForm = ({ open }) => {
  const { displayedFilters, filterValues, setFilters, hideFilter } =
    useListContext();

  if (!displayedFilters.main) return null;

  const onSubmit = (values) => {
    if (Object.keys(values).length > 0) {
      setFilters(values);
    } else {
      hideFilter("main");
    }
  };

  const resetFilter = () => {
    setFilters({}, []);
  };

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box mt={8} />
            <Box display="flex" alignItems="flex-end" mb={1}>
              <Box component="span" mr={2}>
                {/* Full-text search filter. We don't use <SearchFilter> to force a large form input */}
                <TextInput
                  resettable
                  helperText={false}
                  source="firstname"
                  label="Search ชื่อ"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box component="span" mr={2}>
                {/* Full-text search filter. We don't use <SearchFilter> to force a large form input */}
                <TextInput
                  resettable
                  helperText={false}
                  source="email"
                  label="Search email"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box component="span" mr={2} mb={1.5}>
                <CoreButton variant="outlined" color="primary" type="submit">
                  Filter
                </CoreButton>
              </Box>
              <Box component="span" mb={1.5}>
                <CoreButton variant="outlined" onClick={resetFilter}>
                  Close
                </CoreButton>
              </Box>
            </Box>
          </form>
        )}
      </Form>
    </div>
  );
};
export const UserList = (props) => (
  <List {...props} filters={<UserFilter />} exporter={exporter}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="is_active" label="เปิดใช้งาน" />
      <TextField source="email" />
      <TextField source="is_admin" label="ผู้ดูแลระบบ" />
      <EditButton basePath="/users" />
      <DeleteWithConfirmButton undoable={false} />
    </Datagrid>
  </List>
);

const UserTitle = ({ record }) => {
  return <span>User {record ? `"${record.email}"` : ""}</span>;
};

const display_password_policies = () => {
  let policies = Object.keys(requiredPasswordPolicies).map((key) => {
    return (
      <div className="col-12 ml-3">
        <li>{requiredPasswordPolicies[key]}</li>
      </div>
    );
  });
  return policies;
};

const passwordValidation = (value, allValues) => {
  const errors = validatePassword(allValues);
  return errors["new_password"];
};

const confirmPasswordValidation = (value, allValues) => {
  const errors = validatePassword(allValues);
  return errors["confirm_new_password"];
};

export const UserEdit = (props) => {
  const notify = useNotify();
  console.log("props", props);
  const onFailure = (error) => {
    notify(error.body.Message);
  };
  return (
    <Edit
      undoable={false}
      actions={<EditActions />}
      title={<UserTitle />}
      {...props}
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <BooleanInput source="is_active" label="เปิดใช้งาน" />
        <BooleanInput source="is_admin" label="เป็นผู้ดูแลระบบ" />
        <TextInput disabled source="id" />
        <TextInput
          source="email"
          validate={required()}
          label="Email (ชื่อผู้ใช้)"
        />
        <TextInput source="firstname" validate={required()} />
        <TextInput source="lastname" validate={required()} />
        <div className="row font-size-13px mt-3">
          <div className="col-12">ข้อกำหนดการสร้าง password</div>
          {display_password_policies()}
        </div>
        <PasswordInput source="new_password" validate={[passwordValidation]} />
        <PasswordInput
          source="confirm_new_password"
          validate={[confirmPasswordValidation]}
        />
      </SimpleForm>
    </Edit>
  );
};

export const UserCreate = (props) => {
  const notify = useNotify();

  const onFailure = (error) => {
    notify(error.body.Message);
  };

  return (
    <Create
      onFailure={onFailure}
      title="Create a User"
      actions={<EditActions />}
      {...props}
    >
      <SimpleForm>
        <BooleanInput
          source="is_active"
          label="เปิดใช้งาน"
          initialValue={true}
        />
        <BooleanInput source="is_admin" label="เปิดผู้ดูแลระบบ" />
        <TextInput
          source="email"
          validate={required()}
          label="Email (ชื่อผู้ใช้)"
        />
        <TextInput source="firstname" validate={required()} />
        <TextInput source="lastname" validate={required()} />

        <div className="row font-size-13px mt-3">
          <div className="col-12">ข้อกำหนดการสร้าง password</div>
          {display_password_policies()}
        </div>
        <PasswordInput
          source="new_password"
          validate={[required(), passwordValidation]}
        />
        <PasswordInput
          source="confirm_new_password"
          validate={[required(), confirmPasswordValidation]}
        />
      </SimpleForm>
    </Create>
  );
};

import React from 'react';

import OrderProgressFilters from './OrderProgressFilters'

export default function OrderProgressFiltersContainer(props) {
  const onClickBtnGroup = (key, value) => {
    const { flag_filters } = props
    const new_filters = {
      ...flag_filters,
      [key]: flag_filters[key] === value ? null : value
    }
    props.setFlagFilters(new_filters)
  }

  return (
    <OrderProgressFilters
      {...props}
      onClickBtnGroup={onClickBtnGroup}
    />
  );
}
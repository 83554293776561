import * as React from "react";
import {
  List, Datagrid, Edit, Create, SimpleForm, DeleteWithConfirmButton, TextField,
  EditButton, TextInput, Toolbar, SaveButton, ReferenceField, ReferenceInput,
  AutocompleteInput, useListContext
} from 'react-admin';
import BookIcon from '@material-ui/icons/Book';

import { Form } from 'react-final-form';
import { Box, Button as CoreButton, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ContentFilter from "@material-ui/icons/FilterList";

import { exporter } from '../../functions/reactAdmin'

import { EditToolbar, EditActions } from './Default'


export const SubDistrictIcon = BookIcon;

const SubDistrictFilter = props => {
  return props.context === "button" ? (
    <SubDistrictFilterButton {...props} />
  ) : (
    <SubDistrictFilterForm {...props} />
  );
};

const SubDistrictFilterButton = () => {
  const { showFilter } = useListContext();
  return (
      <CoreButton
          size="small"
          color="primary"
          onClick={() => showFilter("main")}
          startIcon={<ContentFilter />}
      >
          Filter
      </CoreButton>
  );
};

const SubDistrictFilterForm = ({ open }) => {
  const {
      displayedFilters,
      filterValues,
      setFilters,
      hideFilter,
  } = useListContext();

  if (!displayedFilters.main) return null;

  const onSubmit = values => {
      if (Object.keys(values).length > 0) {
          setFilters(values);
      } else {
          hideFilter("main");
      }
  };

  const resetFilter = () => {
      setFilters({}, []);
  };

  return (
      <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
          {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
              <Box mt={8} />
              <Box display="flex" alignItems="flex-end" mb={1}>
              <Box component="span" mr={2}>
                  {/* Full-text search filter. We don't use <SearchFilter> to force a large form input */}
                  <TextInput
                  resettable
                  helperText={false}
                  source="name"
                  label="Search ชื่อ"
                  InputProps={{
                      endAdornment: (
                      <InputAdornment>
                          <SearchIcon color="disabled" />
                      </InputAdornment>
                      )
                  }}
                  />
              </Box>
              <Box component="span" mr={1}>
                <ReferenceInput 
                  resettable
                  label="อำเภอ/เขต" 
                  source="district_id" 
                  reference="districts"
                  filterToQuery={searchText => ({ name: searchText })}
                >
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              </Box>
              <Box component="span" mr={2} mb={1.5}>
                  <CoreButton variant="outlined" color="primary" type="submit">
                  Filter
                  </CoreButton>
              </Box>
              <Box component="span" mb={1.5}>
                  <CoreButton variant="outlined" onClick={resetFilter}>
                  Close
                  </CoreButton>
              </Box>
              </Box>
          </form>
          )}
      </Form>
      </div>
  );
};

export const SubDistrictList = (props) => (
  <List {...props} filters={<SubDistrictFilter />} exporter={exporter}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField label="District" source="district_id" reference="districts" sortable={false}>
        <TextField source="name" />
      </ReferenceField>
      <EditButton basePath="/sub_districts" />
       <DeleteWithConfirmButton undoable={false}/>
    </Datagrid>
  </List>
);

const SubDistrictTitle = ({ record }) => {
  return <span>SubDistrict {record ? `"${record.name}"` : ''}</span>;
};

export const SubDistrictEdit = (props) => (
  <Edit undoable={false} actions={<EditActions />} title={<SubDistrictTitle />} {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <ReferenceInput 
        label="District" 
        source="district_id" 
        reference="districts"
        filterToQuery={searchText => ({ name: searchText })}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const SubDistrictCreate = (props) => (
  <Create title="Create a SubDistrict" actions={<EditActions />} {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceInput 
        label="District" 
        source="district_id" 
        reference="districts"
        filterToQuery={searchText => ({ name: searchText })}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
import * as React from "react";
import { Toolbar, TopToolbar, SaveButton, ListButton } from "react-admin";

export const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const EditActions = ({ basePath, record, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

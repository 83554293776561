import React from "react";
import TableChild from "./table-child";

import "./table.css";

export default function ReportTable({ quotations, bank_list, current_user, setQuotations, setIsLoading }) {
  function QuotationChange(quotation) {
    const newQuotations = [...quotations];
    const quotation_index = newQuotations.findIndex((quo) => quo.id === quotation.id);

    newQuotations[quotation_index] = quotation;

    setQuotations(newQuotations);
  }

  function UpdateDeleteQuotation(quotation_id) {
    const newQuotations = [...quotations];
    let quotations_arr = [];

    newQuotations.forEach((quo) => {
      if (quo.id !== quotation_id) {
        quotations_arr.push(quo);
      }
    });

    setQuotations(quotations_arr);
  }

  function UpdateCancelAllQuataion(quotation_id) {
    const newQuotations = [...quotations];
    let quotations_arr = [];

    newQuotations.forEach((quo) => {
      if (quo.id !== quotation_id) {
        quotations_arr.push(quo);
      }
    });

    setQuotations(quotations_arr);
  }

  function UpdateCancelQuataion(quotation_id, state) {
    const newQuotations = [...quotations];
    const quotation_index = newQuotations.findIndex((quo) => quo.id === quotation_id);
    newQuotations[quotation_index].state = state;
    setQuotations(newQuotations);
  }

  return (
    <div className="container-table Flipped">
      <div className="content-table">
        <div className="table-head-report">
          <div className="table-content" style={{ padding: "0 0 0 10px" }}>
            ผู้รับผิดชอบ
          </div>
          <div className="table-content">ผู้ซื้อ</div>
          <div className="table-content">สถานี</div>
          <div className="table-content">ใบเสนอราคา</div>
          <div className="table-content">หมวดหมู่</div>
          <div className="table-content">แจ้งผู้ผลิต</div>
          <div className="table-content">สินค้า</div>
          <div className="table-content">ออเดอร์</div>
          <div className="table-content">ORC</div>
          <div className="table-content">Payment</div>
          <div className="table-content">ตั้งหนี้</div>
          <div className="table-content">วันที่กำหนดติดตั้ง</div>
          <div className="table-content">พร้อมติดตั้ง</div>
          <div className="table-content">PO</div>
          <div className="table-content">GR</div>
          <div className="table-content">DO</div>
          <div className="table-content">Billing</div>
        </div>
        {quotations &&
          quotations.map((quotation, index) => {
            return (
              <TableChild
                UpdateCancelQuataion={UpdateCancelQuataion}
                setIsLoading={setIsLoading}
                quo={quotation}
                index={index}
                bank_list={bank_list}
                current_user={current_user}
                QuotationChange={QuotationChange}
                UpdateDeleteQuotation={UpdateDeleteQuotation}
                UpdateCancelAllQuataion={UpdateCancelAllQuataion}
              />
            );
          })}
      </div>
    </div>
  );
}

import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import moment from "moment";
// Global component
import VendorBilling from "./VendorBilling";

// Functions
import { formatDate, formatApiDate, getDateFromToday } from "../../../functions/Date";
import { uniqueKey } from "../../../functions/Random";
import { objectIsEmpty } from "../../../functions/Object";

// model
import { toExcelVendorBillingReport } from "../../../models/ManufactureRequest";

// constants
import { API_RESOURCES } from "../../../constants";

const MAX_ITEMS = 500;

let searchParams = {};

class VendorBillingContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      selected_state: null,
      selected_id: "",
      selected_sorting: "mr_date",
      selected_sorting_reversed: true,
      selected_date_form: getDateFromToday(-120),
      selected_date_to: new Date(),
      selected_vendor: null,
      reset_sorting: 0,
      selected_preview_mr: null,
      is_updated: false,
      is_loading: true,
      is_refilltering: true,
      requests: [],
      resetKey: uniqueKey(),

      // export
      export_report: null,
    };
  }

  componentDidMount() {
    if (this.props.location.search) {
      this.getPathParams();
    } else {
      this.getMRs();
    }
  }

  componentDidUpdate() {
    if (this.state.is_refilltering) {
      this.getMRs();
    }
  }

  getPathParams() {
    const query = new URLSearchParams(this.props.location.search).get("query") || "";
    const start_date = new URLSearchParams(this.props.location.search).get("start_date") || "";
    const end_date = new URLSearchParams(this.props.location.search).get("end_date") || "";
    const state = new URLSearchParams(this.props.location.search).get("state") || null;
    const state_label = new URLSearchParams(this.props.location.search).get("state_label") || null;
    const vendor = new URLSearchParams(this.props.location.search).get("vendor") || null;

    if (query !== "") searchParams["query"] = query;
    if (start_date) searchParams["start_date"] = start_date;
    if (end_date) searchParams["end_date"] = end_date;
    if (vendor !== null) searchParams["vendor"] = vendor;
    if (state !== null) searchParams["state"] = state;
    if (state_label !== null) searchParams["state_label"] = state_label;

    this.setState({
      // is_loading: false,
      query: query,
      selected_date_form: start_date ? moment(start_date).toDate() : getDateFromToday(-120),
      selected_date_to: end_date ? moment(end_date).toDate() : new Date(),
      selected_state: state ? { value: state, label: state_label } : null,
      selected_vendor: vendor ? parseInt(vendor) : null,
    });

    // this.getMRs();
  }

  getMRs(resetPage) {
    // filter: {}
    // range: [0,9]
    // sort: ["code","ASC"]
    let { selected_sorting, selected_sorting_reversed, selected_state, selected_date_form, selected_date_to, query, selected_vendor } = this.state;
    let sort_dir = selected_sorting_reversed ? "DESC" : "ASC";
    let options = {
      for_vendor: true,
      sort: JSON.stringify([selected_sorting, sort_dir]),
      filter: {},
      range: JSON.stringify([0, MAX_ITEMS]),
    };
    if (!objectIsEmpty(selected_state)) {
      options.filter.tb_received_date = selected_state.value;
    }
    if (selected_date_form || selected_date_to) {
      let start_date = formatApiDate(selected_date_form);
      let end_date = formatApiDate(selected_date_to);
      options.filter.mr_date = [start_date, end_date];
    }
    if (selected_vendor) {
      options.filter.vendor_id = selected_vendor;
    }
    if (query) {
      options.filter.query = query;
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.mr}`, {
        params: options,
      })
      .then((response) => {
        let export_report = toExcelVendorBillingReport(response.data);

        this.setState({
          requests: response.data,
          export_report: export_report,
          is_refilltering: false,
          is_loading: false,
          resetKey: resetPage ? uniqueKey() : this.state.resetKey,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          is_refilltering: false,
          is_loading: false,
        });
      })
      .then(() => {
        // always executed
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.addSearchParams(searchParams);
    this.setState({ is_refilltering: true });
  };

  onEnter = (event) => {
    this.setState({ is_refilltering: true });
  };

  onChangeQuery = (e) => {
    const value = e.target.value;

    if (value === "" && searchParams["query"]) {
      delete searchParams["query"];
    } else {
      searchParams["query"] = value;
    }

    this.setState({ query: value });
  };

  onChangeState = (e) => {
    if (e !== null) {
      searchParams["state"] = e.value;
      searchParams["state_label"] = e.label;
    } else {
      delete searchParams["state"];
      delete searchParams["state_label"];
    }

    this.setState({ selected_state: e });
  };

  onChangeSorting = (e) => {
    this.setState({ selected_sorting: e.value });
  };

  onChangeFromDate = (date) => {
    searchParams["start_date"] = moment(date).format("MM/DD/YYYY");
    this.setState({ selected_date_form: date });
  };

  onChangeToDate = (date) => {
    searchParams["end_date"] = moment(date).format("MM/DD/YYYY");
    this.setState({ selected_date_to: date });
  };

  onChangeVendor = (id, e) => {
    searchParams["vendor"] = id;
    this.setState({ selected_vendor: id });
  };

  addSearchParams(params, path = this.props.location.pathname) {
    let searchParams = "";
    Object.keys(params).forEach((key, index) => {
      searchParams = searchParams + `${Object.keys(params).length > 1 && index !== 0 ? "&" : ""}${key}=${params[key]}`;
    });

    this.props.history.replace({ pathname: `${path}`, search: searchParams });
  }

  onClickClear = () => {
    this.setState({
      selected_state: null,
      selected_period: "",
      selected_id: "",
      selected_sorting: "mr_date",
      selected_sorting_reversed: true,
      selected_date_form: getDateFromToday(-120),
      selected_date_to: new Date(),
      selected_vendor: null,
      query: "",
      is_refilltering: true,
      resetKey: uniqueKey(),
    });

    searchParams = {};
    this.props.history.replace({ pathname: `${this.props.location.pathname}`, search: null });
  };

  onChangeMrInfo = (e, id) => {
    let { requests } = this.state;
    let index = requests.findIndex((elm) => elm.id === id);
    if (index > -1) {
      requests[index] = {
        ...requests[index],
        [e.target.name]: e.target.value,
      };
      requests[index].is_updated = true;

      this.setState({ requests: requests, is_updated: true });
    }
  };

  onClickEdit = (id) => {
    let { requests } = this.state;
    let index = requests.findIndex((elm) => elm.id === id);
    if (index > -1) {
      let { current_user } = this.props;
      let today = new Date();
      let edit_text = `แก้ไขวันที่ส่งเอกสารตัวจริง เดิมวันที่ ${formatDate(requests[index].tb_received_date)} (by ${current_user.firstname}, ${formatDate(today)})`;
      requests[index].is_updated = true;
      requests[index].tb_remark = requests[index].tb_remark ? requests[index].tb_remark + "\n" + edit_text : edit_text;
      requests[index].tb_received_date = null;

      this.setState({ requests: requests, is_updated: true });
    }
  };

  onChangeDate = (id, field, date) => {
    let { requests } = this.state;
    let index = requests.findIndex((elm) => elm.id === id);

    if (index > -1) {
      // for fixing date problem, (input 2/8/64 -> save -> return 1/8/64)
      // https://trello.com/c/qWzFkFZu
      // if the default is not given to Datepicker, the time will be 00:00:00
      // if new Date() is given as a defualt, the time will be xx:xx:xx
      if (date instanceof Date) {
        date = formatApiDate(date);
      }
      requests[index] = {
        ...requests[index],
        [field]: date,
      };
      requests[index].is_updated = true;
      this.setState({ requests: requests, is_updated: true });
    }
  };

  onSaveMrRequests = () => {
    let { requests } = this.state;
    let updated_obj_arr = [];
    for (let mr of requests) {
      if (mr.is_updated) {
        updated_obj_arr.push(mr);
      }
    }

    if (updated_obj_arr.length !== 0) {
      this.setState({ is_loading: true });

      axios
        .put(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.mr}/bulk_updates_for_billing`, {
          mrs: updated_obj_arr,
        })
        .then((response) => {
          alert("Saved!");
          for (let mr of requests) {
            mr.is_updated = null;
          }
          this.setState({ is_loading: false, is_updated: false, requests: requests });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ is_loading: false });
        });
    }
  };

  render() {
    return (
      <VendorBilling
        {...this.props}
        {...this.state}
        onEnter={this.onEnter}
        onChangeQuery={this.onChangeQuery}
        handleSubmit={this.handleSubmit}
        onChangeState={this.onChangeState}
        onChangeFromDate={this.onChangeFromDate}
        onChangeToDate={this.onChangeToDate}
        onChangeVendor={this.onChangeVendor}
        onChangeSorting={this.onChangeSorting}
        onClickClear={this.onClickClear}
        onChangeMrInfo={this.onChangeMrInfo}
        onClickEdit={this.onClickEdit}
        onChangeDate={this.onChangeDate}
        onSaveMrRequests={this.onSaveMrRequests}
      />
    );
  }
}
export default withRouter(VendorBillingContainer);

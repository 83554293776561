import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { APP_NAME } from '../../constants'

export default () => (
    <Card>
        <Title title={`${APP_NAME} ADMIN`} />
        <CardContent>
          <React.Fragment>
            <h3 className="pt-3">Welcome to the administration</h3>
            <p className="pt-3">
              ฟังก์ชันการดูแลระบบของระบบซึ่งจะพร้อมใช้งานสำหรับผู้ใช้ที่มีสิทธิ์ของผู้ดูแลระบบเท่านั้น โดยมีจุดประสงค์เพื่อให้ผู้ดูแลระบบสามารถตั้งค่าและรักษาข้อมูลหลักซึ่งจะใช้กับระบบได้
            </p>
          </React.Fragment>
        </CardContent>
    </Card>
);
import React from 'react';
import PropTypes from "prop-types";
import ReactTooltip from 'react-tooltip';

class TooltipExclamationMark extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let { text, innerStyle, iconBeforeText } = this.props
    if (iconBeforeText) {
      return (
        <React.Fragment>
          <i className={`icon-error mr-1 ${innerStyle}`} data-tip={text}></i>
          <ReactTooltip multiline={true}  html={true}/>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <span class={`exclamation-mark ${innerStyle}`} data-tip={text}>!</span>
          <ReactTooltip multiline={true}  html={true}/>
        </React.Fragment>
      );
    }
  }
}
export default TooltipExclamationMark;

TooltipExclamationMark.propTypes = {
  text: PropTypes.string,
  innerStyle: PropTypes.string,
  iconBeforeText: PropTypes.bool,
};
import React from 'react';
import { withRouter } from "react-router-dom";

// Components
import VendorBilling from '../components/vendor/VendorBilling';

// Constants
import { PATH_VENDORS } from '../constants'

class Vendor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      current_page: props.match.params.path,
    }

    this.onChangeOrderPage = this.onChangeOrderPage.bind(this)
  }

  onChangeOrderPage(page) {
    this.setState({current_page: page})
  }

  render() {
    switch (this.state.current_page) {
      case PATH_VENDORS.billing:
        return (
          <VendorBilling
            {...this.props}/>
        );
      default:
        break;
    }
  }
}
export default withRouter(Vendor);
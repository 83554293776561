import axios from "axios";

const Core = {
  get: (url, params) => {
    return axios.get(url, { params });
  },
  post: (url, data) => {
    return axios.post(url, data);
  },
  put: (url, data) => {
    return axios.put(url, data);
  },
  del: (url, data) => {
    return axios.delete(url, data);
  },
  getWithData: (url, params) => {
    try {
      return axios
        .get(url, params)
        .then((response) => {
          console.log("response", response);
          return response.data;
        })
        .catch((error) => {
          return null;
        });
    } catch (error) {
      return null;
    }
  },
};

export default Core;

import React from "react";
import { Storage } from 'aws-amplify';
import PropTypes from "prop-types";

// functions
import { uniqueKey } from '../../functions/Random';

// Component
import Spinner from '../global/Spinner'

Storage.configure({
  AWSS3: {
    region: 'ap-southeast-1'
  }
})
class FileUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: props.url,
      is_loading: false,
      uniqueKey: uniqueKey()
    };

    this.onUploadFile = this.onUploadFile.bind(this);
  }

  componentDidMount() {
    this.checkImage()
  }

  componentDidUpdate() {
    this.checkImage()
  }

  checkImage() {
    let file_url = this.state.file
    if (file_url && file_url.indexOf("http") < 0) {
      Storage.get(file_url).then((url) => {
        this.setState({file: url, uniqueKey: uniqueKey()})
      })
    }
  }

  async onUploadFile(e) {
    const file = e.target.files[0];
    // let filetype = e.target.files[0].type.split('/')[1]
    let filename = `${this.props.file_category}_${file.name}`
    this.setState({is_loading: true})
    try {
      const result = await Storage.put(filename, file, {
        contentType: e.target.files[0].type // contentType is optional
      });
      const signedURL = await Storage.get(result.key)

      this.props.onUploadNewFile(`${result.key}`)
      this.setState({file: signedURL, is_loading: false})
    } catch (error) {
      console.log('Error uploading file: ', error);
      this.setState({is_loading: false})
    }
  }

  render() {
    let { disabled } = this.props
    let { file } = this.state
    let file_src = (file && (file.indexOf("?") >= 0)) ? file : `${file}?${this.state.uniqueKey}`
    const download = () => {
      if (file && file.indexOf("http") >= 0) {
        let filename = file.split('?')[0].replace(/^.*[\\\/]/, '')
        return (
          <a href={file_src} target="_blank" className="mb-2">{decodeURIComponent(filename)} (Click to Download)</a>
        )
      }
    }
    const preview = () => {
      if (file && file.indexOf("http") >= 0) {
        return (
          <img className="max-w-100p" src={file_src} onError={i => i.target.style.display='none'}/>
        )
      }
    }

    return (
      <div>
        <div>
          <Spinner loading={this.state.is_loading}/>
          <div className="d-flex flex-column">
            {download()}
            {preview()}
            <input type="file" name="myFile" onChange={this.onUploadFile} disabled={disabled}/>
          </div>
        </div>
      </div>
    );
  }
}
export default FileUploader;

FileUploader.propTypes = {
  file_category: PropTypes.string.isRequired,
  onUploadNewFile: PropTypes.func.isRequired,
  url: PropTypes.string,
  disabled: PropTypes.string
};
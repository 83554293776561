import React from 'react';
import { DocumentPreview } from '../global/DocumentPreview.js'
import ToggleSwitch from '../global/ToggleSwitch.js';
import { Divider } from 'antd';

export class MrFormDocuments extends React.Component {
  render() {
    let url = (this.props.mr === null) ? '' : this.props.mr.s3_base_url

    return (
      <div style={{ paddingBottom: 20 }}>
        <h5>MR Remark</h5>
        <div style={{ display: "grid", justifyContent: "space-between", marginBottom: 10, gridTemplateColumns: "25% 25% 25% 25%", background: "white", borderRadius: 5, padding: 10 }}>
          <b>ลำดับ</b>
          <b>ชื่อ Category</b>
          <b>Mr Remark</b>
          <b>
            แสดง Mr.Remark
          </b>
        </div>
        {this.props.request.manufacture_request_stations.map((mrs, index) => {
          return <><div className='border' style={{ marginBottom: 10, background: "white", borderRadius: 5, display: "grid", justifyContent: "space-between", gridTemplateColumns: "25% 25% 25% 25%", padding: 10 }}>
            <div>{index + 1}.</div>
            <div>{mrs?.item_category_name}</div>
            <div>{mrs?.item_category?.mr_remark || "-"}</div>
            <div>
              <ToggleSwitch style={{ width: "100%" }} onChange={(e) => this.props.onToggleMrRemark(e, index)} checked={mrs?.toggle_mr_remark} />
            </div>
          </div>
          </>
        })}



        <DocumentPreview
          docName="เอกสาร MR"
          url={url}
        />
      </div>
    );
  }
}
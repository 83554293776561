import React from 'react';
import DatePicker from "react-datepicker";
import Select from 'react-select'
import Spinner from '../../global/Spinner';

// Global component
import VendorSelect from '../../global/VendorSelect'
import Number from '../../global/Number'
import ExportExcel from '../../global/ExportExcel';
import SearchBar from '../../global/SearchBar';

// Functions
import { formatApiDate } from '../../../functions/Date'

// model
import { vendorBillingFields } from '../../../models/ManufactureRequest'

// constants
import {
  API_RESOURCES, APP_TITLE_VENDOR_BILLING, FORMAT_APP_DATE, MR_STATES
} from '../../../constants'

const VENDOR_BILL_STATES = [
  {value: "NULL", name: "รายการที่ยังไม่ได้รับเอกสาร"},
  {value: "NOTNULL", name: "รายการที่ได้รับเอกสารแล้ว"}
]
const OPTIONS_STATES = VENDOR_BILL_STATES.map((elm) => { return {value: elm.value, label: elm.name} })
const OPTIONS_SORT = [
  { value: '', label: '' },
  { value: 'code', label: 'MR No.' },
  { value: 'mr_date', label: 'MR Date' },
  { value: 'state', label: 'State' },
  { value: 'manufacturer_name', label: 'Manufacturer name' },
  { value: 'contract_no', label: 'Contract No.' },
]

export default function VendorBilling(props) {
  const inputField = (elm, field, text_align, type, disabled) => {
    if (type === "Number") {
      return (
        <Number
          innerClass={text_align}
          name={field}
          value={elm[field]}
          onChange={(e) => props.onChangeMrInfo(e, elm.id)}
          disabled={disabled}
          key={elm.id}
        />
      )
    }
    else if (type === "Text") {
      let row_nb = elm[field] && elm[field].length > 25 ? 5 : 1
      return (
        <textarea className="form-control"
                  name={field}
                  rows={row_nb}
                  onChange={(e) => props.onChangeMrInfo(e, elm.id)}
                  value={elm[field]}
                  key={elm.id}
                  disabled={disabled}></textarea>
      )
    } else {
      return (
        <input className={`form-control ${text_align}`}
              name={field}
              value={elm[field]}
              key={elm.id}
              onChange={(e) => props.onChangeMrInfo(e, elm.id)}
              disabled={disabled}></input>
      )
    }
  }

  const tableHeader = () => {
    return (
      <div className="list-header mt-2 index">
        <div className="row mt-1">
          <div className="col-5 text-center">บริษัท/สถานี</div>
          <div className="col pl-0 text-center">{vendorBillingFields['tb_po_invoice_nb']}</div>
          <div className="col pl-0 text-center">{vendorBillingFields['tb_design_eng_nb']}</div>
          <div className="col pl-0 text-center">{vendorBillingFields['tb_rwt_nb']}</div>
          <div className="col pl-0 text-center">{vendorBillingFields['tb_received_date']}</div>
          <div className="col-2 pl-0 text-center">{vendorBillingFields['tb_remark']}</div>
          <div className="col-p5 pl-0 text-center"></div>
        </div>
      </div>
    )
  }

  const mrItems = () => {
    const { requests } = props
    const items = requests.map((elm, index) => {
      const is_disabled = !elm.is_updated && elm.tb_received_date
      const color_style = MR_STATES.cancelled === elm.state ? 'color-darkgray01' : ''
      return (
        <div className={`list-item index ${color_style}`} key={''+ elm.no + index}>
          <div className="row">
            <div className="col-5 pl-4 d-flex flex-column">
              <div className="d-flex">
                <a href={`/${API_RESOURCES.mr}/${elm.id}`} target="_blank">
                  <i className="icon-open-new invert-07 cursor-pointer"></i>
                </a>
                <div className="pl-1 ">{elm.manufacturer_name} [{elm.state}]</div>
              </div>
              {elm.manufacture_request_stations.map((station, index) => {
                return (
                  <div className="row">
                    <div className="col-1"></div>
                    <div className="col-7">{index + 1}. {station.station_name}</div>
                    <div className="col">{station.item_category_name}</div>
                  </div>
                )
              })}
            </div>
            <div className="col pl-0">{inputField(elm, "tb_po_invoice_nb", "", null, is_disabled)}</div>
            <div className="col pl-0">{inputField(elm, "tb_design_eng_nb", "", null, is_disabled)}</div>
            <div className="col pl-0">{inputField(elm, "tb_rwt_nb", "", null, is_disabled)}</div>
            <div className="col pl-0 date-picker-wrapper">
              <DatePicker
                className="form-control"
                dateFormat={FORMAT_APP_DATE}
                selected={elm.tb_received_date ? new Date(elm.tb_received_date) : null}
                onChange={date => props.onChangeDate(elm.id, "tb_received_date", date)}
                disabled={is_disabled}
              />
            </div>
            <div className="col-2 pl-0">{inputField(elm, "tb_remark", "", "Text", is_disabled)}</div>
            <div className="col-p5 d-flex align-items-center">
              {is_disabled ? <i className="icon-edit" onClick={() => props.onClickEdit(elm.id)}></i>  : ''}
            </div>
          </div>
        </div>
      )
    })
    return items
  }

  const { selected_date_form, selected_date_to, export_report } = props
  const text_date_from = formatApiDate(selected_date_form)
  const text_date_to = formatApiDate(selected_date_to)
  const saveBTN = () => {
    return (
      <button type="button" className="btn btn-primary btn-sm"
              onClick={props.onSaveMrRequests} disabled={!props.is_updated}>Save</button>
    )
  }
  return (
    <React.Fragment>
      <Spinner loading={props.is_refilltering || props.is_loading}/>
      <div className="p-3 home-scrollable">
        <div className="row">
          <div className="col-8">
            <h2>{APP_TITLE_VENDOR_BILLING}</h2>
          </div>
          <div className="col text-right">
            <ExportExcel
              data={[export_report]}
              content={"Export"}
              filename={`วางบิล_${text_date_from ? text_date_from + '_' : ''}${text_date_to ? text_date_to : ''}`}
              customClass={"btn btn-success ml-2"}
              disabled={export_report === null}
            />
          </div>
        </div>

        <div className="card-box light p-3">
        <form onSubmit={props.handleSubmit}>
          <SearchBar
            onEnter={props.onEnter}
            onChangeQuery={props.onChangeQuery}
            onClickClear={props.onClickClear}
            query={props.query}
            placeholder={"ค้นหา เลขที่ใบสั่งผลิต, เลขที่สัญญา, สถานี..."}
          />
          <div className="row">
            <div className="col-4 col-md-2 mt-2"><label>วันที่ MR</label></div>
            <div className="col-8 col-md-4 mt-2 date-picker-wrapper">
              <DatePicker className="form-control" selected={props.selected_date_form} onChange={date => props.onChangeFromDate(date)} dateFormat={FORMAT_APP_DATE}/>
            </div>
            <div className="col-4 col-md-2 mt-2"><label>ถึง</label></div>
            <div className="col mt-2 date-picker-wrapper">
              <DatePicker className="form-control" selected={props.selected_date_to} onChange={date => props.onChangeToDate(date)} dateFormat={FORMAT_APP_DATE}/>
            </div>
          </div>

          <div className="row">
            <div className="col-4 col-md-2 mt-2"><label>สถานะ</label></div>
            <div className="col-8 col-md-4 mt-2">
              <Select
                isClearable
                value={props.selected_state}
                onChange={props.onChangeState}
                options={OPTIONS_STATES}
              />
            </div>
            <div className="col-4 col-md-2 mt-2"><label>ผู้ผลิต</label></div>
            <div className="col-8 col-md-4 mt-2">
              <VendorSelect
                onChange={props.onChangeVendor}
                vendorId={props.selected_vendor}
                disabled={false}
              />
            </div>
            {/* Visible xs, sm  */}
            <div className="col-4 col-md-2 mt-2 d-block d-md-none"><label>Sort By</label></div>
            <div className="col mt-2 d-block d-md-none">
              <Select
                value={OPTIONS_SORT.filter(option => option.value === props.selected_sorting)}
                onChange={props.onChangeSorting}
                options={OPTIONS_SORT}
              />
            </div>
          </div>

          {/* Visible xs, sm  */}
          <div className="row d-block d-md-none">
            <div className="col-12 mt-3 text-right">
              <button type="submit" className="btn btn-primary ml-2">Search</button>
              <button type="button" className="btn btn-danger ml-2" onClick={props.onClickClear}>clear</button>
            </div>
          </div>
        </form>
        </div>

        <div className="text-right mt-2">
          {saveBTN()}
        </div>
        <div className="">
          {tableHeader()}
          {mrItems()}
        </div>
        <div className="text-right mt-2">
          {saveBTN()}
        </div>
        <div className="block-info-buffer-box"></div>
      </div>
    </React.Fragment>
  );
}
import React from "react";
import DatePicker from "react-datepicker";
import _ from "lodash";
import Select from "react-select";
import { FORMAT_APP_DATE } from "../../../constants.js";
import moment from "moment";

// Models
import { WARRANTY_OPTIONS } from "../../../models/ItemCategories";
import { CheckCircleOutlined } from "@ant-design/icons";

// Component
import Number from "../../global/Number";
import ToggleSwitch from "../../global/ToggleSwitch";
import { OrderFormOtherInfo } from "../OrderFormOtherInfo";

// Function
import { formatDate, formatApiDate } from "../../../functions/Date";
import { objectIsEmpty } from "../../../functions/Object";
import { Image, Popover } from "antd";

export class OrderFormInstallation extends React.Component {
  constructor(props) {
    super(props);
    let order_item_categories = {};
    Object.keys(props.order.order_item_categories).map((key) => {
      props.order.order_item_categories[key].opened = true;
      order_item_categories[key] = props.order.order_item_categories[key];
    });
    let firstkey = Object.keys(props.order.order_item_categories)[0];
    this.state = {
      order_item_categories: order_item_categories,
      informed_date: props.order.quotation.approved_state_date ? props.order.quotation.approved_state_date : null,
    };

    this.onChangeInstallationDate = this.onChangeInstallationDate.bind(this);
    this.onChangeWarrantyType = this.onChangeWarrantyType.bind(this);
    this.onChangeStationReady = this.onChangeStationReady.bind(this);
    this.onChangeCatStationReady = this.onChangeCatStationReady.bind(this);
  }

  onChangeStationReady = (e, id, days) => {
    let ready = {
      target: {
        name: "station_ready",
        value: e ? formatApiDate(new Date()) : null,
      },
    };
    this.props.onChangeItemManufactureDetails(ready, id);
    let num = {
      target: {
        name: "days_to_installation",
        value: days <= 0 ? 0 : days,
      },
    };
    this.onChangeInstallationNumofD(num, id);
  };

  onChangeCatStationReady(e, items) {
    if (e) {
      items = items.filter((items) => items.station_ready == null);
    } else {
      items = items.filter((items) => items.station_ready != null);
    }
    items.map((item) => {
      this.onChangeStationReady(e, item.id, item.days_to_installation);
    });
  }

  onChangeInstallationDate = (date, id) => {
    let { order } = this.props;
    let date_h = {
      target: {
        name: "installation_date",
        value: date != null ? formatApiDate(date) : formatApiDate(new Date()),
      },
    };
    this.props.onChangeItemManufactureDetails(date_h, id);

    let approved_state_date_moment = moment(new Date(order.quotation.approved_state_date));
    let num = moment(new Date(date)).startOf("day").diff(approved_state_date_moment.startOf("day"), "days");

    let num_days = {
      target: {
        name: "days_to_installation",
        value: num,
      },
    };
    this.props.onChangeItemManufactureDetails(num_days, id);
  };

  // when change days to installation to change installation date
  onChangeInstallationNumofD = (num, id) => {
    let { order } = this.props;
    this.props.onChangeItemManufactureDetails(num, id);

    let approved_state_date_moment = moment(new Date(order.quotation.approved_state_date));
    let date = moment(approved_state_date_moment).add(num["target"].value, "days");

    let date_h = {
      target: {
        name: "installation_date",
        value: formatApiDate(date),
      },
    };
    this.props.onChangeItemManufactureDetails(date_h, id);
  };

  onChangeWarrantyType(e, id) {
    let type_h = {
      target: {
        name: "warranty_type",
        value: e.value,
      },
    };
    this.props.onChangeItemManufactureDetails(type_h, id);
  }

  onClickRow = (key) => {
    let { order_item_categories } = this.state;
    order_item_categories[key].opened = !order_item_categories[key].opened;

    this.setState({ order_item_categories: order_item_categories });
  };

  displayItems() {
    let { order, disabled } = this.props;
    let { order_item_categories } = this.state;
    let order_items = order.purchasing_order_items.filter((elm) => !elm.is_mbom);
    if (objectIsEmpty(order_item_categories)) {
      return false;
    }

    let itemsByCate = _.groupBy(order_items, function (value) {
      return value.item_category_id;
    });
    let items = [];
    Object.keys(itemsByCate).forEach((key) => {
      let is_opened = order_item_categories[key].opened;
      itemsByCate[key].map((elm, index) => {
        if (index === 0) {
          items.push(
            <div className="row p-2 order-manu-header" onClick={() => this.onClickRow(key)}>
              <div className="col-1 m-auto text-center">
                <i className={`${is_opened ? "icon-arrow-up" : "icon-arrow-down"} mr-1`} />
              </div>
              <div className="col-4 d-flex align-items-center text-left">{order_item_categories[key].sys_name}</div>
              <div className="col-7">
                <div className="row align-items-center justify-content-end pr-1">
                  <div className="pr-1">{`สถานีพร้อมติดตั้งทั้งหมวด ${is_opened}`}</div>
                  <ToggleSwitch checked={itemsByCate[key].every((item) => item.station_ready != null)} onChange={(e) => this.onChangeCatStationReady(e, itemsByCate[key])} disabled={disabled} />
                </div>
              </div>
            </div>
          );
        }

        if (is_opened) {
          items.push(
            <React.Fragment>
              <div className={`row p-2 ${index % 2 === 0 ? "" : "bg-aliceblue"}`}>
                <div className="col-1 m-auto text-center items-name">{index + 1}</div>
                <div className="row col-11 m-auto items-name">
                  <span>{`${elm.code} - ${elm.name}`}</span>
                  {elm.delivery_date && (
                    <span className="pl-3">
                      <Popover
                        content={
                          <div>
                            <div style={{ color: "#8c8c8c", fontSize: 16 }}>จัดส่งติดตั้งแล้วเมื่อ {moment(elm.delivery_date).format("DD-MM-YYYY")}</div>
                            {elm.delivery_images !== null && (
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "auto auto",
                                  gridGap: 15,
                                  marginTop: 15,
                                }}
                              >
                                {elm.delivery_images.map((image) => {
                                  return <Image width={100} src={image.url} />;
                                })}
                              </div>
                            )}
                          </div>
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 5,
                            color: "green",
                            cursor: "pointer",
                          }}
                        >
                          <CheckCircleOutlined style={{ color: "green", fontSize: 20 }} /> จัดส่ง / ติดตั้งแล้ว
                        </div>
                      </Popover>
                    </span>
                  )}
                </div>
              </div>
              <div className={`row p-2 align-items-center flex-row justify-content-between order-item-header`}>{this.displayItemHeader()}</div>
              <div className={`row p-2 align-items-center ${index % 2 === 0 ? "" : "bg-aliceblue"}`}>
                <div className="col-5 text-center">
                  <div className="row">
                    <div className="col-5 align-items-center">
                      <DatePicker
                        className="form-control text-right"
                        dateFormat={FORMAT_APP_DATE}
                        selected={elm.installation_date ? new Date(elm.installation_date) : null}
                        onChange={(date) => this.onChangeInstallationDate(date, elm.id)}
                        disabled={disabled}
                        placeholderText="วันที่ติดตั้ง"
                      />
                    </div>
                    <div className="col-1 text-center m-auto">|</div>
                    <div className="col-5 align-items-center">
                      <Number
                        innerClass="text-right"
                        name={"days_to_installation"}
                        value={elm.days_to_installation == 0 ? 0 : elm.days_to_installation}
                        onChange={(e) => this.onChangeInstallationNumofD(e, elm.id)}
                        disabled={disabled}
                        placeholder="วัน"
                      />
                    </div>
                    <div className="col-1 text-center m-auto">วัน</div>
                  </div>
                </div>
                <div className="col-2 text-center m-auto">
                  <Number innerClass="text-right" name={"warranty_value"} value={elm.warranty_value} onChange={(e) => this.props.onChangeItemManufactureDetails(e, elm.id)} disabled={this.props.disabled} />
                </div>
                <div className="col-1 p-0 text-center m-auto">
                  <Select value={WARRANTY_OPTIONS.filter((option) => option.value == elm.warranty_type)} onChange={(e) => this.onChangeWarrantyType(e, elm.id)} options={WARRANTY_OPTIONS} isDisabled={this.props.disabled} />
                </div>
                <div className="col-2 text-center m-auto">{formatDate(elm.expiration_date)}</div>

                {/* Station Ready to Install */}
                <div className="col-2 text-center m-auto align-items-center">
                  <ToggleSwitch checked={elm.station_ready != null} onChange={(e) => this.onChangeStationReady(e, elm.id, elm.days_to_installation)} disabled={disabled} />
                </div>
              </div>
            </React.Fragment>
          );
        }
      });
    });

    return items;
  }

  displayHeader() {
    return (
      <React.Fragment>
        <div className="row px-3">
          <div className="col-1 m-auto text-center">ลำดับ</div>
          <div className="col-11">หมวดหมู่ - สินค้า DC</div>
        </div>
      </React.Fragment>
    );
  }

  displayItemHeader() {
    return (
      <React.Fragment>
        <div className="col-5 text-center">
          <div className="row">
            <div className="col-5 align-items-center">วันที่ติดตั้ง</div>
            <div className="col-1 align-items-center">|</div>
            <div className="col-5 align-items-center">วันจากวันที่แจ้ง</div>
            <div className="col-1" />
          </div>
        </div>
        <div className="col-3 text-center">รับประกัน</div>
        <div className="col-2 text-center">หมดประกัน</div>
        <div className="col-2 text-center">{!this.props.disabled ? <b>สถานีพร้อมติดตั้ง</b> : <div className="disabled-pale-text">สถานีพร้อมติดตั้ง</div>}</div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="col quo-scrollable-table">
          <div className="row px-4 pt-1 pb-4">
            <div className="col-6">
              <h5 className="pb-1">ข้อมูลการติดตั้ง</h5>
            </div>
            <div className="col d-flex justify-content-end">
              <b>วันที่แจ้งผู้ผลิต: {this.state.informed_date}</b>
            </div>
            <div className="col-12">
              <div className="card-box p-2 d-none d-md-block">{this.displayHeader()}</div>
              <div className="card-box px-3 mt-1">{this.displayItems()}</div>
              <OrderFormOtherInfo
                page_nb={this.props.page_nb}
                note={this.props.order.order_notes.filter((elm) => elm.page_nb === this.props.page_nb)[0]}
                onChangeNote={this.props.onChangeNote}
                disabled={this.props.disabled}
                key={this.props.resetKeyNote}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// in maintenance_structure_charts.js
import * as React from "react";
import { useState, useEffect } from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  DeleteWithConfirmButton,
  TextField,
  EditButton,
  TextInput,
  required,
  useListContext,
  ReferenceInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  AutocompleteInput,
  FormDataConsumer,
} from "react-admin";
import BookIcon from "@material-ui/icons/Book";
import { Form } from "react-final-form";
import { Box, Button as CoreButton, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ContentFilter from "@material-ui/icons/FilterList";
import { isExpired } from "../../functions/Auth";
import StatusModal from "../../components/global/modal/modal.jsx";
import axios from "axios";
import { Auth } from "aws-amplify";
import { EditOutlined, WarningFilled, UserAddOutlined, SaveOutlined } from "@ant-design/icons";
import { exporter } from "../../functions/reactAdmin";
import { EditToolbar, EditActions } from "./Default";
import { Button, Input, Modal, Space, Switch, Table, TreeSelect } from "antd";
import _ from "lodash";
export const MaintenanceStructureChartIcon = BookIcon;
const { SHOW_PARENT } = TreeSelect;

const MaintenanceStructureChartFilter = (props) => {
  return props.context === "button" ? <MaintenanceStructureChartFilterButton {...props} /> : <MaintenanceStructureChartFilterForm {...props} />;
};

const MaintenanceStructureChartFilterButton = (props) => {
  const { showFilter } = useListContext();
  return (
    <React.Fragment>
      <CoreButton size="small" color="primary" onClick={() => props.onClickEditMode()} startIcon={<EditOutlined />}>
        Edit Mode
      </CoreButton>
      <CoreButton size="small" color="primary" onClick={() => showFilter("main")} startIcon={<ContentFilter />}>
        Filter
      </CoreButton>
    </React.Fragment>
  );
};

const MaintenanceStructureChartFilterForm = ({ open }) => {
  const { displayedFilters, filterValues, setFilters, hideFilter } = useListContext();

  if (!displayedFilters.main) return null;

  const onSubmit = (values) => {
    if (Object.keys(values).length > 0) {
      setFilters(values);
    } else {
      hideFilter("main");
    }
  };

  const resetFilter = () => {
    setFilters({}, []);
  };

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box mt={8} />
            <Box display="flex" alignItems="flex-end" mb={1}>
              <Box component="span" mr={3}>
                <BooleanInput source="is_active" label="เปิดใช้งาน" alwaysOn />
              </Box>
              <Box component="span" mr={2}>
                {/* Full-text search filter. We don't use <SearchFilter> to force a large form input */}
                <TextInput
                  resettable
                  helperText={false}
                  source="name"
                  label="Search ชื่อ"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box component="span" mr={2}>
                {/* Full-text search filter. We don't use <SearchFilter> to force a large form input */}
                <TextInput
                  resettable
                  helperText={false}
                  source="code"
                  label="Search รหัสหนักงาน"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box component="span" mr={2} mb={1.5}>
                <CoreButton variant="outlined" color="primary" type="submit">
                  Filter
                </CoreButton>
              </Box>
              <Box component="span" mb={1.5}>
                <CoreButton variant="outlined" onClick={resetFilter}>
                  Close
                </CoreButton>
              </Box>
            </Box>
          </form>
        )}
      </Form>
    </div>
  );
};

export const MaintenanceStructureChartList = (props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [provincesData, setProvincesData] = useState([]);
  const [provinceArray, setProvinceArray] = useState([]);
  const [leftProvince, setLeftProvince] = useState([]);
  const [duplicateSelected, setDuplicateSelected] = useState([]);
  const [list, setList] = useState([]);
  const [tempList, setTempList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [visibleStatusModal, setVisibleStatusModal] = useState(false);
  const [modalDetail, setModalDetail] = useState({ title: "Untitle", detail: "Success Detail", type: "success" });
  const [currentSearch, setCurrentSearch] = useState(null);
  const [nameList, setNameList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [searchName, setSearchName] = useState([]);
  const [searchProvince, setSearchProvince] = useState([]);
  const [sessionToken, setSessionToken] = useState();

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "5%",
    },
    {
      title: "การเปิดใช้งาน",
      dataIndex: "is_active",
      key: "is_active",
      width: "10%",
      render: (is_active, data) => {
        return <Switch checked={is_active} onChange={(e) => onClickSwtich(e, data)} />;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "15%",
      render: (name, data) => {
        return <Input value={name} onChange={(e) => onChangeEdit(e, "name", data)} />;
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: "15%",
      render: (code, data) => {
        return <Input value={code} onChange={(e) => onChangeEdit(e, "code", data)} />;
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: "15%",
      render: (phone, data) => {
        return <Input value={phone} onChange={(e) => onChangeEdit(e, "phone", data)} />;
      },
    },
    {
      title: "จังหวัด - อำเภอ",
      dataIndex: "provinces_districts",
      key: "provinces_districts",
      render: (_, data) => {
        const tree_props = {
          treeData: provincesData,
          value: parseDataToArray(data),
          onChange: (e) => onChangeProvince(e, data),
          treeCheckable: true,
          showCheckedStrategy: SHOW_PARENT,
          placeholder: "Please select",
          style: {
            width: "100%",
          },
        };

        return <TreeSelect {...tree_props} />;
      },
    },
  ];

  function onChangeProvince(e, data) {
    const new_provinces_districts = [];
    e.forEach((item) => {
      const split = item.split("-");
      const provinces_districts = {
        province_id: parseInt(split[0]),
        province_name: split[1],
        district_id: parseInt(split[2]) || null,
        district_name: split[3] || null,
      };

      new_provinces_districts.push(provinces_districts);
    });

    const new_value_provinces = [...list];
    new_value_provinces[list.findIndex((item) => item.id === data.id)].provinces_districts = new_provinces_districts;

    checkProvinceDuplicateOrLost(new_value_provinces);
    setList(new_value_provinces);
  }

  const isSameLocation = (a, b) => a === b;
  const leftLocation = (left, right, compare) => left.filter((leftValue) => !right.some((rightValue) => compare(leftValue, rightValue)));
  const filterDuplicate = (items) => items.filter((item, index) => items.indexOf(item) !== index);

  function checkProvinceDuplicateOrLost(provinces_value, provinces_array = null, provinces_data = null) {
    const province_selected_array = [];

    provinces_value.forEach((item) => {
      if (item.is_active) {
        item.provinces_districts.forEach((value) => {
          if (value.province_name && !value.district_name) {
            const find_index =
              provinces_data !== null
                ? provinces_data.findIndex((province) => parseInt(province.value.split("-")[0]) === parseInt(value.province_id))
                : provincesData.findIndex((province) => parseInt(province.value.split("-")[0]) === parseInt(value.province_id));
            province_selected_array.push(`${value.province_name}`);

            if (find_index !== -1) {
              if (provinces_data !== null) {
                provinces_data[find_index].children.forEach((children) => {
                  province_selected_array.push(`${children.title}`);
                });
              } else {
                provincesData[find_index].children.forEach((children) => {
                  province_selected_array.push(`${children.title}`);
                });
              }
            }
          } else {
            province_selected_array.push(`${value.province_name}-${value.district_name}`);
          }
        });
      }
    });

    const onlySelect = leftLocation(province_selected_array, provinces_array !== null ? provinces_array : provinceArray, isSameLocation);
    const onlyProvinceArray = leftLocation(provinces_array !== null ? provinces_array : provinceArray, province_selected_array, isSameLocation);
    const leftProvince = [...onlySelect, ...onlyProvinceArray];
    const duplicate_selected = filterDuplicate(province_selected_array);
    let result = compareDistriceAndProvince(leftProvince, provinces_data !== null ? provinces_data : provincesData);
    let result_duplicate_selected = [];

    duplicate_selected.forEach((duplicate) => {
      const data_province = provinces_data !== null ? provinces_data : provincesData;
      const is_district = duplicate.split("-").length > 1;
      const province = duplicate.split("-")[0];
      const index_of_data_province = data_province.findIndex((value) => value.title === province);
      const province_index = province_selected_array.findIndex((select) => select.toString() === province.toString());
      const district_index = province_selected_array.findIndex((select) => select.toString() === duplicate.toString());

      if (!is_district) {
        if (province_index !== -1) {
          result_duplicate_selected.push(duplicate);
        }
      } else {
        if (district_index !== -1) {
          let count = 0;
          duplicate_selected.forEach((select) => {
            if (duplicate.split("-")[0] === select.split("-")[0]) {
              count = count + 1;
            }
          });

          if (data_province[index_of_data_province].children.length === count - 1) {
            result_duplicate_selected.push(province);
          } else {
            result_duplicate_selected.push(duplicate);
          }
        }
      }
    });

    result_duplicate_selected = [...new Set(result_duplicate_selected)];

    setDuplicateSelected(result_duplicate_selected);
    setLeftProvince(result);
  }

  function compareDistriceAndProvince(leftProvince, provinces_data) {
    let result = [];
    leftProvince.forEach((left) => {
      let count_districts = 0;
      let district_with_province = [];
      const find_index = provinces_data.findIndex((value) => value.title === left);
      if (find_index !== -1) {
        leftProvince.forEach((check) => {
          if (check.split("-")[0] === left && check !== left) {
            count_districts = count_districts + 1;
            district_with_province.push(check);
          }
        });

        if ((provincesData.length > 0 && provincesData[find_index].children.length === count_districts) || (provinces_data !== null && provinces_data[find_index].children.length === count_districts)) {
          result = [...result, left];
        } else {
          result = [...result, ...district_with_province];
        }
      }
    });

    return result;
  }

  function parseDataToArray(data) {
    const new_array_data = [];
    if (data && data.provinces_districts) {
      data.provinces_districts.forEach((item) => {
        if (item.province_name && !item.district_name) new_array_data.push(`${item.province_id}-${item.province_name}`);
        else new_array_data.push(`${item.province_id}-${item.province_name}-${item.district_id}-${item.district_name}`);
      });
    }
    return new_array_data;
  }

  function onChangeEdit(e, object_name, data) {
    let new_value_state = [...list];
    new_value_state[list.findIndex((item) => item.id === data.id)][object_name] = e.target.value;
    setList(new_value_state);
  }

  function onClickSwtich(e, data) {
    let new_check_state = [...list];
    new_check_state[list.findIndex((item) => item.id === data.id)].is_active = e;

    checkProvinceDuplicateOrLost(new_check_state);
    setList(new_check_state);
  }

  function onClickEditMode() {
    setIsEditMode((prevState) => !prevState);
  }

  useEffect(() => {
    getAuthData();
  }, []);

  useEffect(() => {
    if (isEditMode) getAuthData();
  }, [isEditMode]);

  function getAuthData() {
    setIsLoading(true);
    return Auth.currentSession()
      .then((session) => {
        const refreshToken = session.getRefreshToken();
        if (isExpired(session)) {
          Auth.currentAuthenticatedUser().then((res) => {
            res.refreshSession(refreshToken, (err) => {
              if (err) {
                Auth.signOut();
              } else {
                setSessionToken(session.getIdToken().getJwtToken());
                getListProvinces(session.getIdToken().getJwtToken());
              }
            });
          });
        } else {
          setSessionToken(session.getIdToken().getJwtToken());
          getListProvinces(session.getIdToken().getJwtToken());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getListProvinces(token) {
    axios
      .get(`${process.env.REACT_APP_API_URL}/provinces`, {
        headers: {
          Authorization: token,
        },
      })
      .then((provinces_res) => {
        if (provinces_res.status === 200) {
          axios
            .get(`${process.env.REACT_APP_API_URL}/districts`, {
              headers: {
                Authorization: token,
              },
            })
            .then((districts_res) => {
              if (districts_res.status === 200) {
                arrangeProvincsData(provinces_res.data, districts_res.data, token);
              }
            })
            .catch((error) => {
              // console.log("error :>> ", error);
            });
        }
      })
      .catch((error) => {
        // console.log("error :>> ", error);
      });
  }

  async function arrangeProvincsData(provinces, districts, token) {
    const tree_data = [];
    const provinces_array = [];
    provinces.forEach((province) => {
      const children = [];
      const province_tree = {
        title: province.name,
        value: `${province.id}-${province.name}`,
        key: `${province.id}-${province.name}`,
        children: [],
      };

      provinces_array.push(`${province.name}`);

      districts.forEach((district) => {
        if (district.province_id === province.id) {
          const district_children = {
            title: `${province.name}-${district.name}`,
            value: `${province.id}-${province.name}-${district.id}-${district.name}`,
            key: `${province.id}-${province.name}-${district.id}-${district.name}`,
          };

          provinces_array.push(`${province.name}-${district.name}`);
          children.push(district_children);
        }
      });

      province_tree.children = children;
      tree_data.push(province_tree);
    });

    setProvinceArray(provinces_array);
    setProvincesData(tree_data);
    await getListData(token)
      .then((response) => {
        let new_res = removeProvinceWhenHaveDistrict(response);

        setIsLoading(false);
        setList(new_res);
        setTempList(new_res);
        setSearchNameAndCode(new_res);
        checkProvinceDuplicateOrLost(new_res, provinces_array, tree_data);
      })
      .catch((error) => console.log("error :>> ", error));
  }

  function removeProvinceWhenHaveDistrict(response) {
    const new_response = response;
    new_response.forEach((value, index) => {
      let new_provinces_districts = [];
      value.provinces_districts.forEach((items) => {
        const not_only_province = value.provinces_districts.filter((item) => item.province_id === items.province_id && items.district_id === null);

        if (not_only_province.length <= 1) {
          new_provinces_districts.push(items);
        }
      });
      new_response[index].provinces_districts = new_provinces_districts;
    });
    return new_response;
  }

  function setSearchNameAndCode(data) {
    const name_list = [];

    data.forEach((item) => {
      const name_params = {
        title: `${item.name} (${item.code})`,
        key: `${item.name} (${item.code})`,
        value: `${item.name} (${item.code})`,
      };

      name_list.push(name_params);
    });

    setNameList(name_list);
  }

  function getListData(token) {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/maintenance_structure_charts`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data.sort((a, b) => (a.id > b.id ? 1 : -1));
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
      });
  }

  function onCloseEditModal() {
    if (list === tempList) {
      setIsEditMode(false);
    } else {
      setModalDetail({ title: "ยกเลิกการแก้ไข  !", detail: "มีการเปลี่ยนแปลงข้อมูล หากทำการปิดหน้าต่าง การแก้ไขจะถูกยกเลิก คุณต้องการยกเลิกใช่หรือไม่ ?", type: "warning", onClickOk: onClickOKModalStatus, onClickCancel: onClickCancelModalStatus });
      setIsLoading(true);
      setVisibleStatusModal(true);
    }
  }

  function onClickCancelModalStatus() {
    setVisibleStatusModal(false);
    setIsLoading(false);
  }

  function onClickOKModalStatus() {
    setVisibleStatusModal(false);
    setIsEditMode(false);
  }

  function onCreateData() {
    const new_data = [...list];
    console.log("list.length :>> ", list.length);
    console.log("list :>> ", list);
    const empty = {
      type: "add",
      id: parseInt(list[list.length - 1].id) + 1,
      name: "",
      code: "",
      phone: "",
      provinces_districts: [],
      is_active: true,
    };
    new_data.push(empty);
    setList(new_data);
  }

  function onSaveData() {
    if (leftProvince.length > 0 || duplicateSelected.length > 0) {
      setModalDetail({
        title: "บันทึกข้อมูลไม่สำเร็จ !",
        detail: (
          <>
            {leftProvince.length > 0 && (
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "left", alignItems: "center", color: "red", fontWeight: "bold", fontSize: 14, gap: 5, marginBottom: 15 }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: 14, gap: 15 }}>
                  <WarningFilled style={{ color: "red", fontSize: 26 }} />
                  <span style={{ fontSize: 16 }}>จังหวัด - อำเภอ ต่อไปนี้ยังไม่มีผู้รับผิดชอบ</span>
                </div>
                <div style={{ fontWeight: "normal" }}>{leftProvince.join(", ")}</div>
              </div>
            )}
            {duplicateSelected.length > 0 && (
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "left", alignItems: "center", color: "red", fontWeight: "bold", fontSize: 14, gap: 5, marginBottom: 15 }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: 14, gap: 15 }}>
                  <WarningFilled style={{ color: "red", fontSize: 26 }} />
                  <span style={{ fontSize: 16 }}>จังหวัด - อำเภอ ต่อไปนี้มีผู้รับผิดชอบมากกว่า 1 คน</span>
                </div>
                <div style={{ fontWeight: "normal" }}>{duplicateSelected.join(", ")}</div>
              </div>
            )}
          </>
        ),
        type: "failed",
        onClickOk: isCanNotSaveData,
        showCancel: false,
      });
      setVisibleStatusModal(true);
    } else {
      isCanSaveData(list);
    }
  }

  function isCanNotSaveData() {
    setVisibleStatusModal(false);
  }

  function isCanSaveData(data) {
    const new_data = [];

    data.forEach((item) => {
      const province_group = _.groupBy(item.provinces_districts, "province_id");
      let province = [];

      Object.keys(province_group).map((key) => {
        const prams = {
          district_id: null,
          district_name: null,
          province_id: province_group[key][0].province_id,
          province_name: province_group[key][0].province_name,
        };

        province.push(prams);
      });

      const params_item = {
        code: item.code,
        id: item.id,
        is_active: item.is_active,
        name: item.name,
        phone: item.phone,
        province_ids: parseDataProvinces(province),
        district_ids: parseDataDistricts(item.provinces_districts),
        type: item.type || null,
      };
      new_data.push(params_item);
    });

    const params = {
      list: new_data,
    };

    setIsLoading(true);

    axios
      .put(`${process.env.REACT_APP_API_URL}/maintenance_structure_charts_list`, {
        params,
        headers: {
          Authorization: sessionToken,
        },
      })
      .then(() => {
        setModalDetail({ title: "สำเร็จ  !", detail: "ระบบทำการบันทึกข้อมูลของคุณสำเร็จแล้ว !", type: "success", onClickOk: onClickCreateDataSuccess, showCancel: false });
        setVisibleStatusModal(true);
      })
      .catch((error) => {
        setModalDetail({ title: "ไม่สำเร็จ  !", detail: "ระบบทำการบันทึกข้อมูลของคุณไม่สำเร็จแล้วกรุณาลองใหม่อีกครั้ง !", type: "failed", onClickOk: onClickCreateDataFailed, showCancel: false });
        setVisibleStatusModal(true);
      });
  }

  function onClickCreateDataFailed() {
    setIsLoading(false);
    setVisibleStatusModal(false);
  }

  function onClickCreateDataSuccess() {
    setVisibleStatusModal(false);
    setIsEditMode(false);
    window.location.reload();
  }

  function parseDataProvinces(value) {
    let new_data = [];
    value.forEach((province) => {
      if (!province.district_id) new_data.push(province.province_id);
    });

    new_data = [...new Set(new_data)];
    return new_data;
  }

  function parseDataDistricts(value) {
    let new_data = [];
    value.forEach((province) => {
      if (province.district_id) new_data.push(province.district_id);
    });

    new_data = [...new Set(new_data)];

    return new_data;
  }

  function handleSearch(e, searchType) {
    const filter = [];

    if (searchType === "name") {
      setSearchName(e);
    } else {
      setSearchProvince(e);
    }

    if (searchName.length === 0 && searchProvince.length === 0) {
      setCurrentSearch(searchType);
    }

    if (searchType === "name") {
      e.forEach((text) => {
        const find = list.find((item) => item[searchType] === text.split(" (")[0]);
        if (find) filter.push(find);
      });

      if (searchProvince.length > 0) {
        searchProvince.forEach((text) => {
          const is_district = text.split("-").length > 2;
          const text_split = text.split("-");
          const find = [];
          if (is_district) {
            list.forEach((item) => {
              const is_match = item.provinces_districts.filter((location) => location.district_name === text_split[3] && location.province_name === text_split[1]);
              if (is_match.length > 0) filter.push(item);
            });
          } else {
            list.forEach((item) => {
              const is_match = item.provinces_districts.filter((location) => location.province_name === text_split[1]);
              if (is_match.length > 0) filter.push(item);
            });
          }
          if (find.length > 0) filter.push(find);
        });
      }
    } else {
      if (searchName.length > 0) {
        searchName.forEach((text) => {
          const find = list.find((item) => item.name === text.split(" (")[0]);
          if (find) filter.push(find);
        });
      }

      e.forEach((text) => {
        const is_district = text.split("-").length > 2;
        const text_split = text.split("-");
        const find = [];
        if (is_district) {
          list.forEach((item) => {
            console.log("text_split :>> ", text_split);
            const is_match = item.provinces_districts.filter((location) => (location.district_name === text_split[3] && location.province_name === text_split[1]) || (location.district_name === null && location.province_name === text_split[1]));
            console.log("is_match :>> ", is_match);
            if (is_match.length > 0) filter.push(item);
          });
        } else {
          list.forEach((item) => {
            const is_match = item.provinces_districts.filter((location) => location.province_name === text_split[1]);
            if (is_match.length > 0) filter.push(item);
          });
        }
        if (find.length > 0) filter.push(find);
      });
    }
    setSearchList([...new Set(filter)]);
  }
  return (
    <>
      <List {...props} filters={<MaintenanceStructureChartFilter onClickEditMode={onClickEditMode} />} exporter={exporter}>
        <Datagrid>
          <TextField source="id" />
          <TextField source="is_active" label="เปิดใช้งาน" />
          <TextField source="name" />
          <TextField source="code" />
          <EditButton basePath="/maintenance_structure_charts" />
          <DeleteWithConfirmButton undoable={false} />
        </Datagrid>
      </List>
      <Modal centered footer={false} visible={isEditMode} onCancel={!isLoading ? onCloseEditModal : () => {}} width={"80%"} bodyStyle={{ margin: "50px 0px" }}>
        <>
          {leftProvince.length > 0 && (
            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", color: "red", fontWeight: "bold", fontSize: 14, gap: 15, marginBottom: 15 }}>
              <WarningFilled style={{ color: "red", fontSize: 26 }} />
              <span>จังหวัด - อำเภอ ต่อไปนี้ยังไม่มีผู้รับผิดชอบ : {<span style={{ fontWeight: "normal" }}>{leftProvince.join(", ")}</span>}</span>
            </div>
          )}
          {duplicateSelected.length > 0 && (
            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", color: "red", fontWeight: "bold", fontSize: 14, gap: 15, marginBottom: 15 }}>
              <WarningFilled style={{ color: "red", fontSize: 26 }} />
              <span>จังหวัด - อำเภอ ต่อไปนี้มีผู้รับผิดชอบมากกว่า 1 คน : {<span style={{ fontWeight: "normal" }}>{duplicateSelected.join(", ")}</span>}</span>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 15, marginTop: 30 }}>
            <Space>
              <TreeSelect style={{ width: 280 }} placeholder="ค้นหา ชื่อ, Code" treeCheckable={true} treeData={nameList} onChange={(e) => handleSearch(e, "name")} />
              <TreeSelect style={{ width: 280 }} placeholder="ค้นหา จังหวัด-อำเภอ" treeData={provincesData} showCheckedStrategy={SHOW_PARENT} treeCheckable={true} onChange={(e) => handleSearch(e, "provinces_districts")} />
            </Space>
            <Space>
              <Button style={{ width: 120, borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center" }} type="primary" onClick={onCreateData} disabled={isLoading}>
                <UserAddOutlined /> CREATE
              </Button>
              <Button style={{ width: 120, borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center" }} type="primary" onClick={onSaveData} loading={isLoading}>
                <SaveOutlined /> SAVE
              </Button>
            </Space>
          </div>
          <Table dataSource={searchList.length > 0 ? searchList : list} columns={columns} style={{ width: "100%" }} loading={isLoading} />
        </>
      </Modal>
      <StatusModal visible={visibleStatusModal} title={modalDetail.title} detail={modalDetail.detail} type={modalDetail.type} onClickCancel={modalDetail.onClickCancel} onClickOK={modalDetail.onClickOk} showCancel={modalDetail.showCancel} />
    </>
  );
};

const MaintenanceStructureChartTitle = ({ record }) => {
  return <span>MaintenanceStructureChart {record ? `"${record.name}"` : ""}</span>;
};

export const MaintenanceStructureChartEdit = (props) => (
  <Edit undoable={false} actions={<EditActions />} title={<MaintenanceStructureChartTitle />} {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput disabled source="id" />
      <BooleanInput label="เปิดใช้งาน" source="is_active" />
      <TextInput source="name" validate={required()} />
      <TextInput source="code" validate={required()} />
      <TextInput source="phone" validate={required()} />
      <ArrayInput source="provinces_districts" label="จังหวัด-เขต/อำเภอ">
        <SimpleFormIterator TransitionProps={{ enter: false }}>
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => {
              return (
                <Box display="flex">
                  <Box mr="0.5em">
                    <ReferenceInput validate={required()} label="จังหวัด" source={getSource("province_id")} reference="provinces" filterToQuery={(searchText) => ({ name: searchText })}>
                      <AutocompleteInput optionText="name" resettable />
                    </ReferenceInput>
                  </Box>
                  {scopedFormData && scopedFormData.province_id ? (
                    <Box ml="0.5em" key={getSource("province_id")}>
                      <ReferenceInput label="อำเภอ" source={getSource("district_id")} reference="districts" filterToQuery={(searchText) => ({ name: searchText, province_id: scopedFormData ? scopedFormData.province_id : "" })}>
                        <AutocompleteInput optionText="name" resettable />
                      </ReferenceInput>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export const MaintenanceStructureChartCreate = (props) => (
  <Create title="Create a MaintenanceStructureChart" actions={<EditActions />} {...props}>
    <SimpleForm>
      <BooleanInput label="เปิดใช้งาน" source="is_active" defaultValue={true} />
      <TextInput source="name" validate={required()} />
      <TextInput source="code" validate={required()} />
      <TextInput source="phone" validate={required()} />
      <ArrayInput source="provinces_districts" label="จังหวัด-เขต/อำเภอ">
        <SimpleFormIterator TransitionProps={{ enter: false }}>
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => {
              return (
                <Box display="flex">
                  <Box mr="0.5em">
                    <ReferenceInput validate={required()} label="จังหวัด" source={getSource("province_id")} reference="provinces" filterToQuery={(searchText) => ({ name: searchText })}>
                      <AutocompleteInput optionText="name" resettable />
                    </ReferenceInput>
                  </Box>
                  {scopedFormData && scopedFormData.province_id ? (
                    <Box ml="0.5em" key={getSource("province_id")}>
                      <ReferenceInput label="อำเภอ" source={getSource("district_id")} reference="districts" filterToQuery={(searchText) => ({ name: searchText, province_id: scopedFormData ? scopedFormData.province_id : "" })}>
                        <AutocompleteInput optionText="name" resettable />
                      </ReferenceInput>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

import * as React from "react";
import axios from "axios";
import {
  List, Datagrid, Edit, Create, SimpleForm, DeleteWithConfirmButton, TextField, SelectInput,
  EditButton, TextInput, ReferenceField, ReferenceInput,
  AutocompleteInput, required, useListContext, BooleanInput, FormDataConsumer, useDataProvider, useNotify, useRedirect
} from 'react-admin';
import { useFormState } from 'react-final-form';
import BookIcon from '@material-ui/icons/Book';
import { Form, useForm } from 'react-final-form';
import { Box, Button as CoreButton, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ContentFilter from "@material-ui/icons/FilterList";

import { exporter } from '../../functions/reactAdmin'

import { EditToolbar, EditActions } from './Default'
import { TRACKING_USER_TYPE } from "../../constants";

export const ClientStationIcon = BookIcon;


const getDistricts = async (provinceId) => {
  const { data: districts } = await axios.get(
    `${process.env.REACT_APP_API_URL}/districts?province_id=${provinceId}`
  );
  return districts;
};

const ClientStationFilter = props => {
  return props.context === "button" ? (
    <ClientStationFilterButton {...props} />
  ) : (
    <ClientStationFilterForm {...props} />
  );
};

const ClientStationFilterButton = () => {
  const { showFilter } = useListContext();
  return (
    <CoreButton
      size="small"
      color="primary"
      onClick={() => showFilter("main")}
      startIcon={<ContentFilter />}
    >
      Filter
    </CoreButton>
  );
};

const ClientStationFilterForm = ({ open }) => {
  const {
    displayedFilters,
    filterValues,
    setFilters,
    hideFilter,
  } = useListContext();

  if (!displayedFilters.main) return null;

  const onSubmit = values => {
    if (Object.keys(values).length > 0) {
      setFilters(values);
    } else {
      hideFilter("main");
    }
  };

  const resetFilter = () => {
    setFilters({}, []);
  };

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box mt={8} />
            <Box display="flex" alignItems="flex-end" mb={1}>
              <Box component="span" mr={3}>
                <BooleanInput source="is_active" label="เปิดใช้งาน" alwaysOn />
              </Box>
              <Box component="span" mr={2}>
                {/* Full-text search filter. We don't use <SearchFilter> to force a large form input */}
                <TextInput
                  resettable
                  helperText={false}
                  source="name"
                  label="Search ชื่อ"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon color="disabled" />
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
              <Box component="span" mr={2} mb={1.5}>
                <CoreButton variant="outlined" color="primary" type="submit">
                  Filter
                </CoreButton>
              </Box>
              <Box component="span" mb={1.5}>
                <CoreButton variant="outlined" onClick={resetFilter}>
                  Close
                </CoreButton>
              </Box>
            </Box>
          </form>
        )}
      </Form>
    </div>
  );
};



export const ClientStationList = (props) => (
  <List {...props} filters={<ClientStationFilter />} exporter={exporter}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="is_active" label="เปิดใช้งาน" />
      <TextField source="sold_to_code" label="Sold-to" />
      <TextField source="ship_to_code" label="Ship-to" />
      <ReferenceField label="Province" source="address.province_id" reference="provinces" sortable={false}>
        <TextField source="name" />
      </ReferenceField>
      <EditButton basePath="/client_stations" />
      <DeleteWithConfirmButton undoable={false} />
    </Datagrid>
  </List>
);

const ClientStationTitle = ({ record }) => {
  return <span>ClientStation {record ? `"${record.name}"` : ''}</span>;
};



const ProvinceField = props => {
  const [distances, setDistances] = React.useState([]);
  const form = useForm();
  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/distance_matrixes?filter=%7B"src_province_id":1%7D`)
      .then((response) => {
        setDistances(response.data)
      }).catch((error) => {
        console.log(error)
      });
  }, []);
  return (
    <FormDataConsumer>
      {({
        formData, // The whole form data
        scopedFormData, // The data for this item of the ArrayInput
        getSource, // A function to get the valid source inside an ArrayInput
        ...rest
      }) => {
        return (formData.address && formData.address.province_id ? (
          <ReferenceInput
            label="จังหวัด"
            source="address.province_id"
            reference="provinces"
            filterToQuery={searchText => ({ name: searchText })}
            validate={required()}
            onChange={(e) => {
              form.change('address.district_id', null);
              form.change('address.sub_district_id', null);
              let distanceData = distances.find(
                (item) => {
                  return item.dst_province_id == e
                }
              )
              form.change('distance', (distanceData) ? distanceData.distance : null);
            }}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        ) : null)
      }

      }
    </FormDataConsumer>
  );
};

const ProvinceFieldCreate = props => {
  const [distances, setDistances] = React.useState([]);
  const form = useForm();
  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/distance_matrixes?filter=%7B"src_province_id":1%7D`)
      .then((response) => {
        setDistances(response.data)
      }).catch((error) => {
        console.log(error)
      });
  }, []);
  return (
    <ReferenceInput
      label="จังหวัด"
      source="address.province_id"
      reference="provinces"
      filterToQuery={searchText => ({ name: searchText })}
      validate={required()}
      onChange={(e) => {
        form.change('address.district_id', null);
        form.change('address.sub_district_id', null);
        let distanceData = distances.find(
          (item) => {
            return item.dst_province_id == e
          }
        )
        form.change('distance', (distanceData) ? distanceData.distance : null);
      }}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  );
};



const DistrictField = props => {
  const form = useForm();
  return (
    <FormDataConsumer>
      {({
        formData, // The whole form data
        scopedFormData, // The data for this item of the ArrayInput
        getSource, // A function to get the valid source inside an ArrayInput
        ...rest
      }) => {
        return (formData.address && formData.address.province_id ? (
          <ReferenceInput
            label="อำเภอ"
            source="address.district_id"
            reference="districts"
            filter={{ province_id: formData.address.province_id }}
            filterToQuery={searchText => ({ name: searchText, province_id: formData.address.province_id })}
            validate={required()}
            onChange={(e) => {
              form.change('address.sub_district_id', null);
            }}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        ) : null)
      }

      }
    </FormDataConsumer>
  );
};

const SubDistrictField = props => {
  return (
    <FormDataConsumer>
      {({
        formData, // The whole form data
        scopedFormData, // The data for this item of the ArrayInput
        getSource, // A function to get the valid source inside an ArrayInput
        ...rest
      }) =>
        formData.address && formData.address.district_id ? (
          <ReferenceInput
            label="ตำบล"
            source="address.sub_district_id"
            reference="sub_districts"
            filter={{ district_id: formData.address.district_id }}
            filterToQuery={searchText => ({ name: searchText, district_id: formData.address.district_id })}
            validate={required()}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        ) : null
      }
    </FormDataConsumer>
  );
};



export const ClientStationEdit = (props) => {
  const dataProvider = useDataProvider();
  const [user, setUser] = React.useState();
  const [province, setProvince] = React.useState(null);

  const [distance, setDistance] = React.useState('');
  const [distanceStatus, setDistanceStatus] = React.useState(true);
  const [data, setData] = React.useState([]);

  const redirect = useRedirect()
  const notify = useNotify()
  React.useEffect(() => {
    dataProvider.getOne(props.resource, { id: props.id })
      .then(({ data }) => {
        setUser(data);
      })
      .catch(error => {
        console.log(error)
      });
    axios.get(`${process.env.REACT_APP_API_URL}/distance_matrixes?filter=%7B"src_province_id":1%7D`)
      .then((response) => {
        setData(response.data)
      }).catch((error) => {
        console.log(error)
      });

  }, [distance, distanceStatus, province]);
  // to reset password in tracking if exist
  const handleSubmit = (event) => {
    event.preventDefault();
    const { elements } = event.currentTarget;
    let new_password = elements.namedItem("new_password").value;
    axios.put(`${process.env.REACT_APP_API_URL}/change-user-info`, {
      username: user.sold_to_code,
      password: new_password
    })
      .then((response) => {
        console.log(response)
        alert(`รหัสผ่านสถานี SoldTo ID: ${user.sold_to_code} ถูกเปลี่ยนเป็น ${new_password}`)
      })
      .catch((error) => {
        console.log(error)
        alert(error.message);
      });

  }
  // onSuccess function to create user when update sold_to_code from none
  const onSuccess = (event) => {
    if (!event.data.is_error) {
      if (event.data.sold_to_code) {
        dataProvider.getOne(props.resource, { id: event.data.id })
          .then(({ data }) => {
            let params = {
              'username': data.sold_to_code,
              'password': data.sold_to_code,
              'role': TRACKING_USER_TYPE.client_stations.value,
              'user_type': TRACKING_USER_TYPE.client_stations.name,
              'user_id': data.id,
              'id': data.id,
              'name': data.name,
              'address_id': data.address_id ? data.address_id : null
            }
            console.log(params)
            axios.post(`${process.env.REACT_APP_API_URL}/tracking/user`, params)
              .then((response_user) => {
                console.log(response_user)
                notify(`Client station successfully created/updated on P4S and Tracking!`)
                redirect('/client_stations')
              })
              .catch((error) => {
                console.log(error)
                alert(error.message);
                redirect('/client_stations')
              });

          })
          .catch(error => {
            alert(error)
            redirect('/client_stations')
          })
      } else {
        notify('Element updated')
        redirect('/client_stations')
      }

    } else {
      notify(event.data.message)
    }
  }



  return (
    <React.Fragment>
      <Edit undoable={false} actions={<EditActions />} title={<ClientStationTitle />} onSuccess={onSuccess} {...props}>
        <SimpleForm toolbar={<EditToolbar />} id="edit_form">
          <BooleanInput label="เปิดใช้งาน" source="is_active" />
          <TextInput disabled source="id" />
          <TextInput source="name" label="สถานี" validate={required()} />
          {/* <TextInput source="nickname" inputProps={{ maxLength: 12 }} label="Nickname ไม่เกิน 12 ตัวอักษร" /> */}
          <TextInput source="sold_to_code" label="Sold-to" />
          <TextInput source="ship_to_code" label="Ship-to" />
          <TextInput source="contact_person" label="ผู้ติดต่อ" />
          <TextInput source="contact_tel" label="เบอร์ติดต่อ" />
          <TextInput source="address.address_line1" label="ที่อยู่" />

          <ProvinceField />
          <DistrictField />
          <SubDistrictField />
          <TextInput label="รหัสไปรษณีย์" source="address.postcode" />
          <TextInput source="distance" label="Distance"></TextInput>

        </SimpleForm>
      </Edit>

      <div className="dropdown-divider"></div>
      <div className="card-body"><b>รีเซ็ทรหัสผ่านสำหรับระบบ Tracking</b></div>
      <div className='card'>
        <form className="card-body form-inline" onSubmit={handleSubmit}>
          <input type="password" className="form-control mb-2 mr-sm-2" id="new_password" placeholder="New Password" />
          <button type="submit" className="btn btn-secondary mb-2">Reset Password</button>
        </form>
      </div>

    </React.Fragment>
  );
}

export const ClientStationCreate = (props) => {
  const redirect = useRedirect()
  const notify = useNotify()
  const dataProvider = useDataProvider();

  const onSuccess = (event) => {
    if (event.data.id !== "duplicate") {
      if (event.data.sold_to_code) {
        dataProvider.getOne(props.resource, { id: event.data.id })
          .then(({ data }) => {
            let params = {
              'username': data.sold_to_code,
              'password': data.sold_to_code,
              'role': TRACKING_USER_TYPE.client_stations.value,
              'user_type': TRACKING_USER_TYPE.client_stations.name,
              'user_id': data.id,
              'id': data.id,
              'name': data.name,
              'address_id': data.address_id ? data.address_id : null
            }
            console.log(params)
            axios.post(`${process.env.REACT_APP_API_URL}/tracking/user`, params)
              .then((response) => {
                console.log(response)
                notify(`Client station successfully created on P4S and Tracking!`)
                redirect(`/client_stations`)
              })

              .catch((error) => {
                console.log(error)
                alert(error.message);
              });

          })
          .catch(error => {
            alert(error)
          })
      } else {
        notify('Client station successfully created!')
        redirect('/client_stations')
      }
    } else {
      notify("มีหมายเลข Ship to code ในระบบแล้ว !")
    }
  }
  return (
    <div>
      <Create title="Create a ClientStation" actions={<EditActions />} onSuccess={onSuccess} {...props}>
        <SimpleForm>

          <BooleanInput label="เปิดใช้งาน" source="is_active" defaultValue={true} />
          <TextInput source="name" label="สถานี" validate={required()} />
          {/* <TextInput source="nickname" inputProps={{ maxLength: 12 }} label="Nickname ไม่เกิน 12 ตัวอักษร" /> */}
          <TextInput source="sold_to_code" label="Sold-to" />
          <TextInput source="ship_to_code" label="Ship-to" />
          <TextInput source="contact_person" label="ผู้ติดต่อ" />
          <TextInput source="contact_tel" label="เบอร์ติดต่อ" />
          <TextInput source="address.address_line1" label="ที่อยู่" />
          <ProvinceFieldCreate />
          <DistrictField />
          <SubDistrictField />
          <TextInput label="รหัสไปรษณีย์" source="address.postcode" />
          <TextInput source="distance" label="Distance"></TextInput>

        </SimpleForm>
      </Create>
    </div>
  );
}
import React from 'react'
import { DocumentPreview } from '../global/DocumentPreview.js'
export class QuotationFormDocuments extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let url = this.props.quotation === null ? '' : this.props.quotation.s3_base_url
    return (
      <React.Fragment>
        <DocumentPreview docName="ใบเสนอราคา" url={url} />
      </React.Fragment>
    )
  }
}

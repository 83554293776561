import { getObjectValueByString } from './Object'

export function validateInputFromRequireFields(obj, fields) {
  let errors = {}

  Object.keys(fields).map(key => {
    let value = getObjectValueByString(obj, key)
    if (value === undefined || value === '' || value === null) {
      errors[key] = `โปรดระบุ ${fields[key]}`
    }
  })

  return errors
}

export function hasLowerCase(str) {
  return (/[a-z]/.test(str));
}

export function hasUpperCase(str) {
  return (/[A-Z]/.test(str));
}

export function hasNumber(myString) {
  return /\d/.test(myString);
}
import React from 'react';
import PropTypes from "prop-types";

import '../../styles/icons.css';

export class IconSorting extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active_up: props.activeUp || false,
      active_down: props.activeDown || false
    }

    this.onClickUp = this.onClickUp.bind(this)
    this.onClickDown = this.onClickDown.bind(this)
  }

  onClickUp(active, type) {
    this.setState({active_up: !active, active_down: false})
    this.props.onClickUp(!active, type)
  }

  onClickDown(active, type) {
    this.setState({active_down: !active, active_up: false})
    this.props.onClickDown(!active, type)
  }

  render() {
    let { active_down, active_up } = this.state
    let { type } = this.props

    return (
      <React.Fragment>
        <div className="d-flex flex-column">
          <i className={`icon-arrow-up ${active_up ? 'active' : ''}`} ></i>
          <i className={`icon-arrow-down ${active_down ? 'active' : ''}`} ></i>
        </div>      
      </React.Fragment>
    );
  }
}

IconSorting.propTypes = {
  activeUp: PropTypes.bool,
  activeDown: PropTypes.bool,
  onClickUp: PropTypes.func,
  onClickDown: PropTypes.func,
  type: PropTypes.string,
};
export function Multiple(a, b) {
  if (isNaN(a) || isNaN(b)) {
    return 0
  } else {
    return a*b
  }
}

export function Division(a, b) {
  if (isNaN(a) || isNaN(b) || b===0) {
    return 0
  } else {
    return a/b
  }
}

export function toLocaleStringCus(num) {
  if (num && num !== undefined && !isNaN(num)) {
    return num.toLocaleString()
  } else {
    return num
  }
}

export function toStrWithLeadingZeros(num, size) {
  if (!num) {
    return num
  }
  let num_str = num.toString();
  while (num_str.length < size) num_str = "0" + num_str;
  return num_str
}

export function saveMultiple(a, b, precision=2) {
  if (isNaN(a) || isNaN(b)) {
    return 0
  } else {
    return parseFloat((a*b).toFixed(precision))
  }
}

export function saveDivision(a, b, precision=2) {
  if (isNaN(a) || isNaN(b) || b===0) {
    return 0
  } else {
    return parseFloat((a/b).toFixed(precision))
  }
}
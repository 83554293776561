import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import axios from 'axios'

// constants
import { R_SEL_OPTION_MAX_H } from '../../constants'

import { objectIsEmpty, toUniqueArr } from '../../functions/Object'

class ContractByIds extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      contracts: [],
      isMounted: false,
    }
    this.contractSelected = this.contractSelected.bind(this)
  }

  componentDidMount() {
    if (!objectIsEmpty(this.props.optionIds)) {
      this.getCollection()
    }
  }

  setOptions(response) {
    let { optionDisabledIds, contractId } = this.props
    let options = []
    response.data.map((e) => {
      let opt = { id: e.id, text: `${e.code} - ${e.name}`, value: e.id, code: e.code }
      if (optionDisabledIds && contractId !== e.id && optionDisabledIds.includes(e.id)) {
        opt.disabled = true
      }
      options.push(opt)
    })

    this.setState({
      contracts: options,
      isMounted: true,
    })
  }

  getCollection() {
    let { optionIds, contractId } = this.props
    // let ids = toUniqueArr([contractId].concat(optionIds))

    let params = {
      sort: ['created_at', 'DESC'],
      filter: {
        id: optionIds,
        for_mr: false,
      },
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/contracts`, {
        params: params,
      })
      .then((response) => {
        this.setOptions(response)
      })
      .catch(function (error) {
        console.log(error)
      })
      .then(function () {
        // always executed
      })
  }

  contractSelected(e) {
    let value = e.value
    if (value !== this.props.contractId && this.state.isMounted) {
      this.props.onChange(value)
    }
  }

  render() {
    return (
      <React.Fragment>
        <Select
          className="select2-style"
          onChange={this.contractSelected}
          getOptionLabel={(option) => `${option.text}`}
          value={this.state.contracts.filter((option) => option.value === this.props.contractId)}
          getOptionValue={(option) => `${option.id}`}
          options={this.state.contracts}
          isOptionDisabled={(option) => option.disabled}
          isDisabled={this.props.disabled}
          placeholder={'ค้นหา...'}
          menuPortalTarget={document.body}
          maxMenuHeight={R_SEL_OPTION_MAX_H}
        />
      </React.Fragment>
    )
  }
}

ContractByIds.propTypes = {
  contractId: PropTypes.number,
  optionIds: PropTypes.array.isRequired,
  optionDisabledIds: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default ContractByIds

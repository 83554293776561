import * as React from "react";
import axios from "axios";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  DeleteWithConfirmButton,
  TextField,
  EditButton,
  TextInput,
  Toolbar,
  SaveButton,
  ReferenceInput,
  FormDataConsumer,
  useRedirect,
  useRefresh,
  NumberInput,
  required,
  Button,
  Link,
  ReferenceManyField,
  AutocompleteInput,
  useNotify,
  FunctionField,
  useListContext,
  BooleanInput,
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import BookIcon from "@material-ui/icons/Book";
import { getExpenseDetails } from "../../models/ItemCategories";

import { Form } from "react-final-form";
import { Box, Button as CoreButton, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ContentFilter from "@material-ui/icons/FilterList";

import { exporter } from "../../functions/reactAdmin";

import { EditToolbar, EditActions } from "./Default";

export const ItemIcon = BookIcon;

const ItemTitle = ({ record }) => {
    return <span>Item {record ? `"${record.name}"` : ""}</span>;
  };

function getAddress(data) {
    let address = data.address;
    let address_line1 = (address.address_line1)?address.address_line1:'';
    let postcode = (address.postcode)?address.postcode:'';
    let sub_district = (address.sub_district)?address.sub_district.name:'';
    let district = (address.district)?address.district.name:'';
    let province = (address.province)?address.province.name:'';
    return `${address_line1} ${postcode} ${sub_district} ${district} ${province}`;
}

export const DCList = (props) => (        
    <List {...props} >
          <Datagrid>
          <TextField source="id" />  
          <TextField source="name" />  
          <TextField source="phone" />  
          {/* <TextField source="address" />   */}
          <FunctionField label="Address"  render={data => `${getAddress(data)}`} />;
          <EditButton basePath="/dc_admin" /> 
        </Datagrid>
    </List>
  );


const provinces = async () => {
    let province = await axios.get("/provinces").then((response) => {
        return response.data;
    });
    return province;
}

 function getProvince() {
    let province =  axios.get("/provinces").then((response) => {
        return response.data;
    });
    
    return province;
}
 function getDistrict(province_id) {
    let district =  axios.get("/districts");
    return district.data;
}
 function getSubDistrict(district_id) {
    let sub_district =  axios.get("/sub_districts");
    return sub_district.data;
} 
export const DCEdit =  (props) => { 
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const [province, setProvince] = React.useState([])
    const [district, setDistrict] = React.useState([])
    const onSuccess = () => {
        notify("Item updated");
        redirect("/dc_admin"); 
    };
    return (
        <Edit  actions={<EditActions />} title={<ItemTitle />} onSuccess={onSuccess} {...props}>
       
            <SimpleForm toolbar={<EditToolbar></EditToolbar>}>
                <TextInput source="name" />
                <TextInput source="phone" />
                <TextInput source="address.address_line1" />
                <TextInput source="address.postcode" />
                <ReferenceInput label="Provinces" source="address.province_id" reference="provinces" filterToQuery={(searchText) => ({ name: searchText })} validate={required()} onChange={(e)=>{
                  
                    setProvince(e);
                }}>
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput label="District" source="address.district_id" filter={{ province_id: Number(province) }} reference="districts" filterToQuery={(searchText) => ({ name: searchText  })} validate={required()} onChange={
                    (e)=>{ 
                        setDistrict(e);
                    }
                } >
                    <AutocompleteInput optionText="name"  />
                </ReferenceInput>
                <ReferenceInput label="Sub District" source="address.sub_district_id" filter={{ district_id: Number(district) }}  reference="sub_districts" filterToQuery={(searchText) => ({ name: searchText })} validate={required()}>
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
                {/* <TextInput source="address.province_id" />
                <TextInput source="address.district_id" /> 
                <TextInput source="address.sub_district_id" /> */}
            </SimpleForm>
        </Edit>
    );
  } 
 
import axios from "axios";
import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { API_RESOURCES, APP_TITLE_DC_INBOUND_ORDER } from "../../../constants.js";
import { NEW_DC_ORDER } from "../../../models/Orders.js";
import Spinner from "../../global/Spinner.js";
import DcInboundOrderFormInfo from "./DcInboundOrderFormInfo.jsx";
import DcInboundOrderFormProductList from "./DcInboundOrderFormProductList.jsx";
import DcInboundOrderFormInform from "./DcInboundOrderFormInform.jsx";
import DcInboundOrderFormPo from "./DcInboundOrderFormPo.jsx";
import DcInboundOrderFormReceived from "./DcInboundOrderFormReceived.jsx";
import cloneDeep from "lodash/cloneDeep";
import { INBOUND_ORDER_STATES } from "../../../constants.js";
import dayjs from "dayjs";
import { Tooltip } from "antd";

const DC_INBOUND_TABS = {
  info: 0,
  product_list: 1,
  inform: 2,
  po: 3,
  check_product: 4,
};

let poTemplate = {
  index: Math.floor(Math.random() * 9999) + 1111,
  id: null,
  po_date: null,
  contract_id: null,
  po_code: null,
  submit_date: null,
  manufacture_request_id: null,
};

let qtyChangeTimeout = null;
let expense_ratio = [];

class DcInboundOrderForm extends React.Component {
  constructor(props) {
    super(props);
    let dc_in_bound_id = props.id && props.id !== "new" ? parseInt(props.id) : null;
    this.state = {
      /**
       * Order's data
       */
      selected_id: dc_in_bound_id,
      dc_order: cloneDeep(NEW_DC_ORDER.inbound_order),
      current_contract: {},
      distance: 176,
      load_cate: 0,
      cate_length: 0,

      /**
       * Master data
       */
      item_options: [],
      dc_contact: cloneDeep(NEW_DC_ORDER.inbound_order.dc),

      /**
       * Flag
       */
      disabled: false,
      is_loading: true,
      is_updated: false,
      current_tab: dc_in_bound_id && localStorage.getItem("current_tab") ? parseInt(localStorage.getItem("current_tab")) : DC_INBOUND_TABS.info,

      is_reset_expense: false,
      is_reset_contract_cate: false,
      inbound_info: null,

      // Product List State
      addList: [],
      addReceivedItems: [],
      addListPo: [],
      selectToAddItem: null,
      qtyToAddItem: 1,
      noted: "",
      approved_date: dayjs().format("YYYY-MM-DD"),
      order_code: "",
      order_status: "",
      inbound_data: {},
      visibleDeletePo: false,
      poDeleteIndex: null,

      is_refresh_contract: false,
    };

    this.onSelectItemToAdd = this.onSelectItemToAdd.bind(this);
    this.onSubmitAddItem = this.onSubmitAddItem.bind(this);
    this.onClickBack = this.onClickBack.bind(this);
    this.onChangeQtyItem = this.onChangeQtyItem.bind(this);
    this.onChangeContract = this.onChangeContract.bind(this);
    this.handelChangeItemQTY = this.handelChangeItemQTY.bind(this);
    this.onClickDeleteItem = this.onClickDeleteItem.bind(this);
    this.onClickDeleteItemFee = this.onClickDeleteItemFee.bind(this);
    this.onClickDeleteCategotyFee = this.onClickDeleteCategotyFee.bind(this);
    this.onChangeItemFeeQTY = this.onChangeItemFeeQTY.bind(this);
    this.onChangeCategotyFeeQTY = this.onChangeCategotyFeeQTY.bind(this);
    this.onSubmitMultiSelected = this.onSubmitMultiSelected.bind(this);
    this.onChangeDiscount = this.onChangeDiscount.bind(this);
    this.onSubmitAddExpense = this.onSubmitAddExpense.bind(this);
    this.onDeleteAddExpense = this.onDeleteAddExpense.bind(this);
    this.onChangeQTYAddExpense = this.onChangeQTYAddExpense.bind(this);
    this.onChangePriceAddExpense = this.onChangePriceAddExpense.bind(this);
    this.onChangeCategotyFeePrice = this.onChangeCategotyFeePrice.bind(this);
    this.onChangeItemFeePrice = this.onChangeItemFeePrice.bind(this);
    this.handelChangeItemPrice = this.handelChangeItemPrice.bind(this);
    this.onChangeApproveDate = this.onChangeApproveDate.bind(this);
    this.onAddSumFeesToAddList = this.onAddSumFeesToAddList.bind(this);
    this.resetFees = this.resetFees.bind(this);
    this.onChangeInstallationDate = this.onChangeInstallationDate.bind(this);
    this.onChangeInformedDate = this.onChangeInformedDate.bind(this);
    this.onClickAddListPo = this.onClickAddListPo.bind(this);
    this.onClickDeletePo = this.onClickDeletePo.bind(this);
    this.onChangeSelectPo = this.onChangeSelectPo.bind(this);
    this.onChangePoCode = this.onChangePoCode.bind(this);
    this.onChangePoSubmitDate = this.onChangePoSubmitDate.bind(this);
    this.onChangePoDate = this.onChangePoDate.bind(this);
    this.onChangeNoted = this.onChangeNoted.bind(this);
    this.onGetWarrantyCode = this.onGetWarrantyCode.bind(this);
    this.onAddReceivedItem = this.onAddReceivedItem.bind(this);
    this.onDeleteWarrantyCode = this.onDeleteWarrantyCode.bind(this);
    this.onDeleteLotNoneID = this.onDeleteLotNoneID.bind(this);
    this.onDeleteLotID = this.onDeleteLotID.bind(this);
    this.onChangeQTYAddedLot = this.onChangeQTYAddedLot.bind(this);
    this.onClickAutoGenerateMR = this.onClickAutoGenerateMR.bind(this);
    this.onCancelPoDelete = this.onCancelPoDelete.bind(this);
    this.onAcceptDeletPo = this.onAcceptDeletPo.bind(this);
    this.onChangeExpRatio = this.onChangeExpRatio.bind(this);
  }

  componentDidMount() {
    this.getInfo();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  backToDcInboundIndex() {
    this.props.history.push({
      pathname: `/${API_RESOURCES.dc_inbound_order}`,
    });
  }

  onChangeExpRatio(ratios) {
    expense_ratio = ratios;
  }

  onClickBack() {
    this.backToDcInboundIndex();
  }

  onChangeFormTab(tab) {
    localStorage.setItem("current_tab", tab);
    this.setState({ current_tab: tab });
  }

  displayTabs(text, tab_name, isDisable = false, isDraft = false) {
    let { current_tab } = this.state;
    return (
      <Tooltip title={isDisable ? "กรุณา Save order ก่อนใช้งาน !" : isDraft ? "ตรวจรับสินค้าได้เมื่อออก PO แล้ว !" : ""} placement="bottom">
        <li className={`nav-item ${isDisable | isDraft && "tab-disable"}`}>
          <a className={`nav-link px-2 ${isDisable | isDraft && "tab-disable"} ${current_tab === DC_INBOUND_TABS[tab_name] ? "active" : ""}`} onClick={() => (isDraft || isDisable ? () => {} : this.onChangeFormTab(DC_INBOUND_TABS[tab_name]))}>
            {text}
          </a>
        </li>
      </Tooltip>
    );
  }

  onSelectItemToAdd(item) {
    this.setState({ selectToAddItem: item });
  }

  onChangeQtyItem(value) {
    this.setState({ qtyToAddItem: value !== "" ? value : 1 });
  }

  onChangeNoted(e) {
    this.setState({ noted: e.target.value });
  }

  onChangeDiscount(e, type, category) {
    const { addList } = this.state;
    const deList = [...addList];
    const addlistIndex = deList.findIndex((list) => list.id === category.id);

    if (type === "qty") {
      deList[addlistIndex].discount.qty = e;
    } else if (type === "price") {
      deList[addlistIndex].discount.price = e;
    }
    this.setState({ addList: deList });
  }

  async onSubmitAddItem() {
    const { addList, selectToAddItem, qtyToAddItem, inbound_info, inbound_data } = this.state;

    if (selectToAddItem) {
      const select_index = addList.findIndex((item) => item.id === selectToAddItem.item_category_id);
      let AddItem = selectToAddItem;
      AddItem = { ...AddItem, qty: qtyToAddItem };

      let new_add_list = [...addList];
      this.setState({ is_loading: true });

      if (select_index !== -1) {
        await new_add_list[select_index].item_category.push(AddItem);
        let item_ids = [];
        item_ids = await new_add_list[select_index].item_category.map((item) => item.id);

        new_add_list[select_index].item_category.forEach((item) => {
          if (item.is_mbom) {
            item.bom_items_list.forEach((bom) => {
              item_ids.push(bom.id);
            });
            const bombCateGroup = _.groupBy(item.bom_items_list, "item_category_id");
            Object.keys(bombCateGroup).map(async (cateKey) => {
              const bomIds = bombCateGroup[cateKey].map((bom) => bom.id);
              const bomFeeList = await this.getItemCategotyContract(parseInt(cateKey), bomIds);

              bombCateGroup[cateKey].map((bomItem) => {
                const findBomPrice = bomFeeList.contract_items.find((contract) => contract.item_id === bomItem.id);
                bomItem.qty = item.qty;
                bomItem.price = findBomPrice ? findBomPrice.price : 0;
              });
            });
          } else {
            item_ids.push(AddItem.id);
          }
        });

        const fee_list = await this.getItemCategotyContract(new_add_list[select_index].id, item_ids);
        const distance = inbound_info.dc[0].distance;
        let allitemQty = 0;

        new_add_list[select_index].contract_items = fee_list.contract_items;

        new_add_list.map((items, itemIndex) => {
          items.item_category.forEach((category, categoryIndex) => {
            let contractItemIndex = fee_list.contract_items.findIndex((contract) => contract.item_id === category.id);
            if (contractItemIndex !== -1) {
              allitemQty = allitemQty + category.qty;
              fee_list.contract_items[contractItemIndex].contract_item_fees.forEach((_, index) => {
                let feeList = fee_list.contract_items[contractItemIndex].contract_item_fees[index];
                fee_list.contract_items[contractItemIndex].contract_item_fees[index]["qty"] = this.itemItypeMapping(feeList, category.qty, distance);
              });
            }

            items.item_category_fees.forEach((fee, index) => {
              if (fee.method_type === 3) {
                items.item_category_fees[index].qty = allitemQty;
              } else {
                items.item_category_fees[index].qty = this.itemItypeMapping(fee, items.qty, distance);
              }
            });

            let addContractItems = {
              ...category,
              contract_item_fees: contractItemIndex !== -1 ? fee_list.contract_items[contractItemIndex].contract_item_fees : [],
            };
            new_add_list[itemIndex].item_category[categoryIndex] = addContractItems;
          });
        });
      } else {
        let ids = [];

        ids.push(AddItem.id);

        if (AddItem.is_mbom) {
          AddItem.bom_items_list.forEach((bom) => {
            ids.push(bom.id);
          });
          const bombCateGroup = _.groupBy(AddItem.bom_items_list, "item_category_id");
          Object.keys(bombCateGroup).map(async (cateKey) => {
            const bomIds = bombCateGroup[cateKey].map((bom) => bom.id);
            const bomFeeList = await this.getItemCategotyContract(parseInt(cateKey), bomIds);

            bombCateGroup[cateKey].map(async (bomItem) => {
              const findBomPrice = await bomFeeList.contract_items.find((contract) => contract.item_id === bomItem.id);
              bomItem.qty = AddItem.qty;
              bomItem.price = findBomPrice ? findBomPrice.price : 0;
            });
          });
        } else {
          ids.push(AddItem.id);
        }

        let categoty = inbound_info.item_category_dc.find((categoty) => categoty.id === AddItem.item_category_id);
        const contract_list = await this.getCategoryContract(categoty.id);
        const fee_list = await this.getItemCategotyContract(categoty.id, ids);
        const distance = inbound_info.dc[0].distance;

        categoty = {
          ...categoty,
          item_category: [AddItem],
          contract_list: contract_list,
          item_expense: fee_list && fee_list.contract_item_category_fees,
          contract_items: fee_list && fee_list.contract_items,
          contract_id: fee_list && fee_list.contract_id,
          qty: AddItem.qty,
          discount: { name: "รายหมวดหมู่", qty: 0, price: 0 },
          add_expense: [],
        };

        categoty.item_category_fees.forEach((fee, index) => {
          if (fee_list) {
            const findContrcat = fee_list.contract_item_category_fees.find((contractFee) => contractFee.item_category_fee_id === fee.id);
            categoty.item_category_fees[index]["qty"] = this.itemItypeMapping(fee, categoty.qty, distance);
            if (findContrcat) categoty.item_category_fees[index].price = findContrcat.price;
          }
        });

        categoty.item_category.forEach((category, categoryIndex) => {
          let contractItemIndex = fee_list && fee_list.contract_items && fee_list.contract_items.findIndex((contract) => contract.item_id === category.id);
          let feeListWithQTY = fee_list ? fee_list.contract_items : [];

          if (contractItemIndex && contractItemIndex !== -1) {
            feeListWithQTY.forEach((fee, index) => {
              fee.contract_item_fees.forEach((_, feeIndex) => {
                let contract_fee = feeListWithQTY[index].contract_item_fees[feeIndex];
                feeListWithQTY[index].contract_item_fees[feeIndex]["qty"] = this.itemItypeMapping(contract_fee, categoty.qty, distance);

              });
            });
          }

          let addContractItems = {
            ...category,
            contract_item_fees: contractItemIndex && contractItemIndex !== -1 && fee_list ? fee_list.contract_items[contractItemIndex].contract_item_fees : [],
          };
          categoty.item_category[categoryIndex] = addContractItems;
        });

        new_add_list.push(categoty);
      }

      this.setState({
        addList: new_add_list,
        qtyToAddItem: 1,
        selectToAddItem: null,
      });
      this.removeUsedItem();
    }

    setTimeout(() => {
      this.setState({ is_loading: false });
    }, 1500);
  }

  async resetFees(id) {
    const { addList, inbound_info, approved_date } = this.state;
    const distance = inbound_info.dc[0].distance;
    const addListIndex = addList.findIndex((list) => list.id === id);
    const deList = [...addList];
    let categoryQTY = 0;
    let contract = await this.getItemCategotyContract(
      deList[addListIndex].id,
      deList[addListIndex].item_category.map((itemCate) => itemCate.id),
      null,
      approved_date,
      true
    );
    deList[addListIndex].item_category_fees = contract.contract_item_category_fees;
    deList[addListIndex].item_category.forEach((itemCate, index) => {
      let findContractIndex = deList[addListIndex].contract_items.findIndex((c) => c.item_id === deList[addListIndex].item_category[index].id);
      let findNewContractIndex = contract.contract_items.findIndex((c) => c.id === deList[addListIndex].contract_items[findContractIndex].id);
      categoryQTY = categoryQTY + itemCate.qty;
      deList[addListIndex].item_category[index].contract_item_fees = contract.contract_items[findNewContractIndex].contract_item_fees;
      deList[addListIndex].contract_items[findContractIndex].contract_item_fees = contract.contract_items[findNewContractIndex].contract_item_fees;
      deList[addListIndex].item_category[index].contract_item_fees.forEach((contractItemFee, feeIndex) => {
        let findContractFee = contract.contract_items[findContractIndex].contract_item_fees.find((fee) => fee.id === deList[addListIndex].item_category[index].contract_item_fees[feeIndex].id);
        if (findContractFee) {
          deList[addListIndex].item_category[index].contract_item_fees[feeIndex].price = findContractFee.price;
        }
        deList[addListIndex].item_category[index].contract_item_fees[feeIndex].qty = this.itemItypeMapping(contractItemFee, itemCate.qty, distance);
      });
    });

    deList[addListIndex].item_category_fees.forEach(async (cateFee, index) => {
      let findContract = contract.contract_item_category_fees.find((fee) => fee.item_category_fee_id === deList[addListIndex].item_category_fees[index].id);
      if (findContract) {
        deList[addListIndex].item_category_fees[index].price = findContract.price;
      }
      deList[addListIndex].item_category_fees[index].qty = this.itemItypeMapping(cateFee, categoryQTY, distance);
    });

    this.setState({ addList: deList });
  }

  onAddSumFeesToAddList(result, data) {
    const { addList } = this.state;
    const addListIndex = addList.findIndex((list) => list.id === data.id);
    let deList = [...addList];

    deList[addListIndex]["sum_fee"] = result;
    this.setState({ addList: deList });
  }

  async onSubmitMultiSelected(items) {
    const { inbound_info, addList } = this.state;

    let new_add_list = [...addList];
    const categoryGroups = _.groupBy(items, "item_category_id");

    Object.keys(categoryGroups).map(async (key) => {
      const isCategoryAlreadyAdd = addList.findIndex((item) => item.id === parseInt(key));

      this.setState({ is_loading: true });

      categoryGroups[key].forEach((_, index) => {
        categoryGroups[key][index]["qty"] = 1;
      });

      if (isCategoryAlreadyAdd !== -1) {
        await new_add_list[isCategoryAlreadyAdd].item_category.push(...categoryGroups[key]);
        const item_ids = await new_add_list[isCategoryAlreadyAdd].item_category.map((item) => item.id);
        const fee_list = await this.getItemCategotyContract(new_add_list[isCategoryAlreadyAdd].id, item_ids);
        const distance = inbound_info.dc[0].distance;
        let allitemQty = 0;

        new_add_list[isCategoryAlreadyAdd].contract_items = fee_list.contract_items;

        await new_add_list.map((items, itemIndex) => {
          items.item_category.forEach((category, categoryIndex) => {
            let contractItemIndex = fee_list.contract_items.findIndex((contract) => contract.item_id === category.id);
            if (contractItemIndex !== -1) {
              allitemQty = allitemQty + (category.qty || 1);
              fee_list.contract_items[contractItemIndex].contract_item_fees.forEach((_, index) => {
                let feeList = fee_list.contract_items[contractItemIndex].contract_item_fees[index];
                fee_list.contract_items[contractItemIndex].contract_item_fees[index]["qty"] = this.itemItypeMapping(feeList, category.qty || 1, distance);
              });
            }

            if (category.is_mbom) {
              const bombCateGroup = _.groupBy(category.bom_items_list, "item_category_id");
              Object.keys(bombCateGroup).map(async (cateKey) => {
                const ids = bombCateGroup[cateKey].map((bom) => bom.id);
                const bomFeeList = await this.getItemCategotyContract(parseInt(cateKey), ids);
                bombCateGroup[cateKey].map(async (bomItem) => {
                  const findBomPrice = await bomFeeList.contract_items.find((contract) => contract.item_id === bomItem.id);

                  bomItem.qty = category.qty;
                  bomItem.price = findBomPrice ? findBomPrice.price : 0;
                });
              });
            }

            items.item_category_fees.forEach((_, index) => {
              let categoty_fee = items.item_category_fees[index];
              if (categoty_fee.method_type === 3) {
                items.item_category_fees[index].qty = allitemQty;
              } else {
                items.item_category_fees[index].qty = this.itemItypeMapping(categoty_fee, items.qty, distance);
              }
            });

            let addContractItems = {
              ...category,
              contract_item_fees: contractItemIndex !== -1 ? fee_list.contract_items[contractItemIndex].contract_item_fees : [],
            };
            new_add_list[itemIndex].item_category[categoryIndex] = addContractItems;
          });
        });
      } else {
        let category = inbound_info.item_category_dc.find((item_cate) => item_cate.id === parseInt(key));
        const itemListID = categoryGroups[key].map((item) => item.id);
        const contract_list = await this.getCategoryContract(parseInt(key));
        const fee_list = await this.getItemCategotyContract(parseInt(key), itemListID);
        const distance = inbound_info.dc[0].distance;

        this.setState({ is_loading: true });

        categoryGroups[key].forEach((_, index) => {
          categoryGroups[key][index]["qty"] = 1;
        });

        category = {
          ...category,
          item_category: categoryGroups[key],
          contract_list: contract_list,
          item_expense: fee_list ? fee_list.contract_item_category_fees : [],
          contract_items: fee_list ? fee_list.contract_items : [],
          contract_id: fee_list ? fee_list.contract_id : 0,
          qty: categoryGroups[key].length,
          discount: { name: "รายหมวดหมู่", qty: 0, price: 0 },
          add_expense: [],
        };

        category.item_category_fees.forEach((fee, index) => {
          if (fee_list) {
            let findContract = fee_list?.contract_item_category_fees?.find((contractFee) => contractFee.item_category_fee_id === fee.id);
            category.item_category_fees[index]["qty"] = this.itemItypeMapping(fee, category.qty, distance);
            category.item_category_fees[index].price = findContract ? findContract.price : 0;
          }
        });
        category.item_category.map((itemCategory, categoryIndex) => {
          let contractItemIndex = fee_list ? fee_list?.contract_items?.findIndex((contract) => contract.item_id === itemCategory.id) : -1;
          let feeListWithQTY = fee_list ? fee_list.contract_items : [];
          if (contractItemIndex !== -1) {
            feeListWithQTY?.forEach((fee, index) => {
              fee.contract_item_fees.forEach((_, feeIndex) => {
                let contract_fee = feeListWithQTY[index].contract_item_fees[feeIndex];
                feeListWithQTY[index].contract_item_fees[feeIndex]["qty"] = this.itemItypeMapping(contract_fee, category.qty, distance);
              });
            });
          }

          if (itemCategory.is_mbom) {
            const bombCateGroup = _.groupBy(itemCategory.bom_items_list, "item_category_id");
            Object.keys(bombCateGroup).map(async (cateKey) => {
              const ids = bombCateGroup[cateKey].map((bom) => parseInt(bom.id));
              const bomFeeList = await this.getItemCategotyContract(parseInt(cateKey), ids);
              await bombCateGroup[cateKey].map((bomItem) => {
                const findBomPrice = bomFeeList.contract_items.find((contract) => contract.item_id === bomItem.id);

                bomItem.qty = itemCategory.qty;
                bomItem.price = findBomPrice ? findBomPrice.price : 0;
              });
            });
          }

          let addContractItems = {
            ...itemCategory,
            contract_item_fees: contractItemIndex && contractItemIndex !== -1 ? fee_list?.contract_items[contractItemIndex]?.contract_item_fees : [],
          };
          category.item_category[categoryIndex] = addContractItems;
        });
        new_add_list.push(category);
      }
    });

    setTimeout(() => {
      this.setState({
        addList: new_add_list,
        qtyToAddItem: 1,
        is_loading: false,
        selectToAddItem: null,
      });
      this.removeUsedItem();
    }, 1500);
  }

  async onGetDataByID(itemInbound, data) {
    const { inbound_info, addList } = this.state;
    let new_add_list = [...addList];

    const categoryGroups = _.groupBy(itemInbound, "item_category_id");

    Object.keys(categoryGroups).map(async (key) => {
      let itemListID = [];
      let sumCategoryQTY = 0;
      let category = inbound_info.item_category_dc.find((categoty) => categoty.id === parseInt(key));
      const inbound_item_cat_index = data.inbound_order_item_category.findIndex((inboundCate) => inboundCate.item_category_id === category.id);
      const inbound_item_category = data.inbound_order_item_category[inbound_item_cat_index];

      categoryGroups[key].forEach((cate, index) => {
        let findItemDC = null;

        if (cate.is_mbom) {
          findItemDC = inbound_info.item_dc.find((itemDC) => itemDC.id === cate.contract_item.item_id);
          itemListID.push(cate.contract_item.item_id);
          const bombCateGroup = _.groupBy(findItemDC.bom_items_list, "item_category_id");
          // await this.getItemCategotyContract(parseInt(key), itemListID, inbound_item_category.contract_id);

          Object.keys(bombCateGroup).map(async (cateKey) => {
            const ids = bombCateGroup[cateKey].map((bom) => bom.id);
            const bomFeeList = await this.getItemCategotyContract(parseInt(cateKey), ids, inbound_item_category.contract_id);

            bombCateGroup[cateKey].map((bomItem) => {
              const findBomPrice = bomFeeList ? bomFeeList.contract_items.find((contract) => contract.item_id === bomItem.id) : null;
              bomItem.qty = categoryGroups[key][index].qty;
              bomItem.price = findBomPrice ? findBomPrice.price : 0;
            });
          });
        } else {
          itemListID.push(cate.contract_item.item_id);
        }

        sumCategoryQTY = sumCategoryQTY + categoryGroups[key][index].qty;
        categoryGroups[key][index].in_bound_id = categoryGroups[key][index].id;
        categoryGroups[key][index].installation_date = categoryGroups[key][index].installation_date;
        categoryGroups[key][index].id = categoryGroups[key][index].contract_item.item_id;
        categoryGroups[key][index].bom_items_list = findItemDC ? findItemDC.bom_items_list : [];
      });

      const contract_list = await this.getCategoryContract(parseInt(key));
      const fee_list = await this.getItemCategotyContract(parseInt(key), itemListID, inbound_item_category.contract_id);

      const addedRecived = [];
      data.inbound_order_items.forEach((inboundOrder) => {
        inboundOrder.inbound_order_item_lot.forEach((itemLot) => {
          let lot_params = {
            index: Math.floor(Math.random() * 9999) + 1111,
            isDelete: false,
            id: itemLot.id,
            in_bound_id: inboundOrder.in_bound_id,
            qty: itemLot.qty,
            manufacture_request_id: itemLot.manufacture_request_id,
            remark: itemLot.remark,
          };

          console.log("itemLot", itemLot);

          try {
            lot_params.code = itemLot.inventory_items[0].item ? itemLot.inventory_items[0].item.code : "-";
            lot_params.name = itemLot.inventory_items[0].item.name;
            lot_params.item_category_id = itemLot.inventory_items[0].item.item_category_id;
            lot_params.unit = itemLot.inventory_items[0].item.unit;
            lot_params.received_date = itemLot.inventory_items[0].received_date;
            lot_params.receiver_name = `${itemLot.inventory_items[0].inventory_logs[0].user.firstname} ${itemLot.inventory_items[0].inventory_logs[0].user.lastname}`;
          } catch (e) {}

          addedRecived.push(lot_params);
        });

        this.setState({ addReceivedItems: addedRecived });
      });

      if (fee_list && fee_list.contract_items) {
        fee_list.contract_items.forEach((item, index) => {
          const finding_contract = categoryGroups[key].find((cate) => cate.id === item.item_id);
          fee_list.contract_items[index].price = finding_contract.contract_price;
        });
      }

      category = {
        ...category,
        item_category: categoryGroups[key],
        contract_list: contract_list,
        item_expense: fee_list ? fee_list.contract_item_category_fees : {},
        contract_items: fee_list ? fee_list.contract_items : {},
        contract_id: fee_list ? fee_list.contract_id : {},
        qty: sumCategoryQTY,
        discount: { name: "รายหมวดหมู่", qty: 0, price: 0 },
        add_expense: [],
      };

      const add_expense_items = data.inbound_order_item_category[inbound_item_cat_index].item_expense.filter((itemCate) => itemCate.resource_type === "ItemCategoryFee" && itemCate.resource_id === null && itemCate.resource_type_id === null);
      add_expense_items.forEach((expenseItem, index) => {
        add_expense_items[index]["price"] = expenseItem.contract_price;
      });

      category.informed_date = data.inbound_order_item_category[inbound_item_cat_index].informed_date;
      category.add_expense = add_expense_items;

      category.item_category_fees.forEach((categoryFee, index) => {
        // const category_fee = category.item_category_fees[index];
        const findExistingCategoryFee = inbound_item_category.item_expense.find((expense) => expense.resource_type_id === categoryFee.id && expense.resource_type === "ItemCategoryFee");

        if (findExistingCategoryFee) {
          category.item_category_fees[index].update_id = findExistingCategoryFee.id;
          category.item_category_fees[index]["qty"] = findExistingCategoryFee.qty;
          category.item_category_fees[index].price = findExistingCategoryFee.contract_price;
          category.item_category_fees[index]["position"] = findExistingCategoryFee.position;
        }
      });

      category.item_category.forEach(async (itemCategory, categoryIndex) => {
        const contractItemIndex = fee_list ? (fee_list.contract_items ? fee_list.contract_items.findIndex((contract) => contract.item_id === itemCategory.id) : -1) : -1;
        let addContractItems = {
          ...itemCategory,
          contract_item_fees: contractItemIndex !== -1 && fee_list ? fee_list.contract_items[contractItemIndex].contract_item_fees : [],
        };

        addContractItems.contract_item_fees.forEach((itemFee, index) => {
          const findExistingExpense = inbound_item_category.item_expense.find((expense) => expense.resource_type_id === itemFee.item_fee_id && expense.resource_type === "ItemFee");
          if (findExistingExpense) {
            addContractItems.contract_item_fees[index].update_id = findExistingExpense.id;
            addContractItems.contract_item_fees[index]["qty"] = findExistingExpense.qty;
            addContractItems.contract_item_fees[index].price = findExistingExpense.contract_price;
            addContractItems.contract_item_fees[index]["position"] = findExistingExpense.position;
          }
        });

        category.item_category[categoryIndex] = addContractItems;
      });

      const existingDiscount = inbound_item_category.item_expense.find((expense) => expense.resource_type === "ItemCategoryDiscount");

      category.discount.price = existingDiscount && existingDiscount.contract_price;
      category.discount.qty = existingDiscount && existingDiscount.qty;
      category.discount["id"] = existingDiscount && existingDiscount.id;
      category.contract_id = inbound_item_category.contract_id;

      new_add_list.push(category);

      // console.log("data", data);

      this.setState({
        order_status: INBOUND_ORDER_STATES[data.state],
        order_code: data.code,
        addList: new_add_list,
        qtyToAddItem: 1,
        is_loading: false,
        selectToAddItem: null,
        approved_date: data.approved_date,
        noted: data.note,
      });

      this.onChangeApproveDate(dayjs(data.approved_date), new_add_list);
      this.removeUsedItem();
    });

    if (data.inbound_order_item_pos.length > 0) {
      let poList = [];
      data.inbound_order_item_pos.forEach((po) => {
        const findAddListId = data.inbound_order_item_pos.find((item) => item.contract_id === po.contract_id);

        if (findAddListId) {
          let poTemplate = {
            index: Math.floor(Math.random() * 9999) + 1111,
            id: po.contract_id,
            po_id: po.id,
            po_date: po.po_date,
            contract_id: po.contract_id,
            po_code: po.po_code,
            submit_date: po.submit_date,
            manufacture_request_id: po.manufacture_request_id,
            isDelete: false,
          };

          poList.push(poTemplate);
        }
      });

      if (poList.length > 0) this.setState({ is_loading: false, addListPo: poList });
    }

    this.setState({ is_loading: false });
  }

  onClickDeleteItem(item, list) {
    const { addList, inbound_info } = this.state;
    const addListIndex = addList.findIndex((listItem) => listItem.id === list.id);
    let deList = [...addList];

    if (item) {
      let contractItemIndex = addList[addListIndex].contract_items.findIndex((contractItem) => contractItem.item_id === item.item_id);
      let categoryItemIndex = addList[addListIndex].item_category.findIndex((categoryItem) => categoryItem.id === item.item_id);

      deList[addListIndex].contract_items.splice(contractItemIndex, 1);
      deList[addListIndex].item_category.splice(categoryItemIndex, 1);

      const findAddBackToList = inbound_info.item_dc.find((itemDC) => itemDC.id === item.item_id);

      this.removeUsedItem(findAddBackToList);
    }

    if ((addList[addListIndex].contract_items && addList[addListIndex].contract_items.length === 0) || !item) {
      addList.splice(addListIndex, 1);
      deList = addList;
    }

    this.setState({ addList: deList });
    // this.removeUsedItem();
  }

  onClickDeleteItemFee(categoty, fee, list) {
    const { addList } = this.state;
    const deList = [...addList];
    const addListIndex = addList.findIndex((listItem) => listItem.id === list.id);
    let categoryItemIndex = addList[addListIndex].item_category.findIndex((categoryItem) => categoryItem.id === categoty.id);
    let cetegotyItemFeeIndex = deList[addListIndex].item_category[categoryItemIndex].contract_item_fees.findIndex((itemFee) => itemFee.id === fee.id);

    deList[addListIndex].item_category[categoryItemIndex].contract_item_fees.splice(cetegotyItemFeeIndex, 1);
    this.setState({ addList: deList });
  }

  onClickDeleteCategotyFee(item, list) {
    const { addList } = this.state;
    const deList = [...addList];
    const addListIndex = addList.findIndex((listItem) => listItem.id === list.id);
    let categoryItemIndex = addList[addListIndex].item_category_fees.findIndex((categoryItem) => categoryItem.id === item.id);

    deList[addListIndex].item_category_fees.splice(categoryItemIndex, 1);
    this.setState({ addList: deList });
  }

  onDeleteAddExpense(item, list) {
    const { addList } = this.state;
    const deList = [...addList];
    const addListIndex = addList.findIndex((listItem) => listItem.id === list.id);
    let addExpenseIndex = addList[addListIndex].add_expense.findIndex((addExpense) => addExpense.id === item.id);

    deList[addListIndex].add_expense.splice(addExpenseIndex, 1);
    this.setState({ addList: deList });
  }

  onChangeQTYAddExpense(e, item, list) {
    const { addList } = this.state;
    const deList = [...addList];
    const addListIndex = addList.findIndex((listItem) => listItem.id === list.id);
    let addExpenseIndex = addList[addListIndex].add_expense.findIndex((addExpense) => addExpense.id === item.id);

    deList[addListIndex].add_expense[addExpenseIndex].qty = e;
    this.setState({ addList: deList });
  }

  onChangeInstallationDate(e, item, list) {
    const { addList } = this.state;
    let deList = [...addList];
    const addListIndex = addList.findIndex((listItem) => listItem.id === list.id);
    let itemCategoryIndex = addList[addListIndex].item_category.findIndex((itemCate) => itemCate.id === item.id);

    deList[addListIndex].item_category[itemCategoryIndex]["installation_date"] = dayjs(e).format("YYYY-MM-DD");
    this.setState({ addList: deList });
  }

  onChangeInformedDate(e, list) {
    const { addList } = this.state;
    let deList = [...addList];
    const addListIndex = addList.findIndex((listItem) => listItem.id === list.id);

    deList[addListIndex]["informed_date"] = dayjs(e).format("YYYY-MM-DD");
    this.setState({ addList: deList });
  }

  onChangePriceAddExpense(e, item, list) {
    const { addList } = this.state;
    const deList = [...addList];
    const addListIndex = addList.findIndex((listItem) => listItem.id === list.id);
    let addExpenseIndex = addList[addListIndex].add_expense.findIndex((addExpense) => addExpense.id === item.id);

    deList[addListIndex].add_expense[addExpenseIndex].price = e;
    this.setState({ addList: deList });
  }

  onChangeItemFeeQTY(e, categoty, fee, list) {
    const { addList } = this.state;
    const deList = [...addList];
    const addListIndex = addList.findIndex((listItem) => listItem.id === list.id);
    let categoryItemIndex = addList[addListIndex].item_category.findIndex((categoryItem) => categoryItem.id === categoty.id);
    let cetegotyItemFeeIndex = deList[addListIndex].item_category[categoryItemIndex].contract_item_fees.findIndex((itemFee) => itemFee.id === fee.id);

    deList[addListIndex].item_category[categoryItemIndex].contract_item_fees[cetegotyItemFeeIndex].qty = e;
    this.setState({ addList: deList });
  }

  onChangeItemFeePrice(e, categoty, fee, list) {
    const { addList } = this.state;
    const deList = [...addList];
    const addListIndex = addList.findIndex((listItem) => listItem.id === list.id);
    let categoryItemIndex = addList[addListIndex].item_category.findIndex((categoryItem) => categoryItem.id === categoty.id);
    let cetegotyItemFeeIndex = deList[addListIndex].item_category[categoryItemIndex].contract_item_fees.findIndex((itemFee) => itemFee.id === fee.id);

    deList[addListIndex].item_category[categoryItemIndex].contract_item_fees[cetegotyItemFeeIndex].price = e;
    this.setState({ addList: deList });
  }

  onChangeCategotyFeePrice(e, item, list) {
    const { addList } = this.state;
    const deList = [...addList];
    const addListIndex = addList.findIndex((listItem) => listItem.id === list.id);
    let categoryItemIndex = addList[addListIndex].item_category_fees.findIndex((categoryItem) => categoryItem.id === item.id);
    deList[addListIndex].item_category_fees[categoryItemIndex].price = e;
    this.setState({ addList: deList });
  }

  onChangeCategotyFeeQTY(e, item, list) {
    const { addList } = this.state;
    const deList = [...addList];
    const addListIndex = addList.findIndex((listItem) => listItem.id === list.id);
    let categoryItemIndex = addList[addListIndex].item_category_fees.findIndex((categoryItem) => categoryItem.id === item.id);

    deList[addListIndex].item_category_fees[categoryItemIndex].qty = e;
    this.setState({ addList: deList });
  }

  itemItypeMapping(item, qty = 1, distance) {
    let qtyItem = 0;
    if (item.method_type === 1 || item.method_type === 6) {
      qtyItem = 1;
    } else if (item.method_type === 2 || item.method_type === 5) {
      if (distance >= item.from_value && distance >= item.to_value && item.to_value) {
        qtyItem = item.to_value - item.from_value + 1;
      } else if (!item.to_value && distance >= item.from_value) {
        qtyItem = distance - item.from_value + 1;
      }
    } else if (item.method_type === 3) {
      qtyItem = qty;
    } else {
      qtyItem = qty;
    }

    return qtyItem;
  }

  async onChangeApproveDate(e, newList = null) {
    const { addList } = this.state;

    let newAddItem = [...addList];
    await newAddItem?.forEach(async (item, index) => {
      const contract_list = await this.getCategoryContract(item.id, dayjs(e).format("YYYY-MM-DD"));
      let contract = await this.getItemCategotyContract(
        item.id,
        item.item_category.map((itemCate) => itemCate.id),
        item.contract_id,
        dayjs(e).format("YYYY-MM-DD")
      );

      newAddItem[index].contract_list = contract_list;

      if (contract) {
        if (newList) {
          newAddItem[index].contract_items = contract.contract_items;
          contract.contract_items?.forEach((c_contract, c_index) => {
            newList?.forEach((item) => {
              const finditem = item.item_category.find(({ contract_item_id }) => contract_item_id === c_contract.id);

              if (finditem) {
                contract.contract_items[c_index].price = finditem.contract_price;
              }
            });
          });
        }

        newAddItem[index].item_expense = contract.contract_item_category_fees;
        newAddItem[index].contract_items = contract.contract_items;
        newAddItem[index].contract_id = contract.contract_id;
      } else {
        newAddItem[index].contract_items = [];
        newAddItem[index].item_expense = [];
      }

      this.setState({ approved_date: dayjs(e).format("YYYY-MM-DD") });
      this.updateItemExpense(newAddItem);
    });
  }

  async onChangeContract(contract_id, category_id, items) {
    const { addList } = this.state;

    this.getItemCategotyContract(category_id, items, contract_id).then((contract) => {
      this.setState({ is_loading: true });
      let newAddItem = [...addList];
      let contractIndex = addList.findIndex((item) => item.id === category_id);
      newAddItem[contractIndex].item_expense = contract.contract_item_category_fees;
      newAddItem[contractIndex].contract_items = contract.contract_items;
      newAddItem[contractIndex].contract_id = contract.contract_id;

      newAddItem[contractIndex].item_category.forEach((cate) => {
        if (cate.is_mbom) {
          const bombCateGroup = _.groupBy(cate.bom_items_list, "item_category_id");
          Object.keys(bombCateGroup).map(async (cateKey) => {
            const bomIds = bombCateGroup[cateKey].map((bom) => bom.id);
            const bomFeeList = await this.getItemCategotyContract(parseInt(cateKey), bomIds, contract_id);

            bombCateGroup[cateKey].map((bomItem) => {
              const findBomPrice = bomFeeList.contract_items.find((contract) => contract.item_id === bomItem.id);
              bomItem.qty = newAddItem[contractIndex].qty;
              bomItem.price = findBomPrice ? findBomPrice.price : 0;
            });

            this.updateItemExpense(newAddItem);
          });
        }
      });

      this.setState({ is_loading: false });
    });
  }

  updateItemExpense(newAddItem) {
    newAddItem.forEach((addItem, itemIndex) => {
      addItem.item_category_fees.forEach((categoryFee, categoryFeeIndex) => {
        if (addItem.item_expense) {
          let itemExpenseItem = addItem.item_expense.find((expense) => expense.item_category_fee_id === categoryFee.id);
          addItem.item_category_fees[categoryFeeIndex].price = itemExpenseItem && itemExpenseItem.price ? itemExpenseItem.price : 0;
        } else {
          addItem.item_category_fees[categoryFeeIndex].price = 0;
        }
      });

      addItem.item_category.forEach((itemCate, itemCateIndex) => {
        itemCate.contract_item_fees.forEach((fee, feeIndex) => {
          let contractItem = addItem.contract_items && addItem.contract_items.find((ctItem) => ctItem.item_id === itemCate.id);
          let contractItemFee = contractItem && contractItem.contract_item_fees && contractItem.contract_item_fees.find((itemFee) => itemFee.item_fee_id === fee.item_fee_id);
          newAddItem[itemIndex].item_category[itemCateIndex].contract_item_fees[feeIndex].price = contractItemFee ? contractItemFee.price : 0;
        });
      });
    });

    this.setState({ addList: newAddItem });
  }

  getItemCategotyContract(category_id, items, contract_id = null, approvedDate = dayjs().format("YYYY-MM-DD"), isReset = false) {
    this.setState({ is_loading: true });

    let params = {
      sort: JSON.stringify(["created_at", "DESC"]),
      filter: {
        for_mr: false,
        is_active: true,
        approved_date: approvedDate,
        item_category_id: category_id,
        province_id: this.state.inbound_info.dc[0].address.province_id,
        item_ids: items,
      },
    };

    if (contract_id) {
      params.filter.id = contract_id;
    }

    return axios
      .get(`${process.env.REACT_APP_API_URL}/contract_by_cate`, {
        params: params,
      })
      .then((response) => {
        const appned_data = response?.data?.contract_item_categories ? response?.data?.contract_item_categories[0] : {};

        const res = {
          ...appned_data,
          contract_id: response.data.id,
        };

        this.setState({ is_loading: false });
        return res;
      })
      .catch((err) => {
        this.setState({ is_loading: false });
        return err;
      });
  }

  removeUsedItem(addedBack = null) {
    const { categoryGroup, addList } = this.state;
    let cateGroup = [...categoryGroup];

    if (addedBack) {
      const cateGroupIndex = cateGroup.findIndex((cate) => cate.id === addedBack.item_category_id);
      cateGroup[cateGroupIndex].item_dc.push(addedBack);
    } else {
      addList?.forEach((list) => {
        list.item_category?.forEach((item) => {
          cateGroup?.forEach((cate, index) => {
            const find_not_duplicate = cate.item_dc.filter((item_dc) => item_dc.id !== item.id);
            cateGroup[index].item_dc = find_not_duplicate;
          });
        });
      });
    }

    this.setState({ categoryGroup: cateGroup });
  }

  groupCategotyWithItem(data) {
    let groups = [];

    data.item_category_dc?.forEach((categoty) => {
      const find_item = data.item_dc.filter((item) => item.item_category_id === categoty.id);
      if (find_item.length > 0) {
        groups.push({ ...categoty, item_dc: find_item });
      }
    });
    return groups;
  }

  onSubmitAddExpense(values, category) {
    const { addList } = this.state;
    let id = Math.floor(Math.random() * 9999) + 1111;
    let deList = [...addList];
    const addListIndex = deList.findIndex((listItem) => listItem.id === category.id);

    deList[addListIndex].add_expense.push({
      id: id,
      name: values.service_name,
      unit: values.service_unit,
      qty: values.service_qty,
      price: values.service_price,
    });
    this.setState({ addList: deList });
  }

  onCancelPoDelete() {
    this.setState({ visibleDeletePo: false, poDeleteIndex: null });
  }

  onAddReceivedItem(item, qty, remark, receivedDate) {
    const { addReceivedItems } = this.state;
    const addedRecived = [...addReceivedItems];
    const newItem = { ...item };

    const lot_params = {
      index: Math.floor(Math.random() * 9999) + 1111,
      qty: qty,
      in_bound_id: newItem.in_bound_id,
      code: newItem.code,
      remark: remark,
      name: newItem.name,
      item_category_id: newItem.item_category_id,
      unit: newItem.unit,
      received_date: dayjs(receivedDate).format("YYYY-MM-DD"),
    };

    addedRecived.push(lot_params);
    this.setState({ addReceivedItems: addedRecived });
  }

  onChangeQTYAddedLot(index, e, type) {
    const { addReceivedItems } = this.state;
    const addedReceivedIndex = addReceivedItems.findIndex((item) => item.index === index);
    let newAddedReceivedItems = [...addReceivedItems];

    if (addedReceivedIndex !== -1) {
      newAddedReceivedItems[addedReceivedIndex][type] = e;
    }

    this.setState({ addReceivedItems: newAddedReceivedItems });
  }

  onDeleteWarrantyCode(itemID, item) {
    const { addList } = this.state;
    const params = { for_inbound_order: true, inbound_order_item_id: itemID };

    const addListIndex = addList.findIndex((list) => list.id === item.item_category_id);
    const itemCateIndex = addList[addListIndex].item_category.findIndex((itemCate) => itemCate.in_bound_id === item.in_bound_id);

    const newAddList = [...addList];

    this.setState({ is_loading: true });

    axios
      .put(`${process.env.REACT_APP_API_URL}/purchasing_orders/delete_warranty_codes`, params)
      .then(() => {
        newAddList[addListIndex].item_category[itemCateIndex].warranty_code = [];
        this.setState({ is_loading: false, addList: newAddList });
      })
      .catch(function (error) {
        this.setState({ is_loading: false });
      });
  }

  getContractList(list) {
    const { addList } = this.state;
    let contractList = [];

    addList.forEach((item) => {
      item.contract_list.forEach((list) => {
        let isExist = contractList.findIndex((contract) => contract.id === item.contract_id);
        if (isExist === -1) {
          contractList.push(list);
        }
      });
    });

    return contractList;
  }

  async onClickAutoGenerateMR() {
    const { addList, inbound_data, addListPo } = this.state;
    const { current_user } = this.props;
    const manufacture_request = [];
    const newList = [...addList];
    const poList = this.getContractList();

    await poList.forEach((pos) => {
      let isAlreadyMr = addListPo.find((po) => po.contract_id === pos.contract_id && po.manufacture_request_id !== null);
      if (!isAlreadyMr) {
        let contract = addListPo.find((po) => po.contract_id === pos.id);

        let mrs = [];
        newList.forEach((itemList) => {
          if (itemList.contract_id === pos.id) {
            itemList.item_category.forEach((item) => {
              const params = {
                client_station_id: 637,
                station_name: "คลัง DC",
                station_type: "DODO",
                item_category_id: item.item_category_id,
                item_category_name: item.name,
              };

              mrs.push(params);
            });

            const mr_params = {
              inbound_order_item_po_id: contract ? contract.po_id || null : null,
              contract_id: pos.id,
              inbound_order_id: inbound_data.id,
              mr: {
                toggle_committee: false,
                id: null,
                code: "31300039/",
                mr_date: dayjs().format(),
                mr_subject: "",
                manufacturer_name: "",
                contract_no: "",
                contract_date: dayjs().format(),
                state: "draft",
                request_date: dayjs().format(),
                manufacture_request_stations: mrs,
                nb_days_to_complete: "",
                coordinator_name: `${current_user.firstname} ${current_user.lastname}`,
                approver_name: "",
                approver_title: "",
                s3_signature_upload: "",
                s3_inspection_sign_url: "",
                cancel_note: "",
                vendor_id: "",
                user_id: current_user.id,
                order_po_gr: {
                  order_id: null,
                  id: null,
                  po_code: null,
                },
                contract_id: pos.id,
              },
            };

            console.log("mr_params", mr_params);

            manufacture_request.push(mr_params);
          }
        });
      }
    });

    const params = {
      mrs: manufacture_request,
    };

    this.setState({ is_loading: true });
    axios
      .post(`${process.env.REACT_APP_API_URL}/manufacture_requests/auto_gen`, params)
      .then((response) => {
        let poList = [];
        if (response.status === 200) {
          response.data.forEach((res) => {
            let poTemplate = {
              index: Math.floor(Math.random() * 9999) + 1111,
              id: res.contract_id,
              po_id: res.id,
              po_date: res.po_date,
              contract_id: res.contract_id,
              po_code: res.po_code,
              submit_date: res.submit_date,
              manufacture_request_id: res.manufacture_request_id,
            };

            poList.push(poTemplate);
          });

          this.setState({ addListPo: poList });

          this.handleSubmitUpdate();
        }
      })
      .then((error) => {
        this.setState({ is_loading: false });
      });
  }

  onDeleteLotNoneID(index) {
    const { addReceivedItems } = this.state;
    const deleteIndex = addReceivedItems.findIndex((item) => item.index === index);
    let newAdded = [...addReceivedItems];

    newAdded.splice(deleteIndex, 1);
    this.setState({ addReceivedItems: newAdded });
  }

  onDeleteLotID(index) {
    const { addReceivedItems } = this.state;
    const deleteIndex = addReceivedItems.findIndex((item) => item.index === index);
    let newAdded = [...addReceivedItems];

    newAdded.splice(deleteIndex, 1);
    this.setState({ addReceivedItems: newAdded });
  }

  displayTabDetail() {
    const { order_code, expense_ratio, visibleDeletePo, addReceivedItems, addList, selectToAddItem, inbound_data, qtyToAddItem, is_loading, categoryGroup, inbound_info, addListPo, noted, approved_date } = this.state;

    if (this.state.current_tab === DC_INBOUND_TABS.info) {
      return <DcInboundOrderFormInfo dc_info={inbound_info.dc} current_user={this.props.current_user} order_data={inbound_data} />;
    } else if (this.state.current_tab === DC_INBOUND_TABS.product_list) {
      return (
        <DcInboundOrderFormProductList
          categoryGroup={categoryGroup}
          isLoading={is_loading}
          expense_ratio={expense_ratio}
          addList={addList}
          selectToAddItem={selectToAddItem}
          qtyToAddItem={qtyToAddItem}
          inbound_data={inbound_info}
          order_data={inbound_data}
          noted={noted}
          match={this.props.match}
          approveDate={approved_date}
          onSelectItemToAdd={this.onSelectItemToAdd}
          onChangeQtyItem={this.onChangeQtyItem}
          onSubmitAddItem={this.onSubmitAddItem}
          onChangeContract={this.onChangeContract}
          handelChangeItemQTY={this.handelChangeItemQTY}
          onClickDeleteItem={this.onClickDeleteItem}
          onClickDeleteItemFee={this.onClickDeleteItemFee}
          onClickDeleteCategotyFee={this.onClickDeleteCategotyFee}
          onChangeItemFeeQTY={this.onChangeItemFeeQTY}
          onChangeCategotyFeeQTY={this.onChangeCategotyFeeQTY}
          onSubmitMultiSelected={this.onSubmitMultiSelected}
          onChangeDiscount={this.onChangeDiscount}
          onSubmitAddExpense={this.onSubmitAddExpense}
          onChangeExpRatio={this.onChangeExpRatio}
          onDeleteAddExpense={this.onDeleteAddExpense}
          onChangeQTYAddExpense={this.onChangeQTYAddExpense}
          onChangePriceAddExpense={this.onChangePriceAddExpense}
          onChangeCategotyFeePrice={this.onChangeCategotyFeePrice}
          onChangeItemFeePrice={this.onChangeItemFeePrice}
          handelChangeItemPrice={this.handelChangeItemPrice}
          onChangeApproveDate={this.onChangeApproveDate}
          onAddSumFeesToAddList={this.onAddSumFeesToAddList}
          resetFees={this.resetFees}
          onChangeNoted={this.onChangeNoted}
        />
      );
    } else if (this.state.current_tab === DC_INBOUND_TABS.inform) {
      return (
        <DcInboundOrderFormInform
          onDeleteWarrantyCode={this.onDeleteWarrantyCode}
          match={this.props.match}
          addList={addList}
          onGetWarrantyCode={this.onGetWarrantyCode}
          onChangeInstallationDate={this.onChangeInstallationDate}
          onChangeInformedDate={this.onChangeInformedDate}
        />
      );
    } else if (this.state.current_tab === DC_INBOUND_TABS.po) {
      return (
        <DcInboundOrderFormPo
          history={this.props.history}
          match={this.props.match}
          noted={noted}
          addList={addList}
          addListPo={addListPo}
          onChangeNoted={this.onChangeNoted}
          onChangePoSubmitDate={this.onChangePoSubmitDate}
          onChangePoDate={this.onChangePoDate}
          onChangePoCode={this.onChangePoCode}
          onClickAddListPo={this.onClickAddListPo}
          onClickDeletePo={this.onClickDeletePo}
          onChangeSelectPo={this.onChangeSelectPo}
          onClickAutoGenerateMR={this.onClickAutoGenerateMR}
          visibleDeletePo={visibleDeletePo}
          onCancelPoDelete={this.onCancelPoDelete}
          onAcceptDeletPo={this.onAcceptDeletPo}
        />
      );
    } else if (this.state.current_tab === DC_INBOUND_TABS.check_product) {
      return (
        <DcInboundOrderFormReceived
          current_user={this.props.current_user}
          addList={addList}
          onAddReceivedItem={this.onAddReceivedItem}
          addReceivedItems={addReceivedItems}
          onDeleteLotNoneID={this.onDeleteLotNoneID}
          onDeleteLotID={this.onDeleteLotID}
          onChangeQTYAddedLot={this.onChangeQTYAddedLot}
          order_code={order_code}
          fullData={this.state.inbound_data}
        />
      );
    }
  }

  getCategoryContract(categoty, approveDate = dayjs().format("YYYY-MM-DD")) {
    let params = {
      sort: JSON.stringify(["created_at", "DESC"]),
      filter: {
        is_active: true,
        for_mr: false,
        approved_date: approveDate,
        item_category_id: categoty,
      },
    };

    return axios
      .get(`${process.env.REACT_APP_API_URL}/contract_options_by_cate`, {
        params: params,
      })
      .then((response) => {
        this.setState({ is_loading: false });
        return response.data;
      })
      .catch(function (error) {
        this.setState({ is_loading: false });
      });
  }

  async getInfo() {
    localStorage.removeItem("current_tab");
    await axios
      .get(`${process.env.REACT_APP_API_URL}/inbound_info`)
      .then(async (response) => {
        if (response.status === 200) {
          await this.getDistanceFromMatrix(response.data);
        }
      })
      .catch((error) => {
        this.setState({ is_loading: false });
      });
  }

  onGetWarrantyCode(item, contract_id) {
    const { addList } = this.state;
    const params = {
      asset_code: item.contract_item.item.asset_code ? item.contract_item.item.asset_code : item.item_category.code,
      contract_id: contract_id,
      item_category_id: item.item_category_id,
      item_id: item.id,
      for_inbound_order: true,
      qty: item.qty,
      inbound_order_item_id: item.in_bound_id,
    };

    const addListIndex = addList.findIndex((list) => list.id === item.item_category_id);
    const itemCateIndex = addList[addListIndex].item_category.findIndex((itemCate) => itemCate.in_bound_id === item.in_bound_id);

    const newAddList = [...addList];

    this.setState({ is_loading: true });

    axios.put(`${process.env.REACT_APP_API_URL}/purchasing_orders/get_warranty_codes`, params).then((response) => {
      newAddList[addListIndex].item_category[itemCateIndex].warranty_code = response.data;
      this.setState({ is_loading: false, addList: newAddList });
    });
  }

  getInbound(id) {
    this.setState({ is_loading: true });
    axios
      .get(`${process.env.REACT_APP_API_URL}/inbound/${id}`)
      .then((response) => {
        if (response.status === 200) {
          const resData = response.data;
          this.onGetDataByID(resData.inbound_order_items, resData);
          this.setState({ inbound_data: resData });
        }
      })
      .catch((err) => {
        this.setState({ is_loading: false });
      });
  }

  onChangeSelectPo(e, index) {
    const { addListPo } = this.state;
    const addListPoIndex = addListPo.findIndex((po) => po.index === index);
    let deAddListPo = [...addListPo];

    deAddListPo[addListPoIndex].id = e;
    deAddListPo[addListPoIndex].contract_id = e;

    this.setState({ addListPo: deAddListPo });
  }

  onChangePoCode(e, index) {
    const { addListPo } = this.state;
    const addListPoIndex = addListPo.findIndex((po) => po.index === index);
    let deAddListPo = [...addListPo];

    deAddListPo[addListPoIndex].po_code = e.target.value;
    this.setState({ addListPo: deAddListPo });
  }

  onChangePoDate(e, index) {
    const { addListPo } = this.state;
    const addListPoIndex = addListPo.findIndex((po) => po.index === index);
    let deAddListPo = [...addListPo];

    deAddListPo[addListPoIndex].po_date = dayjs(e).format("YYYY-MM-DD");
    this.setState({ addListPo: deAddListPo });
  }

  onChangePoSubmitDate(e, index) {
    const { addListPo } = this.state;
    const addListPoIndex = addListPo.findIndex((po) => po.index === index);
    let deAddListPo = [...addListPo];

    deAddListPo[addListPoIndex].submit_date = dayjs(e).format("YYYY-MM-DD");
    this.setState({ addListPo: deAddListPo });
  }

  onClickDeletePo(index) {
    this.setState({ visibleDeletePo: true, poDeleteIndex: index });
  }

  onAcceptDeletPo() {
    const { addListPo, poDeleteIndex } = this.state;
    const addListPoIndex = addListPo.findIndex((po) => po.index === poDeleteIndex);
    let deAddListPo = [...addListPo];

    this.setState({ is_loading: true, visibleDeletePo: false });

    if (deAddListPo[addListPoIndex].po_id) {
      axios
        .delete(`${process.env.REACT_APP_API_URL}/inbound_order_item_po/${deAddListPo[addListPoIndex].po_id}`)
        .then((response) => {
          if (response.status === 200) {
            // if (addListPo.length <= 1) {
            //   deAddListPo[addListPoIndex] = poTemplate;
            // } else {
            //   deAddListPo.splice(addListPoIndex, 1);
            // }
            deAddListPo.splice(addListPoIndex, 1);
            this.setState({
              addListPo: deAddListPo,
              visibleDeletePo: false,
              poDeleteIndex: null,
            });
          }
          this.setState({ is_loading: false });
        })
        .catch((error) => {
          console.log("error :>> ", error);
        });
    } else {
      this.setState({ is_loading: false });
      deAddListPo.splice(addListPoIndex, 1);
      this.setState({
        addListPo: deAddListPo,
        visibleDeletePo: false,
        poDeleteIndex: null,
      });
    }
  }

  onClickAddListPo() {
    const { addListPo } = this.state;
    const deAddListPo = [...addListPo];
    let template = { ...poTemplate };

    template.index = Math.floor(Math.random() * 9999) + 1111;
    template.id = null;

    deAddListPo.push({ ...template });

    console.log("deAddListPo", deAddListPo);

    this.setState({ addListPo: deAddListPo });
  }

  handelChangeItemQTY(e, value, item_category) {
    clearTimeout(qtyChangeTimeout);
    qtyChangeTimeout = setTimeout(() => {
      let valueQty = e || 1;
      let { addList } = this.state;
      let newAddlist = [...addList];
      let addItemIndex = addList.findIndex((item) => item.id === value.id);
      let itemCategotyIndex = addList[addItemIndex].item_category.findIndex((item) => item.id === item_category.id);
      let itemCategoryQTY = 0;
      newAddlist[addItemIndex].item_category[itemCategotyIndex].qty = valueQty;

      if (newAddlist[addItemIndex].item_category[itemCategotyIndex].bom_items_list.length > 0) {
        newAddlist[addItemIndex].item_category[itemCategotyIndex].bom_items_list.forEach((_, index) => {
          newAddlist[addItemIndex].item_category[itemCategotyIndex].bom_items_list[index].qty = valueQty;
        });
      }

      addList[addItemIndex].item_category.forEach((categoty) => {
        let cateQTY = categoty.qty ? categoty.qty : 1;
        itemCategoryQTY = itemCategoryQTY + cateQTY;
      });

      addList[addItemIndex].item_category_fees.forEach((fee, index) => {
        if (fee.method_type === 3) {
          if (e >= fee.from_value) {
            newAddlist[addItemIndex].item_category_fees[index].qty = itemCategoryQTY - fee.from_value + 1;
          }
        }
      });

      if (newAddlist[addItemIndex].item_category[itemCategotyIndex].contract_item_fees) {
        newAddlist[addItemIndex].item_category[itemCategotyIndex].contract_item_fees.forEach((fee, index) => {
          if (fee.method_type === 3) {
            if (e >= fee.from_value) {
              newAddlist[addItemIndex].item_category[itemCategotyIndex].contract_item_fees[index].qty = valueQty - fee.from_value + 1;
            }
          }
        });
      }

      this.setState({ addList: newAddlist });
    }, 250);
  }

  handelChangeItemPrice(e, value, item_category) {
    let { addList } = this.state;
    let newAddlist = [...addList];
    let addItemIndex = addList.findIndex((item) => item.id === value.id);
    let itemCategotyIndex = addList[addItemIndex].contract_items.findIndex((item) => item.item_id === item_category.id);

    newAddlist[addItemIndex].contract_items[itemCategotyIndex].price = e;
    this.setState({ addList: newAddlist });
  }

  handleSubmitUpdate() {
    const { addList, noted, approved_date, inbound_info, addListPo, addReceivedItems } = this.state;
    let inbound_order_items = [];
    let inbound_order_item_category = [];
    let inbound_order_item_pos = [];

    this.setState({ is_loading: true });

    let error_contract = "";

    addList.forEach((listItem) => {
      let inbound_order_item_expense = [];
      let categoryQTY = 0;
      let position = 0;

      listItem.item_category.forEach((itemCate) => {
        const contractItem = listItem?.contract_items?.find((contract) => contract.item_id === itemCate.id);
        const findAaddedItems = addReceivedItems.filter((addedItem) => addedItem.in_bound_id === itemCate.in_bound_id);
        const expIndex = expense_ratio.findIndex((ratio) => ratio.categoty_item_id === itemCate.id);

        if (!contractItem) {
          error_contract = error_contract + `- ไม่พบสินค้า ${itemCate.name} นี้ในสัญญาที่เลือก\n`;
        }

        let inbound_order_item_lot = [];
        findAaddedItems.forEach((addedItem) => {
          let params = {
            qty: addedItem.isDelete ? 0 : addedItem.qty,
            received_date: addedItem.received_date ? addedItem.received_date : null,
            remark: addedItem.remark ? addedItem.remark : "",
          };

          if (addedItem.id) params["id"] = addedItem.id;

          inbound_order_item_lot.push(params);
        });

        const inbound_item_params = {
          id: itemCate.in_bound_id ? itemCate.in_bound_id : null,
          price: itemCate.price,
          code: itemCate.code,
          name: itemCate.name,
          unit: itemCate.unit,
          contract_price: contractItem && contractItem.price ? contractItem.price : 0,
          expense_ratio: expense_ratio[expIndex] ? expense_ratio[expIndex].expense_ratio : itemCate.expense_ratio,
          installation_date: itemCate.installation_date ? itemCate.installation_date : null,
          is_mbom: itemCate.is_mbom ? itemCate.is_mbom : false,
          expense_ratio_arr: expense_ratio[expIndex] ? expense_ratio[expIndex].expense_ratio_arr : itemCate.expense_ratio_arr,
          warranty_code_id: null,
          contract_item_id: contractItem && contractItem.id,
          item_category_id: itemCate.item_category_id,
          inbound_order_item_lot: inbound_order_item_lot,
          qty: itemCate.qty,
        };

        categoryQTY = categoryQTY + itemCate.qty;

        itemCate.contract_item_fees.forEach((itemFee) => {
          const inbound_item_expense_params = {
            id: itemFee.update_id ? itemFee.update_id : null,
            fee_type: itemFee.fee_type,
            method_type: itemFee.method_type,
            qty: itemFee.qty,
            item_qty: itemFee.method_type === 5 ? Math.ceil(itemCate.qty / itemFee.package_qty) : 1, // default 1 if type 5 is use multiply for item_qty
            package_qty: itemFee.package_qty,
            contract_price: itemFee.price,
            unit: itemFee.unit,
            from_value: itemFee.from_value,
            to_value: itemFee.to_value,
            resource_type: "ItemFee",
            resource_type_id: itemFee.item_fee_id,
            resource_id: itemCate.id,
            position: itemFee.position ? itemFee.position : position + 1,
            is_custom_qty: false,
          };

          if (!itemFee.position) position = position + 1;
          else position = itemFee.position;

          inbound_order_item_expense.push(inbound_item_expense_params);
        });

        inbound_order_items.push(inbound_item_params);
      });

      listItem.item_category_fees.forEach((cateFee) => {
        let contract_fee = listItem.item_expense.find((expense) => expense.item_category_fee_id === cateFee.id);

        const inbound_item_expense_params = {
          id: cateFee.item_category_fee_id,
          name: cateFee.name,
          fee_type: cateFee.fee_type,
          method_type: cateFee.method_type,
          qty: cateFee.qty,
          item_qty: cateFee.method_type === 5 ? Math.ceil(categoryQTY / cateFee.package_qty) : 1, // default 1 if type 5 is use multiply for item_qty
          package_qty: cateFee.package_qty,
          contract_price: cateFee.price,
          unit: cateFee.unit,
          from_value: cateFee.from_value,
          to_value: cateFee.to_value,
          resource_type: "ItemCategoryFee",
          resource_type_id: cateFee.item_category_fee_id,
          resource_id: null,
          position: cateFee.position,
          is_custom_qty: false,
        };

        inbound_order_item_expense.push(inbound_item_expense_params);
      });

      const inbound_category_discount_params = {
        id: listItem.discount.id,
        name: listItem.discount.name,
        fee_type: 3,
        method_type: 4,
        qty: listItem.discount.qty,
        item_qty: 1,
        package_qty: null,
        contract_price: listItem.discount.price,
        unit: "",
        from_value: 0,
        to_value: null,
        resource_type: "ItemCategoryDiscount",
        resource_type_id: null,
        resource_id: null,
        position: 999,
        is_custom_qty: false,
      };

      inbound_order_item_expense.push(inbound_category_discount_params);

      listItem.add_expense.forEach((addExpense) => {
        const inbound_add_expense_params = {
          id: addExpense.id,
          name: addExpense.name,
          fee_type: 3,
          method_type: 4,
          qty: addExpense.qty > 0 ? addExpense.qty : null,
          item_qty: 1,
          package_qty: null,
          contract_price: addExpense.price,
          unit: addExpense.unit,
          from_value: 0,
          to_value: null,
          resource_type: "ItemCategoryFee",
          resource_type_id: null,
          resource_id: null,
          position: addExpense.position ? addExpense.position : position + 1,
          is_custom_qty: false,
        };

        if (!addExpense.position) position = position + 1;
        else position = addExpense.position;

        inbound_order_item_expense.push(inbound_add_expense_params);
      });

      let inbound_order_category_params = {
        sys_name: listItem?.sys_name,
        ori_name: listItem?.ori_name,
        informed_date: listItem?.informed_date ? listItem?.informed_date : null,
        contract_id: listItem?.contract_id,
        item_category_id: listItem?.id,
        contract_item_category_id: listItem?.contract_items && listItem?.contract_items[0]?.contract_item_category_id,
        inbound_order_item_expense: inbound_order_item_expense,
      };

      inbound_order_item_category.push(inbound_order_category_params);
    });

    addListPo.forEach((po) => {
      if (po.contract_id !== null) {
        const po_params = {
          id: po.po_id ? po.po_id : null,
          contract_id: po.contract_id,
          po_code: po.po_code,
          po_date: po.po_date,
          submit_date: po.submit_date,
        };
        inbound_order_item_pos.push(po_params);
      }
    });

    const params = {
      note: noted,
      db_delivery_code: null,
      db_delivery_date: null,
      delivery_date: null,
      cancel_note: null,
      approved_date: approved_date,
      user_id: this.props.current_user.id,
      dc_id: inbound_info.dc[0].id,
      inbound_order_items: inbound_order_items,
      inbound_order_item_category: inbound_order_item_category,
      inbound_order_item_pos: inbound_order_item_pos,
    };

    if (error_contract !== "") {
      this.setState({ is_loading: false });
      alert(error_contract);
    } else {
      axios
        .put(`${process.env.REACT_APP_API_URL}/inbound/${this.props.match.params.id}`, params)
        .then((response) => {
          if (response.status === 200) {
            this.props.history.push({
              pathname: `/dc_inbound_orders/${response.data.id}`,
            });
          }
        })
        .catch((err) => {
          this.setState({ is_loading: false });
        });

      // setTimeout(() => {
      this.setState({ is_loading: true });
      // }, 1500)
    }
  }

  handleSubmitSave() {
    const { addList, noted, approved_date, inbound_info } = this.state;
    this.setState({ is_loading: true });
    let inbound_order_items = [];
    let inbound_order_item_category = [];

    let error_contract = "";

    addList.forEach((listItem) => {
      let inbound_order_item_expense = [];
      let categoryQTY = 0;
      let position = 0;

      listItem.item_category.forEach((itemCate) => {
        const contractItem = listItem?.contract_items?.find((contract) => contract.item_id === itemCate.id);
        const expIndex = expense_ratio.findIndex((ratio) => ratio.categoty_item_id === itemCate.id);

        if (!contractItem) {
          error_contract = error_contract + `- ไม่พบสินค้า ${itemCate.name} นี้ในสัญญาที่เลือก\n`;
        }

        const inbound_item_params = {
          price: itemCate.price,
          code: itemCate.code,
          name: itemCate.name,
          unit: itemCate.unit,
          contract_price: contractItem?.price ? contractItem?.price : 0,
          expense_ratio: expense_ratio[expIndex].expense_ratio,
          installation_date: itemCate.installation_date ? itemCate.installation_date : null,
          is_mbom: itemCate.is_mbom ? itemCate.is_mbom : false,
          expense_ratio_arr: expense_ratio[expIndex].expense_ratio_arr,
          warranty_code_id: null,
          contract_item_id: contractItem?.id,
          item_category_id: itemCate.item_category_id,
          qty: itemCate.qty,
        };

        categoryQTY = categoryQTY + itemCate.qty;

        itemCate.contract_item_fees.forEach((itemFee) => {
          const inbound_item_expense_params = {
            name: itemFee.name,
            fee_type: itemFee.fee_type,
            method_type: itemFee.method_type,
            qty: itemFee.qty,
            item_qty: itemFee.method_type === 5 ? Math.ceil(itemCate.qty / itemFee.package_qty) : 1, // default 1 if type 5 is use multiply for item_qty
            package_qty: itemFee.package_qty,
            contract_price: itemFee.price,
            unit: itemFee.unit,
            from_value: itemFee.from_value,
            to_value: itemFee.to_value,
            resource_type: "ItemFee",
            resource_type_id: itemFee.item_fee_id,
            resource_id: itemCate.id,
            position: position,
            is_custom_qty: false,
          };

          position = position + 1;
          inbound_order_item_expense.push(inbound_item_expense_params);
        });

        inbound_order_items.push(inbound_item_params);
      });

      listItem.item_category_fees.forEach((cateFee) => {
        const inbound_item_expense_params = {
          name: cateFee.name,
          fee_type: cateFee.fee_type,
          method_type: cateFee.method_type,
          qty: cateFee.qty,
          item_qty: cateFee.method_type === 5 ? Math.ceil(categoryQTY / cateFee.package_qty) : 1, // default 1 if type 5 is use multiply for item_qty
          package_qty: cateFee.package_qty,
          contract_price: cateFee.price,
          unit: cateFee.unit,
          from_value: cateFee.from_value,
          to_value: cateFee.to_value,
          resource_type: "ItemCategoryFee",
          resource_type_id: cateFee.id,
          resource_id: null,
          position: cateFee.position,
          is_custom_qty: false,
        };

        inbound_order_item_expense.push(inbound_item_expense_params);
      });

      const inbound_category_discount_params = {
        name: listItem.discount.name,
        fee_type: 3,
        method_type: 4,
        qty: listItem.discount.qty,
        item_qty: 1,
        package_qty: null,
        contract_price: listItem.discount.price,
        unit: "",
        from_value: 0,
        to_value: null,
        resource_type: "ItemCategoryDiscount",
        resource_type_id: null,
        resource_id: null,
        position: 999,
        is_custom_qty: false,
      };

      inbound_order_item_expense.push(inbound_category_discount_params);

      listItem.add_expense.forEach((addExpense) => {
        const inbound_add_expense_params = {
          name: addExpense.name,
          fee_type: 3,
          method_type: 4,
          qty: addExpense.qty > 0 ? addExpense.qty : null,
          item_qty: 1,
          package_qty: null,
          contract_price: addExpense.price,
          unit: addExpense.unit,
          from_value: 0,
          to_value: null,
          resource_type: "ItemCategoryFee",
          resource_type_id: null,
          resource_id: null,
          position: position,
          is_custom_qty: false,
        };

        position = position + 1;
        inbound_order_item_expense.push(inbound_add_expense_params);
      });

      let inbound_order_category_params = {
        sys_name: listItem.sys_name,
        ori_name: listItem.ori_name,
        informed_date: listItem.informed_date ? listItem.informed_date : null,
        contract_id: listItem.contract_id,
        item_category_id: listItem.id,
        contract_item_category_id: listItem?.contract_items && listItem?.contract_items[0]?.contract_item_category_id,
        inbound_order_item_expense: inbound_order_item_expense,
      };

      inbound_order_item_category.push(inbound_order_category_params);
    });

    const params = {
      note: noted,
      db_delivery_code: null,
      db_delivery_date: null,
      delivery_date: null,
      cancel_note: null,
      approved_date: approved_date,
      user_id: this.props.current_user.id,
      dc_id: inbound_info.dc[0].id,
      inbound_order_items: inbound_order_items,
      inbound_order_item_category: inbound_order_item_category,
    };

    if (error_contract !== "") {
      this.setState({ is_loading: false });
      alert(error_contract);
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/inbound`, params)
        .then((response) => {
          if (response.status === 200) {
            this.props.history.push({
              pathname: `/dc_inbound_orders/${response.data.id}`,
            });
          }
        })
        .catch((err) => {
          this.setState({ is_loading: false });
        });

      this.setState({ is_loading: true });
    }
  }

  actionBtns(order_status) {
    const { addList } = this.state;
    const isCreate = this.props.match.params.id === "create";
    if (order_status != "Cancelled") {
      return (
        <React.Fragment>
          <button type="button" className={`btn btn-primary btn-sm ml-2 ${addList.length === 0 && "disabled-btn"}`} onClick={() => (isCreate ? this.handleSubmitSave() : this.handleSubmitUpdate())} disabled={addList.length === 0}>
            Save
          </button>
          {isCreate ? (
            ""
          ) : (
            <button
              type="button"
              className="btn btn-danger btn-sm ml-2"
              onClick={() => {
                this.cancelDC();
              }}
            >
              Cancel
            </button>
          )}
        </React.Fragment>
      );
    }
  }

  getDistanceFromMatrix(data) {
    // src_province_id = 1 (Bangkok)
    axios
      .get(`${process.env.REACT_APP_API_URL}/distance_matrixes?filter=%7B"src_province_id":1,"dst_province_id":${data.dc[0].address.province_id}%7D`)
      .then((response) => {
        const id = this.props.match.params.id;

        data.dc[0].distance = response.data[0].distance;
        this.setState(
          {
            is_loading: id !== "create" ? true : false,
            inbound_info: data,
            categoryGroup: this.groupCategotyWithItem(data),
          },
          () => {
            if (this.props.match.params.id !== "create") {
              this.getInbound(id);
            }
          }
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getContractListForPo(list) {
    let contractList = [];

    list.forEach((item) => {
      item.contract_list.forEach((list) => {
        let isExist = contractList.findIndex((contract) => contract.id === item.contract_id);
        if (isExist === -1) {
          contractList.push(list);
        }
      });
    });

    return contractList;
  }

  async cancelDC() {
    let check = window.confirm("คุณแน่ใจที่จะยกเลิกออเดอร์นี้");
    if (check) {
      await this.setState({ is_loading: true });
      let cancel = axios
        .put(`${process.env.REACT_APP_API_URL}/inbound/${this.props.match.params.id}/cancel`, { user_id: this.props.current_user.id })
        .then((res) => {
          if (res.status === 200) {
            this.props.history.push({
              pathname: `/dc_inbound_orders/${this.props.match.params.id}`,
            });
          }
          this.setState({ is_loading: false });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ is_loading: false });
        });
    }
    //await this.setState({ is_loading: false });
  }

  render() {
    let { dc_order, is_loading, order_code, order_status, inbound_data } = this.state;
    const isCreate = this.props.match.params.id === "create";
    let isEnableLot = false;
    // let contarctList = this.getContractListForPo(addList);
    if (inbound_data && inbound_data.state === "draft" /*|| addListPo.length === 1 && !addListPo[0].manufacture_request_id*/) {
      isEnableLot = true;
    } else {
      isEnableLot = false;
    }

    return (
      <React.Fragment>
        <Spinner loading={!order_status && !isCreate ? true : is_loading} />
        <div className="pb-3 px-3">
          <div className="row mb-2 mb-md-0">
            <div className="col-9 pb-2 pr-0 d-flex flex-row align-items-end">
              <span className="header-title   d-md-block my-0">
                <span style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span class="d-none  d-md-block"> {APP_TITLE_DC_INBOUND_ORDER} </span> <span className="order-code">{order_code}</span>
                  {order_status && <span className={`order-status ${order_status === "Received" && "order-recived"} ${order_status === "Cancelled" && "order-cancel"}`}>{order_status}</span>}
                </span>
              </span>
              {dc_order.code ? <h5 className="my-0 pl-0 pl-md-2 pr-2 color-darkblue">{dc_order.code}</h5> : null}
              <div className="d-flex align-items-end mb-1">
                <span className={`py-1 px-2 badge badge-pill badge-warning quo-state-tag text-truncate`}>{dc_order.state}</span>
              </div>
            </div>
            <div className="col-3 text-right">
              <button type="button" className="btn btn-outline-primary btn-sm" onClick={this.onClickBack}>
                Back
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-8">
              <ul className="nav nav-tabs tab-responsive div-hide-scrollbar">
                {this.displayTabs("ข้อมูลเบื้องต้น (Info)", "info")}
                {this.displayTabs("รายการสั่งซื้อสินค้า", "product_list")}
                {order_status != "Cancelled" ? this.displayTabs("แจ้งผู้ผลิต", "inform") : ""}
                {order_status != "Cancelled" ? this.displayTabs("PO", "po", isCreate) : ""}
                {order_status != "Cancelled" ? this.displayTabs("ตรวจรับสินค้า", "check_product", isCreate, isEnableLot) : ""}
              </ul>
            </div>
            <div className="col-12 col-md-4 mt-2"> {<div className="pl-3 text-right">{this.actionBtns(order_status)}</div>}</div>
          </div>
        </div>
        <form onSubmit={this.handleSubmit}>{this.state.inbound_info && this.displayTabDetail()}</form>
      </React.Fragment>
    );
  }
}
export default withRouter(DcInboundOrderForm);

import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { LoadingOutlined } from "@ant-design/icons";
import CreatableSelect from "react-select/creatable";
import axios from "axios";

import "./style.css";

// functions
import { uniqueKey } from "../../functions/Random";

let searchTime;

class ClientStation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      client_stations: [],
      isMounted: false,
      isSearching: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onHandleCreatableSelect = this.onHandleCreatableSelect.bind(this);
    this.clientStationSelected = this.clientStationSelected.bind(this);
    this.getCollection = this.getCollection.bind(this);
  }

  componentDidMount() {
    if (this.props.clientStation.id) this.getCollection(this.props.clientStation.id, "id");
    else this.getCollection();
  }

  getVendorFromId(vendorId) {
    axios
      .get(`${process.env.REACT_APP_API_URL}/client_stations/${vendorId}`)
      .then((response) => {
        let options = [{ id: response.data.id, text: response.data.name, value: response.data.id }];
        this.setState({
          vendors: options,
          isMounted: true,
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.clientStation.id != this.props.clientStation.id && this.state.isMounted) {
      let selectOption = this.state.client_stations.filter((option) => option.value == this.props.clientStation.id)[0];
      this.props.onChange(this.props.clientStation.id, selectOption);
    }
  }

  handleInputChange(value) {
    if (value !== "") {
      this.getCollection(value);
    }
  }

  setOptions(data) {
    let options = [];

    data.map((e) => {
      e.text = e.name;
      if (e.address) {
        e.text += ` - ${e.address.province.name} ${e.ship_to_code} ${e.sold_to_code && "(" + e.sold_to_code + ")"}`;
      }
      e.label = e.text;
      e.value = e.id;

      options.push(e);
    });

    this.setState({
      client_stations: options,
      isMounted: true,
    });
  }

  getCollection(query, type = null) {
    let options = {
      sort: JSON.stringify(["id", "DESC"]),
      filter: {},
      range: JSON.stringify([0, 14]),
    };

    if (query && !type) options.filter.name = query;
    if (query && !type) options.filter.sold_to_code = query;
    if (type === "id") options.filter.id = query;

    if (query === "") {
      delete options.filter.name
      delete options.filter.sold_to_code
    }

    this.setOptions([]);
    this.setState({ isSearching: true });

    clearTimeout(searchTime);
    searchTime = setTimeout(
      () => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/client_stations`, {
            params: options,
          })
          .then((response) => {
            this.setOptions(response.data);
            this.setState({ isSearching: false });
          })
          .catch(function () {
            this.setOptions([]);
            this.setState({ isSearching: false });
          })
      },
      query && !type ? 750 : 0
    );
  }

  clientStationSelected(e) {
    let value = e.value;
    if (value !== this.props.clientStation.id && this.state.isMounted) {
      this.props.onChange(value, e);
    }
  }

  onHandleCreatableSelect(e) {
    if (e) {
      if (e.__isNew__ !== undefined && e.id === undefined) {
        let client_stations = this.state.client_stations;
        e.id = uniqueKey();
        e.value = e.id;
        e.name = e.label;
        client_stations.push(e);

        this.setState({ selected_name: e, client_stations: client_stations });
      }

      let value = e.value;
      if (value !== this.props.clientStation.id && this.state.isMounted) {
        this.props.onChange(value, e);
      }
    } else {
      this.props.onChange(null, null);
    }
  }

  render() {
    const { isSearching } = this.state;

    if (this.props.creatable_select) {
      return (
        <CreatableSelect
          isClearable
          placeholder="พิมพ์ เพื่อค้นหา..."
          value={this.state.client_stations.filter((option) => option.value === this.props.clientStation.id)}
          onChange={this.onHandleCreatableSelect}
          onInputChange={this.handleInputChange}
          options={this.state.client_stations}
          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        // menuPortalTarget={document.body}
        // maxMenuHeight={R_SEL_OPTION_MAX_H}
        />
      );
    } else {
      return (
        <div style={{ display: "flex", gap: 10, alignItems: "center", width: "100%" }}>
          {isSearching && <LoadingOutlined />}
          <Select
            className="select2-style custom-select-style"
            placeholder="พิมพ์ เพื่อค้นหา..."
            onChange={this.clientStationSelected}
            onInputChange={this.handleInputChange}
            getOptionLabel={(option) => `${option.text}`}
            value={this.state.client_stations.filter((option) => option.value === this.props.clientStation.id)}
            getOptionValue={(option) => `${option.id}`}
            options={this.state.client_stations}
            isDisabled={this.props.disabled}
          />
        </div>
      );
    }
  }
}

ClientStation.propTypes = {
  clientStation: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ClientStation;

import React from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import {
  OPTIONS_PERIOD, R_SCREEN_DOCK_SIZE_SM, R_SEL_OPTION_MAX_H, MODAL_STYLE_SMALL,
  MODAL_STYLE_SMALL_SM, MODAL_SELECT_STYLE
} from '../../constants';

import { formatApiDate, quarterToStartMonth, OPTIONS_YEAR, OPTIONS_QUARTER, OPTIONS_MONTH, MONTH_NAMES } from '../../functions/Date'

const OPTIONS_DATE = []
for (var key in OPTIONS_PERIOD){
  OPTIONS_DATE.push({value: OPTIONS_PERIOD[key].value, label: OPTIONS_PERIOD[key].name})
}
export class SettingQuery extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filters: props.filters
    }

    this.onSubmitSetting = this.onSubmitSetting.bind(this)
    this.onChangeFilterType = this.onChangeFilterType.bind(this)
    this.onChangeFilterYear = this.onChangeFilterYear.bind(this)
    this.onChangeFilterPeriod = this.onChangeFilterPeriod.bind(this)
  }

  onSubmitSetting() {
    let {filters} = this.state
    if ((filters.type !== OPTIONS_PERIOD.year.value) && (filters.period === null)) {
      alert("โปรดใส่รายละเอียดให้ครบถ้วน")
    } else {
      let dates = this.generate_date_period()
      this.props.onSubmitSetting(filters, dates.from_value, dates.to_value)
    }
  }

  generate_date_period() {
    let {filters} = this.state
    let dates = {}

    switch(filters.type) {
      case OPTIONS_PERIOD.year.value:
        dates.from_value = formatApiDate(new Date(filters.year, 0))
        dates.to_value = formatApiDate(new Date(filters.year, 12, 0))
        break
      case OPTIONS_PERIOD.quarter.value:
        let month_start = quarterToStartMonth(parseInt(filters.period[1]))
        let month_end = month_start+3
        dates.from_value = formatApiDate(new Date(filters.year, month_start))
        dates.to_value = formatApiDate(new Date(filters.year, month_end, 0))
        break
      case OPTIONS_PERIOD.month.value:
        let month = MONTH_NAMES.indexOf(filters.period)
        dates.from_value = formatApiDate(new Date(filters.year, month))
        dates.to_value = formatApiDate(new Date(filters.year, month + 1, 0))
        break
    }

    return dates
  }

  onChangeFilterType(e) {
    let filters = this.state.filters
    filters.type = e.value
    filters.period = null
    this.setState({filters: filters})
  }

  onChangeFilterYear(e) {
    let filters = this.state.filters
    filters.year = e.value
    this.setState({filters: filters})
  }

  onChangeFilterPeriod(e) {
    let filters = this.state.filters
    filters.period = e.value
    this.setState({filters: filters})
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('/');
  }

  options_year(filter) {
    return (
      <div className="d-flex flex-row mt-2">
        <div className="d-flex-1"><label>ปี</label></div>
        <div className="d-flex-2">
          <Select
            value={OPTIONS_YEAR.filter(option => option.value === filter.year)}
            onChange={this.onChangeFilterYear}
            options={OPTIONS_YEAR}
            menuPortalTarget={document.body}
            maxMenuHeight={R_SEL_OPTION_MAX_H}
            styles={MODAL_SELECT_STYLE}
          />
        </div>
      </div>
    )
  }

  options_quarter(filter) {
    if (filter.type === OPTIONS_PERIOD.quarter.value) {
      return (
        <div className="d-flex flex-row mt-2">
          <div className="d-flex-1"><label className="sign-is-required">ไตรมาส</label></div>
          <div className="d-flex-2">
            <Select
              value={OPTIONS_QUARTER.filter(option => option.value === filter.period)}
              onChange={this.onChangeFilterPeriod}
              options={OPTIONS_QUARTER}
              menuPortalTarget={document.body}
              maxMenuHeight={R_SEL_OPTION_MAX_H}
              styles={MODAL_SELECT_STYLE}
            />
          </div>
        </div>
      )
    }
  }

  options_month(filter) {
    if (filter.type === OPTIONS_PERIOD.month.value) {
      return (
        <div className="d-flex flex-row mt-2">
          <div className="d-flex-1"><label className="sign-is-required">เดือน</label></div>
          <div className="d-flex-2">
            <Select
              value={OPTIONS_MONTH.filter(option => option.value === filter.period)}
              onChange={this.onChangeFilterPeriod}
              options={OPTIONS_MONTH}
              menuPortalTarget={document.body}
              maxMenuHeight={R_SEL_OPTION_MAX_H}
              styles={MODAL_SELECT_STYLE}
            />
          </div>
        </div>
      )
    }
  }

  render() {
    let {filters} = this.state
    let {currentSetting} = this.props
    
    if (currentSetting) {
      return (
        <React.Fragment>
          <Modal
              isOpen={currentSetting}
              contentLabel="QuotationSetting"
              style={(window.innerWidth > R_SCREEN_DOCK_SIZE_SM) ? MODAL_STYLE_SMALL : MODAL_STYLE_SMALL_SM}
          >
            <div className="d-flex flex-column">
              <h5>ตั้งค่า</h5>
              {this.options_year(filters)}
              <div className="d-flex flex-row mt-2">
                <div className="d-flex-1"><label>ราย</label></div>
                <div className="d-flex-2">
                  <Select
                    value={OPTIONS_DATE.filter(option => option.value === filters.type)}
                    onChange={this.onChangeFilterType}
                    options={OPTIONS_DATE}
                    menuPortalTarget={document.body}
                    maxMenuHeight={R_SEL_OPTION_MAX_H}
                    styles={MODAL_SELECT_STYLE}
                  />
                </div>
              </div>
              {this.options_quarter(filters)}
              {this.options_month(filters)}
              <div className="d-flex flex-row mt-2">
                <button type="button" className="btn btn-success btn-sm w-100 mr-1"
                        onClick={this.onSubmitSetting}>ตกลง</button>
              </div>
            </div>
          </Modal>
        </React.Fragment>
      );
    } else {
      return false
    }
  }
}
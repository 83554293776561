import * as React from "react";
import axios from "axios";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  DeleteWithConfirmButton,
  TextField,
  EditButton,
  TextInput,
  Toolbar,
  SaveButton,
  ReferenceInput,
  FormDataConsumer,
  useRedirect,
  useRefresh,
  NumberInput,
  required,
  Button,
  Link,
  ReferenceManyField,
  AutocompleteInput,
  useNotify,
  FunctionField,
  useListContext,
  BooleanInput,
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import BookIcon from "@material-ui/icons/Book";
import { getExpenseDetails } from "../../models/ItemCategories";

import { Form, useForm } from "react-final-form";
import { Box, Button as CoreButton, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ContentFilter from "@material-ui/icons/FilterList";

import { exporter } from "../../functions/reactAdmin";

import { EditToolbar, EditActions } from "./Default";

export const ItemIcon = BookIcon;

// const ItemFilter = (props) => (
//   <Filter {...props}>
//       <TextInput label="Search" source="name" alwaysOn />
//   </Filter>
// );

const ItemFilter = (props) => {
  return props.context === "button" ? <ItemFilterButton {...props} /> : <ItemFilterForm {...props} />;
};

const ItemFilterButton = () => {
  const { showFilter } = useListContext();
  return (
    <CoreButton size="small" color="primary" onClick={() => showFilter("main")} startIcon={<ContentFilter />}>
      Filter
    </CoreButton>
  );
};

const ItemFilterForm = ({ open }) => {
  const { displayedFilters, filterValues, setFilters, hideFilter } = useListContext();

  if (!displayedFilters.main) return null;

  const onSubmit = (values) => {
    if (Object.keys(values).length > 0) {
      setFilters(values);
    } else {
      hideFilter("main");
    }
  };

  const resetFilter = () => {
    setFilters({}, []);
  };

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box mt={7} />
            <Box display="flex" alignItems="flex-end" mb={1}>
              <Box component="span" mr={1}>
                <ReferenceInput resettable label="หมวดหมู่" source="item_category_id" reference="item_categories" filterToQuery={(searchText) => ({ sys_name: searchText })}>
                  <AutocompleteInput optionText="sys_name" />
                </ReferenceInput>
              </Box>
              <Box component="span" mr={2}>
                {/* Full-text search filter. We don't use <SearchFilter> to force a large form input */}
                <TextInput
                  resettable
                  helperText={false}
                  source="name"
                  label="Search ชื่อ"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box component="span" mr={2} mb={1.5}>
                <CoreButton variant="outlined" color="primary" type="submit">
                  Filter
                </CoreButton>
              </Box>
              <Box component="span" mb={1.5}>
                <CoreButton variant="outlined" onClick={resetFilter}>
                  Close
                </CoreButton>
              </Box>
            </Box>
          </form>
        )}
      </Form>
    </div>
  );
};

export const ItemList = (props) => (
  <List {...props} filters={<ItemFilter />} filter={{ is_mbom: false }} exporter={exporter}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="is_active" label="เปิดใช้งาน" />
      <TextField source="code" />
      <TextField source="name" />
      <TextField source="item_category.sys_name" label="หมวดหมู่" />
      <EditButton basePath="/items" />
      <DeleteWithConfirmButton undoable={false} />
    </Datagrid>
  </List>
);

export const BomItemList = (props) => {
  const notify = useNotify();
  const handleFailure = (error) => {
    notify("Error: posts not updated", { type: "warning" });
  };
  return (
    <List {...props} filters={<ItemFilter />} filter={{ is_mbom: true }}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="is_active" label="เปิดใช้งาน" />
        <TextField source="code" />
        <TextField source="name" />
        <TextField source="item_category.sys_name" label="หมวดหมู่" />
        <EditButton basePath="/bom_items" />
        <DeleteWithConfirmButton undoable={false} onFailure={handleFailure} />
      </Datagrid>
    </List>
  );
};

const styles = {
  button: {
    marginTop: "1em",
    marginBottom: "1em",
    color: "#fff",
    backgroundColor: "#3f51b5",
  },
};

const AddNewFeeButton = withStyles(styles)(({ classes, record }) => (
  <Button
    className={classes.button}
    variant="raised"
    component={Link}
    to={{
      pathname: "/item_fees/create",
      search: `?item_id=${record.id}`,
    }}
    label="Add a fee"
  ></Button>
));

const AddNewDiscountButton = withStyles(styles)(({ classes, record }) => (
  <Button
    className={classes.button}
    variant="raised"
    component={Link}
    to={{
      pathname: "/item_discounts/create",
      search: `?item_id=${record.id}`,
    }}
    label="Add a discount"
  ></Button>
));

const ItemTitle = ({ record }) => {
  return <span>Item {record ? `"${record.name}"` : ""}</span>;
};

const FeeDisplay = ({ basePath, record, resource }) => {
  var text = getExpenseDetails(record);

  return (
    <React.Fragment>
      {/* <FunctionField
        label="Types"
        render={record => `${ITEM_CATE_FEE_TYPES[record.fee_type]} - `}
      /> */}
      <FunctionField label="Price" render={(record) => text} />
    </React.Fragment>
  );
};

const DiscountDisplay = ({ basePath, record, resource }) => {
  var text = getExpenseDetails(record);
  return (
    <React.Fragment>
      {/* <FunctionField
        label="Types"
        render={record => `ส่วนลด ${ITEM_CATE_FEE_TYPES[record.fee_type]} - `}
      /> */}
      <FunctionField label="Price" render={(record) => text} />
    </React.Fragment>
  );
};

const Regional = (props) => {
  const [distances, setDistances] = React.useState([]);
  const form = useForm();
  return (
    <FormDataConsumer>
      {({
        formData, // The whole form data
        scopedFormData, // The data for this item of the ArrayInput
        getSource, // A function to get the valid source inside an ArrayInput
        ...rest
      }) => {
        let cal_regional_vat = formData.regional_price * 0.07;
        let cal_regional_wht = formData.wht_percen ? formData.regional_price * (formData.wht_percen / 100) : 0;
        formData.regional_vat = cal_regional_vat.toFixed(2);
        formData.regional_wht = cal_regional_wht.toFixed(2);
        formData.regional_total = (formData.regional_price + cal_regional_vat - cal_regional_wht).toFixed(2);
        return (
          <div className="d-md-flex  regional-form">
            <NumberInput className="regional-price" label="ราคาขายส่วนภูมิภาค (ถ้ามี)" id="regional_price" source="regional_price" step={0.01} helperText="ราคาขายส่วนภูมิภาค สำหรับ DC เท่านั้น" disabled={!props.isDC} />
            <br class="d-md-none"></br>
            <NumberInput className="regional-vat" source="regional_vat" label="Vat" step={0.01} disabled={true} />
            <br class="d-md-none"></br>

            <NumberInput className="regional-vat" source="regional_wht" label="WHT" step={0.01} disabled={true} />
            <br class="d-md-none"></br>
            <NumberInput className="regional-vat" source="regional_total" label="Total" step={0.01} disabled={true} />
          </div>
        );
      }}
    </FormDataConsumer>
  );
};

export const ItemEdit = (props) => {
  const redirect = useRedirect();
  const refresh = useRefresh();

  const [listItemCategory, setListItemCategory] = React.useState([]);
  const [isDC, setIsDC] = React.useState(false);
  const [isParent, setIsParent] = React.useState(false);
  const [fromParent, setFromParent] = React.useState(false);

  const [itemTypeSelect, setItemTypeSelect] = React.useState(null);

  const handleOnTypeChance = (e) => {
    setItemTypeSelect(e.target.value);

    if (e.target.value === 1) {
      setIsParent(true);
      setFromParent(false);
    } else if (e.target.value) {
      setIsParent(false);
      setFromParent(true);
    } else {
      setIsParent(false);
      setFromParent(false);
    }
  };

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/item_categories`).then((res) => {
      setListItemCategory(res.data);
    });
  }, []);

  return (
    <Edit undoable={false} actions={<EditActions />} title={<ItemTitle />} {...props}>
      <TabbedForm toolbar={<EditToolbar />}>
        <FormTab label="Item">
          <FormDataConsumer>
            {({
              formData, // The whole form data
              scopedFormData, // The data for this item of the ArrayInput
              getSource, // A function to get the valid source inside an ArrayInput
              ...rest
            }) => {
              if (!formData.wht_percen) formData.wht_percen = 0;

              let cal_vat = formData.price * 0.07;
              let cal_wht = formData.wht_percen ? formData.price * (formData.wht_percen / 100) : 0;
              formData.vat = cal_vat.toFixed(2);
              formData.wht = cal_wht.toFixed(2);
              formData.total = (formData.price + cal_vat - cal_wht).toFixed(2);

              if (!formData.regional_price) formData.regional_price = 0;
              if (formData.parent_items && formData.parent_items.length > 0) formData["parent_item_ids"] = formData.parent_items.map((item) => item?.item_id);

              let category = listItemCategory.find((item) => item.id === formData.item_category_id);
              if (category !== undefined) {
                if (category.dc_id === 0 || category.dc_id === null) {
                  formData.regional_price = 0;
                  formData.regional_vat = 0;
                  formData.regional_wht = 0;
                  formData.regional_total = 0;
                  setIsDC(false);
                } else {
                  setIsDC(true);
                }
              }
              if (formData?.is_parent) {
                setIsParent(true);
                setFromParent(false);
              } else if (!formData?.is_parent && formData?.from_parent) {
                setIsParent(false);
                setFromParent(true);
              } else {
                setIsParent(false);
                setFromParent(false);
              }

              setItemTypeSelect(formData.item_type);
            }}
          </FormDataConsumer>

          <BooleanInput label="เปิดใช้งาน" source="is_active" />
          <SelectInput
            source="item_type"
            label="ประเภทสินค้า"
            disabled
            // validate={required()}
            onChange={handleOnTypeChance}
            choices={[
              {
                id: 0,
                name: "สินค้าทั่วไป",
              },
              {
                id: 1,
                name: "เป็น Parent (มี Sub item)",
              },
              {
                id: 2,
                name: "เป็น Sub item",
              },
            ]}
          />

          {itemTypeSelect !== null && (
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <TextInput style={{ maxWidth: "260px", width: "50%" }} disabled source="id" />
              <TextInput style={{ maxWidth: "260px", width: "50%" }} source="name" validate={required()} />
              <TextInput style={{ maxWidth: "260px", width: "50%" }} source="nickname" inputProps={{ maxLength: 12 }} label="Nickname ไม่เกิน 12 ตัวอักษร" />
              {itemTypeSelect !== 2 && <TextInput style={{ maxWidth: "260px", width: "50%" }} source="code" label="รหัสสินค้า" disabled={fromParent ? true : false} />}
              {itemTypeSelect === 0 && <TextInput style={{ maxWidth: "260px", width: "50%" }} source="asset_code" label="รหัสทรัพย์สิน" />}
              <TextInput style={{ maxWidth: "260px", width: "50%" }} source="unit" />
              <ReferenceInput style={{ width: "100%", width: 260 }} label="Item Category" source="item_category_id" reference="item_categories" filterToQuery={(searchText) => ({ sys_name: searchText })} validate={required()} disabled={true}>
                <AutocompleteInput style={{ maxWidth: "260px", width: "50%" }} optionText="sys_name" />
              </ReferenceInput>
              <NumberInput style={{ maxWidth: "260px", width: "50%" }} source="wht_percen" label="Withholding Tax" step={0.1} />

              <FormDataConsumer>
                {({
                  formData, // The whole form data
                }) => {
                  formData.item_type = itemTypeSelect;
                  setFromParent(formData.from_parent);
                  setIsParent(formData.is_parent);
                  setItemTypeSelect(formData.item_type);

                  return formData && formData.is_parent && formData.item_category_id ? (
                    <div style={{ width: "100%" }}>
                      <ArrayInput source="parent_items" label="รายการ Sub Items">
                        <SimpleFormIterator TransitionProps={{ enter: false }}>
                          <FormDataConsumer>
                            {({ getSource, scopedFormData }) => {
                              return (
                                <Box display="flex">
                                  <Box ml="0.5em" key={getSource("item_category_id")}>
                                    <ReferenceInput
                                      label="สินค้า"
                                      source={getSource("item_id")}
                                      reference="items"
                                      filterToQuery={(searchText) => ({ name: searchText, is_mbom: false, is_parent: false, from_parent: true, item_category_id: formData.item_category_id })}
                                    >
                                      <AutocompleteInput optionText="name" resettable />
                                    </ReferenceInput>
                                  </Box>
                                </Box>
                              );
                            }}
                          </FormDataConsumer>
                        </SimpleFormIterator>
                      </ArrayInput>
                    </div>
                  ) : (
                    <>
                      <div className="d-md-flex regional-form">
                        <NumberInput className="regional-price" label="ราคาขาย หรือ ราคาขายส่วนกลาง" source="price" step={0.01} validate={required()} helperText="ราคาขายสำหรับ Vendor หรือ ราคาขายส่วนกลาง สำหรับ DC" />
                        <br class="d-md-none"></br>
                        <NumberInput className="regional-vat" source="vat" step={0.01} disabled={true} />
                        <br class="d-md-none"></br>
                        <NumberInput className="regional-vat" source="wht" label="WHT" step={0.01} disabled={true} />
                        <br class="d-md-none"></br>
                        <NumberInput className="regional-vat" source="total" step={0.01} disabled={true} />
                      </div>
                      <Regional isDC={isDC}></Regional>
                    </>
                  );
                }}
              </FormDataConsumer>
            </div>
          )}
        </FormTab>
        <FormTab label="Fee">
          <ReferenceManyField addLabel={false} reference="item_fees" target="item_id">
            <Datagrid>
              <TextField source="name" />
              <FeeDisplay />
              <EditButton />
              <DeleteWithConfirmButton
                {...props}
                undoable={false}
                onSuccess={(response) => {
                  redirect(`/items/${response.data.item_id}/1`);
                  refresh();
                }}
              />
            </Datagrid>
          </ReferenceManyField>
          <AddNewFeeButton />
        </FormTab>
        <FormTab label="Discount">
          <ReferenceManyField addLabel={false} reference="item_discounts" target="item_id">
            <Datagrid>
              <TextField source="name" />
              <DiscountDisplay />
              <EditButton />
              <DeleteWithConfirmButton
                {...props}
                undoable={false}
                onSuccess={(response) => {
                  redirect(`/items/${response.data.item_id}/2`);
                  refresh();
                }}
              />
            </Datagrid>
          </ReferenceManyField>
          <AddNewDiscountButton />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const ItemCreate = (props) => {
  const [listItemCategory, setListItemCategory] = React.useState([]);
  const [isDC, setIsDC] = React.useState(false);
  const [isParent, setIsParent] = React.useState(false);
  const [fromParent, setFromParent] = React.useState(false);
  const [category, setCetegory] = React.useState(null);
  const [isChange, setIsChange] = React.useState(false);

  const [itemTypeSelect, setItemTypeSelect] = React.useState(null);

  const handleOnTypeChance = (e) => {
    setItemTypeSelect(e.target.value);

    if (e.target.value === 1) {
      setIsParent(true);
      setFromParent(false);
    } else if (e.target.value) {
      setIsParent(false);
      setFromParent(true);
    } else {
      setIsParent(false);
      setFromParent(false);
    }
  };

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/item_categories`).then((res) => {
      setListItemCategory(res.data);
    });
  }, []);

  return (
    <Create title="Create a Item" actions={<EditActions />} {...props}>
      <SimpleForm>
        <BooleanInput label="เปิดใช้งาน" source="is_active" defaultValue={true} />
        <SelectInput
          source="item_type"
          label="ประเภทสินค้า"
          validate={required()}
          onChange={handleOnTypeChance}
          choices={[
            {
              id: 0,
              name: "สินค้าทั้วไป",
            },
            {
              id: 1,
              name: "เป็น Parent (มี Sub item)",
            },
            {
              id: 2,
              name: "เป็น Sub item",
            },
          ]}
        />
        {itemTypeSelect !== null && (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <TextInput style={{ maxWidth: "260px", width: "50%" }} source="name" validate={required()} />
            <TextInput style={{ maxWidth: "260px", width: "50%" }} source="nickname" inputProps={{ maxLength: 12 }} label="Nickname ไม่เกิน 12 ตัวอักษร" />
            {itemTypeSelect !== 2 && <TextInput style={{ maxWidth: "260px", width: "50%" }} source="code" disabled={fromParent ? true : false} />}
            {itemTypeSelect === 0 && <TextInput style={{ maxWidth: "260px", width: "50%" }} source="asset_code" label="รหัสทรัพย์สิน" />}
            <TextInput style={{ maxWidth: "260px", width: "50%" }} source="unit" />

            <ReferenceInput
              style={{ maxWidth: "260px", width: "50%" }}
              onChange={(e) => {
                setCetegory(null);
                setCetegory(e);
              }}
              label="Item Category"
              source="item_category_id"
              reference="item_categories"
              filterToQuery={(searchText) => ({ sys_name: searchText })}
              validate={required()}
            >
              <AutocompleteInput optionText="sys_name" />
            </ReferenceInput>

            <NumberInput style={{ maxWidth: "260px", width: "50%" }} source="wht_percen" lable="Withholding Tax" />

            {!isParent ? (
              <React.Fragment>
                <div className="d-md-flex regional-form">
                  <NumberInput label="ราคาขาย หรือ ราคาขายส่วนกลาง" className="regional-price" source="price" step={0.01} validate={required()} helperText="ราคาขายสำหรับ Vendor หรือ ราคาขายส่วนกลาง สำหรับ DC" />
                  <br class="d-md-none"></br>
                  <NumberInput className="regional-vat" source="vat" step={0.01} disabled={true} />
                  <br class="d-md-none"></br>
                  <NumberInput className="regional-vat" source="wht" label="WHT" step={0.01} disabled={true} />
                  <br class="d-md-none"></br>
                  <NumberInput className="regional-vat" source="total" step={0.01} disabled={true} />
                </div>
                <div className="d-md-flex regional-form">
                  <NumberInput label="ราคาขายส่วนภูมิภาค (ถ้ามี)" className="regional-price" source="regional_price" step={0.01} helperText="ราคาขายส่วนภูมิภาค สำหรับ DC เท่านั้น" disabled={!isDC} />
                  <br class="d-md-none"></br>
                  <NumberInput source="regional_vat" className="regional-vat" label="Vat" step={0.01} disabled={true} />
                  <br class="d-md-none"></br>

                  <NumberInput source="regional_wht" className="regional-vat" label="WHT" step={0.01} disabled={true} />
                  <br class="d-md-none"></br>
                  <NumberInput source="regional_total" className="regional-vat" label="Total" step={0.01} disabled={true} />
                </div>
              </React.Fragment>
            ) : category ? (
              <ArrayInput source="parent_items" label="รายการ Sub Items">
                <SimpleFormIterator TransitionProps={{ enter: false }}>
                  <FormDataConsumer>
                    {({ getSource, formData }) => {
                      if (isChange) {
                        formData.items = [];
                        setIsChange(false);
                      }

                      formData.regional_price = 0;
                      formData.regional_vat = 0;
                      formData.regional_wht = 0;
                      formData.regional_total = 0;
                      formData.vat = 0;
                      formData.total = 0;
                      formData.price = 0;

                      if (formData.is_parent) formData.item_type = 1;
                      else if (formData.is_from_parent) formData.item_type = 2;
                      else formData.item_type = 0;

                      return (
                        <Box display="flex">
                          {formData && formData.item_category_id ? (
                            <Box ml="0.5em" key={getSource("item_category_id")}>
                              <ReferenceInput
                                label="สินค้า"
                                source={getSource("item_id")}
                                reference="items"
                                filterToQuery={(searchText) => ({ name: searchText, is_mbom: false, is_parent: false, from_parent: true, item_category_id: formData ? formData.item_category_id : "" })}
                              >
                                <AutocompleteInput optionText="name" resettable />
                              </ReferenceInput>
                            </Box>
                          ) : (
                            <></>
                          )}
                        </Box>
                      );
                    }}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            ) : (
              <></>
            )}
          </div>
        )}
        <FormDataConsumer>
          {({
            formData, // The whole form data
            scopedFormData, // The data for this item of the ArrayInput
            getSource, // A function to get the valid source inside an ArrayInput
            ...rest
          }) => {
            let cal_vat = formData.price * 0.07;
            let cal_wht = formData.wht_percen ? formData.price * (formData.wht_percen / 100) : 0;
            formData.vat = formData.is_parent ? 0 : cal_vat.toFixed(2);
            formData.wht = cal_wht.toFixed(2);
            formData.total = formData.is_parent ? 0 : (formData.price + cal_vat - cal_wht).toFixed(2);

            let cal_regional_vat = formData.regional_price * 0.07;
            let cal_regional_wht = formData.wht_percen ? formData.regional_price * (formData.wht_percen / 100) : 0;
            formData.regional_vat = cal_regional_vat.toFixed(2);
            formData.regional_wht = cal_regional_wht.toFixed(2);
            formData.regional_total = (formData.regional_price + cal_regional_vat - cal_regional_wht).toFixed(2);

            if (!formData.regional_price) {
              formData.regional_price = 0;
            }

            if (formData.parent_items && formData.parent_items.length > 0) {
              formData["parent_item_ids"] = formData.parent_items.map((item) => item?.item_id);
            }

            if (formData?.item_type === 1) {
              setIsParent(true);
              setFromParent(false);
              formData["is_parent"] = true;
              formData["from_parent"] = false;
              formData["price"] = 0;
              formData["parent_item_ids"] = [null];
            } else if (formData?.item_type === 2) {
              setIsParent(false);
              setFromParent(true);
              formData["is_parent"] = false;
              formData["from_parent"] = true;
            } else {
              setIsParent(false);
              setFromParent(false);
              formData["is_parent"] = false;
              formData["from_parent"] = false;
            }

            let category = listItemCategory.find((item) => item.id === formData.item_category_id);
            if (category !== undefined) {
              if (category.dc_id === 0 || category.dc_id === null) {
                formData.regional_price = 0;
                formData.regional_vat = 0;
                formData.regional_wht = 0;
                formData.regional_total = 0;
                setIsDC(false);
              } else {
                setIsDC(true);
              }
            }
          }}
        </FormDataConsumer>
        {/* <PriceFields/> */}
      </SimpleForm>
    </Create>
  );
};

export const BomItemCreate = (props) => {
  const [listItemCategory, setListItemCategory] = React.useState([]);
  const [isDC, setIsDC] = React.useState(false);
  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/item_categories`).then((res) => {
      setListItemCategory(res.data);
    });
  }, []);
  return (
    <Create title="Create a Item" actions={<EditActions />} {...props}>
      <SimpleForm>
        <BooleanInput label="เปิดใช้งาน" source="is_active" defaultValue={true} />
        <BooleanInput label="Materials BOM" source="is_mbom" defaultValue={true} formClassName="d-none" disabled />
        <TextInput source="name" validate={required()} />
        <TextInput style={{ maxWidth: "260px", width: "50%" }} source="nickname" inputProps={{ maxLength: 12 }} label="Nickname ไม่เกิน 12 ตัวอักษร" />
        <TextInput source="code" />
        <TextInput source="asset_code" label="รหัสทรัพย์สิน" />
        <TextInput source="unit" />
        {/* <NumberInput source="price" step={0.01} validate={required()} />
      <NumberInput source="vat" step={0.01} disabled={true} />
      <NumberInput source="total" step={0.01} disabled={true} /> */}
        <ReferenceInput label="Item Category" source="item_category_id" reference="item_categories" filterToQuery={(searchText) => ({ sys_name: searchText })} validate={required()}>
          <AutocompleteInput optionText="sys_name" />
        </ReferenceInput>
        <div className="d-md-flex regional-form">
          <NumberInput label="ราคาขาย หรือ ราคาขายส่วนกลาง" className="regional-price" source="price" step={0.01} validate={required()} helperText="ราคาขายสำหรับ Vendor หรือ ราคาขายส่วนกลาง สำหรับ DC" />
          <br class="d-md-none"></br>
          <NumberInput className="regional-vat" source="vat" step={0.01} disabled={true} />
          <br class="d-md-none"></br>
          <NumberInput className="regional-vat" source="total" step={0.01} disabled={true} />
        </div>
        <div className="d-md-flex regional-form">
          <NumberInput label="ราคาขายส่วนภูมิภาค (ถ้ามี)" className="regional-price" source="regional_price" step={0.01} helperText="ราคาขายส่วนภูมิภาค สำหรับ DC เท่านั้น" disabled={!isDC} />
          <br class="d-md-none"></br>
          <NumberInput source="regional_vat" className="regional-vat" label="Vat" step={0.01} disabled={true} />
          <br class="d-md-none"></br>
          <NumberInput source="regional_total" className="regional-vat" label="Total" step={0.01} disabled={true} />
        </div>

        <FormDataConsumer>
          {({
            formData, // The whole form data
            scopedFormData, // The data for this item of the ArrayInput
            getSource, // A function to get the valid source inside an ArrayInput
            ...rest
          }) => {
            let cal_vat = formData.price * 0.07;
            formData.vat = cal_vat.toFixed(2);
            formData.total = (formData.price + cal_vat).toFixed(2);

            let cal_regional_vat = formData.regional_price * 0.07;
            formData.regional_vat = cal_regional_vat.toFixed(2);
            formData.regional_total = (formData.regional_price + cal_regional_vat).toFixed(2);

            if (!formData.regional_price) {
              formData.regional_price = 0;
            }

            let category = listItemCategory.find((item) => item.id === formData.item_category_id);
            if (category !== undefined) {
              if (category.dc_id === 0 || category.dc_id === null) {
                formData.regional_price = 0;
                formData.regional_vat = 0;
                formData.regional_total = 0;
                setIsDC(false);
              } else {
                setIsDC(true);
              }
            }
          }}
        </FormDataConsumer>
        {/* <PriceFields/> */}

        <ArrayInput source="bom_items" label="Materials BOM">
          <SimpleFormIterator TransitionProps={{ enter: false }}>
            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                return (
                  <Box display="flex">
                    <Box mr="0.5em">
                      <ReferenceInput validate={required()} label="หมวดหมู่" source={getSource("item_category_id")} reference="item_categories" filterToQuery={(searchText) => ({ sys_name: searchText })}>
                        <AutocompleteInput optionText="sys_name" resettable />
                      </ReferenceInput>
                    </Box>
                    {scopedFormData && scopedFormData.item_category_id ? (
                      <Box ml="0.5em" key={getSource("item_category_id")}>
                        <ReferenceInput label="สินค้า" source={getSource("item_id")} reference="items" filterToQuery={(searchText) => ({ name: searchText, is_mbom: false, item_category_id: scopedFormData ? scopedFormData.item_category_id : "" })}>
                          <AutocompleteInput optionText="name" resettable />
                        </ReferenceInput>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export const BomItemEdit = (props) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [listItemCategory, setListItemCategory] = React.useState([]);
  const [isDC, setIsDC] = React.useState(false);
  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/item_categories`).then((res) => {
      setListItemCategory(res.data);
    });
  }, []);
  return (
    <Edit undoable={false} actions={<EditActions />} title={<ItemTitle />} {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <BooleanInput label="เปิดใช้งาน" source="is_active" />
        <BooleanInput label="Materials BOM" source="is_mbom" formClassName="d-none" disabled />
        <TextInput source="name" validate={required()} />
        <TextInput style={{ maxWidth: "260px", width: "50%" }} source="nickname" inputProps={{ maxLength: 12 }} label="Nickname ไม่เกิน 12 ตัวอักษร" />
        <TextInput source="code" />
        <TextInput source="asset_code" label="รหัสทรัพย์สิน" />
        <TextInput source="unit" />
        {/* <NumberInput source="price" step={0.01} validate={required()} />
        <NumberInput source="vat" step={0.01} disabled={true} />
        <NumberInput source="total" step={0.01} disabled={true} /> */}
        <ReferenceInput label="Item Category" source="item_category_id" reference="item_categories" filterToQuery={(searchText) => ({ sys_name: searchText })} validate={required()}>
          <AutocompleteInput optionText="sys_name" />
        </ReferenceInput>
        <div className="d-md-flex  regional-form">
          <NumberInput className="regional-price" label="ราคาขาย หรือ ราคาขายส่วนกลาง" source="price" step={0.01} validate={required()} helperText="ราคาขายสำหรับ Vendor หรือ ราคาขายส่วนกลาง สำหรับ DC" />
          <br class="d-md-none"></br>
          <NumberInput className="regional-vat" source="vat" step={0.01} disabled={true} />
          <br class="d-md-none"></br>
          <NumberInput className="regional-vat" source="total" step={0.01} disabled={true} />
        </div>

        <Regional isDC={isDC}></Regional>
        <FormDataConsumer>
          {({
            formData, // The whole form data
            scopedFormData, // The data for this item of the ArrayInput
            getSource, // A function to get the valid source inside an ArrayInput
            ...rest
          }) => {
            let cal_vat = formData.price * 0.07;
            formData.vat = cal_vat.toFixed(2);
            formData.total = (formData.price + cal_vat).toFixed(2);

            let cal_regional_vat = formData.regional_price * 0.07;
            formData.regional_vat = cal_regional_vat.toFixed(2);
            formData.regional_total = (formData.regional_price + cal_regional_vat).toFixed(2);

            if (!formData.regional_price) {
              formData.regional_price = 0;
            }

            let category = listItemCategory.find((item) => item.id === formData.item_category_id);
            if (category !== undefined) {
              if (category.dc_id === 0 || category.dc_id === null) {
                formData.regional_price = 0;
                formData.regional_vat = 0;
                formData.regional_total = 0;
                setIsDC(false);
              } else {
                setIsDC(true);
              }
            }
          }}
        </FormDataConsumer>
        {/* <PriceFields/> */}

        {/* <ReferenceArrayInput label="Materials BOM"
                              source="bom_item_ids"
                              reference="items"
                              filterToQuery={searchText => ({ name: searchText })}
                              sort={{field: 'item_category_id', order: 'ASC'}} fullWidth>
          <SelectArrayInput source="name" validate={required()}/>
        </ReferenceArrayInput> */}
        <ArrayInput source="bom_items" label="Materials BOM">
          <SimpleFormIterator TransitionProps={{ enter: false }}>
            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                return (
                  <Box display="flex">
                    <Box mr="0.5em">
                      <ReferenceInput validate={required()} label="หมวดหมู่" source={getSource("item_category_id")} reference="item_categories" filterToQuery={(searchText) => ({ sys_name: searchText })}>
                        <AutocompleteInput optionText="sys_name" resettable />
                      </ReferenceInput>
                    </Box>
                    {scopedFormData && scopedFormData.item_category_id ? (
                      <Box ml="0.5em" key={getSource("item_category_id")}>
                        <ReferenceInput label="สินค้า" source={getSource("item_id")} reference="items" filterToQuery={(searchText) => ({ name: searchText, is_mbom: false, item_category_id: scopedFormData ? scopedFormData.item_category_id : "" })}>
                          <AutocompleteInput optionText="name" resettable />
                        </ReferenceInput>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

import React from 'react'
import Modal from 'react-modal'
import _ from 'lodash'
import { MODAL_STYLE_SMALL_SM, R_SCREEN_DOCK_SIZE } from '../../constants'
import { itemCategoryName } from '../../models/ItemCategories'
import ModalConfirmation from '../global/ModalConfirmation'
import { PullRequestOutlined } from '@ant-design/icons'

export class OrderFormPurchaseCostItems extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected_item: '',
      selected_item_category: '',
      edited_item: null,
      custom_item: '',
      custom_quantity: '',
      quantity: '',
      comment: '',
      modal_preview: false,
      isConfirmingAdjustment: false,
      vendor: {},
    }

    this.handleOpenEditedModal = this.handleOpenEditedModal.bind(this)
    this.handleCloseEditedModal = this.handleCloseEditedModal.bind(this)
    this.onChangeComment = this.onChangeComment.bind(this)

    // Confirm shipping adjustment
    this.onResetContractPrice = this.onResetContractPrice.bind(this)
    this.onHandleFeeAdjustModal = this.onHandleFeeAdjustModal.bind(this)
  }

  handleOpenEditedModal(id) {
    this.setState({ edited_item: id })
  }

  handleCloseEditedModal() {
    this.setState({ edited_item: null })
  }

  onChangeComment(e) {
    this.setState({ comment: e.target.value })
  }

  /* Confirm shipping adjustment (start) */
  onHandleFeeAdjustModal(cate_id) {
    this.setState({ isConfirmingAdjustment: cate_id })
  }

  onResetContractPrice() {
    this.props.onResetContractPrice([this.state.isConfirmingAdjustment])
    this.onHandleFeeAdjustModal(false)
  }

  displayFeeAdjustmentModal() {
    let { isConfirmingAdjustment } = this.state
    let { order_item_categories } = this.props.order
    if (isConfirmingAdjustment !== false) {
      return (
        <ModalConfirmation
          isOpen={true}
          title="ปรับค่าราคาขาซื้อเป็นค่าเริ่มต้น"
          content={
            <p>
              ต้องการปรับค่าราคาขาซื้อของ{' '}
              <u>
                <strong>{itemCategoryName(order_item_categories[isConfirmingAdjustment])}</strong>
              </u>{' '}
              เป็นค่าเริ่มต้น ?
            </p>
          }
          onConfirm={this.onResetContractPrice}
          onCancel={() => this.onHandleFeeAdjustModal(false)}
        />
      )
    }
  }
  /* Confirm shipping adjustment (end) */

  inputField(elm, field, isNumber) {
    // if (isNumber === true) {
    return (
      <input
        className="form-control text-right"
        name={field}
        value={elm[field]}
        onChange={(e) => this.props.onChangeItemDetails(e, elm.id)}
        disabled={this.props.disabled}
      ></input>
    )
  }

  inputCostField(elm, field, mainData) {
    // if (isNumber === true) {

    return (
      <input
        className="form-control text-right"
        type={'number'}
        name={field}
        value={elm[field] || 0}
        onChange={(e) => this.props.onChangeAddedCost(e, elm, mainData)}
        disabled={this.props.disabled}
      ></input>
    )
  }

  editModal(elm) {
    let { edited_item } = this.state
    let sumAddedCost = parseFloat(this.getSumOfAddedUnitCost(elm))
    let net_cost = this.sumCostPerCate(elm)
    return (
      <Modal isOpen={elm.id === edited_item} contentLabel="onEditItem" onRequestClose={this.handleCloseEditedModal} style={MODAL_STYLE_SMALL_SM}>
        <div className="d-flex flex-column">
          <h5>แก้ไขรายการสินค้า</h5>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>รายการ</label>
            </div>
            <div className="d-flex-2">{elm.name}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>จำนวน</label>
            </div>
            <div className="d-flex-2">
              {elm.qty} ({elm.unit})
            </div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>รวม</label>
            </div>
            <div className="d-flex-2">{(parseFloat(net_cost) + sumAddedCost).toLocaleString()}</div>
          </div>
          <div class="items-modal-container" style={{ fontWeight: 'bold', fontSize: '80%' }}>
            <div>ชิ้นที่ (ID)</div>
            <div>ราคาต้นทุน</div>
            <div>ราคาเพิ่มเติม</div>
            <div>รวมเป็นเงิน</div>
          </div>

          {elm.inventories.map((value, i) => {
            return (
              <div class="items-modal-container" style={{ fontSize: '80%' }}>
                {/* md up */}

                <div>
                  {i + 1} ({value.id})
                </div>
                <div>{new Intl.NumberFormat().format(parseFloat(value.calc_unit_price))}</div>
                <div style={{ display: 'flex', gap: 5, alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap' }}>
                  {this.inputCostField(value, 'added_unit_cost', elm)}
                  <div
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      color: 'lightgray',
                      fontSize: 20,
                      alignItems: 'center',
                      display: 'flex',
                    }}
                    onClick={() => this.props.onApplyAddedCost(value.added_unit_cost, value, elm)}
                  >
                    <PullRequestOutlined />
                  </div>
                </div>
                <div>{new Intl.NumberFormat().format(parseFloat(value.calc_unit_price + (value.added_unit_cost || 0)))}</div>
              </div>
            )
          })}

          <div className="d-flex flex-row mt-2">
            <button type="button" className="btn btn-danger btn-sm w-100 ml-1" onClick={this.handleCloseEditedModal}>
              ปิด
            </button>
          </div>
        </div>
      </Modal>
    )
  }

  getSumOfAddedUnitCost(data) {
    let sum_added_price = 0
    // reduce added unit cost for sum
    sum_added_price = data.inventories.reduce((prev, inventory) => prev + (inventory.calc_unit_price || 0), sum_added_price)
    return sum_added_price
  }

  sumCostPerCate(data) {
    let total_cost = 0
    total_cost = data.inventories.reduce((prev, value) => prev + (value.added_unit_cost || 0), total_cost)
    return total_cost
  }

  displayItems(itemsByCate, index_offset) {
    let items = itemsByCate.map((elm, index) => {
      let net_cost = this.sumCostPerCate(elm)
      let code_name = `${elm.code ? elm.code + '-' : ''}${elm.name}`
      let sumAddedCost = parseFloat(this.getSumOfAddedUnitCost(elm))
      return (
        <>
          <React.Fragment key={elm.id}>
            {/* md up */}
            <div className="row mt-2 border d-none d-md-flex">
              <div className="table-item-bg first col-p5 justify-content-center">{index_offset + index}</div>
              <div className="table-item-bg col-12 pt-1 col-md-6 pr-0">{code_name}</div>
              <div className="table-item-bg col-3 col-md-1 text-center">
                <div className="w-100 ml-2 text-right">
                  {elm.qty} {elm.unit}
                </div>
              </div>
              <div className="table-item-bg col-3 col-md justify-content-end sign-baht">{new Intl.NumberFormat().format(sumAddedCost)}</div>
              <div className="table-item-bg col-4 col-md-3 justify-content-end">
                <div className="w-100 ml-2 text-right">{(parseFloat(net_cost) + sumAddedCost).toLocaleString()}</div>
              </div>
            </div>
            {/* md down */}
            <a onClick={() => (this.props.disabled ? false : this.handleOpenEditedModal(elm.id))}>
              <div className="row mt-2 border d-md-none">
                <div className="table-item-bg col-12 pt-1 col-md-4 pr-0">{code_name}</div>
                <div className="table-item-bg col col-md-1 text-center d-md-none">
                  จำนวน {elm.qty} {elm.unit}
                </div>
                <div className="table-item-bg col-12 col-md d-flex justify-content-end bg-ghostwhite d-md-none">
                  <h4 className="sign-baht color-darkgrayblue">{(parseFloat(net_cost) + sumAddedCost).toLocaleString()}</h4>
                </div>
              </div>
            </a>
            {this.editModal(elm)}
          </React.Fragment>
          {elm.inventories.map((value, i) => {
            return (
              <React.Fragment key={'item ' + i}>
                {/* md up */}
                <div className="row mt-2 border d-none d-md-flex">
                  <div className="table-item-bg first col-p5 justify-content-center"></div>
                  <div className="table-item-bg col-8 pt-1 col-md-5 pr-0" style={{ gap: 40 }}>
                    <div>{'ชิ้นที่ ' + (i + 1)}</div>
                    <div>{'ID ' + value.id}</div>
                  </div>
                  <div className="table-item-bg col-6 col-md-2 text-right" style={{ gap: 10, whiteSpace: 'nowrap', justifyContent: 'right' }}>
                    <div className="ml-2 text-right">ราคาต้นทุนขาเข้า</div>
                    <div className="ml-2 text-right sign-baht" style={{ fontWeight: 'bold' }}>
                      {new Intl.NumberFormat().format(parseFloat(value.calc_unit_price))}
                    </div>
                  </div>
                  <div className="table-item-bg col-3 col-md justify-content-end sign-baht">{this.inputCostField(value, 'added_unit_cost', elm)}</div>
                  <div
                    className="table-item-bg col-4 col-md-3"
                    style={{ justifyContent: 'space-between', gap: 10, whiteSpace: 'nowrap', paddingRight: '15px !important' }}
                  >
                    <div
                      style={{ cursor: 'pointer', textDecoration: 'underline', color: 'lightgray' }}
                      onClick={() => this.props.onApplyAddedCost(value.added_unit_cost, value, elm)}
                    >
                      Apply All
                    </div>
                    <div>{new Intl.NumberFormat().format(parseFloat(value.calc_unit_price + (value.added_unit_cost || 0)))}</div>
                  </div>
                </div>
                {/* md down */}
                {this.editModal(elm)}
              </React.Fragment>
            )
          })}
        </>
      )
    })
    return items
  }

  displayItemByCategory() {
    let { order } = this.props
    let { order_item_categories, purchasing_order_items } = order
    // let order_items = purchasing_order_items.filter((elm) => !elm.is_mbom);
    let order_items = purchasing_order_items
    let index_offset = 1
    let itemsByCategory = _.groupBy(order_items, function (value) {
      return value.item_category_id
    })

    let result = Object.keys(itemsByCategory).map((key, index) => {
      let current_cate = order_item_categories[key]
      // let price_sum = 0;
      // let disabled_contract_sel = itemsByCategory[key].reduce((pre, cur) => {
      //   return pre || !stringIsEmpty(getWarrantyCode(cur.warranty_codes));
      // }, false);
      let rowItems = this.displayItems(itemsByCategory[key], index_offset)
      // itemsByCategory[key].map((temp) => {
      //   let contract_price = parseFloat(temp.contract_price);
      //   let qty = parseFloat(temp.qty);
      //   // price_sum += Multiple(qty, contract_price);
      // });
      index_offset += itemsByCategory[key].length
      // let sum_expense = getCategoryTotalExpense(
      //   current_cate.item_category_expenses,
      //   true
      // );

      return (
        <React.Fragment key={key}>
          <div className="row purchase-price">
            {/* <div className="table-item-bg category-title first col-1 col-p5 p-0">
              <i className={`icon-adjust cursor-pointer ${this.props.disabled ? 'd-none' : ''}`} onClick={() => this.onHandleFeeAdjustModal(key)} />
            </div> */}
            <div className="p-1 table-item-bg category-title font-weight-bold col p-0 col-md-3">{order_item_categories[key].sys_name}</div>
            <div className="table-item-bg category-title col p-md-0 justify-content-end d-none d-md-flex"></div>
            <div className="table-item-bg category-title font-weight-bold col justify-content-end d-none d-md-flex"></div>
          </div>
          {rowItems}
          <div className="row mt-3"></div>
        </React.Fragment>
      )
    })

    return result
  }

  displayTable() {
    let tableHeight = ''
    //let tableHeight = (window.innerWidth > R_SCREEN_DOCK_SIZE) ? (this.props.disabled ? 'max-vh-55' :  'max-vh-45') : 'max-vh-45'
    return (
      <React.Fragment>
        <div className="col-12">
          <div className="row table-header d-none d-md-flex">
            <div className="table-item-bg first col-p5 justify-content-center">
              <strong>ลำดับ</strong>
            </div>
            <div className="table-item-bg col-6">
              <strong>รายการ</strong>
            </div>
            <div className="table-item-bg col-1 justify-content-center">
              <strong>จำนวน</strong>
            </div>
            {/* <div className="table-item-bg col-1 justify-content-center"><strong>หน่วย</strong></div> */}
            <div className="table-item-bg col justify-content-center">
              <strong>ต้นทุนเพิ่มเติม</strong>
            </div>
            <div className="table-item-bg col justify-content-center">
              <strong>รวมเป็นเงิน</strong>
            </div>
            {/* <div className="table-item-bg col-2 pl-5"><strong>comment</strong></div> */}
          </div>
          <div className={`row quo-table ${tableHeight}`}>
            <div className="col-12">{this.displayItemByCategory()}</div>
          </div>
          {/* {this.displaySummary(true)} */}
        </div>
      </React.Fragment>
    )
  }

  // displaySummary(md) {
  //   let { order } = this.props;
  //   let total_with_discount = calTotalCost(order, true);
  //   let discount = calTotalDiscount(order, true);
  //   let total = total_with_discount + discount;
  //   let vat = total_with_discount * 0.07;

  //   let text_total = "รวมเป็นเงิน";
  //   let text_discount = "ส่วนลด";
  //   let text_total_with_discount = "หลังหักส่วนลด";
  //   let text_vat = "VAT 7%";
  //   let text_sum = "รวมทั้งสิ้น";
  //   if (md) {
  //     return (
  //       <React.Fragment>
  //         {/* lg down */}
  //         <div className="mt-2 d-lg-none">
  //           <div className="row">
  //             <div className="table-item-bg col p-0 justify-content-end">
  //               {text_total}
  //             </div>
  //             <div className="table-item-bg col justify-content-end sign-baht">
  //               {total.toLocaleString()}
  //             </div>
  //           </div>
  //           <div className="row">
  //             <div className="table-item-bg col p-0 justify-content-end">
  //               {text_discount}
  //             </div>
  //             <div className="table-item-bg col justify-content-end sign-baht">
  //               {discount.toLocaleString()}
  //             </div>
  //           </div>
  //           <div className="row">
  //             <div className="table-item-bg col p-0 justify-content-end">
  //               {text_total_with_discount}
  //             </div>
  //             <div className="table-item-bg col justify-content-end sign-baht">
  //               {total_with_discount.toLocaleString()}
  //             </div>
  //           </div>
  //           <div className="row mb-2">
  //             <div className="table-item-bg col p-0 justify-content-end">
  //               {text_vat}
  //             </div>
  //             <div className="table-item-bg col justify-content-end sign-baht">
  //               {vat.toLocaleString()}
  //             </div>
  //           </div>
  //           <div className="row">
  //             <div className="table-item-bg total col p-0 justify-content-end">
  //               {text_sum}
  //             </div>
  //             <div className="table-item-bg total col justify-content-end sign-baht">
  //               {(vat + total_with_discount).toLocaleString()}
  //             </div>
  //             {/* <div className="table-item-bg total col justify-content-end sign-baht">{(calTotalCostWithVat(this.props.quotation)).toLocaleString()}</div> */}
  //           </div>
  //         </div>
  //       </React.Fragment>
  //     );
  //   } else {
  //     return (
  //       <React.Fragment>
  //         {/* lg up */}
  //         <div className="mt-2 d-none d-lg-block">
  //           <div className="d-flex flex-row pr-3">
  //             <div className="d-flex-1 py-1 text-right">{text_total}</div>
  //             <div className="d-flex-1 py-1 text-right">
  //               {total.toLocaleString()}
  //             </div>
  //           </div>
  //           <div className="d-flex flex-row pr-3">
  //             <div className="d-flex-1 py-1 text-right">{text_discount}</div>
  //             <div className="d-flex-1 py-1 text-right">
  //               {discount.toLocaleString()}
  //             </div>
  //           </div>
  //           <div className="d-flex flex-row pr-3">
  //             <div className="d-flex-1 py-1 text-right">
  //               {text_total_with_discount}
  //             </div>
  //             <div className="d-flex-1 py-1 text-right">
  //               {total_with_discount.toLocaleString()}
  //             </div>
  //           </div>
  //           <div className="d-flex flex-row pr-3">
  //             <div className="d-flex-1 py-1 text-right">{text_vat}</div>
  //             <div className="d-flex-1 py-1 text-right">
  //               {vat.toLocaleString()}
  //             </div>
  //           </div>
  //           <div className="d-flex flex-row pr-3 bg-grayyellow">
  //             <div className="d-flex-1 py-2 text-right font-weight-bold">
  //               {text_sum}
  //             </div>
  //             <div className="d-flex-1 py-2 text-right font-weight-bold">
  //               {(total_with_discount + vat).toLocaleString()}
  //             </div>
  //           </div>
  //         </div>
  //       </React.Fragment>
  //     );
  //   }
  // }

  // rightSideBar() {
  //   return (
  //     <React.Fragment>
  //       <div className="quo-right-side-bar">{this.displaySummary()}</div>
  //     </React.Fragment>
  //   );
  // }

  render() {
    return (
      <React.Fragment>
        <div className="col">
          <div className="row mt-2">
            <div className="col p-0">{this.displayTable()}</div>
            {/* <div className="col-3 d-none d-lg-block">{this.rightSideBar()}</div> */}
          </div>
        </div>
        {this.displayFeeAdjustmentModal()}
      </React.Fragment>
    )
  }
}

import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import axios from "axios";

// constants
import { R_SEL_OPTION_MAX_H } from "../../constants";

import { formatApiDate } from "../../functions/Date";

class ContractByCateSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      contracts: [],
      isMounted: false,
      approved_date: null,
    };
    this.contractSelected = this.contractSelected.bind(this);
  }

  componentDidMount() {
    if (this.props.approvedDate && this.props.itemCategoryId) {
      this.getCollection();
    }
  }

  componentDidUpdate() {
    if (this.state.approved_date !== formatApiDate(this.props.approvedDate)) this.getCollection();
  }

  setOptions(response) {
    let options = [];
    response.data.map((e) => {
      options.push({
        id: e.id,
        text: `${e.zone.name} - ${e.vendor.name} ${e.code}`,
        value: e.id,
        code: e.code,
      });
    });
    this.setState({
      contracts: options,
      isMounted: true,
    });
  }

  getCollection() {
    let { approvedDate, itemCategoryId } = this.props;

    this.setState({
      approved_date: formatApiDate(approvedDate),
    });

    let params = {
      sort: JSON.stringify(["created_at", "DESC"]),
      filter: {
        is_active: true,
        for_mr: false,
        approved_date: formatApiDate(approvedDate),
        item_category_id: itemCategoryId,
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/contract_options_by_cate`, {
        params: params,
      })
      .then((response) => {
        this.setOptions(response);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  contractSelected(e) {
    let value = e.value;
    if (value !== this.props.contractId && this.state.isMounted) {
      this.props.onChange(value, e, this.props.itemCategoryId);
    }
  }

  render() {
    return (
      <React.Fragment>
        <Select
          className="select2-style pb-md-0"
          onChange={this.contractSelected}
          getOptionLabel={(option) => `${option.text}`}
          value={this.state.contracts.filter((option) => option.value === this.props.contractId)}
          getOptionValue={(option) => `${option.id}`}
          options={this.state.contracts}
          isDisabled={this.props.disabled}
          placeholder={"ค้นหา..."}
          menuPortalTarget={document.body}
          maxMenuHeight={R_SEL_OPTION_MAX_H}
        />
      </React.Fragment>
    );
  }
}

ContractByCateSelect.propTypes = {
  contractId: PropTypes.number,
  approvedDate: PropTypes.string.isRequired,
  itemCategoryId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ContractByCateSelect;

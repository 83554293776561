import cloneDeep from 'lodash/cloneDeep';

import { PO_STATES, PAYMENT_METHODS } from '../constants.js';
import { getObjectValueByString, objectIsEmpty } from '../functions/Object';
import { NEW_CUSTOMER } from '../models/Customer'
import {
  isCategoryDiscount, initNewFee, initNewDiscount, POSITION_CATEGORY_DISCOUNT, getCategoryTotalExpense,
  getTotalItemPrice
} from '../models/Quotations'

const requiredFields = {
  'po_date': "วันที่ PO",
  'delivery_date': "วันจัดส่ง",
}

export const NEW_ORDER_NOTE = {
  page_nb: null,
  assigned_person: '',
  action_date: null,
  note: '',
}

export const new_po = {
  id: null,
  quotation: {
    client_station: NEW_CUSTOMER
  },
  po_date: new Date(),
  delivery_date: new Date(),
  state: PO_STATES.draft.name,
  customer_id: null,
  item_ids: [],
  updated_at: new Date(),
  note: '',
  payment_method: {
    id: null,
    method_type: '',
    note: '',
    bank_id: null
  }
}

export function init_po(po) {
  if (!po.payment_method) {
    po.payment_method = cloneDeep(new_po.payment_method)
  }

  if (!po.po_date) {
    po.po_date = new Date()
  }

  if (!po.delivery_date) {
    po.delivery_date = new Date()
  }

  if (po.quotation && po.quotation.client_station) {
    po.station_name = po.quotation.client_station.name
    po.ship_to = po.quotation.client_station.ship_to_code
    po.sold_to = po.quotation.client_station.sold_to_code
  }

  return po
}

export function validatePO(po, newState) {
  let errors = {}

  // validate require fields
  Object.keys(requiredFields).map(key => {
    let value = getObjectValueByString(po, key)
    if (value === undefined || value === '') {
      errors[key] = `โปรดระบุ ${requiredFields[key]}`
    }
  })

  return errors
}

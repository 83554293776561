import React from 'react';
import PropTypes from "prop-types";

class Decimal extends React.Component {
  constructor(props) {
    super(props)

    this.onChangeValue = this.onChangeValue.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  onChangeValue(e) {
    this.props.onChange(e)
  }

  handleKeyPress(e) {
    if ((e.key != '.') && isNaN(e.key)) {
      e.preventDefault();
    }
  }

  render() {
    let { placeholder, disabled, name, innerClass, value } = this.props

    return (
      <React.Fragment>
        <input  className={`form-control ${innerClass}`}
                name={name}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                onKeyPress={this.handleKeyPress}
                onChange={this.onChangeValue}
        ></input>  
      </React.Fragment>
    );
  }
}
export default Decimal;

Decimal.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  innerClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
import React from "react";
import { SignIn } from "aws-amplify-react";
import { Hub } from "aws-amplify";
import axios from "axios";
import { APP_NAME } from "../constants";

const listener = (data) => {
  switch (data.payload.event) {
    case "signIn_failure":
      alert(
        "Email หรือ Password ไม่ถูกต้อง ในกรณีที่ลืมหรือไม่แน่ใจ ให้ติดต่อ admin เพื่อทำการตั้ง password ใหม่"
      );
      break;
    default:
      const email = data.payload.data.attributes.email;
      const password = document.getElementById("password").value;

      const params = {
        username: email,
        password: password,
        role: "2",
        name: email,
        user_id: null,
        address_id: null,
        user_type: "admin",
      };

      axios
        .post(`${process.env.REACT_APP_API_URL}/cross-login`, params)
        .then((response) => {
          console.log("response", response);
          if (response.data.code === 201) {
            localStorage.setItem("token", response.data.data.token);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      break;
  }
};
export class MySignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut"];
  }

  componentWillMount() {
    this.authUnlisten = Hub.listen("auth", listener);
  }

  componentWillUnmount() {
    this.authUnlisten();
  }

  showComponent(theme) {
    let { bg_01, bg_02 } = this.props;
    return (
      <React.Fragment>
        {/* Header */}
        <img src={bg_01} className="background-header top-layer" />
        <img src={bg_02} className="background-header bottom-layer" />
        <div className="row mt-3 mb-3 ml-1">
          <div className="col">
            <h4>{APP_NAME}</h4>
          </div>
        </div>
        <form>
          <div className="row">
            <div className="col m-auto outer-card-md p-30px max-w-350px">
              <div className="mb-2 text-center">
                <h2>Login</h2>
              </div>

              <div className="row mt-2">
                <div className="col-12">
                  <label htmlFor="username">Username</label>
                </div>
                <div className="col">
                  <input
                    className="form-control"
                    id="username"
                    key="username"
                    name="username"
                    onChange={this.handleInputChange}
                    type="text"
                    placeholder="e-mail"
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-12">
                  <label htmlFor="password">Password</label>
                </div>
                <div className="col">
                  <input
                    className="form-control"
                    id="password"
                    key="password"
                    name="password"
                    onChange={this.handleInputChange}
                    type="password"
                    placeholder="password"
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    onClick={() => super.signIn()}
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

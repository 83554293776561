import * as React from "react";
import {
  List, Datagrid, Edit, Create, SimpleForm, DeleteWithConfirmButton, TextField, EditButton,
  TextInput, ReferenceField, ReferenceInput, SelectInput, 
  AutocompleteInput, required, useListContext, NumberInput
} from 'react-admin';
import BookIcon from '@material-ui/icons/Book';

import { Form } from 'react-final-form';
import { Box, Button as CoreButton, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ContentFilter from "@material-ui/icons/FilterList";

import { exporter } from '../../functions/reactAdmin'

import { EditToolbar, EditActions } from './Default'


export const DistanceMatrixIcon = BookIcon;

const DistanceMatrixFilter = props => {
  return props.context === "button" ? (
    <DistanceMatrixFilterButton {...props} />
  ) : (
    <DistanceMatrixFilterForm {...props} />
  );
};

const DistanceMatrixFilterButton = () => {
  const { showFilter } = useListContext();
  return (
      <CoreButton
          size="small"
          color="primary"
          onClick={() => showFilter("main")}
          startIcon={<ContentFilter />}
      >
          Filter
      </CoreButton>
  );
};

const DistanceMatrixFilterForm = ({ open }) => {
  const {
      displayedFilters,
      filterValues,
      setFilters,
      hideFilter,
  } = useListContext();

  if (!displayedFilters.main) return null;

  const onSubmit = values => {
      if (Object.keys(values).length > 0) {
          setFilters(values);
      } else {
          hideFilter("main");
      }
  };

  const resetFilter = () => {
      setFilters({}, []);
  };

  return (
      <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
          {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
              <Box mt={8} />
              <Box display="flex" alignItems="flex-end" mb={1}>
              <Box component="span" mr={1}>
                <ReferenceInput 
                  resettable
                  label="จากจังหวัด" 
                  source="src_province_id" 
                  reference="provinces"
                  filterToQuery={searchText => ({ name: searchText })}
                  sort={{ field: 'name', order: 'ASC' }}
                >
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              </Box>
              <Box component="span" mr={1}>
                <ReferenceInput 
                  resettable
                  label="ถึงจังหวัด" 
                  source="dst_province_id" 
                  reference="provinces"
                  filterToQuery={searchText => ({ name: searchText })}
                  sort={{ field: 'name', order: 'ASC' }}
                >
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              </Box>
              <Box component="span" mr={2} mb={1.5}>
                  <CoreButton variant="outlined" color="primary" type="submit">
                  Filter
                  </CoreButton>
              </Box>
              <Box component="span" mb={1.5}>
                  <CoreButton variant="outlined" onClick={resetFilter}>
                  Close
                  </CoreButton>
              </Box>
              </Box>
          </form>
          )}
      </Form>
      </div>
  );
};



export const DistanceMatrixList = (props) => (
  <List {...props} filters={<DistanceMatrixFilter />} exporter={exporter}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField label="Province" source="src_province_id" reference="provinces" sortBy="name" label="จาก">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Province" source="dst_province_id" reference="provinces" label="ถึง">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="distance" />
      <EditButton basePath="/distance_matrixes" />
       <DeleteWithConfirmButton undoable={false}/>
    </Datagrid>
  </List>
);

const DistanceMatrixTitle = ({ record }) => {
  return <span>DistanceMatrix</span>;
};

export const DistanceMatrixEdit = (props) => (
  <Edit undoable={false} actions={<EditActions />} title={<DistanceMatrixTitle />} {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput disabled source="id" />
      <ReferenceInput 
        label="จังหวัดตั้งต้น"
        source="src_province_id"
        reference="provinces"
        filterToQuery={searchText => ({ name: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput 
        label="จังหวัดปลายทาง"
        source="dst_province_id"
        reference="provinces"
        filterToQuery={searchText => ({ name: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="distance" validate={required()}/>
    </SimpleForm>
  </Edit>
);

export const DistanceMatrixCreate = (props) => (
  <Create title="Create a DistanceMatrix" actions={<EditActions />} {...props}>
    <SimpleForm>
      <ReferenceInput
        label="จังหวัดตั้งต้น"
        source="src_province_id"
        reference="provinces"
        filterToQuery={searchText => ({ name: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="จังหวัดปลายทาง"
        source="dst_province_id"
        reference="provinces"
        filterToQuery={searchText => ({ name: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="distance" validate={required()}/>
    </SimpleForm>
  </Create>
);
import * as React from "react";
import { List, Datagrid, Edit, Create, SimpleForm, DeleteWithConfirmButton, TextField, EditButton, TextInput, Toolbar, SaveButton, required, useListContext } from "react-admin";
import BookIcon from "@material-ui/icons/Book";

import { Form } from "react-final-form";
import { Box, Button as CoreButton, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ContentFilter from "@material-ui/icons/FilterList";

import { exporter } from "../../functions/reactAdmin";

import { EditToolbar, EditActions } from "./Default";

export const PaymentMethodIcon = BookIcon;

const PaymentMethodFilter = (props) => {
  return props.context === "button" ? <PaymentMethodFilterButton {...props} /> : <PaymentMethodFilterForm {...props} />;
};

const PaymentMethodFilterButton = () => {
  const { showFilter } = useListContext();
  return (
    <CoreButton size="small" color="primary" onClick={() => showFilter("main")} startIcon={<ContentFilter />}>
      Filter
    </CoreButton>
  );
};

const PaymentMethodFilterForm = ({ open }) => {
  const { displayedFilters, filterValues, setFilters, hideFilter } = useListContext();

  if (!displayedFilters.main) return null;

  const onSubmit = (values) => {
    if (Object.keys(values).length > 0) {
      setFilters(values);
    } else {
      hideFilter("main");
    }
  };

  const resetFilter = () => {
    setFilters({}, []);
  };

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box mt={8} />
            <Box display="flex" alignItems="flex-end" mb={1}>
              <Box component="span" mr={2}>
                {/* Full-text search filter. We don't use <SearchFilter> to force a large form input */}
                <TextInput
                  resettable
                  helperText={false}
                  source="name"
                  label="Search ชื่อ"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box component="span" mr={2} mb={1.5}>
                <CoreButton variant="outlined" color="primary" type="submit">
                  Filter
                </CoreButton>
              </Box>
              <Box component="span" mb={1.5}>
                <CoreButton variant="outlined" onClick={resetFilter}>
                  Close
                </CoreButton>
              </Box>
            </Box>
          </form>
        )}
      </Form>
    </div>
  );
};

export const PaymentMethodList = (props) => (
  <List {...props} filters={<PaymentMethodFilter />} exporter={exporter}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="payment_name" />
      <EditButton basePath="/payment_method" />
      <DeleteWithConfirmButton undoable={false} />
    </Datagrid>
  </List>
);

const PaymentMethodTitle = ({ record }) => {
  return <span>PaymentMethod {record ? `"${record.name}"` : ""}</span>;
};

export const PaymentMethodEdit = (props) => (
  <Edit undoable={false} actions={<EditActions />} title={<PaymentMethodTitle />} {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput disabled source="id" />
      <TextInput source="payment_name" validate={required()} />
    </SimpleForm>
  </Edit>
);

export const PaymentMethodCreate = (props) => (
  <Create title="Create a PaymentMethod" actions={<EditActions />} {...props}>
    <SimpleForm>
      <TextInput source="payment_name" validate={required()} />
    </SimpleForm>
  </Create>
);

import React from 'react';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import _ from 'lodash';
import {
  FORMAT_APP_DATE, API_RESOURCES
} from '../../constants.js';

// Models

// Component
import ErrorMessage from '../global/ErrorMessage'
import ContractByIds from '../global/ContractByIds.js'
import { OrderFormOtherInfo } from './OrderFormOtherInfo'
import ToggleSwitch from '../global/ToggleSwitch'
import ModalConfirmation from '../global/ModalConfirmation';

// Function
import { toUniqueArr } from '../../functions/Object'
import { uniqueKey } from '../../functions/Random'

export class OrderFormPoGr extends React.Component {
  constructor(props) {
    super(props)
    let disabled_ids = props.order.order_po_grs.reduce((res, elm) => {
      if (elm.contract_id) {
        res.push(elm.contract_id)
      }
      return res
    }, [])
    this.state = {
      is_deleting: null,
      po_gr_contract_ids: toUniqueArr(props.po_gr_contract_ids),
      disabled_ids: disabled_ids,
      reset_contract_select: uniqueKey()
    }
    this.onChangePoGrContract = this.onChangePoGrContract.bind(this)
    this.onDeletePoGr = this.onDeletePoGr.bind(this)
  }

  onChangePoGrContract(contract_id, id) {
    let { disabled_ids } = this.state
    disabled_ids.push(contract_id)
    this.props.onChangePoGrContract(contract_id, id)
    this.setState({disabled_ids: disabled_ids, reset_contract_select: uniqueKey()})
  }

  onDeletePoGr(contract_id, id) {
    // let { disabled_ids, reset_contract_select } = this.state
    // let index = disabled_ids.findIndex(value => value === contract_id)
    // if (index >= 0) {
    //   delete disabled_ids[index]
    //   reset_contract_select = uniqueKey()
    // }
    // this.setState({disabled_ids: disabled_ids, reset_contract_select: reset_contract_select, is_deleting: id})
    this.setState({is_deleting: id})
  }

  onConfirmDelete = () => {
    this.props.onDeletePoGr(this.state.is_deleting)
    this.setState({is_deleting: null})
  }

  sub_record() {
    let { order, disabled } = this.props
    let { po_gr_contract_ids, disabled_ids, reset_contract_select } = this.state
    let records = []
    order.order_po_grs.map((elm) => {
      records.push((
        <div className="card-box p-3 mt-2">
          <div className="row">
            <div className="col-4 col-md-2 pr-0 mt-2"><label>วันที่ส่งข้อมูล PO</label></div>
            <div className="col-8 col-md-4 date-picker-wrapper mt-2">
              <DatePicker
                className="form-control"
                dateFormat={FORMAT_APP_DATE}
                selected={elm.submit_date ? new Date(elm.submit_date) : null}
                onChange={date => this.props.onChangePoGrDateDetails(date, "submit_date", elm.id)}
                disabled={disabled}
              />
            </div>
            <div className="col-4 col-md-2 pr-0 mt-2"><label className="sign-is-required">เลขที่สัญญา</label></div>
            <div className="col-8 col-md-4 date-picker-wrapper mt-2">
              <ContractByIds
                contractId={elm.contract_id}
                optionIds={po_gr_contract_ids}
                optionDisabledIds={disabled_ids}
                onChange={(e) => this.onChangePoGrContract(e, elm.id)}
                disabled={this.props.disabled}
                key={reset_contract_select}
              />
              <ErrorMessage errorKey={`order_po_grs[${elm.id}].contract_id`} errorMessages={this.props.errors}/>
            </div>
          </div>
  
          <div className="row">
            <div className="col-4 col-md-2 pr-0 mt-2"><label>เลขที่ PO</label></div>
            <div className="col-8 col-md-4 date-picker-wrapper mt-2">
              <input type="id" className="form-control" id="order-po-code" name="po_code"
                     onChange={(e) => this.props.onChangePoGrDetails(e, elm.id)} value={elm.po_code} disabled={disabled}
                     ></input>
            </div>
            <div className="col-4 col-md-2 pr-0 mt-2"><label>วันที่ได้รับ</label></div>
            <div className="col-8 col-md-4 date-picker-wrapper mt-2">
              <DatePicker
                className="form-control"
                dateFormat={FORMAT_APP_DATE}
                selected={elm.po_date ? new Date(elm.po_date) : null}
                onChange={date => this.props.onChangePoGrDateDetails(date, "po_date", elm.id)}
                disabled={disabled}
              />
            </div>
          </div>
  
          <div className="row">
            <div className="col-4 col-md-2 pr-0 mt-2"><label>เลขที่ GR</label></div>
            <div className="col-8 col-md-4 date-picker-wrapper mt-2">
              <input type="id" className="form-control" id="order-gr-code" name="gr_code"
                     onChange={(e) => this.props.onChangePoGrDetails(e, elm.id)} value={elm.gr_code} disabled={disabled}
                     ></input>
            </div>
            <div className="col-4 col-md-2 pr-0 mt-2"><label>วันที่ได้รับ</label></div>
            <div className="col-8 col-md-4 date-picker-wrapper mt-2">
              <DatePicker
                className="form-control"
                dateFormat={FORMAT_APP_DATE}
                selected={elm.gr_date ? new Date(elm.gr_date) : null}
                onChange={date => this.props.onChangePoGrDateDetails(date, "gr_date", elm.id)}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4 col-md-2 pr-0 mt-2"><label>ใบสั่งจ้างผลิต</label></div>
            <div className="col mt-2">
              {elm.manufacture_request_id ? 
              <Link to={`/${API_RESOURCES.mr}/${elm.manufacture_request_id}`} target="_blank" rel="noopener noreferrer">คลิกเพื่อเปิดดูใบสั่งจ้างผลิต</Link> : '-'}
            </div>
          </div>
          <div className="row mt-2">
            <div className={`col text-right ${(this.state.number_of_record !== 1) ? '' : 'd-none'}`}>
              <button type="button" className="btn btn-danger btn-sm" onClick={() => {this.onDeletePoGr(elm.contract_id, elm.id)}} disabled={order.order_po_grs.length === 1}>ลบ</button>
            </div>
          </div>
        </div>
      ))
    })
    return records
  }

  removeOrderPoGrModal() {
    let { is_deleting } = this.state
    if (is_deleting) {
      const content = () => (
        <React.Fragment>
          <div className="row pb-2 mb-2">
            <div className="col-12"><label>ถ้าลบแล้ว ใบสั่งจ้างผลิตที่ Link อยู่จะถูก unlink อัตโนมัติ</label></div>
          </div>
        </React.Fragment>
      )
      return (
        <ModalConfirmation
          isOpen={true}
          title="ต้องการลบ POย่อย/GR?"
          content={content()}
          onConfirm={this.onConfirmDelete}
          onCancel={() => {this.setState({is_deleting: null})}}
        />
      )
    }
  }

  render() {
    let { order } = this.props
    let { po_payment_compeleted } = order.order_flag
    let { po_gr_contract_ids } = this.state
    let enable_auto_gen_mrs = false
    if (po_payment_compeleted) {
      let all_contract_ids = order.order_po_grs.map((elm) => {
        if (elm.manufacture_request_id) {
          return elm.contract_id
        }
      })
      for (let con_id of po_gr_contract_ids) {
        enable_auto_gen_mrs = enable_auto_gen_mrs || !all_contract_ids.includes(con_id)
      }
    }

    return (
      <React.Fragment>
        {enable_auto_gen_mrs ? 
        <div className="card-box info d-flex flex-row justify-content-between mb-3">
          <div className="d-flex  align-items-center">
            <i className="icon-info large d-md-flex d-none"></i>
            <div className="px-2 p-t-5px">เมื่อราคาขาขายเสร็จสมบูรณ์ และราคาขาซื้อระบุสัญญาแล้วจะสามารถกดเพิ่มสร้างใบสั่งจ้างผลิตอัตโนมัติได้ </div>
          </div>
          <div className="text-right my-auto">
            <button type="button" className="btn btn-outline-primary btn-sm min-w-130px"
                  onClick={this.props.onClickGenerateMR}>สร้างใบสั่งจ้างผลิต</button>
          </div>
        </div> : ''}

        <div className="d-flex justify-content-between">
          <h5 className="pb-1">PO ย่อย/GR</h5>
          <div className="d-flex">
            <div className="mr-3">
              PO/GR สมบูรณ์แล้ว
            </div>
            <ToggleSwitch
              checked={this.props.order.order_flag.po_gr_completed}
              onChange={this.props.confirmPoGr}
            />
          </div>
        </div>

        {this.sub_record()}
        <div className="row mt-2">
          <div className="col text-right">
            <button type="button" className="btn btn-primary" onClick={this.props.onAddNewPoGr} disabled={order.order_po_grs.length >= po_gr_contract_ids.length}>เพิ่ม</button>
          </div>
        </div>

        {/* Other Info */}
        <OrderFormOtherInfo
          page_nb={this.props.page_nb}
          note={this.props.order.order_notes.filter(elm => elm.page_nb === this.props.page_nb)[0]}
          onChangeNote = {this.props.onChangeNote}
          disabled={this.props.disabled}
          key={this.props.resetKeyNote}
        />

        {this.removeOrderPoGrModal()}
        <div className="block-info-buffer-box"></div>
      </React.Fragment>
    );
  }
}
import React from 'react';


export class Error extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error_type: props.match.params.type
    }
  }

  render() {
    let { error_type } = this.state
    
    if (error_type === "access_denied"){
      return (
        <React.Fragment>
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css"></link>
        <div className="container py-5">
          <div className="row mt-5">
            <div className="col text-center">
              <p><i className="fa fa-exclamation-triangle fa-5x"></i><br/>Status Code: 403</p>
            </div>
          </div>
          <div className="row">
            <div className="col text-center">
              <h1 className="color_red mb-3">Access Denied</h1>
              <h5>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</h5>
              <a className="btn btn-danger mt-5" href="/">กลับไปหน้าหลัก</a>
            </div>
          </div>
        </div>
        </React.Fragment>
      )
    }
  }
}
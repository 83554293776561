// in templates.js
import * as React from "react";
import { Edit, Create, SimpleForm, TextInput, Toolbar, TabbedShowLayout, Tab, FileField, SaveButton, Filter, required, ImageInput, ImageField, useRefresh, useRedirect, NumberInput, TextField } from "react-admin";
import BookIcon from "@material-ui/icons/Book";
import Grid from "@material-ui/core/Grid";

import { uniqueKey } from "../../functions/Random";

import { EditToolbar, EditActions } from "./Default";

export const TemplateIcon = BookIcon;

const TemplateFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

export const TemplateList = (props) => {
  const refresh = useRefresh();
  const redirect = useRedirect();

  redirect("/templates/1");
  refresh();
  return null;
};

const TemplateTitle = ({ record }) => {
  return <span>Template</span>;
};

export const TemplateEdit = (props) => {
  console.log("props", props);

  return (
    <div className="scroll-div">
      <Edit undoable={false} actions={<EditActions />} title={<TemplateTitle />} {...props}>
        <SimpleForm toolbar={<EditToolbar />}>
          <Grid container spacing={1} style={{ width: "100%" }}>
            <TabbedShowLayout width="100%">
              <Tab label="Quotation Template" width="100%">
                <ImageInput source="quo_logo" label="logo" accept="image/*" validate={required()}>
                  <ImageField source="src" title="title" />
                </ImageInput>
                <TextInput source="quo_title_name" label="Title" validate={required()} fullWidth />
                <TextInput source="quo_approver_name" label="ชื่อผู้อนุมัติ" validate={required()} fullWidth />
                <TextInput source="quo_approver_title" label="ตำแหน่งชื่อผู้อนุมัติ" validate={required()} fullWidth />
                <FileField source="quo_s3_url" title="ตัวอย่างใบเสนอราคา" label="ตัวอย่างใบเสนอราคา" target="_blank" />
              </Tab>

              <Tab label="Quotation Remark">
                <TextInput source="quotation_remark" label="หมายเหตุใบเสนอราคา" fullWidth multiline />
              </Tab>

              <Tab label="PO Template">
                <TextInput source="po_title_name" label="Title" validate={required()} fullWidth />
                <FileField source="po_s3_url" title="ตัวอย่างใบสั่งซื้อสินค้า" label="ตัวอย่างใบสั่งซื้อสินค้า" target="_blank" />
              </Tab>

              <Tab label="MR Template">
                <ImageInput source="mr_logo" label="logo" accept="image/*" validate={required()}>
                  <ImageField source="src" title="title" />
                </ImageInput>
                <ImageInput source="mr_signature" label="ลายเซ็น" accept="image/*" validate={required()}>
                  <ImageField source="src" title="title" />
                </ImageInput>
                <TextInput source="mr_title_name" label="Title" validate={required()} fullWidth />
                <TextInput source="mr_approver_name" label="ชื่อผู้อนุมัติ" fullWidth />
                <TextInput source="mr_approver_title" label="ตำแหน่งชื่อผู้อนุมัติ" fullWidth />
                <TextInput source="mr_body_approver_title" label="ตำแหน่งชื่อผู้อนุมัติในย่อหน้าแรก" fullWidth />
                <TextInput multiline source="mr_inspection_content" label="ข้อความขออนุมัติเอกสารตรวจรับ" fullWidth />
                <ImageInput source="mr_inspection_s3_sign_url" label="ลายเซ็นตรวจรับ" accept="image/*" validate={required()}>
                  <ImageField source="src" title="title" />
                </ImageInput>

                <FileField source="mr_s3_url" title="ตัวอย่างใบMR" label="ตัวอย่างใบMR" target="_blank" />
              </Tab>

              <Tab label="Delivery Setting">
                <NumberInput source="days_to_ship" label="ระยะเวลาเตรียมจัดส่ง/ติดตั้งพื้นฐาน" validate={required()} fullWidth />
              </Tab>
            </TabbedShowLayout>
          </Grid>
        </SimpleForm>
      </Edit>
    </div>
  );
};

export const TemplateCreate = (props) => (
  <div className="scroll-div">
    <Create title="Create a Template" actions={<EditActions />} {...props}>
      <SimpleForm>
        <ImageInput source="quo_logo" label="logo" accept="image/*" validate={required()}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="quo_title_name" validate={required()} />
        <TextInput source="quo_approver_name" validate={required()} />
        <TextInput source="quo_approver_title" validate={required()} />

        <TextInput source="po_title_name" validate={required()} />

        <ImageInput source="mr_logo" label="logo" accept="image/*" validate={required()}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput source="mr_signature" label="logo" accept="image/*" validate={required()}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="mr_title_name" validate={required()} />
        <TextInput source="mr_approver_name" />
        <TextInput source="mr_approver_title" />
        <TextInput source="mr_body_approver_title" label="ตำแหน่งชื่อผู้อนุมัติในย่อหน้าแรก" fullWidth />
      </SimpleForm>
    </Create>
  </div>
);

import React from "react";
import Modal from "react-modal";

import { R_SCREEN_WIDTH } from "../../constants";
import Number from "../global/Number";
import Decimal from "../global/Decimal";
import { Multiple } from "../../functions/Number";
import { isDiscount, isCategoryDiscount, isItemExpense } from "../../models/Quotations";
import { getExpenseTotalCost, getExpenseUnit, getExpenseName } from "../../models/ItemCategories";

export class QuotationPurchasePricesFee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_preview: false,
      selected_elm: null,
    };
    this.handleEditModal = this.handleEditModal.bind(this);
    this.handleOpenModalPreview = this.handleOpenModalPreview.bind(this);
    this.handleCloseModalPreview = this.handleCloseModalPreview.bind(this);
    this.onAddShippingCost = this.onAddShippingCost.bind(this);
  }

  handleEditModal(id) {
    this.setState({ modal_preview: true, selected_elm: id });
  }

  handleOpenModalPreview() {
    this.setState({ modal_preview: true });
  }

  handleCloseModalPreview() {
    this.setState({ modal_preview: false });
  }

  onAddShippingCost(id, index, item_category_id) {
    this.handleCloseModalPreview();
    this.props.onAddShippingCost(id, index, item_category_id);
  }

  shipBtn(elm, index) {
    if (!this.props.disabled) {
      if (isCategoryDiscount(elm)) {
        return false;
      }
      if (elm.is_new) {
        return (
          <button type="button" className="btn btn-success btn-sm ml-2" onClick={() => this.props.onAddShippingCost(elm.id, index + 1, elm.item_category_id)}>
            +
          </button>
        );
      }
    }
  }

  inputField(elm, field, isNumber) {
    if (isNumber === true) {
      return <Number innerClass="text-right" name={field} value={elm[field]} onChange={(e) => this.props.onChangeShippingDetails(e, elm.id, elm.item_category_id)} disabled={this.props.disabled} />;
    } else {
      return <input className="form-control text-right" name={field} value={elm[field]} onChange={(e) => this.props.onChangeShippingDetails(e, elm.id, elm.item_category_id)} disabled={this.props.disabled}></input>;
    }
  }

  decimalField(elm, field) {
    return <Decimal innerClass="text-right" name={field} value={elm[field]} onChange={(e) => this.props.onChangeShippingDetails(e, elm.id, elm.item_category_id)} disabled={this.props.disabled} />;
  }

  addModal(elm, index) {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        overflow: "hidden",
        transform: "translate(-50%, -50%)",
      },
    };
    return (
      <Modal isOpen={this.state.modal_preview && this.state.selected_elm === elm.id} contentLabel="onAddSpeacialFee" onRequestClose={this.handleCloseModalPreview} style={customStyles}>
        <div className="d-flex flex-column">
          <h5>ค่าบริการ</h5>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>บริการ</label>
            </div>
            <div className="d-flex-2">{elm.is_new ? this.inputField(elm, "name") : elm.name}</div>
          </div>
          <div className="d-flex flex-row mt-2">{`${elm.qty} ${elm.unit}`}</div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>ราคา</label>
            </div>
            <div className="d-flex-2">{this.decimalField(elm, "contract_price")}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>รวม</label>
            </div>
            <div className="d-flex-2">{Multiple(elm.contract_price, elm.qty).toFixed(2)}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <button type="button" className="btn btn-success btn-sm w-100" onClick={() => (elm.is_new ? this.onAddShippingCost(elm.id, index + 1, elm.item_category_id) : this.handleCloseModalPreview())} disabled={this.props.disabled}>
              {elm.is_new ? "เพิ่ม" : "บันทึก"}
            </button>
            <button type="button" className="btn btn-secondary btn-sm w-100 ml-1" onClick={this.handleCloseModalPreview}>
              ยกเลิก
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  displayShippingCost(costByCate) {
    let { disabled, items_in_cate, is_completed } = this.props;
    let items = costByCate
      .sort((a, b) => (a.position > b.position ? 1 : -1))
      .map((elm, index) => {

        let find_tyoe_7 = null

        costByCate.forEach((cate) => {
          if (cate.method_type === 7 && cate.qty > cate.from_value) {
            if (find_tyoe_7) {
              if (cate.qty > find_tyoe_7.from_value && find_tyoe_7.from_value < cate.from_value) find_tyoe_7 = cate
            } else find_tyoe_7 = cate
          }
        })

        // dont display if there is no fee
        if (find_tyoe_7 && elm.method_type === 1 || find_tyoe_7 && elm.method_type === 2 || find_tyoe_7 && elm.method_type === 5 || !find_tyoe_7 && elm.method_type === 7 || find_tyoe_7 && elm.method_type === 7 && elm.from_value !== find_tyoe_7.from_value) return false
        if ((elm.qty == 0 || elm.item_qty == 0) && !elm.is_custom_qty) return false;

        let total = getExpenseTotalCost(elm, true).toLocaleString();
        let row_style = isDiscount(elm) ? "row-discount" : "";
        let canceled_item_style = "";
        if (is_completed && isItemExpense(elm.resource_type)) {
          let target_item = items_in_cate.filter((temp) => temp.item_id === elm.resource_id)[0];
          canceled_item_style = target_item && !target_item.issued ? "text-line-through" : "";
        }
        if (!elm.is_new && !isDiscount(elm)) {
          return (
            <React.Fragment key={elm.id}>
              <a onClick={() => (window.innerWidth <= R_SCREEN_WIDTH.md && !disabled ? this.handleEditModal(elm.id) : false)}>
                <div className={`row mt-2 border ${row_style} ${canceled_item_style}`}>
                  <div className="table-item-bg first col-1 d-none d-md-flex"></div>
                  <div className="table-item-bg col-12 col-md-5 pl-md-0 pr-0">{getExpenseName(elm)}</div>
                  <div className="table-item-bg col-2 d-none d-md-flex pl-0 justify-content-end"><div style={{ marginRight: 5 }}>{elm.qty ? `${elm.qty} ${getExpenseUnit(elm)}` : ""}</div></div>
                  <div className="table-item-bg col-2 pl-0 justify-content-end d-none d-md-flex"><di style={{ marginRight: 10, marginLeft: -5 }}>{this.decimalField(elm, "contract_price")}</di></div>
                  <div style={{ paddingRight: 45 }} className="table-item-bg col-2 d-none d-md-flex">
                    <div className="w-100 ml-2 text-right">{total}</div>
                  </div>
                  <div className="table-item-bg col-12 d-flex justify-content-end bg-ghostwhite d-md-none">
                    <h4 className="sign-baht color-darkgrayblue my-0">{total}</h4>
                  </div>
                </div>
              </a>
              {this.addModal(elm, index)}
            </React.Fragment>
          );
        }
      });
    return items;
  }

  render() {
    let { costByCate } = this.props;
    // let decorated_costs = []
    // costByCate.map((elm) => {
    //   if(isDiscount(elm)) {
    //     let existing_cost = decorated_costs.filter((c) => c.name === elm.name)
    //     if(existing_cost.length !== 0) {
    //       existing_cost[0].item_qty = parseInt(existing_cost[0].item_qty) + parseInt(elm.item_qty)
    //     } else {
    //       decorated_costs.push(elm)
    //     }
    //   } else {
    //     decorated_costs.push(elm)
    //   }
    // })
    return <React.Fragment>{this.displayShippingCost(costByCate)}</React.Fragment>;
  }
}

import { 
  hasLowerCase,
  hasUpperCase,
  hasNumber
} from '../functions/Validation'

export const requiredPasswordPolicies = {
  'minimum_length': "ต้องมีความยาวอย่างน้อย 8 ตัวอักษร",
  'require_number': "ต้องมีด้วยตัวเลข",
  'require_uppercase_letters': "ต้องมีตัวอักษรพิมพ์ใหญ่",
  'require_lowercase_letters': "ต้องมีตัวอักษรพิมพ์เล็ก",
}

export function validatePassword(user) {
  let errors = {}

  if (user.new_password && user.confirm_new_password) {
    if (user.new_password !== user.confirm_new_password) {
      errors["new_password"] = "New Password และ Confirm New Password ต้องเหมือนกัน"
      errors["confirm_new_password"] = "New Password และ Confirm New Password ต้องเหมือนกัน"
    } else {
      // check minimum_length
      if (user.new_password.length < 8) {
        errors["new_password"] = requiredPasswordPolicies['minimum_length']
      }
      // check require_number
      if (!hasNumber(user.new_password)) {
        errors["new_password"] = requiredPasswordPolicies['require_number']
      }
      // check require_uppercase_letters
      if (!hasLowerCase(user.new_password)) {
        errors["new_password"] = requiredPasswordPolicies['require_lowercase_letters']
      }
      // check require_lowercase_letters
      if (!hasUpperCase(user.new_password)) {
        errors["new_password"] = requiredPasswordPolicies['require_uppercase_letters']
      }
    }
  } else if (user.new_password && !user.confirm_new_password) {
    errors["confirm_new_password"] = "ต้องไม่เว้นว่าง"
  } else if (!user.new_password && user.confirm_new_password) {
    errors["new_password"] = "ต้องไม่เว้นว่าง"
  } else {
    // Do nothing
  }

  return errors
}

export const TEMP_USERS = [
  {
    id: 0,
    firstname: "",
    lastname: "",
    value: "",
    label: "",
  },
  {
    id: 1,
    firstname: "test",
    lastname: "user1",
    value: 1,
    label: "test user1",
  },
  {
    id: 2,
    firstname: "admin",
    lastname: "admin",
    value: 2,
    label: "admin admin",
  },
]

import React, { useState } from "react";
import { Button, DatePicker, Tooltip } from "antd";
import "../dc-inbound-order/DcInboundOrder.css";
import moment from "moment";

export default function ProductNoticeCollapse({ category, onChangeInstallationDate, onChangeInformedDate, match, onGetWarrantyCode, onDeleteWarrantyCode }) {
  const [isCollapse, setIsCollapse] = useState(false);
  function getNumberCode(code){
    return String(code).padStart(4, '0');
  }
  function DisplayWarrantyCodes(warrantyCode, item) {
    try{
      return (
        <div className="warranty-code-container" >
          <div>
            {warrantyCode.map((warranty) => {
              return (
                <div>
                  OR-{warranty.asset_code}-{warranty.vendor.vendor_code}-{getNumberCode(warranty.code)}
                </div>
              );
            })}
          </div>
          <div className="delete-warranty-code" onClick={() => onDeleteWarrantyCode(item.in_bound_id, item)}>
            X
          </div>
        </div>
      );
    }catch(e){
      console.log(e)
    }
  }

  function DisplayItemContent(item, index) { 
    try{
      return (
        <section>
          <div className={`expan-product-header ${index % 2 === 1 ? "" : "bg-aliceblue"}`}>
            <div className="expan-product-index"  >{index + 1}</div>
            <div style={{marginLeft:"20px"}}>
              {item.code} - {item.name}  
            </div>
          </div>
          <div className="expan-product-code-header">
            <text className="code-text-center">รหัสทรัพย์สิน</text>
            {/* <div className="inform-text-date">
              <text style={{ textAlign: "left" }} className="code-text-center">
                วันที่จัดส่ง
              </text>
            </div> */}
          </div>
          <div className={`expan-product-code-item-header ${index % 2 === 1 ? "" : "bg-aliceblue"}`}> 
            {item.warranty_code && item.warranty_code.length > 0 ? (
              DisplayWarrantyCodes(item.warranty_code, item)
            ) : (item.contract_item.item.asset_code != null || item.item_category.code != null) || match.params.id === "create" ? (
              <Tooltip title={item.in_bound_id === undefined ? "กรุณา Save ก่อนสร้างรหัส !" : match.params.id === "create" && "กรุณา Save ก่อนสร้างรหัส !"} placement="top">
                <text className="code-text-center">
                  <Button
                    onClick={() => onGetWarrantyCode(item, category.contract_id)}
                    className={`create-code-btn ${item.in_bound_id === undefined ? "disabled-btn" : match.params.id === "create" && "disabled-btn"}`}
                    type="primary"
                    disabled={(match.params.id === "create") | (item.in_bound_id === undefined)}
                  >
                    สร้างรหัส
                  </Button>
                </text>
              </Tooltip>
            ) : (
              <div style={{ width: 180, textAlign: "center" }}>-</div>
            )}
            {/* <div className="inform-text-date">
              <DatePicker value={item.installation_date && moment(item.installation_date)} className="inform-code-date-pickup" format={"DD-MM-YYYY"} placeholder="เลือกวันที่จัดส่ง..." onChange={(e) => onChangeInstallationDate(e, item, category)} />
            </div> */}
          </div>
        </section>
      );
    }catch(e){
        try {
          return (
            <section>
            <div className={`expan-product-header ${index % 2 === 1 ? "" : "bg-aliceblue"}`}>
              <text className="expan-product-index">{index + 1}</text>
              <text>
                {item.code} - {item.name}
              </text>
            </div>
            <div className="expan-product-code-header">
              <text className="code-text-center">รหัสทรัพย์สิน</text>
            </div>
            <div className={`expan-product-code-item-header ${index % 2 === 1 ? "" : "bg-aliceblue"}`}  > 
              <text className="code-text-center">
                    <Button disabled
                      onClick={() => onGetWarrantyCode(item, category.contract_id)}
                      className={`create-code-btn disabled-btn`}
                      type="primary" 
                    >
                      สร้างรหัส
                    </Button>
                  </text> 
            </div>
          </section> 
          )
        } catch (error) {
          
        }
    }
  }
try{
  return (
    <section>
      <section>
        <div className="inform-table-item-header" onClick={() => setIsCollapse((prevState) => !prevState)}>
          <i className={`icon-arrow-down ${isCollapse ? "icon-collapse" : "icon-expaned"}`} />
          <text  style={{marginLeft:"10px"}}>{category.sys_name}</text>
          <div className="inform-vendor">
            <text className="inform-text">แจ้งเมื่อ : </text><br />
            <DatePicker value={category.informed_date && moment(category.informed_date)} className="inform-date-pickup" placeholder="เลือกวันที่แจ้ง..." format={"DD-MM-YYYY"} onChange={(e) => onChangeInformedDate(e, category)} />
          </div>
        </div>
      </section>
      <section>
        <div className={`expan-product-detail-container ${isCollapse ? "inform-product-collapse" : "inform-product-expaned"}`}>
          {category.item_category.map((item, index) => {
            return DisplayItemContent(item, index);
          })}
        </div>
      </section>
    </section>
  );
}catch(e){
  console.log(e)
}
 
}

import React from "react"
import PropTypes from "prop-types"
import Select from 'react-select'
import axios from 'axios';

class BankSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      banks: [],
      isMounted: false
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.bankSelected = this.bankSelected.bind(this)
  }

  componentDidMount() {
    let { bankId } = this.props
    if (bankId) {
      this.getBankFromId(bankId)
    } else {
      this.getCollection()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.bankId != this.props.bankId && this.state.isMounted) {
      let selectOption = this.state.banks.filter(option => option.value == this.props.bankId)[0]
      this.props.onChange(this.props.bankId, selectOption)
    }
  }

  handleInputChange(value) {
    if (value !== "") {
      this.getCollection(value)
    }
  }

  setOptions(response) {
    let options = []
    response.data.map(e => {
      options.push({ id: e.id, text: e.name, value: e.id })
    })
    this.setState({
      banks: options,
      isMounted: true
    })
  }

  getBankFromId(bankId) {
    axios.get(`${process.env.REACT_APP_API_URL}/banks/${bankId}`)
    .then((response) => {
      let options = [{ id: response.data.id, text: response.data.name, value: response.data.id }]
      this.setState({
        banks: options,
        isMounted: true
      })
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  }

  getCollection(query) {
    let params = {
      range: '[0, 15]',
      sort: ["id","ASC"]
    }
    if (query) {
      params.filter = {name: query}
    }

    axios.get(`${process.env.REACT_APP_API_URL}/banks`, {
      params: params
    })
    .then((response) => {
      this.setOptions(response)
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  }

  bankSelected(e) {
    let value = e.value
    if (value !== this.props.bankId && this.state.isMounted) {
      this.props.onChange(value, e)
    }
  }

  render() {
    return (
      <React.Fragment>
        <Select
          className="select2-style"
          onChange={this.bankSelected}
          onInputChange={this.handleInputChange}
          getOptionLabel={option =>
            `${option.text}`
          }
          value={this.state.banks.filter(option => option.value === this.props.bankId)}
          getOptionValue={option => `${option.id}`}
          options={this.state.banks}
          isDisabled={this.props.disabled}
          placeholder={'ค้นหา...'}
        />
      </React.Fragment>
    );
  }
}

BankSelect.propTypes = {
  bankId: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export default BankSelect

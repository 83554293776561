import React from 'react'
import Select from 'react-select'
import _ from 'lodash'

// Models
import { sortItemByCate, getWarrantyCode } from '../../models/Orders'

// Components
import { OrderFormOtherInfo } from './OrderFormOtherInfo'
import { OrderFormPurchaseCostItems } from './OrderFormPurchaseCostItems'
import ErrorMessage from '../global/ErrorMessage'
import Number from '../global/Number'
import ToggleSwitch from '../global/ToggleSwitch'

// Functions
import { Multiple } from '../../functions/Number'
import { parseExpRatioArr } from '../../functions/FeePurchaseCalculation'
import { formatDate } from '../../functions/Date'
import { stringIsEmpty } from '../../functions/String'

export class OrderFormCostPrice extends React.Component {
  constructor(props) {
    super(props)
    let completed = props.order.order_flag.purchase_price_completed
    this.state = {
      items: [],
      vendor: {},
      completed: completed,
      disabled: completed || props.disabled,
    }

    this.onChangeVendor = this.onChangeVendor.bind(this)
    this.onChangeContractPrice = this.onChangeContractPrice.bind(this)
  }

  componentDidMount() {
    // let { items } = this.props.order
    // let de_items = items.map((item) => {
    //   item.price = TEMP_ITEM_PRICES.filter(elm => (elm.item_id === item.value && elm.zone_id === 1))[0].price
    //   return item
    // })
    // this.setState({ items: de_items })
  }

  onChangeVendor(option, cate_id) {
    // let { items } = this.state
    // let selected_item = items.filter(elm => elm.value === id)[0]
    // selected_item.vendor = option
    // selected_item.price = option.price
    // this.setState({ items: items })
  }

  // onChangeVendor(option, index) {
  //   let { vendor } = this.state
  //   let quo = this.state.quotation
  //   let real_index = index - 1
  //   // quo.quotation_items.filter(elm => (index === 0) ? elm.item_category_id === 1 : elm.item_category_id !== 1).map((item) => {
  //   //   if (vendor[index]) {
  //   //     item.price = item.price - vendor[index].value
  //   //   }
  //   //   item.price = item.price + option.value
  //   // })

  //   if (vendor[real_index]) {
  //     quo.quotation_items[real_index].price -= vendor[real_index].value
  //   }
  //   quo.quotation_items[real_index].price += option.value
  //   vendor[real_index] = option
  //   this.setState({quotation: quo, vendor: vendor})
  // }

  onChangeContractPrice(e, id) {
    let { items } = this.state
    let selected_item = items.filter((elm) => elm.value === id)[0]
    selected_item.contract_price = e.target.value

    this.setState({ items: items })
  }

  // displayItemsWithVendor() {
  //   let items = this.state.items.map((elm, index) => {
  //     let options = TEMP_ITEM_PRICES.filter(selm => elm.value === selm.item_id).map((option, index) => {
  //       let zone = TEMP_ZONES.filter(selm => selm.value === option.zone_id)[0].label
  //       let vendor = TEMP_VENDORS.filter(selm => selm.id === option.vendor_id)[0].label
  //       return {
  //         value: index+1,
  //         label: `${zone} - ${vendor}`,
  //         zone_id: option.zone_id,
  //         price: option.price
  //       }
  //     })
  //     return (
  //       <React.Fragment>
  //         <div className="row mt-2 pl-2">
  //           <div className="col-p5 justify-content-center">{index+1}</div>
  //           <div className="col-6">
  //             {elm.label}
  //           </div>
  //           <div className="col-3 p-0">
  //             <Select
  //               defaultValue={options.filter(selm => selm.zone_id === 1)}
  //               onChange={(e)=>this.onChangeVendor(e, elm.value)}
  //               value={elm.vendor}
  //               options={options}
  //             />
  //           </div>
  //           <div className="col">
  //             <Number
  //               innerClass="text-right"
  //               name={`price-${index}`}
  //               value={elm.price}
  //               onChange={(e) => this.onChangeContractPrice(e, elm.value)}
  //               disabled={this.props.disabled}
  //             />
  //           </div>
  //         </div>
  //       </React.Fragment>
  //     )
  //   })
  //   return items
  // }

  displayItems() {
    let items = []
    let list_index = 0
    this.props.order.purchasing_order_items.sort(sortItemByCate).map((elm, index) => {
      let expense_group = {}
      if (!elm.is_mbom) {
        if (elm.expense_ratio_arr) {
          expense_group = _.groupBy(elm.expense_ratio_arr, function (value) {
            return value
          })
        } else {
          expense_group[elm.expense_ratio] = [elm.expense_ratio]
        }

        for (var key in expense_group) {
          let exp_ratio = parseExpRatioArr(key)
          let price_with_fee = elm.contract_price + exp_ratio
          let de_qty = elm.expense_ratio_arr ? expense_group[key].length : elm.qty
          items.push(
            <React.Fragment>
              {/* sm up */}
              <div className={`row mt-2 pl-2 d-none d-sm-flex ${list_index % 2 === 0 ? '' : 'bg-aliceblue'}`}>
                <div className="col-p5 justify-content-center">{list_index + 1}</div>
                <div className="col-6">{elm.name}</div>
                <div className="col-2 p-0">{`${de_qty} ${elm.unit}`}</div>
                <div className="col text-right">{price_with_fee.toLocaleString()}</div>
                <div className="col text-right">{Multiple(price_with_fee, de_qty).toLocaleString()}</div>
              </div>

              {/* sm down */}
              <div className={`row mt-2 pl-2 d-sm-none ${index % 2 === 0 ? '' : 'bg-aliceblue'}`}>
                <div className="col-1 pl-0 justify-content-center">{index + 1}</div>
                <div className="col-7 pl-0">
                  {elm.name} จำนวน {`${de_qty} ${elm.unit}`}
                </div>
                <div className="col text-right">{Multiple(price_with_fee, de_qty).toLocaleString()}</div>
              </div>
            </React.Fragment>
          )
        }
        list_index++
      }
    })
    return items
  }

  displayHeader() {
    return (
      <React.Fragment>
        <div className="row pl-2">
          <div className="col-p5 d-none d-sm-block text-center">ลำดับ</div>
          <div className="col-1 pl-0 d-sm-none text-center">#</div>
          <div className="col-7 pl-0 col-sm-6">สินค้า</div>
          <div className="col-2 d-none d-sm-block">จำนวน</div>
          <div className="col d-none d-sm-block text-right">ราคา</div>
          <div className="col text-right">ราคารวม</div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { order } = this.props
    const { disabled } = this.state
    const disabled_contract = order.purchasing_order_items.reduce((pre, cur) => {
      return pre || !stringIsEmpty(getWarrantyCode(cur.warranty_codes))
    }, false)

    return (
      <React.Fragment>
        {/* <div className="row">
          <div className="col-12 col-md-6">
            <h5 className="pb-1 d-none d-md-block">สรุปราคาขาซื้อ (ต่อหน่วย) - วันที่ดึงสัญญา {formatDate(order.quotation.approved_date)}</h5>
            <h5 className="pb-1 d-md-none ">
              สรุปราคาขาซื้อ (ต่อหน่วย)
              <br />
              <span className="font-size-15px mt-2">วันที่ดึงสัญญา {formatDate(order.quotation.approved_date)}</span>
            </h5>
          </div>
          <div className="col d-flex flex-row justify-content-end">
            <div className="mr-3">ราคาทุนสมบูรณ์แล้ว</div>
            <ToggleSwitch checked={this.state.completed} onChange={this.props.confirmPurchasePrices} />
          </div>
        </div> */}
        {/* <div className="card-box px-3 py-2">{this.displayHeader()}</div> */}
        {/* <div className="card-box px-3 py-2 mt-1">{this.displayItems()}</div> */}
        <h5 className="pb-1 mt-4">ปรับแต่งราคาทุน</h5>
        <div className="col d-flex flex-row justify-content-end">
          <div className="mr-3">ราคาทุนสมบูรณ์แล้ว</div>
          <ToggleSwitch checked={this.state.completed} onChange={this.props.confirmPurchasePrices} />
        </div>
        {disabled_contract ? (
          <div className="color-red">
            <b>คำเตือน</b>: ถ้ามีการออกรหัสทรัพย์สินแล้ว จะไม่สามารถเปลี่ยนสัญญาที่มีสินค้าที่ออกรหัสทรัพย์สินแล้วได้
          </div>
        ) : (
          ''
        )}
        <OrderFormPurchaseCostItems {...this.props} onChangeVendor={this.onChangeVendor} vendor={this.state.vendor} disabled={disabled} />

        {/* Other Info */}
        <OrderFormOtherInfo page_nb={this.props.page_nb} note={this.props.order.order_notes.filter((elm) => elm.page_nb === this.props.page_nb)[0]} onChangeNote={this.props.onChangeNote} disabled={this.props.disabled} key={this.props.resetKeyNote} />
        <div className="block-info-buffer-box"></div>
      </React.Fragment>
    )
  }
}

import React from "react";

// Components
import ExportExcel from "../../../global/ExportExcel";

// Functions
import { formatApiDate } from "../../../../functions/Date";

export default function exportModal(props) {
  const { reportByBuyer } = props;
  const count = Object.keys(reportByBuyer).length;
  return (
    <ExportExcel
      data={[props.select_export]}
      content={`Export (${count})`}
      filename={`รายชื่อผู้ซื้อ_สถานี_${formatApiDate(props.selected_created_date_form)}_to_${formatApiDate(props.selected_created_date_to)}`}
      customClass={props.btnClass}
      onExport={props.onCloseProcurement}
      disabled={count === 0}
    />
  );
}

import React from "react";
import Modal from "react-modal";
import _ from "lodash";
import { MODAL_STYLE_LARGE_Z_999 } from "../../constants";
import { queryInArr } from "../../functions/Object";
import { ShoppingCartOutlined } from "@ant-design/icons";

const ITEM_TABS = {
  vendor: 0,
  dc: 1,
};

export class QuotationFormItemModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current_item_tab: ITEM_TABS.vendor,
      dcItemsLength: 0,
      vendorItemsLength: 0,

      parent_open_list: [],

      isCount: false,
    };
  }

  onChangeItemTab(index) {
    this.setState({ current_item_tab: index });
  }

  displayItemTabs(item_text, item_tab_name) {
    let { current_item_tab } = this.state;
    return (
      <li className="nav-item">
        <a className={`nav-link px-2 ${current_item_tab === ITEM_TABS[item_tab_name] ? "active" : ""}`} onClick={() => this.onChangeItemTab(ITEM_TABS[item_tab_name])}>
          {item_text}
        </a>
      </li>
    );
  }

  onClickOpenParent(id) {
    const { parent_open_list } = this.state;

    let new_open_list = [...parent_open_list];

    if (new_open_list.includes(id)) {
      var index = new_open_list.indexOf(id);
      new_open_list.splice(index, 1);
    } else {
      new_open_list.push(id);
    }

    this.setState({ parent_open_list: new_open_list });
  }

  displayVendorItemList() {
    let { multi_items, modal_multi_add_query, multi_item_cate_ids } = this.props;
    const { parent_open_list } = this.state;
    let query_items = multi_items;
    if (modal_multi_add_query !== "") {
      query_items = queryInArr(multi_items, modal_multi_add_query, ["code", "name"]);
    }

    let filterOnlyVendor = [];

    query_items.forEach((val) => {
      if (val?.item_category?.dc_id === 0) {
        if (modal_multi_add_query !== "") {
          if (val.from_parent) {
            const find_parent = multi_items.filter((m_item) => m_item.is_parent && m_item?.parent_item_ids && m_item?.parent_item_ids?.includes(val.id));

            find_parent.map((parent) => filterOnlyVendor.push(parent));
          }

          if (val.is_parent) {
            const find_sub_item = multi_items.filter((m_item) => m_item.from_parent && val.parent_item_ids.includes(m_item.id));
            find_sub_item.forEach((s_item) => filterOnlyVendor.push(s_item));
          }
        }

        filterOnlyVendor.push(val);
      }
    });

    if (modal_multi_add_query !== "") {
      filterOnlyVendor = filterOnlyVendor.map((filter, f_index) => {
        if (filterOnlyVendor?.findIndex((f_data) => f_data?.id === filter?.id) === f_index) return filter;
        else return false;
      });
    }

    let vendorItemsByCategory = _.groupBy(filterOnlyVendor, function (value) {
      return value?.item_category_id;
    });

    return (
      <React.Fragment>
        <div className="d-flex flex-row my-2">
          <input className="form-control quo-search-box" onChange={this.props.onSearchMultiAddItems} placeholder="ค้นหา ชื่อ, รหัสสินค้า Vendor" value={modal_multi_add_query}></input>
        </div>
        <div className="d-flex flex-row mb-2">
          <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => this.props.onClickMultiItemHideAll(false)}>
            เปิดทั้งหมด
          </button>
          <button type="button" className="btn btn-outline-secondary btn-sm ml-2" onClick={() => this.props.onClickMultiItemHideAll(true)}>
            ปิดทั้งหมด
          </button>
        </div>
        <div className="overflow-y-auto overflow-x-hidden vh-70 div-hide-scrollbar" style={{ paddingBottom: "10vh" }}>


          {Object.keys(vendorItemsByCategory).map((key, index) => {
            let items = vendorItemsByCategory[key];

            if (!multi_item_cate_ids[key]) return false;

            return (
              <React.Fragment>
                <div className="card-box no-round mt-2 p-3 bg-lightblue03 font-weight-bold cursor-pointer" onClick={() => this.props.onClickMultiItemCategory(key)}>
                  <div className="row">
                    <div className="col-1 text-left">
                      <input className="my-auto" type="checkbox" checked={multi_item_cate_ids[key]?.is_selected} onClick={(e) => this.props.onClickMultiItemCate(e, key)} />
                    </div>
                    <div className="col-7 ">{multi_item_cate_ids[key]?.name}</div>
                    <div className="row col-4 pr-0">
                      <div className="col text-right pr-0">
                        <i className={`${multi_item_cate_ids[key]?.is_hidden ? "icon-arrow-down" : "icon-arrow-up"}`} />
                      </div>
                    </div>
                  </div>
                </div>
                {!multi_item_cate_ids[key].is_hidden ? (
                  items.map((item_in_cate, sub_index) => {
                    let sub_item = [];

                    if (item_in_cate.from_parent) return false;
                    if (item_in_cate.parent_item_ids !== null && item_in_cate.parent_item_ids.filter((value) => value === null).length > 0) return false;
                    if (item_in_cate.parent_item_ids === null && item_in_cate.is_parent === true) return false
                    if (item_in_cate.is_parent) sub_item = items.filter((item) => item_in_cate.parent_item_ids?.find((id) => item.id === id));

                    return (
                      <div className={`${item_in_cate.is_parent && !parent_open_list.includes(item_in_cate.id) ? "is_parent_item" : "is_parent_item-open"}`}>
                        <div className={"mt-1 link-no-hover cursor-pointer"} style={{ position: "relative", zIndex: 0 }} onClick={() => (item_in_cate.is_parent ? {} : this.props.onClickMultiItem(item_in_cate.id))}>
                          <div
                            className="row"
                            style={{
                              paddingLeft: "16px",
                              paddingRight: "16px",
                            }}
                          >
                            <div className="col-1 text-left" style={{ gap: 10, display: "flex" }}>
                              {item_in_cate.is_parent ? (
                                parent_open_list.includes(item_in_cate.id) ? (
                                  <div onClick={() => this.onClickOpenParent(item_in_cate.id)}>
                                    <i className="icon-arrow-up"></i>

                                  </div>
                                ) : (
                                  <div onClick={() => this.onClickOpenParent(item_in_cate.id)}>
                                    <i className="icon-arrow-down"></i>
                                  </div>
                                )
                              ) : (
                                ""
                              )}
                              <input
                                style={{ position: "relative", zIndex: 1 }}
                                type="checkbox"
                                checked={item_in_cate.is_selected}
                                onChange={item_in_cate.is_parent ? () => this.props.onClickMultiItem(item_in_cate.id) : (e) => { }}
                                ref={(input) => {
                                  if (input) {
                                    if (item_in_cate.is_parent) {
                                      const is_sub_select = this.props.sub_items_selected.filter((s_item) => s_item.parent_item_id === item_in_cate.id);


                                      input.indeterminate = is_sub_select?.length > 0;
                                      if (is_sub_select?.length === item_in_cate?.parent_item_ids?.length) {
                                        input.indeterminate = false;
                                        input.checked = true;
                                      } else if (is_sub_select.length === 0) {
                                        input.indeterminate = false;
                                        input.checked = false;
                                      }
                                    }
                                  }
                                }}
                              />
                            </div>
                            <div className="col-2" onClick={() => this.onClickOpenParent(item_in_cate.id)}>
                              {item_in_cate.code}
                            </div>
                            <div className="col-8" onClick={() => this.onClickOpenParent(item_in_cate.id)}>
                              {item_in_cate.name}
                            </div>
                            {item_in_cate.is_parent && <b style={{ color: "lightgreen" }}>Parent Item</b>}
                          </div>
                        </div>
                        {sub_item.map((s_item) => {
                          return (
                            <div className={"ml-5 mt-1 link-no-hover cursor-pointer"} onClick={() => this.props.onSelectSubItem(s_item.id, item_in_cate.id)}>
                              <div
                                className="row mr-5"
                                style={{
                                  paddingLeft: "16px",
                                  paddingRight: "16px",
                                }}
                              >
                                <div className="col-1 text-left">
                                  <input type="checkbox" checked={this.props.sub_items_selected.find((sub_item) => sub_item.parent_item_id === item_in_cate.id && sub_item.sub_item_id === s_item.id)} onChange={(e) => { }} />
                                </div>
                                <div className="col-2">{s_item.code}</div>
                                <div className="col-9">{s_item.name}</div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  displayDcItemList() {
    let { multi_items, modal_multi_add_query, multi_item_cate_ids } = this.props;
    let query_items = multi_items;
    if (modal_multi_add_query !== "") {
      query_items = queryInArr(multi_items, modal_multi_add_query, ["code", "name"]);
    }

    let filterOnlyDc = [];

    query_items.forEach((val) => {
      if (val.item_category.dc_id === 1) {
        filterOnlyDc.push(val);
      }
    });

    let vendorItemsByCategory = _.groupBy(filterOnlyDc, function (value) {
      return value.item_category_id;
    });

    return (
      <React.Fragment>
        <div className="d-flex flex-row my-2">
          <input className="form-control quo-search-box" onChange={this.props.onSearchMultiAddItems} placeholder="ค้นหา ชื่อ, รหัสสินค้า DC" value={modal_multi_add_query}></input>
        </div>
        <div className="d-flex flex-row mb-2">
          <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => this.props.onClickMultiItemHideAll(false)}>
            เปิดทั้งหมด
          </button>
          <button type="button" className="btn btn-outline-secondary btn-sm ml-2" onClick={() => this.props.onClickMultiItemHideAll(true)}>
            ปิดทั้งหมด
          </button>
        </div>
        <div className="overflow-y-auto overflow-x-hidden vh-70 div-hide-scrollbar" style={{ paddingBottom: "10vh" }}>
          {Object.keys(vendorItemsByCategory).map((key, index) => {
            let items = vendorItemsByCategory[key];
            return (
              <React.Fragment>
                <div className="card-box no-round mt-2 p-3 bg-lightblue03 font-weight-bold cursor-pointer" onClick={() => this.props.onClickMultiItemCategory(key)}>
                  <div className="row">
                    <div className="col-1 text-left">
                      <input className="my-auto" type="checkbox" checked={multi_item_cate_ids[key].is_selected} onClick={(e) => this.props.onClickMultiItemCate(e, key)} />
                    </div>
                    <div className="col-7 ">
                      <div
                        className="row justify-content-between"
                        style={{
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                      >
                        <div>{multi_item_cate_ids[key].name}</div>
                        <div>สินค้าคงเหลือในคลัง :</div>
                      </div>
                    </div>
                    <div className="row col-4 pr-0">
                      <div className="col-5 text-center" style={{ color: "#3B9E42" }}>
                        Active
                      </div>
                      <div className="col-5 text-center" style={{ color: "#FF0000" }}>
                        Reserved
                      </div>
                      <div className="col-2 text-right pr-0">
                        <i className={`${multi_item_cate_ids[key].is_hidden ? "icon-arrow-down" : "icon-arrow-up"}`} />
                      </div>
                    </div>
                  </div>
                </div>
                {!multi_item_cate_ids[key].is_hidden
                  ? items.map((item_in_cate, sub_index) => {
                    return (
                      <div className={"mt-1 link-no-hover cursor-pointer"} onClick={() => this.props.onClickMultiItem(item_in_cate.id, true)}>
                        <div
                          className="row"
                          style={{
                            paddingLeft: "16px",
                            paddingRight: "16px",
                          }}
                        >
                          <div className="col-1 text-left">
                            <input type="checkbox" checked={item_in_cate.is_selected} onChange={(e) => { }} />
                          </div>
                          <div className="col-2">{item_in_cate.code}</div>
                          <div className="col-5">{item_in_cate.name}</div>
                          <div className="row col-4">
                            <div className="col-5 text-center" style={{ color: "#3B9E42" }}>
                              {item_in_cate.active}
                            </div>
                            <div className="col-5 text-center" style={{ color: "#FF0000" }}>
                              {item_in_cate.reserved}
                            </div>
                            <div className="col-2" />
                          </div>
                        </div>
                      </div>
                    );
                  })
                  : ""}
              </React.Fragment>
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} contentLabel="onMultiAddItems" onRequestClose={this.props.handleCloseMultiAddItemsModal} style={MODAL_STYLE_LARGE_Z_999}>
        <div className="d-flex flex-column">
          <div className="d-flex flex-row mb-2" style={{ alignItems: "center" }}>
            <h5 className="pb-1 mb-0 mr-auto">เพิ่มรายการสินค้า</h5>
            <span style={{ display: "flex", gap: 10, fontWeight: "bold", fontSize: 16 }}>
              <ShoppingCartOutlined style={{ fontSize: "24px" }} />

              <span>สินค้าผู้ผลิต x{this.props.select_multi_item_vendor}</span>
              <span>|</span>
              <span> สินค้า DC x{this.props.select_multi_item_dc}</span>
            </span>

            <button type="button" className="btn btn-primary ml-2" onClick={this.props.onAddMultiItems}>
              เพิ่มสินค้า
            </button>
            <button type="button" className="btn btn-secondary ml-2" onClick={this.props.handleCloseMultiAddItemsModal}>
              ยกเลิก
            </button>
          </div>
          <div className="d-flex flex-row mb-2">
            <ul className="nav nav-tabs tab-responsive">
              {this.displayItemTabs("สินค้า Vendor", "vendor")}
              {this.displayItemTabs("สินค้า DC", "dc")}
            </ul>
          </div>
          {this.state.current_item_tab === ITEM_TABS.vendor ? this.displayVendorItemList() : this.displayDcItemList()}
        </div>
      </Modal>
    );
  }
}

import React from 'react'
import PropTypes from "prop-types"

import SearchBar from './SearchBar'

export default function SearchBarContainer(props) {
  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      props.onEnter()
    }
  }

  return (
    <SearchBar
      {...props}
      onKeyDown={onKeyDown}
      />
  )
}

SearchBarContainer.propTypes = {
  onChangeQuery: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
  onClickClear: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  placeholder: PropTypes.string,
}

SearchBarContainer.defaultProps = {
  query: "",
  placeholder: "ค้นหา..."
}
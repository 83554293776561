import React from "react";
import { Table } from "antd";
// import "antd/dist/antd.css";
import moment from "moment";

class DCLogTable extends React.Component {
  constructor(props) {
    super();
    this.state = {
      someKey: "someValue",
    };
    this.dataSource = [
      {
        date: "2022-02-41",
        name: "John Brown",
        added: 32,
        removed: 32,
        diff: 32,
        changed_stock_percent: 32,
        changed_added_percent: 32,
        changed_removed_percent: 32,
      },
    ];

    this.columns = [
      {
        title: "วันที่",
        dataIndex: "date",
        key: "date",
        sorter: (a, b) =>
          moment(a.date, "DD/MM/YYYY").unix() -
          moment(b.date, "DD/MM/YYYY").unix(),
      },
      {
        title: "สินค้า",
        dataIndex: "item_name",
        key: "item_name",
      },
      {
        title: "ยอดคงเหลือก่อนหน้า",
        dataIndex: "begin_stock",
        key: "begin_stock",
        sorter: (a, b) => a.begin_stock - b.begin_stock,
      },
      {
        title: "จำนวนรับเข้า",
        dataIndex: "added",
        key: "added",
        sorter: (a, b) => a.added - b.added,
      },
      {
        title: "จำนวนจำหน่ายออก",
        dataIndex: "removed",
        key: "removed",
        sorter: (a, b) => a.removed - b.removed,
      },
      {
        title: "ยอดสุทธิสิ้นวัน",
        dataIndex: "end_stock",
        key: "end_stock",
        sorter: (a, b) => a.end_stock - b.end_stock,
      },
      {
        title: "สิ้นค้าคงคลังเปลี่ยนแปลง",
        dataIndex: "changed_stock_percent",
        key: "changed_stock_percent",
        sorter: (a, b) => a.csp - b.csp,
      },
      {
        title: "รับเข้าเปลี่ยนแปลง",
        dataIndex: "changed_added_percent",
        key: "changed_added_percent",
        sorter: false,
      },
      {
        title: "จำหน่ายออกเปลี่ยนแปลง",
        dataIndex: "changed_removed_percent",
        key: "changed_removed_percent",
        sorter: false,
      },
    ];
  }

  render() {
    return (
      <div>
        <Table
          dataSource={this.dataSource}
          columns={this.columns}
          pagination={{ position: ["topRight"], defaultPageSize: 15 }}
        />
      </div>
    );
  }

  componentDidMount() {
    this.setState({
      someKey: "otherValue",
    });
    this.dataSource = this.props.data;
  }
  componentDidUpdate() {
    this.dataSource = this.props.data;
  }
}

DCLogTable.defaultProps = {
  test: "default test",
  data: [],
};

export default DCLogTable;

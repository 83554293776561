import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import _ from "lodash";

// Components
import ContractBudgetReport from "./ContractBudgetReport";

// Functions
import { uniqueKey } from "../../../functions/Random";
import { getHeaderTotalPageCount } from "../../../functions/Response";

// Models
import { toExcelContractBudgetReport } from "../../../models/Reports";

import { ORDER_STATES, API_RESOURCES } from "../../../constants";

const DEFAULT_SORTING = "created_date";
const OPTIONS_STATES = [{ value: "pending order", label: "pending order" }];
Object.keys(ORDER_STATES).forEach((key) => {
  OPTIONS_STATES.push({ value: ORDER_STATES[key].name, label: ORDER_STATES[key].name });
});

let searchParams = {};

class ContractBudgetReportContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: false,
      active_filter: null,
      selected_contract: "",
      selected_vendor: { id: null },
      selected_cate: { id: null },

      selected_date_form: new Date(new Date().getFullYear(), 0, 1), //getDateFromToday(-30),
      selected_date_to: new Date(),

      contracts: [],
      is_refiltering: true,

      // pagination
      page: 0,
      per_page: 100,
      page_count: 1,

      resetKey: uniqueKey(),

      // export
      export_report: null,
    };
  }

  componentDidMount() {
    if (this.props.location.search) {
      this.getPathParams();
    } else {
      this.getContract();
    }
  }

  componentDidUpdate() {
    if (this.state.is_refiltering) {
      this.getContract();
    }
  }

  getContract(resetPage) {
    // filter: {}
    // range: [0,9]
    // sort: ["code","ASC"]
    let {
      active_filter,
      page,
      per_page,
      selected_contract,
      selected_cate,
      selected_date_form,
      selected_date_to,
      selected_vendor,
    } = this.state;
    let de_per_page = per_page;
    let target_page = resetPage ? 0 : page;
    let range_start = target_page * de_per_page;
    let options = {
      filter: {},
      range: JSON.stringify([range_start, range_start + per_page - 1]),
    };

    if (selected_contract) {
      options.filter.query = selected_contract;
    }
    if (selected_vendor.id) {
      options.filter.vendor_id = selected_vendor.id;
    }
    if (selected_cate.id) {
      options.filter.item_category_id = selected_cate.id;
    }
    if (active_filter !== null) {
      options.filter.is_active = active_filter;
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.contract}/report`, {
        params: options,
      })
      .then((response) => {
        let page_count = getHeaderTotalPageCount(response, this.state.per_page);
        const { approval_person, ...res_contract_data } = response.data;
        let contract_data = res_contract_data;
        // console.log('response => ', contract_data)
        Object.keys(contract_data).forEach((key) => {
          let items = [];
          Object.keys(contract_data[key]).forEach((item) => {
            if (
              contract_data[key][item]["contract_responsible_persons"] &&
              contract_data[key][item]["contract_responsible_persons"]?.length > 0
            ) {
              const checkHasSomeName = contract_data[key][item]["contract_responsible_persons"].some(
                (obj) => obj.name !== null
              );
              const checkHasSomeResourceType = contract_data[key][item]["contract_responsible_persons"].some(
                (obj) => obj.resource_type !== null
              );
              if (checkHasSomeName) {
                const reduceName = contract_data[key][item]["contract_responsible_persons"]
                  .filter((el) => el.name !== null)
                  .map((el) => {
                    if (el.name) {
                      return el.name;
                    }
                  })
                  .join(", ");
                contract_data[key][item]["contract_responsible_persons"] = `${reduceName}`;
              } else if (checkHasSomeResourceType) {
                const reduceResourceType = contract_data[key][item]["contract_responsible_persons"]
                  .filter((el) => el.resource_type !== null)
                  .map((el) => {
                    if (el.resource_type.includes("am")) {
                      return "AM";
                    } else if (el.resource_type.includes("eng")) {
                      return "วฟ.";
                    } else if (el.resource_type.includes("main")) {
                      return "ซฟ.";
                    }
                  })
                  .join(", ");
                contract_data[key][item]["contract_responsible_persons"] = `${reduceResourceType}`;
              }
            } else {
              contract_data[key][item]["contract_responsible_persons"] = "-";
            }
            items.push({
              ...contract_data[key][item],
              is_opened: false,
            });
          });
          contract_data[key] = items;
        });
        let export_report = toExcelContractBudgetReport(contract_data, approval_person);
        this.setState({
          contracts: contract_data,
          export_report: export_report,
          is_refiltering: false,
          page_count: page_count,
          resetKey: resetPage ? uniqueKey() : this.state.resetKey,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          is_refiltering: false,
        });
      })
      .then(() => {
        // always executed
      });
  }

  onClickClear = () => {
    this.setState({
      selected_period: "",
      selected_id: "",

      selected_contract: "",
      selected_vendor: { id: null },
      selected_cate: { id: null },

      selected_sorting: DEFAULT_SORTING,
      selected_sorting_reversed: true,
      selected_date_form: new Date(new Date().getFullYear(), 0, 1), //getDateFromToday(-30),
      selected_date_to: new Date(),
      page: 0,
      is_refiltering: true,
      resetKey: uniqueKey(),
    });

    searchParams = {};
    this.props.history.replace({ pathname: `${this.props.location.pathname}`, search: null });
  };

  onChangeSubmittedFromDate = (date) => {
    this.setState({ selected_date_form: date });
  };

  onChangeSubmittedToDate = (date) => {
    this.setState({ selected_date_to: date });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.addSearchParams(searchParams);
    this.setState({ is_refiltering: true });
  };

  onClickSelectContract = (value, e) => {
    let selected_contract = e;
    selected_contract.id = value;
    this.setState({ selected_contract: selected_contract });
  };

  onClickSelectVendor = (value, e) => {
    let selected_vendor = e;
    selected_vendor.id = value;

    searchParams["vendor"] = value;

    this.setState({ selected_vendor: selected_vendor });
  };

  onClickSelectCate = (value, e) => {
    let selected_cate = e;
    selected_cate.id = value;

    searchParams["category"] = value;

    this.setState({ selected_cate: selected_cate });
  };

  onAddContract = (e) => {
    if (e.target.value) {
      searchParams["contract"] = e.target.value;
    } else {
      delete searchParams["contract"];
    }
    this.setState({ selected_contract: e.target.value });
  };

  onClickContractGroup = (key) => {
    let { contracts } = this.state;
    Object.keys(contracts).forEach((contract_code) => {
      for (let contract of contracts[contract_code]) {
        if (contract.id === key) {
          contract.is_opened = !contract.is_opened;
        }
      }
    });
    this.setState({ contracts: contracts });
  };

  onClickFilterStatus = (status) => {
    const { active_filter } = this.state;

    this.setState({ active_filter: status === active_filter ? null : status });
    if (status === active_filter) {
      delete searchParams["status"];
    } else {
      searchParams["status"] = status;
    }
  };

  getPathParams() {
    const contract = new URLSearchParams(this.props.location.search).get("contract") || null;
    const category = new URLSearchParams(this.props.location.search).get("category") || null;
    const vendor = new URLSearchParams(this.props.location.search).get("vendor") || null;
    const status = new URLSearchParams(this.props.location.search).get("status") || null;

    if (contract) searchParams["contract"] = contract;
    if (category) searchParams["category"] = category;
    if (vendor) searchParams["vendor"] = vendor;
    if (status) searchParams["status"] = status;

    this.setState({
      selected_contract: contract,
      selected_cate: category ? { id: parseInt(category) } : { id: null },
      selected_vendor: vendor ? { id: parseInt(vendor) } : { id: null },
      active_filter: status !== null ? status === "true" : null,
    });
  }

  addSearchParams(params, path = this.props.location.pathname) {
    let searchParams = "";
    Object.keys(params).forEach((key, index) => {
      searchParams = searchParams + `${Object.keys(params).length > 1 && index !== 0 ? "&" : ""}${key}=${params[key]}`;
    });

    this.props.history.replace({ pathname: `${path}`, search: searchParams });
  }

  render() {
    return (
      <ContractBudgetReport
        {...this.state}
        setState={this.setState}
        onClickClear={this.onClickClear}
        onChangeSubmittedFromDate={this.onChangeSubmittedFromDate}
        onChangeSubmittedToDate={this.onChangeSubmittedToDate}
        handleSubmit={this.handleSubmit}
        onClickSelectContract={this.onClickSelectContract}
        onClickSelectVendor={this.onClickSelectVendor}
        onClickSelectCate={this.onClickSelectCate}
        onAddContract={this.onAddContract}
        onClickContractGroup={this.onClickContractGroup}
        onClickFilterStatus={this.onClickFilterStatus}
      />
    );
  }
}
export default withRouter(ContractBudgetReportContainer);

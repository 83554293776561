import React from "react"
import axios from 'axios';
import { withRouter } from "react-router-dom";

// Global Components
import ErrorMessage from '../components/global/ErrorMessage'
import Spinner from '../components/global/Spinner'

// Global Functions
import { validateInputFromRequireFields } from '../functions/Validation'
import { objectIsEmpty } from '../functions/Object'

// Model
import { requiredPasswordPolicies, validatePassword } from '../models/Users'

const requiredFields = {
  'email': "email (ชื่อผู้ใช้)",
  'firstname': "ชื่อ",
  'lastname': "นามสกุล",
}

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: props.current_user,
      is_loading: false,
      errors: {},
    }

    this.onChangeDetails = this.onChangeDetails.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  validateInputs(user) {
    // validate required fields
    let errors = validateInputFromRequireFields(user, requiredFields)

    // validate new password
    if (objectIsEmpty(errors)) {
      errors = validatePassword(user)
    }

    return errors
  }

  onSubmit() {
    let { user } = this.state
    let errors = this.validateInputs(user)

    if (objectIsEmpty(errors)) {
      let data = {
        firstname: user.firstname,
        lastname: user.lastname
      }

      if (user.new_password) {
        data.password = user.new_password
      }
  
      axios.put(`${process.env.REACT_APP_API_URL}/users/profile/${user.id}`, data)
      .then((response) => {
        alert("saved!")
        user.new_password = ''
        user.confirm_new_password = ''
        this.setState({ is_loading: false, user: user, errors: {} })
      })
      .catch((error) => {
        console.log(error);
        this.setState({ is_loading: false })
      });
      this.setState({ is_loading: true })
    } else {
      this.setState({ errors: errors })
    }
  }

  onChangeDetails(e) {
    let user = this.state.user
    user = {
      ...user,
      [e.target.name]: e.target.value
    }
    this.setState({user: user})
  }

  display_password_policies() {
    let policies = Object.keys(requiredPasswordPolicies).map(key => {
      return (
        <div className="col-12 ml-3"><li>{requiredPasswordPolicies[key]}</li></div>
      )
    })
    return policies
  }

  render() {
    return (
      <React.Fragment>
        <Spinner loading={this.state.is_loading}/>
        <div className="pb-3 px-3">
          <div className={'offset-lg-1 col-lg-10 px-3 block-info'}>
            <div className="row">
              <div className="col-6">
                <h2>Profile</h2>
              </div>
              <div className="col-6 mt-2 text-right">
                <button type="button" className="btn btn-primary btn-sm" onClick={this.onSubmit}>Save</button>
                <button type="button" className="btn btn-outline-primary ml-2 btn-sm" onClick={this.props.history.goBack}>Back</button>
              </div>
            </div>
            <div className="card-box p-3 mt-1">
              <div className="row my-2">
                <div className="col-4 col-md-3 pr-0"><label>ผู้ดูแลระบบ</label></div>
                <div className="col">
                  <div className="form-check">
                    <input type="checkbox" checked={this.state.user.is_admin} className="form-check-input"
                          id="user_is_admin" name="is_admin" readOnly></input>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4 col-md-3 pr-0 mt-2"><label>Email</label></div>
                <div className="col-8 col-md-6 mt-2">
                  <input type="text" className="form-control" id="user_firstname" name="firstname"
                        value={this.state.user.email} readOnly></input>
                </div>
              </div>
              <div className="row">
                <div className="col-4 col-md-3 pr-0 mt-2"><label className="sign-is-required">{requiredFields['firstname']}</label></div>
                <div className="col-8 col-md-6 mt-2">
                  <input type="input" className="form-control" id="user_firstname" name="firstname" autocomplete="off"
                          onChange={this.onChangeDetails} value={this.state.user.firstname} required></input>
                  <ErrorMessage errorKey={'firstname'} errorMessages={this.state.errors}/>
                </div>
              </div>
              <div className="row">
                <div className="col-4 col-md-3 pr-0 mt-2"><label className="sign-is-required">{requiredFields['lastname']}</label></div>
                <div className="col-8 col-md-6 mt-2">
                  <input type="input" className="form-control" id="user_lastname" name="lastname" autocomplete="off"
                          onChange={this.onChangeDetails} value={this.state.user.lastname} required></input>
                  <ErrorMessage errorKey={'lastname'} errorMessages={this.state.errors}/>
                </div>
              </div>

            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <h5>เปลี่ยน Password</h5>
              </div>
            </div>
            <div className="card-box p-3 mt-1">
              <div className="row font-size-13px">
                <div className="col-12"><p>ข้อกำหนด</p></div>
                {this.display_password_policies()}
              </div>
              <div className="row mt-4">
                <div className="col-4 col-md-3 pr-0 mt-2"><label>New Password</label></div>
                <div className="col-8 col-md-6 mt-2">
                  <input type="password" className="form-control" id="user_new_password" name="new_password" autocomplete="new-password"
                          onChange={this.onChangeDetails} value={this.state.user.new_password} required></input>
                  <ErrorMessage errorKey={'new_password'} errorMessages={this.state.errors}/>
                </div>
              </div>
              <div className="row">
                <div className="col-4 col-md-3 mt-2"><label>Confirm New Password</label></div>
                <div className="col-8 col-md-6 mt-2">
                  <input type="password" className="form-control" id="user_confirm_new_password" name="confirm_new_password" autocomplete="new-password"
                          onChange={this.onChangeDetails} value={this.state.user.confirm_new_password} required></input>
                  <ErrorMessage errorKey={'confirm_new_password'} errorMessages={this.state.errors}/>
                </div>
              </div>
            </div>
          </div>
        </div>

      </React.Fragment>
    );
  }
}


export default withRouter(Profile);
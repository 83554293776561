import React from "react";
import ProductNoticeCollapse from "../../global/ProductNoticeCollapse";
import EmptyIcon from "../../../assets/images/empty-icon.png";

export default function DcInboundOrderFormInform({ match, addList, onChangeInstallationDate, onChangeInformedDate, onGetWarrantyCode, onDeleteWarrantyCode }) {
  function DisplayHeader() {
    return (
      <section>
        {/* Header Section */}
        <section>
          <div className="card-box p-2 d-none d-md-block">
            <div className="row px-3">
              <div className="col-1 m-auto text-left">ลำดับ</div>
              <div className="col-11">หมวดหมู่ - สินค้า</div>
            </div>
          </div>
        </section>
        {/* Categoty List Section */}
        <section>
          {addList.length > 0 ? (
            addList.map((listItem) => {
              return (
                <ProductNoticeCollapse
                  onDeleteWarrantyCode={onDeleteWarrantyCode}
                  onGetWarrantyCode={onGetWarrantyCode}
                  match={match}
                  category={listItem}
                  onChangeInstallationDate={onChangeInstallationDate}
                  onChangeInformedDate={onChangeInformedDate}
                />
              );
            })
          ) : (
            <div className="empty-content">
              <img src={EmptyIcon} width={50} height={50} />
              <span>กรุณาเลือกสินค้า</span>
            </div>
          )}
        </section>
      </section>
    );
  }

  return <div class="item-inform-container pb-3 pb-md-4 px-3 px-md-4">{DisplayHeader()}</div>;
}

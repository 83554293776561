import { Button, Divider, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { BlockOutlined } from "@ant-design/icons";

export default function QuotationModalDuplicate({ isOpen, onCancelShowDuplicateModal, itemDuplicateList }) {
  return (
    <Modal width={700} open={isOpen} centered footer={false} onCancel={() => onCancelShowDuplicateModal(false)}>
      <h4 style={{ display: "flex", gap: 10, alignItems: "center" }}>
        <BlockOutlined style={{ fontSize: 28, color: "#007bff" }} />
        รายการสินค้าซ้ำ
      </h4>
      <Divider style={{ margin: "15px 0px" }} />
      <div style={{ padding: "0px 15px" }}>
        <div className="duplicate-modal-table" style={{ fontSize: 16 }}>
          <center>
            <b>ลำดับ</b>
          </center>
          <b>ชื่อสินค้า</b>
          <center>
            <b>ใบเสนอราคา</b>
          </center>
        </div>
        {itemDuplicateList.map((item, index) => {
          return (
            <div className="duplicate-modal-table">
              <center>{index + 1}.</center>
              <div>
                {item.item_category.dc_id === 1 ? `(DC)` : `(Vendor)`} {item.code ? `${item.code} - ` : ""}
                {item.name}
              </div>
              <center>
                <a href={`/quotations/${item.quotation_duplicate_id}`} target={"_blank"}>
                  คลิก
                </a>
              </center>
            </div>
          );
        })}
        <Divider style={{ margin: "15px 0px" }} />
        <Button
          onClick={() => onCancelShowDuplicateModal(false)}
          style={{ backgroundColor: "red", color: "white", width: "100%" }}
        >
          ปิด
        </Button>
      </div>
    </Modal>
  );
}

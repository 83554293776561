import React from "react"
import PropTypes from "prop-types"
import Select from 'react-select'
import axios from 'axios';

class ContractSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      contracts: [],
      isMounted: false
    }
    this.contractSelected = this.contractSelected.bind(this)
  }

  componentDidMount() {
    let { contractId, vendorId } = this.props
    if (contractId) {
      this.getContractFromId(contractId)
    } else {
      if (vendorId) {
        this.getCollection()
      }
    }
  }

  setOptions(response) {
    let options = []
    response.data.map(e => {
      options.push({
        id: e.id, text: e.name, value: e.id,
        code: e.code, from_date: e.from_date,
        nb_days_to_complete: e.nb_days_to_complete
      })
    })
    this.setState({
      contracts: options,
      isMounted: true
    })
  }

  getContractFromId(contractId) {
    axios.get(`${process.env.REACT_APP_API_URL}/contracts/${contractId}`)
      .then((response) => {
        let options = [{ id: response.data.id, text: response.data.name, value: response.data.id }]
        this.setState({
          contracts: options,
          isMounted: true
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  getCollection(query) {
    let params = {
      filter: { is_active: true }
    }
    if (query) {
      params.filter.name = query
    }
    if (this.props.vendorId) {
      params.filter.vendor_id = this.props.vendorId
    }

    axios.get(`${process.env.REACT_APP_API_URL}/contracts`, {
      params: params
    })
      .then((response) => {
        this.setOptions(response)
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  contractSelected(e) {
    let value = e.value
    if (value !== this.props.contractId && this.state.isMounted) {
      this.props.onChange(value, e)
    }
  }

  render() {
    return (
      <React.Fragment>
        <Select
          className="select2-style"
          onChange={this.contractSelected}
          getOptionLabel={option =>
            `${option.text}`
          }
          value={this.state.contracts.filter(option => option.value === this.props.contractId)}
          getOptionValue={option => `${option.id}`}
          options={this.state.contracts}
          isDisabled={this.props.disabled}
          placeholder={'ค้นหา...'}
        />
      </React.Fragment>
    );
  }
}

ContractSelect.propTypes = {
  contractId: PropTypes.number,
  vendorId: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export default ContractSelect

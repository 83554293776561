import React from 'react'
import PropTypes, { oneOfType } from 'prop-types'
import Select from 'react-select'
import axios from 'axios'

import { API_RESOURCES } from '../../constants.js'

let inputChangeTimeOut = null
class InboundOrderSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      inbound_orders: [],
      isMounted: false,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.orderSelected = this.orderSelected.bind(this)
  }

  componentDidMount() {
    let { orderId } = this.props
    if (orderId) {
      this.getInboundOrderFromId(orderId)
    } else {
      this.getCollection()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.orderId != this.props.orderId && this.state.isMounted) {
      let selectOption = this.state.inbound_orders.filter((option) => option.value == this.props.orderId)[0]
      this.props.onChange(this.props.orderId, selectOption)
    }
  }

  handleInputChange(value) {
    clearTimeout(inputChangeTimeOut)
    inputChangeTimeOut = setTimeout(() => {
      if (value !== '') {
        this.getCollection(value)
      }
    }, 500)
  }

  getOption(obj) {
    return {
      id: obj.id,
      text: `${obj.code}`,
      value: obj.id,
      order: obj,
    }
  }

  setOptions(response) {
    let options = []
    response.data.map((e) => {
      options.push(this.getOption(e))
    })
    this.setState({
      inbound_orders: options,
      isMounted: true,
    })
  }

  getInboundOrderFromId(orderId) {
    axios
      .get(`${process.env.REACT_APP_API_URL}/inbound/for_select/${orderId}`)
      .then((response) => {
        let options = [this.getOption(response.data)]
        this.setState({
          inbound_orders: options,
          isMounted: true,
        })
      })
      .catch(function (error) {
        console.log(error)
      })
      .then(function () {
        // always executed
      })
  }

  getCollection(query) {
    let params = {
      range: '[0, 9]',
      sort: JSON.stringify(['id', 'DESC']),
    }
    if (query) {
      params.filter = { query_for_select: query }
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/inbound/for_select`, {
        params: params,
      })
      .then((response) => {
        this.setOptions(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
      .then(function () {
        // always executed
      })
  }

  orderSelected(e) {
    if (e) {
      let value = e.value
      if (value !== this.props.orderId && this.state.isMounted) {
        this.props.onChange(value, e)
      }
    } else {
      this.props.onChange(null, e)
    }
  }

  render() {
    let { inbound_orders } = this.state

    return (
      <React.Fragment>
        <Select
          className="select2-style"
          onChange={this.orderSelected}
          onInputChange={this.handleInputChange}
          getOptionLabel={(option) => `${option.text}`}
          value={inbound_orders.filter((option) => option.value === this.props.orderId)}
          getOptionValue={(option) => `${option.id}`}
          options={inbound_orders}
          isDisabled={this.props.disabled}
          isClearable={true}
          placeholder={'ค้นหา...'}
        />
      </React.Fragment>
    )
  }
}

InboundOrderSelect.propTypes = {
  orderId: oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default InboundOrderSelect

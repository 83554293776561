import React, { useState, useEffect, useRef } from "react";
import SearchBar from "../../global/SearchBar";
import ReactPaginate from "react-paginate";

import Spinner from "../../global/Spinner.js";
import { API_RESOURCES, APP_TITLE_DC_INVENTORIES_MANAGEMENT, PER_PAGE } from "../../../constants.js";
import { Button, Form, Input, InputNumber, Modal, Select, DatePicker } from "antd";
import { AppstoreAddOutlined, OrderedListOutlined, MoreOutlined } from "@ant-design/icons";
import { getHeaderTotalPageCount } from "../../../functions/Response";
import { IconSorting } from "../../global/IconSorting";
import axios from "axios";
import "../DcInventoriesManagement.css";
import { NavLink } from "react-router-dom";
import moment from "moment";

const { TextArea } = Input;
const { Option } = Select;

export default function DcInventoriesManagementIndex({props}) {
  const [selectedSorting, setSelectedSorting] = useState("code");
  const [selectedSortingReversed, setSelectedSortingReversed] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(PER_PAGE);
  const [pageCount, setPageCount] = useState(1);
  const [isLoading, setIsLoading] = useState(1);
  const [itemDC, setItemDC] = useState();
  const [itemDCList, setItemDCList] = useState();
  const [contractList, setContractList] = useState(null);
  const [inboundInfo, setInboundInfo] = useState();
  const [itemSelected, setItemSelected] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);
  const [contractItemSelected, setContractItemSelected] = useState(null);

  const [routeSearch, setRouteSearch] = useState("");
  const [routeCategory, setRouteCategory] = useState("");

  function getQuerySearch(data){   
    let params = (data)?`search=${data}`:(routeSearch)?`search=${routeSearch}`:''
    let category = (routeCategory)?`&category=${routeCategory}`:'' 
    props.history.replace({
      pathname: `${props.location.pathname}`,
      search: `?${params}${category}`,
    });
  }
  function getQueryCategory(data){  
    let params = (data)?`category=${data}`:''
    let search = (routeSearch)?`&search=${routeSearch}`:'' 
    props.history.replace({
      pathname: `${props.location.pathname}`,
      search: `?${params}${search}`,
    });
  }

  function clearForm(){
    setRouteSearch('')
    setRouteCategory('') 
    props.history.replace({
      pathname: `${props.location.pathname}`,
      search: ``,
    });
 }


  const formRef = useRef();

  function onClickSort(type) {
    let new_toggle = selectedSorting === type ? !selectedSortingReversed : false;

    setSelectedSorting(type);
    setSelectedSortingReversed(new_toggle); 
  }

  useEffect(() => {
    getInventoryList();
  }, [selectedSorting,selectedSortingReversed]);

  function getFilter(){
    let filter = {}
    let search = new URLSearchParams(props.location.search).get('search')
    let category = new URLSearchParams(props.location.search).get('category')
    if(search){
      filter = {...filter, query: search}
      setRouteSearch(search)
    }
    if(category){
      filter = {...filter, item_category_id: Number(category)}
      setRouteCategory(category)
    }
    return filter
  }

  function getInventoryList() {
    let range_start = page * perPage;
    let sort_dir = selectedSortingReversed ? "desc" : "asc";
    let filter = getFilter()
    if(filter.item_category_id){
        setSelectedSorting('active')
    }
    let options = {
      sort: JSON.stringify([selectedSorting, sort_dir]),
      filter: filter,
      //range: JSON.stringify([range_start, range_start + perPage - 1]),
    };

    // if(selectedSorting == 'code'){
    //   delete options.range
    // }

    if(options.filter.query || options.filter.item_category_id){
      delete options.sort
      delete options.range
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/inventory_index`, { params: options })
      .then((response) => {
        let page_count = getHeaderTotalPageCount(response, perPage);

        if (response.data.code === 200) {
          setPageCount(page_count);
          setList(response.data.data);
          getInfoItems();
        }
      })
      .catch((err) => console.log("err", err));
  }

  function getInfoItems() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/inbound_info`)
      .then(async (response) => {
        if (response.status === 200) {
          setItemDC(response.data.item_category_dc);
          setItemDCList(response.data.item_dc);
          setInboundInfo(response.data.dc[0]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  function getContractItem(contractID) {
    setIsLoading(true);

    let params = {
      sort: JSON.stringify(["created_at", "DESC"]),
      filter: { id: contractID, for_mr: false, is_active: true, approved_date: moment().format("YYYY-MM-DD"), item_category_id: categorySelected, province_id: inboundInfo.address.province_id, item_ids: [itemSelected] },
    };

    return axios
      .get(`${process.env.REACT_APP_API_URL}/contract_by_cate`, {
        params: params,
      })
      .then((response) => {
        setIsLoading(false);
        const contractItemCate = response.data.contract_item_categories.find((contractItem) => contractItem.item_category_id === categorySelected);
        const constractItem = contractItemCate.contract_items.find((item) => item.item_id === itemSelected);
        setContractItemSelected(constractItem.id);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  function getContractList(categoryID) {
    setIsLoading(true);

    let params = {
      sort: JSON.stringify(["created_at", "DESC"]),
      filter: {
        is_active: true,
        for_mr: false,
        approved_date: moment().format("YYYY-MM-DD"),
        item_category_id: categoryID,
      },
    };

    return axios
      .get(`${process.env.REACT_APP_API_URL}/contract_options_by_cate`, {
        params: params,
      })
      .then((response) => {
        setIsLoading(false);
        return response.data;
      })
      .catch(function () {
        setIsLoading(false);
      });
  }

  function onClickSortDown(active, type) {
    let sortType = active ? type : "";

    setSelectedSorting(sortType);
    setSelectedSortingReversed(true);
  }

  function headerColWithSortIcon(text, sortText) {
    let active = selectedSorting === sortText;
    let sort_up = active && !selectedSortingReversed;
    let sort_down = active && selectedSortingReversed;

    return (
      <div className="d-flex flex-row cursor-pointer align-items-center" onClick={() => onClickSort(sortText)}>
        <div className="col-break-word text-bold">{text}</div>
        <IconSorting  type={sortText} key={Math.random() * 100} activeUp={sort_up ? true : undefined} activeDown={sort_down ? true : undefined} />
      </div>
    );
  }

  function DisplayTableHeader() {
    return (
      <div className="card-box mt-1 p-3 d-none d-md-block mt-3">
        <div className="row">
          <div className="col-12 d-flex flex-column flex-sm-row align-items-center">
            <div className="col-custom-18 col-custom-sm-100 px-1 py-2">{headerColWithSortIcon("รหัสสินค้า", "code")}</div>
            <div className="col-custom-18 col-custom-sm-100 px-1 py-2">ชื่อสินค้า</div>
            <div className="col-custom-18 col-custom-sm-100 px-1 py-2">หมวดหมู่สินค้า</div>
            <div className="col-custom-14 col-custom-sm-100 px-1 py-2 justify-content-center">{headerColWithSortIcon("Active", "active")}</div>
            <div className="col-custom-14 col-custom-sm-100 px-1 py-2 justify-content-center">{headerColWithSortIcon("Reserve", "reserve")}</div>
            <div className="col-custom-14 col-custom-sm-100 px-1 py-2 justify-content-center">{headerColWithSortIcon("Delivered", "deliver")}</div>
            <div className="col-custom-4 col-custom-sm-100 px-1 py-2"></div>
          </div>
        </div>
      </div>
    );
  }

  function DisplayTableItems() {
    return list.map((value) => {
      return (
        <NavLink to={`/dc_inventories_orders/${value.item_id}`}>
          <div className="card-box mt-1 px-3 pt-3 pb-2 link-no-hover cursor-pointer" key={value.code}>
            <div className="row mb-2">
              {/* md */}
              <div className="col-12 flex-custom-md flex-custom-sm-none flex-column flex-sm-row">
                <div className="col-custom-18 col-custom-sm-100 px-1">
                  <div className="col-break-word">{`${value.code ? value.code : "-"}`}</div>
                </div>
                <div className="col-custom-18 col-custom-sm-100 px-1">
                  <div className="col-break-word">{`${value.name ? value.name : "-"}`}</div>
                </div>
                <div className="col-custom-18 col-custom-sm-100 px-1">
                  <div className="col-break-word">{`${value.category_name ? value.category_name : "-"}`}</div>
                </div>
                <div className="col-custom-14 col-custom-sm-100 px-1 text-center">
                  <div className="col-break-word">{`${value.active ? value.active : "0"}`}</div>
                </div>
                <div className="col-custom-14 col-custom-sm-100 px-1 text-center">
                  <div className="col-break-word">{`${value.reserved ? value.reserved : "0"}`}</div>
                </div>
                <div className="col-custom-14 col-custom-sm-100 px-1 text-center">
                  <div className="col-break-word">{`${value.delivered ? value.delivered : "0"}`}</div>
                </div>
                <div className="col-custom-4 col-custom-sm-100 px-1 justify-content-end">
                  <MoreOutlined />
                </div>
              </div>

              {/* sm */}
              <div className="col-12 flex-custom-sm flex-custom-md-none flex-column flex-sm-row">
                <div className="col-custom-18 col-custom-sm-100 px-1">
                  <div className="col-break-word">
                    <span className="text-bold pr-2">รหัสสินค้า</span>
                    <span className="text-bold pr-2">:</span>
                    {`${value.code ? value.code : "-"}`}
                  </div>
                </div>
                <div className="col-custom-18 col-custom-sm-100 px-1">
                  <div className="col-break-word">
                    <span className="text-bold pr-2">ชื่อสินค้า</span>
                    <span className="text-bold pr-2">:</span>
                    {`${value.name ? value.name : "-"}`}
                  </div>
                </div>
                <div className="col-custom-18 col-custom-sm-100 px-1">
                  <div className="col-break-word">
                    <span className="text-bold pr-2">หมวดหมู่สินค้า</span>
                    <span className="text-bold pr-2">:</span>
                    {`${value.category_name ? value.category_name : "-"}`}
                  </div>
                </div>
                <div className="col-custom-14 col-custom-sm-100 px-1">
                  <div className="col-break-word">
                    <span className="text-bold pr-2">Active</span>
                    <span className="text-bold pr-2">:</span>
                    {`${value.active ? value.active : "0"}`}
                  </div>
                </div>
                <div className="col-custom-14 col-custom-sm-100 px-1">
                  <div className="col-break-word">
                    <span className="text-bold pr-2">Reserve</span>
                    <span className="text-bold pr-2">:</span>
                    {`${value.reserved ? value.reserved : "0"}`}
                  </div>
                </div>
                <div className="col-custom-14 col-custom-sm-100 px-1">
                  <div className="col-break-word">
                    <span className="text-bold pr-2">Delivered</span>
                    <span className="text-bold pr-2">:</span>
                    {`${value.delivered ? value.delivered : "0"}`}
                  </div>
                </div>
                <div className="col-custom-4 col-custom-sm-100 flex-row justify-content-end">
                  <MoreOutlined />
                </div>
              </div>
            </div>
          </div>
        </NavLink>
      );
    });
  }

  async function onSelectManualAddItem(e) {
    const itemID = parseInt(e.split("_")[0]);
    const itemCategoryID = parseInt(e.split("_")[1]);
    const contract_list = await getContractList(itemCategoryID);

    setItemSelected(itemID);
    setCategorySelected(itemCategoryID);
    setContractList(contract_list);
  }

  function onSubmitAddManualItem(values) {
    setIsLoading(true);
    const params = {
      qty: parseInt(values.qty),
      note: values.note,
      calc_unit_price: values.calc_unit_price,
      status: "active",
      item_id: itemSelected,
      contract_item_id: contractItemSelected,
      received_date: moment(values.received_date).format("YYYY-MM-DD"),
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/inventory_bulk`, params)
      .then(() => {
        setIsLoading(false);
        getInventoryList();
      })
      .catch(() => {
        setIsLoading(false);
      });

    onCloseModal(false);
  }

  function onCloseModal() {
    setVisibleModal(false);
    setItemSelected(null);
    setCategorySelected(null);
    setContractItemSelected(null);
    setContractList(null);
    formRef.current.resetFields();
  }

  function DisplayModalAddItem() { 
    return (
      <Modal className="modalstyle-dc" open={visibleModal} closable={false} centered footer={false} onCancel={() => onCloseModal()}>
        <React.Fragment>
          <div className="text-bold text-center text-custom-18 mb-2">เพิ่ม Stock สินค้า</div>
          <Form layout="vertical" onFinish={onSubmitAddManualItem} ref={formRef}>
            <Form.Item name="product" required={true} rules={[{ required: true, message: "กรุณาเลือก สินค้า !" }]} label="สินค้า">
              <Select showSearch onChange={onSelectManualAddItem}  filterOption={(input, option) =>  
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
      || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    } >
                {itemDCList &&
                  itemDCList.map((item, index) => {
                    return (
                      <Option key={index} value={`${item.id}_${item.item_category_id}`}>
                        {item.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            {contractList && (
              <Form.Item name="contract" required={true} rules={[{ required: true, message: "กรุณาเลือก สัญญา/ผู้ผลิต !" }]} label="สัญญา/ผู้ผลิต">
                <Select onChange={getContractItem}>
                  {contractList.length > 0 &&
                    contractList.map((contract, index) => {
                      return (
                        <Option index={index} value={contract.id}>
                          {contract.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            )}
            <Form.Item name="calc_unit_price" required={true} rules={[{ required: true, message: "กรุณากรอก ราคาต้นทุนขาเข้า !" }]} label="ราคาต้นทุนขาเข้า (ราคาต่อชุด+ค่าขนส่งเฉลี่ยต่อชุด)">
              <InputNumber style={{ width: "100%", borderRadius: 5, height: 40, paddingRight: '20px'  }}  />
            </Form.Item>
            <Form.Item name="qty" required={true} rules={[{ required: true, message: "กรุณากรอก จำนวนสินค้า !" }]} label="จำนวนสินค้า">
              <InputNumber style={{ width: "100%", borderRadius: 5, height: 40, paddingRight: '20px' }}  />
            </Form.Item>
            <Form.Item initialValue={moment()} name="received_date" required={true} rules={[{ required: true, message: "กรุณาเลือก วันที่นำเข้าสินค้า !" }]} label="วันที่นำเข้าสินค้า">
              <DatePicker style={{ width: "100%", borderRadius: 5, height: 40 }} defaultValue={moment()} format={"DD-MM-YYYY"} clearIcon={false} />
            </Form.Item>
            <Form.Item name="note" requ ired={true} rules={[{ required: true, message: "กรุณากรอก เหตุผล/หมายเหตุ !" }]} label="เหตุผล/หมายเหตุ *">
              <TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
            </Form.Item>
            <Form.Item className="mb-0">
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  gap: 15,
                  marginTop: 10,
                }}
              >
                <Button htmlType="submit" type="primary">
                  Confirm
                </Button>
                <Button danger type="primary" onClick={() => onCloseModal()}>
                  Cancel
                </Button>
              </div>
            </Form.Item>
          </Form>
        </React.Fragment>
      </Modal>
    );
  }

  return (
    <>
      <Spinner loading={isLoading} />
      <div className="offset-xl-1 col-xl-10 p-3 home-scrollable">
        <div className="row align-items-end">
          <div className="col-md-7 col-12 pb-2 d-flex flex-row align-items-end">
            <span className="header-title d-md-block my-0">{APP_TITLE_DC_INVENTORIES_MANAGEMENT}</span>
          </div>
          <div className="col-md-5 col-12 text-right">
            <div className="row justify-content-end pb-3 pr-3">
              <div className="pr-2">
                <Button danger ghost className="antd-ghost-btn-green" onClick={() => setVisibleModal(true)}>
                  <AppstoreAddOutlined /> เพิ่ม Stock
                </Button>
              </div>
              <NavLink to={`/${API_RESOURCES.dc_inventories_management}/log`}>
                <Button danger ghost className="antd-ghost-btn-green">
                  <OrderedListOutlined /> Log
                </Button>
              </NavLink>
            </div>
          </div>
        </div>
        <form onSubmit={(e)=>{getQuerySearch(e.target.value)}} >
        <div className="card-box light p-3">
          <SearchBar placeholder={"ค้นหา รหัสสินค้าและชื่อสินค้า"}  onEnter={(e)=>{ getQuerySearch(e) }} onChangeQuery={(e)=>{ setRouteSearch(e.target.value) }}   onClickClear={(e)=>{ clearForm()}} query={routeSearch} />
          {/* <SearchBar onEnter={this.onEnter} onChangeQuery={this.onChangeQuery} onClickClear={this.onClickClear} query={this.state.query} placeholder={"ค้นหาเลขที่ใบเสนอราคา, สถานี, ผู้ซื้อ"} /> */}
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-4 col-md-2 mt-2">
              <label style={{ margin: 0 }}>หมวดหมู่สินค้า</label>
            </div>
            <div className="col-8 col-md-10 mt-2">
              
              <Select placeholder={"ค้นหา..."} style={{ width: "100%" }}   onChange={(e)=>{getQueryCategory(e)}} value={routeCategory}>
              <Option value="">
                       ทั้งหมด
                      </Option>
                {itemDC &&
                  itemDC.map((item, index) => {
                    return (
                      <Option key={index} value={`${item.id}`}>
                        {item.sys_name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
           
          </div>
          <div className="row d-block d-md-none">
                <div className="col-12 mt-3 text-right">
                  <button className="btn btn-primary ml-2" type="submit" >
                    Search
                  </button>
                  <button type="button" className="btn btn-danger ml-2" onClick={(e)=>{ clearForm()}}>
                    Clear
                  </button>
                </div>
              </div>
        </div>
        </form>
        <div className="d-flex justify-content-end my-3">
          <ReactPaginate
            forcePage={page}
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            // onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
            // key={this.state.resetKey}
          />
        </div>
        {DisplayTableHeader()}
        {DisplayTableItems()}
        {DisplayModalAddItem()}
      </div>
    </>
  );
}

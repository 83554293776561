import React from "react";

// Components
import ExportExcel from "../../../global/ExportExcel";

// Functions
import { formatApiDate } from "../../../../functions/Date";

export default function exportModal(props) {
  const { selected_orders } = props;
  const count = Object.keys(selected_orders).length;
  return <ExportExcel data={[props.export_orders]} content={`Export (${count})`} filename={`OR_Mass_Upload_Auto_PO_GR_${formatApiDate(new Date())}`} customClass={props.btnClass} onExport={props.onCloseProcurement} disabled={count === 0} />;
}

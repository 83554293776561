import React from 'react';
import DatePicker from "react-datepicker";
import cloneDeep from 'lodash/cloneDeep';
import _ from 'lodash';
import { FORMAT_APP_DATE } from '../../constants'

// Models
import { NEW_ORDER_NOTE } from '../../models/PurchasingOrders'

// Component
import ErrorMessage from '../global/ErrorMessage'

// Functions
import { objectIsEmpty } from '../../functions/Object'
import { formatApiDate } from '../../functions/Date'


export class OrderFormOtherInfo extends React.Component {
  constructor(props) {
    super(props)
    let note = objectIsEmpty(props.note) ? cloneDeep(NEW_ORDER_NOTE) : props.note

    note.page_nb = props.page_nb
    this.state = {
      note: note
    }
  }

  onChange = (e) => {
    let { note } = this.state

    note = {
      ...note,
      [e.target.name]: e.target.value
    }
    this.props.onChangeNote(note)
    this.setState({note: note})
  }

  onChangeDate = (date) => {
    let { note } = this.state
    if (date instanceof Date) {
      note.action_date = formatApiDate(date)
    }
    this.props.onChangeNote(note)
    this.setState({note: note})
  }

  render() {
    let { disabled } = this.props
    let { note } = this.state
    return (
      <React.Fragment>
        {/* Other Info */}
        <h5 className="mt-4 pb-1">ข้อมูลอื่นๆ</h5>
        <div className="card-box p-3 mt-1">
          <div className="row">
            <div className="col-4 col-md-2 pr-0 mt-2"><label>ผู้รับผิดชอบ</label></div>
            <div className="col-8 col-md-4 mt-2">
              <input type="id" className="form-control" name="assigned_person"
                     onChange={this.onChange} value={note.assigned_person} disabled={disabled}
                ></input>
            </div>
            <div className="col-4 col-md-2 pr-0 mt-2"><label>วันที่</label></div>
            <div className="col-8 col-md-4 date-picker-wrapper mt-2">
              <DatePicker
                className="form-control"
                dateFormat={FORMAT_APP_DATE}
                selected={note.action_date ? new Date(note.action_date) : null}
                onChange={date => this.onChangeDate(date)}
                disabled={disabled}
              />
              <ErrorMessage errorKey={'delivery_date'} errorMessages={this.state.errors}/>
            </div>
          </div>
          <div className="row">
            <div className="col-4 col-md-2 pr-0 mt-2"><label>Note</label></div>
            <div className="col-8 col-md-10 mt-2">
              <textarea type="text" className="form-control" name="note" rows={2}
                        onChange={this.onChange} value={note.note} disabled={disabled}
                ></textarea>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
import React from 'react';
import Modal from 'react-modal';
import { R_SCREEN_DOCK_SIZE_SM, MODAL_STYLE_SMALL, MODAL_STYLE_SMALL_SM } from '../../../../constants'

// Functions
import { objectIsEmpty } from '../../../../functions/Object'

const ERR_NO_ORC_CODE = "ไม่ได้ระบุเลข Order จาก ORC ในช่อง Reference (INV No.)"

export default function ImportProcurementModal(props) {
  const previewImportDate = () => {
    const { import_data } = props
    if (!objectIsEmpty(import_data)) {
      let preview_data = []

      for (let key of Object.keys(import_data)) {
        const row = import_data[key]
        if (row.order_code && (row.order_code.length !== 3)) {
          preview_data.push(
            <div className="d-flex flex-column mb-2">
              <div className="d-flex-1 order-preview-import-header">สัญญา {row.contract_code} - {row.client_name} ({row.order_code})</div>
              <div className={`d-flex-1 ml-4 ${row.po_code ? '' : 'color-red'}`}>เลข PO: {row.po_code}</div>
              <div className={`d-flex-1 ml-4 ${row.gr_code ? '' : 'color-red'}`}>เลข GR: {row.gr_code}</div>
              {row.db_delivery_code ? <div className="d-flex-1 ml-4">เลขที่ Delivery: {row.db_delivery_code}</div> : ''}
              {row.db_delivery_date ? <div className="d-flex-1 ml-4">วันที่ Delivery: {row.db_delivery_date}</div> : ''}
              {row.db_billing_code ? <div className="d-flex-1 ml-4">เลขที่ Billing: {row.db_billing_code}</div> : ''}
              {row.db_billing_date ? <div className="d-flex-1 ml-4">วันที่ Billing: {row.db_billing_date}</div> : ''}
              {row.invoice_issued_date ? <div className="d-flex-1 ml-4">วันที่ส่งไปรษณีย์: {row.invoice_issued_date}</div> : ''}
              {row.invoice_tracking_no ? <div className="d-flex-1 ml-4">เลขที่พัสดุ: {row.invoice_tracking_no}</div> : ''}
            </div>
          )
        } else {
          preview_data.push(
            <div className="d-flex flex-column mb-2">
              <div className="d-flex-1 order-preview-import-header color-red">WARNING! สัญญา {row.contract_code} - {row.client_name} ({row.order_code})</div>
              <div className="d-flex-1 ml-4 color-red">{ERR_NO_ORC_CODE}</div>
              <div className="d-flex-1 ml-4 color-red">จะไม่สามารถ import เลข PO และ GR ใน Order</div>
            </div>
          )
        }
      }

      return preview_data
    }
  }

  return (
    <Modal
      isOpen={props.is_importing}
      contentLabel="onImportProcurement"
      onRequestClose={() => props.handleProcurementModal(false)}
      style={(window.innerWidth > R_SCREEN_DOCK_SIZE_SM) ? MODAL_STYLE_SMALL : MODAL_STYLE_SMALL_SM}
    >
      <div className="d-flex flex-column">
        <h5>นำเข้ารายการส่งจัดซื้อ</h5>
        <div className="line mb-2"></div>
        <div className="mt-2 mb-3">
          เมื่อนำเข้ารายการจัดซื้อ ระบบจะทำการอัพเดทเลขที่ PO/GR อัตโนมัติไปที่ทุก Order ที่ระบุในเอกสาร
        </div>
        <div className="d-flex flex-column flex-sm-row">
          <div className="pr-2"><label>รายการส่งจัดซื้อ</label></div>
          <div className="form-group">
            <input type="file" class="form-control-file" name="import-po-gr" onChange={props.onImportFile}/>
          </div>
        </div>
        <div className="color-red font-weight-bold">{props.import_error ? `ERROR: ${props.import_error}` : ''}</div>
        {(props.import_data ?
          <React.Fragment>
            <div className="mb-2 font-weight-bold">
              Preview:
            </div>
            <div className="bg-aliceblue order-preview-import">
              {previewImportDate()}
            </div>
          </React.Fragment>
          : '')}
        <div className="d-flex flex-row mt-3">
          <button type="button" className="btn btn-primary btn-sm w-100 mr-1"
                  onClick={props.onConfirmImportFile}>{'ตกลง'}</button>
          <button type="button" className="btn btn-secondary btn-sm w-100 mr-1"
                  onClick={() => props.handleProcurementModal(false)}>{'ยกเลิก'}</button>
        </div>
      </div>
    </Modal>
  )
}
import React from "react";
import SuccessIcon from "../../../assets/images/success.png";
import FailedIcon from "../../../assets/images/failed.png";
import WarningIcon from "../../../assets/images/warning.png";
import { Modal, Button } from "antd";

export default function StatusModal({ visible, type, title, detail, onClickOK, onClickCancel, showCancel = true }) {
  const image_status = {
    success: SuccessIcon,
    failed: FailedIcon,
    warning: WarningIcon,
  };

  return (
    <Modal visible={visible} centered footer={false} closable={false} onClickCancel={onClickCancel}>
      <div style={{ textAlign: "center" }}>
        <div>
          <img src={image_status[type]} width={80} />
        </div>
        <div style={{ fontWeight: "bold", fontSize: 24, marginTop: 20 }}>{title}</div>
        <div style={{ fontSize: 18, marginTop: 20 }}>{detail}</div>
        <div style={{ display: "flex", gap: 10, justifyContent: "center", marginTop: 25 }}>
          <Button style={{ borderRadius: 5, width: 150 }} type="primary" onClick={onClickOK}>
            ตกลง
          </Button>
          {showCancel && (
            <Button style={{ borderRadius: 5, width: 150 }} onClick={onClickCancel}>
              ยกเลิก
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}

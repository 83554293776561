import { MR_STATES, API_RESOURCES } from '../constants.js'

// Functions
import { validateInputFromRequireFields } from '../functions/Validation'
import { parseData } from '../functions/Excel'
import { formatDate } from '../functions/Date'

export const MR_CODE = '31300039/'
export const MR_STATION_TYPE_DEFAULT = 'DODO'
export const requiredFields = {
  code: 'เลขที่ใบขอผลิต',
  mr_date: 'วันที่ MR',
  mr_subject: 'หัวข้อ MR',
  manufacturer_name: 'ชื่อผู้ผลิต',
  contract_no: 'เลขที่สัญญา',
  contract_date: 'วันที่ของสัญญา',
  nb_days_to_complete: 'ระยะเวลาดำเนินงาน',
  request_date: 'วันทียื่นคำขอ',
  coordinator_name: 'ชื่อผู้ติดต่อ',
  approver_name: 'ชื่อผู้อนุมัติ',
  approver_title: 'ตำแหน่งชื่อผู้อนุมัติ',
  s3_signature_upload: 'อัพโหลดลายเซ็น',
  informed_date: 'วันที่ส่งมอบ',
  inspection_date: 'วันที่ตรวจรับ',
  s3_inspection_sign_url: 'ลายเซ็นคนตรวจรับ',
  vendor_id: 'ผู้ผลิต',
  contract_id: 'สัญญา',
}

export const vendorBillingFields = {
  tb_po_invoice_nb: 'PO/Invoice',
  tb_design_eng_nb: 'เลข อฟ.วฟธ.',
  tb_rwt_nb: 'RWT',
  tb_remark: 'หมายเหตุ',
  tb_received_date: 'วันที่ส่งเอกสาร',
}

export const NEW_ORDER_PO_GR = {
  order_id: null,
  id: null,
  po_code: null,
}

export const NEW_INBOUND_ORDER_PO = {
  inbound_order_id: null,
  id: null,
  po_code: null,
}

export function isDisabled(state) {
  return state === MR_STATES.cancelled.name
}

export function validateMR(mr) {
  let errors = {}

  // validate require fields
  errors = validateInputFromRequireFields(mr, requiredFields)

  return errors
}

export function getNewMR(current_user) {
  let today = new Date()
  return {
    id: null,
    code: MR_CODE,
    mr_date: today,
    mr_subject: '',
    manufacturer_name: '',
    contract_no: '',
    contract_date: today,
    state: MR_STATES.draft.name,
    request_date: today,
    manufacture_request_stations: [],
    nb_days_to_complete: '',
    coordinator_name: `${current_user.firstname || ""} ${current_user.lastname || ""}`,
    approver_name: '',
    approver_title: '',
    s3_signature_upload: '',
    s3_inspection_sign_url: '',
    cancel_note: '',
    vendor_id: '',
    user_id: current_user.id,
    order_po_gr: NEW_ORDER_PO_GR,
    inbound_order_item_po: {},
    toggle_committee: false,
  }
}

export function toExcelVendorBillingReport(mrs) {
  let data = []
  let font_set = { name: 'Arial', sz: '10' }

  let border_color = { style: 'thin', color: { rgb: 'FF000000' } }
  let border_color_medium = { style: 'medium', color: { rgb: 'FF000000' } }
  let border_style = { top: border_color, bottom: border_color, left: border_color, right: border_color }
  let border_style_bm = { top: border_color, bottom: border_color_medium, left: border_color, right: border_color }

  let style_row_1 = {
    fill: { fgColor: { rgb: 'FFC5E0B4' } },
    border: border_style_bm,
    font: { sz: '11', bold: true, name: 'Arial' },
    alignment: { horizontal: 'center' },
  }
  let style_row_body_title_center = { border: border_style, font: font_set, alignment: { horizontal: 'center' } }
  let style_row_body_title = { border: border_style, font: font_set }
  let style_row_body_center = { border: border_style, font: font_set, alignment: { vertical: 'top' }, alignment: { horizontal: 'center' } }
  let style_row_body = { border: border_style, font: font_set, alignment: { vertical: 'top' } }

  let width_col_xs = { wch: 8 }
  let width_col_sm = { wch: 15 }
  let width_col_md = { wch: 25 }
  let width_col_lg = { wch: 35 }
  let width_col_xl = { wch: 60 }
  let header = [
    { title: 'ลำดับ', width: width_col_xs, style: style_row_1 },
    { title: 'บริษัท', width: width_col_xl, style: style_row_1 },
    { title: 'สถานี', width: width_col_xl, style: style_row_1 },
    { title: 'หมวดหมู่', width: width_col_lg, style: style_row_1 },
    { title: vendorBillingFields['tb_po_invoice_nb'], width: width_col_md, style: style_row_1 },
    { title: vendorBillingFields['tb_design_eng_nb'], width: width_col_sm, style: style_row_1 },
    { title: vendorBillingFields['tb_rwt_nb'], width: width_col_sm, style: style_row_1 },
    { title: vendorBillingFields['tb_received_date'], width: width_col_sm, style: style_row_1 },
    { title: vendorBillingFields['tb_remark'], width: width_col_xl, style: style_row_1 },
  ]

  let index = 1
  for (let mr of mrs) {
    let station_index = 1
    for (let station of mr.manufacture_request_stations) {
      let style_center = station_index === 1 ? style_row_body_title_center : style_row_body_center
      let style = station_index === 1 ? style_row_body_title : style_row_body
      if (station_index === 1) {
        data.push([
          parseData(station_index === 1 ? index : '', style_center),
          parseData(station_index === 1 ? mr.manufacturer_name : '', style),
          parseData(station.station_name, style),
          parseData(station.item_category_name, style),
          parseData(mr.tb_po_invoice_nb, style),
          parseData(mr.tb_design_eng_nb, style),
          parseData(mr.tb_rwt_nb, style),
          parseData(formatDate(mr.tb_received_date), style),
          parseData(mr.tb_remark, style),
        ])
      } else {
        data.push([
          parseData('', style_center),
          parseData('', style),
          parseData(station.station_name, style),
          parseData(station.item_category_name, style),
          parseData('', style),
          parseData('', style),
          parseData('', style),
          parseData('', style),
          parseData('', style),
        ])
      }

      station_index += 1
    }
    index += 1
  }

  return { sheet_name: 'sheet1', dataSet: [{ columns: header, data: data }] }
}

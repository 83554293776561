import React from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";

// Components
import Spinner from "../../global/Spinner";
import ExportExcel from "../../global/ExportExcel";
import ItemCategorySelect from "../../global/ItemCategorySelect";
import VendorSelect from "../../global/VendorSelect";
import SearchBar from "../../global/SearchBar";

// Models
import { REPORT_TYPES } from "../../../models/Reports";

import { ORDER_STATES } from "../../../constants";

const OPTIONS_STATES = [{ value: "pending order", label: "pending order" }];
Object.keys(ORDER_STATES).forEach((key) => {
  OPTIONS_STATES.push({ value: ORDER_STATES[key].name, label: ORDER_STATES[key].name });
});
const COLUMN_STYLES = ["m-auto", "m-auto", "m-auto font-weight-bold", "m-auto", "m-auto", "m-auto", "m-auto", "m-auto font-weight-bold", "m-auto", "m-auto font-weight-bold", "m-auto"];

function ContractBudgetReport(props) {
  const tableHeader = () => (
    <div className="card-box p-2 d-none d-md-block">
      {display_row_item(["", "เลขที่สัญญา", "ชื่อสัญญา / ชื่อสินค้า", "ชื่อผู้ผลิต", "วันที่เริ่ม", "วันที่สิ้นสุด", "ระยะเวลาผลิต", "วงเงินสัญญา", "จำนวนสินค้า", "วงเงินที่ใช้ไป", "วงเงินคงเหลือ"], "p-2 mx-1 font-weight-bold")}
    </div>
  );

  const display_row_item = (cols, row_classname, col_styles = COLUMN_STYLES) => {
    return (
      <div className={`row ${row_classname}`} key={cols[5]}>
        <div className={`col-p5 ${col_styles[0]}`}>{cols[0]}</div>
        <div className={`col-1 ${col_styles[1]}`}>{cols[1]}</div>
        <div className={`col ${col_styles[2]}`}>{cols[2]}</div>
        <div className={`col ${col_styles[3]}`}>{cols[3]}</div>
        <div className={`col-1 ${col_styles[4]}`}>{cols[4]}</div>
        <div className={`col-1 ${col_styles[5]}`}>{cols[5]}</div>
        <div className={`col-1 ${col_styles[6]}`}>{cols[6]}</div>
        <div className={`col-1 ${col_styles[7]}`}>{cols[7]}</div>
        <div className={`col-1 ${col_styles[8]}`}>{cols[8]}</div>
        <div className={`col-1 ${col_styles[9]}`}>{cols[9]}</div>
        <div className={`col-1 ${col_styles[10]}`}>{cols[10]}</div>
      </div>
    );
  };

  const displayItemList = (contract_item_categories) => {
    let result = [];
    contract_item_categories.forEach((c_item) => {
      let { contract_items } = c_item;
      contract_items.forEach((contract_item) => {
        result.push(contract_item.name);
      });
    });
    return result;
  };

  const reportItems = () => {
    let { contracts } = props;
    let rows = [];
    Object.keys(contracts).forEach((key) => {
      let contract_group = contracts[key];
      let contract_head = contract_group[0];
      let row_classname_set = "p-2 mx-2";

      let { is_opened } = contract_head;

      let total_contract_useage = 0;
      contract_group.forEach((item) => {
        total_contract_useage += item.total_quo_item_contract_price;
      });

      let total_qty_useage = 0;
      contract_group.forEach((item) => {
        total_qty_useage += item.qty;
      });

      rows.push(
        <React.Fragment>
          <div
            className="card-box py-1 mt-2 cursor-pointer link-no-hover"
            onClick={(e) => {
              props.onClickContractGroup(contract_head.id);
            }}
          >
            {display_row_item(
              [
                <i className={`${contract_head.is_opened ? "icon-arrow-up" : "icon-arrow-down"} mr-1`}></i>,
                contract_head.code,
                contract_head.name,
                contract_head.vendor_name,
                contract_head.from_date,
                contract_head.to_date,
                contract_head.nb_days_to_complete,
                contract_head.contract_budget != null ? contract_head.contract_budget.toLocaleString() : "",
                total_qty_useage != null ? total_qty_useage : "",
                total_contract_useage != null ? total_contract_useage.toLocaleString() : "",
                contract_head.contract_budget != null && total_contract_useage != null ? (contract_head.contract_budget - total_contract_useage).toLocaleString() : "-",
              ],
              row_classname_set
            )}
          </div>
        </React.Fragment>
      );
      if (is_opened) {
        rows.push(
          <React.Fragment>
            <div className="card-box py-1 bg-aliceblue">
              {contract_group.map((elm) => {
                return (
                  <div className="py-1 mt-2 border-bottom">
                    {display_row_item(
                      ["", "", elm.contract_item_name, "", "", "", "", "", elm.qty != null ? elm.qty.toLocaleString() : 0, elm.total_quo_item_contract_price != null ? elm.total_quo_item_contract_price.toLocaleString() : 0, ""],
                      row_classname_set
                    )}
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        );
      }
    });
    return rows;
  };

  return (
    <React.Fragment>
      <Spinner loading={props.is_refiltering || props.is_loading} />
      <div className="col p-3 home-scrollable">
        <div className="row">
          <div className="col-8">
            <h3>{REPORT_TYPES.contract_budget.name}</h3>
          </div>
        </div>

        <div className="card-box light p-3">
          <form onSubmit={props.handleSubmit}>
            <div className="row">
              <div className="col-4 col-md-8 mt-2">
                <SearchBar
                  onEnter={() => {
                    props.setState({ is_refiltering: true });
                  }}
                  onChangeQuery={props.onAddContract}
                  onClickClear={props.onClickClear}
                  query={props.selected_contract}
                  placeholder={"ค้นหาชื่อสัญญา..."}
                />
                {/*<Select
                isMulti
                value={props.selected_state}
                onChange={props.onAddContract}
                options={props.contracts}
                placeholder={'ค้นหา...'}
            />*/}
              </div>
            </div>
            <div className="row">
              <div className="col-4 col-md-2 mt-2">
                <label>หมวดหมู่</label>
              </div>
              <div className="col-8 col-md-3 mt-2 date-picker-wrapper">
                <ItemCategorySelect onChange={props.onClickSelectCate} itemCateId={props.selected_cate.id} key={props.selected_cate.id} />
              </div>
            </div>

            <div className="row">
              <div className="col-4 col-md-2 mt-2">
                <label>ผู้ผลิต</label>
              </div>
              <div className="col-8 col-md-3 mt-2 date-picker-wrapper">
                <VendorSelect onChange={props.onClickSelectVendor} vendorId={props.selected_vendor.id} key={props.selected_vendor.id} />
              </div>
            </div>
            <div className="row">
              <div className="col-4 col-md-2 mt-2">
                <label>สัญญาเปิดใช้งาน</label>
              </div>
              <div className="col-8 col-md-3 mt-2 date-picker-wrapper">
                <div className="col-12 col-md-4 mt-2 d-flex flex-row">
                  <div className="btn-group" role="group">
                    <button
                      type="button"
                      className={`btn btn-sm ${props.active_filter === true ? "btn-success" : "btn-secondary"} `}
                      onClick={() => {
                        props.onClickFilterStatus(true);
                      }}
                    >
                      <span>&#10003;</span>
                    </button>
                    <button
                      type="button"
                      className={`btn btn-sm ${props.active_filter === false ? "btn-danger" : "btn-secondary"} `}
                      onClick={() => {
                        props.onClickFilterStatus(false);
                      }}
                    >
                      X
                    </button>
                  </div>
                  {/* <div className="ml-2">{ORDER_PROGRESS_FLAGS[key]}</div> */}
                </div>
              </div>
            </div>

            {/* Visible xs, sm  */}
            <div className="row d-block d-md-none">
              <div className="col-12 mt-3 text-right">
                <button type="submit" className="btn btn-primary ml-2">
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="row">
          <div className="d-none d-md-block col-12 mt-2">
            <div className="d-flex flex-row-reverse">
              <ExportExcel
                data={[props.export_report]}
                content={"Export"}
                filename={`วงเงินสัญญา_${props.selected_vendor.id != null ? props.selected_vendor.text : "ทั้งหมด"}${props.selected_cate.id != null ? "_" + props.selected_cate.text : ""}`}
                customClass={"btn btn-success ml-2"}
                disabled={props.export_report === null}
              />
            </div>
          </div>
        </div>
        <div className="font-size-14px pt-2">
          {tableHeader()}
          {reportItems()}
        </div>
        <div className="block-info-buffer-box"></div>
      </div>
    </React.Fragment>
  );
}
export default withRouter(ContractBudgetReport);

import * as React from "react";
import {
  List, Datagrid, Edit, Create, SimpleForm, DeleteWithConfirmButton, TextField,
  EditButton, TextInput, ReferenceInput, SelectInput, useRedirect, useRefresh,
  NumberInput, NumberField, required, Button, Link, ReferenceManyField, ReferenceField,
  FunctionField, useListContext, BooleanInput, TabbedForm, FormTab
} from 'react-admin';
import { withStyles } from "@material-ui/core/styles";
import BookIcon from '@material-ui/icons/Book';
import { getExpenseDetails } from '../../models/ItemCategories'

import { Form } from 'react-final-form';
import { Box, Button as CoreButton, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ContentFilter from "@material-ui/icons/FilterList";

import { exporter } from '../../functions/reactAdmin'

import { EditToolbar, EditActions } from './Default'


export const ContractItemIcon = BookIcon;

// const ContractItemFilter = (props) => (
//   <Filter {...props}>
//       <TextInput label="Search" source="name" alwaysOn />
//   </Filter>
// );

const ContractItemFilter = props => {
  return props.context === "button" ? (
    <ContractItemFilterButton {...props} />
  ) : (
    <ContractItemFilterForm {...props} />
  );
};

const ContractItemFilterButton = () => {
  const { showFilter } = useListContext();
  return (
      <CoreButton
          size="small"
          color="primary"
          onClick={() => showFilter("main")}
          startIcon={<ContentFilter />}
      >
          Filter
      </CoreButton>
  );
};

const ContractItemFilterForm = ({ open }) => {
  const {
      displayedFilters,
      filterValues,
      setFilters,
      hideFilter,
  } = useListContext();

  if (!displayedFilters.main) return null;

  const onSubmit = values => {
      if (Object.keys(values).length > 0) {
          setFilters(values);
      } else {
          hideFilter("main");
      }
  };

  const resetFilter = () => {
      setFilters({}, []);
  };

  return (
      <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
          {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
              <Box mt={7} />
              <Box display="flex" alignContractItems="flex-end" mb={1}>
              <Box component="span" mr={3}>
                <BooleanInput source="is_active" label="เปิดใช้งาน" alwaysOn />
              </Box>
              <Box component="span" mr={1}>
                <TextInput
                  resettable
                  helperText={false}
                  source="name"
                  label="Search ชื่อ"
                  InputProps={{
                      endAdornment: (
                      <InputAdornment>
                          <SearchIcon color="disabled" />
                      </InputAdornment>
                      )
                  }}
                />
              </Box>
              <Box component="span" mr={1}>
                <ReferenceInput 
                  resettable
                  label="หมวดหมู่" 
                  source="contract_item_category_id" 
                  reference="contract_item_categories"
                  filterToQuery={searchText => ({ sys_name: searchText })}
                >
                  <SelectInput optionText="item_category.sys_name" />
                </ReferenceInput>
              </Box>
              <Box component="span" mr={2} mb={1.5}>
                  <CoreButton variant="outlined" color="primary" type="submit">
                  Filter
                  </CoreButton>
              </Box>
              <Box component="span" mb={1.5}>
                  <CoreButton variant="outlined" onClick={resetFilter}>
                  Close
                  </CoreButton>
              </Box>
              </Box>
          </form>
          )}
      </Form>
      </div>
  );
};


export const ContractItemList = (props) => (
  <List {...props} filters={<ContractItemFilter />} exporter={exporter}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="is_active" label="เปิดใช้งาน"/>
      <TextField source="code" />
      <TextField source="name" />
      <TextField source="contract_item_category_id" label="con_cate_id"/>
      <ReferenceField label="หมวดหมู่" source="contract_item_category_id" reference="contract_item_categories" sortable={false}>
        <TextField source="item_category.sys_name" />
      </ReferenceField>
      <EditButton basePath="/contract_items" />
       <DeleteWithConfirmButton undoable={false}/>
    </Datagrid>
  </List>
);

const styles = {
  button: {
    marginTop: "1em",
    marginBottom: "1em",
    color: "#fff",
    backgroundColor: "#3f51b5"
  },
};

const AddNewFeeButton = withStyles(styles)(({ classes, record }) => (
  <Button
    className={classes.button}
    variant="raised"
    component={Link}
    to={{
      pathname: "/contract_item_fees/create",
      search: `?contract_item_id=${record.id}`,
    }}
    label="Add a fee"
  >
  </Button>
));

const AddNewDiscountButton = withStyles(styles)(({ classes, record }) => (
  <Button
    className={classes.button}
    variant="raised"
    component={Link}
    to={{
      pathname: "/contract_item_discounts/create",
      search: `?contract_item_id=${record.id}`,
    }}
    label="Add a discount"
  >
  </Button>
));

const ContractItemTitle = ({ record }) => {
  return <span>ContractItem {record ? `"${record.name}"` : ''}</span>;
};

const FeeDisplay = ({ basePath, record, resource }) => {
  var text = getExpenseDetails(record)

  return (
    <React.Fragment>
      {/* <FunctionField
        label="Types"
        render={record => `${ITEM_CATE_FEE_TYPES[record.fee_type]} - `}
      /> */}
      <FunctionField
        label="Price"
        render={record => text}
      />
    </React.Fragment>
  )
}

const DiscountDisplay = ({ basePath, record, resource }) => {
  var text = getExpenseDetails(record)
  return (
    <React.Fragment>
      {/* <FunctionField
        label="Types"
        render={record => `ส่วนลด ${ITEM_CATE_FEE_TYPES[record.fee_type]} - `}
      /> */}
      <FunctionField
        label="Price"
        render={record => text}
      />
    </React.Fragment>
  )
}

export const ContractItemEdit = (props) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  return (
    <Edit undoable={false} actions={<EditActions />} title={<ContractItemTitle />} {...props}>
      <TabbedForm toolbar={<EditToolbar />}>
        <FormTab label="ContractItem">
          <BooleanInput label="เปิดใช้งาน" source="is_active"/>
          <TextInput disabled source="id" />
          <TextInput source="name" validate={required()}/>
          <TextInput source="code" validate={required()}/>
          <TextInput source="unit" />
          <NumberInput source="price" step={0.01} validate={required()}/>
          <NumberInput source="vat" step={0.01} validate={required()}/>
          <NumberField source="total" step={0.01}/>
          <ReferenceInput 
            label="ContractItem Category" 
            source="contract_item_category_id" 
            reference="contract_item_categories"
            filterToQuery={searchText => ({ sys_name: searchText })}
            validate={required()}
          >
            <SelectInput optionText="sys_name" />
          </ReferenceInput>
        </FormTab>
        <FormTab label="Fee">

        <ReferenceManyField
          addLabel={false}
          reference="contract_item_fees"
          target="contract_item_id"
        >
          <Datagrid>
            <TextField source="name"/>
            <FeeDisplay />
            <EditButton />
            <DeleteWithConfirmButton
              {...props}
              undoable={false}
              onSuccess={(response) => {
                redirect(`/contract_items/${response.data.contract_item_id}/1`);
                refresh();
              }}
            />
          </Datagrid>
        </ReferenceManyField>
        <AddNewFeeButton />
        </FormTab>
        <FormTab label="Discount">
          <ReferenceManyField
            addLabel={false}
            reference="contract_item_discounts"
            target="contract_item_id"
          >
            <Datagrid>
              <TextField source="name"/>
              <DiscountDisplay />
              <EditButton />
              <DeleteWithConfirmButton
                {...props}
                undoable={false}
                onSuccess={(response) => {
                  redirect(`/contract_items/${response.data.contract_item_id}/2`);
                  refresh();
                }}
              />
            </Datagrid>
          </ReferenceManyField>
          <AddNewDiscountButton />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

export const ContractItemCreate = (props) => (
  <Create title="Create a ContractItem" actions={<EditActions />} {...props}>
    <SimpleForm>
      <BooleanInput label="เปิดใช้งาน" source="is_active" defaultValue={true}/>
      <TextInput source="name" validate={required()}/>
      <TextInput source="code" validate={required()}/>
      <TextInput source="unit" />
      <NumberInput source="price" step={0.01} validate={required()}/>
      <NumberInput source="vat" step={0.01} validate={required()}/>
      <NumberField source="total" step={0.01}/>
      {/* <PriceFields/> */}
      <ReferenceInput 
        label="ContractItem Category" 
        source="contract_item_category_id" 
        reference="contract_item_categories"
        filterToQuery={searchText => ({ sys_name: searchText })}
        validate={required()}
      >
        <SelectInput optionText="sys_name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

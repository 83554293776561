
import React from "react"
import PropTypes from "prop-types"

import { objectIsEmpty } from '../../functions/Object'

class ErrorMessage extends React.Component {
  render () {
    if (objectIsEmpty(this.props.errorMessages)) { return null }
    let errors = this.props.errorMessages
    let message = errors[this.props.errorKey]
    if (message) {
      return <span className="color-red">{message}</span>
    }

    return null
  }
}

ErrorMessage.propTypes = {
  errorKey: PropTypes.string.isRequired,
  errorMessages: PropTypes.object.isRequired
}

export default ErrorMessage

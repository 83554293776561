import * as React from 'react';
import { AppBar } from 'react-admin';

import { APP_NAME } from '../../constants'

const MyUserMenu = props => (
  <React.Fragment>
    <a type="button" href="/" className="btn btn-warning btn-sm">Go to {APP_NAME}</a>
    {/* <AmplifySignOut /> */}
  </React.Fragment>
);

const MyAppBar = props => <AppBar {...props} userMenu={<MyUserMenu />} />;


export default MyAppBar;
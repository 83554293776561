import React from "react";
import PropTypes from "prop-types";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { uniqueKey } from "../../functions/Random.js";

const copiedPopover = (
  <Popover id="popover-basic">
    <Popover.Title as="h3">Copied!</Popover.Title>
  </Popover>
);

export class DocumentPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      document_url: window.location.origin + "/test_quotation.pdf",
      po_url: window.location.origin + "/test_po.pdf",
    };
  }

  render() {
    let { docName, url, resetKey } = this.props;
    let unique_key = resetKey ? resetKey : uniqueKey();
    return (
      <React.Fragment>
        <h5 className="mt-3 pb-1">{docName}</h5>
        <div className="card-box p-3">
          <div className="d-flex justify-content-between mt-2">
            <input type="id" className="form-control d-none d-md-flex mr-2" value={url} readOnly></input>
            <OverlayTrigger trigger="click" placement="top" overlay={copiedPopover}>
              <button
                type="button"
                className="btn btn-primary btn-sm ml-auto"
                onClick={() => {
                  navigator.clipboard.writeText(url);
                }}
              >
                Copy!
              </button>
            </OverlayTrigger>
            <a className="btn btn-primary btn-sm ml-2" href={url} target="_blank" download>
              Download
            </a>
          </div>
          <iframe className="w-100 mt-3 d-none d-md-flex vh-70" src={`${url}`} value="FitH" title={`${docName}-${unique_key}`} />
          {/* <iframe className="w-100 mt-3 d-none d-md-flex vh-70" src={`${url}?${unique_key}#zoom=page-fit`} value="FitH" title={`${docName}-${unique_key}`}/> */}
        </div>
      </React.Fragment>
    );
  }
}

DocumentPreview.propTypes = {
  docName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  resetKey: PropTypes.string,
};

import React from 'react';
import {
    Line,
    Bar,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ComposedChart,
    ResponsiveContainer,
    ReferenceLine
  } from "recharts";
import _ from 'lodash'
class Chart extends React.Component {
  constructor() {
    super();
    this.state = {
      someKey: 'someValue'
    };
    this.barSize = 50;
    this.data = [];
  }
  getStrokeColor = (value) => {
  if (value >= 3000) {
    return 'red';
  } else if (value >= 2000) {
    return 'orange';
  } else {
    return 'blue';
  }
};
  render() {
    return   <div className="chart-container">
         
    <ResponsiveContainer
      className="col-custom-80 col-custom-sm-100"
      height={320}
    >
      <ComposedChart data={this.props.data} barGap={-this.barSize}>
        {/* <XAxis dataKey="name" /> */}
        <XAxis 
        dataKey="name" 
        domain={['dataMin - 1', 'dataMax + 1']} 
        tick={{ fontSize: 14 }} 
        axisLine={{ strokeWidth: 0 }} 
        height={60}   
      />
        <YAxis />
        <Tooltip />
        <Legend />
        <ReferenceLine y={0} stroke="#000000" />
        <CartesianGrid strokeDasharray="3 3"  stroke="#cdcdcd" vertical={false}  />
        {/* <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
        <Bar
          dataKey="added"
          barSize={this.barSize}
          fill="#339ABE"
          name="จำนวนรับเข้า"
        />
        <Bar
          dataKey="removed"
          barSize={this.barSize}
          fill="#d54934"
          name="จำนวนจำหน่ายออก"
        />
        <Line
          type="monotone"
          dataKey="end_stock"
           stroke="#4BDA51"
          name="ยอดสุทธิสิ้นวัน"
          dot={false}
      
        />
      </ComposedChart>
    
    </ResponsiveContainer>
  </div>
  }

  componentDidMount() {
    this.setState({
      someKey: 'otherValue'
    });

    let data = _.map(this.props.data, (item) => {
      item.removed = item.removed * -1;
       return item
    });

    this.data = this.props.data; 
  }
}

Chart.defaultProps = {
    data : [
        {
          name: "1-October-2022",
          plus: 4000,
          minus: -2400,
          amt: 0,
        },
        {
          name: "2-October-2022",
          plus: 3000,
          minus: -1398,
          amt: 1200,
        },
        {
          name: "3-October-2022",
          plus: 2000,
          minus: -2800,
          amt: 2500,
        },
        {
          name: "4-October-2022",
          plus: 2780,
          minus: -3908,
          amt: 2000,
        },
        {
          name: "5-October-2022",
          plus: 1890,
          minus: -2800,
          amt: 1000,
        },
        {
          name: "6-October-2022",
          plus: 2390,
          minus: -3800,
          amt: 2000,
        },
        {
          name: "7-October-2022",
          plus: 3490,
          minus: -4300,
          amt: 2800,
        },
      ]
}

export default Chart;

import React, { useState } from "react";
import { EditOutlined, CloseOutlined, DeleteOutlined, RedoOutlined, CheckOutlined, ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import { InputNumber, Input, Modal, Button } from "antd";
import axios from "axios";
import FailIcon from "../../assets/images/failed.png";
import SuccessIcon from "../../assets/images/success.png";
import dayjs from "dayjs";

const { TextArea } = Input;

export default function ProductReviced({ item, index, currentUser, onDeleteLotNoneID, onDeleteLotID, onChangeQTYAddedLot, order_code }) {
  const addedItem = { ...item };

  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [validateState, setValidateState] = useState(null);
  const [validateMessage, setValidateMessage] = useState(null);
  const [qtyEdit, setQTYEdit] = useState(null);

  function onDeleteItemLot() {
    setQTYEdit(0);
    setIsValidate(true);
    setIsDelete(true);
    validateChangeQTY(true);
  }

  function onAccepeValidateModal() {
    const params = {
      qty: qtyEdit !== null ? qtyEdit : addedItem.qty,
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}/inbound_order_lot/${addedItem.id}`, params)
      .then((response) => {
        if (response.status === 200) {
          setQTYEdit(null);
          setIsDelete(false);
          setIsEdit(false);
          setIsValidate(false);
          setValidateState(null);
          setValidateMessage(null);

          if (isDelete) {
            if (addedItem.id) {
              onDeleteLotID(addedItem.index);
            } else {
              onDeleteLotNoneID();
            }
          } else {
            onChangeQTYAddedLot(addedItem.index, qtyEdit ? qtyEdit : addedItem.qty, "qty");
            onChangeQTYAddedLot(addedItem.index, addedItem.remark, "remark");
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function DisplayModal() {
    return (
      <Modal open={isValidate} footer={false} closable={false} centered className="received-modal">
        <div className="modal-received-item-title">เปลี่ยนแปลงข้อมูลการรับสินค้า {order_code}</div>
        <div className="modal-received-item-content">
          {addedItem.code} - {addedItem.name}
        </div>
        <div className="modal-received-change-content">
          <span style={{ color: "#28a745", fontWeight: "bold", fontSize: 18 }}>{addedItem.qty}</span>
          <span>
            <ArrowRightOutlined />
          </span>
          <span style={{ color: "red", fontWeight: "bold", fontSize: 18 }}>{qtyEdit !== null ? qtyEdit : addedItem.qty}</span>
        </div>
        {validateState === null ? (
          <div style={{ textAlign: "center", display: "flex", justifyContent: "center", gap: 15, flexDirection: "column" }}>
            <span className="validate-progress">กำลังตรวจสอบ</span>
            <div>
              <LoadingOutlined style={{ fontSize: 32 }} spin />
            </div>
          </div>
        ) : (
          <div style={{ textAlign: "center", display: "flex", justifyContent: "center", gap: 15, flexDirection: "column" }}>
            {validateState ? (
              <div style={{ textAlign: "center", display: "flex", flexDirection: "column", gap: 10 }}>
                <div>
                  <img src={SuccessIcon} alt="failIcon" width={40} />
                </div>
                <span className="validate-progress" style={{ color: "#28a745" }}>
                  คลิก ‘ยืนยัน’ เพื่อ เปลี่ยนแปลงจำนวน {isDelete && <span style={{ color: "red" }}>(ลบ)</span>}
                </span>
                <div style={{ display: "flex", gap: 10, justifyContent: "center" }}>
                  <Button className="validate-btn" onClick={() => onAccepeValidateModal()}>
                    ยืนยัน
                  </Button>
                  <Button className="validate-cancel-btn" onClick={() => onCloseValidateModal()}>
                    ยกเลิก
                  </Button>
                </div>
              </div>
            ) : (
              <div style={{ textAlign: "center", display: "flex", flexDirection: "column", gap: 10 }}>
                <div>
                  <img src={FailIcon} alt="failIcon" width={40} />
                </div>
                <span className="validate-progress" style={{ color: "red" }}>
                  {validateMessage == "Total quantity from all lot is more than the order's quantity" ? "จำนวนที่ต้องการเปลี่ยนแปลง มากกว่าจำนวนสินค้าในออเดอร์นี้" : "ไม่สามารถเปลี่ยนแปลงได้เนื่องจากสินค้าถูกนำไปใช้แล้ว"}
                </span>
                <div>
                  <div style={{ display: "flex", gap: 10, justifyContent: "center" }}>
                    <Button className="validate-cancel-btn" onClick={() => onCloseValidateModal()}>
                      ยกเลิก
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Modal>
    );
  }

  function onChangeQTY(e) {
    setQTYEdit(e);
  }

  function onCloseValidateModal() {
    setQTYEdit(null);
    setIsDelete(false);
    setIsEdit(false);
    setIsValidate(false);
    setValidateState(null);
    setValidateMessage(null);
  }

  function validateChangeQTY(isCheckDelete = false) {
    setIsValidate(true);

    const params = {
      qty: qtyEdit ? qtyEdit : isCheckDelete ? 0 : addedItem.qty,
      only_verify: true,
    };

    setTimeout(() => {
      axios
        .put(`${process.env.REACT_APP_API_URL}/inbound_order_lot/${addedItem.id}`, params)
        .then((response) => {
          if (response.status === 200) {
            setValidateState(response.data ? response.data.verification : false);
            setValidateMessage(response.data ? response.data.message : null);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }, 2000);
    //
  }

  function onCancelEdit() {
    setQTYEdit(null);
    setIsEdit((prev) => !prev);
  }

  return (
    <React.Fragment>
      <div className={`col-12 mt-2 d-none d-md-flex flex-row  ${addedItem.isDelete && "divider-delete"}`}>
        <div className="col-custom-4 col-custom-sm-100">{index}</div>
        <div className="col-custom-26 col-custom-sm-100">
          {addedItem.code} - {addedItem.name}
        </div>
        <div className="col-custom-10 col-custom-sm-100 justify-content-center">
          {isEdit ? <InputNumber min={1} controls={false} stringMode={false} style={{ height: 30, borderRadius: 5, display: "flex", alignItems: "center" }} value={qtyEdit ? qtyEdit : addedItem.qty} onChange={onChangeQTY} /> : addedItem.qty}
        </div>
        <div className="col-custom-10 col-custom-sm-100 justify-content-center">{addedItem.unit}</div>
        <div className="col-custom-12 col-custom-sm-100">{dayjs(addedItem.received_date).format("DD/MM/YYYY")}</div>
        <div className="col-custom-12 col-custom-sm-100">{addedItem.receiver_name}</div>
        <div className="col-custom-14 col-custom-sm-100" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", display: "unset" }}>
          {addedItem.remark ? addedItem.remark : "-"}
        </div>
        <div className="col-custom-12 col-custom-sm-100 justify-content-end">
          {!addedItem.isDelete && addedItem.id && (
            <div className={`edit-icon mr-2 ${isEdit && "edit-icon-is-edit"}`} onClick={() => (isEdit ? validateChangeQTY() : setIsEdit((prev) => !prev))}>
              {isEdit ? <CheckOutlined /> : <EditOutlined />}
            </div>
          )}
          <div className={addedItem.id ? "remove-icon" : "remove-icon-none-id"} onClick={() => (isEdit ? onCancelEdit() : addedItem.id ? onDeleteItemLot() : onDeleteLotNoneID())}>
            {addedItem.isDelete ? <RedoOutlined /> : isEdit ? <CloseOutlined /> : <DeleteOutlined />}
          </div>
        </div>
      </div>
      <table class="table table-striped table-mobile-responsive table-mobile-sided d-block d-md-none">
        <tbody>
          <tr>
            <td data-content="ลำดับ">{index}</td>
            <td data-content="รายการ">
              {addedItem.code} - {addedItem.name}
            </td>
            <td data-content="จำนวน">
              {isEdit ? <InputNumber min={1} controls={false} stringMode={false} style={{ height: 30, borderRadius: 5, display: "flex", alignItems: "center" }} value={qtyEdit ? qtyEdit : addedItem.qty} onChange={onChangeQTY} /> : addedItem.qty}
            </td>
            <td data-content="หน่วย">{addedItem.unit}</td>
            <td data-content="วันที่ตรวจรับ">{dayjs(addedItem.received_date).format("DD/MM/YYYY")}</td>
            <td data-content="ผู้ตรวจรับ">{addedItem.receiver_name}</td>
            <td data-content="หมายเหตุ">
              <div style={{ whiteSpace: "normal", overFlow: "hidden", textOverFlow: "ellipsis", display: "unset" }}>{addedItem.remark ? addedItem.remark : "-"}</div>
            </td>
            <td data-content="">
              {!addedItem.isDelete && addedItem.id && (
                <div className={`edit-icon mr-2 ${isEdit && "edit-icon-is-edit"}`} onClick={() => (isEdit ? validateChangeQTY() : setIsEdit((prev) => !prev))}>
                  {isEdit ? <CheckOutlined /> : <EditOutlined />}
                </div>
              )}
              <div className={addedItem.id ? "remove-icon" : "remove-icon-none-id"} onClick={() => (isEdit ? onCancelEdit() : addedItem.id ? onDeleteItemLot() : onDeleteLotNoneID())}>
                {addedItem.isDelete ? <RedoOutlined /> : isEdit ? <CloseOutlined /> : <DeleteOutlined />}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {DisplayModal()}
    </React.Fragment>
  );
}

import React, { useEffect, useState, useContext } from "react";
import moment from "moment";

import { APP_TITLE_MAINTENANCE } from "../../../constants.js";
import { DatePicker, Divider, Empty, Input, Pagination, Select, Table, Tooltip } from "antd";
import { formatApiDate } from "../../../functions/Date";

import axios from "axios";
import Spinner from "../../global/Spinner.js";
import dayjs from "dayjs";
import ExportExcel from "../../global/ExportExcel";

import "./style.css";
import { toExcelOrderRepairReport } from "./instanceFunction.jsx";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const columns = [
  {
    title: "ชื่อสินค้า",
    dataIndex: "order_item",
    key: "order_item",
    width: 220,
    sorter: (a, b) => (a.order_item.name < b.order_item.name ? 1 : -1),
    render: (order_item) => {
      return (
        <div
          style={{
            zIndex: 999,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
          }}
        >
          <Tooltip placement="top" title={order_item.name}>
            {order_item.name}
          </Tooltip>
        </div>
      );
    },
  },
  {
    title: "ชื่อสถานี",
    dataIndex: "station_name",
    key: "station_name",
    ellipsis: {
      showTitle: false,
    },
    sorter: (a, b) => (a.order_repair.quotation.client_station.name < b.order_repair.quotation.client_station.name ? 1 : -1),
    render: (_, data) => {
      return (
        <Tooltip placement="topLeft" title={data.order_repair.quotation.client_station.name}>
          {data.order_repair.quotation.client_station.name}
        </Tooltip>
      );
    },
  },
  {
    title: "จังหวัด",
    dataIndex: "province",
    key: "province",
    sorter: (a, b) => (a.order_repair.quotation.client_station.address.province.name < b.order_repair.quotation.client_station.address.province.name ? 1 : -1),
    render: (_, data) => {
      return data.order_repair.quotation.client_station.address.province.name;
    },
  },
  {
    title: "วันที่แจ้ง",
    dataIndex: "informed_date",
    key: "informed_date",
    sorter: (a, b) => (a.informed_date < b.informed_date ? 1 : -1),
    render: (informed_date) => {
      return moment(informed_date).format("DD-MM-YYYY");
    },
  },
  {
    title: "สาเหตุ",
    dataIndex: "comment_cause",
    key: "comment_cause",
    ellipsis: {
      showTitle: false,
    },
    sorter: (a, b) => (a.comment_cause < b.comment_cause ? 1 : -1),
    render: (comment_cause) => {
      return (
        <Tooltip placement="topLeft" title={comment_cause}>
          {comment_cause || "-"}
        </Tooltip>
      );
    },
  },
  {
    title: "วันที่แก้ไขเสร็จ",
    dataIndex: "repaired_date",
    key: "repaired_date",
    sorter: (a, b) => (a.repaired_date < b.repaired_date ? 1 : -1),
    render: (repaired_date) => {
      return repaired_date ? moment(repaired_date).format("DD-MM-YYYY") : "-";
    },
  },
  {
    title: "วิธีแก้ไข",
    dataIndex: "comment_how_to_fix",
    key: "comment_how_to_fix",
    ellipsis: {
      showTitle: false,
    },
    sorter: (a, b) => (a.comment_how_to_fix < b.comment_how_to_fix ? 1 : -1),
    render: (comment_how_to_fix) => {
      return (
        <Tooltip placement="topLeft" title={comment_how_to_fix}>
          {comment_how_to_fix || "-"}
        </Tooltip>
      );
    },
  },
  {
    title: "หมายเหตุ",
    dataIndex: "comment",
    key: "comment",
    ellipsis: {
      showTitle: false,
    },
    sorter: (a, b) => (a.comment < b.comment ? 1 : -1),
    render: (comment) => {
      return (
        <Tooltip placement="topLeft" title={comment}>
          {comment || "-"}
        </Tooltip>
      );
    },
  },
];

export default function MaintenanceReport({ props }) {
  const [routeDate, setRouteDate] = useState(null);
  const [routeItem, setRouteItem] = useState(null);
  const [routeOrderCode, setRouteOrderCode] = useState(null);

  const [itemList, setItemList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [dataList, setDataList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    getItemList();
  }, []);

  function onChangePagination(e) {
    setCurrentPage(e);
    setIsLoading(true);
    getDataList(routeDate, routeItem, routeOrderCode, e - 1);
  }

  function getParamsQuery() {
    const filter_date = [new URLSearchParams(props.location.search).get("date")?.split("_")[0], new URLSearchParams(props.location.search).get("date")?.split("_")[1]];
    const item_id = parseInt(new URLSearchParams(props.location.search).get("item_id"));
    const order_code = new URLSearchParams(props.location.search).get("order_code");
    setRouteOrderCode(order_code);
    setRouteDate(filter_date);
    setRouteItem(item_id);

    getDataList(filter_date, item_id, order_code);
  }

  function getDataList(filter_date, item_id, order_code, page = null) {
    const date = filter_date ? filter_date : routeDate;
    let params = {
      filter: {
        offset: page !== null ? page * pageSize : currentPage * pageSize,
        limit: pageSize,
        informed_date: date,
        item_id: item_id,
        order_code: order_code,
      },
    };

    if (!date[0] && !date[1]) delete params.filter.informed_date;
    if (!item_id) delete params.filter.item_id;
    if (!order_code) delete params.filter.order_code;

    axios
      .get(`${process.env.REACT_APP_API_URL}/order_repair`, { params: params })
      .then((response) => {
        console.log("response", response);
        if (response.status === 200) {
          setDataList(response.data);
          setDataCount(response.headers["x-total-count"]);
        }

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  function handleSubmit(e) {
    e.preventDefault();
    let item = routeItem || null;
    let date = routeDate?.length > 0 && routeDate[0] && routeDate[1] ? `${dayjs(routeDate[0]).format("YYYY-MM-DD")}_${dayjs(routeDate[1]).format("YYYY-MM-DD")}` : null;
    let order_code = routeOrderCode || null;
    let params = `?${item ? `item_id=${item}` : ""}${date ? `&date=${date}` : ""}${order_code ? `&order_code=${order_code}` : ""}`;
    props.history.replace({
      pathname: `${props.location.pathname}`,
      search: `${params}`,
    });
  }

  function getItemList() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/items`)
      .then((response) => {
        if (response.status === 200) setItemList(response.data);
        else setIsLoading(false);
        getParamsQuery();
      })
      .catch((err) => {
        setIsLoading(false);
        getParamsQuery();
      });
  }

  function clearForm() {
    setRouteDate(null);
    setRouteItem(null);
    setRouteOrderCode(null);
    props.history.replace({
      pathname: `${props.location.pathname}`,
      search: ``,
    });
  }

  return (
    <>
      <Spinner loading={isLoading} />
      <div className="p-3 home-scrollable">
        <div className="row align-items-end">
          <div className="col-12 pb-2 d-flex flex-row align-items-end">
            <span className="header-title d-md-block my-0">{APP_TITLE_MAINTENANCE}</span>
          </div>
        </div>

        <div className="card-box light p-3">
          <form onSubmit={handleSubmit}>
            <div className="row" style={{ marginBottom: 10 }}>
              <div className="col-4 col-md-2 mt-2">
                <label>สินค้า</label>
              </div>
              <div className="col-8 col-md-10 mt-2 date-picker-wrapper">
                {itemList.length > 0 ? (
                  <Select
                    allowClear
                    className="w-100"
                    showSearch
                    optionFilterProp="label"
                    options={itemList.map((item) => {
                      return {
                        value: item.id,
                        label: `${item.item_category.dc_id === 0 ? "(Vendor)" : "(DC)"} ${item.name}`,
                      };
                    })}
                    value={routeItem || null}
                    placeholder={"ค้นหา..."}
                    onChange={(e) => {
                      setRouteItem(e);
                    }}
                  />
                ) : (
                  <div className="border" style={{ padding: 5 }}>
                    กำลังโหลดรายการสินค้า...
                  </div>
                )}
                {/* onChange={(data)=>{ getQueryDC(data)}} */}
              </div>
            </div>
            <div className="row">
              <div className="col-4 col-md-2 mt-2" style={{ alignContent: "center" }}>
                <label>วันที่แจ้งซ่อม</label>
              </div>
              <div className="col-8 col-md-4 mt-2 date-picker-wrapper">
                <RangePicker
                  className="antd-datepicker-custom w-100"
                  placeholder={["เริ่มต้น", "จนถึง"]}
                  format={"DD/MM/YYYY"}
                  value={routeDate && routeDate[0] && routeDate[1] ? [dayjs(routeDate[0], dateFormat), dayjs(routeDate[1], dateFormat)] : null}
                  onChange={(r) => {
                    setRouteDate(r);
                  }}
                />
              </div>
              <div className="col-4 col-md-2 mt-2" style={{ alignContent: "center" }}>
                <label>เลขที่ออเดอร์</label>
              </div>
              <div className="col mt-2 date-picker-wrapper">
                <Input
                  className="antd-datepicker-custom w-100"
                  placeholder="เลขที่ Order"
                  format={"DD/MM/YYYY"}
                  value={routeOrderCode ? routeOrderCode : ""}
                  onChange={(r) => {
                    setRouteOrderCode(r.target.value);
                  }}
                />
              </div>
            </div>
            <div className="row" style={{ marginTop: -15, marginRight: -25 }}>
              <div class="w-100 mt-4 mr-4 d-flex flex-row  justify-content-end">
                <button type="submit" class="btn btn-primary ml-2">
                  Search
                </button>
                <button
                  onClick={(e) => {
                    clearForm();
                  }}
                  type="button"
                  class="btn btn-danger ml-2"
                >
                  Clear
                </button>
              </div>
            </div>
          </form>
        </div>
        <Divider />
        {dataList.length > 0 && (
          <div style={{ textAlign: "right", marginBottom: 10, marginTop: -10 }}>
            <ExportExcel data={[toExcelOrderRepairReport(dataList)]} content="Export" filename={`รายงานการแจ้งซ่อม`} customClass={"btn btn-success ml-2"} />
          </div>
        )}
        <div>
          {dataList.length > 0 || isLoading ? (
            <React.Fragment>
              <Table pagination={false} columns={columns} dataSource={dataList} loading={isLoading} />
              <div style={{ textAlign: "right", marginTop: 10 }}>
                {console.log("dataCount", dataCount)}
                <Pagination onChange={(e) => onChangePagination(e)} current={currentPage} pageSize={pageSize} total={dataCount} showSizeChanger={false} />
              </div>
            </React.Fragment>
          ) : (
            <Empty description="ไม่มีข้อมูล, กรุณาเลือกสินค้าและช่วงเวลาที่ต้องการ." />
          )}
        </div>
      </div>
    </>
  );
}

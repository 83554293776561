import * as React from 'react'
import { useState, useEffect } from 'react'
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  DeleteWithConfirmButton,
  TextField,
  EditButton,
  TextInput,
  NumberInput,
  required,
  Button,
  Link,
  SelectInput,
  Tab,
  ReferenceManyField,
  FunctionField,
  useListContext,
  BooleanInput,
  TabbedForm,
  FormTab,
  useRedirect,
  useRefresh,
} from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import BookIcon from '@material-ui/icons/Book'
import { WARRANTY_TYPES, getExpenseDetails } from '../../models/ItemCategories'

import { Form } from 'react-final-form'
import { Box, Button as CoreButton, InputAdornment } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ContentFilter from '@material-ui/icons/FilterList'

import { exporter } from '../../functions/reactAdmin'

import { EditToolbar, EditActions } from './Default'
import axios from 'axios'

export const ItemCategoryIcon = BookIcon

const WARRANTTY_TYPE_OPTIONS = Object.keys(WARRANTY_TYPES).map((key) => {
  return { id: key, name: WARRANTY_TYPES[key] }
})

const ItemCategoryFilter = (props) => {
  return props.context === 'button' ? <ItemCategoryFilterButton {...props} /> : <ItemCategoryFilterForm {...props} />
}

const ItemCategoryFilterButton = () => {
  const { showFilter } = useListContext()
  return (
    <CoreButton size="small" color="primary" onClick={() => showFilter('main')} startIcon={<ContentFilter />}>
      Filter
    </CoreButton>
  )
}

const ItemCategoryFilterForm = ({ open }) => {
  const { displayedFilters, filterValues, setFilters, hideFilter } = useListContext()

  if (!displayedFilters.main) return null

  const onSubmit = (values) => {
    if (Object.keys(values).length > 0) {
      setFilters(values)
    } else {
      hideFilter('main')
    }
  }

  const resetFilter = () => {
    setFilters({}, [])
  }

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box mt={8} />
            <Box display="flex" alignItems="flex-end" mb={1}>
              <Box component="span" mr={3}>
                <BooleanInput source="is_active" label="เปิดใช้งาน" alwaysOn />
              </Box>
              <Box component="span" mr={2}>
                {/* Full-text search filter. We don't use <SearchFilter> to force a large form input */}
                <TextInput
                  resettable
                  helperText={false}
                  source="sys_name"
                  label="Search ชื่อ"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box component="span" mr={2} mb={1.5}>
                <CoreButton variant="outlined" color="primary" type="submit">
                  Filter
                </CoreButton>
              </Box>
              <Box component="span" mb={1.5}>
                <CoreButton variant="outlined" onClick={resetFilter}>
                  Close
                </CoreButton>
              </Box>
            </Box>
          </form>
        )}
      </Form>
    </div>
  )
}

export const ItemCategoryList = (props) => (
  <List {...props} filters={<ItemCategoryFilter />} exporter={exporter}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="sys_name" label="Name" />
      <TextField source="is_active" label="เปิดใช้งาน" />
      <TextField source="unit" />
      <EditButton basePath="/item_categories" />
      <DeleteWithConfirmButton undoable={false} />
    </Datagrid>
  </List>
)

const styles = {
  button: {
    marginTop: '1em',
    marginBottom: '1em',
    color: '#fff',
    backgroundColor: '#3f51b5',
  },
}

const AddNewFeeButton = withStyles(styles)(({ classes, record }) => (
  <Button
    className={classes.button}
    variant="raised"
    component={Link}
    to={{
      pathname: '/item_category_fees/create',
      search: `?item_category_id=${record.id}`,
    }}
    label="Add a fee"
  ></Button>
))

const FeeDisplay = ({ basePath, record, resource }) => {
  var text = getExpenseDetails(record)

  return (
    <React.Fragment>
      {/* <FunctionField
        label="Types"
        render={record => `${ITEM_CATE_FEE_TYPES[record.fee_type]} - `}
      /> */}
      <FunctionField label="Price" render={(record) => text} />
    </React.Fragment>
  )
}

export const ItemCategoryEdit = (props) => {
  const redirect = useRedirect()
  const refresh = useRefresh()
  const [typeSelect, setTypeSelect] = useState(null)
  const [categories, setCategories] = useState(null)
  const formRef = React.useRef()
  useEffect(() => {
    getCategory()
  }, [])

  function getCategory() {
    axios.get(`${process.env.REACT_APP_API_URL}/item_categories`).then((response) => {
      const categoty = []

      response.data.forEach((cate) => {
        if (cate.dc_id === 0) {
          categoty.push(cate)
        }
      })

      setCategories(categoty)
    })
  }

  const ItemCategoryTitle = ({ record }) => {
    setTypeSelect(record.dc_id)
    return <span>ItemCategory {record ? `"${record.sys_name}"` : ''}</span>
  }

  return categories ? (
    <Edit {...props} undoable={false} actions={<EditActions />} title={<ItemCategoryTitle />}>
      <TabbedForm toolbar={<EditToolbar />}>
        <FormTab label="Item Category" ref={formRef}>
          <BooleanInput label="เปิดใช้งาน" source="is_active" />
          <TextInput disabled source="id" />
          <SelectInput
            source="dc_id"
            id="dc_id"
            label="ประเภทหมวดหมู่สินค้า"
            disabled
            choices={[
              {
                id: 0,
                name: 'สินค้าผู้ผลิต',
              },
              {
                id: 1,
                name: 'สินค้า DC',
              },
            ]}
          />

          {typeSelect === 1 && (
            <SelectInput
              source="parent_id"
              label="หมวดหมู่ที่เกี่ยวข้อง"
              choices={[
                ...categories.map((cate) => {
                  return { id: cate.id, name: cate.sys_name }
                }),
              ]}
            />
          )}
          <TextInput source="code" label="รหัสสินค้า (ใช้ออกรหัสทรัพย์สิน)" />
          <TextInput source="ori_name" label="ชื่อเริ่มต้น" validate={required()} />
          <TextInput source="sys_name" label="ชื่อที่ใช้ในระบบ" validate={required()} />
          <TextInput source="unit" />
          <Box display="flex" style={{ width: 500 }}>
            <Box flex={1} style={{ width: 200 }}>
              <NumberInput source="warranty_value" label="ระยะเวลารับประกัน" min={0} />
            </Box>
            <Box flex={1} ml="0.5em">
              <SelectInput source="warranty_type" label="หน่วย" choices={WARRANTTY_TYPE_OPTIONS} />
            </Box>
          </Box>
          <TextInput multiline source="remark" fullWidth />
        </FormTab>
        <FormTab label="Fee">
          <ReferenceManyField addLabel={false} reference="item_category_fees" target="item_category_id" sort={{ field: 'position', order: 'ASC' }}>
            <Datagrid>
              <TextField source="position" label="ตำแหน่ง" />
              <TextField source="name" />
              <FeeDisplay />
              <EditButton />
              <DeleteWithConfirmButton
                {...props}
                undoable={false}
                onSuccess={(response) => {
                  redirect(`/item_categories/${response.data.item_category_id}/1`)
                  refresh()
                }}
              />
            </Datagrid>
          </ReferenceManyField>
          <AddNewFeeButton />
        </FormTab>
        <FormTab label="MR Remark">
          <TextInput label="Remark" multiline source="mr_remark" fullWidth />
        </FormTab>
      </TabbedForm>
    </Edit>
  ) : (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 20,
        marginTop: 60,
        height: 60,
        background: 'white',
        borderRadius: '10px',
        fontWeight: 'bold',
        fontSize: 18,
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      }}
    >
      กำลังโหลดหมวดหมู่สินค้า...
    </div>
  )
}

export const ItemCategoryCreate = (props) => {
  const [typeSelect, setTypeSelect] = useState(null)
  const [categories, setCategories] = useState(null)
  const handleOnTypeChance = (e) => {
    setTypeSelect(e.target.value)
  }

  useEffect(() => {
    getCategory()
  }, [])

  function getCategory() {
    axios.get(`${process.env.REACT_APP_API_URL}/item_categories`).then((response) => {
      const categoty = []

      response.data.forEach((cate) => {
        if (cate.dc_id === 0) {
          categoty.push(cate)
        }
      })

      setCategories(categoty)
    })
  }

  return categories ? (
    <Create title="Create a ItemCategory" actions={<EditActions />} {...props}>
      <SimpleForm>
        <BooleanInput label="เปิดใช้งาน" source="is_active" defaultValue={true} />
        <SelectInput
          source="dc_id"
          label="ประเภทหมวดหมู่สินค้า"
          validate={required()}
          onChange={handleOnTypeChance}
          choices={[
            {
              id: 0,
              name: 'สินค้าผู้ผลิต',
            },
            {
              id: 1,
              name: 'สินค้า DC',
            },
          ]}
        />
        {typeSelect === 1 && (
          <SelectInput
            source="parent_id"
            label="หมวดหมู่ที่เกี่ยวข้อง"
            choices={[
              {
                id: 0,
                name: 'ไม่มีหมวดหมู่ที่เกี่ยวข้อง',
              },
              ...categories.map((cate) => {
                return { id: cate.id, name: cate.sys_name }
              }),
            ]}
          />
        )}

        {typeSelect !== null && <TextInput source="code" label="รหัสสินค้า (ใช้ออกรหัสทรัพย์สิน)" />}
        {typeSelect !== null && <TextInput source="ori_name" label="ชื่อเริ่มต้น" validate={required()} />}
        {typeSelect !== null && <TextInput source="sys_name" label="ชื่อที่ใช้ในระบบ" validate={required()} />}
        {typeSelect !== null && <TextInput source="unit" />}
        {typeSelect !== null && (
          <Box display="flex" style={{ width: 500 }}>
            <Box flex={1} style={{ width: 200 }}>
              <NumberInput source="warranty_value" label="ระยะเวลารับประกัน" min={0} />
            </Box>
            <Box flex={1} ml="0.5em">
              <SelectInput source="warranty_type" label="หน่วย" choices={WARRANTTY_TYPE_OPTIONS} />
            </Box>
          </Box>
        )}
        {typeSelect !== null && <TextInput multiline source="remark" fullWidth />}
      </SimpleForm>
    </Create>
  ) : (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 20,
        marginTop: 60,
        height: 60,
        background: 'white',
        borderRadius: '10px',
        fontWeight: 'bold',
        fontSize: 18,
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      }}
    >
      กำลังโหลดหมวดหมู่สินค้า...
    </div>
  )
}

import React from "react";
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import * as XLSX from "xlsx";
import { withRouter } from "react-router-dom";
import { FORMAT_DATE, API_RESOURCES, FORMAT_DATE_EXCEL_IN, ORDER_STATES } from "../../../constants";

// Components
import ExportExcel from "../../global/ExportExcel";
import OrderIndex from "./OrderIndex";

// Functions
import { formatApiDate } from "../../../functions/Date";
import { getHeaderTotalPageCount } from "../../../functions/Response";
import { uniqueKey } from "../../../functions/Random";
import { objectIsEmpty } from "../../../functions/Object";
import { getDataRange } from "../../../functions/Excel";

import { ORDER_PROGRESS_FLAGS, toExcelMassUploadPoGr } from "../../../models/Orders";

const OPTIONS_STATES = Object.keys(ORDER_STATES).map((key) => {
  return { value: ORDER_STATES[key].name, label: ORDER_STATES[key].name };
});

const DEFAULT_SORTING = "po_date";
const DEFAULT_FLAG_FILTER = {};
for (let key of Object.keys(ORDER_PROGRESS_FLAGS)) {
  DEFAULT_FLAG_FILTER[key] = null;
}

const ORDER_PER_PAGE = 10;

let searchParams = {};

class OrderIndexContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      flag_filters: cloneDeep(DEFAULT_FLAG_FILTER),
      selected_state: props.state ? OPTIONS_STATES.filter((elm) => props.state === elm.value) : null,
      selected_id: "",
      selected_station_name: "",
      selected_sorting: DEFAULT_SORTING,
      selected_sorting_type: "",
      selected_sorting_reversed: true,
      selected_date_form: props.link_date_from ? moment(props.link_date_from).toDate() : "",
      selected_date_to: props.link_date_to ? moment(props.link_date_to).toDate() : "",
      selected_preview_po: null,
      modal_preview: false,
      collapsed_id: null,
      orders: [],
      order_excel: [],
      is_refiltering: true,
      is_exporting: false,
      is_importing: false,
      is_showing_more: false,
      is_displaying_basket: false,

      // pagination
      page: 0,
      per_page: ORDER_PER_PAGE,
      page_count: 1,
      resetKey: uniqueKey(),

      // export
      selected_orders: {},
      export_orders: null,
      import_error: null,
      import_data: null,

      is_loading: false,
    };
  }

  componentDidMount() {
    if (this.props.location.search) {
      this.getPathParams();
    } else {
      this.getOrder();
      this.getOrderExcel();
    }
  }

  componentDidUpdate() {
    if (this.state.is_refiltering) {
      this.getOrder();
      // this.getOrderExcel();
    }
  }

  getPathParams() {
    const query = new URLSearchParams(this.props.location.search).get("query") || "";
    const start_date = new URLSearchParams(this.props.location.search).get("start_date") || "";
    const end_date = new URLSearchParams(this.props.location.search).get("end_date") || "";
    const page = new URLSearchParams(this.props.location.search).get("page") || 0;
    const state = new URLSearchParams(this.props.location.search).get("state") || "";
    const type_filter = new URLSearchParams(this.props.location.search).get("type_filter") || "";

    let search_state = [];

    if (query !== "") searchParams["query"] = query;
    if (type_filter !== "") searchParams["type_filter"] = type_filter;
    if (start_date) searchParams["start_date"] = start_date;
    if (end_date) searchParams["end_date"] = end_date;
    if (page !== 0) searchParams["page"] = page;

    Object.keys(DEFAULT_FLAG_FILTER).forEach((flag) => {
      const flag_search = new URLSearchParams(this.props.location.search).get(flag) || null;
      if (flag_search) DEFAULT_FLAG_FILTER[flag] = flag_search === "true";
      else DEFAULT_FLAG_FILTER[flag] = null;
    });

    if (state) {
      state.split(",").forEach((search) => {
        search_state.push({ value: search, label: search });
      });
    }

    this.setState({
      query: query,
      selected_sorting_type: type_filter,
      selected_date_form: start_date ? moment(start_date).toDate() : "",
      selected_date_to: end_date ? moment(end_date).toDate() : "",
      page: page ? parseInt(page) - 1 : 0,
      flag_filters: DEFAULT_FLAG_FILTER,
      selected_state: search_state,
    });

    // this.getOrder();
  }

  getDataOrder(id) {
    return axios.get(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.po}/${id}`).then((res) => {
      return res.data;
    });
  }

  vatCalc(cost) {
    var vat = cost * 0.07
    return vat + cost;
  }
  generateExcelData(data) {
    let styleHead = {
      "fill": {
        "fgColor": {
          "rgb": "FFC5E0B4"
        }
      },
      "font": {
        "sz": "11",
        "bold": true
      },
      "alignment": {
        "horizontal": "center"
      }
    }
    let styleHeadNoBold = {
      "fill": {
        "fgColor": {
          "rgb": "FFC5E0B4"
        }
      },
      "font": {
        "sz": "11",
        "bold": false
      },
      "alignment": {
        "horizontal": "center"
      }
    }
    let styleSubHead = {
      "fill": {
        "fgColor": {
          "rgb": "F9EB94"
        }
      },
      "font": {
        "sz": "11",
        "bold": true
      },
      "alignment": {
        "horizontal": "center"
      }
    }
    let styleSubTxt = {
      "font": {
        "sz": "11",
        "bold": true
      },
      "alignment": {
        "horizontal": "center"
      }
    }
    let styleSubTxtNoBold = {
      "font": {
        "sz": "11",
        "bold": false
      },
      "alignment": {
        "horizontal": "center"
      }
    }
    let excel_data = [
      {
        "sheet_name": "รายการ",
        "dataSet": [
          {
            "columns": [
              {
                "title": "Order Code",
                "width": {
                  "wch": 25
                },
                "style": {
                  "fill": {
                    "fgColor": {
                      "rgb": "95b3d7"
                    }
                  },
                  "font": {
                    "sz": "12",
                    "bold": true
                  },
                  "alignment": {
                    "horizontal": "center"
                  }
                }
              },
              {
                "title": "ORC Number",
                "width": {
                  "wch": 50
                },
                "style": {
                  "fill": {
                    "fgColor": {
                      "rgb": "95b3d7"
                    }
                  },
                  "font": {
                    "sz": "12",
                    "bold": true
                  },
                  "alignment": {
                    "horizontal": "center"
                  }
                }
              },
              {
                "title": "Client Station",
                "width": {
                  "wch": 50
                },
                "style": {
                  "fill": {
                    "fgColor": {
                      "rgb": "95b3d7"
                    }
                  },
                  "font": {
                    "sz": "12",
                    "bold": true
                  },
                  "alignment": {
                    "horizontal": "center"
                  }
                }
              },
              {
                "title": "ราคาขายทั้งออเดอร์ (ไม่รวม Vat)",
                "width": {
                  "wch": 15
                },
                "style": {
                  "fill": {
                    "fgColor": {
                      "rgb": "95b3d7"
                    }
                  },
                  "font": {
                    "sz": "12",
                    "bold": true
                  },
                  "alignment": {
                    "horizontal": "center"
                  }
                }
              },
              {
                "title": "ราคาทุนทั้งออเดอร์ (ไม่รวม Vat)",
                "width": {
                  "wch": 15
                },
                "style": {
                  "fill": {
                    "fgColor": {
                      "rgb": "95b3d7"
                    }
                  },
                  "font": {
                    "sz": "12",
                    "bold": true
                  },
                  "alignment": {
                    "horizontal": "center"
                  }
                }
              },
            ],
            "data": [],
          }
        ]
      }
    ];

    for (let index = 0; index < data.length; index++) {
      if (data[index].dc_id != 0) {
        excel_data[0].dataSet[0].data.push(
          [
            {
              "value": (data[index].code) ? data[index].code : "-",
              "style": styleHead
            },
            {
              "value": (data[index].order_code) ? data[index].order_code : "-",
              "style": styleHead
            },
            {
              "value": (data[index].quotation.client_station.name) ? data[index].quotation.client_station.name : "-",
              "style": styleHead
            },
            {
              "value": (data[index].total_sales) ? data[index].total_sales : 0,
              "style": styleHead
            },
            {
              "value": (data[index].total_cost) ? data[index].total_cost : 'N/A',
              "style": (data[index].total_cost) ? styleHead : styleHeadNoBold
            }
          ])
        excel_data[0].dataSet[0].data.push(
          [
            {
              "value": "",
              "style": styleSubHead
            },
            {
              "value": "หมวดหมู่สินค้า",
              "style": styleSubHead
            },
            {
              "value": "รหัสสินค้า - ชื่อสินค้า",
              "style": styleSubHead
            },
            {
              "value": "ราคาขาย",
              "style": styleSubHead
            },
            {
              "value": "ราคาทุน",
              "style": styleSubHead
            },
          ])

        let orders = data[index].purchasing_order_items;
        for (let i = 0; i < orders.length; i++) {
          try {
            let dataItem = orders[i];
            let notNA = _.some(dataItem.inventories, function (o) { return o.added_unit_cost != null });
            let cost = 0;
            if (notNA) {
              let all = _.map(dataItem.inventories, function (o) {
                o.cost = o.added_unit_cost;
                return o.calc_unit_price + o.added_unit_cost
              });
              cost = _.sum(all);
            }
            let response = {
              category: (dataItem.item_category) ? dataItem.item_category.sys_name : '-',
              item: `${dataItem.code} - ${dataItem.name}`,
              // [each(((purchasing_order_item.price+purchasing_order_item.expense_ratio)*qty+sum(expense_ratio_arr)/100))]
              price: dataItem.price * dataItem.qty,
              cost: (notNA) ? cost : 'N/A'
            }
            excel_data[0].dataSet[0].data.push(
              [
                {
                  "value": "",
                  "style": styleSubTxt
                },
                {
                  "value": response.category,
                  "style": styleSubTxt
                },
                {
                  "value": response.item,
                  "style": styleSubTxt
                },
                {
                  "value": response.price,
                  "style": styleSubTxt
                },
                {
                  "value": response.cost,
                  "style": response.cost != 'N/A' ? styleSubTxt : styleSubTxtNoBold
                },
              ])

          } catch (error) {
            console.log(error)
          }
        }
      }
    }
    return excel_data
  }

  getOrderExcel(resetPage) {
    let { page, per_page, selected_sorting, selected_sorting_type, selected_sorting_reversed, selected_state, selected_date_form, selected_date_to, query, flag_filters, is_exporting } = this.state;
    let de_per_page = per_page;
    let target_page = resetPage ? 0 : page;
    let range_start = target_page * de_per_page;
    let sort_dir = selected_sorting_reversed ? "DESC" : "ASC";
    let options = {
      sort: JSON.stringify([selected_sorting, sort_dir]),
      filter: {
        "state": ["in progress", "completed"]
      },
      // range: JSON.stringify([range_start, range_start + de_per_page - 1]),
    };
    if (!objectIsEmpty(selected_state)) {
      options.filter.state = selected_state.map((elm) => {
        return elm.value;
      });
    }

    if (selected_sorting_type || is_exporting) {
      options.filter.dc_id = is_exporting ? 0 : selected_sorting_type
    }

    if (selected_date_form || selected_date_to) {
      let start_date = selected_date_form ? moment(selected_date_form).format(FORMAT_DATE) : null;
      let end_date = selected_date_to ? moment(selected_date_to).format(FORMAT_DATE) : null;
      options.filter.po_date = [start_date, end_date];
    } else {
      let start_date = moment().subtract(1, 'month').format(FORMAT_DATE);
      let end_date = moment().format(FORMAT_DATE);
      options.filter.po_date = [start_date, end_date];
    }
    if (query) {
      options.filter.query = query;
    }


    // Flag filters
    Object.keys(flag_filters).map((key) => {
      if (flag_filters[key] != null) {
        options.filter[`order_flag.${key}`] = flag_filters[key];
        options.filter[`dc_id`] = 0;
      }
    });

    // this.setState({
    //   is_refiltering: false,
    // });

    axios
      .get(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.po}${is_exporting ? "/for_export" : ""}`, {
        params: options,
      })
      .then((response) => {
        let listData = response.data;
        let data = _.map(listData, (item) => {
          return item
        });

        this.setState({
          order_excel: this.generateExcelData(data),
          // is_refiltering: false,
        });
      })
      .catch((error) => {
        // console.log(error);
        // this.setState({
        //   is_refiltering: false,
        // });
      })
      .then(() => {
        // always executed
      });
  }

  getOrder(resetPage) {
    let { page, per_page, selected_sorting, selected_sorting_type, selected_sorting_reversed, selected_state, selected_date_form, selected_date_to, query, flag_filters, is_exporting } = this.state;
    let de_per_page = per_page;
    let target_page = resetPage ? 0 : page;
    let range_start = target_page * de_per_page;
    let sort_dir = selected_sorting_reversed ? "DESC" : "ASC";
    let options = {
      sort: JSON.stringify([selected_sorting, sort_dir]),
      filter: {},
      range: JSON.stringify([range_start, range_start + de_per_page - 1]),
    };
    if (!objectIsEmpty(selected_state)) {
      options.filter.state = selected_state.map((elm) => {
        return elm.value;
      });
    }

    if (selected_sorting_type) {
      options.filter.dc_id = selected_sorting_type
    }

    if (selected_date_form || selected_date_to) {
      let start_date = selected_date_form ? moment(selected_date_form).format(FORMAT_DATE) : null;
      let end_date = selected_date_to ? moment(selected_date_to).format(FORMAT_DATE) : null;
      options.filter.po_date = [start_date, end_date];
    }
    if (query) {
      options.filter.query = query;
    }
    // Flag filters
    Object.keys(flag_filters).map((key) => {
      if (flag_filters[key] != null) {
        options.filter[`order_flag.${key}`] = flag_filters[key];
        options.filter[`dc_id`] = 0;
      }
    });

    axios
      .get(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.po}${is_exporting ? "/for_export" : ""}`, {
        params: options,
      })
      .then((response) => {
        let page_count = getHeaderTotalPageCount(response, this.state.per_page);
        let listData = _.map(response.data, (item) => {
          item.cost_sale = (item.total_sales) ? this.vatCalc(item.total_sales) : 0
          item.cost_vat = (item.total_cost) ? this.vatCalc(item.total_cost) : 0
          return item
        });
        this.setState({
          orders: listData,
          is_refiltering: false,
          page_count: page_count,
          resetKey: resetPage ? uniqueKey() : this.state.resetKey,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          is_refiltering: false,
        });
      })
      .then(() => {
        // always executed
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { is_exporting } = this.state;

    delete searchParams["page"];

    if (!is_exporting) {
      this.addSearchParams(searchParams);
    }
    this.setState({ is_refiltering: true });
  };

  onClickProcurement = () => {
    let { flag_filters } = this.state;
    // let to_date = new Date();
    // let from_date = new Date();
    // from_date.setDate(from_date.getDate() - 7);
    flag_filters["purchase_price_completed"] = true;

    this.setState({
      is_exporting: true,
      selected_sorting_type: 0,
      // selected_date_form: from_date,
      // selected_date_to: to_date,
      flag_filters: flag_filters,
      is_refiltering: true,
    });

    setTimeout(() => {
      this.getOrderExcel();
    }, 1500);
  };

  onCloseProcurement = () => {
    let { flag_filters } = this.state;
    flag_filters["purchase_price_completed"] = null;

    this.addSearchParams(searchParams);

    this.setState({
      is_exporting: false,
      is_displaying_basket: false,
      // selected_date_form: '',
      // selected_date_to: '',
      selected_orders: {},
      flag_filters: flag_filters,
      export_orders: null,
      is_refiltering: true,
    });
  };

  onEnter = () => {
    // this.addSearchParams(searchParams);
    this.setState({ is_refiltering: true });
  };

  addSearchParams(params, path = this.props.location.pathname) {
    let searchParams = "";
    Object.keys(params).forEach((key, index) => {
      searchParams = searchParams + `${Object.keys(params).length > 1 && index !== 0 ? "&" : ""}${key}=${params[key]}`;
    });

    this.props.history.replace({ pathname: `${path}`, search: searchParams });
  }

  onChangeID = (e) => {
    this.setState({ selected_id: e.target.value });
  };

  onChangeState = (e) => {
    if (e.length > 0) {
      let state_search = [];
      e.forEach((state) => {
        state_search.push(state.value);
      });
      searchParams["state"] = state_search.join(",");
    } else {
      delete searchParams["state"];
    }

    this.setState({ selected_state: e });
  };

  onChangeSorting = (e) => {
    this.setState({ selected_sorting: e.value });
  };

  onChangeSortingType = (e) => {

    if (e.value) {
      searchParams["type_filter"] = e.value;
    } else {
      delete searchParams["type_filter"];
    }

    this.setState({ selected_sorting_type: e.value });
  }

  onChangeStationName = (e) => {
    this.setState({ selected_station_name: e.target.value });
  };

  onChangeQuery = (e) => {
    if (e.target.value) {
      searchParams["query"] = e.target.value;
    } else {
      delete searchParams["query"];
    }

    this.setState({ query: e.target.value });
  };

  onChangeFromDate = (date) => {
    searchParams["start_date"] = moment(date).format("MM/DD/YYYY");
    this.setState({ selected_date_form: date });
  };

  onChangeToDate = (date) => {
    searchParams["end_date"] = moment(date).format("MM/DD/YYYY");
    this.setState({ selected_date_to: date });
  };

  onClickClear = () => {
    const { is_exporting } = this.state;
    this.setState({
      selected_state: null,
      selected_period: "",
      selected_id: "",
      selected_station_name: "",
      selected_sorting: DEFAULT_SORTING,
      selected_sorting_reversed: true,
      selected_date_form: "",
      selected_sorting_type: "",
      selected_date_to: "",
      page: 0,
      per_page: ORDER_PER_PAGE,
      query: "",
      flag_filters: cloneDeep(DEFAULT_FLAG_FILTER),
      is_refiltering: true,
      resetKey: uniqueKey(),
    });

    for (let key of Object.keys(ORDER_PROGRESS_FLAGS)) {
      DEFAULT_FLAG_FILTER[key] = null;
    }

    searchParams = {};
    if (!is_exporting)
      this.props.history.replace({
        pathname: `${this.props.location.pathname}`,
        search: null,
      });
  };

  export_orders_to_excel(selected_orders) {
    let order_arr = [];
    for (let key in selected_orders) {
      order_arr.push(selected_orders[key]);
    }

    return toExcelMassUploadPoGr(order_arr);
  }

  onSelectAllRecords = (is_selected) => {
    let { orders, selected_orders } = this.state;
    orders.forEach((order) => {
      if (is_selected) {
        selected_orders[order.id] = order;
      } else {
        delete selected_orders[order.id];
      }
    });
    this.setState({
      export_orders: this.export_orders_to_excel(selected_orders),
      selected_orders: selected_orders,
    });
  };

  onClickOrder = (id, order_type_key) => {
    if (!this.state.is_exporting) {
      if (order_type_key && order_type_key === "dc") {
        this.addSearchParams(searchParams, `/${API_RESOURCES.order}/dc/${id}`);
      } else {
        this.addSearchParams(searchParams, `/${API_RESOURCES.order}/${id}`);
      }
    } else {
      let { selected_orders, orders } = this.state;
      if (selected_orders[id]) {
        delete selected_orders[id];
      } else {
        let order = orders.filter((elm) => elm.id === id)[0];
        selected_orders[id] = order;
      }

      this.setState({
        export_orders: this.export_orders_to_excel(selected_orders),
        selected_orders: selected_orders,
      });
    }
  };

  onShowOrderDetails = (id) => {
    if (id !== this.state.collapsed_id) {
      this.setState({ collapsed_id: id });
    } else {
      this.setState({ collapsed_id: null });
    }
  };

  onClickSort = (type) => {
    let new_toggle = this.state.selected_sorting === type ? !this.state.selected_sorting_reversed : false;
    this.setState({
      selected_sorting: type,
      selected_sorting_reversed: new_toggle,
      is_refiltering: true,
    });
  };

  setFlagFilters = (filters) => {
    Object.keys(filters).forEach((key) => {
      if (filters[key] !== null) {
        DEFAULT_FLAG_FILTER[key] = filters[key];
        searchParams[key] = filters[key];
      } else {
        DEFAULT_FLAG_FILTER[key] = null;
        delete searchParams[key];
      }
    });

    this.setState({ flag_filters: filters });
  };
  /* ================== Export Procurement Template (Start) ================== */
  handleDisplayingBasket = (enable) => {
    this.setState({ is_displaying_basket: enable });
  };

  onClearSelectedOrders = () => {
    this.setState({ selected_orders: {} });
  };

  on_export_orders(btn_class) {
    let { selected_orders } = this.state;
    let count = Object.keys(selected_orders).length;
    return <ExportExcel data={[this.state.export_orders]} content={`Export (${count})`} filename={`OR_Mass_Upload_Auto_PO_GR_${formatApiDate(new Date())}`} customClass={btn_class} onExport={this.onCloseProcurement} disabled={count === 0} />;
  }
  /* ================== Export Procurement Template (End) ==================== */

  /* ================== Import Procurement Template (Start) ================== */
  handleProcurementModal = (isOpened) => {
    this.setState({
      is_importing: isOpened,
      import_error: null,
      import_data: null,
      is_loading: false,
    });
  };

  onImportFile = (e) => {
    const file = e.target.files[0];

    if (file) {
      var reader = new FileReader();
      reader.onload = (e) => {
        try {
          var data = e.target.result;
          let readedData = XLSX.read(data, { type: "binary" });
          const wsname = readedData.SheetNames[0];
          const ws = readedData.Sheets[wsname];
          ws["!ref"] = getDataRange(ws);

          /* Convert array to json*/
          const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

          let start_row = 5;
          let contract_code_col = 0;
          let client_name_col = 6;
          let order_code_col = 26;
          let po_code_col = 46;
          let gr_code_col = 48;
          let delivery_no_col = 53;
          let delivery_date_col = 54;
          let billing_no_col = 55;
          let billing_date_col = 56;
          let invoice_issued_date_col = 57;
          let invoice_tracking_no_col = 58;
          let today = formatApiDate(new Date());
          let import_data = {};
          for (let index in dataParse) {
            if (index < start_row) {
              continue;
            }
            if (!objectIsEmpty(dataParse[index])) {
              let row = dataParse[index];
              if (!(row[order_code_col] in import_data)) {
                let order_code_arr = row[order_code_col] ? row[order_code_col].split("-") : [],
                  order_code = order_code_arr[0];
                import_data[row[order_code_col]] = {
                  order_code: "" + order_code,
                  client_name: row[client_name_col],
                  contract_code: "" + row[contract_code_col],
                  po_code: row[po_code_col] ? "" + row[po_code_col] : null,
                  gr_code: row[gr_code_col] ? "" + row[gr_code_col] : null,
                  po_date: today,
                  gr_date: today,
                };
                if (row[delivery_no_col]) {
                  import_data[row[order_code_col]].db_delivery_code = row[delivery_no_col];
                  import_data[row[order_code_col]].db_delivery_date = row[delivery_date_col] ? moment(row[delivery_date_col], FORMAT_DATE_EXCEL_IN).format(FORMAT_DATE) : today;
                }
                if (row[billing_no_col]) {
                  import_data[row[order_code_col]].db_billing_code = row[billing_no_col];
                  import_data[row[order_code_col]].db_billing_date = row[billing_date_col] ? moment(row[billing_date_col], FORMAT_DATE_EXCEL_IN).format(FORMAT_DATE) : today;
                }
                if (row[invoice_issued_date_col]) {
                  import_data[row[order_code_col]].invoice_issued_date = moment(row[invoice_issued_date_col], FORMAT_DATE_EXCEL_IN).format(FORMAT_DATE);
                }
                if (row[invoice_tracking_no_col]) {
                  import_data[row[order_code_col]].invoice_tracking_no = row[invoice_tracking_no_col];
                }
              }
            }
          }
          this.setState({ import_error: null, import_data: import_data });
        } catch (error) {
          console.log(error);
          this.setState({ import_error: error.message });
        }
      };
      reader.readAsBinaryString(file);
    }
  };

  onConfirmImportFile = () => {
    let { import_data } = this.state;
    axios
      .put(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.po}/import_po_gr`, {
        po_grs: import_data,
      })
      .then((response) => {
        this.handleProcurementModal(false);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ is_loading: false });
      });
    this.setState({ is_loading: true });
  };
  /* ================== Import Procurement Template (End) ================== */

  /* ============== Pagination (start) ============== */
  handlePageClick = (data) => {
    if (data.selected !== 0) searchParams["page"] = data.selected + 1;
    else delete searchParams["page"];

    this.setState({ is_refiltering: true, page: data.selected });
  };
  /* ============== Pagination (end) ============== */

  /* ============== More Filters ============== */
  handleShowFiltersMD = (is_showing_more) => {
    this.setState({ is_showing_more: is_showing_more });
  };
  /* ============== More Filters ============== */

  render() {
    return (
      <OrderIndex
        {...this.state}
        handleSubmit={this.handleSubmit}
        onClickProcurement={this.onClickProcurement}
        onCloseProcurement={this.onCloseProcurement}
        onEnter={this.onEnter}
        onChangeID={this.onChangeID}
        onChangeState={this.onChangeState}
        onChangeSorting={this.onChangeSorting}
        onChangeSortingType={this.onChangeSortingType}
        onChangeStationName={this.onChangeStationName}
        onChangeQuery={this.onChangeQuery}
        onChangeFromDate={this.onChangeFromDate}
        onChangeToDate={this.onChangeToDate}
        onClickClear={this.onClickClear}
        onSelectAllRecords={this.onSelectAllRecords}
        onClickOrder={this.onClickOrder}
        onShowOrderDetails={this.onShowOrderDetails}
        onClickSort={this.onClickSort}
        setFlagFilters={this.setFlagFilters}
        handleDisplayingBasket={this.handleDisplayingBasket}
        onClearSelectedOrders={this.onClearSelectedOrders}
        on_export_orders={this.on_export_orders}
        handleProcurementModal={this.handleProcurementModal}
        onImportFile={this.onImportFile}
        onConfirmImportFile={this.onConfirmImportFile}
        handlePageClick={this.handlePageClick}
        handleShowFiltersMD={this.handleShowFiltersMD}
        addSearchParams={this.addSearchParams}
        searchParams={searchParams}
      />
    );
  }
}
export default withRouter(OrderIndexContainer);

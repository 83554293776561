import React from "react";
import axios from "axios";
import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { ORDER_STATES, API_RESOURCES } from "../../../constants.js";
import { OrderFormInfo } from "../OrderFormInfo";
import { OrderFormPayment } from "../OrderFormPayment";
import { OrderFormPurchasePrice } from "../OrderFormPurchasePrice";
import { OrderFormRepair } from "../OrderFormRepair";

// Models
import { init_order, validateOrder, validateDeliveryBilling, NEW_ORDER, DC_ORDER_TABS, validatePayment, validateInstallation, calTotalCost, is_complete, is_delivery_billing_disabled } from "../../../models/Orders";
import { getCategoryTotalExpense, isDiscount, isItemExpense, isItemDiscount, new_expense } from "../../../models/Quotations";
import { getQtyCategory, getWarrantyExpirationDate } from "../../../models/ItemCategories";

// Component
import { DocumentPreview } from "../../global/DocumentPreview.js";
import Spinner from "../../global/Spinner";
import ModalConfirmation from "../../global/ModalConfirmation";

// Functions
import { objectIsEmpty, objectIndexOf, toSnakeCase } from "../../../functions/Object";
import { uniqueKey } from "../../../functions/Random";
import { formatApiDate } from "../../../functions/Date";
import { solveFee, FEE_MULTIPLIER, solveFeeNew } from "../../../functions/FeePurchaseCalculation";
import { saveMultiple } from "../../../functions/Number";
import { OrderFormDelivery } from "./OrderFormDelivery.js";
import { OrderFormInstallation } from "./OrderFormInstallation.js";
import { OrderFormCostPrice } from "./OrderFormCostPrice.js";
import { withRouter } from "react-router-dom";

class OrderFormDc extends React.Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(props.location.search);
    const selected_tab = urlParams.get("tab");
    this.state = {
      current_tab: selected_tab ? DC_ORDER_TABS[selected_tab] : DC_ORDER_TABS.info,
      selected_id: parseInt(props.id),
      order: cloneDeep(NEW_ORDER),
      distance: null,
      disabled: false,
      is_canceling: false,
      is_loading: false,
      is_leaving: false,
      is_updated: false,
      is_changing_contract: false,
      errors: {},
      resetKey: uniqueKey(),
      resetKeyPurchasePricing: uniqueKey(),
      resetKeyNote: uniqueKey(),
      resetKeyPOdoc: "",

      //PO GR
      po_gr_contract_ids: [],

      // purchase price
      is_reset_contract_cate: false,
      is_reset_expense: false,

      load_cate: 0,
      cate_length: 0,
    };

    this.onChangeFormTab = this.onChangeFormTab.bind(this);
    this.onChangeOrderDate = this.onChangeOrderDate.bind(this);
    this.onChangeOrderDetails = this.onChangeOrderDetails.bind(this);

    // payment
    this.onSelectBank = this.onSelectBank.bind(this);
    this.onSelectPaymentMethod = this.onSelectPaymentMethod.bind(this);
    this.onChangePaymentDate = this.onChangePaymentDate.bind(this);
    this.onChangePaymentNote = this.onChangePaymentNote.bind(this);
    this.onUploadPaySlip = this.onUploadPaySlip.bind(this);
    this.onDeletePaySlip = this.onDeletePaySlip.bind(this);

    // Purchase Price
    this.onResetContractPrice = this.onResetContractPrice.bind(this);
    this.onChangeItemDetails = this.onChangeItemDetails.bind(this);
    this.onAddFee = this.onAddFee.bind(this);
    this.onChangeFeeDetails = this.onChangeFeeDetails.bind(this);
    this.onChangeContract = this.onChangeContract.bind(this);
    this.onDeleteExpense = this.onDeleteExpense.bind(this);

    // Manufacture
    this.onGetWarrantyCodes = this.onGetWarrantyCodes.bind(this);
    this.onDeleteWarrantyCodes = this.onDeleteWarrantyCodes.bind(this);
    this.onChangeItemManufactureDetails = this.onChangeItemManufactureDetails.bind(this);

    // note
    this.onChangeNote = this.onChangeNote.bind(this);

    // Repair Log
    this.onChangeRepairLog = this.onChangeRepairLog.bind(this);
    this.onAddRepairLog = this.onAddRepairLog.bind(this);
    this.onRemoveRepairLog = this.onRemoveRepairLog.bind(this);

    // submit
    this.onHandleSubmit = this.onHandleSubmit.bind(this);
    this.putPurchasingOrder = this.putPurchasingOrder.bind(this);

    // Cancel
    this.onHandleCancelModal = this.onHandleCancelModal.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onCancelOrder = this.onCancelOrder.bind(this);

    // back
    this.backToOrderIndex = this.backToOrderIndex.bind(this);
    this.onHandleModalBack = this.onHandleModalBack.bind(this);
    this.onClickBack = this.onClickBack.bind(this);
  }

  componentDidMount() {
    if (this.state.selected_id !== null) {
      this.getPurchasingOrder();
      // this.setState({ order: TEMP_ORDERS.filter(elm => elm.id === this.state.selected_id)[0], resetKey: uniqueKey()})
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { order, is_reset_expense } = this.state;
    // reset purchase prices all items in categories
    if (!objectIsEmpty(this.state.is_reset_contract_cate)) {
      let { quotation } = order;
      let item_ids = [];
      order.purchasing_order_items.forEach((elm) => item_ids.push(elm.item_id));
      this.state.is_reset_contract_cate.forEach((reset_cate) => {
        this.setContractByItemCategory(
          {
            is_active: true,
            approved_date: formatApiDate(quotation.approved_date),
            item_category_id: reset_cate,
            province_id: quotation.client_station.address.province_id,
            item_ids: item_ids,
          },
          is_reset_expense,
          "update"
        );
      });

      this.setState({ is_reset_contract_cate: false });
    }

    // check order's status
    if (order.state !== ORDER_STATES.completed.name && is_complete(order.order_flag)) {
      order.state = ORDER_STATES.completed.name;
      this.setState({ order: order });
    }
  }

  getPurchasingOrder() {
    this.setState({ is_loading: true });
    axios
      .get(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.po}/${this.state.selected_id}`)
      .then((response) => {
        let current_contract = {};
        let current_installation_date = {};
        let order = init_order(response.data);
        let distance = order.quotation.client_station.distance;
        if (order.order_flag.init_completed !== true) {
          for (var key in order.order_item_categories) {
            this.reCalExpense(order.purchasing_order_items, order.order_item_categories[key], distance);
          }

          order.total_cost = calTotalCost(order);
        }
        for (let key in order.order_item_categories) {
          current_contract[key] = order.order_item_categories[key].contract_id;
        }
        order.purchasing_order_items.forEach((item) => {
          current_installation_date[item.id] = item.installation_date;
        });

        order.order_flag.init_completed = true;

        this.setState({
          order: order,
          current_contract: current_contract,
          current_installation_date: current_installation_date,
          po_gr_contract_ids: this.getPoGrContractOptionIds(order),
          distance: distance,
          disabled: order.state === ORDER_STATES.cancelled.name,
          resetKeyPOdoc: uniqueKey(),
          resetKey: uniqueKey(),
          resetKeyNote: uniqueKey(),
        });

        this.checkItemContract(order, "get");
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          is_loading: false,
        });
      })
      .then(function () {
        // always executed
      });
  }

  putPurchasingOrder(newState) {
    let { order, is_leaving, current_installation_date } = this.state;
    let changed_installation_date = this.checkInstallationChanged(order, current_installation_date);

    const error = this.checkSaveError(order.purchasing_order_items);

    if (error !== "") {
      alert(error);
      return;
    }

    if (newState !== undefined) {
      order.state = newState;
    }
    order.user_id = this.props.current_user.id;
    axios
      .put(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.po}/${order.id}`, {
        changed_installation_date: changed_installation_date,
        po: order,
      })
      .then((response) => {
        // console.log("response", response);
        if (is_leaving) {
          window.location.href = `/${API_RESOURCES.order}`;
        } else {
          if (response.data) {
            order.s3_base_url = response.data.s3_base_url;
            if (response.data.order_po_grs.length > 0) {
              order.order_po_grs = response.data.order_po_grs;
            }
          }
          let current_contract = {};
          for (let key in order.order_item_categories) {
            current_contract[key] = order.order_item_categories[key].contract_id;
          }
          let current_installation_date = {};
          order.purchasing_order_items.forEach((po_item) => {
            current_installation_date[po_item.id] = po_item.installation_date;
          });

          this.setState({
            order: order,
            current_contract: current_contract,
            current_installation_date: current_installation_date,
            is_loading: false,
            is_updated: false,
            resetKeyPOdoc: uniqueKey(),
            errors: {},
          });

          alert("Order Saved!");
          this.checkItemContract(order, "save");
        }
      })
      .catch((error) => {
        this.setState({ is_loading: false });
      });
    this.setState({ is_loading: true });
  }

  checkSaveError(items) {
    let errors = "";

    items.forEach((item) => {
      if (item.contract_price === 0 && !item.is_active) {
        errors = errors + `- ราคาขาขายของ ${item.name} เป็น 0 บาท และไม่พบสินค้านี้ในสัญญาที่เลือก\n`;
      } else if (item.contract_price !== 0 && !item.is_active) {
        errors = errors + `- ไม่พบสินค้า ${item.name} นี้ในสัญญาที่เลือก\n`;
      }
    });

    return errors;
  }

  onHandleSubmit(e, newState) {
    let { order, is_changing_contract } = this.state;
    let changed_contract = this.checkContractChange();
    let is_changed_contract = changed_contract.length > 0;

    this.setState({ cate_length: 0, load_cate: 0 });

    if (is_changed_contract && !is_changing_contract) {
      this.onHandleChangeContractModal(true);
    } else {
      if (is_changed_contract) {
        let params = [];
        changed_contract.forEach((item) => {
          if (item.prev_contract_id)
            params.push({
              po_item_id: item.po_item.id,
              prev_contract_id: item.prev_contract_id,
              next_contract_id: item.next_contract_id,
              client_station_id: order.quotation.client_station.id,
            });
        });
        if (params.length > 0) {
          axios
            .post(`${process.env.REACT_APP_API_URL}/log/change_contract`, {
              params: params,
            })
            .then((response) => {
              this.onHandleChangeContractModal(false);
              let errors = validateOrder(order, newState);
              if (objectIsEmpty(errors)) {
                this.putPurchasingOrder(newState);
              } else {
                alert("กรุณากรอกข้อมูลให้ครบถ้วน");
                this.setState({ errors: errors });
              }
            });
        } else {
          this.onHandleChangeContractModal(false);
          let errors = validateOrder(order, newState);
          if (objectIsEmpty(errors)) {
            this.putPurchasingOrder(newState);
          } else {
            alert("กรุณากรอกข้อมูลให้ครบถ้วน");
            this.setState({ errors: errors });
          }
        }
      } else {
        let errors = validateOrder(order, newState);
        if (objectIsEmpty(errors)) {
          this.putPurchasingOrder(newState);
        } else {
          alert("กรุณากรอกข้อมูลให้ครบถ้วน");
          this.setState({ errors: errors });
        }
      }
    }
  }

  onChangeOrderDetails(e) {
    let order = this.state.order;
    order = {
      ...order,
      [e.target.name]: e.target.value,
    };
    order = this.setDeliveryBillingCompletedFlag(order);
    if (e.target.name === "order_code") {
      order.order_flag.po_payment_compeleted = validatePayment(order);
    }
    this.setState({ order: order, is_updated: true });
  }

  /********* Note (Start) *********/
  onChangeNote(note) {
    let { order } = this.state;
    let index = objectIndexOf(order.order_notes, "page_nb", note.page_nb);

    if (index < 0) {
      order.order_notes.push(note);
    } else {
      order.order_notes[index] = note;
    }

    this.setState({ order: order, is_updated: true });
  }
  /********* Note (End) *********/

  /********* Repair Log (Start) *********/
  setRepairLogUser(repair_log) {
    repair_log.user_id = this.props.current_user.id;
    repair_log.user = {
      firstname: this.props.current_user.firstname,
      lastname: this.props.current_user.lastname,
    };
    return repair_log;
  }

  onChangeRepairLog(id, name, value) {
    let { order } = this.state;
    let index = objectIndexOf(order.order_repairs, "id", id);
    order.order_repairs[index] = {
      ...order.order_repairs[index],
      [name]: value,
    };
    order.order_repairs[index] = this.setRepairLogUser(order.order_repairs[index]);
    this.setState({ order: order, is_updated: true });
  }

  onAddRepairLog(new_log) {
    let { order } = this.state;
    let de_new_log = this.setRepairLogUser(new_log);
    order.order_repairs.push(de_new_log);
    this.setState({ order: order, is_updated: true });
  }

  onRemoveRepairLog(id) {
    let { order } = this.state;
    order.order_repairs = order.order_repairs.filter((elm) => elm.id !== id);
    this.setState({ order: order, is_updated: true });
  }
  /********* Repair Log (End) *********/

  onChangeQuotationDetails = (e, field) => {
    let { order } = this.state;
    order.quotation[field] = {
      ...order.quotation[field],
      [e.target.name]: e.target.value,
    };

    this.setState({ order: order, is_updated: true });
  };

  onChangeCustomerStationDetails(e) {
    let { order } = this.state;
    order.quotation.client_station = {
      ...order.quotation.client_station,
      [e.target.name]: e.target.value,
    };

    this.setState({ order: order, is_updated: true });
  }

  onChangeFormTab(tab) {
    this.setState({ current_tab: tab });
  }

  onChangeOrderDate(date, field) {
    let order = this.state.order;
    order[field] = formatApiDate(date);
    if (field === "invoice_received_date") {
      order = this.onValidateInvoice(order);
    }
    this.setState({ order: order });
  }

  /********* Payment (Start) *********/
  onSelectBank(value, e) {
    let { order } = this.state;
    order.payment_method.bank_id = value;
    this.setState({ order: order, is_updated: true });
  }

  onSelectPaymentMethod(value) {
    let { order } = this.state;
    order.payment_method.method_type = value;
    order.payment_method.note = "";
    order.payment_method.bank_id = null;
    this.setStatePayment(order);
  }

  onChangePaymentDate(date, field) {
    let { order } = this.state;
    order.payment_method[field] = formatApiDate(date);
    this.setStatePayment(order);
  }

  onChangePaymentNote(e) {
    let { order } = this.state;
    order.payment_method.note = e.target.value;
    this.setState({ order: order, is_update: true });
  }

  onUploadPaySlip(key) {
    let { order } = this.state;
    order.payment_method.s3_slip_url = key;
    this.setStatePayment(order);
  }

  onDeletePaySlip() {
    let { order } = this.state;
    order.payment_method.s3_slip_url = null;
    this.setStatePayment(order);
  }

  setStatePayment(order) {
    order.order_flag.po_payment_compeleted = validatePayment(order);
    this.setState({ order: order, is_update: true });
  }
  /********* Payment (End) *********/

  /********* Purchase Price (Start) *********/
  onResetContractPrice(item_category_id_arr) {
    this.setState({
      is_reset_contract_cate: item_category_id_arr,
      is_reset_expense: item_category_id_arr[0],
    });
  }

  confirmPurchasePrices = (value) => {
    let order = this.state.order;
    order.order_flag.purchase_price_completed = value;

    this.setState({
      order: order,
      resetKeyPurchasePricing: uniqueKey(),
      is_update: true,
    });
  };

  reCalExpense(items, item_category, distance) {
    let target_items = items.filter((item) => item.item_category_id === item_category.item_category_id && !item.is_mbom);
    let sum_qty = target_items.reduce((sum, item) => (sum += parseInt(item.qty)), 0);

    if (target_items.length > 0) {
      // recal fee qty (start)
      item_category.item_category_expenses
        .filter((expense) => !isDiscount(expense))
        .forEach((elm, index) => {
          if (elm.is_new !== true) {
            let input_qty = sum_qty;
            if (isItemExpense(elm.resource_type)) {
              let target_item = items.filter((item) => item.item_id === elm.resource_id)[0];
              input_qty = target_item ? target_item.qty : 0;
            }
            let caled_qtys = getQtyCategory(target_items, elm, distance, input_qty);
            if (elm.is_custom_qty !== true) {
              elm.qty = caled_qtys.qty;
            }
            elm.item_qty = caled_qtys.item_qty;
          }
        });
      // recal fee qty (end)

      // recal item discount qty (start)
      let discountGroupByName = _.groupBy(item_category.item_category_expenses, function (elm) {
        if (!elm.is_custom_qty && !elm.is_new && isItemDiscount(elm)) {
          return elm.name;
        } else {
          return "";
        }
      });
      Object.keys(discountGroupByName).forEach((key) => {
        if (key !== "") {
          let all_qty_in_group = 0;
          discountGroupByName[key].forEach((elm, index) => {
            all_qty_in_group += parseInt(items.filter((item) => item.item_id === elm.resource_id)[0].qty);
            if (index !== 0) {
              elm.item_qty = 0;
            }
          });

          let elm = discountGroupByName[key][0];
          let input_qty = sum_qty;
          if (isItemExpense(elm.resource_type)) {
            input_qty = all_qty_in_group;
          }
          let caled_qtys = getQtyCategory(target_items, elm, distance, input_qty);
          if (elm.is_custom_qty !== true) {
            elm.qty = caled_qtys.qty;
          }
          elm.item_qty = caled_qtys.item_qty;
        }
      });
      // recal item discount qty (end)

      // set qty to 0 if there is a match discount with a fee (start)
      let GroupByName = _.groupBy(item_category.item_category_expenses, function (elm) {
        return elm.name;
      });
      Object.keys(discountGroupByName).forEach((key) => {
        if (key !== "" && GroupByName[key].length >= 2) {
          let group_sum = getCategoryTotalExpense(GroupByName[key], true);
          if (group_sum >= 0) {
            let discount = GroupByName[key].filter((elm) => isDiscount(elm))[0];
            let fee = GroupByName[key].filter((elm) => !isDiscount(elm))[0];
            if (fee && discount) {
              //======= Method 3 per qty ========
              if (fee.method_type === 3) {
                if (fee.is_custom_qty !== true) {
                  fee.qty = fee.qty - discount.qty;
                }
                discount.qty = 0;
              }
              //======= Method 5 per km * qty ========
              if (fee.method_type === 5) {
                fee.item_qty = fee.item_qty - discount.item_qty;
                discount.item_qty = 0;
              }
            }
          }
        }
      });
      // set qty to 0 if there is a match discount with a fee (end)

      // recal item's retio
      let sum_expense = getCategoryTotalExpense(item_category.item_category_expenses, true);
      let item_qty_arr = target_items.map((item) => {
        return parseInt(item.qty) || 0;
      });
      let item_price_arr = target_items.map((item) => {
        return parseInt(item.price) || 0;
      });

      let suggest_fee = solveFeeNew(saveMultiple(sum_expense, FEE_MULTIPLIER), item_qty_arr, item_price_arr);
      let res_ratio = suggest_fee[2];
      let suggest_qty_4 = suggest_fee[4];
      // console.log(`${item_category.sys_name} solveFee: fee ${sum_expense}, items ${item_qty_arr}, remain ${suggest_fee[1] / FEE_MULTIPLIER}, suggest_qty_4: ${suggest_qty_4}`);

      // handle
      // [3,3] 200 =>  [3333, 3334, 3335] [3, 2, 1]
      // [3] 100 => [3333, 3334] [2, 1]]
      // [2,1] 100 => [3333, 3334] [2, 1]]
      if (suggest_qty_4.length !== item_qty_arr.length) {
        let suggest_qty_index = 0;
        let de_suggest_fee_2 = [];
        for (let index in item_qty_arr) {
          if (item_qty_arr[index] === suggest_qty_4[suggest_qty_index]) {
            de_suggest_fee_2.push(res_ratio[suggest_qty_index]);
            suggest_qty_index += 1;
          } else {
            let item_qty = item_qty_arr[index];
            let fee_arr = [];
            while (item_qty > 0) {
              item_qty -= suggest_qty_4[suggest_qty_index];
              for (let i = 0; i < suggest_qty_4[suggest_qty_index]; i++) {
                fee_arr.push(res_ratio[suggest_qty_index]);
              }
              suggest_qty_index += 1;
            }
            de_suggest_fee_2.push(fee_arr);
          }
        }
        res_ratio = de_suggest_fee_2;
      }

      res_ratio.forEach((value, index) => {
        if (Array.isArray(value)) {
          target_items[index].expense_ratio = 0;
          target_items[index].expense_ratio_arr = value;
        } else {
          target_items[index].expense_ratio = value;
          target_items[index].expense_ratio_arr = null;
        }
      });
    }
  }

  onChangeItemDetails(e, id) {
    let { order } = this.state;
    let { purchasing_order_items, order_item_categories } = order;
    let de_item_index = purchasing_order_items.findIndex((x) => x.id === id);

    purchasing_order_items[de_item_index] = {
      ...purchasing_order_items[de_item_index],
      [e.target.name]: e.target.value,
    };

    // Recalculate expenses
    this.reCalExpense(purchasing_order_items, order_item_categories[purchasing_order_items[de_item_index].item_category_id], this.state.distance);
    order.total_cost = calTotalCost(order);
    this.setState({ order: order, is_updated: true });
  }

  onAddFee(id, index, item_category_id) {
    let { order } = this.state;
    let category_expenses = order.order_item_categories[item_category_id].item_category_expenses;
    let edited_index = category_expenses.findIndex((x) => x.id === id);
    let clone_cost = cloneDeep(category_expenses[edited_index]);

    clone_cost.id = uniqueKey();
    clone_cost.position = index;
    clone_cost.is_new = false;
    category_expenses.push(clone_cost);
    // Reset
    category_expenses[edited_index] = cloneDeep(new_expense);
    category_expenses[edited_index].item_category_id = item_category_id;

    this.setState({ order: order, is_updated: true });
  }

  onChangeFeeDetails(e, id, item_category_id) {
    let { order } = this.state;
    let category_expenses = order.order_item_categories[item_category_id].item_category_expenses;
    let de_item_index = category_expenses.findIndex((x) => x.id === id);
    category_expenses[de_item_index] = {
      ...category_expenses[de_item_index],
      [e.target.name]: e.target.value,
    };

    // update flag
    if (e.target.name === "qty") {
      category_expenses[de_item_index].is_custom_qty = true;
    }

    // Recalculate expenses
    this.reCalExpense(order.purchasing_order_items, order.order_item_categories[item_category_id], this.state.distance);
    order.total_cost = calTotalCost(order);
    this.setState({ order: order, is_updated: true });
  }

  displayChangeContractConfirm() {
    if (this.state.is_changing_contract) {
      const content = (
        <React.Fragment>
          <div>
            เมื่อสัญญาถูกเปลี่ยนในออเดอร์ รายการที่แสดงให้กับผู้ผลิตรายเก่าจะแสดงเป็นสถานะ <b className="text-danger">ยกเลิก</b> และโยกย้ายไปยังผู้ผลิตรายใหม่ <b className="text-success">ในสถานะเดิม</b>
          </div>
          <br />
          <div className="text-primary">เพื่อความไม่สับสน ควรชี้แจ้งกับผู้ผลิตเองอีกครั้ง หากเปลี่ยนสัญญา</div>
          <br />
          <div>คลิก 'ตกลง' เพื่อ save</div>
        </React.Fragment>
      );
      return <ModalConfirmation isOpen={true} title="มีการเปลี่ยนสัญญาในออเดอร์" content={content} onConfirm={this.onHandleSubmit} onCancel={() => this.onHandleChangeContractModal(false)} onCustomAction={(e) => {}} />;
    }
  }

  onHandleChangeContractModal(value) {
    this.setState({ is_changing_contract: value });
  }

  checkContractChange() {
    let { order, current_contract } = this.state;
    let { order_item_categories, purchasing_order_items } = order;
    let key_contract_change = [];
    let item_contract_change = [];
    for (let key in order_item_categories) {
      if (order_item_categories[key].contract_id != current_contract[key]) {
        key_contract_change.push(parseInt(key));
      }
    }
    purchasing_order_items.forEach((item) => {
      // console.log(item);
      if (key_contract_change.includes(item.item_category_id)) {
        item_contract_change.push({
          po_item: item,
          prev_contract_id: current_contract[item.item_category_id],
          next_contract_id: order_item_categories[item.item_category_id].contract_id,
        });
      }
    });
    return item_contract_change;
  }

  onChangeContract(value, e, item_category_id) {
    let { order, current_contract } = this.state;
    order.order_item_categories[item_category_id].contract_id = value;
    const item_ids = order.purchasing_order_items.reduce((results, elm) => {
      if (elm.item_category_id == item_category_id) results.push(elm.item_id);
      return results;
    }, []);

    this.setContractByItemCategory(
      {
        id: value,
        item_category_id: item_category_id,
        item_ids: item_ids,
      },
      item_category_id,
      "update"
    );
    this.setState({
      order: order,
      po_gr_contract_ids: this.getPoGrContractOptionIds(order),
    });
  }

  checkItemContract(order, type = "none") {
    let { is_reset_expense } = this.state;

    let reset_cates = Object.keys(order.order_item_categories);
    let item_ids = [];

    this.setState({ cate_length: reset_cates.length, load_cate: 0 });

    if (order.purchasing_order_items && type !== "save") {
      let { quotation } = order;
      order.purchasing_order_items.map((elm) => item_ids.push(elm.item_id));
      reset_cates.forEcah((reset_cate) => {
        let contract_id = order.order_item_categories[reset_cate].contract_id;
        this.setContractByItemCategory(
          {
            is_active: true,
            approved_date: formatApiDate(quotation.approved_date),
            item_category_id: reset_cate,
            id: contract_id,
            // province_id: quotation.client_station.address.province_id,
            item_ids: item_ids,
          },
          is_reset_expense,
          type
        );
      });
    }
  }

  setContractByItemCategory(filter_params, is_reset_expense, type = "none") {
    // console.log("=====615 filter_params", filter_params);
    let filters = { for_mr: false };
    Object.keys(filter_params).forEach((key) => {
      filters[key] = filter_params[key];
    });

    let params = {
      sort: JSON.stringify(["created_at", "DESC"]),
      filter: filters,
    };

    this.setState({ is_loading: true });
    axios
      .get(`${process.env.REACT_APP_API_URL}/contract_by_cate`, {
        params: params,
      })
      .then((response) => {
        // console.log(response.data);
        let { order } = this.state;

        if (response.data.id) {
          let contract = response.data;
          let contract_cate = contract.contract_item_categories[0];
          let all_expenses = contract_cate.contract_item_category_fees;
          contract_cate.contract_items.forEach((elm) => {
            all_expenses = all_expenses.concat(elm.contract_item_fees, elm.contract_item_discounts);
          });

          if (type === "update") {
            order.order_item_categories[filter_params.item_category_id].contract_id = contract.id;
          }

          // Update expenses
          order = this.updateExpenseContractPrice(order, filter_params.item_category_id, all_expenses, is_reset_expense);
          // Update items
          order.purchasing_order_items
            .filter((elm) => elm.item_category_id == filter_params.item_category_id)
            .forEach((item) => {
              let contract_item = contract_cate.contract_items.filter((elm) => elm.item_id === item.item_id)[0];
              if (contract_item) {
                if (contract_item.price === 0) {
                  if (type === "update") {
                    item.contract_price = item.contract_price ? item.contract_price : contract_item.price;
                  }
                } else {
                  item.contract_price = item.contract_price ? item.contract_price : contract_item.price;
                }
                item.contract_item_id = contract_item.id;
                item.is_active = contract_item.is_active;
              } else {
                item.contract_price = item.contract_price ? item.contract_price : contract_item.price;
                item.contract_item_id = contract_item.id;
                item.is_active = contract_item.false;
              }
            });

          if (is_reset_expense) {
            // Recalculate expenses
            this.reCalExpense(order.purchasing_order_items, order.order_item_categories[is_reset_expense], this.state.distance);
          }

          this.setState({
            order: order,
            po_gr_contract_ids: this.getPoGrContractOptionIds(order),
            is_reset_expense: false,
            load_cate: this.state.load_cate + 1,
          });
        } else {
          this.setState({ load_cate: this.state.load_cate + 1 });
        }

        if (this.state.cate_length === this.state.load_cate && type !== "update") {
          this.setState({ is_loading: false });
          if (this.state.current_tab === 2) {
            this.errorAlert(order.purchasing_order_items);
          }
        }

        if (type === "update") {
          this.setState({ is_loading: false });
          if (this.state.current_tab === 2) {
            this.errorAlert(order.purchasing_order_items);
          }
        }
      })
      .catch(() => {
        this.setState({ is_loading: false });
      })
      .then(function () {
        // always executed
      });
  }

  checkInstallationChanged(order, current_installation_date) {
    let { purchasing_order_items } = order;
    let changed_installation_date = {};
    purchasing_order_items.forEach((item) => {
      if (current_installation_date[item.id] !== item.installation_date) {
        changed_installation_date[item.id] = current_installation_date[item.id];
      }
    });
    return changed_installation_date;
  }

  errorAlert(item) {
    let select_error = this.checkContrackStatus(item);
    if (select_error) {
      alert(select_error);
      this.setState({ is_loading: false });
      return;
    }
  }

  checkContrackStatus(items) {
    let errors = "";
    items.forEach((item) => {
      if (item.contract_price === 0 && item.is_active) {
        errors = errors + `- ราคาขาขายของ ${item.name} เป็น 0 บาท\n`;
      } else if (item.contract_price === 0 && !item.is_active) {
        errors = errors + `- ราคาขาขายของ ${item.name} เป็น 0 บาท และไม่พบสินค้านี้ในสัญญาที่เลือก\n`;
      } else if (item.contract_price !== 0 && !item.is_active) {
        errors = errors + `- ไม่พบสินค้า ${item.name} นี้ในสัญญาที่เลือก\n`;
      }
    });

    return errors;
  }

  updateExpenseContractPrice(order, item_category_id, all_expenses, reset) {
    let reset_exp = reset ? reset : false;
    order.order_item_categories[item_category_id].item_category_expenses.forEach((exp) => {
      if (exp.resource_type_id) {
        let contract_exp = all_expenses.filter((elm) => elm[`${toSnakeCase(exp.resource_type)}_id`] === exp.resource_type_id)[0];
        if (contract_exp) {
          exp.contract_price = exp.contract_price ? exp.contract_price : contract_exp.price;
        } else {
          console.warning("======== Not found contract_exp for %s", exp.name);
        }
      }
      if (reset_exp) {
        exp.is_custom_qty = false;
      }
    });

    return order;
  }

  onDeleteExpense(elm) {
    let { order } = this.state;
    let { order_item_categories } = order;
    order_item_categories[elm.item_category_id].item_category_expenses = order_item_categories[elm.item_category_id].item_category_expenses.filter((temp) => (isDiscount(elm) ? temp.name !== elm.name : temp.id !== elm.id));

    // Recalculate expenses
    this.reCalExpense(order.purchasing_order_items, order_item_categories[elm.item_category_id], this.state.distance);
    this.setState({ order: order, is_updated: true });
  }
  /********* Purchase Price (End) *********/

  /********* PO GR (Start) *********/
  getPoGrContractOptionIds(order) {
    let ids = Object.keys(order.order_item_categories).reduce((results, key) => {
      results.push(order.order_item_categories[key].contract_id);
      return results;
    }, []);

    return ids;
  }
  /********* PO GR (End) *********/

  /********* Delilvery Billing (Start) *********/
  setDeliveryBillingCompletedFlag(order) {
    order.order_flag.delivery_billing_completed = validateDeliveryBilling(order);
    return order;
  }
  /********* Delilvery Billing (End) *********/

  /********* Manufacture (Start) *********/
  onGetWarrantyCodes(item_id) {
    let { order } = this.state;
    let index = objectIndexOf(order.purchasing_order_items, "id", item_id);
    let order_item_cate = order.order_item_categories[order.purchasing_order_items[index].item_category_id];
    let asset_code = order.purchasing_order_items[index].item.asset_code ? order.purchasing_order_items[index].item.asset_code : order_item_cate.item_category.code;

    if (order_item_cate.contract_id) {
      let body = {
        quotation_item_id: order.purchasing_order_items[index].quotation_item_id,
        purchasing_order_item_id: item_id,
        item_category_id: order.purchasing_order_items[index].item_category_id,
        asset_code: asset_code,
        contract_id: order_item_cate.contract_id,
        qty: order.purchasing_order_items[index].qty,
      };

      if (order.purchasing_order_items[index].item.asset_code) {
        body.item_id = order.purchasing_order_items[index].item_id;
      }

      this.setState({
        is_loading: true,
      });
      axios
        .put(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.po}/get_warranty_codes`, body)
        .then((response) => {
          order.purchasing_order_items[index].warranty_codes = response.data;

          this.setState({
            order: order,
            is_loading: false,
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data) {
            alert(error.response.data.Message);
          }
          this.setState({
            is_loading: false,
          });
        })
        .then(function () {
          // always executed
        });
    } else {
      alert(`ไม่สามารถขอรหัสทรัพย์สินได้ เนื่องจากยังไม่ได้ระบุสัญญาใน ${order_item_cate.sys_name}`);
    }
  }

  onDeleteWarrantyCodes(item_id) {
    let { order } = this.state;
    let index = objectIndexOf(order.purchasing_order_items, "id", item_id);

    let body = {
      purchasing_order_item_id: item_id,
    };

    this.setState({
      is_loading: true,
    });
    axios
      .put(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.po}/delete_warranty_codes`, body)
      .then((response) => {
        order.purchasing_order_items[index].warranty_codes = [];

        this.setState({
          order: order,
          is_loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data) {
          alert(error.response.data.Message);
        }
        this.setState({
          is_loading: false,
        });
      })
      .then(function () {
        // always executed
      });
  }

  onChangeItemManufactureDetails(e, id) {
    let { order } = this.state;
    let { purchasing_order_items } = order;
    let de_item_index = purchasing_order_items.findIndex((x) => x.id === id);

    purchasing_order_items[de_item_index] = {
      ...purchasing_order_items[de_item_index],
      [e.target.name]: e.target.value,
    };
    purchasing_order_items[de_item_index].expiration_date = formatApiDate(getWarrantyExpirationDate(purchasing_order_items[de_item_index]));

    order.order_flag.installation_completed = validateInstallation(order);
    this.setState({ order: order, is_updated: true });
  }
  /********* Manufacture (End) *********/

  /* ========================================================= */
  /* Cancel (start) */
  onHandleCancelModal(value) {
    this.setState({ is_canceling: value });
  }

  onCancel() {
    if (this.state.order.cancel_note) {
      this.onCancelOrder(ORDER_STATES.cancelled.name);
    } else {
      alert("โปรดระบุเหตุผล");
    }
  }

  onCancelOrder(state) {
    let { order } = this.state;
    order.state = state;
    axios
      .put(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.po}/${order.id}/cancel`, {
        state: order.state,
        cancel_note: order.cancel_note,
      })
      .then((response) => {
        for (let index in order.purchasing_order_items) {
          order.purchasing_order_items[index].warranty_codes = [];
        }
        this.setState({
          order: order,
          is_loading: false,
          is_updated: false,
          is_canceling: false,
          disabled: true,
        });

        alert("Cancel Success!");
      })
      .catch((error) => {
        console.log(error);
        this.setState({ is_loading: false });
      });

    this.setState({ is_loading: true });
  }

  /* Cancel (end) */
  /* ========================================================= */

  /* ========================================================= */
  /* Back (start) */
  backToOrderIndex() {
    this.props.history.push({
      pathname: `/${API_RESOURCES.order}`,
      search: this.props.location.search,
    });
  }

  onHandleModalBack(value) {
    this.setState({ is_leaving: value });
  }

  onClickBack() {
    if (this.state.is_updated) {
      this.onHandleModalBack(true);
    } else {
      this.backToOrderIndex();
    }
  }

  displayBackConfirmation() {
    if (this.state.is_leaving) {
      return (
        <ModalConfirmation
          isOpen={true}
          title="บันทึกใบสั่งซื้อสินค้า ?"
          content="มีการแก้ไขที่ยังไม่ได้ถูกบันทึก ต้องการบันทึก?"
          btnDanger="ไม่บันทึก"
          onConfirm={this.onHandleSubmit}
          onCustomAction={this.backToOrderIndex}
          onCancel={() => this.onHandleModalBack(false)}
        />
      );
    }
  }
  /* Back (end) */
  /* ========================================================= */

  /* ========================================================= */
  /* Display (start) */
  actionBtns() {
    const SaveBtn = () => (
      <button type="button" className="btn btn-primary btn-sm" onClick={this.onHandleSubmit}>
        Save
      </button>
    );
    const SubmitBtn = () => (
      <button type="button" className="btn btn-primary btn-sm ml-2" onClick={(e) => this.onHandleSubmit(e, ORDER_STATES.completed.name)}>
        Complete
      </button>
    );
    const CancelBtn = () => (
      <button type="button" className="btn btn-danger btn-sm ml-2" onClick={() => this.onHandleCancelModal(true)}>
        Cancel
      </button>
    );
    const CanceledBtn = () => (
      <button type="button" className="btn btn-danger btn-sm ml-2" onClick={() => this.onHandleCancelModal(true)}>
        เหตุผล
      </button>
    );

    switch (this.state.order.state) {
      case ORDER_STATES.in_progress.name:
        return (
          <React.Fragment>
            <SaveBtn />
            <SubmitBtn />
            <CancelBtn />
          </React.Fragment>
        );
      case ORDER_STATES.cancelled.name:
        return (
          <React.Fragment>
            <CanceledBtn />
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <SaveBtn />
          </React.Fragment>
        );
    }
  }

  displayPages() {
    let { order, disabled, current_tab, resetKeyPOdoc, po_gr_contract_ids, resetKeyPurchasePricing, resetKeyNote, errors } = this.state;

    // console.log("OrderFormDC : this.state.order >>>", this.state.order);

    const displayPage = () => {
      switch (current_tab) {
        case DC_ORDER_TABS.selling_price:
          return (
            <React.Fragment>
              <div className="offset-lg-1 col-lg-10 px-3 pb-3 mt-sm-2">
                <OrderFormPayment
                  page_nb={current_tab}
                  order={order}
                  onChangeOrderDetails={this.onChangeOrderDetails}
                  onSelectPaymentMethod={this.onSelectPaymentMethod}
                  onSelectBank={this.onSelectBank}
                  onChangePaymentNote={this.onChangePaymentNote}
                  onChangePaymentDate={this.onChangePaymentDate}
                  onUploadPaySlip={this.onUploadPaySlip}
                  onDeletePaySlip={this.onDeletePaySlip}
                  onChangeNote={this.onChangeNote}
                  disabled={disabled}
                  resetKeyNote={resetKeyNote}
                />
                {/* Note */}
                <div className="card-box p-3 mt-1">
                  <div className="row">
                    <div className="col-4 col-md-2 pr-0 mt-2">
                      <label>หมายเหตุ (PDF)</label>
                    </div>
                    <div className="col-8 col-md-10 mt-2">
                      <textarea type="order-note" className="form-control" name="note" rows={5} onChange={this.onChangeOrderDetails} value={order.note} disabled={disabled}></textarea>
                    </div>
                  </div>
                </div>
                <DocumentPreview docName="ใบสั่งซื้อสินค้า" url={order.s3_base_url} resetKey={resetKeyPOdoc} />
              </div>
            </React.Fragment>
          );
        case DC_ORDER_TABS.cost_price:
          return (
            <OrderFormCostPrice
              page_nb={current_tab}
              order={order}
              disabled={disabled}
              confirmPurchasePrices={this.confirmPurchasePrices}
              onChangeNote={this.onChangeNote}
              onResetContractPrice={this.onResetContractPrice}
              onChangeFeeDetails={this.onChangeFeeDetails}
              onChangeItemDetails={this.onChangeItemDetails}
              onChangeContract={this.onChangeContract}
              onDeleteExpense={this.onDeleteExpense}
              onAddFee={this.onAddFee}
              key={resetKeyPurchasePricing}
            />
          );
        case DC_ORDER_TABS.delivery:
          return (
            <div className="offset-lg-1 col-lg-10 px-3 pb-3 mt-2">
              <OrderFormDelivery
                page_nb={current_tab}
                order={order}
                disabled={disabled || is_delivery_billing_disabled(order.order_flag)}
                warning={is_delivery_billing_disabled(order.order_flag) ? "หมายเหตุ: ยังไม่สามารถกรอกข้อมูลได้ กรุณาระบุข้อมูล PO/GR ให้เสร็จสมบูรณ์ก่อน" : null}
                onChangeOrderDetails={this.onChangeOrderDetails}
                onChangeOrderDate={this.onChangeOrderDate}
                onChangeNote={this.onChangeNote}
                resetKeyNote={resetKeyNote}
              />
            </div>
          );
        case DC_ORDER_TABS.installation:
          return (
            <OrderFormInstallation
              page_nb={current_tab}
              order={order}
              disabled={disabled}
              key={this.state.resetKey}
              onChangeNote={this.onChangeNote}
              onChangeItemManufactureDetails={this.onChangeItemManufactureDetails}
              onGetWarrantyCodes={this.onGetWarrantyCodes}
              onDeleteWarrantyCodes={this.onDeleteWarrantyCodes}
              resetKeyNote={resetKeyNote}
            />
          );
        case DC_ORDER_TABS.repair:
          return (
            <div className="col-12 px-3 pb-3 mt-2">
              <OrderFormRepair
                page_nb={current_tab}
                current_user={this.props.current_user}
                order={order}
                disabled={disabled}
                onChangeRepairLog={this.onChangeRepairLog}
                onAddRepairLog={this.onAddRepairLog}
                onRemoveRepairLog={this.onRemoveRepairLog}
                onChangeNote={this.onChangeNote}
              />
            </div>
          );
        default:
          return (
            <div className="offset-lg-1 col-lg-10 px-3 pb-3 mt-2">
              <OrderFormInfo
                errors={errors}
                page_nb={current_tab}
                order={order}
                disabled={disabled}
                onChangeQuotationDetails={this.onChangeQuotationDetails}
                onChangeOrderDetails={this.onChangeOrderDetails}
                onChangeOrderDate={this.onChangeOrderDate}
                onChangeNote={this.onChangeNote}
                resetKeyNote={resetKeyNote}
              />
            </div>
          );
      }
    };
    return <React.Fragment>{displayPage()}</React.Fragment>;
  }

  cancelOrder() {
    let { disabled, order } = this.state;
    let disable_cancel_modal = order.state === ORDER_STATES.cancelled.name;
    if (this.state.is_canceling) {
      const content = (cancel_note) => (
        <React.Fragment>
          <div className="row pb-2 mb-2">
            <div className="col-12 col-md-4 pt-md-2">
              <label>เหตุผล</label>
            </div>
            <div className="col pt-2">
              <textarea className="form-control" type="text" name="cancel_note" defaultValue={this.state.disabled ? cancel_note : ""} onChange={this.onChangeOrderDetails} rows="4" disabled={disable_cancel_modal}></textarea>
            </div>
          </div>
        </React.Fragment>
      );
      return <ModalConfirmation isOpen={true} title="ยกเลิกใบสั่งซื้อสินค้า" content={content(order.cancel_note)} onConfirm={disable_cancel_modal ? undefined : this.onCancel} onCancel={() => this.onHandleCancelModal(false)} />;
    }
  }

  displayCheckmark(active) {
    return <span className={`checkmark-wrapper ${active ? "active" : ""} symbol-checkmark`}></span>;
  }
  /* Display (end) */
  /* ========================================================= */

  render() {
    let { current_tab, order } = this.state;
    let { delivery_billing_completed, installation_completed, po_payment_compeleted, purchase_price_completed } = order.order_flag;
    let stateColor = "warning";

    if (this.state.order) {
      switch (this.state.order.state) {
        case ORDER_STATES.cancelled.name:
          stateColor = "danger";
          break;
        case ORDER_STATES.completed.name:
          stateColor = "success";
          break;
        default:
      }
    }
    return (
      <React.Fragment>
        <Spinner loading={this.state.is_loading} allCategory={this.state.cate_length} allLoadeCategory={this.state.load_cate} />
        <div className="pb-sm-3 px-3">
          <div className="row">
            <div className="col-9 pr-0 d-flex flex-row">
              <h2 className="d-none d-md-block">DC Order Form</h2>
              <h5 className="mb-2 pb-1 mt-auto px-2 color-darkblue text-wrap">{this.state.order.code}</h5>
              <span className={`badge badge-pill badge-${stateColor} quo-state-tag`}>{this.state.order.state}</span>
            </div>
            <div className="col-3 text-right">
              <button type="button" className="btn btn-outline-primary btn-sm" onClick={this.onClickBack}>
                Back
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-9">
              <ul className="nav nav-tabs tab-responsive">
                <li className="nav-item">
                  <a className={`nav-link ${current_tab === DC_ORDER_TABS.info ? "active" : ""}`} onClick={() => this.onChangeFormTab(DC_ORDER_TABS.info)}>
                    Info
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${current_tab === DC_ORDER_TABS.selling_price ? "active" : ""}`} onClick={() => this.onChangeFormTab(DC_ORDER_TABS.selling_price)}>
                    ราคาขาขาย{this.displayCheckmark(po_payment_compeleted)}
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${current_tab === DC_ORDER_TABS.cost_price ? "active" : ""}`} onClick={() => this.onChangeFormTab(DC_ORDER_TABS.cost_price)}>
                    ราคาทุน{this.displayCheckmark(purchase_price_completed)}
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${current_tab === DC_ORDER_TABS.delivery ? "active" : ""}`} onClick={() => this.onChangeFormTab(DC_ORDER_TABS.delivery)}>
                    Delivery
                    {this.displayCheckmark(delivery_billing_completed)}
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${current_tab === DC_ORDER_TABS.installation ? "active" : ""}`} onClick={() => this.onChangeFormTab(DC_ORDER_TABS.installation)}>
                    Installation{this.displayCheckmark(installation_completed)}
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${current_tab === DC_ORDER_TABS.repair ? "active" : ""}`} onClick={() => this.onChangeFormTab(DC_ORDER_TABS.repair)}>
                    แจ้งซ่อม
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-3 mt-2">
              <div className="text-right">{this.actionBtns()}</div>
            </div>
          </div>
        </div>
        <form onSubmit={this.handleSubmit}>
          {this.displayPages()}
          {this.cancelOrder()}
        </form>
        {this.displayBackConfirmation()}
        {this.displayChangeContractConfirm()}
      </React.Fragment>
    );
  }
}
export default withRouter(OrderFormDc);

import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";

import { ORDER_STATES, PAYMENT_METHODS, FORMAT_DATE_EXCEL_IN } from "../constants.js";
import { getObjectValueByString, objectIsEmpty, objectPreventNull } from "../functions/Object";
import { generateID } from "../functions/Random";
import { formatExportDate } from "../functions/Date";
import { Multiple, toStrWithLeadingZeros } from "../functions/Number";
import { parseExpRatioArr } from "../functions/FeePurchaseCalculation";
import { NEW_CUSTOMER } from "../models/Customer";
import { isCategoryDiscount, initNewFee, initNewDiscount, POSITION_CATEGORY_DISCOUNT, getCategoryTotalExpense, getTotalItemPrice, getCategoryDiscount } from "../models/Quotations";
import { solveFee, FEE_MULTIPLIER, solveFeeNew } from "../functions/FeePurchaseCalculation";
import { saveMultiple } from "../functions/Number";

const requiredFields = {
  po_date: "วันที่ ORDER",
  delivery_date: "วันจัดส่ง",
};

export const ORDER_TABS = {
  info: 0,
  payment: 1,
  purchase_price: 2,
  manufacturer: 3,
  po_gr: 4,
  delivery_billing: 5,
  invoice: 6,
  repair: 7,
  cost_price: 8,
};

export const DC_ORDER_TABS = {
  info: 0,
  selling_price: 1,
  cost_price: 2,
  delivery: 3,
  installation: 4,
  repair: 5,
};

export const ORDER_PROGRESS_FLAGS = {
  po_payment_compeleted: "ขาขาย ชำระแล้ว",
  purchase_price_completed: "ราคาต้นทุนสมบูรณ์แล้ว",
  po_gr_completed: "PO/GR บันทึกแล้ว",
  delivery_billing_completed: "Delivery/Billing บันทึกแล้ว",
  installation_completed: "แจ้งสถานีพร้อมติดตั้ง ครบแล้ว",
  invoice_complated: "รับใบแจ้งหนี้แล้ว",
};

export const NEW_ORDER = {
  id: null,
  quotation: {
    code: "",
    client_station: NEW_CUSTOMER,
  },
  po_date: new Date(),
  delivery_date: new Date(),
  state: ORDER_STATES.in_progress.name,
  customer_id: null,
  item_ids: [],
  updated_at: new Date(),
  note: "",
  order_notes: [],
  payment_method: {
    id: null,
    method_type: "",
    note: "",
    bank_id: null,
  },
  order_flag: set_order_flag(),
  purchasing_order_items: [],
  order_item_categories: [],
  order_po_grs: [],
};

export const NEW_DC_ORDER = {
  item_category_dc: [
    {
      // ori_name: "ตู้ API DC",
      // id: 42,
      // sys_name: "ตู้ API DC",
      // code: "0033",
      // remark: "- ตู้ API DC",
      // is_active: true,
      // dc_id: 1,
      // warranty_value: 1,
      // parent_id: 9,
      // warranty_type: 2,
      // item_category_fees: [],
      // unit: "ชุด",
    },
  ],
  item_dc: [
    {
      // item_category_id: 43,
      // name: "Test item dc 1",
      // id: 198,
      // code: "001",
      // item_category: {
      //   ori_name: "Signage System DC",
      //   sys_name: "Signage System DC",
      // },
      // is_mbom: false,
      // unit: "ชุด",
    },
  ],
  inbound_order: {
    db_delivery_date: new Date(),
    dc: {
      name: "",
      id: null,
      address: {
        address_line1: "",
        id: null,
        province: {
          name: "",
        },
        sub_district_id: null,
        sub_district: {
          name: "",
        },
        province_id: null,
        district: {
          name: "",
        },
        district_id: null,
        postcode: "",
      },
      phone: "",
    },
    id: null,
    note: "",
    cancel_note: null,
    code: "",
    state: "",
    db_delivery_code: null,
    inventoryarr: [
      // {
      //   value: [139, 140, 141],
      // },
      // {
      //   value: [142, 143, 144],
      // },
    ],
    user: {
      firstname: "",
      lastname: "",
    },
    inbound_order_item_pos: [
      {
        // contract_id: 44,
        // id: 1,
        // manufacture_request_id: null,
        // inbound_order_id: 6,
        // po_date: "2022-11-15",
        // submit_date: null,
        // po_code: "xxxx001",
      },
    ],
    total_cost: null,
    inbound_order_items: [
      {
        contract_item: {
          item_id: null,
          line_number: null,
        },
        inventory_items: [],
        installation_date: null,
        is_mbom: false,
        id: null,
        expense_ratio: 0,
        name: "",
        code: "",
        unit: "",
        expense_ratio_arr: [],
        price: null,
        warranty_code: null,
        item_category: {},
        qty: null,
        contract_price: null,
      },
    ],
    created_at: null,
    inbound_order_item_categories: [
      {
        contract: {
          contract_item_categories: [
            // {
            //   item_category_id: 36,
            //   contract_id: 44,
            //   id: 125,
            //   contract_items: [
            //     {
            //       contract_item_fees: [],
            //       name: "สินค้าทดสอบระบบ 04",
            //       id: 612,
            //       code: "5555555",
            //       is_active: true,
            //       line_number: 1,
            //       price: 21000.0,
            //       contract_item_discounts: [],
            //       unit: "ครั้ง",
            //     },
            //   ],
            //   contract_item_category_fees: [],
            // },
            // {
            //   item_category_id: 43,
            //   contract_id: 44,
            //   id: 126,
            //   contract_items: [
            //     {
            //       contract_item_fees: [],
            //       name: "Test item dc 1",
            //       id: 615,
            //       code: "001",
            //       is_active: true,
            //       line_number: null,
            //       price: 15000.0,
            //       contract_item_discounts: [],
            //       unit: "ชุด",
            //     },
            //     {
            //       contract_item_fees: [],
            //       name: "Test item dc 2",
            //       id: 616,
            //       code: "02",
            //       is_active: true,
            //       line_number: null,
            //       price: 15000.0,
            //       contract_item_discounts: [],
            //       unit: "ชุด",
            //     },
            //   ],
            //   contract_item_category_fees: [],
            // },
          ],
          updated_at: null,
          name: "",
          id: null,
          zone_id: null,
          to_date: null,
          code: "",
          nb_days_to_complete: null,
          is_active: true,
          days_to_ship: null,
          for_mr: false,
          contract_budget: null,
          contract_responsible_persons: [],
          created_at: null,
          from_date: null,
          vendor_id: null,
          detail: null,
          is_default: false,
        },
        ori_name: "",
        contract_item_category: {
          item_category_id: null,
          contract_id: null,
          id: null,
        },
        id: null,
        sys_name: "",
        item_category: {
          ori_name: "",
          id: null,
          sys_name: "",
          code: "",
          remark: null,
          is_active: true,
          dc_id: null,
          warranty_value: null,
          parent_id: null,
          warranty_type: null,
          item_category_fees: [],
          unit: "",
        },
        informed_date: null,
      },
    ],
    delivery_date: null,
  },
};

export const TEMP_REPORT = [
  {
    opened: true,
    client_station_id: 1,
    client_station_name: "ทีพีเอสปิโตรเลียม",
    client_phone: "08x-xxx-xxxx",
    client_province: "สมุทรปราการ",
    client_user_name: "L ทีพีเอส",
    quotation_id: 4,
    quotation_code: "PTTOR21060010",
    quotation_approved_date: "19/4/64",
    orders: [
      {
        order_id: 1,
        order_code: "PTTOR2106000901",
        order_orc_code: "5xxxxxxxxxx",
        order_date: "21/4/64",
        payment_date: "14/5/64",
        payment_method: "DA",
        billing_date: "10/6/64",
        invoice_date: "14/6/64",
        invoice_received_date: "18/6/64",
        installation: "5/9",
        contracts: [
          {
            id: 1,
            name: "FACIA O",
            confirm: true,
            informed_manufacturer: false,
            plan: "13/5/64",
            order_confirmed_date: "12/5/64",
            oc_sent_date: "",
            payment_date: "",
            payment_method: "",
            generated_debt_date: "",
            informed_purchasing_dep: "25/5/64",
            po_gr_date: "9/6/64",
            po_no: "xxxxxxxx",
            gr_no: "xxxxxxxx",
            items: [
              {
                name: "FACIA ส่วนตรง (รวมระบบ และอุปกรณ์ไฟฟ้า)",
                qty: 30,
              },
              {
                name: "FACIA ส่วนโค้ง (รวมระบบ และอุปกรณ์ไฟฟ้า)",
                qty: 30,
              },
            ],
          },
          {
            id: 2,
            name: "เสาเกาะจ่าย",
            confirm: true,
            informed_manufacturer: false,
            plan: "13/5/64",
            order_confirmed_date: "12/5/64",
            oc_sent_date: "",
            generated_debt_date: "",
            informed_purchasing_dep: "25/5/64",
            po_gr_date: "9/6/64",
            po_no: "xxxxxxxx",
            gr_no: "xxxxxxxx",
            items: [
              {
                name: "เสา Canopy รูปแบบ Minorchange",
                qty: 4,
              },
            ],
          },
          {
            id: 3,
            name: "ป้าย",
            confirm: true,
            informed_manufacturer: false,
            plan: "13/5/64",
            order_confirmed_date: "12/5/64",
            oc_sent_date: "",
            generated_debt_date: "",
            informed_purchasing_dep: "25/5/64",
            po_gr_date: "9/6/64",
            po_no: "xxxxxxxx",
            gr_no: "xxxxxxxx",
            billing_date: "9/6/64",
            items: [
              {
                name: "ป้ายประชาสัมพันธ์",
                qty: 2,
              },
              {
                name: "ป้ายทางเข้าแบบมีล้อ",
                qty: 2,
              },
              {
                name: "ป้าย Tenant Sign 6 m.",
                qty: 1,
              },
            ],
          },
          {
            id: 4,
            name: "POS P2+พัดลม",
            confirm: true,
            informed_manufacturer: false,
            plan: "13/5/64",
            order_confirmed_date: "12/5/64",
            oc_sent_date: "",
            generated_debt_date: "",
            informed_purchasing_dep: "25/5/64",
            po_gr_date: "9/6/64",
            items: [
              {
                name: "ตู้คลุม POS 2.9 m. (P2/2 Counter) + พัดลมติดผนัง 12 นิ้ว",
                qty: 2,
              },
              {
                name: "ตู้คลุม POS 1.65 m. (Compact) + พัดลมติดผนัง 12 นิ้ว",
                qty: 2,
              },
            ],
          },
          {
            id: 5,
            name: "โคมไฟสนาม",
            confirm: true,
            informed_manufacturer: false,
            plan: "13/5/64",
            order_confirmed_date: "12/5/64",
            oc_sent_date: "",
            generated_debt_date: "",
            informed_purchasing_dep: "25/5/64",
            po_gr_date: "9/6/64",
            po_no: "xxxxxxxx",
            gr_no: "xxxxxxxx",
            billing_date: "9/6/64",
            items: [
              {
                name: "Area Light Pole (เสาไฟสนาม)",
                qty: 7,
              },
            ],
          },
        ],
      },
      {
        order_id: 1,
        order_code: "",
        order_date: "",
        payment_date: "",
        payment_method: "",
        billing_date: "",
        invoice_date: "",
        invoice_received_date: "",
        installation: "0/1",
        contracts: [
          {
            id: 6,
            name: "ราวกันชน",
            confirm: true,
            informed_manufacturer: false,
            plan: "",
            order_confirmed_date: "",
            oc_sent_date: "",
            generated_debt_date: "",
            informed_purchasing_dep: "",
            po_gr_date: "",
            po_no: "",
            gr_no: "",
            items: [
              {
                name: "ราวกันชน",
                qty: 14,
              },
            ],
          },
        ],
      },
      {
        order_id: 1,
        order_code: "",
        order_date: "",
        payment_date: "",
        payment_method: "",
        billing_date: "",
        invoice_date: "",
        invoice_received_date: "",
        installation: "0/1",
        contracts: [
          {
            id: 7,
            name: "ATG",
            confirm: true,
            informed_manufacturer: false,
            plan: "",
            order_confirmed_date: "",
            oc_sent_date: "",
            generated_debt_date: "",
            informed_purchasing_dep: "",
            po_gr_date: "",
            po_no: "",
            gr_no: "",
            items: [
              {
                name: "xxxxxxxxxxxxxxxxxx",
                qty: 1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    opened: true,
    client_station_id: 2,
    client_station_name: "บจ.สหโชคการณ์กิจ นิตย์ปิโตรเลียม",
    client_phone: "0813091666",
    client_province: "สุพรรณบุรี",
    client_user_name: "Chalermpol",
    quotation_id: 1,
    quotation_code: "PTTOR21060010",
    quotation_approved_date: "1/5/64",
    orders: [
      {
        order_id: 1,
        order_code: "PTTOR2106000901",
        order_orc_code: "5xxxxxxxxxx",
        order_date: "31/5/64",
        payment_date: "14/6/64",
        payment_method: "DL",
        billing_date: "9/6/64",
        invoice_date: "14/6/64",
        invoice_received_date: "18/6/64",
        installation: "0/2",
        contracts: [
          {
            id: 8,
            name: "ถังน้ำมันใต้ดิน",
            confirm: true,
            informed_manufacturer: true,
            plan: "9/6/64",
            order_confirmed_date: "12/6/64",
            oc_sent_date: "",
            generated_debt_date: "14/6/64",
            informed_purchasing_dep: "14/6/64",
            po_gr_date: "23/6/64",
            po_no: "xxxxxxxx",
            gr_no: "xxxxxxxx",
            items: [
              {
                name: "ถังน้ำมันใต้ดิน 20,000 ลิตร (แบ่ง 2 ช่อง) ระบบ STP",
                qty: 1,
              },
              {
                name: "ถังน้ำมันใต้ดิน 40,000 ลิตร (แบ่ง 2 ช่อง) ระบบ STP",
                qty: 1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    opened: true,
    client_station_id: 3,
    client_station_name: "14ออยซัคเซส",
    client_phone: "08x-xxx-xxxx",
    client_province: "xxxxxx",
    client_user_name: "user 1",
    quotation_id: 2,
    quotation_code: "PTTOR21060010",
    quotation_approved_date: "22/5/64",
    orders: [
      {
        order_id: 1,
        order_code: "PTTOR2106000901",
        order_orc_code: "5xxxxxxxxxx",
        order_date: "17/5/64",
        payment_date: "17/5/64",
        payment_method: "DL",
        billing_date: "9/6/64",
        invoice_date: "2/7/64",
        invoice_received_date: "10/7/64",
        installation: "2/2",
        contracts: [
          {
            id: 9,
            name: "HWS",
            confirm: false,
            informed_manufacturer: false,
            plan: "25/6/64",
            oc_sent_date: "",
            generated_debt_date: "17/5/64",
            informed_purchasing_dep: "17/5/64",
            po_gr_date: "",
            po_no: "",
            gr_no: "",
            items: [
              {
                name: "Highway Sign 12 m. Type A (7-11+Amz)",
                qty: 1,
              },
              {
                name: "Highway Sign 20 m. Type C (7-11+Amz+FD+ร้านค้า 2)",
                qty: 1,
              },
            ],
          },
        ],
      },
      {
        order_id: 1,
        order_code: "PTTOR2106000902",
        order_date: "17/5/64",
        payment_date: "17/5/64",
        payment_method: "DL",
        billing_date: "9/6/64",
        invoice_date: "2/7/64",
        invoice_received_date: "4/7/64",
        installation: "0/2",
        contracts: [
          {
            id: 10,
            name: "เครื่องเติมลม",
            confirm: false,
            informed_manufacturer: false,
            plan: "25/6/64",
            oc_sent_date: "",
            generated_debt_date: "17/5/64",
            informed_purchasing_dep: "17/5/64",
            po_gr_date: "",
            po_no: "",
            gr_no: "",
            items: [
              {
                name: "หลังคาคลุมเครื่องเติมลมยาง",
                qty: 1,
              },
              {
                name: "เครื่องเติมลม",
                qty: 2,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    opened: true,
    client_station_id: 1,
    client_station_name: "ทีพีเอสปิโตรเลียม",
    client_phone: "08x-xxx-xxxx",
    client_province: "สมุทรปราการ",
    client_user_name: "L ทีพีเอส",
    quotation_id: 3,
    quotation_code: "PTTOR21050011",
    quotation_approved_date: "22/5/64",
    orders: [
      {
        order_id: 1,
        order_code: "PTTOR2106000901",
        order_orc_code: "5xxxxxxxxxx",
        order_date: "17/5/64",
        payment_date: "17/5/64",
        payment_method: "DL",
        billing_date: "9/6/64",
        invoice_date: "2/7/64",
        invoice_received_date: "5/7/64",
        installation: "0/2",
        contracts: [
          {
            id: 12,
            name: "HWS",
            confirm: false,
            informed_manufacturer: false,
            plan: "25/6/64",
            oc_sent_date: "",
            generated_debt_date: "17/5/64",
            informed_purchasing_dep: "17/5/64",
            po_gr_date: "",
            po_no: "",
            gr_no: "",
            items: [
              {
                name: "Highway Sign 12 m. Type A (7-11+Amz)",
                qty: 1,
              },
              {
                name: "Highway Sign 20 m. Type C (7-11+Amz+FD+ร้านค้า 2)",
                qty: 1,
              },
            ],
          },
        ],
      },
    ],
  },
];

export const TEMP_REPORT_V1 = [
  {
    client_station_name: "บจก.ธนพล อินโนเวชั่น",
    client_phone: "08x-xxx-xxxx",
    client_province: "กรุงเทพมหานคร",
    phone: "",
    item_category: "หลังคาคลุมเครื่องติมลม",
    order_id: "",
    quotation_created_at: "15/4",
    comfirmed_quotation_date: "",
    informed_manufacturer_date: "",
    order_confirmed_date: "",
    oc_sent_date: "",
    payment_date: "",
    payment_method: "",
    generated_debt_date: "",
    informed_purchasing_dep: "",
    po_gr_date: "",
    delivery_date: "",
    billing_date: "",
    invoice_date: "",
    contract_no: "",
    po_gr_submitted_date: "",
    po_no: "",
    gr_no: "",
    items: [
      {
        name: "xxxxxx",
        installation_date: "1/5/64",
        code: "OR-xx-xx-xxxx",
        warranty_expiration_date: "1/5/65",
      },
      {
        name: "xxxxxx",
        installation_date: "1/5/64",
        code: "OR-xx-xx-xxxx",
        warranty_expiration_date: "1/5/65",
      },
      {
        name: "xxxxxx",
        installation_date: "1/5/64",
        code: "OR-xx-xx-xxxx",
        warranty_expiration_date: "1/5/65",
      },
    ],
  },
  {
    client_station_name: "สน แสงอารีย์ 7",
    phone: "",
    item_category: "ราวกันชน",
    order_id: "",
    quotation_created_at: "17/4",
    comfirmed_quotation_date: "",
    informed_manufacturer_date: "19/4",
    order_confirmed_date: "19/4",
    oc_sent_date: "",
    payment_date: "20/4",
    payment_method: PAYMENT_METHODS.yodl,
    generated_debt_date: "20/4",
    informed_purchasing_dep: "20/4",
    po_gr_date: "21/4",
    delivery_date: "23/4",
    billing_date: "23/4",
    invoice_date: "23/4",
    contract_no: "4170003169",
    po_gr_submitted_date: "20/4",
    po_no: "97879790",
    gr_no: "21322341",
    items: [
      {
        name: "xxxxxx",
        installation_date: "1/5/64",
        code: "OR-xx-xx-xxxx",
        warranty_expiration_date: "1/5/65",
      },
    ],
  },
  {
    client_station_name: "สน แสงอารีย์ 7",
    phone: "",
    item_category: "หลังคาคลุมเครื่องติมลม",
    order_id: "",
    quotation_created_at: "17/4",
    comfirmed_quotation_date: "",
    informed_manufacturer_date: "19/4",
    order_confirmed_date: "19/4",
    oc_sent_date: "",
    payment_date: "20/4",
    payment_method: PAYMENT_METHODS.yodl,
    generated_debt_date: "20/4",
    informed_purchasing_dep: "20/4",
    po_gr_date: "21/4",
    delivery_date: "23/4",
    billing_date: "23/4",
    invoice_date: "23/4",
    contract_no: "4170003188",
    po_gr_submitted_date: "20/4",
    po_no: "43465345",
    gr_no: "24544455",
    items: [
      {
        name: "xxxxxx",
        installation_date: "1/5/64",
        code: "OR-xx-xx-xxxx",
        warranty_expiration_date: "1/5/65",
      },
      {
        name: "xxxxxx",
        installation_date: "1/5/64",
        code: "OR-xx-xx-xxxx",
        warranty_expiration_date: "1/5/65",
      },
    ],
  },
  {
    client_station_name: "ศรีสุธาไพศาล",
    phone: "",
    item_category: "ตู้หล่อลื่น",
    order_id: "",
    quotation_created_at: "18/4",
    comfirmed_quotation_date: "",
    informed_manufacturer_date: "20/4",
    order_confirmed_date: "",
    oc_sent_date: "",
    payment_date: "",
    payment_method: "",
    generated_debt_date: "",
    informed_purchasing_dep: "",
    po_gr_date: "",
    delivery_date: "",
    billing_date: "",
    invoice_date: "",
    contract_no: "4170003199",
    po_gr_submitted_date: "20/4",
    po_no: "",
    gr_no: "",
    items: [
      {
        name: "xxxxxx",
        installation_date: "1/5/64",
        code: "OR-xx-xx-xxxx",
        warranty_expiration_date: "1/5/65",
      },
      {
        name: "xxxxxx",
        installation_date: "1/5/64",
        code: "OR-xx-xx-xxxx",
        warranty_expiration_date: "1/5/65",
      },
      {
        name: "xxxxxx",
        installation_date: "1/5/64",
        code: "OR-xx-xx-xxxx",
        warranty_expiration_date: "1/5/65",
      },
    ],
  },
  {
    client_station_name: "ฟู่เอ็นเนอร์ยี่",
    phone: "",
    item_category: "HWS",
    order_id: "",
    quotation_created_at: "18/4",
    comfirmed_quotation_date: "",
    informed_manufacturer_date: "20/4",
    order_confirmed_date: "20/4",
    oc_sent_date: "",
    payment_date: "20/4",
    payment_method: PAYMENT_METHODS.yodl,
    generated_debt_date: "",
    informed_purchasing_dep: "",
    po_gr_date: "",
    delivery_date: "",
    billing_date: "",
    invoice_date: "",
    contract_no: "41700031611",
    po_gr_submitted_date: "",
    po_no: "",
    gr_no: "",
    items: [
      {
        name: "xxxxxx",
        installation_date: "1/5/64",
        code: "OR-xx-xx-xxxx",
        warranty_expiration_date: "1/5/65",
      },
    ],
  },
  {
    client_station_name: "รุ่งเรืองวังสะพุง",
    phone: "",
    item_category: "ถังน้ำมัน",
    order_id: "",
    quotation_created_at: "18/4",
    comfirmed_quotation_date: "",
    informed_manufacturer_date: "",
    order_confirmed_date: "",
    oc_sent_date: "",
    payment_date: "20/4",
    payment_method: PAYMENT_METHODS.yodl,
    generated_debt_date: "20/4",
    informed_purchasing_dep: "20/4",
    po_gr_date: "22/4",
    delivery_date: "",
    billing_date: "",
    invoice_date: "",
    contract_no: "4170003169",
    po_gr_submitted_date: "19/4",
    po_no: "97879790",
    gr_no: "21322341",
    items: [
      {
        name: "xxxxxx",
        installation_date: "1/5/64",
        code: "OR-xx-xx-xxxx",
        warranty_expiration_date: "1/5/65",
      },
    ],
  },
  {
    client_station_name: "นิวนิตยา",
    phone: "",
    item_category: "เครื่องเติมลม",
    order_id: "",
    quotation_created_at: "18/4",
    comfirmed_quotation_date: "",
    informed_manufacturer_date: "20/4",
    order_confirmed_date: "20/4",
    oc_sent_date: "20/4",
    payment_date: "",
    payment_method: "",
    generated_debt_date: "",
    informed_purchasing_dep: "",
    po_gr_date: "",
    delivery_date: "",
    billing_date: "",
    invoice_date: "",
    contract_no: "4170003169",
    po_gr_submitted_date: "",
    po_no: "",
    gr_no: "",
    items: [
      {
        name: "xxxxxx",
        installation_date: "1/5/64",
        code: "OR-xx-xx-xxxx",
        warranty_expiration_date: "1/5/65",
      },
    ],
  },
  {
    client_station_name: "นิวนิตยา",
    phone: "",
    item_category: "หลังคาคลุมเครื่องติมลม",
    order_id: "",
    quotation_created_at: "18/4",
    comfirmed_quotation_date: "",
    informed_manufacturer_date: "20/4",
    order_confirmed_date: "20/4",
    oc_sent_date: "20/4",
    payment_date: "",
    payment_method: "",
    generated_debt_date: "",
    informed_purchasing_dep: "",
    po_gr_date: "",
    delivery_date: "",
    billing_date: "",
    invoice_date: "",
    contract_no: "4170003169",
    po_gr_submitted_date: "",
    po_no: "",
    gr_no: "",
    items: [
      {
        name: "xxxxxx",
        installation_date: "1/5/64",
        code: "OR-xx-xx-xxxx",
        warranty_expiration_date: "1/5/65",
      },
    ],
  },
  {
    client_station_name: "นิวนิตยา",
    phone: "",
    item_category: "ตู้ API",
    order_id: "",
    quotation_created_at: "18/4",
    comfirmed_quotation_date: "",
    informed_manufacturer_date: "20/4",
    order_confirmed_date: "20/4",
    oc_sent_date: "20/4",
    payment_date: "",
    payment_method: "",
    generated_debt_date: "",
    informed_purchasing_dep: "",
    po_gr_date: "",
    delivery_date: "",
    billing_date: "",
    invoice_date: "",
    contract_no: "4170003169",
    po_gr_submitted_date: "",
    po_no: "",
    gr_no: "",
    items: [
      {
        name: "xxxxxx",
        installation_date: "1/5/64",
        code: "OR-xx-xx-xxxx",
        warranty_expiration_date: "1/5/65",
      },
      {
        name: "xxxxxx",
        installation_date: "1/5/64",
        code: "OR-xx-xx-xxxx",
        warranty_expiration_date: "1/5/65",
      },
    ],
  },
  {
    client_station_name: "นิวนิตยา",
    phone: "",
    item_category: "ราวกันชน",
    order_id: "",
    quotation_created_at: "18/4",
    comfirmed_quotation_date: "",
    informed_manufacturer_date: "20/4",
    order_confirmed_date: "20/4",
    oc_sent_date: "20/4",
    payment_date: "",
    payment_method: "",
    generated_debt_date: "",
    informed_purchasing_dep: "",
    po_gr_date: "",
    delivery_date: "",
    billing_date: "",
    invoice_date: "",
    contract_no: "4170003169",
    po_gr_submitted_date: "",
    po_no: "",
    gr_no: "",
    items: [
      {
        name: "xxxxxx",
        installation_date: "1/5/64",
        code: "OR-xx-xx-xxxx",
        warranty_expiration_date: "1/5/65",
      },
      {
        name: "xxxxxx",
        installation_date: "1/5/64",
        code: "OR-xx-xx-xxxx",
        warranty_expiration_date: "1/5/65",
      },
      {
        name: "xxxxxx",
        installation_date: "1/5/64",
        code: "OR-xx-xx-xxxx",
        warranty_expiration_date: "1/5/65",
      },
    ],
  },
  {
    client_station_name: "บุญสะดวก",
    phone: "",
    item_category: "ถังน้ำมัน",
    order_id: "",
    quotation_created_at: "20/4",
    comfirmed_quotation_date: "24/4",
    informed_manufacturer_date: "24/4",
    order_confirmed_date: "",
    oc_sent_date: "",
    payment_date: "",
    payment_method: "",
    generated_debt_date: "",
    informed_purchasing_dep: "",
    po_gr_date: "",
    delivery_date: "",
    billing_date: "",
    invoice_date: "",
    contract_no: "4170003169",
    po_gr_submitted_date: "",
    po_no: "",
    gr_no: "",
    items: [
      {
        name: "xxxxxx",
        installation_date: "1/5/64",
        code: "OR-xx-xx-xxxx",
        warranty_expiration_date: "1/5/65",
      },
    ],
  },
  {
    client_station_name: "บจ.บางกอก ไลฟ์ สไตล์ สาขา 2",
    phone: "",
    item_category: "ตู้หล่อลื่น",
    order_id: "",
    quotation_created_at: "20/4",
    comfirmed_quotation_date: "20/4",
    informed_manufacturer_date: "",
    order_confirmed_date: "20/4",
    oc_sent_date: "",
    payment_date: "20/4",
    payment_method: PAYMENT_METHODS.yodl,
    generated_debt_date: "",
    informed_purchasing_dep: "",
    po_gr_date: "",
    delivery_date: "",
    billing_date: "",
    invoice_date: "",
    contract_no: "4170003169",
    po_gr_submitted_date: "",
    po_no: "",
    gr_no: "",
    items: [
      {
        name: "xxxxxx",
        installation_date: "1/5/64",
        code: "OR-xx-xx-xxxx",
        warranty_expiration_date: "1/5/65",
      },
    ],
  },
];

export function init_order(order) {
  if (!order.payment_method) {
    order.payment_method = cloneDeep(NEW_ORDER.payment_method);
  }

  if (!order.po_date) {
    order.po_date = new Date();
  }

  if (!order.delivery_date) {
    order.delivery_date = new Date();
  }

  if (order.quotation && order.quotation.client_station) {
    order.station_name = order.quotation.client_station.name;
    order.ship_to = order.quotation.client_station.ship_to_code;
    order.sold_to = order.quotation.client_station.sold_to_code;
  }

  order.order_item_categories = initOrderItemCategories(order.order_item_categories);
  order.purchasing_order_items = initDaystoInstallation(order);

  // init po/gr
  if (order.order_po_grs.length === 0) {
    order.order_po_grs.push(set_new_order_po_gr());
  }

  // init flag
  if (!order.order_flag) {
    order.order_flag = set_order_flag();
  }

  // init order manufacturer
  order = set_default_warranty_values(order);
  order.order_flag.installation_completed = validateInstallation(order);

  return order;
}

export function set_new_order_po_gr() {
  return {
    id: generateID(),
    is_new: true,
    submit_date: null,
    po_code: null,
    po_date: null,
    gr_code: null,
    gr_date: null,
    contract_id: null,
  };
}

function set_order_flag() {
  return {
    po_payment_compeleted: false,
    purchase_price_completed: false,
    po_gr_completed: false,
    delivery_billing_completed: false,
    installation_completed: false,
    invoice_complated: false,
    init_completed: false,
  };
}

export function is_delivery_billing_disabled(order_flag) {
  return !order_flag.po_gr_completed;
}

export function is_complete(order_flag) {
  let completed = true;
  for (let key of Object.keys(order_flag)) {
    completed = completed && order_flag[key];
  }
  return completed;
}

function set_default_warranty_values(order) {
  if (objectIsEmpty(order.order_item_categories)) {
    return order;
  }

  order.purchasing_order_items.map((elm) => {
    if (!elm.warranty_type) {
      elm.warranty_type = order.order_item_categories[elm.item_category_id].item_category.warranty_type;
    }

    if (!elm.warranty_value) {
      elm.warranty_value = order.order_item_categories[elm.item_category_id].item_category.warranty_value;
    }
  });

  return order;
}

export function validateOrder(order, newState) {
  let errors = {};

  // validate require fields
  Object.keys(requiredFields).map((key) => {
    let value = getObjectValueByString(order, key);
    if (value === undefined || value === "") {
      errors[key] = `โปรดระบุ ${requiredFields[key]}`;
    }
  });

  // validate po gr
  for (let elm of order.order_po_grs) {
    if (!elm.contract_id) {
      if (elm.po_date || elm.gr_date || elm.submit_date || elm.po_code || elm.gr_code) {
        errors[`order_po_grs[${elm.id}].contract_id`] = "โปรดระบุ สัญญา";
      }
    }
  }

  return errors;
}

export function validatePayment(order) {
  let payment = order.payment_method;
  if (!order.order_code) {
    return false;
  }

  if (!payment.method_type) {
    return false;
  }

  if (!payment.payment_date) {
    return false;
  }

  if (payment.method_type === PAYMENT_METHODS.yodl) {
    if (objectIsEmpty(payment.s3_slip_url)) {
      return false;
    }
  }

  return true;
}

export function validatePoGr(order_po_grs, contract_ids) {
  let result = true;
  for (let id of contract_ids) {
    let po_gr = order_po_grs.filter((elm) => elm.contract_id === id)[0];
    if (po_gr) {
      if (!po_gr.po_code || po_gr.po_code === "") {
        result = false;
        break;
      }
      if (!po_gr.gr_code || po_gr.gr_code === "") {
        result = false;
        break;
      }
    } else {
      result = false;
      break;
    }
  }
  return result;
}

export function validateDeliveryBilling(order) {
  let result = true;
  if (order.dc_id === 0) {
    if (!order.db_delivery_code || order.db_delivery_code === "") {
      result = false;
    }
    if (!order.db_billing_code || order.db_billing_code === "") {
      result = false;
    }
  } else {
    if (!order.db_delivery_code || order.db_delivery_code === "") {
      result = false;
    }
  }
  return result;
}

export function validateInstallation(order) {
  let result = true;
  let order_items = order.purchasing_order_items.filter((elm) => ((order.dc_id === 0) & !elm.is_mbom) | (order.dc_id > 0));
  for (var index in order_items) {
    if (!order_items[index].installation_date) {
      result = false;
    }
    if (order_items[index].station_ready == null) {
      result = false;
    }
  }
  return result;
}

export function validateInvoice(order) {
  if (!order.invoice_received_date) {
    return false;
  }

  if (!order.s3_invoice_url) {
    return false;
  }

  return true;
}

export function initOrderItemCategories(order_item_categories) {
  let new_cate = {};
  order_item_categories.map((cate) => {
    new_cate[cate.item_category_id] = initOrderItemExpense(cate);
  });
  return new_cate;
}

export function initDaystoInstallation(po) {
  let { purchasing_order_items, quotation } = po;
  purchasing_order_items.map((item) => {
    if (item.installation_date) {
      let approved_state_date_moment = moment(new Date(quotation.approved_state_date));
      let date = new Date(item.installation_date);
      item.days_to_installation = moment(new Date(date)).diff(approved_state_date_moment, "days");
    }
  });

  return purchasing_order_items;
}

export function getWarrantyCode(warranty_codes) {
  // if (warranty_code) {
  //   return "OR-" + warranty_code.asset_code + "-" + warranty_code.vendor.vendor_code + "-" + toStrWithLeadingZeros(warranty_code.code, 4)
  // }
  let code = null;
  if (warranty_codes && warranty_codes.length !== 0) {
    let arr = [];
    for (let index in warranty_codes) {
      arr.push("OR-" + warranty_codes[index].asset_code + "-" + warranty_codes[index].vendor.vendor_code + "-" + toStrWithLeadingZeros(warranty_codes[index].code, 4));
    }
    code = arr.join("\n");
  }

  return code;
}

export function calDcTotalCost(dc_order) {
  let { inbound_order_items } = dc_order;
  let order_items = inbound_order_items.filter((elm) => !elm.is_mbom);
  let order_item_categories = dc_order.item_categories_data_with_key;
  let total = 0;
  if (order_items.length !== 0) {
    total = getDcInboundTotalExpense(order_item_categories) + getTotalItemPrice(order_items, true);
  }
  return total;
}

export function calDcTotalDiscount(dc_order) {
  let order_item_categories = dc_order.item_categories_data_with_key;
  let discount = 0;

  for (var key in order_item_categories) {
    discount += getCategoryDiscount(order_item_categories[key].item_expense, true);
  }

  return discount;
}

export function calTotalCost(order) {
  let { purchasing_order_items, order_item_categories } = order;
  let total = 0;
  let order_items = purchasing_order_items.filter((elm) => !elm.is_mbom);

  if (order_items.length !== 0) {
    total = getQuotationTotalExpense(order_item_categories) + getTotalItemPrice(order_items, true);
  }

  return total;
}

export function calTotalDiscount(order) {
  let discount = 0;
  let categories = order.order_item_categories;

  for (var key in categories) {
    discount += getCategoryDiscount(categories[key].item_category_expenses, true);
  }

  return discount;
}

export function sortItemByCate(a, b) {
  if (a.item_category_id < b.item_category_id) {
    return -1;
  }
  if (a.item_category_id > b.item_category_id) {
    return 1;
  }
  return 0;
}

export function sortItemByCateForDcInboundOrder(a, b) {
  if (a.item_category.id < b.item_category.id) {
    return -1;
  }
  if (a.item_category.id > b.item_category.id) {
    return 1;
  }
  return 0;
}

export function sortItemById(a, b) {
  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  return 0;
}

function getSumFees(data) {
  let fee = 0;
  let categotyQTY = 0;

  data.inbound_order_item_category.forEach((item) => {
    let loop_sum = 0;
    let itemQTY = item.qty || 1;
    categotyQTY = categotyQTY + itemQTY;
    if (item.item_expense) {
      item.item_expense.forEach((contractFee) => {
        if (contractFee.method_type === 6) {
          if (itemQTY >= contractFee.from_value && itemQTY <= contractFee.to_value) {
            loop_sum = contractFee.contract_price;
          } else if (contractFee.to_value === null && itemQTY >= contractFee.from_value) {
            loop_sum = contractFee.contract_price;
          }
        } else if (contractFee.method_type === 3) {
          if (itemQTY >= contractFee.from_value) {
            if (!contractFee.to_value) {
              fee = fee + contractFee.qty * contractFee.contract_price;
            } else {
              loop_sum = contractFee.qty * contractFee.contract_price;
            }
          }
        } else if (contractFee.method_type === 5) {
          let itemQTY = item.qty;
          let multiply = Math.ceil(itemQTY / contractFee.package_qty);
          let priceQty = contractFee.contract_price * contractFee.qty;

          fee = fee + priceQty * multiply;
        } else {
          let priceWithQty = contractFee.contract_price * contractFee.qty;
          fee = fee + priceWithQty;
        }
      });

      fee = fee + loop_sum;
    }
  });

  return fee;
}

function initOrderItemExpense(order_item_category) {
  let de_category = order_item_category;
  de_category.item_category_expenses = [];
  order_item_category.order_item_expenses.map((expense, index) => {
    expense.position = isCategoryDiscount(expense) ? POSITION_CATEGORY_DISCOUNT : index;
    expense.item_category_id = de_category.item_category_id;
    expense.is_new = false;
    de_category.item_category_expenses.push(expense);
  });

  // push new fee to the cloned category
  de_category.item_category_expenses.push(initNewFee(de_category.item_category_id));
  // push new discount to the cloned category if there is no discount
  if (de_category.item_category_expenses.filter((elm) => isCategoryDiscount(elm)).length === 0) {
    de_category.item_category_expenses.push(initNewDiscount(de_category.item_category_id));
  }
  delete order_item_category["order_item_expenses"];
  return de_category;
}

function getQuotationTotalExpense(order_item_categories) {
  let sum_fee = 0;
  for (var key in order_item_categories) {
    let find_type_7 = null;

    order_item_categories[key]?.item_category_expenses.forEach((cate) => {
      if (cate.method_type === 7 && cate.qty > cate.from_value) {
        if (find_type_7) {
          if (cate.qty > find_type_7.from_value && find_type_7.from_value < cate.from_value) {
            find_type_7 = cate;
          }
        } else {
          find_type_7 = cate;
        }
      }
    });

    sum_fee += getCategoryTotalExpense(order_item_categories[key].item_category_expenses, true, find_type_7 ? find_type_7 : false);
  }
  return sum_fee;
}

function getDcInboundTotalExpense(order_item_categories) {
  let sum_fee = 0;
  for (var key in order_item_categories) {
    sum_fee += getCategoryTotalExpense(order_item_categories[key].item_expense, true);
  }
  return sum_fee;
}

function get_short_text_8(item, station, itemNickname, order) {
  const FIX_LEN = 23;
  const ITEM_FIX_LEN = 12
  let de_station = ` ${station}`;
  let item_len = itemNickname ? itemNickname.length : item.length;
  let itemName = itemNickname ? itemNickname : item
  let station_len = de_station.length;
  let short_text_item = "";
  let short_text_station = "";
  if (item_len > ITEM_FIX_LEN && station_len > FIX_LEN) {
    short_text_item = itemName.substr(0, ITEM_FIX_LEN);
    short_text_station = de_station.substr(0, FIX_LEN);
  } else if (item_len > ITEM_FIX_LEN) {
    let fraction = FIX_LEN - station_len;
    short_text_item = itemName.substr(0, ITEM_FIX_LEN + fraction);
    short_text_station = de_station;
  } else if (station_len > FIX_LEN) {
    let fraction = ITEM_FIX_LEN - item_len;
    short_text_item = itemName;
    short_text_station = de_station.substr(0, FIX_LEN + fraction);
  } else {
    short_text_item = itemName;
    short_text_station = de_station;
  }
  return `${short_text_item}-${short_text_station}${order?.buyer ? "-ผรม" : ""}`;
}

export function toExcelMassUploadPoGr(orders) {
  let data = [];
  let end_line = 1;

  // Styles
  let font = { sz: "10" };
  let font_red = { sz: "10", color: { rgb: "FFff0000" } };
  let style_row_1 = { font: { sz: "11" }, alignment: { horizontal: "left" } };
  let style_row_2 = {
    fill: { fgColor: { rgb: "FFC5E0B4" } },
    font: { sz: "11", bold: true },
    alignment: { horizontal: "center" },
  };
  let style_row_3 = {
    fill: { fgColor: { rgb: "FFffe699" } },
    font: { sz: "10", bold: true },
    alignment: { horizontal: "center" },
  };
  let style_row_4_l = {
    fill: { fgColor: { rgb: "FFfff2cc" } },
    font: { sz: "10" },
    alignment: { vertical: "top", horizontal: "center" },
  };
  let style_row_4_r = {
    fill: { fgColor: { rgb: "FFfff2cc" } },
    font: { sz: "10" },
    alignment: { vertical: "top", horizontal: "center" },
  };
  let style_row_4_nb = {
    fill: { fgColor: { rgb: "FFfff2cc" } },
    font: { sz: "10" },
    alignment: { vertical: "top", horizontal: "center" },
  };
  let style_row_5_r = {
    fill: { fgColor: { rgb: "FFdeebf7" } },
    font: font_red,
    alignment: { vertical: "top", horizontal: "center" },
  };
  let style_row_5_nb = {
    fill: { fgColor: { rgb: "FFdeebf7" } },
    font: font_red,
    alignment: { vertical: "top", horizontal: "center" },
  };
  let style_row_remark_tlr = {
    fill: { fgColor: { rgb: "FFbfbfbf" } },
    font: font,
    alignment: { vertical: "top", horizontal: "center" },
  };
  let style_row_remark_blr = {
    fill: { fgColor: { rgb: "FFbfbfbf" } },
    font: font,
  };
  let style_row_remark_lr = {
    fill: { fgColor: { rgb: "FFbfbfbf" } },
    font: font,
  };
  let style_row_body = { font: { sz: "10" }, alignment: { vertical: "top" } };
  let style_row_body_last = {
    font: { sz: "10" },
    alignment: { vertical: "top" },
  };
  let style_row_remark_body = {
    fill: { fgColor: { rgb: "FFd9d9d9" } },
    font: font,
  };
  let style_row_remark_body_last = {
    fill: { fgColor: { rgb: "FFd9d9d9" } },
    font: font,
  };
  let width_col_xs = { wch: 12 };
  let width_col_sm = { wch: 15 };
  let width_col_md = { wch: 25 };
  let width_col_lg = { wch: 35 };
  let width_col_xl = { wch: 50 };

  /* Old Template Styles
  let font = {sz: "10"}
  let font_red = {sz: "10", color: { rgb: "FFff0000" }}
  let border_color = {style: 'thin', color: { rgb: "FF000000" }}
  let border_color_medium = {style: 'medium', color: { rgb: "FF000000" }}
  let border_style = {top: border_color, bottom: border_color, left: border_color, right: border_color}
  let border_style_bm = {top: border_color, bottom: border_color_medium, left: border_color, right: border_color}
  let style_row_1 = {border: border_style_bm, font: {sz: "11", bold: true}, alignment: {horizontal: "center"}}
  let style_row_2 = {fill: {fgColor: {rgb: "FFC5E0B4"}}, border: {bottom: border_color, left: border_color, right: border_color}, font: {sz: "11", bold: true}, alignment: {horizontal: "center"}}
  let style_row_3 = {fill: {fgColor: {rgb: "FFffe699"}}, border: border_style, font: {sz: "10"}, alignment: {horizontal: "center"}}
  let style_row_4_l = {fill: {fgColor: {rgb: "FFfff2cc"}},
                       border: {top: border_color, bottom: border_color, left: border_color},
                       font: {sz: "10"},
                       alignment: {vertical: "top", horizontal: "center"}}
  let style_row_4_r = {fill: {fgColor: {rgb: "FFfff2cc"}},
                       border: {top: border_color, bottom: border_color, right: border_color},
                       font: {sz: "10"},
                       alignment: {vertical: "top", horizontal: "center"}}
  let style_row_4_nb = {fill: {fgColor: {rgb: "FFfff2cc"}}, border: {top: border_color, bottom: border_color}, font: {sz: "10"}, alignment: {vertical: "top", horizontal: "center"}}
  let style_row_5_r = {fill: {fgColor: {rgb: "FFdeebf7"}},
                       border: {top: border_color, bottom: border_color_medium, right: border_color},
                       font: font_red,
                       alignment: {vertical: "top", horizontal: "center"}}
  let style_row_5_nb = {fill: {fgColor: {rgb: "FFdeebf7"}},border: {top: border_color, bottom: border_color_medium}, font: font_red, alignment: {vertical: "top", horizontal: "center"}}
  let style_row_remark_tlr = {fill: {fgColor: {rgb: "FFbfbfbf"}}, border: {top: border_color_medium, bottom: border_color, left: border_color_medium, right: border_color_medium}, font: font, alignment: {vertical: "top", horizontal: "center"}}
  let style_row_remark_blr = {fill: {fgColor: {rgb: "FFbfbfbf"}}, border: {top: border_color, bottom: border_color_medium, left: border_color_medium, right: border_color_medium}, font: font}
  let style_row_remark_lr = {fill: {fgColor: {rgb: "FFbfbfbf"}}, border: {top: border_color, bottom: border_color, left: border_color_medium, right: border_color_medium}, font: font}
  let style_row_body = {border: {bottom: border_color, left: border_color, right: border_color}, font: {sz: "10"}, alignment: {vertical: "top"}}
  let style_row_body_last = {border: {bottom: border_color_medium, left: border_color, right: border_color}, font: {sz: "10"}, alignment: {vertical: "top"}}
  let style_row_remark_body = {fill: {fgColor: {rgb: "FFd9d9d9"}}, border: {bottom: border_color, left: border_color, right: border_color}, font: font}
  let style_row_remark_body_last = {fill: {fgColor: {rgb: "FFd9d9d9"}}, border: {bottom: border_color_medium, left: border_color, right: border_color}, font: font}
  let width_col_xs = {wch: 12}
  let width_col_sm = {wch: 15}
  let width_col_md = {wch: 25}
  let width_col_lg = {wch: 35}
  let width_col_xl = {wch: 50} */

  let header = [
    { title: "1", width: width_col_sm, style: style_row_1 },
    { title: "2", width: width_col_sm, style: style_row_1 },
    { title: "3", width: width_col_xs, style: style_row_1 },
    { title: "4", width: width_col_xs, style: style_row_1 },
    { title: "5", width: width_col_sm, style: style_row_1 },
    { title: "6", width: width_col_sm, style: style_row_1 },
    { title: "7", width: width_col_lg, style: style_row_1 },
    { title: "8", width: width_col_xl, style: style_row_1 },
    { title: "9", width: width_col_sm, style: style_row_1 },
    { title: "10", width: width_col_sm, style: style_row_1 },
    { title: "11", width: width_col_sm, style: style_row_1 },
    { title: "12", width: width_col_sm, style: style_row_1 },
    { title: "13", width: width_col_sm, style: style_row_1 },
    { title: "14", width: width_col_sm, style: style_row_1 },
    { title: "15", width: width_col_sm, style: style_row_1 },
    { title: "16", width: width_col_sm, style: style_row_1 },
    { title: "17", width: width_col_sm, style: style_row_1 },
    { title: "18", width: width_col_sm, style: style_row_1 },
    { title: "19", width: width_col_sm, style: style_row_1 },
    { title: "20", width: width_col_sm, style: style_row_1 },
    { title: "21", width: width_col_sm, style: style_row_1 },
    { title: "22", width: width_col_sm, style: style_row_1 },
    { title: "23", width: width_col_sm, style: style_row_1 },
    { title: "24", width: width_col_sm, style: style_row_1 },
    { title: "25", width: width_col_sm, style: style_row_1 },
    { title: "26", width: width_col_sm, style: style_row_1 },
    { title: "27", width: width_col_md, style: style_row_1 },
    { title: "28", width: width_col_sm, style: style_row_1 },
    { title: "29", width: width_col_sm, style: style_row_1 },
    { title: "30", width: width_col_sm, style: style_row_1 },
    { title: "31", width: width_col_sm, style: style_row_1 },
    { title: "32", width: width_col_sm, style: style_row_1 },
    { title: "33", width: width_col_sm, style: style_row_1 },
    { title: "34", width: width_col_sm, style: style_row_1 },
    { title: "35", width: width_col_sm, style: style_row_1 },
    { title: "36", width: width_col_sm, style: style_row_1 },
    { title: "37", width: width_col_sm, style: style_row_1 },
    { title: "38", width: width_col_sm, style: style_row_1 },
    { title: "39", width: width_col_sm, style: style_row_1 },
    { title: "40", width: width_col_md, style: style_row_1 },
    { title: "41", width: width_col_sm, style: style_row_1 },
    { title: "42", width: width_col_sm, style: style_row_1 },
    { title: "43", width: width_col_sm, style: style_row_1 },
    { title: "44", width: width_col_sm, style: style_row_1 },
    { title: "45", width: width_col_sm, style: style_row_1 },
    { title: "46", width: width_col_sm, style: style_row_1 },
    { title: "47", width: width_col_sm, style: style_row_1 },
    { title: "48", width: width_col_sm, style: style_row_1 },
    { title: "49", width: width_col_sm, style: style_row_1 },
    { title: "50", width: width_col_sm, style: style_row_1 },
    { title: "51", width: width_col_sm, style: style_row_1 },
    { title: "52", width: width_col_sm, style: style_row_1 },
    { title: "53", width: width_col_sm, style: style_row_1 },
    { title: "54", width: width_col_sm, style: style_row_1 },
    { title: "55", width: width_col_sm, style: style_row_1 },
    { title: "56", width: width_col_sm, style: style_row_1 },
    { title: "57", width: width_col_sm, style: style_row_1 },
    { title: "58", width: width_col_sm, style: style_row_1 },
    { title: "59", width: width_col_sm, style: style_row_1 },
  ];
  data.push([
    { value: "Contract No.", style: style_row_2 },
    { value: "Contract Item", style: style_row_2 },
    { value: "PR No.", style: style_row_2 },
    { value: "PR Item", style: style_row_2 },
    { value: "PO Group", style: style_row_2 },
    { value: "PO Group Item", style: style_row_2 },
    { value: "Header Text", style: style_row_2 },
    { value: "Short Text", style: style_row_2 },
    { value: "Service Line No.", style: style_row_2 },
    { value: "Service Short Text", style: style_row_2 },
    { value: "Document Date", style: style_row_2 },
    { value: "Posting Date", style: style_row_2 },
    { value: "Delivery Date", style: style_row_2 },
    { value: "Quantity", style: style_row_2 },
    { value: "UOM", style: style_row_2 },
    { value: "Gross Price", style: style_row_2 },
    { value: "Currency", style: style_row_2 },
    { value: "G/L", style: style_row_2 },
    { value: "Cost Center", style: style_row_2 },
    { value: "Order", style: style_row_2 },
    { value: "BA", style: style_row_2 },
    { value: "Asset No", style: style_row_2 },
    { value: "PO item text 6", style: style_row_2 },
    { value: "Requistioner", style: style_row_2 },
    { value: "Plant", style: style_row_2 },
    { value: "Location", style: style_row_2 },
    { value: "Reference (INV No.)", style: style_row_2 },
    { value: "PRsp. Intern", style: style_row_2 },
    { value: "Company Code", style: style_row_2 },
    { value: "Business Place", style: style_row_2 },
    { value: "Tax Code", style: style_row_2 },
    { value: "Payment term", style: style_row_2 },
    { value: "Baseline Date", style: style_row_2 },
    { value: "Payment Method", style: style_row_2 },
    { value: "Pmnt Meth.Sup.", style: style_row_2 },
    { value: "Part. Bank", style: style_row_2 },
    { value: "Vendor Branch", style: style_row_2 },
    { value: "WHT type", style: style_row_2 },
    { value: "WHT code", style: style_row_2 },
    { value: "WHT base amount", style: style_row_2 },
    { value: "OTV: Name 1", style: style_row_2 },
    { value: "OTV: Name 2", style: style_row_2 },
    { value: "OTV: Street", style: style_row_2 },
    { value: "OTV: City", style: style_row_2 },
    { value: "OTV: PostCode", style: style_row_2 },
    { value: "OTV: Country", style: style_row_2 },
    { value: "PO Number", style: style_row_2 },
    { value: "PO Item", style: style_row_2 },
    { value: "GR Document", style: style_row_2 },
    { value: "GR Item", style: style_row_2 },
    { value: "IR Document", style: style_row_2 },
    { value: "FI Doc (Invoice)", style: style_row_2 },
    { value: "หมายเหตุ", style: style_row_remark_tlr },
    { value: "เลขที่ Delivery", style: style_row_remark_tlr },
    { value: "วันที่ Delivery", style: style_row_remark_tlr },
    { value: "เลขที่ Billing", style: style_row_remark_tlr },
    { value: "วันที่ Billing", style: style_row_remark_tlr },
    { value: "วันที่ส่งไปรษณีย์", style: style_row_remark_tlr },
    { value: "เลขที่พัสดุ", style: style_row_remark_tlr },
  ]);
  data.push([
    { value: "CHAR(10)", style: style_row_3 },
    { value: "NUM(5)", style: style_row_3 },
    { value: "CHAR(10)", style: style_row_3 },
    { value: "NUM(5)", style: style_row_3 },
    { value: "CHAR(10)", style: style_row_3 },
    { value: "NUM(5)", style: style_row_3 },
    { value: "CHAR(25)", style: style_row_3 },
    { value: "CHAR(40)", style: style_row_3 },
    { value: "NUM(10)", style: style_row_3 },
    { value: "CHAR(40)", style: style_row_3 },
    { value: "DD.MM.YYYY", style: style_row_3 },
    { value: "DD.MM.YYYY", style: style_row_3 },
    { value: "DD.MM.YYYY", style: style_row_3 },
    { value: "NUM(10.3)", style: style_row_3 },
    { value: "CHAR(3)", style: style_row_3 },
    { value: "NUM(11.2)", style: style_row_3 },
    { value: "CHAR(3)", style: style_row_3 },
    { value: "CHAR(10)", style: style_row_3 },
    { value: "CHAR(10)", style: style_row_3 },
    { value: "CHAR(12)", style: style_row_3 },
    { value: "CHAR(4)", style: style_row_3 },
    { value: "CHAR(12)", style: style_row_3 },
    { value: "CHAR(255)", style: style_row_3 },
    { value: "NUM(8)", style: style_row_3 },
    { value: "CHAR(4)", style: style_row_3 },
    { value: "CHAR(4)", style: style_row_3 },
    { value: "CHAR(16)", style: style_row_3 },
    { value: "CHAR(12)", style: style_row_3 },
    { value: "CHAR(4)", style: style_row_3 },
    { value: "CHAR(4)", style: style_row_3 },
    { value: "CHAR(2)", style: style_row_3 },
    { value: "CHAR(4)", style: style_row_3 },
    { value: "DD.MM.YYYY", style: style_row_3 },
    { value: "CHAR(1)", style: style_row_3 },
    { value: "CHAR(2)", style: style_row_3 },
    { value: "CHAR(3)", style: style_row_3 },
    { value: "CHAR(5)", style: style_row_3 },
    { value: "CHAR(2)", style: style_row_3 },
    { value: "CHAR(2)", style: style_row_3 },
    { value: "NUM(15.2)", style: style_row_3 },
    { value: "CHAR(35)", style: style_row_3 },
    { value: "CHAR(35)", style: style_row_3 },
    { value: "CHAR(35)", style: style_row_3 },
    { value: "CHAR(35)", style: style_row_3 },
    { value: "CHAR(10)", style: style_row_3 },
    { value: "CHAR(3)", style: style_row_3 },
    { value: "CHAR(10)", style: style_row_3 },
    { value: "NUM(5)", style: style_row_3 },
    { value: "CHAR(10)", style: style_row_3 },
    { value: "NUM(4)", style: style_row_3 },
    { value: "CHAR(10)", style: style_row_3 },
    { value: "CHAR(10)", style: style_row_3 },
    { value: "", style: style_row_remark_lr },
    { value: "", style: style_row_remark_lr },
    { value: "DD.MM.YYYY", style: style_row_remark_lr },
    { value: "", style: style_row_remark_lr },
    { value: "DD.MM.YYYY", style: style_row_remark_lr },
    { value: "DD.MM.YYYY", style: style_row_remark_lr },
    { value: "", style: style_row_remark_lr },
  ]);
  const dateEx = moment(new Date()).format(FORMAT_DATE_EXCEL_IN);
  data.push([
    { value: "", style: style_row_4_l },
    {
      value: "ระบุเลขที่ Contract/Item หรือ PR/Item อย่างใดอย่างหนึ่ง\n เพื่อเป็นเอกสารอ้างอิงสำหรับสร้าง PO\n* PR Doc type: N5NR, N8NB",
      style: style_row_4_nb,
    },
    { value: "", style: style_row_4_nb },
    { value: "", style: style_row_4_r },
    { value: "สำหรับจัดกลุ่ม PO\nจะต้องมี # นำหน้า", style: style_row_4_r },
    { value: "PO Item", style: style_row_4_r },
    {
      value: "กรณีทีเป็น Service\nSES - Short Text Header\nGR - Material Short Text / Item Text\nIR - Header Text\n\nไม่ใช่ Service\nGR - Header Text\nIR - Header Text",
      style: style_row_4_r,
    },
    { value: "PO - Short Text", style: style_row_4_r },
    { value: "Service Line No.", style: style_row_4_r },
    { value: "SES - Short Text (Item)", style: style_row_4_r },
    {
      value: "SES - Document Date \nGR - Document Date\nIR - Invoice date",
      style: style_row_4_r,
    },
    {
      value: "SES - Posting Date\nGR - Posting Date\nIR - Posting date",
      style: style_row_4_r,
    },
    { value: "Delivery Date", style: style_row_4_r },
    {
      value: "จำนวนสั่งซื้อ\n1. ตัวเลขไม่เกิน 10 หลัก\n2. ทศนิยมไม่เกิน 3 หลัก\n3. ต้องไม่มี , (Comma) ",
      style: style_row_4_r,
    },
    { value: "หน่วยสั่งซื้อ", style: style_row_4_r },
    {
      value: "ราคาต่อหน่วย\n\n1. ตัวเลขไม่เกิน 9 หลัก \n2. ทศนิยมไม่เกิน 2 หลัก\n3. ต้องไม่มี , (Comma) ",
      style: style_row_4_r,
    },
    { value: "สกุลเงิน", style: style_row_4_r },
    { value: "G/L Account No.", style: style_row_4_r },
    { value: "รหัส \nCost Center", style: style_row_4_r },
    { value: "รหัสงบประมาณ", style: style_row_4_r },
    { value: "Business\nArea", style: style_row_4_r },
    { value: "Asset No", style: style_row_4_r },
    { value: "คณะกรรมการตรวจรับ", style: style_row_4_r },
    { value: "รหัสหน่วยงาน", style: style_row_4_r },
    { value: "รหัสคลังสินค้า", style: style_row_4_r },
    { value: "รหัสสถานที่จัดเก็บ", style: style_row_4_r },
    {
      value: "เลขใบแจ้งหนี้ / ใบกำกับ / ใบส่งของ\n\nSES - Reference\nGR - Delivery Note\nIR - Reference",
      style: style_row_4_r,
    },
    { value: "รหัสพนักงานทำรับ\n\nSES - PRsp. Intern.", style: style_row_4_r },
    { value: "รหัสบริษัท", style: style_row_4_r },
    { value: "รหัสหน่วยงาน\nตั้งหนี้", style: style_row_4_r },
    { value: "รหัสภาษีซื้อ", style: style_row_4_r },
    { value: "Payment term", style: style_row_4_r },
    { value: "Baseline Date", style: style_row_4_r },
    { value: "Payment Method", style: style_row_4_r },
    { value: "Pmnt Meth.Sup.", style: style_row_4_r },
    { value: "Part. Bank", style: style_row_4_r },
    { value: "รหัสสาขาของ\nVendor", style: style_row_4_r },
    { value: "Withholding Tax Type", style: style_row_4_r },
    { value: "Withholding Tax Code", style: style_row_4_r },
    {
      value: "มูลค่าสำหรับคิด Withholding Tax\n1. ตัวเลขไม่เกิน 13 หลัก\n2. ทศนิยมไม่เกิน 2 หลัก\n3. ต้องไม่มี , (Comma) ",
      style: style_row_4_r,
    },
    { value: "", style: style_row_4_nb },
    { value: "", style: style_row_4_nb },
    { value: "Address ของ Onetime Vendor", style: style_row_4_nb },
    { value: "", style: style_row_4_nb },
    { value: "", style: style_row_4_nb },
    { value: "", style: style_row_4_r },
    { value: "PO Number", style: style_row_4_r },
    { value: "PO Item", style: style_row_4_r },
    { value: "GR Document", style: style_row_4_r },
    { value: "GR Item", style: style_row_4_r },
    { value: "IR Document", style: style_row_4_r },
    { value: "FI Doc (Invoice)", style: style_row_4_r },
    { value: "", style: style_row_remark_lr },
    { value: "", style: style_row_remark_lr },
    { value: `ex. ${dateEx}`, style: style_row_remark_lr },
    { value: "", style: style_row_remark_lr },
    { value: `ex. ${dateEx}`, style: style_row_remark_lr },
    { value: `ex. ${dateEx}`, style: style_row_remark_lr },
    { value: "", style: style_row_remark_lr },
  ]);
  data.push([
    { value: "", style: style_row_5_r },
    {
      value: "Require: Contract/Item หรือ PR/Item อย่างใดอย่างหนึ่ง",
      style: style_row_5_nb,
    },
    { value: "", style: style_row_5_nb },
    { value: "", style: style_row_5_r },
    { value: "Require", style: style_row_5_r },
    { value: "Require", style: style_row_5_r },
    { value: "Require กรณี Service", style: style_row_5_r },
    {
      value: "Optional\nหากไม่ระบุ ดึงจาก PR หรือ Contract Item (Short text)",
      style: style_row_5_r,
    },
    {
      value: "Require กรณี PO Service (ยกเว้น N8NB ดึงจาก PR เท่านั้น)",
      style: style_row_5_r,
    },
    {
      value: "Require กรณี PO Service (ยกเว้น N8NB ดึงจาก PR เท่านั้น)",
      style: style_row_5_r,
    },
    { value: "Require กรณี SES GR IR", style: style_row_5_r },
    {
      value: "Optional\n(หากไม่ระบุ ระบบจะ default เป็นวันที่ปัจจุบัน ซึ่งสามารถแก้ไขได้ก่อน Post)",
      style: style_row_5_r,
    },
    {
      value: "Optional\nหากไม่ระบุ ดึงจาก PR, Contract วันที่ปัจจุบัน",
      style: style_row_5_r,
    },
    {
      value: "Require กรณี ref Contract กรณี Ref PR ดึงจาก Excel or PR (ยกเว้น N8NB ดึงจาก PR เท่านั้น)",
      style: style_row_5_r,
    },
    {
      value: "Require กรณี Service กรณีซื้อ Stock ดึงจาก Contract (ยกเว้น type N8NB  ดึงจาก PR เท่านั้น)",
      style: style_row_5_r,
    },
    {
      value: "Require กรณี Service กรณีซื้อ Stock ดึงจาก Excel or Contract (ยกเว้น type N8NB  ดึงจาก PR เท่านั้น)",
      style: style_row_5_r,
    },
    { value: "Optional\nหากไม่ระบุ ดึงจาก PR/Contract", style: style_row_5_r },
    {
      value: "Require กรณีสร้าง PO ประเภท Service (ยกเว้น type N8NB  ดึงจาก PR เท่านั้น)",
      style: style_row_5_r,
    },
    {
      value: "Require กรณีสร้าง PO ประเภท Service (ยกเว้น type N8NB  ดึงจาก PR เท่านั้น)",
      style: style_row_5_r,
    },
    {
      value: "Require กรณีสร้าง PO ประเภท Service (ยกเว้น type N8NB  ดึงจาก PR เท่านั้น)",
      style: style_row_5_r,
    },
    {
      value: "Require กรณี Service (ยกเว้น type N8NB  ดึงจาก PR เท่านั้น)",
      style: style_row_5_r,
    },
    //Added Start
    { value: "Require กรณีสร้าง PO ประเภท Asset", style: style_row_5_r },
    { value: "Optional คณะกรรมการตรวจรับ", style: style_row_5_r },
    //Added End
    { value: "Require", style: style_row_5_r },
    {
      value: "Optional\nหากไม่ระบุ ดึงจาก PR/Contract (ยกเว้น type N8NB  ดึงจาก PR เท่านั้น)",
      style: style_row_5_r,
    },
    { value: "Require กรณี GR Stock", style: style_row_5_r },
    { value: "Require กรณี GR/SES/IR", style: style_row_5_r },
    { value: "Require กรณี Service", style: style_row_5_r },
    { value: "Require กรณี IR", style: style_row_5_r },
    { value: "Require กรณี IR", style: style_row_5_r },
    { value: "Require กรณี IR", style: style_row_5_r },
    {
      value: "Optional\nหากไม่ระบุให้ใช้ค่า default Require กรณีที่เป็น One-Time Vendor",
      style: style_row_5_r,
    },
    { value: "Optional\nหากไม่ระบุให้ใช้ค่า default", style: style_row_5_r },
    { value: "Optional\nหากไม่ระบุให้ใช้ค่า default", style: style_row_5_r },
    { value: "Optional\nหากไม่ระบุให้ใช้ค่า default", style: style_row_5_r },
    { value: "Optional\nหากไม่ระบุให้ใช้ค่า default", style: style_row_5_r },
    { value: "Require กรณี IR", style: style_row_5_r },
    { value: "Optional", style: style_row_5_r },
    { value: "Optional", style: style_row_5_r },
    { value: "Optional", style: style_row_5_r },
    { value: "Require กรณี Onetime", style: style_row_5_r },
    { value: "Require กรณี Onetime", style: style_row_5_r },
    { value: "Require กรณี Onetime", style: style_row_5_r },
    { value: "Require กรณี Onetime", style: style_row_5_r },
    { value: "Require กรณี Onetime", style: style_row_5_r },
    { value: "Require กรณี Onetime", style: style_row_5_r },
    { value: "Required กรณี Mode: 3,4", style: style_row_5_r },
    { value: "Required กรณี Mode: 3,4", style: style_row_5_r },
    { value: "Required กรณี Mode: 5", style: style_row_5_r },
    { value: "Required กรณี Mode: 5", style: style_row_5_r },
    { value: "-", style: style_row_5_r },
    { value: "-", style: style_row_5_r },
    { value: "", style: style_row_remark_blr },
    { value: "", style: style_row_remark_blr },
    { value: "", style: style_row_remark_blr },
    { value: "", style: style_row_remark_blr },
    { value: "", style: style_row_remark_blr },
    { value: "", style: style_row_remark_blr },
    { value: "", style: style_row_remark_blr },
  ]);

  let po_group = 0;
  for (let order of orders) {
    // if (!order.checked) { continue }
    let order_cates = {};
    for (let elm of order.order_item_categories || order.inbound_order_item_category) {
      order_cates[elm.item_category_id] = elm;
    }

    let items_by_contract = null;

    if (order.purchasing_order_items) {
      items_by_contract = _.groupBy(
        order.purchasing_order_items.filter((elm) => !elm.is_mbom && !elm.from_parent),
        function (value) {
          return order_cates[value?.item_category_id]?.contract_id;
        }
      );
    } else {
      items_by_contract = _.groupBy(
        order.inbound_order_items.filter((elm) => !elm.is_mbom),
        function (value) {
          return order_cates[value.item_category_id].contract_id;
        }
      );
    }

    let delivery_date = formatExportDate(order.delivery_date);
    // eslint-disable-next-line no-loop-func
    Object.keys(items_by_contract).forEach((key, index) => {
      let running_num = 0;
      let item_len = items_by_contract[key].length;
      po_group = po_group + 1;
      for (let item of items_by_contract[key].sort(sortItemByCate)) {
        let expense_group = {};
        if (item.expense_ratio_arr) {
          expense_group = _.groupBy(item.expense_ratio_arr, function (value) {
            return value;
          });
          item_len = item_len + Object.keys(expense_group).length - 1;
        } else {
          expense_group[item.expense_ratio] = [item.expense_ratio];
        }

        for (var key_ex in expense_group) {
          running_num += 1;
          let style_body = running_num === item_len ? style_row_body_last : style_row_body;
          let style_body_blank = running_num === item_len ? style_row_remark_body_last : style_row_remark_body;
          let exp_ratio = parseExpRatioArr(key_ex);
          let de_qty = item.expense_ratio_arr ? expense_group[key_ex].length : item.qty;
          let short_text_8 = get_short_text_8(item.name, order.quotation ? order.quotation.client_station.name : "", item.item.nickname, order.quotation);
          data.push([
            {
              value: objectPreventNull(order_cates[item?.item_category_id]?.contract, "code") || "",
              style: style_body,
            },
            {
              value: objectPreventNull(item.contract_item, "line_number") || "",
              style: style_body,
            },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: `#${po_group}`, style: style_body },
            { value: running_num, style: style_body },
            {
              value: order.quotation ? order.quotation.client_station.name.substr(0, 25) : "",
              style: style_body,
            },
            { value: short_text_8, style: style_body },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: delivery_date ? delivery_date : "", style: style_body },
            { value: "", style: style_body_blank },
            { value: delivery_date ? delivery_date : "", style: style_body },
            { value: de_qty, style: style_body },
            { value: "", style: style_body_blank },
            { value: item.contract_price + exp_ratio, style: style_body },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "31300039", style: style_body },
            { value: "H019", style: style_body },
            { value: "VDDC", style: style_body },
            {
              value: `${order.order_code ? order.order_code : ""}-${toStrWithLeadingZeros(index + 1, 2)}`,
              style: style_body,
            },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
          ]);
        }
      }
    });
  }

  return { sheet_name: "รายการ", dataSet: [{ columns: header, data: data }] };
}

export function toExcelMassUploadPoInbound(orders) {
  let data = [];
  let end_line = 1;

  // Styles
  let font = { sz: "10" };
  let font_red = { sz: "10", color: { rgb: "FFff0000" } };
  let style_row_1 = { font: { sz: "11" }, alignment: { horizontal: "left" } };
  let style_row_2 = {
    fill: { fgColor: { rgb: "FFC5E0B4" } },
    font: { sz: "11", bold: true },
    alignment: { horizontal: "center" },
  };
  let style_row_3 = {
    fill: { fgColor: { rgb: "FFffe699" } },
    font: { sz: "10", bold: true },
    alignment: { horizontal: "center" },
  };
  let style_row_4_l = {
    fill: { fgColor: { rgb: "FFfff2cc" } },
    font: { sz: "10" },
    alignment: { vertical: "top", horizontal: "center" },
  };
  let style_row_4_r = {
    fill: { fgColor: { rgb: "FFfff2cc" } },
    font: { sz: "10" },
    alignment: { vertical: "top", horizontal: "center" },
  };
  let style_row_4_nb = {
    fill: { fgColor: { rgb: "FFfff2cc" } },
    font: { sz: "10" },
    alignment: { vertical: "top", horizontal: "center" },
  };
  let style_row_5_r = {
    fill: { fgColor: { rgb: "FFdeebf7" } },
    font: font_red,
    alignment: { vertical: "top", horizontal: "center" },
  };
  let style_row_5_nb = {
    fill: { fgColor: { rgb: "FFdeebf7" } },
    font: font_red,
    alignment: { vertical: "top", horizontal: "center" },
  };
  let style_row_remark_tlr = {
    fill: { fgColor: { rgb: "FFbfbfbf" } },
    font: font,
    alignment: { vertical: "top", horizontal: "center" },
  };
  let style_row_remark_blr = {
    fill: { fgColor: { rgb: "FFbfbfbf" } },
    font: font,
  };
  let style_row_remark_lr = {
    fill: { fgColor: { rgb: "FFbfbfbf" } },
    font: font,
  };
  let style_row_body = { font: { sz: "10" }, alignment: { vertical: "top" } };
  let style_row_body_last = {
    font: { sz: "10" },
    alignment: { vertical: "top" },
  };
  let style_row_remark_body = {
    fill: { fgColor: { rgb: "FFd9d9d9" } },
    font: font,
  };
  let style_row_remark_body_last = {
    fill: { fgColor: { rgb: "FFd9d9d9" } },
    font: font,
  };
  let width_col_xs = { wch: 12 };
  let width_col_sm = { wch: 15 };
  let width_col_md = { wch: 25 };
  let width_col_lg = { wch: 35 };
  let width_col_xl = { wch: 50 };

  /* Old Template Styles
  let font = {sz: "10"}
  let font_red = {sz: "10", color: { rgb: "FFff0000" }}
  let border_color = {style: 'thin', color: { rgb: "FF000000" }}
  let border_color_medium = {style: 'medium', color: { rgb: "FF000000" }}
  let border_style = {top: border_color, bottom: border_color, left: border_color, right: border_color}
  let border_style_bm = {top: border_color, bottom: border_color_medium, left: border_color, right: border_color}
  let style_row_1 = {border: border_style_bm, font: {sz: "11", bold: true}, alignment: {horizontal: "center"}}
  let style_row_2 = {fill: {fgColor: {rgb: "FFC5E0B4"}}, border: {bottom: border_color, left: border_color, right: border_color}, font: {sz: "11", bold: true}, alignment: {horizontal: "center"}}
  let style_row_3 = {fill: {fgColor: {rgb: "FFffe699"}}, border: border_style, font: {sz: "10"}, alignment: {horizontal: "center"}}
  let style_row_4_l = {fill: {fgColor: {rgb: "FFfff2cc"}},
                       border: {top: border_color, bottom: border_color, left: border_color},
                       font: {sz: "10"},
                       alignment: {vertical: "top", horizontal: "center"}}
  let style_row_4_r = {fill: {fgColor: {rgb: "FFfff2cc"}},
                       border: {top: border_color, bottom: border_color, right: border_color},
                       font: {sz: "10"},
                       alignment: {vertical: "top", horizontal: "center"}}
  let style_row_4_nb = {fill: {fgColor: {rgb: "FFfff2cc"}}, border: {top: border_color, bottom: border_color}, font: {sz: "10"}, alignment: {vertical: "top", horizontal: "center"}}
  let style_row_5_r = {fill: {fgColor: {rgb: "FFdeebf7"}},
                       border: {top: border_color, bottom: border_color_medium, right: border_color},
                       font: font_red,
                       alignment: {vertical: "top", horizontal: "center"}}
  let style_row_5_nb = {fill: {fgColor: {rgb: "FFdeebf7"}},border: {top: border_color, bottom: border_color_medium}, font: font_red, alignment: {vertical: "top", horizontal: "center"}}
  let style_row_remark_tlr = {fill: {fgColor: {rgb: "FFbfbfbf"}}, border: {top: border_color_medium, bottom: border_color, left: border_color_medium, right: border_color_medium}, font: font, alignment: {vertical: "top", horizontal: "center"}}
  let style_row_remark_blr = {fill: {fgColor: {rgb: "FFbfbfbf"}}, border: {top: border_color, bottom: border_color_medium, left: border_color_medium, right: border_color_medium}, font: font}
  let style_row_remark_lr = {fill: {fgColor: {rgb: "FFbfbfbf"}}, border: {top: border_color, bottom: border_color, left: border_color_medium, right: border_color_medium}, font: font}
  let style_row_body = {border: {bottom: border_color, left: border_color, right: border_color}, font: {sz: "10"}, alignment: {vertical: "top"}}
  let style_row_body_last = {border: {bottom: border_color_medium, left: border_color, right: border_color}, font: {sz: "10"}, alignment: {vertical: "top"}}
  let style_row_remark_body = {fill: {fgColor: {rgb: "FFd9d9d9"}}, border: {bottom: border_color, left: border_color, right: border_color}, font: font}
  let style_row_remark_body_last = {fill: {fgColor: {rgb: "FFd9d9d9"}}, border: {bottom: border_color_medium, left: border_color, right: border_color}, font: font}
  let width_col_xs = {wch: 12}
  let width_col_sm = {wch: 15}
  let width_col_md = {wch: 25}
  let width_col_lg = {wch: 35}
  let width_col_xl = {wch: 50} */

  let header = [
    { title: "1", width: width_col_sm, style: style_row_1 },
    { title: "2", width: width_col_sm, style: style_row_1 },
    { title: "3", width: width_col_xs, style: style_row_1 },
    { title: "4", width: width_col_xs, style: style_row_1 },
    { title: "5", width: width_col_sm, style: style_row_1 },
    { title: "6", width: width_col_sm, style: style_row_1 },
    { title: "7", width: width_col_lg, style: style_row_1 },
    { title: "8", width: width_col_xl, style: style_row_1 },
    { title: "9", width: width_col_sm, style: style_row_1 },
    { title: "10", width: width_col_sm, style: style_row_1 },
    { title: "11", width: width_col_sm, style: style_row_1 },
    { title: "12", width: width_col_sm, style: style_row_1 },
    { title: "13", width: width_col_sm, style: style_row_1 },
    { title: "14", width: width_col_sm, style: style_row_1 },
    { title: "15", width: width_col_sm, style: style_row_1 },
    { title: "16", width: width_col_sm, style: style_row_1 },
    { title: "17", width: width_col_sm, style: style_row_1 },
    { title: "18", width: width_col_sm, style: style_row_1 },
    { title: "19", width: width_col_sm, style: style_row_1 },
    { title: "20", width: width_col_sm, style: style_row_1 },
    { title: "21", width: width_col_sm, style: style_row_1 },
    { title: "22", width: width_col_sm, style: style_row_1 },
    { title: "23", width: width_col_sm, style: style_row_1 },
    { title: "24", width: width_col_sm, style: style_row_1 },
    { title: "25", width: width_col_sm, style: style_row_1 },
    { title: "26", width: width_col_sm, style: style_row_1 },
    { title: "27", width: width_col_md, style: style_row_1 },
    { title: "28", width: width_col_sm, style: style_row_1 },
    { title: "29", width: width_col_sm, style: style_row_1 },
    { title: "30", width: width_col_sm, style: style_row_1 },
    { title: "31", width: width_col_sm, style: style_row_1 },
    { title: "32", width: width_col_sm, style: style_row_1 },
    { title: "33", width: width_col_sm, style: style_row_1 },
    { title: "34", width: width_col_sm, style: style_row_1 },
    { title: "35", width: width_col_sm, style: style_row_1 },
    { title: "36", width: width_col_sm, style: style_row_1 },
    { title: "37", width: width_col_sm, style: style_row_1 },
    { title: "38", width: width_col_sm, style: style_row_1 },
    { title: "39", width: width_col_sm, style: style_row_1 },
    { title: "40", width: width_col_md, style: style_row_1 },
    { title: "41", width: width_col_sm, style: style_row_1 },
    { title: "42", width: width_col_sm, style: style_row_1 },
    { title: "43", width: width_col_sm, style: style_row_1 },
    { title: "44", width: width_col_sm, style: style_row_1 },
    { title: "45", width: width_col_sm, style: style_row_1 },
    { title: "46", width: width_col_sm, style: style_row_1 },
    { title: "47", width: width_col_sm, style: style_row_1 },
    { title: "48", width: width_col_sm, style: style_row_1 },
    { title: "49", width: width_col_sm, style: style_row_1 },
    { title: "50", width: width_col_sm, style: style_row_1 },
    { title: "51", width: width_col_sm, style: style_row_1 },
    { title: "52", width: width_col_sm, style: style_row_1 },
  ];
  data.push([
    { value: "Contract No.", style: style_row_2 },
    { value: "Contract Item", style: style_row_2 },
    { value: "PR No.", style: style_row_2 },
    { value: "PR Item", style: style_row_2 },
    { value: "PO Group", style: style_row_2 },
    { value: "PO Group Item", style: style_row_2 },
    { value: "Header Text", style: style_row_2 },
    { value: "Short Text", style: style_row_2 },
    { value: "Service Line No.", style: style_row_2 },
    { value: "Service Short Text", style: style_row_2 },
    { value: "Document Date", style: style_row_2 },
    { value: "Posting Date", style: style_row_2 },
    { value: "Delivery Date", style: style_row_2 },
    { value: "Quantity", style: style_row_2 },
    { value: "UOM", style: style_row_2 },
    { value: "Gross Price", style: style_row_2 },
    { value: "Currency", style: style_row_2 },
    { value: "G/L", style: style_row_2 },
    { value: "Cost Center", style: style_row_2 },
    { value: "Order", style: style_row_2 },
    { value: "BA", style: style_row_2 },
    { value: "Asset No", style: style_row_2 },
    { value: "PO item text 6", style: style_row_2 },
    { value: "Requistioner", style: style_row_2 },
    { value: "Plant", style: style_row_2 },
    { value: "Location", style: style_row_2 },
    { value: "Reference (INV No.)", style: style_row_2 },
    { value: "PRsp. Intern", style: style_row_2 },
    { value: "Company Code", style: style_row_2 },
    { value: "Business Place", style: style_row_2 },
    { value: "Tax Code", style: style_row_2 },
    { value: "Payment term", style: style_row_2 },
    { value: "Baseline Date", style: style_row_2 },
    { value: "Payment Method", style: style_row_2 },
    { value: "Pmnt Meth.Sup.", style: style_row_2 },
    { value: "Part. Bank", style: style_row_2 },
    { value: "Vendor Branch", style: style_row_2 },
    { value: "WHT type", style: style_row_2 },
    { value: "WHT code", style: style_row_2 },
    { value: "WHT base amount", style: style_row_2 },
    { value: "OTV: Name 1", style: style_row_2 },
    { value: "OTV: Name 2", style: style_row_2 },
    { value: "OTV: Street", style: style_row_2 },
    { value: "OTV: City", style: style_row_2 },
    { value: "OTV: PostCode", style: style_row_2 },
    { value: "OTV: Country", style: style_row_2 },
    { value: "PO Number", style: style_row_2 },
    { value: "PO Item", style: style_row_2 },
    { value: "GR Document", style: style_row_2 },
    { value: "GR Item", style: style_row_2 },
    { value: "IR Document", style: style_row_2 },
    { value: "FI Doc (Invoice)", style: style_row_2 },
  ]);
  data.push([
    { value: "CHAR(10)", style: style_row_3 },
    { value: "NUM(5)", style: style_row_3 },
    { value: "CHAR(10)", style: style_row_3 },
    { value: "NUM(5)", style: style_row_3 },
    { value: "CHAR(10)", style: style_row_3 },
    { value: "NUM(5)", style: style_row_3 },
    { value: "CHAR(25)", style: style_row_3 },
    { value: "CHAR(40)", style: style_row_3 },
    { value: "NUM(10)", style: style_row_3 },
    { value: "CHAR(40)", style: style_row_3 },
    { value: "DD.MM.YYYY", style: style_row_3 },
    { value: "DD.MM.YYYY", style: style_row_3 },
    { value: "DD.MM.YYYY", style: style_row_3 },
    { value: "NUM(10.3)", style: style_row_3 },
    { value: "CHAR(3)", style: style_row_3 },
    { value: "NUM(11.2)", style: style_row_3 },
    { value: "CHAR(3)", style: style_row_3 },
    { value: "CHAR(10)", style: style_row_3 },
    { value: "CHAR(10)", style: style_row_3 },
    { value: "CHAR(12)", style: style_row_3 },
    { value: "CHAR(4)", style: style_row_3 },
    { value: "CHAR(12)", style: style_row_3 },
    { value: "CHAR(255)", style: style_row_3 },
    { value: "NUM(8)", style: style_row_3 },
    { value: "CHAR(4)", style: style_row_3 },
    { value: "CHAR(4)", style: style_row_3 },
    { value: "CHAR(16)", style: style_row_3 },
    { value: "CHAR(12)", style: style_row_3 },
    { value: "CHAR(4)", style: style_row_3 },
    { value: "CHAR(4)", style: style_row_3 },
    { value: "CHAR(2)", style: style_row_3 },
    { value: "CHAR(4)", style: style_row_3 },
    { value: "DD.MM.YYYY", style: style_row_3 },
    { value: "CHAR(1)", style: style_row_3 },
    { value: "CHAR(2)", style: style_row_3 },
    { value: "CHAR(3)", style: style_row_3 },
    { value: "CHAR(5)", style: style_row_3 },
    { value: "CHAR(2)", style: style_row_3 },
    { value: "CHAR(2)", style: style_row_3 },
    { value: "NUM(15.2)", style: style_row_3 },
    { value: "CHAR(35)", style: style_row_3 },
    { value: "CHAR(35)", style: style_row_3 },
    { value: "CHAR(35)", style: style_row_3 },
    { value: "CHAR(35)", style: style_row_3 },
    { value: "CHAR(10)", style: style_row_3 },
    { value: "CHAR(3)", style: style_row_3 },
    { value: "CHAR(10)", style: style_row_3 },
    { value: "NUM(5)", style: style_row_3 },
    { value: "CHAR(10)", style: style_row_3 },
    { value: "NUM(4)", style: style_row_3 },
    { value: "CHAR(10)", style: style_row_3 },
    { value: "CHAR(10)", style: style_row_3 },
  ]);
  const dateEx = moment(new Date()).format(FORMAT_DATE_EXCEL_IN);
  data.push([
    { value: "", style: style_row_4_l },
    {
      value: "ระบุเลขที่ Contract/Item หรือ PR/Item อย่างใดอย่างหนึ่ง\n เพื่อเป็นเอกสารอ้างอิงสำหรับสร้าง PO\n* PR Doc type: N5NR, N8NB",
      style: style_row_4_nb,
    },
    { value: "", style: style_row_4_nb },
    { value: "", style: style_row_4_r },
    { value: "สำหรับจัดกลุ่ม PO\nจะต้องมี # นำหน้า", style: style_row_4_r },
    { value: "PO Item", style: style_row_4_r },
    {
      value: "กรณีทีเป็น Service\nSES - Short Text Header\nGR - Material Short Text / Item Text\nIR - Header Text\n\nไม่ใช่ Service\nGR - Header Text\nIR - Header Text",
      style: style_row_4_r,
    },
    { value: "PO - Short Text", style: style_row_4_r },
    { value: "Service Line No.", style: style_row_4_r },
    { value: "SES - Short Text (Item)", style: style_row_4_r },
    {
      value: "SES - Document Date \nGR - Document Date\nIR - Invoice date",
      style: style_row_4_r,
    },
    {
      value: "SES - Posting Date\nGR - Posting Date\nIR - Posting date",
      style: style_row_4_r,
    },
    { value: "Delivery Date", style: style_row_4_r },
    {
      value: "จำนวนสั่งซื้อ\n1. ตัวเลขไม่เกิน 10 หลัก\n2. ทศนิยมไม่เกิน 3 หลัก\n3. ต้องไม่มี , (Comma) ",
      style: style_row_4_r,
    },
    { value: "หน่วยสั่งซื้อ", style: style_row_4_r },
    {
      value: "ราคาต่อหน่วย\n\n1. ตัวเลขไม่เกิน 9 หลัก \n2. ทศนิยมไม่เกิน 2 หลัก\n3. ต้องไม่มี , (Comma) ",
      style: style_row_4_r,
    },
    { value: "สกุลเงิน", style: style_row_4_r },
    { value: "G/L Account No.", style: style_row_4_r },
    { value: "รหัส \nCost Center", style: style_row_4_r },
    { value: "รหัสงบประมาณ", style: style_row_4_r },
    { value: "Business\nArea", style: style_row_4_r },
    { value: "Asset No", style: style_row_4_r },
    { value: "คณะกรรมการตรวจรับ", style: style_row_4_r },
    { value: "รหัสหน่วยงาน", style: style_row_4_r },
    { value: "รหัสคลังสินค้า", style: style_row_4_r },
    { value: "รหัสสถานที่จัดเก็บ", style: style_row_4_r },
    {
      value: "เลขใบแจ้งหนี้ / ใบกำกับ / ใบส่งของ\n\nSES - Reference\nGR - Delivery Note\nIR - Reference",
      style: style_row_4_r,
    },
    { value: "รหัสพนักงานทำรับ\n\nSES - PRsp. Intern.", style: style_row_4_r },
    { value: "รหัสบริษัท", style: style_row_4_r },
    { value: "รหัสหน่วยงาน\nตั้งหนี้", style: style_row_4_r },
    { value: "รหัสภาษีซื้อ", style: style_row_4_r },
    { value: "Payment term", style: style_row_4_r },
    { value: "Baseline Date", style: style_row_4_r },
    { value: "Payment Method", style: style_row_4_r },
    { value: "Pmnt Meth.Sup.", style: style_row_4_r },
    { value: "Part. Bank", style: style_row_4_r },
    { value: "รหัสสาขาของ\nVendor", style: style_row_4_r },
    { value: "Withholding Tax Type", style: style_row_4_r },
    { value: "Withholding Tax Code", style: style_row_4_r },
    {
      value: "มูลค่าสำหรับคิด Withholding Tax\n1. ตัวเลขไม่เกิน 13 หลัก\n2. ทศนิยมไม่เกิน 2 หลัก\n3. ต้องไม่มี , (Comma) ",
      style: style_row_4_r,
    },
    { value: "", style: style_row_4_nb },
    { value: "", style: style_row_4_nb },
    { value: "Address ของ Onetime Vendor", style: style_row_4_nb },
    { value: "", style: style_row_4_nb },
    { value: "", style: style_row_4_nb },
    { value: "", style: style_row_4_r },
    { value: "PO Number", style: style_row_4_r },
    { value: "PO Item", style: style_row_4_r },
    { value: "GR Document", style: style_row_4_r },
    { value: "GR Item", style: style_row_4_r },
    { value: "IR Document", style: style_row_4_r },
    { value: "FI Doc (Invoice)", style: style_row_4_r },
  ]);
  data.push([
    { value: "", style: style_row_5_r },
    {
      value: "Require: Contract/Item หรือ PR/Item อย่างใดอย่างหนึ่ง",
      style: style_row_5_nb,
    },
    { value: "", style: style_row_5_nb },
    { value: "", style: style_row_5_r },
    { value: "Require", style: style_row_5_r },
    { value: "Require", style: style_row_5_r },
    { value: "Require กรณี Service", style: style_row_5_r },
    {
      value: "Optional\nหากไม่ระบุ ดึงจาก PR หรือ Contract Item (Short text)",
      style: style_row_5_r,
    },
    {
      value: "Require กรณี PO Service (ยกเว้น N8NB ดึงจาก PR เท่านั้น)",
      style: style_row_5_r,
    },
    {
      value: "Require กรณี PO Service (ยกเว้น N8NB ดึงจาก PR เท่านั้น)",
      style: style_row_5_r,
    },
    { value: "Require กรณี SES GR IR", style: style_row_5_r },
    {
      value: "Optional\n(หากไม่ระบุ ระบบจะ default เป็นวันที่ปัจจุบัน ซึ่งสามารถแก้ไขได้ก่อน Post)",
      style: style_row_5_r,
    },
    {
      value: "Optional\nหากไม่ระบุ ดึงจาก PR, Contract วันที่ปัจจุบัน",
      style: style_row_5_r,
    },
    {
      value: "Require กรณี ref Contract กรณี Ref PR ดึงจาก Excel or PR (ยกเว้น N8NB ดึงจาก PR เท่านั้น)",
      style: style_row_5_r,
    },
    {
      value: "Require กรณี Service กรณีซื้อ Stock ดึงจาก Contract (ยกเว้น type N8NB  ดึงจาก PR เท่านั้น)",
      style: style_row_5_r,
    },
    {
      value: "Require กรณี Service กรณีซื้อ Stock ดึงจาก Excel or Contract (ยกเว้น type N8NB  ดึงจาก PR เท่านั้น)",
      style: style_row_5_r,
    },
    { value: "Optional\nหากไม่ระบุ ดึงจาก PR/Contract", style: style_row_5_r },
    {
      value: "Require กรณีสร้าง PO ประเภท Service (ยกเว้น type N8NB  ดึงจาก PR เท่านั้น)",
      style: style_row_5_r,
    },
    {
      value: "Require กรณีสร้าง PO ประเภท Service (ยกเว้น type N8NB  ดึงจาก PR เท่านั้น)",
      style: style_row_5_r,
    },
    {
      value: "Require กรณีสร้าง PO ประเภท Service (ยกเว้น type N8NB  ดึงจาก PR เท่านั้น)",
      style: style_row_5_r,
    },
    {
      value: "Require กรณี Service (ยกเว้น type N8NB  ดึงจาก PR เท่านั้น)",
      style: style_row_5_r,
    },
    //Added Start
    { value: "Require กรณีสร้าง PO ประเภท Asset", style: style_row_5_r },
    { value: "Optional คณะกรรมการตรวจรับ", style: style_row_5_r },
    //Added End
    { value: "Require", style: style_row_5_r },
    {
      value: "Optional\nหากไม่ระบุ ดึงจาก PR/Contract (ยกเว้น type N8NB  ดึงจาก PR เท่านั้น)",
      style: style_row_5_r,
    },
    { value: "Require กรณี GR Stock", style: style_row_5_r },
    { value: "Require กรณี GR/SES/IR", style: style_row_5_r },
    { value: "Require กรณี Service", style: style_row_5_r },
    { value: "Require กรณี IR", style: style_row_5_r },
    { value: "Require กรณี IR", style: style_row_5_r },
    { value: "Require กรณี IR", style: style_row_5_r },
    {
      value: "Optional\nหากไม่ระบุให้ใช้ค่า default Require กรณีที่เป็น One-Time Vendor",
      style: style_row_5_r,
    },
    { value: "Optional\nหากไม่ระบุให้ใช้ค่า default", style: style_row_5_r },
    { value: "Optional\nหากไม่ระบุให้ใช้ค่า default", style: style_row_5_r },
    { value: "Optional\nหากไม่ระบุให้ใช้ค่า default", style: style_row_5_r },
    { value: "Optional\nหากไม่ระบุให้ใช้ค่า default", style: style_row_5_r },
    { value: "Require กรณี IR", style: style_row_5_r },
    { value: "Optional", style: style_row_5_r },
    { value: "Optional", style: style_row_5_r },
    { value: "Optional", style: style_row_5_r },
    { value: "Require กรณี Onetime", style: style_row_5_r },
    { value: "Require กรณี Onetime", style: style_row_5_r },
    { value: "Require กรณี Onetime", style: style_row_5_r },
    { value: "Require กรณี Onetime", style: style_row_5_r },
    { value: "Require กรณี Onetime", style: style_row_5_r },
    { value: "Require กรณี Onetime", style: style_row_5_r },
    { value: "Required กรณี Mode: 3,4", style: style_row_5_r },
    { value: "Required กรณี Mode: 3,4", style: style_row_5_r },
    { value: "Required กรณี Mode: 5", style: style_row_5_r },
    { value: "Required กรณี Mode: 5", style: style_row_5_r },
    { value: "-", style: style_row_5_r },
    { value: "-", style: style_row_5_r },
  ]);

  let po_group = 0;
  for (let order of orders) {
    // if (!order.checked) { continue }
    let order_cates = {};
    for (let elm of order.order_item_categories || order.inbound_order_item_category) {
      order_cates[elm.item_category_id] = elm;
    }

    let items_by_contract = null;

    if (order.purchasing_order_items) {
      items_by_contract = _.groupBy(
        order.purchasing_order_items.filter((elm) => !elm.is_mbom),
        function (value) {
          return order_cates[value.item_category_id].contract_id;
        }
      );
    } else {
      items_by_contract = _.groupBy(
        order.inbound_order_items.filter((elm) => !elm.is_mbom),
        function (value) {
          return order_cates[value.item_category_id].contract_id;
        }
      );
    }

    let delivery_date = formatExportDate(order.delivery_date);
    // eslint-disable-next-line no-loop-func
    Object.keys(items_by_contract).map((key, index) => {

      console.log('order', order, items_by_contract[key])

      let running_num = 0;
      let item_len = items_by_contract[key].length;
      let mbom_qty_list = [];
      let mbom_price_list = [];
      let mbomIndex = -1;
      po_group += 1;
      let sumFees = getSumFees(order);

      for (let item of items_by_contract[key].sort(sortItemByCate)) {
        if (item.from_mbom) {
          mbom_qty_list.push(item.qty);
          mbom_price_list.push(item.price);
        }
      }

      let resultBomSolveFee = mbom_qty_list.length > 0 ? solveFeeNew(saveMultiple(sumFees, FEE_MULTIPLIER), mbom_qty_list, mbom_price_list) : [];
      let feePrice = resultBomSolveFee.length > 0 ? resultBomSolveFee[2] : [];

      for (let item of items_by_contract[key].sort(sortItemByCate)) {
        if (item.from_mbom) {
          mbomIndex = mbomIndex + 1;
        }

        let expense_group = {};
        if (item.expense_ratio_arr) {
          expense_group = _.groupBy(item.expense_ratio_arr, function (value) {
            return value;
          });
          item_len = item_len + Object.keys(expense_group).length - 1;
        } else {
          expense_group[item.expense_ratio] = [item.expense_ratio];
        }

        for (var key in expense_group) {
          running_num += 1;
          let style_body = running_num === item_len ? style_row_body_last : style_row_body;
          let style_body_blank = running_num === item_len ? style_row_remark_body_last : style_row_remark_body;
          let exp_ratio = parseExpRatioArr(key);
          let de_qty = item.expense_ratio_arr ? expense_group[key].length : item.qty;
          let short_text_8 = get_short_text_8(item.name, order.quotation ? order.quotation.client_station.name : "", item?.item?.nickname, order.quotation);
          data.push([
            {
              value: objectPreventNull(order_cates[item.item_category_id].contract, "code") || "",
              style: style_body,
            },
            {
              value: objectPreventNull(item.contract_item, "line_number") ? objectPreventNull(item.contract_item, "line_number") : item.line_number || "",
              style: style_body,
            },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: `#${po_group}`, style: style_body },
            { value: running_num, style: style_body },
            {
              value: order.quotation ? order.quotation.client_station.name.substr(0, 25) : "",
              style: style_body,
            },
            { value: short_text_8, style: style_body },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: delivery_date ? delivery_date : "", style: style_body },
            { value: "", style: style_body_blank },
            { value: delivery_date ? delivery_date : "", style: style_body },
            { value: de_qty, style: style_body },
            { value: "", style: style_body_blank },
            {
              value: item.from_mbom ? parseFloat(item.price) + parseFloat(feePrice[mbomIndex] / 100) : item.contract_price + exp_ratio,
              style: style_body,
            },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "31300039", style: style_body },
            { value: "H051", style: style_body },
            { value: "VDDC", style: style_body },
            {
              value: `${order.code}-${toStrWithLeadingZeros(index + 1, 2)}`,
              style: style_body,
            },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
            { value: "", style: style_body_blank },
          ]);
        }
      }
    });
  }

  return { sheet_name: "รายการ", dataSet: [{ columns: header, data: data }] };
}

import React from "react";

import "../styles/quotation.css";
import { QUO_STATES } from "../constants";
import DcInventoriesManagementIndex from "../components/dc-inventories-management/Index/DcInventoriesManagementIndex";
import DcInventoriesManagementList from "../components/dc-inventories-management/List/DcInventoriesManagementList";
import DcInventoriesManagementLog from "../components/dc-inventories-management/Log/DcInventoriesManagementLog";

export class DcInventoriesManagement extends React.Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(props.location.search);
    const defaultStates = Object.keys(QUO_STATES).reduce((pre, cur) => {
      if (cur !== "cancelled") {
        pre.push(QUO_STATES[cur].name);
      }
      return pre;
    }, []);
    const selected_state = urlParams.get("state") ? urlParams.get("state").split(",") : defaultStates;
    const selected_date_from = urlParams.get("date_from");
    const selected_date_to = urlParams.get("date_to");
    let selected_id = props.match.params.id === "log" ? "log" : props.match.params.id;
    this.state = {
      current_page: selected_id === "log" ? "log" : selected_id === "new" ? null : selected_id,
      state: selected_state || "",
      link_date_from: selected_date_from || "",
      link_date_to: selected_date_to || "",
    };

    this.onChangeQuoPage = this.onChangeQuoPage.bind(this);
  }

  onChangeQuoPage(page) {
    this.setState({ current_page: page });
  }

  render() {
    if (this.state.current_page === undefined) {
      return <DcInventoriesManagementIndex props={this.props}  state={this.state.state} link_date_from={this.state.link_date_from} link_date_to={this.state.link_date_to} onChangeQuoPage={this.onChangeQuoPage} />;
    } else if (this.state.current_page === "log") {
      return <DcInventoriesManagementLog props={this.props} state={this.state.state} link_date_from={this.state.link_date_from} link_date_to={this.state.link_date_to} onChangeQuoPage={this.onChangeQuoPage} />;
    } else {
      return <DcInventoriesManagementList {...this.props} id={this.state.current_page} onChangeQuoPage={this.onChangeQuoPage} />;
    }
  }
}

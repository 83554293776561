import * as React from "react";
import {
  List, Datagrid, Edit, Create, SimpleForm, DeleteWithConfirmButton, TextField,
  EditButton, TextInput, Toolbar, SaveButton, ReferenceField, ReferenceInput,
  SelectInput, AutocompleteInput, NumberInput, required, useNotify, useRefresh, useRedirect,
  useListContext, FormDataConsumer
} from 'react-admin';
import BookIcon from '@material-ui/icons/Book';
import { ITEM_CATE_METHOD_TYPES, method_type_example_usages } from '../../models/ItemCategories'
import { parse } from "query-string";

import { Form } from 'react-final-form';
import { Box, Button as CoreButton, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ContentFilter from "@material-ui/icons/FilterList";

import { exporter } from '../../functions/reactAdmin'

import { EditToolbar, EditActions } from './Default'


// const FEE_OPTIONS = Object.keys(ITEM_CATE_FEE_TYPES).map( key => {
//   return {id: key, name: ITEM_CATE_FEE_TYPES[key]}
// })

const METHOD_OPTIONS = Object.keys(ITEM_CATE_METHOD_TYPES).map( key => {
  return {id: key, name: ITEM_CATE_METHOD_TYPES[key]}
})

export const ItemIcon = BookIcon;

const ItemDiscountFilter = props => {
  return props.context === "button" ? (
    <ItemDiscountFilterButton {...props} />
  ) : (
    <ItemDiscountFilterForm {...props} />
  );
};

const ItemDiscountFilterButton = () => {
  const { showFilter } = useListContext();
  return (
      <CoreButton
          size="small"
          color="primary"
          onClick={() => showFilter("main")}
          startIcon={<ContentFilter />}
      >
          Filter
      </CoreButton>
  );
};

const ItemDiscountFilterForm = ({ open }) => {
  const {
      displayedFilters,
      filterValues,
      setFilters,
      hideFilter,
  } = useListContext();

  if (!displayedFilters.main) return null;

  const onSubmit = values => {
      if (Object.keys(values).length > 0) {
          setFilters(values);
      } else {
          hideFilter("main");
      }
  };

  const resetFilter = () => {
      setFilters({}, []);
  };

  return (
      <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
          {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
              <Box mt={8} />
              <Box display="flex" alignItems="flex-end" mb={1}>
              <Box component="span" mr={2}>
                  {/* Full-text search filter. We don't use <SearchFilter> to force a large form input */}
                  <TextInput
                  resettable
                  helperText={false}
                  source="name"
                  label="Search ชื่อ"
                  InputProps={{
                      endAdornment: (
                      <InputAdornment>
                          <SearchIcon color="disabled" />
                      </InputAdornment>
                      )
                  }}
                  />
              </Box>
              <Box component="span" mr={2} mb={1.5}>
                  <CoreButton variant="outlined" color="primary" type="submit">
                  Filter
                  </CoreButton>
              </Box>
              <Box component="span" mb={1.5}>
                  <CoreButton variant="outlined" onClick={resetFilter}>
                  Close
                  </CoreButton>
              </Box>
              </Box>
          </form>
          )}
      </Form>
      </div>
  );
};

export const ItemDiscountList = (props) => (
  <List {...props} filters={<ItemDiscountFilter />} exporter={exporter}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField label="Item" source="item_id" reference="items" sortBy="name">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" label="ส่วนลด"/>
      <TextField source="unit"/>
      <EditButton basePath="/item_discounts" />
       <DeleteWithConfirmButton undoable={false}/>
    </Datagrid>
  </List>
);

const ItemTitle = ({ record }) => {
  return <span>Item {record ? `"${record.name}"` : ''}</span>;
};

export const ItemDiscountEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({data}) => {
    notify('Changes saved')
    redirect(`/items/${data.item_id}/2`);
    refresh();
  };
  return (
    <div className="scroll-div">
    <Edit onSuccess={onSuccess} undoable={false} actions={<EditActions />} title={<ItemTitle />} {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <ReferenceInput
          source="item_id"
          reference="items"
          validate={required()}
          filterToQuery={searchText => ({ name: searchText })}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput
          source="discount_item_id"
          reference="items"
          validate={required()}
          filterToQuery={searchText => ({ name: searchText })}
          label="ส่วนลดสำหรับ"
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        
        {/* <SelectInput source="fee_type" label="ส่วนลด ค่าขนส่ง/ต่าติดตั้ง" choices={FEE_OPTIONS} validate={required()}/> */}
        <SelectInput source="method_type" label="วิธีการคำนวณ" choices={METHOD_OPTIONS} validate={required()}/>
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) => {
            {/* ======= Method 5 per km * qty ======== */}
            if (formData.method_type == 5) {
              return (
                <React.Fragment>
                  {/* ======= display examples ======== */}
                  {method_type_example_usages(formData.method_type)}
                  <NumberInput source="from_value" step={1} validate={required()} label="ตั้งแต่"/>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <NumberInput source="to_value" step={1} label="ถึง"/>
                  <br/>
                  <NumberInput source="package_qty" step={1} label="จำนวนชิ้น ต่อหน่วย"/>
                </React.Fragment>
              )
            {/* ======= Method 1,2,3,6 ======== */}
            } else {
              return (
                <React.Fragment>
                  {/* ======= display examples ======== */}
                  {method_type_example_usages(formData.method_type)}
                  <NumberInput source="from_value" step={1} validate={required()} label="ตั้งแต่"/>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <NumberInput source="to_value" step={1} label="ถึง"/>
                </React.Fragment>
              )
            }
          }}
        </FormDataConsumer>
        <NumberInput source="price" label="ราคา" step={0.01} validate={required()}/>
        <TextInput source="unit" label="หน่วย"/>
      </SimpleForm>
    </Edit>
    </div>
  )
}

export const ItemDiscountCreate = props => {
  // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
  const { item_id: item_id_string } = parse(props.location.search);

  // ra-data-fakerest uses integers as identifiers, we need to parse the querystring
  // We also must ensure we can still create a new comment without having a item_id
  // from the url by returning an empty string if item_id isn't specified
  const item_id = item_id_string ? parseInt(item_id_string, 10) : "";

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({data}) => {
    notify('Changes saved')
    redirect(`/items/${data.item_id}/2`);
    refresh();
  };

  return (
    <div className="scroll-div">
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm defaultValue={{ item_id }} toolbar={<EditToolbar />}>
        <ReferenceInput
          source="item_id"
          reference="items"
          validate={required()}
          filterToQuery={searchText => ({ name: searchText })}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>

        <ReferenceInput
          source="discount_item_id"
          reference="items"
          validate={required()}
          filterToQuery={searchText => ({ name: searchText })}
          label="ส่วนลดสำหรับ"
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        
        {/* <SelectInput source="fee_type" label="ส่วนลด ค่าขนส่ง/ต่าติดตั้ง" choices={FEE_OPTIONS} validate={required()}/> */}
        <SelectInput source="method_type" label="วิธีการคำนวณ" choices={METHOD_OPTIONS} validate={required()}/>
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) => {
            {/* ======= Method 5 per km * qty ======== */}
            if (formData.method_type == 5) {
              return (
                <React.Fragment>
                  {/* ======= display examples ======== */}
                  {method_type_example_usages(formData.method_type)}
                  <NumberInput source="from_value" step={1} validate={required()} label="ตั้งแต่"/>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <NumberInput source="to_value" step={1} label="ถึง"/>
                  <br/>
                  <NumberInput source="package_qty" step={1} label="จำนวนชิ้น ต่อหน่วย"/>
                </React.Fragment>
              )
            {/* ======= Method 1,2,3,6 ======== */}
            } else {
              return (
                <React.Fragment>
                  {/* ======= display examples ======== */}
                  {method_type_example_usages(formData.method_type)}
                  <NumberInput source="from_value" step={1} validate={required()} label="ตั้งแต่"/>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <NumberInput source="to_value" step={1} label="ถึง"/>
                </React.Fragment>
              )
            }
          }}
        </FormDataConsumer>
        <NumberInput source="price" label="ราคา" step={0.01} validate={required()}/>
        <TextInput source="unit" label="หน่วย"/>
      </SimpleForm>
    </Create>
    </div>
  );
};
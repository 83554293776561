import React from "react";
import Modal from "react-modal";
import cloneDeep from "lodash/cloneDeep";
import ToggleSwitch from "../global/ToggleSwitch";
// constants
import { R_SEL_OPTION_MAX_H, MODAL_STYLE_SMALL_SM } from "../../constants";

// components
import ClientStation from "../global/ClientStation";

// Functions
import { uniqueKey } from "../../functions/Random.js";
import { objectIndexOf } from "../../functions/Object.js";

const DEFAULT_TYPE = "DODO";
const NewOption = { value: "", label: "" };
export class MrFormStamp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category_options: props.item_category_options,
      selected_name: cloneDeep(NewOption),
      selected_category: cloneDeep(NewOption),
      selected_type: "",

      edited_item: null,
      modal_preview: false,

      reFilteringClientStation: uniqueKey(),
    };
    this.handleOpenEditedModal = this.handleOpenEditedModal.bind(this);
  }

  handleOpenEditedModal(id) {
    this.setState({ edited_item: id });
  }

  inputFieldResPerson(station_id, elm, field, position) {
    return <input className={`form-control ${position}`} name={field} value={elm[field]} onChange={(e) => this.props.onChangeResponsiblePerson(e, station_id, elm.id)} disabled={this.props.disabled}></input>;
  }

  editModal(station_id, person) {
    let { edited_item } = this.state;
    const btns = () => {
      if (!this.props.disabled) {
        return (
          <React.Fragment>
            <button type="button" className="btn btn-success btn-sm w-100" onClick={() => this.handleOpenEditedModal(null)} disabled={this.props.disabled}>
              บันทึก
            </button>
          </React.Fragment>
        );
      } else {
        return null;
      }
    };
    return (
      <Modal isOpen={person.id === edited_item} contentLabel="onEditCommittee" onRequestClose={() => this.handleOpenEditedModal(null)} style={MODAL_STYLE_SMALL_SM}>
        <div className="d-flex flex-column">
          <h5>แก้ไข</h5>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>ลำดับ</label>
            </div>
            <div className="d-flex-2">{this.inputFieldResPerson(station_id, person, "position", "")}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label className="mb-0">ชื่อ</label>
            </div>
            <div className="d-flex-2">{this.inputFieldResPerson(station_id, person, "name", "")}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label className="mb-0">ตำแหน่ง</label>
            </div>
            <div className="d-flex-2">{this.inputFieldResPerson(station_id, person, "title", "")}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label className="mb-0">รหัสพนักงาน</label>
            </div>
            <div className="d-flex-2">{this.inputFieldResPerson(station_id, person, "code", "")}</div>
          </div>
          <div className="d-flex flex-row mt-3">{btns()}</div>
        </div>
      </Modal>
    );
  }

  displayStations() {
    let { stations, disabled } = this.props;
    let records = stations.map((station, index) => {
      return (
        <React.Fragment>
          <div className="row mt-3">
            <div className="offset-xl-1 col-8 col-xl-9">
              <h5 className="pb-1 mt-1 mb-0">{station.station_name}</h5>
            </div>
            <div className="col col-xl-1 text-right" style={{ display: "flex", gap: 5, flexDirection: "row", justifyContent: "right" }}>
              <button type="button" className="btn btn-danger btn-sm ml-2" disabled={disabled} onClick={(e) => this.props.onClickResetCommittee(e, station)}>
                Reset
              </button>
            </div>
          </div>
          <div className="row mt-2">
            <div className="offset-xl-1 col-xl-10">
              <div className="card-box mt-0 p-3 d-none d-md-block">
                {this.displayResponsiblePersonsHeader()}
                {this.displayResponsiblePersons(station)}
              </div>
              <div className="d-md-none px-3">
                {this.displayResponsiblePersonsHeader()}
                {this.displayResponsiblePersons(station)}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    });
    return records;
  }

  displayResponsiblePersonsHeader() {
    return (
      <React.Fragment>
        {/* md up */}
        <div className="row mt-2 d-none d-md-flex">
          <div className="first col-1 justify-content-center">ลำดับ</div>
          <div className="col-5">ชื่อ</div>
          <div className="col">ตำแหน่ง</div>
          <div className="col">รหัสพนักงาน</div>
        </div>
      </React.Fragment>
    );
  }

  displayResponsiblePersons(station) {
    if (station.manufacture_request_station_committees) {
      let records = station.manufacture_request_station_committees
        .sort((a, b) => a.position - b.position)
        .map((person, index) => {
          return (
            <React.Fragment>
              {/* md up */}
              <div className="row mt-2 d-none d-md-flex">
                <div className="first col-1 justify-content-center">{this.inputFieldResPerson(station.id, person, "position", "text-center")}</div>
                <div className="col-5">{this.inputFieldResPerson(station.id, person, "name", "")}</div>
                <div className="col">{this.inputFieldResPerson(station.id, person, "title", "")}</div>
                <div className="col">{this.inputFieldResPerson(station.id, person, "code", "")}</div>
              </div>
              {/* md down */}
              <a onClick={() => this.handleOpenEditedModal(person.id)}>
                <div className="row mt-2 border d-md-none">
                  <div className="table-item-bg col-12 d-flex bg-ghostwhite">
                    {person.position} - {person.name}
                  </div>
                  <div className="table-item-bg col-12 pt-1 pr-0">ตำแหน่ง {person.title}</div>
                  <div className="table-item-bg col-12 text-center">รหัสพนักงาน {person.code}</div>
                </div>
              </a>
              {this.editModal(station.id, person)}
            </React.Fragment>
          );
        });
      return records;
    }
  }

  render() {
    let { stations, request, onToggleCommittee } = this.props;
    return (
      <div style={{ padding: "0 20px" }}>
        <div className="row mt-3">
          <div className="offset-xl-1 col-8 col-xl-9">
            <h5 style={{ margin: 0, marginBottom: 3 }}>
              ผู้ตรวจรับเอกสาร <span style={{ fontWeight: "normal" }}>(จำนวน {stations.length} สถานี)</span>
            </h5>
          </div>
          <div className="col col-xl-1 text-right" style={{ display: "flex", gap: 5, flexDirection: "row", justifyContent: "right" }}>
            <div>
              <div style={{ fontSize: 12, marginTop: -15, fontWeight: "bold", whiteSpace: "nowrap" }}>แสดงผู้ตรวจรับเอกสาร</div>
              <ToggleSwitch style={{ width: "100%" }} onChange={onToggleCommittee} checked={request.toggle_committee} />
            </div>
          </div>
        </div>

        {this.displayStations()}
      </div>
    );
  }
}

import React from "react";
import Modal from "react-modal";
import _ from "lodash";
import { MODAL_STYLE_LARGE } from "../../../../constants";

// Components
import ExportModal from "./ExportModal";
import { BUYER_TEXT } from "../../../../models/Reports";
import { addressToStr } from "../../../../functions/String";
import { toLocaleStringCus } from "../../../../functions/Number";

export default function ExportProcurementModal(props) {
  let reportByBuyer = _.groupBy(props.selectedExport, function (elm) {
    return elm.quotation.buyer_id ? BUYER_TEXT + elm.quotation.buyer_id : elm.quotation.client_station.id;
  });

  const reportItems = () => {
    let rows = Object.keys(reportByBuyer).map((key, buyer_index) => {
      let reportByClient = _.groupBy(reportByBuyer[key], function (elm) {
        return elm.quotation.client_station.id;
      });
      let all_count = 0;
      let all_sales = 0;
      return (
        <div className="card-box py-1 mt-2">
          {Object.keys(reportByClient).map((client_id_key, client_index) => {
            let first_row = reportByClient[client_id_key][0];
            let buyer_name = first_row.quotation.buyer ? first_row.quotation.buyer.name : "";
            let client_station = first_row.quotation.client_station;
            let total_sales = reportByClient[client_id_key].reduce((pre, cur) => (pre += cur.total_sales), 0);
            all_count += reportByClient[client_id_key].length;
            all_sales += total_sales;
            return props.display_row_item(
              [
                client_index === 0 ? buyer_name : "",
                client_station.name,
                client_station.contact_tel,
                addressToStr(client_station.address),
                reportByClient[client_id_key].length,
                toLocaleStringCus(total_sales),
                client_index === 0 ? (
                  <button type="button" className="btn btn-danger ml-2" onClick={() => props.deleteSelectItem(key)}>
                    ลบ
                  </button>
                ) : (
                  ""
                ),
              ],
              "p-2 mx-1",
              []
            );
          })}
          {/* summary row */}
          {props.display_row_item(["", "", "", "รวม", all_count, toLocaleStringCus(all_sales)], "p-2 mx-1 bg-aliceblue", ["", "", "", "text-right font-weight-bold", "font-weight-bold", "font-weight-bold"])}
        </div>
      );
    });
    return rows;
  };

  return (
    <Modal isOpen={props.is_displaying_basket} contentLabel="onImportProcurement" onRequestClose={() => props.handleDisplayingBasket(false)} style={MODAL_STYLE_LARGE}>
      <div className="order-modal-basket p-4">
        <div className="d-flex flex-row mb-2">
          {/* <h5>รายการ Export ทั้งหมด ({keys.length}x รายการ)</h5> */}
          <h5>รายการ Export ทั้งหมด ({Object.keys(reportByBuyer).length} รายการ)</h5>
          <div className="ml-auto">
            <ExportModal reportByBuyer={reportByBuyer} select_export={props.select_export} btnClass={"btn btn-primary"} />
          </div>
          <button type="button" className="btn btn-danger ml-2" onClick={props.handleClearExport}>
            ล้าง
          </button>
          <button type="button" className="btn btn-outline-secondary ml-2" onClick={() => props.handleDisplayingBasket(false)}>
            Close
          </button>
        </div>
        <div className="order-list">{reportItems()}</div>
      </div>
    </Modal>
  );
}

import React from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import '../../styles/model.css';
import icon_open from '../../assets/images/icon_open_in_browser.png';

class CardLink extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  onClickLink(link) {
    this.props.history.push(link)
  }

  render() {
    let { classOutter, title, content, link } = this.props

    return (
      <React.Fragment>
        <a onClick={() => this.onClickLink(link)}>
          <div className={`p-3 card-box d-flex flex-column cursor-pointer ${classOutter}`}>
            <div className="d-flex">
              <div className="d-flex d-flex-2 text-truncate">
                {title}
              </div>
              <div className="card-icon-link">
                <img src={icon_open} style={{width: "25px"}} />
              </div>
            </div>
            {content}
          </div>
        </a>
      </React.Fragment>
    );
  }
}

export default withRouter(CardLink);

CardLink.propTypes = {
  classOutter: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  link: PropTypes.string.isRequired,
};
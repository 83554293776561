import React from "react";

import "./SearchBar.css";

export default function SearchBar(props) {
  return (
    <div className="row">
      <div className="col-12 offset-md-2 col-md-6">
        <input
          className="form-control search-bar"
          id="searchbar"
          onChange={props.onChangeQuery}
          onKeyDown={props.onKeyDown}
          placeholder={props.placeholder}
          value={props.query}
        ></input>
      </div>
      {/* Hidden on xs, sm  */}
      <div className="d-none d-md-block col-md-2">
        <div className="d-flex flex-row">
          <button type="submit" className="btn btn-primary ml-2">
            Search
          </button>
          <button
            type="button"
            className="btn btn-danger ml-2"
            onClick={props.onClickClear}
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
}

// in vendors.js
import axios from "axios";
import * as React from "react";
import { List, Datagrid, Edit, Create, SimpleForm, DeleteWithConfirmButton, TextField, EditButton, TextInput, required, useListContext, DateField, BooleanInput, useDataProvider, useRedirect, useNotify } from "react-admin";
import BookIcon from "@material-ui/icons/Book";
import { Form } from "react-final-form";
import { Box, Button as CoreButton, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ContentFilter from "@material-ui/icons/FilterList";

import { exporter } from "../../functions/reactAdmin";

import { EditToolbar, EditActions } from "./Default";
import { TRACKING_USER_TYPE } from "../../constants";

export const VendorIcon = BookIcon;

const VendorFilter = (props) => {
  return props.context === "button" ? <VendorFilterButton {...props} /> : <VendorFilterForm {...props} />;
};

const VendorFilterButton = () => {
  const { showFilter } = useListContext();
  return (
    <CoreButton size="small" color="primary" onClick={() => showFilter("main")} startIcon={<ContentFilter />}>
      Filter
    </CoreButton>
  );
};

const VendorFilterForm = ({ open }) => {
  const { displayedFilters, filterValues, setFilters, hideFilter } = useListContext();

  if (!displayedFilters.main) return null;

  const onSubmit = (values) => {
    if (Object.keys(values).length > 0) {
      setFilters(values);
    } else {
      hideFilter("main");
    }
  };

  const resetFilter = () => {
    setFilters({}, []);
  };

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box mt={8} />
            <Box display="flex" alignItems="flex-end" mb={1}>
              <Box component="span" mr={3}>
                <BooleanInput source="is_active" label="เปิดใช้งาน" alwaysOn />
              </Box>
              <Box component="span" mr={2}>
                {/* Full-text search filter. We don't use <SearchFilter> to force a large form input */}
                <TextInput
                  resettable
                  helperText={false}
                  source="name"
                  label="Search ชื่อ"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box component="span" mr={2} mb={1.5}>
                <CoreButton variant="outlined" color="primary" type="submit">
                  Filter
                </CoreButton>
              </Box>
              <Box component="span" mb={1.5}>
                <CoreButton variant="outlined" onClick={resetFilter}>
                  Close
                </CoreButton>
              </Box>
            </Box>
          </form>
        )}
      </Form>
    </div>
  );
};

export const VendorList = (props) => (
  <List {...props} filters={<VendorFilter />} exporter={exporter}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="is_active" label="เปิดใช้งาน" />
      <TextField source="name" label="ชื่อ" />
      <TextField source="vendor_id" label="หมายเลขผู้ผลิต" />
      <TextField source="vendor_code" label="รหัสผู้ผลิต" />
      <TextField source="phone" label="เบอร์โทรศัพท์" />
      <EditButton basePath="/vendors" />
      <DeleteWithConfirmButton undoable={false} />
    </Datagrid>
  </List>
);

const VendorTitle = ({ record }) => {
  return <span>Vendor {record ? `"${record.name}"` : ""}</span>;
};

export const VendorEdit = (props) => {
  const dataProvider = useDataProvider();
  const [user, setUser] = React.useState();
  const redirect = useRedirect();
  const notify = useNotify();
  React.useEffect(() => {
    dataProvider
      .getOne(props.resource, { id: props.id })
      .then(({ data }) => {
        setUser(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  // to reset password in tracking if exist
  const handleSubmit = (event) => {
    event.preventDefault();
    const { elements } = event.currentTarget;
    let new_password = elements.namedItem("new_password").value;
    axios
      .put(`${process.env.REACT_APP_API_URL}/change-user-info`, {
        username: user.vendor_id,
        password: new_password,
      })
      .then((response) => {
        console.log(response);
        alert(`รหัสผ่านผู้ผลิต Vendor ID: ${user.vendor_id} ถูกเปลี่ยนเป็น ${new_password}`);
      })
      .catch((error) => {
        console.log(error);
        alert(error.message);
      });
  };
  // onSuccess function to create user when update vendor_id from none
  const onSuccess = (event) => {
    console.log(event);
    if (event.data.vendor_id) {
      dataProvider
        .getOne(props.resource, { id: event.data.id })
        .then(({ data }) => {
          let params = {
            username: data.vendor_id,
            password: data.vendor_id,
            role: TRACKING_USER_TYPE.vendor.value,
            user_type: TRACKING_USER_TYPE.vendor.name,
            user_id: data.id,
            id: data.id,
            name: data.name,
            address_id: data.address_id ? data.address_id : null,
          };
          console.log(params);
          axios
            .post(`${process.env.REACT_APP_API_URL}/tracking/user`, params)
            .then((response_user) => {
              console.log(response_user);
              notify(`Vendor successfully created/updated on P4S and Tracking!`);
              redirect("/vendors");
            })
            .catch((error) => {
              console.log(error);
              alert(error.message);
              redirect("/vendors");
            });
        })
        .catch((error) => {
          alert(error);
          redirect("/vendors");
        });
    } else {
      notify("Element updated");
      redirect("/vendors");
    }
  };

  return (
    <React.Fragment>
      <Edit undoable={false} actions={<EditActions />} title={<VendorTitle />} onSuccess={onSuccess} {...props}>
        <SimpleForm toolbar={<EditToolbar />}>
          <TextInput disabled source="id" />
          <BooleanInput label="เปิดใช้งาน" source="is_active" />
          <TextInput source="vendor_id" label="หมายเลขผู้ผลิต (ชื่อผู้ใช้ของผู้ผลิต)" />
          <TextInput source="vendor_code" label="รหัสผู้ผลิต (ใช้ออกรหัสทรัพย์สิน)" />
          <TextInput source="name" label="ชื่อผู้ผลิต" validate={required()} />
          <TextInput source="coordinator_name" label="ชื่อผู้ติดต่อ" />
          <TextInput source="phone" label="เบอร์โทรศัพท์" />
          <DateField source="created_at" showTime locales="fr-FR" />
          <DateField source="updated_at" showTime locales="fr-FR" />
        </SimpleForm>
      </Edit>

      <div class="dropdown-divider"></div>
      <div className="card-body">
        <b>รีเซ็ทรหัสผ่านสำหรับระบบ Tracking</b>
      </div>
      <div className="card">
        <form class="card-body form-inline" onSubmit={handleSubmit}>
          <input type="password" class="form-control mb-2 mr-sm-2" id="new_password" placeholder="New Password" />
          <button type="submit" class="btn btn-secondary mb-2">
            Reset Password
          </button>
        </form>
      </div>
    </React.Fragment>
  );
};

export const VendorCreate = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const onSuccess = (event) => {
    if (event.data.vendor_id) {
      dataProvider
        .getOne(props.resource, { id: event.data.id })
        .then(({ data }) => {
          let params = {
            username: data.vendor_id,
            password: data.vendor_id,
            role: TRACKING_USER_TYPE.vendor.value,
            user_type: TRACKING_USER_TYPE.vendor.name,
            user_id: data.id,
            id: data.id,
            name: data.name,
            address_id: data.address_id ? data.address_id : null,
          };
          console.log(params);
          axios
            .post(`${process.env.REACT_APP_API_URL}/tracking/user`, params)
            .then((response) => {
              console.log(response);
              notify(`Vendor successfully created on P4S and Tracking!`);
              redirect(`/vendors`);
            })
            .catch((error) => {
              console.log(error);
              alert(error.message);
            });
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      notify("Vendor successfully created!");
      redirect("/vendors");
    }
  };
  return (
    <Create title="สร้างผู้ผลิต" actions={<EditActions />} onSuccess={onSuccess} {...props}>
      <SimpleForm>
        <BooleanInput label="เปิดใช้งาน" source="is_active" defaultValue={true} />
        <TextInput source="vendor_id" label="หมายเลขผู้ผลิต (ชื่อผู้ใช้ของผู้ผลิต) " />
        <TextInput source="vendor_code" label="รหัสผู้ผลิต (ใช้ออกรหัสทรัพย์สิน)" />
        <TextInput source="name" label="ชื่อผู้ผลิต" validate={required()} />
        <TextInput source="coordinator_name" label="ชื่อผู้ติดต่อ" />
        <TextInput source="phone" label="เบอร์โทรศัพท์" />
      </SimpleForm>
    </Create>
  );
};
